import get from "lodash/get";
import React, { useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import { useFilters, useFlexLayout, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Colors from "../../../assets/Colors";
import { ReactComponent as SingleLeft } from "../../../assets/images/singleLeftArrow.svg";
import { ReactComponent as SingleRight } from "../../../assets/images/singleRightArrow.svg";
import { ReactComponent as SortingDown } from "../../../assets/images/sortingTableArrowDown.svg";
import { ReactComponent as SortingUp } from "../../../assets/images/sortingTableArrowUp.svg";
import { localizer } from "../../../utils/Localizer";
import { StyledAddButton } from "../../styled";
import DropdownButtonNew from "../controls/DropdownButtonNew";
import FilterInput from "../controls/FilterInput";
import Tooltip from "../tooltip/Tooltip";
import { ITableProps } from "./ITableProps";
import {
    ActionDescriptionContainer,
    ButtonContainer,
    EntriesContainer,
    FilterContainer,
    HeaderContainer,
    InfoContainer,
    PageSelectorContainer,
    RowsContainer,
    SortingIndicatorContainer,
    StyledPageNumber,
    StyledPageSelectorContainer,
    TableHeader
} from "./PaginatedTableStyle";
import { Styles } from "./TableStyle";

export const headerJustifyOptions = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
};

const getParameter = (data: any, name: string, defaultValue: any): any => {
    const bodyCellParameterOrDefault = get(
        data,
        ["cell", "column", name],
        defaultValue
    );
    const parameterOfDefault = get(
        data,
        ["column", name],
        bodyCellParameterOrDefault
    );

    return parameterOfDefault;
};

const getStyles = (props: any, data: any) => {
    const justifyContent = getParameter(data, "leftAlign", false)
        ? undefined
        : data.column?.headerAlignment || headerJustifyOptions.center;
    const displayStyle = getParameter(data, "withoutFlex", false)
        ? undefined
        : "flex";
    return [
        {
            ...props,
            title: undefined, // removing 'Toggle SortBy' tooltip
        },
        {
            style: {
                justifyContent,
                alignItems: getParameter(data, "alignItems", "stretch"),
                display: displayStyle,
                flexGrow: getParameter(data, "flexGrow", 0),
                flexShrink: getParameter(data, "flexShrink", 1),
                flexBasis: getParameter(data, "flexBasis", "auto"),
                minWidth: getParameter(data, "minWidth", "auto"),
                maxWidth: getParameter(data, "maxWidth", "auto")
            },
        },
    ];
};

const PaginatedTable = (props: ITableProps) => {
    const { columns, data, initialState, tableHeaderText, summaryId } = props;
    const [filterValue, setFilterValue] = useState<string>("");
    const [entriesCount, setEntriesCount] = useState<number>(10);

    const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        gotoPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        setPageSize,
        setGlobalFilter,
        globalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            initialState: { ...initialState, pageIndex: 0, pageSize: 10 },
            defaultColumn,
            autoResetSortBy: false,
            disableSortRemove: true
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useFlexLayout,
        usePagination
    );

    const { pageIndex } = state;

    const getPages = () => {
        const pages = [];
        if (pageOptions.length === 0) {
            pages.push(<StyledPageNumber active={true} key={"1"}>1</StyledPageNumber>);
        } else if (pageOptions.length <= 5) {
            pages.push(pageOptions.map((index) => {
                return <StyledPageNumber active={index === pageIndex} key={index} onClick={() => gotoPage(index)} >{index + 1}</StyledPageNumber>
            }));
        } else {
            pages.push(
                <StyledPageNumber active={0 === pageIndex} key={0} onClick={() => gotoPage(0)}>
                    1{pageIndex > 2 && "..."}
                </StyledPageNumber>)
            if (pageIndex === 0) {
                pages.push(
                    <StyledPageNumber key={pageIndex + 1} onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</StyledPageNumber>,
                    <StyledPageNumber key={pageIndex + 2} onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</StyledPageNumber>,
                    <StyledPageNumber key={pageIndex + 3} onClick={() => gotoPage(pageIndex + 3)}>{pageIndex + 4}</StyledPageNumber>
                )
            } else if (pageIndex === pageOptions.length - 1) {
                pages.push(
                    <StyledPageNumber key={pageOptions.length - 4} onClick={() => gotoPage(pageOptions.length - 4)}>{pageOptions.length - 3}</StyledPageNumber>,
                    <StyledPageNumber key={pageOptions.length - 3} onClick={() => gotoPage(pageOptions.length - 3)}>{pageOptions.length - 2}</StyledPageNumber>,
                    <StyledPageNumber key={pageOptions.length - 2} onClick={() => gotoPage(pageOptions.length - 2)}>{pageOptions.length - 1}</StyledPageNumber>
                )
            } else {
                pageIndex === pageOptions.length - 2 && pages.push(<StyledPageNumber key={pageIndex - 2} onClick={() => gotoPage(pageIndex - 2)}>{pageIndex - 1}</StyledPageNumber>)
                if (pageIndex - 1 !== 0) {
                    pages.push(<StyledPageNumber key={pageIndex - 1} onClick={() => gotoPage(pageIndex - 1)}>{pageIndex}</StyledPageNumber>)
                }
                pages.push(<StyledPageNumber active={true} key={pageIndex}>{pageIndex + 1}</StyledPageNumber>)
                if (pageIndex + 1 !== pageOptions.length - 1) {
                    pages.push(<StyledPageNumber key={pageIndex + 1} onClick={() => gotoPage(pageIndex + 1)}>{pageIndex + 2}</StyledPageNumber>)
                }
                pageIndex === 1 && pages.push(<StyledPageNumber key={pageIndex + 2} onClick={() => gotoPage(pageIndex + 2)}>{pageIndex + 3}</StyledPageNumber>)

            }
            pages.push(
                <StyledPageNumber active={pageOptions.length - 1 === pageIndex} key={pageOptions.length - 1} onClick={() => gotoPage(pageOptions.length - 1)}>
                    {pageIndex < pageOptions.length - 3 && "..."}{pageOptions.length}
                </StyledPageNumber>)
        }
        return pages;
    }

    const getPageEntriesStatus = () => {
        let entries;
        if (globalFilteredRows.length === 0) {
            entries = "0";
        } else {
            entries = `${(pageIndex) * entriesCount + 1}-${Math.min((pageIndex + 1) * entriesCount, globalFilteredRows.length)}`;
        }
        return localizer(summaryId ?? "", { entries, length: globalFilteredRows.length });
    }

    return (
        <>
            <HeaderContainer>
                {
                    props.headerItem ?
                        props.headerItem
                        :
                        <TableHeader>
                            {tableHeaderText}
                        </TableHeader>
                }
                <ButtonContainer>
                    <StyledPageSelectorContainer>
                        <DropdownButtonNew
                            height={36}
                            id="pageSize"
                            name={"pageSize"}
                            selectedValue={entriesCount}
                            handleChange={(_name, value) => {
                                setEntriesCount(Number(value));
                                setPageSize(Number(value));
                            }}
                            options={[{ label: "10", value: 10 }, { label: "25", value: 25 }, { label: "50", value: 50 }, { label: "100", value: 100 }]}
                        />

                        <ActionDescriptionContainer>
                            {localizer("constants.entries")}
                        </ActionDescriptionContainer>
                    </StyledPageSelectorContainer>
                    <div>
                        <StyledAddButton disabled={props.disableAddClick === true} onClick={props.addClick} sidesize={36} variant="primary" data-qa="add_user_button" />
                        <ActionDescriptionContainer>
                            {localizer("users.addButton.title")}
                        </ActionDescriptionContainer>
                    </div>
                </ButtonContainer>
            </HeaderContainer>
            <FilterContainer>
                <FilterInput
                    placeholder={localizer("constants.search")}
                    value={filterValue}
                    onChange={(value) => {
                        setFilterValue(value);
                        setGlobalFilter(value);
                    }}
                />
                <EntriesContainer>{getPageEntriesStatus()}</EntriesContainer>
            </FilterContainer>
            <Styles {...props.styleProps}>
                <div {...getTableProps()} className="table">
                    <div className="thead">
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className="tr"
                                key={"headerGroup-" + headerGroup.id}
                            >
                                {headerGroup.headers.map((column: any) => {
                                    return (
                                        <div
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(
                                                    getStyles
                                                )
                                            )}
                                            className="th"
                                            key={"header-" + column.id}
                                        >
                                            {column.Header.props && column.render("Header")}
                                            <SortingIndicatorContainer>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <SortingDown fill={Colors.white.clearWhite} />
                                                    ) : (
                                                        <SortingUp fill={Colors.white.clearWhite} />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </SortingIndicatorContainer>
                                            {column.enableFilter && (
                                                <span className="filter-cell">
                                                    {column.render("Filter")}
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                    <RowsContainer className="tbody">
                        <Flipper flipKey={page.map(row => row.id).join("")}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <Flipped key={"flipped-row-" + row.id} flipId={row.id}>
                                        <div
                                            {...row.getRowProps()}
                                            className={"tr"}
                                            key={"row-" + row.id}
                                        >
                                            {row.cells.map((cell: any) => {
                                                return (
                                                    <div
                                                        {...cell.getCellProps(
                                                            getStyles
                                                        )}
                                                        className="td"
                                                        key={row.id + "-" + cell.column.id}
                                                    >
                                                        {cell.render("Cell")}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Flipped>
                                );
                            })}
                        </Flipper>
                        {globalFilteredRows.length === 0 && (
                            <InfoContainer>
                                {localizer("table.noData")}
                            </InfoContainer>
                        )}
                    </RowsContainer>
                    <PageSelectorContainer>
                        <SingleLeft onClick={previousPage} fill={!canPreviousPage ? Colors.blue.darkDisabled : Colors.blue.brandingDark} />
                        {getPages()}
                        <SingleRight onClick={nextPage} fill={!canNextPage ? Colors.blue.darkDisabled : Colors.blue.brandingDark} />
                    </PageSelectorContainer>
                </div>
            </Styles>
            {data.length > 0 && <Tooltip />}
        </>
    );
};

export default PaginatedTable;
