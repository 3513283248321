import React from "react";
import { FormattedMessage } from "react-intl";
import GoTo from "../../common/table/formatters/GoTo";
import TimeFormatter from "./formatters/TimeFormatter";
import Table, { headerJustifyOptions } from "../../common/table/Table";
import { ITableProps } from "../ITableProps";
import HomeResidentFormatter from "./formatters/HomeResidentFormatter";
import TrendTimeFormatter from "./formatters/TrendTimeFormatter";
import WardFormatter from "./formatters/WardFormatter";

const ResponseTimeTable = (props: ITableProps) => {
    // 6h in minutes according to requirements
    const KpiMaxValuePerDay = 360;

    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id={"home.resident"} />,
                accessor: "name",
                Cell: ((props: any) => {
                    const percentage = Math.ceil((100 * props.row.original.statistics.responseTime.value) / KpiMaxValuePerDay)
                    return <HomeResidentFormatter {...props} barPercentage={percentage > 100 ? 100 : percentage} />
                }),
                headerAlignment: headerJustifyOptions.left,
                
                minWidth: 400,
            },
            {
                Header: <FormattedMessage id={"home.average"} />,
                accessor: "statistics.responseTime.value",
                Cell: TimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                minWidth: 150,
            },
            {
                Header: <FormattedMessage id={"home.trend"} />,
                accessor: "statistics.responseTime.trend",
                Cell: TrendTimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                minWidth: 150,
            },
            {
                Header: <FormattedMessage id={"home.night"} />,
                accessor: "statistics.responseTime.valueNight",
                Cell: TimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id={"home.day"} />,
                accessor: "statistics.responseTime.valueDay",
                Cell: TimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id={"home.ward"} />,
                accessor: "wardId",
                Cell: WardFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                minWidth: 230,
            },
            {
                Header: <FormattedMessage id={"general.tableHeader.goTo"} />,
                accessor: "id",
                Cell: GoTo,
                headerAlignment: headerJustifyOptions.right,
                minWidth: 90,
                flexShrink: 3,
                hideTooltipOnProd: true,
                disableSortBy: true
            }
        ],
        []
    );

    const data = React.useMemo(() => props.data, [props.data]);
    const sortBy =  React.useMemo(() => (
        [
            {
                id: "statistics.responseTime.value",
                desc: true
            },
            {
                id: "statistics.responseTime.trend",
                desc: true
            }
        ]
    ), []);

    return (
        <Table
            qaId={"home_table"}
            columns={columns}
            data={data}
            styleProps={{
                rowHeight: 50,
                headerHeight: 55,
                inverseColors: true,
                dynamicHeight: props.data.length <= 3
            }}
            initialState={{
                sortBy: sortBy
            }}
        />
    )
};

export default React.memo(ResponseTimeTable);
