import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { StyledInformationBlockWrapper } from "../View";

export enum EBlockSize {
    M = 270,
    L = 339
}

export const StyledDescription = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: ${Colors.grey.medium};
`;

export const StyledAmount = styled.div`
    width: 100%;
    font-size: 40px;
    line-height: 33px;
    margin-top: auto;
    text-transform: lowercase;
    color: ${Colors.black};

    span {
        font-size: 24px;
    }
`;

export const StyledTitle = styled.span<any>`
    font-size: 24px;
    line-height: 28px;
    ${props => props.isSelected ? `color: ${Colors.blue.brandingDark}; font-weight: 600;` : `color: ${Colors.black};`};
`;

export const ClickableStyledInformationBlockWrapper = styled(StyledInformationBlockWrapper)`
    :hover{
        cursor: pointer;
        box-shadow: -1px 4px 4px rgba(0, 0, 0, 0.15), 1px 3px 12px rgba(0, 0, 0, 0.15);
        transition: box-shadow .5s;
    }
    width: ${props => props.size}px;
`;

export const StyledTrendContainer = styled.div`
    float: right;
    font-size: 20px;
    height:45px;
`;

export const IconContainer = styled.div`
    margin: auto;
    width: 25%;
    height: 22px;
`;

export const StyledValueContainer = styled.div`
    float: left;
    height:47px;
    span {
        font-size: 24px !important;
        color: ${Colors.black} !important;
        position: relative;
        bottom: -15px;
    }
    align-self: self-end;
`;

export const StyledInformationRow = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: ${Colors.grey.tenaLight};
    padding: 16px 32px 16px 32px;
    margin-bottom: 24px;
`;

export const StyledInformationTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.blue.brandingDark}
`;

export const StyledInformationContent = styled.div`
    font-size: 16px;
    color: ${Colors.blue.brandingDark}
`;
