import React, { Component, ReactNode } from "react";
import { Axis } from "../../../models/Axis";

interface IAxisLabelProps {
    axisType: any;
    x: number;
    y: number;
    width: number;
    height: number;
    fill: string;
    children: ReactNode;
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default class AxisLabel extends Component<IAxisLabelProps, {}> {
    private RenderText = (child: any, x: number, y: number, rotate: any, fill: string, key?: any) => {
        if (child && child.content) {
            return (
                <text
                    key={key}
                    x={x}
                    y={y}
                    transform={`rotate(${rotate})`}
                    textAnchor="middle"
                    fill={fill}
                    fontSize={14}
                    {...child.props}
                >
                    {child.content}
                </text>
            );
        }

        return (
            <text
                key={key}
                x={x}
                y={y}
                transform={`rotate(${rotate})`}
                textAnchor="middle"
                fill={fill}
                fontSize={14}
            >
                {child}
            </text>
        );
    };


    private GetAxis = (axisType: any, x: number, y: number, width: number, height: number): Axis => {
        return new Axis(axisType, x, y, width, height);
    };

    render(): React.ReactElement {
        const axis = this.GetAxis(this.props.axisType, this.props.x, this.props.y, this.props.width, this.props.height);
        return this.RenderText(this.props.children, axis.cx, axis.cy, axis.rot, this.props.fill);
    }
}
