import { useState } from "react";
import { Button, Row } from "react-bootstrap";
import styled from "styled-components";
import { AuthRestClient } from "../../../data/RestClient";
import ErrorCodes from "../../../models/ErrorCodes";
import { TwoFAValidationCodeLength } from "../../../settings/Settings";
import { localizer } from "../../../utils/Localizer";
import { toastError, toastLogoutInfo } from "../../../utils/Toaster";
import TextFieldNew from "../../common/controls/TextFieldNew";
import ValidatedFormGroupWrapper, {
    IErrors
} from "../../wrappers/ValidatedFormGroupWrapper";

import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { fetchConfiguration } from "../../../reducers/configurationSlice";
import {
    fetchResources,
    setInactiveTimeInMinutesBeforeLogout
} from "../../../reducers/contextSlice";
import ToastMessages from "../../../models/ToastMessages";
import Colors from "../../../assets/Colors";

const StyledHeader = styled.h5`
    font-weight: 600;
    color: ${Colors.blue.brandingDark};
    text-align: center;
`;

const StyledTwoFactorAuthForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 360px;
`;

const StyledSubmitButton = styled(Button)`
    height: 50px;
    flex: 1;
`;

const StyledCancelButton = styled(Button)`
    height: 50px;
    flex: 1;
    margin-right: 34px;
`;

const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 26px;
`;

enum Fields {
    VerificationCode = "VerificationCode",
}

const TwoFactorAuthForm = (props: any) => {
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [validationErrors, setErrors] = useState<IErrors[]>([]);
    const dispatch = useAppDispatch();

    const goToLogin = (event?: any) => {
        event?.preventDefault();
        props.onBack();
    };

    const validate = (response: any): Promise<boolean> => {
        const errors: IErrors[] = [];
        const { remainingAttempts, isCodeValid } = response;
        if (!isCodeValid) {
            if (remainingAttempts > 0) {
                errors.push({
                    dependentErrorCode: ErrorCodes.WrongValidationCode,
                    values: { remainingAttempts },
                });
            } else {
                goToLogin();
                toastLogoutInfo(localizer(ToastMessages.TooManyVerificationCodeAttempts));
            }
        }
        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        try {
            const response = await AuthRestClient.verifyCode(
                props.email,
                props.password,
                verificationCode
            );
            if (response && response.sessionTimeoutMinutes) {
                dispatch(
                    setInactiveTimeInMinutesBeforeLogout(
                        response.sessionTimeoutMinutes
                    )
                );
                dispatch(fetchConfiguration());
                dispatch(fetchResources());
                sessionStorage.removeItem("blockFetchUserResources");
            } else {
                const isValid = await validate(response);
                if (!isValid) {
                    setVerificationCode("");
                    return;
                }
            }
        } catch (error: any) {
            toastError(localizer(error));
        }
    };

    const handleChange = (verificationCode: string, value: any) => {
        switch (verificationCode) {
            case Fields.VerificationCode:
                setVerificationCode(value);
                break;
        }
    };

    return (
        <>
            <StyledTwoFactorAuthForm autoComplete="false">
                <>
                    <StyledHeader>
                        {localizer("twoFactorAuthForm.title")}
                    </StyledHeader>
                    <Row className="row g-0 mt-3">
                        <p>
                            {!props.mobile && <FormattedMessage
                                id={"twoFactorAuthForm.descriptionEmail"}
                            />
                            }
                            {props.mobile && <FormattedMessage
                                id={"twoFactorAuthForm.descriptionMobile"}
                                values={{ mobile: props.mobile }}
                            />
                            }
                        </p>
                    </Row>
                    <Row className="row g-0 mt-3">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.WrongValidationCode,
                                ErrorCodes.ExpiredValidationCode,
                            ]}
                            fullWidthMessage
                        >
                            <TextFieldNew
                                data-qa="2fa_code_input_text"
                                fieldName={Fields.VerificationCode}
                                disabled={false}
                                placeholder={localizer("twoFactorAuthForm.code")}
                                value={verificationCode}
                                onChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="row g-0">
                        <StyledButtonContainer>
                            <StyledCancelButton
                                data-qa="2fa_cancel_button"
                                variant={"outline-secondary"}
                                onClick={goToLogin}
                            >
                                <FormattedMessage id="twoFactorAuthForm.cancel" />
                            </StyledCancelButton>
                            <StyledSubmitButton
                                data-qa="2fa_submit_button"
                                disabled={verificationCode.length !== TwoFAValidationCodeLength}
                                type="submit"
                                onClick={onSave}
                            >
                                <FormattedMessage id="twoFactorAuthForm.submit" />
                            </StyledSubmitButton>
                        </StyledButtonContainer>
                    </Row>
                </>
            </StyledTwoFactorAuthForm>
        </>
    );
};

export default TwoFactorAuthForm;
