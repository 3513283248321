import React, { SVGProps } from "react";

interface IWirelessIconProps {
    color: string;
}

const WirelessIcon: React.FunctionComponent<
    SVGProps<any> & IWirelessIconProps
> = (props) => {
    const { color } = props;
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx="12"
                cy="12"
                r="11"
                transform="rotate(45 12 12)"
                fill={color}
            />
            <path
                d="M10.2212 15.9143C10.8123 15.3233 10.814 14.3667 10.2251 13.7778C9.63616 13.1888 8.67961 13.1906 8.08856 13.7816C7.49751 14.3727 7.49579 15.3292 8.08471 15.9181C8.67364 16.5071 9.63019 16.5053 10.2212 15.9143Z"
                fill="white"
            />
            <path
                d="M9.39016 5.42641C8.86972 5.42735 8.44439 5.85268 8.42973 6.38683C8.4288 6.90727 8.8526 7.33107 9.3867 7.34381C11.3315 7.3403 13.1517 8.09029 14.5324 9.47106C15.8995 10.8382 16.6631 12.6994 16.646 14.6031C16.6451 15.1236 17.0552 15.5611 17.5893 15.5738C17.8632 15.5733 18.0962 15.477 18.2745 15.2987C18.4392 15.134 18.5492 14.8873 18.5634 14.6271C18.5951 12.2166 17.6133 9.8353 15.9044 8.12642C14.1545 6.37653 11.8554 5.42197 9.39016 5.42641Z"
                fill="white"
            />
            <path
                d="M9.16453 9.47961C8.6441 9.48055 8.21877 9.90588 8.20411 10.44C8.20317 10.9605 8.62697 11.3843 9.16108 11.397C10.0924 11.3953 10.9545 11.7636 11.5971 12.4061C12.2396 13.0486 12.5941 13.9245 12.6062 14.8421C12.6052 15.3625 13.029 15.7863 13.5632 15.7991C13.8371 15.7986 14.0564 15.6886 14.2348 15.5103C14.4131 15.3319 14.5094 15.0989 14.5236 14.8387C14.5261 13.4143 13.9671 12.0594 12.9554 11.0478C11.9437 10.0361 10.6026 9.49072 9.16453 9.47961Z"
                fill="white"
            />
        </svg>
    );
};

export default WirelessIcon;
