import React, { SVGProps } from "react";

interface IWarningIconProps {
    color: string;
}

const WarningIcon: React.FunctionComponent<SVGProps<any> & IWarningIconProps> =
    (props) => {
        const { color } = props;
        return (
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M2.70476 18H19.2952C20.9917 18 22.0493 16.2325 21.2011 14.8249L12.9058 1.05571C12.0576 -0.351904 9.94244 -0.351904 9.09419 1.05571L0.798949 14.8249C-0.0493021 16.2325 1.00826 18 2.70476 18ZM11 10.5915C10.3941 10.5915 9.89837 10.1152 9.89837 9.53315V7.41643C9.89837 6.83434 10.3941 6.35808 11 6.35808C11.6059 6.35808 12.1016 6.83434 12.1016 7.41643V9.53315C12.1016 10.1152 11.6059 10.5915 11 10.5915ZM12.1016 14.8249H9.89837V12.7082H12.1016V14.8249Z"
                    fill={color}
                />
            </svg>
        );
    };

export default WarningIcon;
