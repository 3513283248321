import { isProdEnv } from '../utils/EnvCheck';

export const getConsentNoticeScripts = () => {
    const scripts = [];
    const isProdEnviroment = isProdEnv();

    const autoBlockScript = document.createElement('script');
    autoBlockScript.src = isProdEnviroment ?
        "https://cdn-ukwest.onetrust.com/consent/fe9259f3-3674-4bbe-ae0e-1a4c43cb5278/OtAutoBlock.js" :
        "https://cdn-ukwest.onetrust.com/consent/fe9259f3-3674-4bbe-ae0e-1a4c43cb5278-test/OtAutoBlock.js";
    autoBlockScript.type = "text/javascript";

    scripts.push(autoBlockScript);

    const sdkStubScript = document.createElement('script');
    sdkStubScript.src = "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js";
    sdkStubScript.type = "text/javascript";
    sdkStubScript.setAttribute("data-document-language", "true");
    sdkStubScript.setAttribute("charset", "UTF-8");
    sdkStubScript.setAttribute("data-domain-script", isProdEnviroment ? "fe9259f3-3674-4bbe-ae0e-1a4c43cb5278" : "fe9259f3-3674-4bbe-ae0e-1a4c43cb5278-test");
    scripts.push(sdkStubScript);

    const buttonGroupScript = document.createElement("script");
    buttonGroupScript.type = "text/javascript";
    buttonGroupScript.onload = () => {
        const cookieSettingsBtn = document.getElementById("onetrust-pc-btn-handler");
        const acceptBtn = document.getElementById("onetrust-accept-btn-handler");
        const declineBtn = document.querySelector("button#onetrust-reject-all-handler");
        const btnContainer = document.getElementById("onetrust-button-group");
        btnContainer?.insertBefore(acceptBtn!, cookieSettingsBtn);
        btnContainer?.insertBefore(declineBtn!, cookieSettingsBtn);
    };

    scripts.push(buttonGroupScript);

    return scripts;
};

