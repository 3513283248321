import { useNavigate } from "react-router-dom";
import { appInsights } from "../../../../AppInsights";
import { ETrackEvent } from "../../../../utils/AppInsightsHelpers";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { routePaths } from "../../../../utils/PathHelpers";

const ResidentIcon = (props: any) => {
    const { value } = props;
    const { hideTooltipOnProd } = props.column;
    const { id } = props.row.original;
    const navigate = useNavigate();

    return (
        <StyledLeft data-qa={value + "-table_item"}>
            <TextOverflowWrapper
                onClick={() => {
                    appInsights.trackEvent({ name: ETrackEvent.JumpToResidentViewFromName });
                    navigate(`${routePaths.careRecipients}/${id}`);
                }}
                tooltip={value}
                hideTooltipOnProd={hideTooltipOnProd}
            >
                {value}
            </TextOverflowWrapper>
        </StyledLeft>
    );
}

export default ResidentIcon;
