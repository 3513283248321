import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';

import { StyledAddButton } from "../../styled";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import AddEditWardForm from "./AddEditWardForm";
interface IAddWardButtonState {
    isModalDisplayed: boolean;
}

interface IProps {
    disabled?: boolean
}
// eslint-disable-next-line @typescript-eslint/ban-types
export default class AddWardButton extends Component<IProps, IAddWardButtonState> {
    render(): React.ReactElement {

        const {disabled = false} = this.props

        if(disabled) {
            return (
                <StyledAddButton disabled />
            )
        }

        const triggerComponent: React.ReactNode = (
            <StyledAddButton variant="primary" />
        );

        return (
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={<AddEditWardForm
                    entity={undefined}
                />}
                modalTitle={<FormattedMessage id="setup.addWardPopup.modalTitle"></FormattedMessage>}
                size={EModalSize.S}
            />
        );
    }
}
