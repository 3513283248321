import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { appInsights } from "../../../../AppInsights";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
    fetchExternalUsers,
    IUserStateType,
} from "../../../../reducers/userSlice";
import { ETrackEvent } from "../../../../utils/AppInsightsHelpers";
import { localizer } from "../../../../utils/Localizer";
import ArrayFormatter from "../../../common/table/formatters/ArrayFormatter";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import { headerJustifyOptions } from "../../../common/table/Table";
import { StyledViewContainer } from "../../../common/View";
import StatusFormatter from "../common/tables/formatters/StatusFormatter";
import UserActions from "../common/tables/formatters/UserActions";
import UserNameFormatter from "../common/tables/formatters/UserNameFormatter";
import UsersTable from "../users/tables/UsersTable";
import { IContextStateType } from "../../../../reducers/contextSlice";

const ExternalUserView = () => {
    const dispatch = useAppDispatch();
    const { users } = useAppSelector<IUserStateType>((state) => state.userSlice);
    const {
        id
    } = useAppSelector<IContextStateType>((state) => state.contextSlice);

    useEffect(() => {
        if(id) {
            dispatch(fetchExternalUsers(Number(id)));
        } else {
            dispatch(fetchExternalUsers());
        }

        appInsights.trackEvent({ name: ETrackEvent.UsersPage });
    }, [dispatch, id]);

    const translatedRolesUsers = users.map((user) => {
        const translatedRole = localizer("user.role." + user.role);
        return { ...user, translatedRole: translatedRole };
    });

    const columns =
        [
            {
                Header: <FormattedMessage id="users.tableHeader.usernameEmail" />,
                accessor: "name",
                sortType: "basic",
                Cell: UserNameFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 4,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.role" />,
                accessor: "translatedRole",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.careFacility" />,
                headerAlignment: headerJustifyOptions.left,
                accessor: "careFacilities",
                Cell: ArrayFormatter,
                disableSortBy: true,
                flexGrow: 3,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.lastAccess" />,
                accessor: "lastLogin",
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
                disableGlobalFilter: true
            },
            {
                Header: <FormattedMessage id="users.tableHeader.status" />,
                accessor: "status",
                Cell: StatusFormatter,
                sortType: "basic",
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
            },
            {
                Header: <></>,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: UserActions,
                disableSortBy: true,
                disableGlobalFilter: true
            }
        ];

    return (
        <StyledViewContainer>
            <UsersTable
                columns={columns}
                data={translatedRolesUsers}
                paginated={true}
            />
        </StyledViewContainer>
    );
};

export default ExternalUserView;
