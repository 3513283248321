import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import RoutesGenerator from "./routes/RoutesGenerator";
import Tooltip from "./components/common/tooltip/Tooltip";
import AppRepository, { AppContext } from "./data/AppRepository";
import { RestClient } from "./data/RestClient";
import SessionHandler from "./data/SessionHandler";
import { fetchResources, IContextStateType } from "./reducers/contextSlice";
import { fetchConfiguration, setCookieScripExecution, setReleaseNotification } from "./reducers/configurationSlice";
import { spinnerKeys } from "./reducers/spinnerSlice";
import { getConsentNoticeScripts } from "./scripts/CookieScripts";
import { convertLangCode, convertLocale, getLocale, languageCookieName, localizations } from "./utils/Localizer";
import { toastError } from "./utils/Toaster";
import { BlockingSpinner } from "./views/BlockingSpinner/BlockingSpinner";
import Footer from "./views/Footer";
import MaintenanceBanner from "./views/MaintenanceBanner";
import TitleBar from "./views/TitleBar";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from "./AppInsights";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAppSelector } from "./hooks/useAppSelector";
import { BrowserRouter as Router } from "react-router-dom";

const StyledApp = styled.div`
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyledContentWrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
`;

const AuthenticationProvider = () => {
    const { isAuthenticating } = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const dispatch = useAppDispatch();
    const blockFetchUserResources = sessionStorage.getItem("blockFetchUserResources") === "true";
    useEffect(() => {
        if (!blockFetchUserResources) {
            dispatch(fetchConfiguration());
            dispatch(fetchResources(true));
        }
    }, [dispatch, blockFetchUserResources]);

    return isAuthenticating ? null : <RoutesGenerator />;
};


const App = () => {
    const [cookies] = useCookies();
    const dispatch = useAppDispatch();
    const { inactiveTimeInMinutesBeforeLogout, isAuthenticated } = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const isSpinnerVisible = useAppSelector<boolean>(
        (state) => state.spinnerSlice.activeSpinners.some((activeSpinner) => activeSpinner === spinnerKeys.global)
    );

    const locale = getLocale(cookies[languageCookieName]);

    useEffect(() => {
        const fetchData = async () => {
            const { enableCookieScriptExecution, releaseNotification } = await RestClient.getConfiguration();
            dispatch(setCookieScripExecution(enableCookieScriptExecution));
            dispatch(setReleaseNotification(releaseNotification));
            if (enableCookieScriptExecution) {
                const cookiesScripts = getConsentNoticeScripts();
                cookiesScripts.forEach(script => document.head.appendChild(script));
                document.documentElement.lang = convertLocale(locale);
            }
        };
        fetchData().catch((error: any) =>
            toastError(error.message || error)
        );
    }, []);

    return (
        <IntlProvider
            key={locale}
            locale={locale}
            messages={localizations[convertLangCode(locale)]}
        >
            <Tooltip />
            <ToastContainer enableMultiContainer={false} />
            <BlockingSpinner isVisible={isSpinnerVisible} />
            <AppRepository>
                <AppContext.Consumer>
                    {() => (
                        <>
                            {isAuthenticated && (
                                <SessionHandler
                                    inactivityLimitMinutes={
                                        inactiveTimeInMinutesBeforeLogout
                                    }
                                />
                            )}
                            <StyledApp>
                                <Router>
                                    <TitleBar />
                                    <MaintenanceBanner />
                                    <StyledContentWrapper>
                                        <AuthenticationProvider />
                                    </StyledContentWrapper>
                                </Router>
                            </StyledApp>
                        </>
                    )}
                </AppContext.Consumer>
            </AppRepository>
            <Footer />
        </IntlProvider>
    );
};

export default withAITracking(reactPlugin, App);
