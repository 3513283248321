import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import CareFacilityAddEditForm from "../components/themed/nursingHomes/CareFacilityAddEditForm";
import { PageHeader, StyledViewContainer } from "../components/common/View";
import { localizer } from "../utils/Localizer";

const AddCareFacilityView = () => {

    return (
        <StyledViewContainer>
            <PageHeader>{localizer("careFacility.add")}</PageHeader>
            <CareFacilityAddEditForm
                entity={undefined}
            />
        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, AddCareFacilityView);