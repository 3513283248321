
import { MarketCode } from "./MarketCode";

export default class Market {
    name: string;
    code: MarketCode
    constructor(json: any = {}) {
        this.name = json.name;
        this.code = json.code;
    }

    newInstance(): Market {
        return Object.create(this);
    }

    static sort(markets: Market[]): Market[] {
        markets.sort(((a, b) => {
            if (a.name.toLowerCase() === b.name.toLowerCase()) return 0;
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        }));
        return markets;
    }
}