import React from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Resident } from "../../../models/Resident";
import { ResidentEventType } from "../../../models/ResidentEventType";
import { TransmitterError } from "../../../models/TransmitterError";
import { Ward } from "../../../models/Ward";
import { noBatteryIconList } from "../../../settings/Settings";
import GoTo from "../../common/table/formatters/GoTo";
import Table, {
    headerJustifyOptions
} from "../../common/table/Table";
import BatteryIcon from "./CellFormatters/BatteryIcon";
import MonitoringEditor from "./CellFormatters/MonitoringEditor";
import MonitoringSetup from "./CellFormatters/MonitoringSetup";
import ResidentIcon from "./CellFormatters/ResidentIcon";
import ResidentStatus from "./CellFormatters/ResidentStatus";
import WardName from "./CellFormatters/WardName";

const getStatusSortValue = (resident: Resident) => {
    if (
        resident.bedStatus === ResidentEventType.Inactive &&
        resident.transmitterError === TransmitterError.NoTransmitter
    ) {
        return 7;
    }

    switch (resident.bedStatus) {
        case ResidentEventType.Saturated:
            return 1;
        case ResidentEventType.Error:
            return 2;
        case ResidentEventType.Connecting:
            return 3;
        case ResidentEventType.SomeUrine:
            return 4;
        case ResidentEventType.OK:
            return 5;
        case ResidentEventType.NotBeingMonitored:
            return 6;
        case ResidentEventType.Inactive:
            return 9; // Inactive should be always at the bottom
        default:
            return 8;
    }
};

const BedStatusSort = (a: any, b: any) => {
    const dateSort = b.original.statusUpdatedTime > a.original.statusUpdatedTime ? 1 : ((a.original.statusUpdatedTime > b.original.statusUpdatedTime ? -1 : 0));
    return getStatusSortValue(b.original) - getStatusSortValue(a.original) || dateSort;
};

interface IProps {
    selectedWards: Ward[];
}

const MonitoringTable = (props: IProps) => {
    const residents = useAppSelector<Resident[]>(
        (state) => state.residentSlice.residents
    ).filter((resident) =>
        props.selectedWards.map((ward) => ward.id).includes(resident.wardId)
    );
    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.residents" />
                ),
                accessor: "name",
                Cell: ResidentIcon,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: false,
                maxWidth: 250
            },
            {
                Header: <FormattedMessage id="residents.tableHeader.status" />,
                accessor: "bedStatus",
                Cell: ResidentStatus,
                sortType: BedStatusSort,
                headerAlignment: headerJustifyOptions.left,
                minWidth: 230,
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.monitoringSetup" />
                ),
                accessor: "monitoringSetup",
                disableSortBy: true,
                Cell: MonitoringSetup,
                headerAlignment: headerJustifyOptions.left,
                minWidth: 220,
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.monitoring" />
                ),
                accessor: "nightTimeSetup",
                disableSortBy: true,
                Cell: MonitoringEditor,
                headerAlignment: headerJustifyOptions.left,
                minWidth: 170,
            },
            {
                Header: (
                    <FormattedMessage id="residents.informationBlocks.title.ward" />
                ),
                accessor: "wardId",
                disableSortBy: true,
                Cell: WardName,
                flexGrow: 7,
                headerAlignment: headerJustifyOptions.left,
            },
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.batteryLevel" />
                ),
                accessor: "isBatteryFlat",
                Cell: (props: any) => {
                    const bedStatus = props.cell.row.original.bedStatus;
                    const showBatteryIcon = !noBatteryIconList.some(eventType => bedStatus === eventType);
                    if (showBatteryIcon && props.cell.row.values.isBatteryFlat) {
                        return <BatteryIcon isBatteryFlat />;
                    }
                    return <></>;
                },
                disableSortBy: true,
                maxWidth: 70
            },
            {
                Header: <FormattedMessage id="general.tableHeader.goTo" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: GoTo,
                disableSortBy: true,
                width: 100
            },
        ],
        []
    );
    const data = React.useMemo(() => residents || [], [residents]);
    return (
        <Table
            qaId={"monitoring_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "bedStatus",
                        desc: true,
                    },
                ],
            }}
        />
    );
};

export default React.memo(MonitoringTable);
