import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { reactPlugin } from "../AppInsights";
import { StyledViewContainer } from "../components/common/View";
import CheckBox from "../components/common/controls/CheckBox";
import FilterInput from "../components/common/controls/FilterInput";
import { LocalStorageKeys } from "../data/Persistence";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { Roles } from "../models/Role";
import {
    IContextStateType,
    IUserResourcesHierarchy,
    contextChange,
    setSelectedResourceName,
} from "../reducers/contextSlice";
import { ResourceTypes } from "../reducers/userSlice";
import { getLocalizedCountry, localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { CareFacilityIconContainer, CareFacilityName, CareFacilityView, CheckBoxContainer, CountryNameContainer, FacilitiesViewContainer, FilterContainer, HeaderContainer, NursingHomeGroupNameContainer, StyledCareFacilityIcon } from "./ContextSelectionViewStyle";

interface IResourceContainerProps {
    id: string;
    name: string;
    childNodes: IUserResourcesHierarchy[];
    handleOptionSelect: (name: string, id: string, role?: Roles) => void;
    showCareGroups?: boolean;
    filterValue: string;
}

const NursingHomeGroupContainer = (props: IResourceContainerProps) => {
    const nursingHomes = props.childNodes.map((nursingHome: IUserResourcesHierarchy) => (
        <CareFacilityView key={"careFacility-" + nursingHome.id} onClick={() => props.handleOptionSelect(nursingHome.name, nursingHome.id.toString(), nursingHome.isSupportUser ? Roles.Support : undefined)}>
            <CareFacilityIconContainer>
                <StyledCareFacilityIcon />
            </CareFacilityIconContainer>
            <CareFacilityName>
                {nursingHome.name}
            </CareFacilityName>
        </CareFacilityView>
    ));
    return nursingHomes.length > 0 ? (
        <div key={props.id}>
            <NursingHomeGroupNameContainer>{props.name}</NursingHomeGroupNameContainer>
            <FacilitiesViewContainer>
                {nursingHomes}
            </FacilitiesViewContainer>
        </div>) : <></>

};

const CountryContainer = (props: IResourceContainerProps) => {
    const grouplessNh: IUserResourcesHierarchy[] = [];
    const entries: JSX.Element[] = [];
    props.childNodes.forEach((userResource: IUserResourcesHierarchy) => {
        if (userResource.type === ResourceTypes.NursingHome) {
            grouplessNh.push(userResource);
        } else {
            entries.push(
                <NursingHomeGroupContainer
                    id={userResource.id.toString()}
                    name={userResource.name}
                    key={"careGroup-" + userResource.id}
                    childNodes={userResource.children}
                    handleOptionSelect={props.handleOptionSelect}
                    filterValue={props.filterValue}
                />
            )
        }
    });
    return (
        <div key={props.id}>
            <CountryNameContainer>
                {getLocalizedCountry(props.name)}
            </CountryNameContainer>
            {entries}
            {props.showCareGroups ? (<NursingHomeGroupContainer
                id={"othersId"}
                name={localizer("overview.othersCareGroup")}
                key={"others-" + props.id}
                childNodes={grouplessNh}
                handleOptionSelect={props.handleOptionSelect}
                filterValue={props.filterValue}
            />) : (
                <FacilitiesViewContainer>
                    {grouplessNh.map((nursingHome: IUserResourcesHierarchy) => (
                        <CareFacilityView key={"careFacility-" + nursingHome.id} onClick={() => props.handleOptionSelect(nursingHome.name, nursingHome.id.toString(), nursingHome.isSupportUser ? Roles.Support : undefined)}>
                            <CareFacilityIconContainer>
                                <StyledCareFacilityIcon />
                            </CareFacilityIconContainer>
                            <CareFacilityName>
                                {nursingHome.name}
                            </CareFacilityName>
                        </CareFacilityView>
                    ))}
                </FacilitiesViewContainer>
            )}
        </div>)
};

const handleFilterChange = (filteredResources: any[], userResources: IUserResourcesHierarchy[], showCountries: boolean, showCareGroups: boolean, filterValue?: string): IUserResourcesHierarchy[] => {
    userResources.forEach((userResource: IUserResourcesHierarchy) => {
        if (userResource.type === ResourceTypes.Country) {
            if (showCountries) {
                filteredResources.push(
                    {
                        ...userResource,
                        children: handleFilterChange([], userResource.children, showCountries, showCareGroups, filterValue)
                    });
            } else {
                handleFilterChange(filteredResources, userResource.children, showCountries, showCareGroups, filterValue);
            }
        } else if (userResource.type === ResourceTypes.NursingHomeGroup) {
            if (showCareGroups) {
                filteredResources.push(
                    {
                        ...userResource,
                        children: handleFilterChange([], userResource.children, showCountries, showCareGroups, filterValue)
                    }
                );
            } else {
                handleFilterChange(filteredResources, userResource.children, showCountries, showCareGroups, filterValue);
            }
        } else {
            if (filterValue) {
                if (userResource.name.toLowerCase().includes(filterValue.toLowerCase())) {
                    filteredResources.push(userResource)
                }
            } else {
                filteredResources.push(userResource)
            }
        }
    });
    return filteredResources;
}

const ContextSelectionView = () => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const [filterValue, setFilterValue] = useState<string>("");
    const [showCareGroups, setShowCareGroups] = useState<boolean>(true);
    const [showCountries, setShowCountries] = useState<boolean>(true);
    const [filteredResources, setFilteredResources] = useState<IUserResourcesHierarchy[]>([]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const changeContext = (name: string, id: string, role: Roles) => {
        reactLocalStorage.clear();
        reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
            id,
            role,
        });
        dispatch(contextChange(id, role));
        dispatch(setSelectedResourceName(name));

        if ([Roles.GlobalAdmin, Roles.CountryAdmin].includes(context.role)) {
            navigate(routePaths.careFacility);
        } else {
            navigate(routePaths.home);
        }
    }

    const handleOptionSelect = (name: string, id: string, role?: Roles) => {
        changeContext(name, id, role ? role : context.role);
    };

    const { userResourcesHierarchy } = context;
    const entries: JSX.Element[] = [];
    const grouplessNh: IUserResourcesHierarchy[] = [];

    useEffect(() => {
        const filtered = handleFilterChange([], userResourcesHierarchy, showCountries, showCareGroups, filterValue);
        setFilteredResources(filtered);
    }, [showCareGroups, showCountries, userResourcesHierarchy, filterValue]);

    filteredResources.forEach((userResource: IUserResourcesHierarchy) => {
        if (userResource.type === ResourceTypes.Country) {
            entries.push(
                <CountryContainer
                    id={userResource.id.toString()}
                    name={userResource.name}
                    key={"country-" + userResource.id}
                    childNodes={userResource.children}
                    handleOptionSelect={handleOptionSelect}
                    showCareGroups={showCareGroups}
                    filterValue={filterValue}
                />
            )
        } else if (userResource.type === ResourceTypes.NursingHome) {
            grouplessNh.push(userResource);
        } else if (userResource.type === ResourceTypes.NursingHomeGroup) {
            entries.push(
                <NursingHomeGroupContainer
                    id={userResource.id.toString()}
                    name={userResource.name}
                    key={"careGroup-" + userResource.id}
                    childNodes={userResource.children}
                    handleOptionSelect={handleOptionSelect}
                    filterValue={filterValue}
                />
            )
        }
    });

    const getGrouplessNh = () => {
        if (grouplessNh.length > 0) {
            if (showCareGroups) {
                return <NursingHomeGroupContainer
                    id={"others-00"}
                    name={localizer("overview.othersCareGroup")}
                    key={"others-00"}
                    childNodes={grouplessNh}
                    handleOptionSelect={handleOptionSelect}
                    filterValue={filterValue}
                />;
            } else {
                return (
                    <FacilitiesViewContainer>
                        {grouplessNh.map((nh: IUserResourcesHierarchy) => {
                            const role = nh.isSupportUser ? Roles.Support : context.role;
                            return (
                                <CareFacilityView key={"careFacility-" + nh.id} onClick={() => handleOptionSelect(nh.name, nh.id.toString(), role)}>
                                    <CareFacilityIconContainer>
                                        <StyledCareFacilityIcon />
                                    </CareFacilityIconContainer>
                                    <CareFacilityName>
                                        {nh.name}
                                    </CareFacilityName>
                                </CareFacilityView>
                            )
                        })}
                    </FacilitiesViewContainer>
                );
            }
        }
        return
    }

    return (
        <StyledViewContainer>
            <HeaderContainer>
                {localizer("overview.selectCareFacility")}
            </HeaderContainer>
            <FilterContainer>
                <FilterInput
                    placeholder={localizer("constants.search")}
                    onChange={setFilterValue}
                    value={filterValue}
                />
                <CheckBoxContainer>
                    <CheckBox
                        label={localizer("overview.showCareGroups")}
                        value={showCareGroups}
                        onChange={(_name, value) => setShowCareGroups(value)}
                        fieldName="showCareGroups"
                    />
                    {(context.role === Roles.CountryAdmin || context.role === Roles.GlobalAdmin) && <CheckBox
                        label={localizer("overview.showCountries")}
                        value={showCountries}
                        onChange={(_name, value) => setShowCountries(value)}
                        fieldName="showCountries"
                    />}
                </CheckBoxContainer>
            </FilterContainer>
            {entries}
            {getGrouplessNh()}
        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, ContextSelectionView);
