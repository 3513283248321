import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';
import { ResidentEventType } from '../../../../../models/ResidentEventType';
import { localizer } from '../../../../../utils/Localizer';
import { TransmitterError } from '../../../../../models/TransmitterError';
import styled from 'styled-components';

const StyledContainer = styled(StyledLeft)<any>`
    align-items: ${(props) => props.alignItems ? props.alignItems : ''};
`;

const CellFormatter = (props: any) => {
    const { bedStatus, transmitterError } = props.data.row.original;
    const getEventDescription = (): string => {
        switch (bedStatus) {
            case ResidentEventType.Saturated:
                return localizer('residentHistory.description.saturated');
            case ResidentEventType.SomeUrine:
                return localizer('residentHistory.description.someUrine');
            case ResidentEventType.Error:
                return getErrorEventDescription();
            case ResidentEventType.OK:
                return localizer('residentHistory.description.ok');
            case ResidentEventType.Connecting:
                return localizer('residentHistory.description.connecting');
            case ResidentEventType.Inactive:
                return transmitterError === TransmitterError.NoTransmitter
                    ? localizer('residentHistory.description.error.noTransmitter')
                    : localizer('residentHistory.description.inactive');
            case ResidentEventType.NotBeingMonitored:
                if (transmitterError === TransmitterError.StripDetachedFromBrief) {
                    return localizer('residentHistory.description.error.stripDetachedFromBrief');
                }
                return localizer('residentHistory.description.notBeingMonitored');
            default:
                return "";
        }
    };

    const getErrorEventDescription = (): string => {
        switch (transmitterError) {
            case TransmitterError.SignalLost:
                return localizer('residentHistory.description.error.signalLost');
            case TransmitterError.StripDetachedFromTransmitter:
            case TransmitterError.CalculationError:
                return localizer('residentHistory.description.error.checkChangeIndicator');
            default:
                return localizer('residentHistory.description.error');
        }
    };

    return (
        <StyledContainer data-testid={"event-description-formatter"} alignItems={props.data.column.cellAlignItems}>
            {getEventDescription()}
        </StyledContainer>
    );
};

const EventDescriptionFormatter = (data: any) => <CellFormatter data={data} />;
export default EventDescriptionFormatter;




