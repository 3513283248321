import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ChangeIndicator } from "../assets/images/changeIndicator.svg";
import { ReactComponent as SmartcareLogo } from "../assets/images/smartCareLogo.svg";
import { ReactComponent as TenaLogo } from "../assets/images/tenaLogo.svg";
import { mobileThresholdWidth } from "../settings/Settings";
import { routePaths } from "../utils/PathHelpers";

const StyledLogoTitleContainer = styled.div`
    align-self: flex-start;
    display: flex;
    margin-bottom: 14px;
    flex-grow: 1;
`;

const StyledTenaLogo = styled(TenaLogo)`
    margin-left: -32px;
    float: left;
    height: 89px;
    width: 470px;

    @media (max-width: ${mobileThresholdWidth}px) {
        width: 291px;
        height: 54px;
        margin-left: -82px;
    }
`;

const StyledSmartcareLogo = styled(SmartcareLogo)`
    margin-left: -100px;
    height: 60px;
    margin-top: 20px;
    width: 168px;
`;

const StyledChangeIndicator = styled(ChangeIndicator)`
    vertical-align: middle;
    margin-left: -70px;
    margin-top: -10px;
`;

const SmartcareCIContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: ${mobileThresholdWidth}px) {
        display: none;
    }
`;

const LogoAndTitle = () => {
    const location = useLocation();

    return (<StyledLogoTitleContainer>
        <StyledTenaLogo />
        <SmartcareCIContainer>
            <StyledSmartcareLogo />
            {![routePaths.resetPassword, routePaths.activateAccount].includes(location.pathname) && (<StyledChangeIndicator />)}
        </SmartcareCIContainer>
    </StyledLogoTitleContainer>);
}


export default LogoAndTitle;
