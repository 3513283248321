export enum Roles {
    GlobalAdmin = "GlobalAdmin",
    CountryAdmin = "CountryAdmin",
    NHManager = "NHManager",
    Nurse = "Nurse",
    Caregiver = "Caregiver",
    Support = "Support",
    Invalid = "Invalid",
}

export type Role =
    Roles.GlobalAdmin
    | Roles.CountryAdmin
    | Roles.NHManager
    | Roles.Nurse
    | Roles.Caregiver
    | Roles.Support
    | Roles.Invalid;
