import ProductSize from './ProductSize';
export default class Prod {
    public name: string;
    public padType: string;
    public absorptionClass: string;
    public productFamilyName: string;
    public ProductSizes: ProductSize[] = [];
    public sortOrder: number;

    constructor(json: any, productFamilyName: string) {
        this.name = json.name;
        this.padType = json.padType;
        this.absorptionClass = json.absorptionClass;
        this.productFamilyName = productFamilyName;
        this.sortOrder = json.sortOrder;
        if (json.sizes && Array.isArray(json.sizes)) {
            this.ProductSizes = json.sizes.map((obj: any) => new ProductSize(obj));
        }

    }

    static sort(products: Prod[]): Prod[] {
        products.sort(((a, b) => {
            if (a.sortOrder === b.sortOrder) return 0;
            return a.sortOrder > b.sortOrder ? 1 : -1;
        }));
        return products;
    }
}