import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { StyledFormButtons } from "../../components/common/styled";

interface IAddEditButtonGroupProps {
    isEdit: boolean;
    onSave: (event: any) => void;
    onSaveAndAddAnother?: (event: any) => void;
    onDiscard?: (event: any) => void;
    removeCloseButton?: boolean;
    extraActions?: Array<{ label: string; action: (event: any) => void, disabled?: boolean }>;
    customConfirmButtonMessageId?: string;
    disabledConfirm?: boolean;
    disabledAddAnother?: boolean;
}

export default class AddEditButtonGroup extends Component<IAddEditButtonGroupProps> {
    render(): React.ReactElement {
        return (
            <StyledFormButtons>
                <Button
                    data-qa="save_button"
                    disabled={this.props.disabledConfirm}
                    variant={"primary"}
                    type="submit"
                    className="confirm-button"
                    onClick={this.props.onSave}
                >
                    <FormattedMessage
                        id={
                            this.props.customConfirmButtonMessageId
                                ? this.props.customConfirmButtonMessageId
                                : "addEditButton.saveChanges"
                        }
                    />
                </Button>

                {!this.props.isEdit && this.props.onSaveAndAddAnother && (
                    <Button
                        data-qa="save_add_new_button"
                        variant={"outline-secondary"}
                        type="submit"
                        className="confirm-and-add-another-button"
                        onClick={this.props.onSaveAndAddAnother}
                        disabled={this.props.disabledAddAnother}
                    >
                        <FormattedMessage id="addEditButton.confirmAndAddAnother" />
                    </Button>
                )}

                {this.props.extraActions &&
                    this.props.extraActions.map((extraAction) => (
                        <Button
                            data-qa="delete_button"
                            variant={"outline-secondary"}
                            type="button"
                            className="button"
                            onClick={extraAction.action}
                            key={extraAction.label}
                            disabled={extraAction.disabled}
                        >
                            <FormattedMessage id={extraAction.label} />
                        </Button>
                    ))}
                {!this.props.removeCloseButton && (
                    <Button
                        data-qa="cancel_button"
                        variant={"outline-secondary"}
                        type="button"
                        className="discard-button"
                        onClick={this.props.onDiscard}
                    >
                        <FormattedMessage id="addEditButton.cancel" />
                    </Button>
                )}
            </StyledFormButtons>
        );
    }
}
