import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { ReactComponent as LeftArrow } from "../../../assets/images/leftArrow.svg";
import { localizer } from "../../../utils/Localizer";

const ButtonContainer = styled.span` 
    font-size: 20px;
    cursor: pointer;
    color: ${Colors.blue.brandingDark};
    padding: 16px;     
    :hover {     
        font-weight: 500;  
    };
    gap: 5px;
`;

const StyledIcon = styled(LeftArrow)`
    margin-right: 8px;
    margin-bottom: 3px;
`;

interface IBackButtonProps {
    onClick: () => void;
}

const BackButton = (props: IBackButtonProps) => {
    return (
        <ButtonContainer onClick={props.onClick}>
            <StyledIcon />
            {localizer("constants.back")}
        </ButtonContainer>
    )
};

export default BackButton;