import superagentRequest from 'superagent';
import ErrorCodes from '../models/ErrorCodes';
import { LoginContexts } from '../models/LoginContext';
import { Roles } from '../models/Role';
import { spinnerKeys, startSpinner, stopSpinner } from "../reducers/spinnerSlice";
import { ResourceTypes } from '../reducers/userSlice';
import store from '../store';
import { getLocale, localizer } from "../utils/Localizer";
import { REACT_APP_API_URL } from "../utils/ProcessEnvHelpers";
import {
    getRefreshedAccessToken,
    handleLogout
} from './Auth';
import { LocalStorageKeys } from './Persistence';

const request = superagentRequest
    .agent()
    .retry()
    .withCredentials()
    .use((request: any) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        request.on('response', async (response: any) => {
            store.dispatch(stopSpinner());
            if (response.status === 403) {
                handleLogout("logout.forbidden");
            }
            if (response.status === 401) {
                handleLogout("logout.unauthorized");
            }
        });
    })
    .on('error', () => store.dispatch(stopSpinner()));

const backgroundRequest = superagentRequest
    .agent()
    .retry()
    .withCredentials()
    .use((request: any) => {
        request.on('response', async (response: any) => {
            if (response.status === 403) {
                handleLogout("logout.forbidden");
            }
            if (response.status === 401) {
                handleLogout("logout.unauthorized");
            }
        });
    });

const requestNoPopup = superagentRequest
    .agent()
    .retry()
    .withCredentials()
    .use((request: any) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        request.on('response', async (response: any) => {
            store.dispatch(stopSpinner());
            if (response.status === 403 || response.status === 401) {
                handleLogout();
            }
        });
    })
    .on('error', () => store.dispatch(stopSpinner()));

const API_PATH = `${REACT_APP_API_URL}/api`;

enum URLS {
    NURSING_HOME_ACTIVE = '/nursingHomes/active',
    NURSING_HOME = '/nursingHomes',
    WARD = '/wards',
    CAREGIVER_DEVICES = '/caregiverDevice/getForNursingHome',
    COUNTRY = '/country',
    RESIDENT = '/residents',
    NURSING_HOME_RESIDENTS = '/residents/getByNursingHome',
    RESIDENT_HISTORY = '/residents/history',
    AVG_CHANGE_LOGS = '/nursingHomeStatistics/avgPerDay',
    EXPORT_FILE = '/nursingHomeStatistics/exportFile',
    PRODUCTS = '/product/nursingHome',
    MARKETS = '/product/markets',
    USERS = '/users',
    USERS_EXTERNAL = '/users/external',
    USERS_INTERNAL = '/users/internal',
    SET_PASSWORD = '/users/updatePassword',
    RESET_PASSWORD = '/users/resetPassword',
    EXPIRE_SUPPORT = '/users/expireSupportUserAccess',
    RESEND_ACTIVATION_EMAIL = '/users/resendActivationEmail',
    AUDITLOG_RESIDENT = '/auditlog/resident',
    AUDITLOG_NURSE = '/auditlog/nurse',
    AUDITLOG_ADMIN = '/auditlog/admin',
    AUDITLOG_NURSING_HOME_GROUP_MANAGER = '/auditLog/nursingHomeGroup',
    AUDITLOG_NURSING_HOME_ACCESS = '/auditlog/nursingHomeAccess',
    AUDITLOG_ADMIN_ACCESS = '/auditLog/loginlogout',
    ASSIGN_WARDS_TO_CAREGIVER = '/users/assignWardsToCaregiver',
    UPDATE_CAREGIVER_PASSWORD = '/users/UpdateCaregiverPassword',
    USERS_PASSWORD = '/users/updatePassword',
    USER_SET_TERMS_OF_USE = '/users/termsOfUse',
    RESOURCES = '/users/resources',
    CARE_GROUPS = '/nursingHomeGroups',
    LOGIN_WITH_ID_TOKEN = '/authentication/browserLoginWithIdToken',
    LOGIN_WITH_CUSTOMER_ID_TOKEN = '/authentication/browserCustomerAdLogin',
    SET_MONITORING_STATUS = '/monitoringStatus',
    KEEP_SESSION_ALIVE = '/Authentication/browserKeepAlive',
    LOGOUT = '/authentication/browserLogout',
    VALIDATE_2FA_CODE = '/authentication/verifyCode',
    VERIFY_PASSWORD_LINK = '/users/verifyPasswordLink',
    CONFIGURATION = '/configuration',
    CUSTOMER_AD = '/configuration/customerAd',
    GLOBAL_CONFIG = '/configuration/globalConfigurations',
    OVERVIEW_STATISTICS = '/overview/statistics',
}

const getResourceTypeBasedOnRole = (role: Roles): string => {
    switch (role) {
        case Roles.GlobalAdmin:
            return ResourceTypes.Global;
        case Roles.CountryAdmin:
            return ResourceTypes.Country;
        case Roles.NHManager:
        case Roles.Nurse:
        case Roles.Caregiver:
        case Roles.Support:
            return ResourceTypes.NursingHome;
        default:
            return ResourceTypes.Invalid;
    }
};

const getHeaders = async (ignoreContextId?: boolean, specificResourceType?: ResourceTypes): Promise<any> => {
    const { role, id, loggedInUserId } = store.getState().contextSlice;
    let headers: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'User-Resource-Type': specificResourceType ? specificResourceType : getResourceTypeBasedOnRole(role),
        "User-Role": role,
        'Accept-Language': getLocale(),
        'X-API-VERSION': "1.0",
    }

    if (!ignoreContextId) {
        headers = {
            ...headers,
            'User-Resource-Id': id,
        }
    }

    if (loggedInUserId > 0) {
        headers = {
            ...headers,
            'X-ClientId': loggedInUserId
        };
    }

    const loginContext = sessionStorage.getItem(LocalStorageKeys.LoginContext);
    if (loginContext && loginContext === LoginContexts.CustomerAd) {
        const accessToken = await getRefreshedAccessToken();
        if (accessToken) {
            headers = {
                ...headers,
                "Authorization": "Bearer " + accessToken,
            }
        } else {
            handleLogout();
        }
    }
    return headers;
};

const getNewHeaders = async (resourceIds: number[] = [], resourceType: ResourceTypes = ResourceTypes.NursingHome): Promise<any> => {
    const { role } = store.getState().contextSlice;
    const locale = getLocale();

    let headers: any = {
        Accept: "application/json",
        "X-API-VERSION": "1.0",
    };

    if (locale) {
        headers = {
            ...headers,
            'Accept-Language': locale
        };
    }

    if (role && role !== Roles.Invalid) {
        headers = {
            ...headers,
            "User-Role": role,
        };
    }

    if (resourceIds.length) {
        headers = {
            ...headers,
            'User-Resource-Ids': resourceIds,
            'User-Resource-Type': resourceType,
        };
    }

    const loginContext = sessionStorage.getItem(LocalStorageKeys.LoginContext);
    if (loginContext && loginContext === LoginContexts.CustomerAd) {
        const accessToken = await getRefreshedAccessToken();
        if (accessToken) {
            headers = {
                ...headers,
                "Authorization": "Bearer " + accessToken,
            }
        } else {
            handleLogout()
        }
    }

    return headers;
};

export const AuthRestClient: any = {
    loginWithAdToken: async (idToken: string): Promise<string> => {
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const response = await superagentRequest
                .get(API_PATH + URLS.LOGIN_WITH_ID_TOKEN)
                .set({
                    'Authorization': 'Bearer ' + idToken,
                    'X-API-VERSION': "1.0",
                })
                .withCredentials();
            return response.body;
        }
        catch (error: any) {
            if (error.status === 403) {
                throw ErrorCodes.NoPermissions;
            } else {
                throw error;
            }
        }
        finally {
            store.dispatch(stopSpinner());
        }
    },

    loginWithCustomerAdAccessToken: async (accessToken: string): Promise<string> => {
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const domainHint = sessionStorage.getItem(LocalStorageKeys.DomainHint)
            const response = await superagentRequest
                .post(API_PATH + URLS.LOGIN_WITH_CUSTOMER_ID_TOKEN)
                .set({
                    'Authorization': 'Bearer ' + accessToken,
                    'X-API-VERSION': "1.0",
                    'accept': 'text/plain',
                    'Content-Type': 'application/json-patch+json',
                })
                .send({ config: domainHint })
                .withCredentials();
            return response.body;
        }
        catch (error: any) {
            if (error.status === 403) {
                throw ErrorCodes.NoPermissions;
            } else {
                throw error;
            }
        }
        finally {
            store.dispatch(stopSpinner());
        }
    },

    loginB2C: async (username: string, password: string) => {
        const url = `${API_PATH}/Authentication/browserLogin`;
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    username,
                    password
                }),
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": "application/json",
                    'X-API-VERSION': "1.0",
                    'Accept-Language': getLocale(),
                },
                credentials: "include"
            });

            const { status } = response;
            let body = undefined;
            switch (status) {
                case 403:
                    throw ErrorCodes.NoPermissions;
                case 401:
                    body = await response.json();
                    if (body && body.isLocked) {
                        throw ErrorCodes.Locked;
                    }
                    throw ErrorCodes.LoginFailed;
                case 200:
                    body = await response.json();
                    if (body && (body.sessionTimeoutMinutes || body.is2FactorAuthenticationEnabled)) {
                        return body;
                    }
                    throw ErrorCodes.LoginFailed;
                default:
                    throw ErrorCodes.LoginFailed;
            }
        } finally {
            store.dispatch(stopSpinner());
        }
    },

    keepSessionAlive: async (): Promise<any> => {
        return superagentRequest
            .post(API_PATH + URLS.KEEP_SESSION_ALIVE)
            .set({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-VERSION': "1.0",
            })
            .withCredentials()
            .use((request: any) => {
                request.on('response', async (response: any) => {
                    if (response.status === 401 || response.status === 403) {
                        handleLogout("logout.inactivity");
                    }
                });
            });
    },


    logout: async (): Promise<any> => {
        return superagentRequest
            .delete(API_PATH + URLS.LOGOUT)
            .set({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-VERSION': "1.0",

            })
            .withCredentials();
    },

    verifyCode: async (username: string, password: string, code: string) => {
        store.dispatch(startSpinner(spinnerKeys.global));
        const response = await fetch(API_PATH + URLS.VALIDATE_2FA_CODE, {
            method: "POST",
            body: JSON.stringify({
                username,
                password,
                code,
            }),
            headers: {
                "Accept": 'application/json',
                "Content-Type": "application/json",
                'X-API-VERSION': "1.0",
            },
            credentials: "include"
        });
        store.dispatch(stopSpinner());
        if (response.status === 200) {
            const body = await response.json();
            return body;
        }
        return response;
    }
};

export const RestClient: any = {
    // #region GET
    getResources: async (avoidLogoutMessage: boolean): Promise<any> => getNewFlow(`${API_PATH + URLS.RESOURCES}`, undefined, avoidLogoutMessage),

    getResidentAudits: async (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_RESIDENT}`, query);
    },

    getResidentsForNursingHome: async () => {
        return get(API_PATH + URLS.NURSING_HOME_RESIDENTS);
    },

    getNurseAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSE}`, query);
    },

    getNursingHomeGroupManagerAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSING_HOME_GROUP_MANAGER}`, query);
    },


    getAdminAudits: async (startDate: string, endDate: string) => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_ADMIN}`, query);
    },

    getNursingHomeAccessAudits: (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_NURSING_HOME_ACCESS}`, query);
    },

    getAdminAccessAudits: (startDate: Date, endDate: Date): Promise<any> => {
        const query: any = {
            startDateIso: startDate,
            endDateIso: endDate,
        };

        return get(`${API_PATH + URLS.AUDITLOG_ADMIN_ACCESS}`, query);
    },

    getSelectedNursingHome: (): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME_ACTIVE, undefined, false, false, ResourceTypes.NursingHome);
    },

    getCareFacility: (id: string): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME + '/' + id, undefined, false, true, ResourceTypes.NursingHome);
    },

    getCareFacilityExternal: (externalId: string): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME + '/external/' + externalId, undefined, false, true, ResourceTypes.NursingHome);
    },

    getNursingHomes: (): Promise<any> => {
        return get(API_PATH + URLS.NURSING_HOME, undefined, false, true);
    },

    getAllCareGroups: (): Promise<any[]> => {
        return get(API_PATH + URLS.CARE_GROUPS, undefined, false, true);
    },

    getProducts: (): Promise<any> => {
        return get(API_PATH + URLS.PRODUCTS);
    },

    getMarkets: (): Promise<any> => {
        return get(API_PATH + URLS.MARKETS);
    },

    getWards: (): Promise<any[]> => {
        return get(API_PATH + URLS.WARD, undefined, false, false, ResourceTypes.NursingHome);
    },

    getCountries: () => {
        return get(API_PATH + URLS.COUNTRY);
    },

    getHistoryLogs: (residentId: string, startDate: string, endDate: string, limit: number, offset: number, eventType: string, startTime: string | null, endTime: string | null): Promise<any> => {
        const query: any = {
            startDate,
            endDate,
            limit,
            offset
        };

        if (startTime) {
            query.startTime = startTime;
        }

        if (endTime) {
            query.endTime = endTime;
        }

        // Event type 'All' is not supported by api. It means the same thing as omitting the param.
        if (eventType && eventType !== 'All') {
            // @ts-ignore
            query.bedStatus = eventType;
        }

        return get(`${API_PATH + URLS.RESIDENT_HISTORY}/${residentId}`, query);
    },

    getAverageChangeLogs: (nursingHomeId: string, startDate: string, endDate: string, isDayShift: boolean | null, wardIds?: string): Promise<any> => {
        const query: any = {
            nursingHomeId,
            startDate,
            endDate,
            wardIds: [],
            isDayShift,
        };

        if (wardIds) {
            query.wardIds = [wardIds];
        }

        query.isDayShift = isDayShift;

        return get(API_PATH + URLS.AVG_CHANGE_LOGS, query, false, false, ResourceTypes.NursingHome);
    },

    getResidentStatistics: (residentId: string, nursingHomeId: string, startDate: string, endDate: string, startTime: string, endTime: string, ignoreNightTime: boolean): Promise<any> => {
        const query: any = {
            nursingHomeId,
            startDate,
            endDate,
            startTime,
            endTime,
            ignoreNightTime
        };

        return get(`${API_PATH + URLS.RESIDENT}/${residentId}/statistics`, query);
    },

    getUsersExternal: (id?: number): Promise<any[]> => {
        return getNewFlow(API_PATH + URLS.USERS_EXTERNAL, undefined, false, id ? [id] : undefined);
    },

    getUserExternal: (userId: string, contextId: string): Promise<any> => {
        return getNewFlow(`${API_PATH + URLS.USERS_EXTERNAL}/` + userId, undefined, false, contextId ? [Number(contextId)] : undefined);
    },

    getUsersInternal: (): Promise<any[]> => {
        return getNewFlow(API_PATH + URLS.USERS_INTERNAL);
    },

    getUserInternal: (userId: string): Promise<any> => {
        return getNewFlow(`${API_PATH + URLS.USERS_INTERNAL}/` + userId);
    },

    getCaregivers: () => {
        return get(API_PATH + URLS.CAREGIVER_DEVICES);
    },

    getConfiguration: () => {
        return getInBackground(API_PATH + URLS.CONFIGURATION);
    },

    getCustomerAd: (id: string) => {
        return get(API_PATH + URLS.CUSTOMER_AD, { id });
    },

    getGlobalConfig: () => {
        return get(API_PATH + URLS.GLOBAL_CONFIG, undefined, false, true);
    },

    getOverviewStatistics: (startDate: string, endDate: string): Promise<any> => {
        const query: any = {
            startDate,
            endDate,
        };
        return get(API_PATH + URLS.OVERVIEW_STATISTICS, query);
    },
    // #endregion

    // #region POST
    getStatisticsFile: async (fileName: string, wardIds: string[], startDate: string, endDate: string, _nursingHomeId: string): Promise<any> => {
        const query: any = {
            fileName,
            wardIds,
            startDate,
            endDate,
        };

        query.timezoneOffset = new Date().getTimezoneOffset();

        const headers = await getHeaders();

        const response = await request
            .post(API_PATH + URLS.EXPORT_FILE)
            .set(headers)
            .send(query)
            .responseType('blob');

        if (!response.body) {
            throw new Error();
        }
        return response;
    },

    setPassword: (requestBody: any): Promise<any> => {
        const thenableStatusCodes = [200, 400, 404, 409];
        const validateResponseMethod = (status: number) => {
            if (status === 404 || status === 409 || status === 400) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        };
        return postWithCustomStatus(API_PATH + URLS.SET_PASSWORD, thenableStatusCodes, validateResponseMethod, requestBody);
    },

    resetPassword: (requestBody: any): Promise<any> => {
        const thenableStatusCodes = [200, 404];
        const validateResponseMethod = (status: number) => {
            if (status === 404) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        };
        return postWithCustomStatus(API_PATH + URLS.RESET_PASSWORD, thenableStatusCodes, validateResponseMethod, requestBody);
    },

    createExternalUser: async (requestBody: any, assignedNHids: number[]): Promise<any> => {
        const thenableStatusCodes = [404, 409, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 409) {
                throw {
                    status,
                    showInToast: true,
                    text: localizer('constants.errorMessages.userExists'),
                };
            } else if (status === 404) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        };

        return postNewFlow(API_PATH + URLS.USERS_EXTERNAL, requestBody, assignedNHids, thenableStatusCodes, validateResponseMethod);
    },

    createInternalUser: async (requestBody: any, selectedCountries: number[], resourceType: ResourceTypes): Promise<any> => {
        const thenableStatusCodes = [404, 409, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 409) {
                throw {
                    status,
                    showInToast: true,
                    text: localizer('constants.errorMessages.userExists'),
                };
            } else if (status === 404) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        };

        return await postNewFlow(API_PATH + URLS.USERS_INTERNAL, requestBody, selectedCountries, thenableStatusCodes, validateResponseMethod, resourceType);
    },

    expireSupportUserAccess: async (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.EXPIRE_SUPPORT, requestBody, true, true);
    },

    resendActivationEmail: async (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.RESEND_ACTIVATION_EMAIL, requestBody, true, true);
    },

    postResident: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.RESIDENT, requestBody);
    },

    postCountry: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.COUNTRY, requestBody);
    },

    createCareGroup: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.CARE_GROUPS, requestBody, true);
    },

    postWard: (requestBody: any): Promise<any> => {
        return post(API_PATH + URLS.WARD, requestBody);
    },

    createCareFacility: (requestBody: any): Promise<any> => {
        const thenableStatusCodes = [409, 200];

        const validateResponseMethod = (status: number) => {
            if (status === 409) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        }
        return postWithCustomStatus(`${API_PATH + URLS.NURSING_HOME}`,thenableStatusCodes, validateResponseMethod, requestBody);
    },
    // #endregion

    // #region PUT
    updateWard: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.WARD}`, requestBody);
    },

    updateResident: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.RESIDENT}`, requestBody);
    },

    updateExternalUser: (requestBody: any, assignedNHids: number[]): Promise<any> => {
        return putNewFlow(`${API_PATH + URLS.USERS_EXTERNAL}`, requestBody, assignedNHids, ResourceTypes.NursingHome);
    },

    updateInternalUser: async (requestBody: any, assignedCountryIds: number[], resourceType: ResourceTypes): Promise<any> => {
        const thenableStatusCodes = [404, 200];
        const validateResponseMethod = (status: number) => {
            if (status === 404) {
                throw {
                    status,
                    showInToast: false,
                };
            }
        };
        return putNewFlow(`${API_PATH + URLS.USERS_INTERNAL}`, requestBody, assignedCountryIds, resourceType, thenableStatusCodes, validateResponseMethod);
    },

    updateCountry: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.COUNTRY}`, requestBody);
    },

    setTermsOfUse: (): Promise<any> => {
        return put(`${API_PATH + URLS.USER_SET_TERMS_OF_USE}`);
    },

    setMonitoringStatus: (residentId: string, requestBody: any) => { // IF ERROR make  res && res.body check conditional
        return put(`${API_PATH + URLS.RESIDENT + URLS.SET_MONITORING_STATUS}/${residentId}`, requestBody);
    },

    assignWardsToCaregiver: (requestBody: any) => {
        return put(`${API_PATH + URLS.ASSIGN_WARDS_TO_CAREGIVER}`, requestBody);
    },

    updateCareGroup: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.CARE_GROUPS}`, requestBody, true);
    },

    updateNursingHome: (requestBody: any): Promise<any> => {
        return put(`${API_PATH + URLS.NURSING_HOME}`, requestBody, false, ResourceTypes.NursingHome);
    },
    // #endregion

    // #region DELETE
    deleteWard: (wardId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.WARD}/${wardId}`);
    },

    deleteResident: (residentId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.RESIDENT}/${residentId}`);
    },

    deleteCountry: (id: string): Promise<any> => {
        return remove(`${API_PATH + URLS.COUNTRY}/${id}`);
    },

    deleteCareGroup: (nursingHomeGroupId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.CARE_GROUPS}/${nursingHomeGroupId}`);
    },

    deleteNursingHome: (nursingHomeId: string): Promise<any> => {
        return remove(`${API_PATH + URLS.NURSING_HOME}/${nursingHomeId}`, true);
    },

    deleteExternalUser: (userId: string): Promise<any> => {
        return removeNewFlow(`${API_PATH + URLS.USERS_EXTERNAL}/${userId}`);
    },

    deleteInternalUser: (userId: string): Promise<any> => {
        return removeNewFlow(`${API_PATH + URLS.USERS_INTERNAL}/${userId}`);
    },

    verifyResetPasswordLink: async (username: string = '', passwordResetCode: string = '', externalId: string = '', product: string = '') => {
        try {
            store.dispatch(startSpinner(spinnerKeys.global));
            const requestBody = { username, passwordResetCode, externalId, product };
            return post(API_PATH + URLS.VERIFY_PASSWORD_LINK, requestBody);
        }
        catch (error: any) {
            throw error;
        }
        finally {
            store.dispatch(stopSpinner());
        }
    }
};

// eslint-disable-next-line @typescript-eslint/ban-types
const get = async (url: string, query: string | object = {}, avoidLogoutMessage: boolean = false, ignoreContextId: boolean = false, specificResourceType?: ResourceTypes) => {
    const headers = await getHeaders(ignoreContextId, specificResourceType);

    const specificRequest = avoidLogoutMessage ? requestNoPopup : request;

    const responseBody = await specificRequest
        .get(url)
        .query(query)
        .set(headers)
        .then(response => {
            return response?.body;
        });

    return responseBody;
};

const getNewFlow = async (url: string, query: string | object = {}, avoidLogoutMessage: boolean = false, resourceIds?: number[]) => {
    const headers = await getNewHeaders(resourceIds);

    const specificRequest = avoidLogoutMessage ? requestNoPopup : request;

    const responseBody = await specificRequest
        .get(url)
        .query(query)
        .set(headers)
        .then(response => {
            return response?.body;
        });

    return responseBody;
};

const postNewFlow = async (url: string, requestBody: string | object = {}, resourceIds: number[], thenableStatusCodes: number[], validateResponseMethod: (status: number) => void, resourceType: ResourceTypes = ResourceTypes.NursingHome) => {
    const headers = await getNewHeaders(resourceIds, resourceType);

    const response = await request
        .post(url)
        .send(requestBody)
        .ok(response => {
            return thenableStatusCodes.includes(response.status);
        })
        .set(headers);

    validateResponseMethod(response.status);

    return response.body;
};

const putNewFlow = async (url: string, requestBody: string | object = {}, resourceIds: number[] = [], resourceType: ResourceTypes, thenableStatusCodes: number[] = [200], validateResponseMethod?: (status: number) => void) => {
    const headers = await getNewHeaders(resourceIds, resourceType);
    const response = await request
        .put(url)
        .send(requestBody)
        .ok(response => {
            return thenableStatusCodes.includes(response.status)
        })
        .set(headers);

    if (validateResponseMethod) {
        validateResponseMethod(response.status);
    }
    return response.body;
}

const removeNewFlow = async (url: string) => {
    const headers = await getNewHeaders();
    return request
        .delete(url)
        .set(headers);
}

const getInBackground = async (url: string, query: string | object = {}) => {
    const headers = await getHeaders();
    const responseBody = await backgroundRequest
        .get(url)
        .query(query ?? "")
        .set(headers)
        .then(response => {
            return response?.body;
        });

    return responseBody;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const postWithCustomStatus = async (url: string, thenableStatusCodes: number[], validateResponseMethod: (status: number) => void, requestBody: string | object = {}) => {
    const headers = await getHeaders();

    const response = await request
        .post(url)
        .send(requestBody)
        .ok(response => thenableStatusCodes.includes(response.status))
        .set(headers);

    validateResponseMethod(response.status);

    return response.body;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const post = async (url: string, requestBody: string | object = {}, ignoreContextId: boolean = false, ignoreResponseBody: boolean = false) => {
    const headers = await getHeaders(ignoreContextId);

    const response = await request
        .post(url)
        .send(requestBody)
        .set(headers);

    if (!ignoreResponseBody && !response.body) {
        throw new Error();
    }

    return response.body;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const put = async (url: string, requestBody: string | object = {}, ignoreContextId: boolean = false, specificResourceType?: ResourceTypes) => {
    const headers = await getHeaders(ignoreContextId, specificResourceType);

    const response = await request
        .put(url)
        .send(requestBody)
        .set(headers);

    if (response.status !== 200) {
        throw new Error();
    }

    return response?.body;
};

const remove = async (url: string, ignoreContextId: boolean = false) => {
    const headers = await getHeaders(ignoreContextId);

    return request
        .delete(url)
        .set(headers);
};
