import { configureStore, Middleware } from "@reduxjs/toolkit";
import logger from 'redux-logger';
import configurationSlice from "./reducers/configurationSlice";
import contextSlice from "./reducers/contextSlice";
import countrySlice from "./reducers/countrySlice";
import marketSlice from "./reducers/marketSlice";
import careGroupSlice from "./reducers/careGroupSlice";
import nursingHomeSlice from "./reducers/nursingHomeSlice";
import residentSlice from "./reducers/residentSlice";
import spinnerSlice from "./reducers/spinnerSlice";
import userSlice from "./reducers/userSlice";
import wardSlice from "./reducers/wardSlice";
import { isDevEnv, isLocalEnv } from "./utils/EnvCheck";

let middlewares: Middleware[] = [];

if (isDevEnv() || isLocalEnv()) {
    middlewares = [logger];
}

const store = configureStore({
    reducer: {
        configurationSlice,
        contextSlice,
        nursingHomeSlice,
        wardSlice,
        residentSlice,
        careGroupSlice,
        marketSlice,
        spinnerSlice,
        countrySlice,
        userSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
