import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";
import Colors from "../assets/Colors";

const StyledConnectedIndicator = styled.div`
    color: ${Colors.green.dark};
    display: flex;
    float:right;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    margin-right: 6px;
    font-size: 8px;
`;

const ConnectedIndicator = () => (
    <StyledConnectedIndicator>
        <StyledFontAwesome icon={faCircle}/>
    </StyledConnectedIndicator>
);

export default ConnectedIndicator;
