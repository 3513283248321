import { useEffect, useState } from "react";
import { localizer } from "../../../../utils/Localizer";
import CheckBox from "../../../common/controls/CheckBox";
import FilterInput from "../../../common/controls/FilterInput";
import { NoSearchResultContainer } from "./CommonUserStyle";
import { ScrollableContainer, SelectAllContainer } from "./UserEditFormStyle";
import {Roles} from "../../../../models/Role";
import RadioButtons, {IRadioEntry} from "../../../common/controls/RadioButtons";
import {IUserResourcesHierarchy} from "../../../../reducers/contextSlice";
import { useAppSelector } from "../../../../hooks/useAppSelector";

interface IProps {
    allNhResources: IUserResourcesHierarchy[];
    selectedIds: number[];
    selectedRole: Roles;
    onCareFacilitySelect: (assignedResourceIds: number[]) => void;
    disabled?: boolean | undefined;
}

const CareFacilitiesScrollableContainer = (props: IProps) => {

    const activeRole = useAppSelector<Roles>((state) => state.contextSlice.role);
    const {
        allNhResources,
        onCareFacilitySelect,
    } = props;

    const nhResource = allNhResources.filter((careFacility: IUserResourcesHierarchy) => careFacility.roles.includes(activeRole));
    const [filterValue, setFilterValue] = useState<string>("");

    const [selectedCareFacilities, setSelectedCareFacilities] = useState<IUserResourcesHierarchy[]>((nhResource).filter((careFacility: IUserResourcesHierarchy) => props.selectedIds.includes(careFacility.id)));
    const [filteredCareFacilities, setFilteredCareFacilities] = useState<IUserResourcesHierarchy[]>(nhResource);

    useEffect(() => {
        const nhs = allNhResources.filter((careFacility: IUserResourcesHierarchy) => careFacility.roles.includes(activeRole));
        const foundCareFacilities = nhs.filter(careFacility => careFacility.name.toLowerCase().includes(filterValue.toLowerCase()));
        setFilteredCareFacilities(foundCareFacilities);
    }, [activeRole, allNhResources, filterValue]);

    const shouldTickSelectAllCheckbox = () => {
        const selectedCFIds = selectedCareFacilities.map(({ id }) => id);
        return filteredCareFacilities.length > 0 && filteredCareFacilities.length === filteredCareFacilities.filter(entry => selectedCFIds.includes(entry.id)).length
    }

    const getCareFacilities = () => {
        if (props.selectedRole === Roles.Caregiver) {
            const careFacilityRadioValues: IRadioEntry[] = [];
            filteredCareFacilities.forEach((careFacility) => {
                careFacilityRadioValues.push({ value: careFacility.id, label: careFacility.name });
            });
            if (careFacilityRadioValues.length > 0) {
                return (<RadioButtons
                    readOnly={props.disabled}
                    width={230}
                    entries={careFacilityRadioValues}
                    selectedRadio={selectedCareFacilities.length > 0 ? selectedCareFacilities[0].id : nhResource[0]?.id}
                    setSelectedRadio={(value) => {
                        const selection = filteredCareFacilities.find(careFacility => careFacility.id === parseInt(value));
                        if (selection) {
                            setSelectedCareFacilities([selection]);
                            onCareFacilitySelect([selection].map(entry => entry.id));
                        }
                    }}
                />);
            }
            return <NoSearchResultContainer>{localizer("users.careFacilityAccess.noResults")}</NoSearchResultContainer>;
        } else {
            const careFacilityCheckboxes = filteredCareFacilities.map(careFacility => {
                return (
                    <CheckBox
                        disabled={props.disabled}
                        width={250}
                        label={careFacility.name}
                        key={careFacility.id}
                        fieldName={careFacility.name}
                        value={selectedCareFacilities.map(({id}) => id).includes(careFacility.id)}
                        cutText={true}
                        onChange={(_name, value) => {
                            let selection;
                            if (value) {
                                selection = [...(new Set([...selectedCareFacilities, careFacility]))];
                            } else {
                                selection = selectedCareFacilities.filter(selectedCareFacility => selectedCareFacility.id !== careFacility.id);
                            }
                            setSelectedCareFacilities(selection);
                            onCareFacilitySelect(selection?.map(entry => entry.id));
                        }}
                    />
                );
            });
            return careFacilityCheckboxes.length > 0 ? careFacilityCheckboxes : <NoSearchResultContainer>{localizer("users.careFacilityAccess.noResults")}</NoSearchResultContainer>;
        }
    }

    return (
        <>
            <FilterInput
                placeholder={localizer("constants.search")}
                onChange={(value) => {
                    setFilterValue(value);
                }}
                value={filterValue}
            />
            {props.selectedRole !== Roles.Caregiver && <SelectAllContainer>
                <CheckBox
                    disabled={props.disabled}
                    width={250}
                    label={localizer("constants.selectAll")}
                    fieldName="selectAll"
                    value={shouldTickSelectAllCheckbox()}
                    onChange={(_name, value) => {
                        let selection;
                        if (value) {
                            selection = [...(new Set([...selectedCareFacilities, ...filteredCareFacilities]))];
                        } else {
                            const filteredCFIds = filteredCareFacilities.map(({ id }) => id);
                            selection = selectedCareFacilities.filter(entry => !filteredCFIds.includes(entry.id))
                        }
                        setSelectedCareFacilities(selection);
                        onCareFacilitySelect(selection?.map(entry => entry.id));
                    }}
                />
            </SelectAllContainer>}
            <ScrollableContainer>
                {getCareFacilities()}
            </ScrollableContainer>
        </>
    )
}

export default CareFacilitiesScrollableContainer;

