
import { Link } from "react-router-dom";
import styled from "styled-components";
import Colors from "../assets/Colors";
import SensorStripIndicator from "../components/themed/resident/SensorStripIndicator";

export const SummaryStripIndicator = styled(SensorStripIndicator) <any>`
    .mb-2 {
        display: none;
    }
    .rectangle {
        .rectangle__background {
            width: 400px;
        }
        .rectangle__active {
            width: 400px;
        }
    }

    .indicatorTexts  {
        .text {
            ${props => props.stripLength && "color: transparent"}
        }
        .text.active{
            color: ${Colors.blue.brandingDark};
        }
    }
`;

export const SetupIdentifier = styled.span`
    font-size: 14px;
    font-weight: 400;
    svg {
        margin-right: 5px;
    }
`;

export const ThresholdContainer = styled.div`
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
`;

export const ProductName = styled.span`
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
`;

export const StripPlaceholder = styled.div`
    width: 400px;
    height: 41px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Colors.blue.brandingDark}
`;

export const SameProductContainer = styled.div`
    margin-top: 60px;
`

export const StyledResidentInformationContainer = styled.div`
    min-height: 250px;
    background-color: ${Colors.grey.tenaLight};
    margin: 0px 0px 24px 0px;
    padding: 24px 30px 24px 30px;
    border-radius: 4px;
    color: ${Colors.black};
`;

export const StyledTabButton = styled.div<any>`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 32px;
    gap: 20px;
    width: 100%;
    max-width: 672px;
    height: 60px;

    background: ${Colors.white.clearWhite};

    border-width: 1px 1px 0px 1px;
    border-style: solid;
    ${(props) =>
        props.active ?
            `
                border-color: ${Colors.blue.brandingDark};
                color:${Colors.blue.brandingDark};
            `
            :
            `
                border-color: ${Colors.grey.lightGrey};
                color: ${Colors.black};
            `

    }
    border-radius: 4px 4px 0px 0px;

`;

export const TabButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 12px;
    gap: 24px;

    max-width: 1392px;
    height: 60px;
`;

export const SeparatorLine = styled.div`
    max-width: 1392px;
    height: 1px;
    border: 1px solid ${Colors.blue.brandingDark};
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px;
`;

export const StyledLink = styled(Link)`
    font-style: normal;

    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    align-items: center;
    color: ${Colors.blue.brandingDark};
    text-decoration: none;
`;

export const StyledTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    font-family: 'Asap';
    font-style: normal;
    color: ${Colors.black};
    height: 40px;
    
`;

export const InformationContainer = styled.div`
    display: flex;
`;

export const GeneralResidentInfoContainer = styled.div`
    min-width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 32px;
    line-height: 20px;
`;

export const SingleInformationContainer = styled.div`
    display: grid;
    justify-content: space-between;
    max-width: 320px;
    gap:10px;
    span {
        font-size: 14px;
    }
    div {
        font-size: 16px;
        font-weight: 600;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: initial;
    }
`;

export const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 460px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ButtonContainer = styled.div`
    margin-bottom: 24px;
`;

export const OneStripNotificationTimingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    gap: 10px;
    span {
        text-align: left;
        align-self: flex-start;
    }
    span:nth-child(1) {
        font-size: 14px;
    }
    span:nth-child(2) {
        font-size: 16px;
        font-weight: 600;
    }
`;
