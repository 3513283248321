import React, { Component } from 'react';
import ErrorCodes from "../models/ErrorCodes";
import { Mutable } from "../models/Mutable";
import { CareFacility } from '../models/CareFacility';
import { Ward } from '../models/Ward';

interface IValidatingFormProps<T extends Mutable,> {
    entity?: T;
    hideModal?: () => void;
    context?: any;
    editForm?: any;
    wards?: Ward[];
    nursingHome?: CareFacility;
    reloadData?: () => void;
    setDeleteMode?: () => void;
    setEditMode?: () => void;
}

interface IValidatingFormState<T extends Mutable> {
    isEdit: boolean;
    entity: T;
    validationErrors: ErrorCodes[];
    editState: number;
    isLoading: boolean;
}

export abstract class ValidatingForm<T extends Mutable> extends Component<IValidatingFormProps<T>, IValidatingFormState<T>> {
    readonly state: IValidatingFormState<T> = {
        isEdit: this.props.entity !== undefined,
        entity: this.getNewInstance(this.props.entity),
        editState: 0,
        validationErrors: [],
        isLoading: false
    };

    abstract getNewInstance(entity?: T): T;

    setLoading(loading: boolean): void {
        this.setState({ isLoading: loading });
    }

    clearFormData(): void {
        this.setState({ entity: this.getNewInstance(), validationErrors: [] });
    }

    async validate(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const errors = [];
                errors.push(...this.state.entity.validateSelf());
                this.setState({ validationErrors: errors }, () => { resolve(errors.length < 1); });
            } catch (exception) {
                reject(exception);
            }
        });
    }

    getError(error: ErrorCodes): string | undefined {
        const validationError = this.state.validationErrors.find(validationError => validationError.startsWith(error));
        if (validationError !== undefined) {
            return validationError;
        }
        return;
    }

    render(): React.ReactElement {
        return (<div />);
    }
}
