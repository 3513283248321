import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import { routePaths } from "../../../utils/PathHelpers";
import { IconWithText } from "../../common/table/IconWithText";
import ActionsContainer from "../../common/table/formatters/ActionsContainer";

const CareFacilityActions = (props: any) => {
    const navigate = useNavigate();
    return (
        <ActionsContainer>
            <IconWithText
                icon={
                    <EditIcon onClick={() => navigate(`${routePaths.careFacilities}/${props.row.original.id}`)} />
                }
                textId={"constants.edit"} />
        </ActionsContainer>
    );
};

export default CareFacilityActions;
