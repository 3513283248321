import styled from "styled-components";
import { ExternalUserType } from "../../../../models/EExternalUserType";
import { localizer } from "../../../../utils/Localizer";
import { StyledButton } from "../../../common/View";
import BasicModal from "../../../common/modal/BasicModal";
import { EModalSize } from "../../../common/modal/ModalWrapper";

const MethodTitle = styled.div`
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface ILoginMethodInformationModal {
    showPopup: boolean;
    setShowPopup: (show: boolean) => void;
    loginMethods: ExternalUserType[];
}

const LoginMethodInformationModal = (props: ILoginMethodInformationModal) => {
    const { showPopup, setShowPopup, loginMethods } = props
    const content: JSX.Element[] = [];

    if (loginMethods.includes(ExternalUserType.Federated)) {
        content.push(
            <div key={ExternalUserType.Federated}>
                <MethodTitle>{localizer("user.loginMethod." + ExternalUserType.Federated)}</MethodTitle>
                <div>{localizer("user.loginMethod." + ExternalUserType.Federated + ".description")}</div>
                <br />
            </div>
        )
    }

    if (loginMethods.includes(ExternalUserType.Email)) {
        content.push(
            <div key={ExternalUserType.Email}>
                <MethodTitle>{localizer("user.loginMethod." + ExternalUserType.Email)}</MethodTitle>
                <div>{localizer("user.loginMethod." + ExternalUserType.Email + ".description")}</div>
                <br />
            </div>
        )
    }

    if (loginMethods.includes(ExternalUserType.Username)) {
        content.push(
            <div key={ExternalUserType.Username}>
                <MethodTitle>{localizer("user.loginMethod." + ExternalUserType.Username)}</MethodTitle>
                <div>{localizer("user.loginMethod." + ExternalUserType.Username + ".description")}</div>
                <br />
            </div>
        )
    }

    return <BasicModal
        width={EModalSize.S}
        handleOnHide={() => setShowPopup(false)}
        show={showPopup}
        title={localizer("user.loginMethod.title")}
    >
        {content}
        <ButtonContainer>
            <StyledButton
                variant={"primary"}
                type={"submit"}
                onClick={() => setShowPopup(false)}
            >
                OK
            </StyledButton>
        </ButtonContainer>
    </BasicModal>
}

export default LoginMethodInformationModal;
