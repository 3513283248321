import {
    faUserLock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from 'styled-components';
import Colors from '../../../../../../assets/Colors';
import essityOnlyE from "../../../../../../assets/images/essity-only-e.svg";
import { localizer } from "../../../../../../utils/Localizer";
import { StyledLeft } from '../../../../../common/table/formatters/PositionContainers';
import { tooltipId } from "../../../../../common/tooltip/Tooltip";
import TextOverflowWrapper from '../../../../../wrappers/TextOverflowWrapper';


const StyledLogo = styled.img`
    width: 18px;
    margin-left: -17px;
    margin-right: 7px;
`;

export const StyledIconWithTooltipWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledFontAwesome = styled(FontAwesomeIcon) <any>`
    color: ${Colors.grey.mediumLight};
    font-size: 13px;
    margin-right: 10px;
    margin-left: 5px;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { activeDirectoryAccount, isLocked } = props.data.row.original;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledLeft data-qa={value.toLowerCase() + "-table_item"}>
            {activeDirectoryAccount && <StyledLogo src={essityOnlyE} />}
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                {value}
            </TextOverflowWrapper>
            <StyledIconWithTooltipWrapper
                data-tooltip-id={tooltipId}
                data-tooltip-content={localizer("users.tableRow.isLockedTooltip")} >
                {isLocked ? <StyledFontAwesome icon={faUserLock} data-qa={value.toLowerCase() + "-locked_icon"} /> : ""}
            </StyledIconWithTooltipWrapper>
        </StyledLeft>
    );
};

const UserNameFormatter = (data: any) => <CellFormatter data={data} />;
export default UserNameFormatter;




