import CareGroup from '../../../models/CareGroup';
import ActionsContainer from '../../common/table/formatters/ActionsContainer';
import CareGroupEditIcon from './CareGroupEditIcon';

const CareGroupActions = (props: any) => {
    return (
        <ActionsContainer>
            <CareGroupEditIcon
                nursingHomeGroup={new CareGroup(props.row.original)}
            />
        </ActionsContainer>
    );
};

export default CareGroupActions;