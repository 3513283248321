import { useState } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import infoIcon from "../../../assets/images/info.svg";
import { CareFacility } from "../../../models/CareFacility";
import { localizer } from "../../../utils/Localizer";
import CheckBox from "../../common/controls/CheckBox";
import { Section, SectionHeader, SectionTitle } from "../../common/forms/SectionStyle";
import { tooltipId } from "../../common/tooltip/Tooltip";
import { CareFacilityFields } from "../nursingHomes/CareFacilitiesHelper";
import SecuritySettingsInformationModal from "../users/common/SecuritySettingsInformationModal";
import { StyledImg } from "../users/common/UserEditFormStyle";

interface IPreferencesProps {
    nursingHome: CareFacility;
    handlePreferenceChanges: (fieldName: string, value: any) => void;
    validationErrors: string[];
    readonly?: boolean;
}

const StyledGap = styled.div`
    margin-bottom: 30px;
`;

const Subheader = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 12px;
`;

const SecuritySettings = (props: IPreferencesProps) => {
    const [showSecuritySettingsPopup, setShowSecuritySettingsPopup] = useState<boolean>(false)

    return (
        <Section>
            <SectionHeader>
                <SectionTitle>
                    <span>{localizer("setup.securitySettings")}</span>
                    <StyledImg
                        alt="info"
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={localizer("setup.securitySettings.tooltipInfo")}
                        data-tip={localizer("setup.securitySettings.tooltipInfo")}
                        src={infoIcon}
                        onClick={() => setShowSecuritySettingsPopup(!showSecuritySettingsPopup)}
                    />
                </SectionTitle>
            </SectionHeader>
            <div>
                <Subheader>
                    {localizer("setup.securitySettings.webPortal")}
                </Subheader>
                <CheckBox
                    disabled={props.readonly}
                    label={localizer("setup.securitySettings.is2FAActive.checkboxLabel")}
                    value={props.nursingHome.is2FactorAuthenticationEnabled}
                    fieldName={CareFacilityFields.Is2FAActive}
                    onChange={props.handlePreferenceChanges}
                />
                <StyledGap />
                <Subheader>
                    {localizer("setup.securitySettings.app")}
                </Subheader>
                <Row className="mb-2">
                    <CheckBox
                        disabled={props.readonly}
                        label={localizer("setup.securitySettings.is2FAActiveMobile.checkboxLabel")}
                        value={props.nursingHome.is2FactorAuthenticationEnabledMobile}
                        fieldName={CareFacilityFields.Is2FAActiveMobile}
                        onChange={props.handlePreferenceChanges}
                    />
                </Row>
                <Row className="mb-2">
                    <CheckBox
                        disabled={props.readonly}
                        label={localizer("setup.securitySettings.enforceStrongPasswordForCaregiver.checkboxLabel")}
                        value={!props.nursingHome.isWeakAuthenticationAllowed}
                        fieldName={CareFacilityFields.EnforceStrongPasswordForCaregiver}
                        onChange={props.handlePreferenceChanges}
                    />
                </Row>
                <Row>
                    <CheckBox
                        disabled={props.readonly}
                        label={localizer("setup.securitySettings.enforceEmailForCaregiver.checkboxLabel")}
                        value={props.nursingHome.enforceEmailForCaregiver}
                        fieldName={CareFacilityFields.EnforceEmailForCaregiver}
                        onChange={props.handlePreferenceChanges}
                    />
                </Row>
            </div>
            <SecuritySettingsInformationModal
                showInfoPopup={showSecuritySettingsPopup}
                setShowInfoPopup={setShowSecuritySettingsPopup}
            />
        </Section>
    );
};

export default SecuritySettings;
