
import styled, { css } from "styled-components";
import { Col, Form, Row } from "react-bootstrap";
import Colors from "../../../assets/Colors";
import CustomToggle from "../../common/controls/Toggle";

const commonColStyling = css`
    border-radius: 4px;
    padding:0 20px 0 20px;
`;

const productChosingSectionCommonStyling = css`
    border-radius: 4px;
    background-color: ${Colors.white.hoverWhite};
    padding:0 20px 0 20px;
`;

export const StyledEmptyCol = styled(Col)`
    ${commonColStyling}
    margin-right: 8px;
`;

export const StyledLeftCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-right: 8px;
`;

export const StyledRightCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    margin-left: 8px;
`;

export const StyledNightIcon = styled.div`
    height: 68px;
`;

export const StyledRecommendationRow = styled(Row)`
    height: 104px;
    border-radius: 4px;
    background-color: ${Colors.white.hoverWhite};
    padding: 0 20px 0 20px;
`;

export const StyledTitle = styled.h3`
    color: ${Colors.blue.brandingDark};
    font-size: 18px;
    padding: 0px;
`;

export const StyledLabel = styled.label`
    margin-bottom: .5rem
`;

export const StyledStripRightCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    padding: 20px;
    margin-left: 8px;
`;

export const StyledStripLeftCol = styled(Col)`
    ${productChosingSectionCommonStyling}
    padding: 20px;
    margin-right: 8px;
`;

export const StyledToggle = styled(CustomToggle)`
    margin-left: 3vh;
`;

export const StyledIconContainer = styled(Row)`
    margin-top: 30px;
`;

export const StyledFrom = styled(Form)`
    padding: 16px ;
`;

export const StyledSpaceSmall = styled.div`
    margin: 1rem 0.5rem;
`;