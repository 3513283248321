import styled from "styled-components";
import Colors from "../../../assets/Colors";

export const Section = styled.div`
    width: 100%;
    margin-bottom: 25px;
`;

export const SectionHeader = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
    color: ${Colors.blue.brandingDark};
    font-size: 20px;
    font-weight: 600;
`;

export const RequiredFields = styled.div`
    border-top: 1px solid ${Colors.grey.mediumLight};
    text-align: right;
    font-size: 12px;
    font-style: italic;
    position: absolute;
    width: 100%
`;

export const CaptionMedium = styled.div`
    color: ${Colors.grey.dark};
    font-size: 14px;
    font-weight: 500;
`;
