import { localizer } from '../../../utils/Localizer';
import uniqBy from "lodash/uniqBy";
import { IDropDownOption } from '../../common/controls/DropdownButtonNew';
import { IFilters } from './filters/IFilters';
import * as auditKeys from "./AuditKeys";
import { DateFilter } from '../../../models/DateFilter';
import moment, { Moment } from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';

export const getOptionalDropDownOptions = (data: any[], dataKey: string): IDropDownOption[] => {
    const entries = data.map(entry => ({
        label: entry[dataKey],
        value: entry[dataKey],
    }));

    const uniqueEntries = uniqBy(entries, 'value');

    uniqueEntries.unshift({
        label: localizer('dropDown.all'),
        value: null,
    });

    return uniqueEntries;
};

export const allAuditTypes = {
    resident: 'resident',
    user: 'user',
    admin: 'admin',
    login: 'login',
    adminLogin: 'adminLogin',
    nursingHomeGroupManager: 'nursingHomeGroupManager',
};

export const parsetToDateOrNull = (value: string, defaultDate: Moment) => {
    if (value) {
        return moment(value);
    } else {
        return defaultDate;
    }
};

export const defaultFilters: (auditType?: string) => IFilters = (auditType: string = allAuditTypes.resident) => ({
    [auditKeys.auditTypeKey]: reactLocalStorage.get(auditKeys.auditTypeKey, auditType),
    [auditKeys.auditTimePeriodKey]: parseInt(reactLocalStorage.get(auditKeys.auditTimePeriodKey, DateFilter.Week)),
    [auditKeys.auditTimePeriodStartKey]: parsetToDateOrNull(reactLocalStorage.get(auditKeys.auditTimePeriodStartKey), moment().subtract(6, 'd')),
    [auditKeys.auditTimePeriodEndKey]: parsetToDateOrNull(reactLocalStorage.get(auditKeys.auditTimePeriodEndKey), moment()),
    [auditKeys.auditUserKey]: reactLocalStorage.get(auditKeys.auditUserKey, ''),
    [auditKeys.auditResidentKey]: fixNull(reactLocalStorage.get(auditKeys.auditResidentKey, null)),
    [auditKeys.auditModifiedByKey]: fixNull(reactLocalStorage.get(auditKeys.auditModifiedByKey, null)),
});

export const fixNull = (value: string) => (value === 'null' ? null : value);
