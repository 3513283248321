enum ToastMessages {
    UpdateUserSuccessful = "toast.updateUser.success",
    UpdateUserError = "toast.updateUser.error",
    UpdateUserPasswordSuccessful = "toast.updateUserPassword.success",
    UpdateUserPasswordError = "toast.updateUserPassword.error",
    TooManyVerificationCodeAttempts = 'toast.2faLogin.error',
    CreateUserSuccessful = "toast.createUser.success",
    CreateUserError = "toast.createUser.error",
    DeleteUserSuccessful = "toast.deleteUser.success",
    DeleteUserError = "toast.deleteUser.error",
    UpdateWardSuccessful = "toast.updateWard.success",
    UpdateWardError = "toast.updateWard.error",
    CreateWardSuccessful = "toast.createWard.success",
    CreateWardError = "toast.createWard.error",
    DeleteWardSuccessful = "toast.deleteWard.success",
    DeleteWardError = "toast.deleteWard.error",
    UpdateCareRecipientSuccessful = "toast.updateCareRecipient.success",
    UpdateCareRecipientError = "toast.updateCareRecipient.error",
    CreateCareRecipientSuccessful = "toast.createCareRecipient.success",
    CreateCareRecipientError = "toast.createCareRecipient.error",
    DeleteCareRecipientSuccessful = "toast.deleteCareRecipient.success",
    DeleteCareRecipientError = "toast.deleteCareRecipient.error",
    UpdateCareFacilitySuccessful = "toast.updateCareFacility.success",
    UpdateCareFacilityError = "toast.updateCareFacility.error",
    NursingHomeTimeShiftError = "toast.nursingHome.timeShift.error",
    CreateCareFacilitySuccessful = "toast.createCareFacility.success",
    CreateCareFacilityError = "toast.createCareFacility.error",
    LoadCareFacilityNotFoundError = "toast.loadCareFacility.notFound.error",
    DeleteCareFacilitySuccessful = "toast.deleteCareFacility.success",
    DeleteCareFacilityExistingWardsError = "toast.deleteCareFacility.wards.error",
    DeleteCareFacilityError = "toast.deleteCareFacility.error",
    PageLoadingError = "toast.pageLoading.error",
    DataLoadingError = "toast.dataLoading.error",
    UpdateCareGroupSuccessful = "toast.updateCareGroup.success",
    UpdateCareGroupError = "toast.updateCareGroup.error",
    CreateCareGroupSuccessful = "toast.createCareGroup.success",
    CreateCareGroupError = "toast.createCareGroup.error",
    DeleteCareGroupSuccessful = "toast.deleteCareGroup.success",
    DeleteCareGroupRestrictionError = "toast.deleteCareGroup.restriction.error",
    DeleteCareGroupError = "toast.deleteCareGroup.error",
    NoNursingHomeGroupsError = "toast.noNursingHomeGroups.error",
    UpdateCountrySuccessful = "toast.updateCountry.success",
    UpdateCountryError = "toast.updateCountry.error",
    CreateCountrySuccessful = "toast.createCountry.success",
    CreateCountryError = "toast.createCountry.error",
    DeleteCountrySuccessful = "toast.deleteCountry.success",
    DeleteCountryError = "toast.deleteCountry.error",
    DeleteCountryErrorHasConnectedHomes = "countries.deleteCountryError.hasConnectedHomes",
}

export default ToastMessages;
