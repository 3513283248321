import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import { appInsights } from "../AppInsights";
import { LocalStorageKeys } from "../data/Persistence";
import { RestClient } from "../data/RestClient";
import { CustomerAdUserManagementFlow } from "../models/ECustomerAdUserManagementFlow";
import { Role, Roles } from "../models/Role";
import { parseErrorToString } from "../utils/AppInsightsHelpers";
import { flatten } from "../utils/SliceHelpers";
import { populateRedux } from "./dataHelper";

export interface IUserResourcesHierarchy {
    children: IUserResourcesHierarchy[];
    id: number;
    externalId?: string;
    name: string;
    type: string;
    isSupportUser: boolean;
    roles: Roles[];
    isWeakAuthenticationAllowed?: boolean;
    enforceEmailForCaregiver?: boolean;
    isActive?: boolean;
    customerAdUserManagementFlow: CustomerAdUserManagementFlow;
}

export interface IDirtyFormContext {
    isDirty: boolean;
    popupTitleId: string;
    popupMessageId: string;
    popupConfirmTextId: string;
    callback?: () => void;
}

export interface ICareFacilityIntegration {
    id: string
    name: string
}

export interface IContextStateType {
    id: string;
    role: Role;
    inactiveTimeInMinutesBeforeLogout: number;
    userResourcesHierarchy: IUserResourcesHierarchy[];
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    isPending: boolean;
    userName: string;
    loggedInUserId: number;
    isTermsOfUseAcceptanceNeeded: boolean;
    dirtyFormContext: IDirtyFormContext;
    countries: string[];
    selectedResourceName?: string;
    assignedProducts: string[];
    allNHResources: IUserResourcesHierarchy[];
    initialRole: Role;
    externalCareFacilityIntegrations: ICareFacilityIntegration[];
    language: string;
    redirectUrl: string | null;
}

const initialState: IContextStateType = {
    id: "",
    role: Roles.Invalid,
    inactiveTimeInMinutesBeforeLogout: 60,
    userResourcesHierarchy: [],
    isAuthenticated: false,
    isPending: false,
    userName: "",
    loggedInUserId: -1,
    isTermsOfUseAcceptanceNeeded: false,
    countries: [],
    assignedProducts: [],
    allNHResources: [],
    dirtyFormContext: {
        isDirty: false,
        popupTitleId: "",
        popupMessageId: "",
        popupConfirmTextId: "",
    },
    initialRole: Roles.Invalid,
    externalCareFacilityIntegrations: [],
    language: "",
    redirectUrl: null,
    isAuthenticating: true
};

export const contextSlice = createSlice({
    name: "context",
    initialState,
    reducers: {

        setIsAuthenticating: (state, action) => {
            state.isAuthenticating = action.payload;
        },

        setContext: (state, action) => {
            state.id = action.payload.id;
            state.role = action.payload.role;
        },

        setInactiveTimeInMinutesBeforeLogout: (state, action) => {
            state.inactiveTimeInMinutesBeforeLogout = action.payload;
        },

        setUserResourcesHierarchy: (state, action) => {
            state.userResourcesHierarchy = action.payload;
        },

        setUsername: (state, action) => {
            state.userName = action.payload;
        },

        setLoggedInUserId: (state, action) => {
            state.loggedInUserId = action.payload;
        },

        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },

        setIsTermsOfUseAcceptanceNeeded: (state, action) => {
            state.isTermsOfUseAcceptanceNeeded = action.payload;
        },

        setCountries: (state, action) => {
            state.countries = action.payload;
        },

        setIsPending: (state, action) => {
            state.isPending = action.payload;
        },

        setDirtyFormContext: (state, action) => {
            state.dirtyFormContext = action.payload;
        },

        setSelectedResourceName: (state, action) => {
            state.selectedResourceName = action.payload;
        },

        setAssignedProducts: (state, action) => {
            state.assignedProducts = action.payload;
        },

        setRole: (state, action) => {
            state.role = action.payload;
        },

        setInitialRole: (state, action) => {
            state.initialRole = action.payload;
        },

        setAllNhResources: (state, action) => {
            state.allNHResources = action.payload;
        },

        setLanguage: (state, action) => {
            state.language = action.payload;
        },

        setExternalCareFacilityIntegrations: (state, action) => {
            state.externalCareFacilityIntegrations = action.payload;
        },

        setRedirectUrl: (state, action) => {
            state.redirectUrl = action.payload;
        },

        clearContextSlice: (_state) => ({
            ...initialState,
            isAuthenticating: false,
            redirectUrl: _state.redirectUrl
        })
    },
});

export const {
    setIsAuthenticating,
    setContext,
    setInactiveTimeInMinutesBeforeLogout,
    setUserResourcesHierarchy,
    setIsAuthenticated,
    clearContextSlice,
    setUsername,
    setLoggedInUserId,
    setDirtyFormContext,
    setSelectedResourceName,
    setAssignedProducts,
    setIsTermsOfUseAcceptanceNeeded,
    setCountries,
    setRole,
    setAllNhResources,
    setInitialRole,
    setExternalCareFacilityIntegrations,
    setLanguage,
    setRedirectUrl
} = contextSlice.actions;

export const contextChange = (newId: string, newRole: Role) => async (
    dispatch: any
) => {
    dispatch(setContext({ id: newId, role: newRole }));
    await populateRedux(newRole, newId);
};

export const setFormDirty = (isFormDirty: boolean, popupTitleId?: string, popupMessageId?: string, popupConfirmTextId?: string, callback?: () => void) => async (
    dispatch: any
) => {
    const dirtyFormContext: IDirtyFormContext = {
        isDirty: isFormDirty,
        popupTitleId: popupTitleId ?? "",
        popupMessageId: popupMessageId ?? "",
        popupConfirmTextId: popupConfirmTextId ?? "",
        callback
    }
    dispatch(setDirtyFormContext(dirtyFormContext));
}

export const fetchGlobalConfig = () => async (dispatch: any) => {
    try {
        const response = await RestClient.getGlobalConfig();
        dispatch(setExternalCareFacilityIntegrations(response.externalNursingHomeIntegrations));
    } catch (error) {
        console.error(error);
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
}

export const fetchResources = (avoidLogoutMessage: boolean = false) => async (dispatch: any) => {
    try {
        const response = await RestClient.getResources(avoidLogoutMessage);
        const { id } = reactLocalStorage.getObject(
            LocalStorageKeys.ResourceContext,
            {}
        );

        const { countries, isTermsOfUseAcceptanceNeeded, userId, userName, userResources, products, role, languageCode } = response;

        const flattenedUserResources = flatten(userResources);
        const careFacility = flattenedUserResources.find(userResource => userResource.id === parseInt(id))
        dispatch(setAllNhResources(flattenedUserResources));
        dispatch(setUserResourcesHierarchy(userResources));
        dispatch(setAssignedProducts(products));
        dispatch(setUsername(userName));
        dispatch(setLoggedInUserId(userId));
        dispatch(setIsAuthenticated(true));
        dispatch(setIsAuthenticating(false));
        dispatch(setIsTermsOfUseAcceptanceNeeded(isTermsOfUseAcceptanceNeeded));
        dispatch(setCountries(countries));
        dispatch(setRole(role));
        dispatch(setInitialRole(role));
        dispatch(setLanguage(languageCode));
        toast.dismiss("toastLogoutInfo");

        if (id && careFacility) {
            dispatch(contextChange(id, careFacility.isSupportUser ? Roles.Support : role as Roles));
            dispatch(setSelectedResourceName(careFacility.name));
        } else {
            dispatch(contextChange("", role as Roles));
        }
    } catch {
        dispatch(setIsAuthenticating(false));
        dispatch(setIsAuthenticated(false));
    }
};

export default contextSlice.reducer;
