import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { Ward } from "../../../../models/Ward";
import { useAppSelector } from "../../../../hooks/useAppSelector";

const WardFormatter = (props: any): React.ReactElement => {
    const wards = useAppSelector<Ward[]>(state => state.wardSlice.wards);
    const { value } = props;
    const { hideTooltipOnProd } = props.column;
  
    const wardName = wards.find(ward => ward.id === value.toString())?.name || "";
    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={wardName} hideTooltipOnProd={hideTooltipOnProd}>
                {wardName}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

export default WardFormatter;
