import ErrorCodes from "./ErrorCodes";

export abstract class Mutable {
    id: string;
    name: string;

    protected constructor(json?: any) {
        if (!json) json = {};
        this.id = json.id ? String(json.id) : '';
        this.name = json.name || '';
    }

    validateSelf(): ErrorCodes[] {
        const errors = [];
        if (!this.name || this.name.length < 1) {
            errors.push(ErrorCodes.EmptyNameError);
        }
        return errors;
    }
}