import { SocketStatus } from "../data/SocketClient";
import { useAppSelector } from "../hooks/useAppSelector";
import ConnectedIndicator from "./ConnectedIndicator";
import Navbar, { LinkData } from "./Navbar";
import Portal from "./Portal";

interface INavbarNurseProps {
    linksData: LinkData[];
    hideButtons?: boolean;
}

const NavbarNurse = (props: INavbarNurseProps) => {
    const socketStatus = useAppSelector<SocketStatus>(state => state.nursingHomeSlice.socketStatus);

    return (
        <>
            <Navbar linksData={props.linksData} />
            <Portal>
                {socketStatus === SocketStatus.OK && <ConnectedIndicator />}
            </Portal>
        </>
    );
};

export default NavbarNurse;
