import moment, { Moment } from "moment";
import { DateFilter } from "../../../models/DateFilter";
import { DateTimeUtils } from "../../../models/DateTimeUtils";
import { Resident } from "../../../models/Resident";
import { ResidentEventType } from "../../../models/ResidentEventType";
import "../../../styles/main.scss";
import {
    convertMinutesToHours,
    convertTimeToHtml,
    convertTimeToString
} from "../../../utils/ConvertMinutesToHours";
import { isProdEnv } from "../../../utils/EnvCheck";
import { EMPTY_VALUE, localizer } from "../../../utils/Localizer";
import InformationBlock from "../../../views/InformationBlock/InformationBlock";
import DropdownDateTimeRangeButton from "../../common/controls/dateTime/helpers/DropdownDateTimeRangeButton";
import { PickerType } from "../../common/controls/dateTime/helpers/PickerType";
import TimeRangePickerDropdown from "../../common/controls/dateTime/TimeRangePickerDropdown";
import NumberInformationBlock from "../../common/informationBlock/NumberInformationBlock";
import {
    StyledDropdownsContainer, StyledInformationBlocks, StyledInformationBlockWrapper, StyledSpan
} from "../../common/View";
import "./ResidentHistory.scss";
import "./ResidentHistoryGeneralInfo.scss";

interface IResidentHistoryGeneralInfoProps {
    resident: Resident;
    selectedEventType: ResidentEventType;
    selectedDateFilter: number;
    selectedStartDate: Moment;
    selectedEndDate: Moment;
    selectedStartTime: Moment | null;
    selectedEndTime: Moment | null;
    averageResponseTime: number | null;
    changesPerResident: number | null;
    selectedTimeFilter: string;
    handleOptionSelection: (name: string, value: any) => void;
    startDateChanged: (value: Moment | any) => void;
    endDateChanged: (value: Moment | any) => void;
    startTimeChanged: (value: Moment | null) => void;
    endTimeChanged: (value: Moment | null) => void;
}

const ResidentHistoryGeneralInfo = (props: IResidentHistoryGeneralInfoProps) => {
    const averageResponseTimeFormatted = convertTimeToString(
        convertMinutesToHours(props.averageResponseTime),
        true
    );

    const averageChangesPerShift =
        props.changesPerResident || props.changesPerResident === 0
            ? Math.round(props.changesPerResident)
            : EMPTY_VALUE;

    const specificXAxisOffsetValue = 214;

    return (
        <>
            <StyledDropdownsContainer>
                <InformationBlock
                    title="residents.historyGeneralInfo.date"
                    width={300}
                >
                    <DropdownDateTimeRangeButton
                        amountOfDays={30}
                        id="dateDropdown"
                        options={DateTimeUtils.getDateFilterOptions([DateFilter.Today, DateFilter.Yesterday, DateFilter.Week, DateFilter.HalfMonth, DateFilter.Month, DateFilter.CustomRange])}
                        handleChange={props.handleOptionSelection}
                        selectedValue={props.selectedDateFilter}
                        name="dateSelected"
                        start={props.selectedStartDate}
                        end={props.selectedEndDate}
                        handleStartDateTimeChange={
                            props.startDateChanged
                        }
                        handleEndDateTimeChange={
                            props.endDateChanged
                        }
                        pickerType={PickerType.Date}
                        offsetValue={specificXAxisOffsetValue}
                        endPickerPropertiesInitialMaxDate={moment()}
                        startPickerPropertiesInitialMinDate={moment(props.resident.createdDate)}
                    />
                </InformationBlock>
                <InformationBlock
                    title="residents.historyGeneralInfo.time"
                    width={300}
                >
                    <TimeRangePickerDropdown
                        name="timeSelected"
                        value={props.selectedTimeFilter}
                        onChange={props.handleOptionSelection}
                        options={DateTimeUtils.getTimeFilterOptions()}
                        handleStartDateTimeChange={
                            props.startTimeChanged
                        }
                        handleEndDateTimeChange={props.endTimeChanged}
                        start={props.selectedStartTime}
                        end={props.selectedEndTime}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>

            <StyledInformationBlocks>
                <StyledInformationBlockWrapper
                    title={
                        !isProdEnv() && averageResponseTimeFormatted
                            ? String(averageResponseTimeFormatted)
                            : ""
                    }
                    minWidth={416}
                >
                    <NumberInformationBlock
                        header={localizer(
                            "residents.informationBlocks.title.avgTime"
                        )}
                        description={localizer(
                            "home.avgResponseTimes30Days.description"
                        )}
                        value={convertTimeToHtml(
                            convertMinutesToHours(
                                props.averageResponseTime
                            ),
                            true
                        )}
                    />
                </StyledInformationBlockWrapper>

                <StyledInformationBlockWrapper
                    title={
                        !isProdEnv() && averageChangesPerShift
                            ? String(averageChangesPerShift)
                            : ""
                    }
                    minWidth={416}
                >
                    <NumberInformationBlock
                        header={localizer(
                            "residents.historyGeneralInfo.changes"
                        )}
                        description={localizer(
                            "residents.history.productChanges"
                        )}
                        value={
                            <>
                                {averageChangesPerShift}
                                <StyledSpan>
                                    {localizer(
                                        "constants.changeEventsFilter.kpi.changes"
                                    )}
                                </StyledSpan>
                            </>
                        }
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks>
        </>
    );
}

export default ResidentHistoryGeneralInfo;