import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { cloneDeep, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ButtonContainer, PageHeader, StyledButton, StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { Context, Fields } from "../components/themed/users/common/UserExternalEditForm";
import UserInternalEditForm from "../components/themed/users/common/UserInternalEditForm";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import ErrorCodes from "../models/ErrorCodes";
import { ICountryListItem } from "../models/ICountryListItem";
import InternalUser from "../models/InternalUser";
import { Roles } from "../models/Role";
import { IContextStateType, IUserResourcesHierarchy, setFormDirty } from "../reducers/contextSlice";
import { IUserStateType, ResourceTypes, createInternalUser, fetchInternalUsers } from "../reducers/userSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { emailRegex, phoneRegex } from "../utils/Regex";
import GenericConfirmationForm from "../views/GenericConfirmationForm";

const AddInternalUserView = () => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { users } = useAppSelector<IUserStateType>((state) => state.userSlice);
    const [userEntity, setUserEntity] = useState<InternalUser>(new InternalUser({ role: Roles.GlobalAdmin }));
    const [filterValue, setFilterValue] = useState<string>("");
    const [validationErrors, setErrors] = useState<any[]>([]);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [countries, setCountries] = useState<ICountryListItem[]>([]);
    const initialState = cloneDeep(userEntity);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const leaveFormTitleId="general.leaveTitle";
    const leaveFormMessageId = "general.leaveMessage";
    const leaveFormConfirmTextId = "constants.leave";

    const {
        email,
        mobileNumber,
        language,
        assignedResourceIds,
        role: selectedRole,
    } = userEntity

    const handleChange = useCallback((name: string, value: any) => {
        const user = cloneDeep(userEntity);
        switch (name) {
            case Fields.Email:
                user.email = value;
                break;
            case Fields.Mobile:
                user.mobileNumber = value;
                break;
            case Fields.Language:
                user.language = value;
                break;
            case Fields.Role:
                user.role = value;
                break;
            case Fields.AssignedResources:
                user.assignedResourceIds = value;
                break;
            default:
                break;
        }        
        if (!isFormDirty && !isEqual(initialState, user)) {
            dispatch(setFormDirty(true, leaveFormTitleId, leaveFormMessageId, leaveFormConfirmTextId));
        } else if (isFormDirty && isEqual(initialState, user)) {
            dispatch(setFormDirty(false));
        }
        setUserEntity(user);
    }, [userEntity]);

    useEffect(() => {
        setCountries(mapCountries(context.userResourcesHierarchy));
    }, [context.role, context.userResourcesHierarchy]);

    useEffect(() => {
        if (!users.length) {
            dispatch(fetchInternalUsers())
        }
    }, [dispatch, users]);



    const mapCountries = (countries: IUserResourcesHierarchy[]) => {
        return countries.map(({ name, id }) => ({ code: name, id }));
    }

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!email) {
            errors.push(ErrorCodes.FaultyNameErrorEmpty);
        }

        if ((email && email.length > 0) && !emailRegex.test(email.trim().toLowerCase())) {
            errors.push(ErrorCodes.FaultyNameErrorEmail);
        }

        if (users.some(user => user.name.toLocaleLowerCase() === email.toLocaleLowerCase())) {
            errors.push(ErrorCodes.UserAlreadyExists);
        }

        if (mobileNumber && mobileNumber.length > 0 && !phoneRegex.test(mobileNumber.trim().toLowerCase())) {
            errors.push({
                dependentErrorCode: ErrorCodes.FaultyMobileNumber,
                values: { mobileNumber },
            });
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        try {
            await submitInternalRole();
            navigate(routePaths.users);
            dispatch(setFormDirty(false));
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error?.status === 404) {
                setErrors([ErrorCodes.AccountDoesNotExistInAD]);
            }
        }
    };

    const submitInternalRole = () => {
        const resourceType = selectedRole === Roles.GlobalAdmin ? ResourceTypes.Global : ResourceTypes.Country;
        const countries = selectedRole === Roles.CountryAdmin ? assignedResourceIds : mapCountries(context.userResourcesHierarchy).map(({ id }) => id);

        return dispatch(createInternalUser(email,
            selectedRole,
            resourceType,
            countries,
            language ?? undefined,
            mobileNumber
        ));
    }

    const disableAddButton = () => {
        if (selectedRole === Roles.CountryAdmin) {
            return !email || assignedResourceIds.length < 1 || !language;
        }
        return !email || !language;
    }

    const radioRoleValues = [
        { value: Roles.GlobalAdmin, label: localizer("user.role." + Roles.GlobalAdmin) },
        { value: Roles.CountryAdmin, label: localizer("user.role." + Roles.CountryAdmin) }
    ]

    return (
        <StyledViewContainer>
            <PageHeader>{localizer("users.addUser")}</PageHeader>
            <UserInternalEditForm
                formContext={Context.Add}
                user={userEntity}
                onSubmit={onSubmit}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                validationErrors={validationErrors}
                radioRoleValues={radioRoleValues}
                handleChange={handleChange}
                countries={countries}
            >
                <ButtonContainer>
                    <BackHardButton
                        backRoute={routePaths.users}
                        onClick={() => !isEqual(initialState, userEntity) ? setConfirmLeave(true) : navigate(routePaths.users)}
                    />
                    <StyledButton
                        variant={"primary"}
                        type={"submit"}
                        disabled={disableAddButton()}
                    >
                        {localizer("users.addButton.title")}
                    </StyledButton>
                </ButtonContainer>
            </UserInternalEditForm>
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={leaveFormMessageId}
                            confirmButton={leaveFormConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                navigate(routePaths.users);
                            }}
                        />
                    }
                    modalTitle={localizer(leaveFormTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </StyledViewContainer>
    );
};

export default withAITracking(reactPlugin, AddInternalUserView);

