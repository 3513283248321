import { KpiType } from "../../../models/KpiType";
import { IStatisticValues } from "../../../models/StatisticValues";
import { convertMinutesToHours, convertTimeToHtml } from "../../../utils/ConvertMinutesToHours";
import { localizer } from "../../../utils/Localizer";
import { getTrendIcon, getTrendValue } from "../../../utils/StatisticsHelpers";
import { EBlockSize, ClickableStyledInformationBlockWrapper, IconContainer, StyledTrendContainer, StyledValueContainer } from "../../common/informationBlock/styled";
import NumberInformationBlock from "../informationBlock/NumberInformationBlock";

interface IResponseTimeBlockProps {
    selectedKpi: KpiType;
    responseTime: IStatisticValues;
    handleDropdownSelection: (name: string, value: any) => void;
    size: EBlockSize;
}

const ResponseTimeBlock = (props: IResponseTimeBlockProps) => {

    const {
        selectedKpi,
        handleDropdownSelection,
        responseTime,
        size
    } = props;

    return (
        <ClickableStyledInformationBlockWrapper
            size={size}
            alignItems="start"
            isSelected={
                selectedKpi === KpiType.ResponseTime
            }
            onClick={() =>
                handleDropdownSelection(
                    "kpiSelected",
                    KpiType.ResponseTime
                )
            }
        >
            <NumberInformationBlock
                header={localizer(
                    "constants.changeEventsFilter.kpi.responseTime"
                )}
                description={localizer(
                    "statistics.graphHeader.responseTimeAverage"
                )}
                isSelected={selectedKpi === KpiType.ResponseTime}
                value={
                    <>
                        <StyledValueContainer>
                            {convertTimeToHtml(
                                convertMinutesToHours(
                                    responseTime.value
                                ),
                                true,
                                true
                            )}
                        </StyledValueContainer>
                        {responseTime.value !== null && (
                            <StyledTrendContainer>
                                <IconContainer>{getTrendIcon(responseTime.trend)}</IconContainer>
                                <div>{getTrendValue(responseTime.trend)}{responseTime.trend !== null && "%"}</div>
                            </StyledTrendContainer>)}
                    </>
                }
            />
        </ClickableStyledInformationBlockWrapper>
    )
}

export default ResponseTimeBlock;