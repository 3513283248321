import { StyledLeft } from '../../../common/table/formatters/PositionContainers';
import { convertMinutesToHours, convertTimeToHtml } from "../../../../utils/ConvertMinutesToHours";

const TimeFormatter = (props: any) => {
    const { value } = props;
    return (
        <StyledLeft>
            <span>
                {value ? convertTimeToHtml(
                    convertMinutesToHours(Math.abs(value)),
                    true,
                    false
                ) : "--"}
            </span>
        </StyledLeft>
    );
};

export default TimeFormatter;