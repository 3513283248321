import { ReactComponent as EditIcon } from "../../../assets/images/editIcon.svg";
import CareGroup from "../../../models/CareGroup";
import { localizer } from "../../../utils/Localizer";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import NursingHomeGroupEditForm from "./CareGroupEditForm";

import { IconWithText } from "../../common/table/IconWithText";

interface IProps {
    nursingHomeGroup: CareGroup;
}

const CareGroupGroupEditIcon = (props: IProps) => {
    const triggerComponent: React.ReactNode = (
        <IconWithText
            icon={<EditIcon />}
            textId={"nursingHomes.editButton"}
        />
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={
                <NursingHomeGroupEditForm entity={props.nursingHomeGroup} />
            }
            modalTitle={localizer("careGroup.edit")}
            size={EModalSize.S}
        />
    );
};

export default CareGroupGroupEditIcon;
