import React from "react";
import "./AddEditWardForm.scss";
import { Form, Row } from "react-bootstrap";
import ConfirmDeleteForm from "../../common/forms/ConfirmDeleteForm";
import { Ward } from "../../../models/Ward";
import { ResponseError } from "superagent";
import ErrorCodes from "../../../models/ErrorCodes";
import CheckBox from "../../common/controls/CheckBox";
import { ValidatingForm } from "../../../views/ValidatingForm";
import { toastError, toastSuccess } from "../../../utils/Toaster";
import { localizer } from "../../../utils/Localizer";
import ToastMessages from "../../../models/ToastMessages";
import { connect } from "react-redux";
import { CareFacility } from "../../../models/CareFacility";
import { RootState } from "../../../store";
import { createWard, deleteWard, updateWard } from "../../../reducers/wardSlice";
import cloneDeep from "lodash/cloneDeep";
import { wardToReqBody } from "../../../reducers/wardHelper";
import ValidatedFormGroupWrapper from "../../wrappers/ValidatedFormGroupWrapper";
import TextField from "../../common/controls/TextFieldNew";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../../AppInsights";
import { ETrackEvent, parseErrorToString } from "../../../utils/AppInsightsHelpers";
import AddEditModalButtonGroup from "../../../views/Buttons/AddEditModalButtonGroup";

enum Fields {
    Name = 'Name',
    IsActive = 'IsActive'
}

enum EditStates {
    Edit,
    ConfirmDelete
}

class AddEditWardForm extends ValidatingForm<Ward> {

    getNewInstance(entity?: Ward): Ward {
        return entity ? cloneDeep(entity) : new Ward();
    }

    onDiscard = (event: any) => {
        event.preventDefault();
        if (this.props?.hideModal) {
            this.props.hideModal();
        }
    };

    onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await this.validate();
        if (!isValid) {
            return;
        }
        this.sendWard(false);
    };

    onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await this.validate();
        if (!isValid) {
            return;
        }
        this.sendWard(true);
    };

    onDelete = (event: any) => {
        event.preventDefault();
        if (this.state.entity.id) {
            this.setState({
                editState: EditStates.ConfirmDelete
            });
            if (this.props?.setDeleteMode) {
                this.props.setDeleteMode();
            }
        }
    };

    onDeleteConfirm = (event: any) => {
        event.preventDefault();
        this.deleteWard();
    };

    onConfirmCancel = (event: any) => {
        event.preventDefault();
        this.setState({
            editState: EditStates.Edit
        });
        if (this.props?.setEditMode) {
            this.props.setEditMode();
        }
    };

    handleChange = (name: string, value: any) => {
        const ward = this.state.entity;
        switch (name) {
            case Fields.Name:
                ward.name = value;
                break;
            case Fields.IsActive:
                ward.isActive = value;
                break;
        }
        this.setState({ entity: ward });
    };

    sendWard = (addMore: boolean) => {
        const ward = this.state.entity;
        if (this.state.isEdit) {
            (this.props as IDispatchProps).updateWard(wardToReqBody(ward))
                .then(() => {
                    toastSuccess(localizer(ToastMessages.UpdateWardSuccessful));
                    if (this.props?.hideModal) {
                        this.props.hideModal();
                    }
                    appInsights.trackEvent({ name: ETrackEvent.SettingsUpdateWard });
                })
                .catch((error: ResponseError) => {
                    toastError(localizer(ToastMessages.UpdateWardError));

                    console.error(error);
                    appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
                });
        } else {
            (this.props as IDispatchProps).createWard(wardToReqBody(ward))
                .then(() => {
                    toastSuccess(localizer(ToastMessages.CreateWardSuccessful));
                    if (addMore) {
                        this.clearFormData();
                    } else if (this.props?.hideModal) {
                        this.props.hideModal();
                    }
                    appInsights.trackEvent({ name: ETrackEvent.SettingsAddWard });
                })
                .catch((error: ResponseError) => {
                    toastError(localizer(ToastMessages.CreateWardError));

                    console.error(error);
                    appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
                });
        }
    };

    deleteWard = () => {
        (this.props as IDispatchProps).deleteWard(this.state.entity.id)
            .then(() => {
                toastSuccess(localizer(ToastMessages.DeleteWardSuccessful));
                if (this.props?.hideModal) {
                    this.props.hideModal();
                }
                appInsights.trackEvent({ name: ETrackEvent.SettingsDeleteWard });
            })
            .catch((error: ResponseError) => {
                toastError(localizer(ToastMessages.DeleteWardError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
            });
    };

    validate = async (): Promise<boolean> => {
        await super.validate();

        const errors = [];
        if (!this.state.isEdit) {
            const wards = (this.props as IReduxProps).wards;
            if (!this.state.entity.name.trim()) {
                errors.push(ErrorCodes.EmptyNameError);
            } else {
                const duplicatedWard = wards.find(ward => ward.name?.toLowerCase() === this.state.entity.name.toLowerCase());
                if (duplicatedWard) {
                    errors.push(ErrorCodes.DuplicateNameError);
                }
            }
        }

        this.setState({ validationErrors: errors });
        return Promise.resolve(errors.length < 1);
    };

    render(): React.ReactElement {

        const addEditForm = (
            <React.Fragment>
                <Form>
                    <Row className="row g-0">
                        <ValidatedFormGroupWrapper
                            validationErrors={this.state.validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyNameError, ErrorCodes.DuplicateNameError]}
                            label={'setup.addEditWardForm.wardName'}
                        >
                            <TextField
                                data-qa="ward_name_input_text"
                                fieldName={Fields.Name}
                                value={this.state.entity.name}
                                onChange={this.handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="row g-0">
                        <CheckBox
                            label={localizer("setup.addEditWardForm.active")}
                            value={this.state.entity.isActive}
                            fieldName={Fields.IsActive}
                            onChange={this.handleChange}
                        />
                    </Row>
                    <Row className="row g-0 mt-3">
                        <AddEditModalButtonGroup
                            isEdit={this.state.isEdit}
                            onSave={this.onSave}
                            onSaveAndAddAnother={this.onSaveAndAddAnother}
                            onDiscard={this.onDiscard}
                            extraActions={this.state.isEdit ? [
                                {
                                    label: "setup.addEditWardForm.deleteWard",
                                    action: this.onDelete
                                }
                            ] : []} />
                    </Row>
                </Form>
            </React.Fragment>
        );
        const confirmDeleteForm = (
            <React.Fragment>
                <ConfirmDeleteForm
                    title={localizer("setup.deleteWardPopup.ward")}
                    onConfirmCancel={this.onConfirmCancel}
                    onDeleteConfirm={this.onDeleteConfirm}
                />
            </React.Fragment>
        );
        return this.state.editState === EditStates.ConfirmDelete ? confirmDeleteForm : addEditForm;
    }
}

interface IDispatchProps {
    updateWard: (requestBody: any) => Promise<any>;
    createWard: (requestBody: any) => Promise<any>;
    deleteWard: (id: string) => Promise<any>;
}

interface IReduxProps {
    wards: Ward[];
    nursingHome: CareFacility;
}

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return {
        updateWard: (requestBody: any) => dispatch(updateWard(requestBody)),
        createWard: (requestBody: any) => dispatch(createWard(requestBody)),
        deleteWard: (id: string) => dispatch(deleteWard(id)),
    };
};

const mapStateToProps = (state: RootState): IReduxProps => {
    return {
        wards: state.wardSlice.wards,
        nursingHome: state.nursingHomeSlice.nursingHome || new CareFacility()
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditWardForm);
