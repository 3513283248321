import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { StyledViewContainer } from "../components/common/View";
import CareFacilitiesTable from "../components/themed/nursingHomes/CareFacilitiesTable";
import { useAppSelector } from "../hooks/useAppSelector";
import { CareFacility } from "../models/CareFacility";

const CareFacilityView = () => {
    const careFacilities = useAppSelector<CareFacility[]>(state => state.nursingHomeSlice.nursingHomes);

    return (
        <StyledViewContainer>
            <CareFacilitiesTable
                data={careFacilities}
            />
        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, CareFacilityView);
