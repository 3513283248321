import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { appInsights, reactPlugin } from "../AppInsights";
import { StyledViewContainer } from "../components/common/View";
import MonitoringTable from "../components/themed/resident/MonitoringTable";
import ResidentsInformationBlocks from "../components/themed/resident/ResidentsInformationBlocks";
import { LocalStorageKeys } from "../data/Persistence";
import { useAppSelector } from "../hooks/useAppSelector";
import { Ward } from "../models/Ward";
import { selectActiveWards } from "../selectors/selectors";
import "../styles/main.scss";
import { ETrackEvent } from "../utils/AppInsightsHelpers";

const MonitoringView = () => {
    const wards = useAppSelector<Ward[]>(selectActiveWards);

    const handleWardSelection = (_: string, selectedWardsId: any) => {
        const selectedWards = selectedWardsId.map((id: string) => wards.find(ward => ward.id === id));
        reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify(selectedWardsId));
        setSelectedWards(selectedWards);
        appInsights.trackEvent({ name: ETrackEvent.MonitoringWardFiltering });
    };

    const getDefaultWard = useCallback((): Ward[] | [] => {
        if (wards.length > 0) {
            const cachedSelectedResidentsWardId = reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId) ? JSON.parse(reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId)) : [];
            if (typeof cachedSelectedResidentsWardId === "object") {
                if (cachedSelectedResidentsWardId.length === 0) {
                    return wards;
                } else {
                    return cachedSelectedResidentsWardId.reduce((selectedResidentWards: Ward[], id: string) => {
                        const ward = wards.find(ward => ward.id === id);
                        if (ward) {
                            selectedResidentWards.push(ward);
                        }
                        return selectedResidentWards;
                    }, []);
                }
            } else {
                reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify([wards?.[0]?.id || ""]));
                return [wards?.[0]] || [];
            }
        }
        return [];
    }, [wards]);

    const [selectedWards, setSelectedWards] = useState<Ward[]>([]);

    useEffect(() => {
        setSelectedWards(getDefaultWard());
    }, [getDefaultWard, wards]);

    return (
        <StyledViewContainer>
            <ResidentsInformationBlocks
                handleWardSelection={handleWardSelection}
                selectedWards={selectedWards}
                wards={wards}
            />
            <MonitoringTable
                selectedWards={selectedWards}
            />

        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, MonitoringView);