import { StyledAmount, StyledDescription, StyledTitle } from "./styled";
import { StyledInlineFlexStart } from "../../styled";

interface IProps {
    header: string;
    description?: string;
    value: JSX.Element | number | string;
    qaId?: string;
    isSelected?: boolean;
}

const NumberInformationBlock = (props: IProps) => {
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle isSelected={props.isSelected} >{props.header}</StyledTitle>
            </StyledInlineFlexStart>
            <StyledDescription>
                {props.description}
            </StyledDescription>
            <StyledAmount data-qa={props.qaId}>
                {props.value} 
            </StyledAmount>
        </>
    );
};

export default NumberInformationBlock;
