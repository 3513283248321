import styled from "styled-components";
import { KpiType } from "../../../models/KpiType";
import { IStatisticValues } from "../../../models/StatisticValues";
import { localizer } from "../../../utils/Localizer";
import { getTrendIcon, getTrendValue } from "../../../utils/StatisticsHelpers";
import { ClickableStyledInformationBlockWrapper, EBlockSize, IconContainer, StyledTrendContainer } from "../../common/informationBlock/styled";
import NumberInformationBlock from "../informationBlock/NumberInformationBlock";
import Speedometer from "../Speedometer";

interface IPromptedChangesBlock {
    selectedKpi: KpiType;
    promptedProductChanges: IStatisticValues;
    handleDropdownSelection: (name: string, value: any) => void;
    size: EBlockSize;
    maxWidth?: number;
}

const PromptedContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledPromptedTrendContainer = styled(StyledTrendContainer)`
    align-self: end;
`;

const PromptedChangesBlock = (props: IPromptedChangesBlock) => {

    const {
        selectedKpi,
        handleDropdownSelection,
        maxWidth,
        promptedProductChanges,
        size
    } = props;
    
    const promptedValue = promptedProductChanges.value === null ? "--" : Math.round(promptedProductChanges.value ?? 0);

    return (
        <ClickableStyledInformationBlockWrapper
            size={size}
            alignItems="start"
            isSelected={
                selectedKpi === KpiType.PromptedProductChanges
            }
            onClick={() =>
                handleDropdownSelection(
                    "kpiSelected",
                    KpiType.PromptedProductChanges
                )
            }
        >
            <NumberInformationBlock
                header={localizer("constants.changeEventsFilter.kpi.promptedChanges")}
                description={localizer("constants.changeEventsFilter.kpi.promptedProductChanges.description")}
                isSelected={selectedKpi === KpiType.PromptedProductChanges}
                value={
                    <PromptedContainer>
                        <Speedometer maxWidth={maxWidth} number={promptedValue} />
                        <StyledPromptedTrendContainer>
                            <IconContainer>{getTrendIcon(promptedProductChanges.trend)}</IconContainer>
                            <div>{getTrendValue(promptedProductChanges.trend)}{promptedProductChanges.trend !== null && "% p.p."}</div>
                        </StyledPromptedTrendContainer>
                    </PromptedContainer>
                }
            />
        </ClickableStyledInformationBlockWrapper>
    )
}

export default PromptedChangesBlock;