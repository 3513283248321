import ChangeEvent from "../../../models/ChangeEvent";
import { Ward } from "../../../models/Ward";
import GraphHeader from "../../common/chart/GraphHeader";
import { Chart } from "../../common/chart/Chart";
import moment, { Moment } from "moment";
import { KpiType } from "../../../models/KpiType";
import { IChartData } from "../../../pages/StatisticsView";
import { DateTimeUtils } from "../../../models/DateTimeUtils";
import { FormattedMessage } from "react-intl";

interface IProductStatisticsGraphProps {
    averageChangeEventsLogs: ChangeEvent[];
    compareChangeEventsLogs: ChangeEvent[];
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    kpiValue: number | null | undefined;
    compareKpiValue: number | null | undefined;
    startDate: string;
    endDate: string;
    oldestWardMoment: Moment;
    startCompareDate: Moment | null;
    selectedWardCreation?: Moment;
    setSelectedCompareStartDate: (value: Moment | null) => void;
    setSelectedCompareEndDate: (value: Moment | null) => void;
    getFormattedTooltipValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number) => any;
    getFormattedLegendValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number | null) => any;
    getFormattedDifference: (selectedKpi: KpiType, difference: number, percentageDifference?: number) => any;
}

const getKpiValue = (kpiValue: number | null | undefined) => {
    if (kpiValue !== undefined) {
        if (typeof kpiValue === "number") {
            return kpiValue;
        }
    }
    return null;
};

const getPercentageValue = (selectedKpi: KpiType, kpiValue: { count: number | null | undefined, percentage: number | null | undefined } | number | null | undefined) => {
    if (kpiValue !== undefined) {
        if (selectedKpi === KpiType.PromptedProductChanges || selectedKpi === KpiType.AverageProductChanges) {
            if (kpiValue && typeof kpiValue !== "number") {
                return kpiValue.percentage ? kpiValue.percentage : null;
            }
        }
    }
    return null;
};

const getHeaderId = (kpi: KpiType) => {
    let id: string = "";
    switch (kpi) {
        case KpiType.ResponseTime:
            id = "statistics.graphHeader.responseTime";
            break;
        case KpiType.AllProductChanges:
            id = "constants.changeEventsFilter.kpi.allProductChanges";
            break;
        case KpiType.PromptedProductChanges:
            id = "constants.changeEventsFilter.kpi.promptedProductChanges";
            break;
        case KpiType.AverageProductChanges:
            id = "statistics.graphHeader.avgProductChanges";
            break;
        case KpiType.AvgNightTimeMinutesBetweenChanges:
            id = "statistics.graphHeader.nighTime";
            break;
    }
    return id;
};

const getLegendText = (currentSelectedKpi: KpiType) => {
    if (currentSelectedKpi === KpiType.PromptedProductChanges) {
        return <></>
    } else if (currentSelectedKpi === KpiType.AllProductChanges) {
        return <><FormattedMessage id="constants.changeEventsFilter.kpi.productChanges.total" />:</>;
    } else {
        return <><FormattedMessage id="statistics.legend.average" />:</>;
    }
};

const ProductStatisticsGraph = (props: IProductStatisticsGraphProps) => {

    const start = new Date(props.startDate);
    const end = new Date(props.endDate);
    const interval = DateTimeUtils.getCompareInterval(start, end);
    const generateChartData = (): IChartData[] => {
        const dayProductChangesMap: Map<string, {
            all: number[],
            ward: number,
            wardCompare?: number,
            compareDate?: string,
            allPercentage: number[],
        }> = new Map();

        const dayProductChangesCompareMap: Map<string, {
            all: number[],
            ward: number,
            allPercentage: number[],
            percentage?: number
        }> = new Map();

        const chartData: IChartData[] = [];

        const fromDate = moment(props.startDate);
        const currentDate = moment(props.endDate).subtract(1, "d");
        const fromCompareDate = props.startCompareDate ? moment(props.startCompareDate) : undefined;
        const currentCompareDate = fromCompareDate ? moment(fromCompareDate.clone().add(interval - 1, 'days')) : undefined;

        while (currentDate >= fromDate) {
            const compareDate = currentCompareDate ? currentCompareDate.format('YYYY-MM-DD') : undefined;
            dayProductChangesMap.set(currentDate.format('YYYY-MM-DD'), { all: [], ward: 0, compareDate, allPercentage: [] });
            currentDate.subtract(1, 'days');
            if (compareDate && currentCompareDate) {
                dayProductChangesCompareMap.set(compareDate, { all: [], ward: 0, allPercentage: [] });
                currentCompareDate.subtract(1, 'days');
            }
        }

        for (const event of props.averageChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesMap.has(date)) {
                let all;
                if (props.selectedKpi === KpiType.AllProductChanges) {
                    all = event.productChanges;
                } else if (props.selectedKpi === KpiType.AverageProductChanges) {
                    all = event.avgProductChanges;
                } else {
                    all = event.productChangesPercentage;
                }
                dayProductChangesMap?.get(date)?.all.push(all ?? 0);
            }
        }

        for (const event of props.compareChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesCompareMap.has(date)) {
                let all;
                if (props.selectedKpi === KpiType.AllProductChanges) {
                    all = event.productChanges;
                } else if (props.selectedKpi === KpiType.AverageProductChanges) {
                    all = event.avgProductChanges;
                } else {
                    all = event.productChangesPercentage;
                }
                dayProductChangesCompareMap?.get(date)?.all.push(all ?? 0);
            }
        }


        for (const [key, value] of dayProductChangesMap.entries()) {
            const allChanges = value.all.length > 0 ? value.all.reduce((sum, current) => sum + current, 0) : 0;
            let allCompareChanges;
            if (value.compareDate && dayProductChangesCompareMap.has(value.compareDate)) {
                const entry = dayProductChangesCompareMap.get(value.compareDate);
                if (entry) {
                    allCompareChanges = entry.all.length > 0 ? entry.all.reduce((sum, current) => sum + current, 0) : 0;
                }
            }
            chartData.push({
                date: key,
                selectedWard: allChanges,
                compareDate: value.compareDate,
                selectedCompareWard: allCompareChanges,
            });
        }
        return chartData;
    };

    const maxYAxisValue = (): number => {
        const mappedValues: Map<Date, number> = new Map();
        props.averageChangeEventsLogs.forEach((changeEvent: ChangeEvent) => {
            const createdDate = changeEvent.timeCreated;
            if (createdDate !== undefined) {
                if (mappedValues.has(createdDate)) {
                    const mappedValue = mappedValues.get(createdDate);
                    mappedValues.set(createdDate, (mappedValue ?? 0) + (props.selectedKpi === KpiType.AllProductChanges ? changeEvent.productChanges : changeEvent.avgProductChanges));
                } else {
                    mappedValues.set(createdDate, props.selectedKpi === KpiType.AllProductChanges ? changeEvent.productChanges : changeEvent.avgProductChanges);
                }
            }
        });
        const compareMappedValues: Map<Date, number> = new Map();
        props.compareChangeEventsLogs.forEach((changeEvent: ChangeEvent) => {
            const createdDate = changeEvent.timeCreated;
            if (createdDate !== undefined) {
                if (compareMappedValues.has(createdDate)) {
                    const mappedValue = compareMappedValues.get(createdDate);
                    compareMappedValues.set(createdDate, (mappedValue ?? 0) + (props.selectedKpi === KpiType.AllProductChanges ? changeEvent.productChanges : changeEvent.avgProductChanges));
                } else {
                    compareMappedValues.set(createdDate, props.selectedKpi === KpiType.AllProductChanges ? changeEvent.productChanges : changeEvent.avgProductChanges);
                }
            }
        });
        const allProductChanges = [...mappedValues.values(), ...compareMappedValues.values()];
        const maxAggregatedProductChanges = Math.max(...allProductChanges);
        return Math.max(Math.ceil(maxAggregatedProductChanges * 1.2), 2);
    };

    const chartData = generateChartData();
    const isDataPresent = chartData.filter(data => data.selectedWard !== 0).length > 0;
    return (
        <div>
            <GraphHeader
                disabledCompare={false}
                selectedKpi={props.selectedKpi}
                isDataPresent={isDataPresent}
                daysInterval={interval}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate)}
                startRangeDate={props.selectedWard ? props.selectedWardCreation! : props.oldestWardMoment}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate).format("DD MMMM YYYY") : null}
                getHeaderId={getHeaderId}
                includeToday={false}
            />
            <Chart
                dataKey={"selectedWard"}
                compareDataKey={"selectedCompareWard"}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate).subtract(1)}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate) : null}
                compareEndDate={moment(props.startCompareDate).add(interval - 1, 'days')}
                chartData={generateChartData()}
                maxYAxisValue={maxYAxisValue()}
                selectedKpiValue={getKpiValue(props.kpiValue)}
                compareKpiValue={getKpiValue(props.compareKpiValue)}
                percentageKpiValue={getPercentageValue(props.selectedKpi, props.kpiValue)}
                comparePercentageKpiValue={getPercentageValue(props.selectedKpi, props.compareKpiValue)}
                selectedKpi={props.selectedKpi}
                interval={interval}
                getFormattedTooltipValue={props.getFormattedTooltipValue}
                getFormattedLegendValue={props.getFormattedLegendValue}
                getFormattedDifference={props.getFormattedDifference}
                getLegendText={getLegendText}
            />
        </div>
    );
};

export default ProductStatisticsGraph;
