import cx from "classnames";
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from "styled-components";
import StripIcon from "../../../assets/images/stripIcon";
import './SensorStripIndicator.scss';

/**
 * Clicking this button should bring up a modal for
 * the info on resident history for selected resident.
 *
 * @export
 * @class ResidentHistoryIcon
 * @extends {Component}
 */
interface ISensorStripIndicatorProps {
    stripLength: string | undefined;
    icon: JSX.Element | undefined;
    disabled?: boolean;
    className?: string;
}
interface IIconStyledProps {
    active: boolean;
    disabled: boolean;
}

const StyledStripIcon = styled(({ active, disabled, ...props }) => (<StripIcon {...props} />)) <IIconStyledProps>`
    position: absolute;
    left: 6px;
    top: -2px;
    fill: #E0E0E0;

    #smallPart {
        stroke: #E0E0E0;
    }

    ${props => props.active && css`
        fill: #003B73;
        #smallPart {
            stroke: #003B73;
        }
    `}

    ${props => props.disabled && css`
        fill: #828282;
        #smallPart {
            stroke: #828282;
        }
    `}
`;

const StyledIconContainer = styled.div`
    float: right;
`;

enum stripLengths {
    short = "short",
    medium = "medium",
    long = "long"
}

const lengthToCssMapper = (length: string | undefined) => {
    switch (length) {
        case stripLengths.short: return "100% / 3";
        case stripLengths.medium: return "100% / 1.5";
        case stripLengths.long: return "100%";
        default: return "100% / 3";
    }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default class SensorStripIndicator extends Component<ISensorStripIndicatorProps, {}> {
    render(): React.ReactElement {
        return (
            <div className={this.props.className}>
                <div className="mb-2">
                    <FormattedMessage id="sensorStripIndicator.header" />
                    <StyledIconContainer>
                        {this.props.icon}
                    </StyledIconContainer>
                </div>
                <div className="rectangle">
                    <div className="rectangle__background">
                        <span className="rectangle__item" />
                        <span className="rectangle__item" />
                        <span className="rectangle__item" />
                    </div>
                    <div className="rectangle__active">
                        <StyledStripIcon
                            active={!!this.props.stripLength}
                            disabled={this.props.disabled}
                        />
                        <span
                            className={cx("rectangle__item", {
                                "rectangle__item--active": this.props.stripLength,
                                "rectangle__item--disabled": this.props.disabled
                            })}
                            style={{
                                width: `calc(${lengthToCssMapper(this.props.stripLength?.toLowerCase())})`
                            }}
                        />
                    </div>
                </div>
                <div className="indicatorTexts">
                    {Object.values(stripLengths).map(length => (
                        <div 
                            key={length}
                            className={cx("text", {
                            "active": this.props.stripLength?.toLowerCase() === length,
                            "disabled": this.props.disabled
                        })}>
                            <FormattedMessage id={`sensorStripIndicator.${length}`} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
