import { localizer } from "../../../../utils/Localizer";
import { StyledLeft } from "./PositionContainers";

const ActiveStatusFormatter = (props: any) => {
    const { value } = props;
    return (
        <StyledLeft>
            {value ? localizer("constants.active") : localizer("constants.inactive") }
        </StyledLeft>
    );
}

export default ActiveStatusFormatter;
