import { Roles } from "./Role";

export enum UserTypes {
    INTERNAL = "Internal",
    EXTERNAL = "External",
    FEDERATED = "Federated",
}
export default class DisplayUser {
    id: string;
    name: string;
    role: string;
    lastLogin: Date | undefined;
    status: boolean;
    careFacilities?: string[];
    type: UserTypes;
    readOnly: boolean;

    constructor(json: any = {}) {
        this.id = json.externalId ? String(json.externalId) : '';
        this.name = json.username ? json.username : json.email;
        this.role = json.role ? json.role : '';
        this.lastLogin = json.lastLogin === "0001-01-01T00:00:00" ? null : json.lastLogin;
        this.status = json.isActivated;
        this.type = json.type;
        this.readOnly = json.readOnly ?? false;
        if(json.type !== UserTypes.INTERNAL || json.role === Roles.Support) {
            this.careFacilities = json.nursingHomes ? json.nursingHomes : [];
        }
    }
}
