import styled from "styled-components";
import Colors from "../assets/Colors";

export const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
    width: 240px;
`;

export const StyledFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px;
    margin-top: 32px
`;

export const StyledAddContainer = styled.div`
    color: ${Colors.blue.tenaBlue};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;

    height: 56px;
    left: 1368px;
    top: 299px;
    line-height: 22px;
    button {
        width: 32px;
        height: 32px;
        margin: 0px;
    }
`