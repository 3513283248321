import { useEffect, useState } from "react";
import { ICountryListItem } from "../../../../models/ICountryListItem";
import { getLocalizedCountry, localizer } from "../../../../utils/Localizer";
import CheckBox from "../../../common/controls/CheckBox";
import FilterInput from "../../../common/controls/FilterInput";
import { NoSearchResultContainer } from "./CommonUserStyle";
import { ScrollableContainer, SelectAllContainer } from "./UserEditFormStyle";

interface IProps {
    allCountries: ICountryListItem[];
    selectedIds: number[];
    onCountrySelect: (countries: ICountryListItem[]) => void;
    disabled?: boolean | undefined;
}

const CountriesScrollableContainer = (props: IProps) => {
    const {
        allCountries,
        onCountrySelect,
    } = props;

    const [filterValue, setFilterValue] = useState<string>("")
    const [selectedCountries, setSelectedCountries] = useState<ICountryListItem[]>(allCountries.filter(country => props.selectedIds.includes(country.id)));
    const [filteredCountries, setFilteredCountries] = useState<ICountryListItem[]>(allCountries)

    useEffect(() => {
        const foundCountries = allCountries.filter(country => getLocalizedCountry(country.code).toLowerCase().includes(filterValue.toLowerCase()));
        setFilteredCountries(foundCountries);
    }, [allCountries, filterValue]);

    const shouldTickSelectAllCheckbox = () => {
        const selectedCountriesIds = selectedCountries.map(({ id }) => id);
        return filteredCountries.length > 0 && filteredCountries.length === filteredCountries.filter(entry => selectedCountriesIds.includes(entry.id)).length
    }

    const getCountries = () => {
        const countryCheckboxes = filteredCountries.map(country => {
            return (
                <CheckBox
                    disabled={props.disabled}
                    width={250}
                    label={getLocalizedCountry(country.code)}
                    key={country.id}
                    value={selectedCountries.map(({ id }) => id).includes(country.id)}
                    fieldName={country.id.toString()}
                    cutText={true}
                    onChange={(_name, value) => {
                        let selection;
                        if (value) {
                            selection = [...(new Set([...selectedCountries, country]))];
                        } else {
                            selection = selectedCountries.filter(selectedCountry => selectedCountry.id !== country.id);
                        }
                        setSelectedCountries(selection);
                        onCountrySelect(selection);
                    }}
                />
            )
        })
        return countryCheckboxes.length > 0 ? countryCheckboxes : <NoSearchResultContainer>{localizer("users.countries.noResults")}</NoSearchResultContainer>;
    }

    return (
        <>
            <FilterInput
                placeholder={localizer("constants.search")}
                onChange={(value) => {
                    setFilterValue(value);
                }}
                value={filterValue}
            />
            <SelectAllContainer>
                <CheckBox
                    disabled={props.disabled}
                    width={250}
                    label={localizer("constants.selectAll")}
                    fieldName="selectAll"
                    value={shouldTickSelectAllCheckbox()}
                    onChange={(_name, value) => {
                        let selection;
                        if (value) {
                            selection = [...(new Set([...selectedCountries, ...filteredCountries]))];
                        } else {
                            const filteredCountriesIds = filteredCountries.map(({ id }) => id);
                            selection = selectedCountries.filter(entry => !filteredCountriesIds.includes(entry.id))
                        }
                        setSelectedCountries(selection);
                        onCountrySelect(selection);
                    }}
                />
            </SelectAllContainer>
            <ScrollableContainer>
                {getCountries()}
            </ScrollableContainer>
        </>
    )
}

export default CountriesScrollableContainer;

