
import styled from "styled-components";
import Colors from "../assets/Colors";
import { ReactComponent as ExternalLinkIcon } from "../assets/images/externalLink.svg";
import { mobileThresholdWidth } from "../settings/Settings";

export const StyledTitleBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
`;

export const StyledLowerBarBackground = styled.div`
    width: auto;
    max-width: 100vw;
    height: 108px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: stretch;
    @media (max-width: ${mobileThresholdWidth}px) {
        height: 56px;
    }
`;
export const StyledLowerBar = styled.div`
    max-width: 1440px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: ${Colors.white.clearWhite};
    flex-direction: column;
    @media (min-width: ${mobileThresholdWidth}px) {
        flex-direction: row;
    }
`;

export const StyledNursingHomeSelectorAndLowerBarButtonsWrapper = styled.div`
    max-height: 50px;
    width: auto;
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    @media (min-width: ${mobileThresholdWidth}px) {
        flex-direction: row;
        align-items: flex-end;
    }
`;

export const LowerBarItemsMargin = 15;
export const LowerBarItemsSideMargin = 20;

export const StyledUpperBarButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right:  ${LowerBarItemsSideMargin}px;
    width: calc(100vw - ${2 * LowerBarItemsSideMargin}px);

    @media (min-width: ${mobileThresholdWidth}px) {
        width: auto;
        margin-left: auto;
        margin-bottom: ${LowerBarItemsMargin}px;
        > *:not(:first-child) {
            margin-left: 25px;
        }
    }

    @media (max-width: ${mobileThresholdWidth}px) {
        width: auto;
        margin-right: 0px;
        margin-bottom: 0px;
    }
`;

export const StyledLowerBarNursingHomeSelector = styled.div`
    display: flex;
    justify-content: center;
    margin: ${LowerBarItemsMargin}px ${LowerBarItemsSideMargin}px;
    width: calc(100vw - ${5 * LowerBarItemsSideMargin}px);

    @media (min-width: ${mobileThresholdWidth}px) {
        width: auto;
        margin-bottom: ${LowerBarItemsMargin}px;
    }
`;

export const BlueDivider = styled.div`
    @media (max-width: ${mobileThresholdWidth}px) {
        display: none;
    }
    height: 8px;
    background-color: ${Colors.blue.brandingDark};
`;

export const ActionContainer = styled.div`
    display: flex;
    font-size: 14px;
    flex-direction: column;
    height: 100%;
    color: ${Colors.blue.brandingDark};
    justify-content: space-between;
    flex-grow: 3;
`;

export const UpperActionContainer = styled.div`
    display: flex;

`;
export const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
    :hover {
        cursor: pointer;
    }
`;
export const LowerActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right:  ${LowerBarItemsSideMargin}px;
`;

export const SelectedResourceContainer = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: ${Colors.blue.brandingDark};
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-word;
    flex-grow: 2;
    text-align: center;
`;

export const StyledATag = styled.a`
    color: ${Colors.blue.brandingDark};
    text-decoration: none;
    :hover {
        cursor: pointer;
    }   
`;