export enum DateFilter {
    Today = 1,
    Yesterday = 2,
    Days3 = 3,
    Week = 7,
    HalfMonth = 15,
    Month = 30,
    TwoMonths = 60,
    ThreeMonths = 90,
    SixMonths = 180,
    TwelveMonths = 365,
    SinceStart = 0,
    CustomRange = 99
}

