import { useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CareFacility } from "../../../models/CareFacility";
import { SensorStripType } from "../../../models/EStripSizeSetting";
import ErrorCodes from '../../../models/ErrorCodes';
import { Roles } from "../../../models/Role";
import { IContextStateType } from "../../../reducers/contextSlice";
import { localizer } from "../../../utils/Localizer";
import { IDropDownOption } from "../../common/IDropDownOption";
import DropdownButtonNew from "../../common/controls/DropdownButtonNew";
import TextFieldNew from '../../common/controls/TextFieldNew';
import { CaptionMedium, RequiredFields, Section, SectionHeader, SectionTitle } from "../../common/forms/SectionStyle";
import ValidatedFormGroupWrapper from '../../wrappers/ValidatedFormGroupWrapper';
import { CareFacilityFields } from "../nursingHomes/CareFacilitiesHelper";
import StripSizeSettingsInformationModal from "../users/common/StripSizeSettingsInformationModal";
import {StyledUppercase} from '../../common/styled'

interface IPreferencesProps {
    nursingHome: CareFacility;
    handleChanges: (fieldName: string, value: any) => void;
    validationErrors: string[];
    countries: IDropDownOption[];
    careGroups: IDropDownOption[];
    readonly?: boolean;
}

const GeneralSettings = (props: IPreferencesProps) => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const internalRole: boolean = context.role === Roles.GlobalAdmin || context.role === Roles.CountryAdmin;
    const externalIntegrationOptions = context.externalCareFacilityIntegrations.map(({id, name}) => ({
        value: id,
        label: name
    }));
    externalIntegrationOptions.push({ value: "0", label: localizer("constants.none") });
    const [showStripSizeModal, setShowStripSizeModal] = useState<boolean>(false);

    const stripSizeOptions = Object.values(SensorStripType).map((key) => ({
        value: SensorStripType[key],
        label: localizer(`stripSettings.${SensorStripType[key]}`),
    }));
    const isEditMode = (): boolean => {
        return !!props.nursingHome.id;
    }

    return (
        <>
            <Section>
                <SectionHeader>
                    <SectionTitle>{localizer("setup.generalSettings")}</SectionTitle>
                    <RequiredFields>* {localizer("setup.requiredFields")}</RequiredFields>
                </SectionHeader>
                <div>
                    <ValidatedFormGroupWrapper
                        validationErrors={props.validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNameError, ErrorCodes.DuplicateNameError]}
                        label={"setup.generalSettings.careFacilityName"}
                        required
                    >
                        <TextFieldNew
                            data-qa="nh_name_input_text"
                            disabled={props.readonly}
                            fieldName={CareFacilityFields.Name}
                            value={props.nursingHome.name}
                            onChange={props.handleChanges}
                        />
                    </ValidatedFormGroupWrapper>
                    {(!internalRole && props.nursingHome.externalIntegrationName) && (
                        <ValidatedFormGroupWrapper
                            validationErrors={props.validationErrors}
                            label={"setup.generalSettings.externalIntegrations"}
                        >
                            <TextFieldNew
                                disabled
                                fieldName={CareFacilityFields.ExternalIntegration}
                                value={props.nursingHome.externalIntegrationName}
                                onChange={props.handleChanges}
                            />
                        </ValidatedFormGroupWrapper>
                    )}
                    {internalRole && (
                        <>
                            <ValidatedFormGroupWrapper
                                validationErrors={props.validationErrors}
                                dependentErrorCodes={[ErrorCodes.CountryEmptyCodeError]}
                                label={"setup.generalSettings.country"}
                                required
                            >
                                <DropdownButtonNew
                                    disabled={isEditMode()}
                                    showSelectedAsDisabledMessage={true}
                                    id={CareFacilityFields.Country}
                                    name={CareFacilityFields.Country}
                                    handleChange={props.handleChanges}
                                    options={props.countries}
                                    selectedValue={props.nursingHome.countryId}
                                />
                            </ValidatedFormGroupWrapper>
                            <ValidatedFormGroupWrapper
                                validationErrors={props.validationErrors}
                                dependentErrorCodes={[]}
                                label={"setup.generalSettings.careGroup"}
                            >
                                <DropdownButtonNew
                                    id={CareFacilityFields.CareGroup}
                                    name={CareFacilityFields.CareGroup}
                                    handleChange={props.handleChanges}
                                    options={props.careGroups}
                                    selectedValue={props.nursingHome.nursingHomeGroupId}
                                />
                            </ValidatedFormGroupWrapper>
                            <ValidatedFormGroupWrapper
                                validationErrors={[]}
                                label={"setup.generalSettings.externalIntegrations"}
                            >
                                <>
                                    <DropdownButtonNew
                                        id={CareFacilityFields.ExternalIntegration}
                                        name={CareFacilityFields.ExternalIntegration}
                                        handleChange={props.handleChanges}
                                        options={externalIntegrationOptions}
                                        selectedValue={props.nursingHome.externalIntegrationId}
                                    />
                                    {(isEditMode() && props.nursingHome.externalIntegrationId !== null)  && (
                                        <CaptionMedium>
                                            <p>
                                                {localizer('careRecipients.externalId')}: <StyledUppercase>{props.nursingHome.externalId}</StyledUppercase>
                                            </p>
                                        </CaptionMedium>
                                    )}
                                </>
                            </ValidatedFormGroupWrapper>
                            <ValidatedFormGroupWrapper
                                validationErrors={props.validationErrors}
                                dependentErrorCodes={[ErrorCodes.EmptyCustomer]}
                                label={"setup.preferences.customer"}
                                required

                            >
                                <TextFieldNew
                                    data-qa="nh_customer_input_text"
                                    fieldName={CareFacilityFields.CustomerId}
                                    value={props.nursingHome.customerId}
                                    onChange={props.handleChanges}
                                />
                            </ValidatedFormGroupWrapper>
                            <ValidatedFormGroupWrapper
                                validationErrors={[]}
                                label={"setup.preferences.sensorStripType"}
                                tooltipIconMessage={localizer("stripSettings.tooltip")}
                                onIconClick={() => setShowStripSizeModal(true)}
                            >
                                <DropdownButtonNew
                                    id={CareFacilityFields.StripSizeSetting}
                                    name={CareFacilityFields.StripSizeSetting}
                                    handleChange={props.handleChanges}
                                    options={stripSizeOptions}
                                    selectedValue={props.nursingHome.sensorStripType}

                                />
                            </ValidatedFormGroupWrapper>
                        </>)}

                </div>
            </Section>
            <StripSizeSettingsInformationModal
                showInfoPopup={showStripSizeModal}
                setShowInfoPopup={setShowStripSizeModal}
            />
        </>
    );
};

export default GeneralSettings;
