import styled from "styled-components";
import { localizer } from "../../../../utils/Localizer";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import { ReactComponent as StripIcon } from "../../../../assets/images/stripIcon.svg";
import Colors from "../../../../assets/Colors";

const StyledContainer = styled(StyledLeft)`
    display: flex;
    gap: 10px;
`;

const StyledProductSetup = styled.div`
    display: grid;
`;

const StyledSingleStripIcon = styled(StripIcon)`
    margin-right: 20px;
`;

const MonitoringSetup = (props: any) => {
    const row = props.row.original;
    const daySetupWithProduct =
        row.dayTimeSetup &&
        row.dayTransmitter.productId;

    const nightSetupWithProduct =
        row.nightTimeSetup &&
        row.nightTransmitter.productId;

    const sameProduct =
        daySetupWithProduct &&
        nightSetupWithProduct &&
        row.dayTransmitter.productId === row.nightTransmitter.productId;

    const { separateTransmitter } = row

    const getProductSetupDescription = () => {
        if (sameProduct) {
            return localizer("monitoring.setup.product.oneProduct24h");
        }
        if (nightSetupWithProduct) {
            if (daySetupWithProduct) {
                return localizer("monitoring.setup.product.twoProducts");
            }

            return localizer("monitoring.setup.product.nightProduct");
        }
        return ""
    }

    const products = getProductSetupDescription();

    const stripSetup = nightSetupWithProduct ?
        (daySetupWithProduct && separateTransmitter ?
            <>
                <StripIcon fill={Colors.grey.mediumLight} stroke={Colors.grey.mediumLight} />
                <StripIcon fill={Colors.grey.mediumLight} stroke={Colors.grey.mediumLight} />
            </>
            :
            <StyledSingleStripIcon fill={Colors.grey.mediumLight} stroke={Colors.grey.mediumLight} />)
        :
        "";
    return (
        <StyledContainer>
            {stripSetup}
            <StyledProductSetup>
                <span>
                    {nightSetupWithProduct ?
                        (daySetupWithProduct && separateTransmitter ?
                            localizer("monitoring.setup.strips.two")
                            :
                            localizer("monitoring.setup.strips.one")
                        )
                        :
                        ""
                    }
                </span>
                <span>
                    {products}
                </span>
            </StyledProductSetup>
        </StyledContainer>
    );
}

export default MonitoringSetup;
