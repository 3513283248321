import Moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ResidentEventType } from '../../../../../models/ResidentEventType';
import { EMPTY_VALUE } from "../../../../../utils/Localizer";
import ChangeStatusIcon from '../../../../common/ChangeStatusIcon';
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';
import { countTimeDifference } from './helpers/TimeDifferenceBetweenTwoDates';
import { TransmitterError } from "../../../../../models/TransmitterError";

const StyledContainer = styled(StyledLeft)`
    display: block;
`;

const StyledImageContainer = styled.div`
    width: 36px;
`;

const StyledStatusContainer = styled.div`
    display: flex;
`;

const StyledSpan = styled.span<any>`
    margin-top: 2px;
    max-width: 200px;
`;

const StyledTimeSpan = styled.span<any>`
    margin-left: 36px;
    font-size: 14px;
`;

function calculateTimeInState(currentObject: any, nextObject: any, nextCreatedDate: any) {
    const currentCreatedDate = currentObject.created;
    if (currentObject.nextStatusChangedDateTime) {
        return countTimeDifference(currentObject.nextStatusChangedDateTime, currentCreatedDate);
    } else if (nextObject) {
        const nextCreatedDate = nextObject.created;
        return countTimeDifference(nextCreatedDate, currentCreatedDate);
    }

    const now = nextCreatedDate ? Moment(nextCreatedDate).toDate() : Moment().utc().toDate();
    return countTimeDifference(now, currentCreatedDate);
}

const secondsToMiliseconds = (amount: number) => amount * 1000;

const getStatus = (eventType: ResidentEventType, currentRow: any) => {
    switch (eventType) {
        case ResidentEventType.Saturated:
            return ResidentEventType.Saturated;
        case ResidentEventType.SomeUrine:
            return ResidentEventType.SomeUrine;
        case ResidentEventType.Error:
            if (currentRow.original.transmitterError === TransmitterError.SignalLost) {
                return ResidentEventType.Error + ".SignalLost";
            } else if ([TransmitterError.CalculationError, TransmitterError.StripDetachedFromTransmitter].includes(currentRow.original.transmitterError)) {
                return ResidentEventType.Error + ".StripError";
            }
            return ResidentEventType.Error;
        case ResidentEventType.OK:
            return ResidentEventType.OK;
        case ResidentEventType.Connecting:
            return ResidentEventType.Connecting;
        case ResidentEventType.Inactive:
            return ResidentEventType.Inactive;
        case ResidentEventType.NotBeingMonitored:
            return ResidentEventType.NotBeingMonitored;
        default:
            return "";
    }
}

const CellFormatter = (props: any) => {
    const [timeDifference, setTimeDifference] = useState(EMPTY_VALUE);
    const { data, row, value } = props.data;
    const currentRowIndex = data.findIndex((r: any) => r.id === row.original.id);

    useEffect(() => {
        const nextObject = currentRowIndex === 0 ? undefined : data[currentRowIndex - 1]
        setTimeDifference(calculateTimeInState(data[currentRowIndex], nextObject, props.nextStatusChangedDateTime));
        const myInterval = setInterval(() => setTimeDifference(calculateTimeInState(data[currentRowIndex], nextObject, props.nextStatusChangedDateTime)), secondsToMiliseconds(3));
        return () => clearInterval(myInterval);
    }, [currentRowIndex, data, props.value]);

    return (
        <StyledContainer>
            <StyledStatusContainer>
                <StyledImageContainer>
                    <ChangeStatusIcon
                        status={value}
                    />
                </StyledImageContainer>
                <StyledSpan status={value}><FormattedMessage id={'resident.history.tableRow.status.' + getStatus(value, row)} /></StyledSpan>
            </StyledStatusContainer>
            <StyledTimeSpan>{row.original.timeInStatus ? row.original.timeInStatus : timeDifference}</StyledTimeSpan>
        </StyledContainer>

    );
};

const StatusFormatter = (props: any) => {
    return <CellFormatter data={props.data} nextStatusChangedDateTime={props.nextStatusChangedDateTime} />
};
export default StatusFormatter;
