import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import GlobalAdminAuditView from "../components/themed/audit/views/GlobalAdminAuditView";
import NursingHomeGroupManagerAuditView from "../components/themed/audit/views/NursingHomeGroupManagerAuditView";
import NursingHomeManagerAuditView from "../components/themed/audit/views/NursingHomeManagerAuditView";
import { useAppSelector } from "../hooks/useAppSelector";
import { Roles } from "../models/Role";

export enum AuditContext {
    Global = "G",
    NursingHome = "NH"
}
interface IAuditViewProps {
    context: AuditContext;
}

const AuditView = (props: IAuditViewProps) => {
    const role = useAppSelector<Roles>(
        (state) => state.contextSlice.role
    );
    if(props.context === AuditContext.NursingHome) {
        switch (role) {
            case Roles.GlobalAdmin:
            case Roles.CountryAdmin:
            case Roles.NHManager:
            case Roles.Support:
                return <NursingHomeManagerAuditView />;
            default:
                return null;
        }
    } else {
        switch (role) {
            case Roles.GlobalAdmin:
                return <GlobalAdminAuditView />;
            case Roles.CountryAdmin:
                return <NursingHomeGroupManagerAuditView />;
            case Roles.NHManager:
            case Roles.Support:
                return <NursingHomeManagerAuditView />;
            default:
                return null;
        }
    }
   
};

export default withAITracking(reactPlugin, AuditView);
