import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { reactLocalStorage } from "reactjs-localstorage";
import get from "lodash/get";
import { DateFilter } from "../../../../models/DateFilter";
import {
    StyledInformationBlockWrapper,
    StyledViewContainer,
    StyledInformationBlocks,
} from "../../../common/View";
import {
    allAuditTypes,
    defaultFilters,
    getOptionalDropDownOptions,
} from "../AuditHelpers";
import AuditTimeInformationBlock from "../filters/AuditTimeInformationBlock";
import AuditTypeInformationBlock from "../filters/AuditTypeInformationBlock";
import AuditUsersInformationBlock from "../filters/AuditUsersInformationBlock";
import { IFilters } from "../filters/IFilters";
import { AuditsTableHoc } from "../tables/AuditsTableHoc";
import * as auditKeys from "../AuditKeys";
import { localizer } from "../../../../utils/Localizer";
import { Moment } from "moment";

const auditTypesArray = () => [
    {
        label: localizer(`${auditKeys.auditTypeKey}.${allAuditTypes.user}`),
        value: allAuditTypes.admin,
    },
    {
        label: localizer(`${auditKeys.auditTypeKey}.${allAuditTypes.login}`),
        value: allAuditTypes.adminLogin,
    },
];

const GlobalAdminAuditView = () => {
    const [filters, updateFilters] = useImmer<IFilters>(
        defaultFilters(allAuditTypes.admin)
    );

    const [data, setData] = useState<any[]>([]);

    const handleChange = (
        value: string | number | Moment | null,
        key: string
    ) => {
        reactLocalStorage.set(key, value);

        if (
            key === auditKeys.auditTimePeriodStartKey ||
            key === auditKeys.auditTimePeriodEndKey
        ) {
            updateFilters((draft: any) => {
                draft[key] = value;
                draft[auditKeys.auditTimePeriodKey] = DateFilter.CustomRange;
            });
            reactLocalStorage.set(
                auditKeys.auditTimePeriodKey,
                DateFilter.CustomRange
            );
        } else {
            updateFilters((draft: any) => {
                draft[key] = value;
            });
        }
    };

    useEffect(() => {
        handleChange(null, auditKeys.auditUserKey);
        handleChange(null, auditKeys.auditModifiedByKey);
    }, [
        filters.auditType,
        filters.auditTimePeriod,
        filters.auditTimePeriodStart,
        filters.auditTimePeriodEnd,
    ]);

    const generateDependentFilters = () => {
        switch (get(filters, auditKeys.auditTypeKey)) {
            case allAuditTypes.admin:
                return (
                    <StyledInformationBlockWrapper data-qa="user_card">
                        <AuditUsersInformationBlock
                            filters={filters}
                            handleChange={handleChange}
                            data={getOptionalDropDownOptions(data, "user")}
                        />
                    </StyledInformationBlockWrapper>
                );
            case allAuditTypes.adminLogin:
                return (
                    <StyledInformationBlockWrapper data-qa="user_card">
                        <AuditUsersInformationBlock
                            filters={filters}
                            handleChange={handleChange}
                            data={getOptionalDropDownOptions(
                                data,
                                "performedBy"
                            )}
                        />
                    </StyledInformationBlockWrapper>
                );
            default:
                return null;
        }
    };

    return (
        <StyledViewContainer>
            <StyledInformationBlocks>
                <StyledInformationBlockWrapper data-qa="audit_type_card">
                    <AuditTypeInformationBlock
                        filters={filters}
                        data={auditTypesArray()}
                        handleChange={handleChange}
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper data-qa="time_period_card">
                    <AuditTimeInformationBlock
                        filters={filters}
                        handleChange={handleChange}
                    />
                </StyledInformationBlockWrapper>
                {generateDependentFilters()}
            </StyledInformationBlocks>

            <AuditsTableHoc data={data} setData={setData} filters={filters} />
        </StyledViewContainer>
    );
};

export default GlobalAdminAuditView;
