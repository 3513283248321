import styled from "styled-components";
import { Roles } from "../../../../models/Role";
import BasicModal from "../../../common/modal/BasicModal"
import { EModalSize } from "../../../common/modal/ModalWrapper"
import { localizer } from "../../../../utils/Localizer";
import { StyledButton } from "../../../common/View";

const RoleTitle = styled.div`
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

interface IRoleInformationModalProps {
    showInfoPopup: boolean;
    setShowInfoPopup: (show: boolean) => void;
    roles: Roles[];
}

const RoleInformationModal = (props: IRoleInformationModalProps) => {
    const { showInfoPopup, setShowInfoPopup, roles } = props
    const content: JSX.Element[] = [];
    if (roles.includes(Roles.NHManager)) {
        content.push(
            <div key={Roles.NHManager}>
                <RoleTitle>{localizer("user.role." + Roles.NHManager)}</RoleTitle>
                <div>{localizer("users.roleInformation.administrator")}</div>
                <br />
            </div>
        )
    }
    if (roles.includes(Roles.Nurse)) {
        content.push(
            <div key={Roles.Nurse}>
                <RoleTitle>{localizer("user.role." + Roles.Nurse)}</RoleTitle>
                <div>{localizer("users.roleInformation.nurse")} </div>
                <br />
            </div>
        )
    }
    if (roles.includes(Roles.Caregiver)) {
        content.push(
            <div key={Roles.Caregiver}>
                <RoleTitle>{localizer("user.role." + Roles.Caregiver)}</RoleTitle>
                <div>{localizer("users.roleInformation.caregiver")}</div>
                <br />
            </div>
        )
    }
    if (roles.includes(Roles.Support)) {
        content.push(
            <div key={Roles.Support}>
                <RoleTitle>{localizer("user.role." + Roles.Support)}</RoleTitle>
                <div>{localizer("users.roleInformation.support")}</div>
                <br />
            </div>
        )
    }

    return <BasicModal
        width={EModalSize.S}
        handleOnHide={() => setShowInfoPopup(false)}
        show={showInfoPopup}
        title={localizer("users.roleInformation")}
    >
        {content}
        <ButtonContainer>
            <StyledButton
                variant={"primary"}
                type={"submit"}
                onClick={() => setShowInfoPopup(false)}
            >
                OK
            </StyledButton>
        </ButtonContainer>
    </BasicModal>
}

export default RoleInformationModal;
