
import { Button, ButtonGroup } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import Colors from '../../../../assets/Colors';

const StyledButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const StyledButtonGroup = styled<any>(ButtonGroup)`
    min-width: 120px;
    display: inline-flex;
`;

const StyledButton = styled<any>(Button)`
    margin: 0 !important;
    border-color: ${Colors.grey.disabledGrey} !important;
    background: ${Colors.grey.tenaLight} !important;
    color: ${Colors.black} !important;
    box-shadow: none !important;
    min-width: unset;

    ${(props) =>
        props.stroke
            ? css`
                  stroke: ${Colors.black} !important;
                  fill: none;
              `
            : css`
                  fill: ${Colors.black} !important;
              `}

    ${(props) => props.value === "off" &&
        css`
            circle {
                fill: ${Colors.black};
                stroke: ${Colors.black};
            }
            path {
                stroke: ${Colors.white.clearWhite};
                fill: ${Colors.black} ;
            }
        `
    }

    &:not(:last-child) {
        border-right-width: 0;
    }

    &.active {
        background: ${Colors.blue.brandingDark} !important;
        color: ${Colors.white.clearWhite} !important;
        box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
        z-index: 0 !important;

        ${(props) => props.stroke ?
            css`
                stroke: ${Colors.white.clearWhite} !important;
            `
            :
            css`
                fill: ${Colors.white.clearWhite} !important;
            `
        }
        ${(props) => props.value === "off" &&
            css`
                circle {
                    fill: ${Colors.white.clearWhite};
                    stroke: ${Colors.white.clearWhite};
                }
                path {
                    fill: ${Colors.white.clearWhite};
                    stroke: ${Colors.blue.brandingDark};
                }
        `
        }
    }
    &:hover&:not(.disabled) {
        background: ${Colors.blue.tenaBlueHover} !important;
        color: ${Colors.white.clearWhite} !important;

        ${(props) =>
        props.stroke
            ? css`
                      stroke: ${Colors.white.clearWhite} !important;
                  `
            : css`
                      fill: ${Colors.white.clearWhite} !important;
                  `}

        ${(props) => props.value === "off" &&
            css`
                circle {
                    fill: ${Colors.white.clearWhite};
                    stroke: ${Colors.white.clearWhite};
                }
                path {
                    fill: ${Colors.white.clearWhite};
                    stroke: ${Colors.blue.tenaBlueHover};
                }
            `
        }
    }


    &:disabled {
        fill:  ${(props) => (props.value === "off" ? "" : Colors.grey.mediumLight)} !important;
        color: ${Colors.grey.mediumLight} !important;
        circle {
            fill: ${(props) => (props.value === "off" ? Colors.grey.mediumLight :  "")};
            stroke: ${(props) => (props.value === "off" ? Colors.grey.mediumLight :  "")};
        }
    }

    white-space: nowrap;
    font-size: 14px;
    flex: 1 !important;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 40px;
    width: 40px;
    height: 40px;
`;

export const MonitoringSwitch = (props: any): React.ReactElement => {
    return (
        <StyledButtonsContainer>
            <StyledButtonGroup>
                {props.buttons.map((button: any) => (
                    <StyledButton
                        stroke={button.stroke ? 1 : 0}
                        disabled={props.disabled || button.disabled}
                        id={props.id + button.value}
                        key={button.value}
                        value={button.value}
                        active={props.buttonValue === button.value}
                        checked={props.buttonValue === button.value}
                        onClick={(e: any) => {
                            if (props.buttonValue !== button.value) {
                                props.toggle(button.value, e.currentTarget.value);
                            }
                        }}
                    >
                        {button.name}
                    </StyledButton>
                ))}
            </StyledButtonGroup>
        </StyledButtonsContainer>
    );
};
