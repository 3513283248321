import { be, da, de, enAU, enUS, es, fi, fr, it, nb, nl, nlBE, pl, pt, ro, sv } from "date-fns/locale";
import { createIntl, createIntlCache, IntlShape } from "react-intl";
import czJSON from "../assets/lang/SmartCare_Professional_Web_Czech.json";
import daJSON from "../assets/lang/SmartCare_Professional_Web_Danish.json";
import nlJSON from "../assets/lang/SmartCare_Professional_Web_Dutch.json";
import enJSON from "../assets/lang/SmartCare_Professional_Web_English.json";
import enAUJSON from "../assets/lang/SmartCare_Professional_Web_English_AU.json";
import enUSJSON from "../assets/lang/SmartCare_Professional_Web_English_US.json";
import fiJSON from "../assets/lang/SmartCare_Professional_Web_Finnish.json";
import nlBEJSON from "../assets/lang/SmartCare_Professional_Web_Flemish.json";
import frJSON from "../assets/lang/SmartCare_Professional_Web_French.json";
import frBEJSON from "../assets/lang/SmartCare_Professional_Web_French_BE.json";
import frCAJSON from "../assets/lang/SmartCare_Professional_Web_French_CA.json";
import deJSON from "../assets/lang/SmartCare_Professional_Web_German.json";
import itJSON from "../assets/lang/SmartCare_Professional_Web_Italian.json";
import nbJSON from "../assets/lang/SmartCare_Professional_Web_Norwegian.json";
import ptJSON from "../assets/lang/SmartCare_Professional_Web_Portuguese.json";
import roJSON from "../assets/lang/SmartCare_Professional_Web_Romanian.json";
import skJSON from "../assets/lang/SmartCare_Professional_Web_Slovak.json";
import esJSON from "../assets/lang/SmartCare_Professional_Web_Spanish.json";
import svJSON from "../assets/lang/SmartCare_Professional_Web_Swedish.json";
import { getCookie } from "./Cookies";

export const localizations: any = {
    cs: czJSON,
    da: daJSON,
    nl: nlJSON,
    en: enJSON,
    enAU: enAUJSON,
    enUS: enUSJSON,
    es: esJSON,
    nlBE: nlBEJSON,
    fi: fiJSON,
    fr: frJSON,
    frBE: frBEJSON,
    frCA: frCAJSON,
    de: deJSON,
    it: itJSON,
    nb: nbJSON,
    pt: ptJSON,
    ro: roJSON,
    sk: skJSON,
    sv: svJSON,
};

export const languageCookieName = "language";
export const EMPTY_VALUE = "--";
let intl: IntlShape | null = null;
export const availableLanguages = ["en", "cs", "da", "de", "en-AU", "en-US", "es", "fi", "fr", "fr-BE", "fr-CA", "it", "nb", "nl", "nl-BE", "pt", "ro", "sk", "sv"];
const locales: any = { pl, enAU, enUS, sv, da, nl, de, es, fi, fr, it, nlBE, be, nb, pt, ro };

export const convertLangCode = (langCode: string): string => langCode.replace("-", "");

export const getLocale = (languageCookieValue: string | null = null): string => {
    const languageCookie = languageCookieValue || getCookie(languageCookieName);
    const browserLocaleKey = ((navigator.languages && navigator.languages[0]) || navigator.language).substring(0, 2);

    if (languageCookie && localizations[convertLangCode(languageCookie)]) {
        return languageCookie;
    } else if (localizations[browserLocaleKey]) {
        return browserLocaleKey;
    }
    return "en";
};

export const getBrowserLocale = () => {
    const currentLocale = convertLangCode(navigator.language);
    return locales[currentLocale] || locales[currentLocale.substring(0, 2)];
};

export const getLocaleWithKey = (localeKey: string) => {
    if (Object.keys(locales).includes(localeKey)) {
        return locales[localeKey];
    }

    return enUS;
};


export const localizer = (key: string, values?: Record<string, string | number | Date>): string => {
    const locale = getLocale();

    if (!intl || intl.locale !== locale) {
        intl = createIntl({
            locale,
            messages: (localizations as any)[convertLangCode(locale)]
        }, createIntlCache());
    }

    return intl ? intl.formatMessage({ id: key }, values) : "";
};

export const getLocalizedCountries = (): Array<{ code: string, label: string }> => {
    const locale = getLocale().replace("-", "_");
    let localizedCountries;
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        localizedCountries = require("localized-countries")(require(`localized-countries/data/${locale}`));
    } catch {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        localizedCountries = require("localized-countries")(require("localized-countries/data/en"));
    }

    return localizedCountries.array();
};

export const getLocalizedCountry = (code: string): string => {
    const localizedCountries = getLocalizedCountries();
    return localizedCountries.find(country => country.code.toLowerCase() === code.toLowerCase())?.label || code;
};

// This method converts the locales we are using to a different version of the same country
export const convertLocale = (locale: string) => {
    switch (locale) {
        case "nb":
            return "no";
        default:
              return locale;
    }
};
