import React from "react";
import { ReactComponent as Eye } from "../../../assets/images/eye.svg";
import { ReactComponent as EyeSlash } from "../../../assets/images/eyeSlashed.svg";
import styled, { css } from "styled-components";
import Colors from "../../../assets/Colors";

const IconStyling = css`
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 15px;
    cursor: pointer;
`;

const StyledEye = styled(Eye)`
    ${IconStyling}
`;

const StyledEyeSlash = styled(EyeSlash)`
    ${IconStyling}
`;

const Container = styled.div`
    position: relative;
`;

const CustomInput = styled.input`
    width: 100%;
    border: 1px solid ${Colors.grey.mediumLight};
    height: 42px;
    padding-left: 8px;
    padding-right: 40px;
    &:hover {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
    &:focus {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Colors.blue.dark};
    }
`;
interface IPasswordFieldProps {
    fieldName: string;
    value: string;
    onChange: (name: string, value: any) => void;
    disabled?: boolean;
}
const PasswordField = (props: IPasswordFieldProps) => {

    const { useState } = React;

    const [eyeIcon, setEyeIcon] = useState(true);
    const [password, setPassword] = useState("password");

    const toggleIcon = () => {
        if (password === "password") {
            setPassword("text");
            setEyeIcon(false);
        }
        else {
            setPassword("password");
            setEyeIcon(true);
        }
    };

    const handleChange = (result: any) => {
        const name = result.target.name;
        const value = result.target.value;
        props.onChange(name, value);
    };

    return (

        <Container className="input-text">
            <CustomInput
                {...props}
                autoComplete={"new-password"}
                name={props.fieldName}
                type={password}
                className={"form-control"}
                value={props.value as string}
                onChange={handleChange}
                data-testid="password-input"
            />
            {props.value.length > 0 && (
                eyeIcon ? <StyledEyeSlash data-testid={"eye-slashed"} onClick={toggleIcon} /> : <StyledEye data-testid={"eye"} onClick={toggleIcon} />
            )}
        </Container>


    );
};

export default PasswordField;