import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment, { Moment } from "moment";
import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import styled from "styled-components";
import { appInsights, reactPlugin } from "../AppInsights";
import Colors from "../assets/Colors";
import { ReactComponent as ExportIcon } from "../assets/images/exportIcon.svg";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { StyledViewContainer } from "../components/common/View";
import ChangeInformationBlocks from "../components/themed/statistics/ChangeInformationBlock";
import ExcelExportForm from "../components/themed/statistics/ExcelExportForm";
import ProductChangesGraph from "../components/themed/statistics/ProductChangesGraph";
import StatisticsGraph from "../components/themed/statistics/StatisticsGraph";
import { RestClient } from '../data/RestClient';
import ChangeEvent from '../models/ChangeEvent';
import ChangeEventPager from '../models/ChangeEventPager';
import { DateFilter } from "../models/DateFilter";
import { DateTimeUtils } from "../models/DateTimeUtils";
import { KpiType } from "../models/KpiType";
import { CareFacility } from '../models/CareFacility';
import ISorting, { Direction } from '../models/Sorting';
import { IStatisticValues } from '../models/StatisticValues';
import { TimeFilter } from "../models/TimeFilter";
import { Ward } from "../models/Ward";
import { RootState } from '../store';
import '../styles/main.scss';
import { EFilter, ETrackEvent, parseErrorToString } from "../utils/AppInsightsHelpers";
import { convertMinutesToHours, convertTimeToHtml } from "../utils/ConvertMinutesToHours";
import { localizer } from "../utils/Localizer";
import './Statistics.scss';
import { Roles } from "../models/Role";

interface IStatisticsState {
    dateFilterSelected: DateFilter;
    timeFilterSelected: TimeFilter;
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    selectedStartDate: Moment;
    selectedEndDate: Moment;
    selectedCompareStartDate: Moment | null;
    selectedCompareEndDate: Moment | null;
    selectedStartTime: Moment | null;
    selectedEndTime: Moment | null;
    totalItemsCount: number;
    compareChangeEventsLogs: ChangeEvent[];
    averageChangeEventsLogs: ChangeEvent[];
    hasMorePages: boolean;
    sorting: ISorting;
    isLoadingNextPage: boolean;
    productChanges: IStatisticValues;
    responseTime: IStatisticValues;
    avgProductChanges: IStatisticValues;
    promptedProductChanges: IStatisticValues;
    unpromptedProductChanges: IStatisticValues;
    nightTimeMinutesBetweenChanges: IStatisticValues;
    selectedWardDaysOld: number;
    oldestWardCreationDateDays: number;
    oldestWardMoment: Moment;
    compareKpis?: {
        compareProductChanges: IStatisticValues | undefined;
        compareAvgProductChanges: IStatisticValues | undefined;
        compareResponseTime: IStatisticValues | undefined;
        comparePromptedProductChanges: IStatisticValues | undefined;
        compareNightTimeMinutesBetweenChanges: IStatisticValues | undefined;
    };
}

export interface IChartData {
    date: string;
    selectedWard: number | null;
    selectedCompareWard?: number | null;
    compareDate?: string;
    selectedWardPercentage?: number | null;
    selectedCompareWardPercentage?: number | null;
}

const StyledButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 90px;
    float: right;
    &:hover{
        fill: #092477 !important;
    }
`;

const StyledExportIcon = styled(ExportIcon)<any>`
    margin-left: 16px;
    margin-top: -3px;
    fill: ${props => props.disabled ? Colors.grey.disabledGrey : Colors.blue.brandingDark};
    &:hover{
        fill: inherit;
    }
    & path {
        transition: all 0.15s ease-in-out;
    }
`;

const StyledStatisticsContainer = styled(StyledViewContainer)`
    padding: 0px 0px 160px 0px;
`;

class Statistics extends Component<IReduxProps, IStatisticsState> {
    readonly state: IStatisticsState = {
        dateFilterSelected: DateFilter.Week,
        timeFilterSelected: DateTimeUtils.getTimeFilterOptions()[0].value,
        selectedKpi: KpiType.ResponseTime,
        selectedWard: undefined,
        selectedStartDate: moment().subtract(7, 'd'),
        selectedEndDate: moment().subtract(1, 'd'),
        selectedStartTime: null,
        selectedEndTime: null,
        totalItemsCount: 0,
        averageChangeEventsLogs: [],
        compareChangeEventsLogs: [],
        hasMorePages: true,
        sorting: { column: 'timeCreated', direction: Direction.Down },
        isLoadingNextPage: false,
        productChanges: { value: null, trend: null },
        avgProductChanges: { value: null, trend: null },
        responseTime: { value: null, trend: null },
        promptedProductChanges: { value: null, trend: null },
        nightTimeMinutesBetweenChanges: { value: null, trend: null },
        unpromptedProductChanges: { value: null, trend: null },
        selectedWardDaysOld: 0,
        oldestWardCreationDateDays: 0,
        oldestWardMoment: moment(),
        selectedCompareStartDate: null,
        selectedCompareEndDate: null,

    };

    averageChangeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);
    changeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);

    componentDidMount(): void {
        this.loadChangeLogs();
        const oldestWardDate = this.props.wards.map(ward => new Date(ward.createdDate)).sort((a, b) => a.getTime() - b.getTime())[0];
        if (this.props.wards && this.props.wards.length > 0) {
            // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
            oldestWardDate.setUTCHours(0, 0, 0, 0);
            const days = Math.floor((Date.now() - oldestWardDate.getTime()) / (24 * 3600 * 1000));
            this.setState({
                oldestWardCreationDateDays: days,
                oldestWardMoment: moment(oldestWardDate)
            });
        }
    }

    shouldComponentUpdate(nextProps: Readonly<IReduxProps>): boolean {
        // If user is logged out we DO NOT need to update component
        return Boolean(nextProps.userName);
    }

    componentDidUpdate(prevProps: IReduxProps, prevState: IStatisticsState): void {
        if (
            ((prevProps.nursingHome?.id !== this.props.nursingHome?.id) && !!this.props.nursingHome?.id)
            || prevState.dateFilterSelected !== this.state.dateFilterSelected
            || prevState.selectedStartDate !== this.state.selectedStartDate
            || prevState.selectedEndDate !== this.state.selectedEndDate
            || prevState.selectedStartTime !== this.state.selectedStartTime
            || prevState.selectedEndTime !== this.state.selectedEndTime
            || prevState.selectedWard?.id !== this.state.selectedWard?.id
            || prevState.selectedKpi !== this.state.selectedKpi
            || prevState.timeFilterSelected !== this.state.timeFilterSelected
            || prevState.sorting.column !== this.state.sorting.column
            || prevState.sorting.direction !== this.state.sorting.direction
            || prevState.selectedCompareStartDate !== this.state.selectedCompareStartDate
            || prevState.selectedCompareEndDate !== this.state.selectedCompareEndDate
        ) {
            this.loadChangeLogs();
        }

        if (prevProps.wards !== this.props.wards && this.props.wards.length > 0) {
            const oldestWardDate = this.props.wards.map(ward => new Date(ward.createdDate)).sort((a, b) => a.getTime() - b.getTime())[0];
            // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
            oldestWardDate.setUTCHours(0, 0, 0, 0);
            const days = Math.floor((Date.now() - oldestWardDate.getTime()) / (24 * 3600 * 1000));
            this.setState({
                oldestWardCreationDateDays: days,
                oldestWardMoment: moment(oldestWardDate)
            });
        }
    }

    loadChangeLogs = () => {
        this.getAverageChangeLogs();
    }

    private allWardOption = { name: localizer('statistics.chart.allWards'), id: 'all' };

    getCurrentFilerSelection = () => {
        return {
            dateFilter: this.state.dateFilterSelected,
            startDate: this.state.selectedStartDate.format('YYYY-MM-DD'),
            endDate: this.state.selectedEndDate.format('YYYY-MM-DD'),
            timeFilter: this.state.timeFilterSelected,
            kpi: this.state.selectedKpi,
            ward: this.state.selectedWard ? this.state.selectedWard.id : "All",
        }
    };

    handleWardSelection = (wardId: string | null) => {
        if (wardId !== null) {
            const ward = this.props.wards.find(ward => ward.id === wardId);
            let amountOfDays = 1;
            if (ward) {
                const wardDate = new Date(ward.createdDate);
                // we are setting creation date to be start of day so we ensure we always take in consideration that day as a valid one
                wardDate.setUTCHours(0, 0, 0, 0);
                amountOfDays = Math.floor((Date.now() - wardDate.getTime()) / (24 * 3600 * 1000));
            }
            this.setState({ selectedWard: ward, selectedWardDaysOld: amountOfDays });
        } else {
            this.setState({ selectedWard: undefined });
        }
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                ward: wardId ? wardId : "All",
                filterUsed: EFilter.WardFilter
            }
        });
        this.clearCompareDates();
    };

    dateFilterChanged = (value: any) => {
        this.setState({ dateFilterSelected: Number(value) });
        this.changeEventsPager.timeFrameDays = Number(value);
        this.averageChangeEventsPager.timeFrameDays = Number(value);
        this.clearCompareDates();
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                dateFilter: value,
                filterUsed: EFilter.DateFilter
            }
        });
    };

    timeFilterChanged = (value: any) => {
        if (value === TimeFilter.AllDay || value === TimeFilter.DayShift || value === TimeFilter.NightShift) {
            this.setState({ timeFilterSelected: value, selectedStartTime: null, selectedEndTime: null });
        } else {
            this.setState({ timeFilterSelected: value });
        }
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                timeFilter: value,
                filterUsed: EFilter.TimeFilter
            }
        });
        this.clearCompareDates();
    };

    startDateChanged = (value: any) => {
        if (this.state.dateFilterSelected !== DateFilter.CustomRange) {
            this.setState({ selectedStartDate: value, dateFilterSelected: DateFilter.CustomRange });
        } else {
            this.setState({ selectedStartDate: value });
        }
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                dateFilter: DateFilter.CustomRange,
                startDate: value.format('YYYY-MM-DD'),
                filterUsed: EFilter.CustomStartDateFilter
            }
        });
        this.clearCompareDates();
    }

    endDateChanged = (value: any) => {
        if (this.state.dateFilterSelected !== DateFilter.CustomRange) {
            this.setState({ selectedEndDate: value, dateFilterSelected: DateFilter.CustomRange });
        } else {
            this.setState({ selectedEndDate: value });
        }
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                dateFilter: DateFilter.CustomRange,
                endDate: value.format('YYYY-MM-DD'),
                filterUsed: EFilter.CustomEndDateFilter
            }
        });
        this.clearCompareDates();
    }

    startTimeChanged = (value: any) => {
        if (this.state.timeFilterSelected !== TimeFilter.CustomRange) {
            this.setState({ selectedStartTime: value, timeFilterSelected: TimeFilter.CustomRange });
        } else {
            this.setState({ selectedStartTime: value });
        }
    }

    endTimeChanged = (value: any) => {
        if (this.state.timeFilterSelected !== TimeFilter.CustomRange) {
            this.setState({ selectedEndTime: value, timeFilterSelected: TimeFilter.CustomRange });
        } else {
            this.setState({ selectedEndTime: value });
        }
    }

    kpiChanged = (value: any) => {
        appInsights.trackEvent({
            name: ETrackEvent.NHStatistics,
            properties: {
                ...this.getCurrentFilerSelection(),
                kpi: value,
                filterUsed: EFilter.KpiFilter
            }
        });
        this.setState({ selectedKpi: value });
    }

    getAllWardsOptions(): { name: string, id: string }[] {
        const options = this.props.wards.map((ward: Ward) => ({ name: ward.name, id: ward.id }));
        // sort alphabetically
        options.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
        if (options.length !== 0 || [Roles.GlobalAdmin].includes(this.props.role)) {
            options.unshift(this.allWardOption);
        }
        return options;
    }

    validateStartTimeField(): string {
        if (this.state.selectedStartTime === null && this.state.selectedEndTime !== null) {
            return "error-field";
        }
        return "";
    }

    clearAverageChangeLogs = () => {
        this.averageChangeEventsPager = new ChangeEventPager(this.state.dateFilterSelected);
        this.setState({
            averageChangeEventsLogs: [],
        });
    }

    getAverageChangeLogs = async () => {
        this.clearAverageChangeLogs();

        const { startDate, endDate } = DateTimeUtils.getDateRange(this.state.dateFilterSelected, this.state.selectedStartDate, this.state.selectedEndDate, this.state.selectedWard ? this.state.selectedWardDaysOld - 1 : this.state.oldestWardCreationDateDays - 1, true, true);

        // Day shift = true
        // Night shift = false
        // Day and night = null
        const isDayShift = this.state.timeFilterSelected === TimeFilter.DayShift || this.state.timeFilterSelected === TimeFilter.NightShift ? this.state.timeFilterSelected === TimeFilter.DayShift : null;

        try {
            const response: any = await RestClient.getAverageChangeLogs(
                this.props.nursingHome.id,
                startDate,
                endDate,
                isDayShift,
                this.state.selectedWard ? this.state.selectedWard.id : this.props.wards.map(w => w.id)
            );
            const rawChangeLogs: ChangeEvent[] = response.groupedNurseActions;

            const logsPage = rawChangeLogs
                .map(value => new ChangeEvent(value));

            this.averageChangeEventsPager.pages.push(logsPage);

            const logs = this.averageChangeEventsPager.getLogs();

            let compareLogs: ChangeEvent[] = [];

            let compareProductChanges: IStatisticValues | undefined;
            let compareResponseTime: IStatisticValues | undefined;
            let comparePromptedProductChanges: IStatisticValues | undefined;
            let compareNightTimeMinutesBetweenChanges: IStatisticValues | undefined;
            let compareAvgProductChanges: IStatisticValues | undefined;

            if (this.state.selectedCompareStartDate !== null && this.state.selectedCompareEndDate !== null) {
                const compareDates = DateTimeUtils.getDateRange(DateFilter.CustomRange, this.state.selectedCompareStartDate, this.state.selectedCompareEndDate, this.state.selectedWard ? this.state.selectedWardDaysOld - 1 : this.state.oldestWardCreationDateDays - 1, true, true);

                const compareResponse: any = await RestClient.getAverageChangeLogs(
                    this.props.nursingHome.id,
                    compareDates.startDate,
                    compareDates.endDate,
                    isDayShift,
                    this.state.selectedWard ? this.state.selectedWard.id : this.props.wards.map(w => w.id)
                );
                appInsights.trackEvent({
                    name: ETrackEvent.NHStatisticsCompare,
                    properties: {
                        startDate: compareDates.startDate,
                        endDate: compareDates.endDate,
                        selectedKpi: this.state.selectedKpi,
                    }
                });
                const rawCompareChangeLogs: ChangeEvent[] = compareResponse.groupedNurseActions;
                const compareLogsPage = rawCompareChangeLogs
                    .map(value => new ChangeEvent(value));
                compareLogs = compareLogsPage;
                compareProductChanges = compareResponse.productChanges;
                compareAvgProductChanges = compareResponse.averageProductChanges;
                compareResponseTime = compareResponse.responseTime.value ? { ...compareResponse.responseTime, value: Math.round(compareResponse.responseTime.value) } : compareResponse.responseTime;
                comparePromptedProductChanges = compareResponse.promptedProductChanges;
                compareNightTimeMinutesBetweenChanges = compareResponse.nightTimeMinutesBetweenChanges ? { ...compareResponse.nightTimeMinutesBetweenChanges, value: Math.round(compareResponse.nightTimeMinutesBetweenChanges.value) } : compareResponse.nightTimeMinutesBetweenChanges;

            }

            const responseTime = response.responseTime.value ? { ...response.responseTime, value: Math.round(response.responseTime.value) } : response.responseTime;
            const nightTime = response.nightTimeMinutesBetweenChanges ? { ...response.nightTimeMinutesBetweenChanges, value: Math.round(response.nightTimeMinutesBetweenChanges.value) } : response.nightTimeMinutesBetweenChanges;
            this.setState({
                averageChangeEventsLogs: logs,
                compareChangeEventsLogs: compareLogs,
                productChanges: response.productChanges,
                avgProductChanges: response.averageProductChanges,
                responseTime,
                promptedProductChanges: response.promptedProductChanges,
                nightTimeMinutesBetweenChanges: nightTime,
                compareKpis: {
                    compareProductChanges,
                    compareAvgProductChanges,
                    compareResponseTime,
                    comparePromptedProductChanges,
                    compareNightTimeMinutesBetweenChanges
                }
            });
        } catch (error: any) {
            this.clearAverageChangeLogs();

            console.error(error);
            appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
        }
    }

    setLoadingNextPage = (value: boolean) => {
        this.setState({
            isLoadingNextPage: value
        });
    }

    setCompareStartDate = (value: Moment | null) => {
        this.setState({
            selectedCompareStartDate: value
        });
    }

    setCompareEndDate = (value: Moment | null) => {
        this.setState({
            selectedCompareEndDate: value
        });
    }

    clearCompareDates = () => {
        this.setState({
            selectedCompareStartDate: null,
            selectedCompareEndDate: null
        });

    }

    getSelectedKpiValueProduct = (selectedKpi: KpiType, isCompare: boolean) => {
        let value;
        switch (selectedKpi) {
            case KpiType.AllProductChanges:
                value = isCompare ? this.state.compareKpis?.compareProductChanges?.value : this.state.productChanges.value;
                break;
            case KpiType.PromptedProductChanges:
                value = isCompare ? this.state.compareKpis?.comparePromptedProductChanges?.value : this.state.promptedProductChanges.value;
                break;
            case KpiType.AverageProductChanges:
                value = isCompare ? this.state.compareKpis?.compareAvgProductChanges?.value : this.state.avgProductChanges.value;
                break;
            default:
                break;
        }
        return value;
    }

    getSelectedKpiValueStatistics = (selectedKpi: KpiType, isCompare: boolean) => {
        let value;
        switch (selectedKpi) {
            case KpiType.ResponseTime:
                value = isCompare ? this.state.compareKpis?.compareResponseTime?.value : this.state.responseTime.value;
                break;
            case KpiType.AvgNightTimeMinutesBetweenChanges:
                value = isCompare ? this.state.compareKpis?.compareNightTimeMinutesBetweenChanges?.value : this.state.nightTimeMinutesBetweenChanges.value;
                break;
            default:
                value = isCompare ? this.state.compareKpis?.compareResponseTime?.value : this.state.responseTime.value;
                break;
        }
        return value;
    }

    getFormattedTooltipValue = (kpiRawValue: number | null, selectedKpi: KpiType) => {
        let formattedValue: any;
        if (kpiRawValue === null || kpiRawValue === 0) {
            formattedValue = "--";
        } else {
            if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
                formattedValue = convertTimeToHtml(
                    convertMinutesToHours(Math.round(selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges ? kpiRawValue * 60 : kpiRawValue)),
                    true
                );
            } else if (selectedKpi === KpiType.AllProductChanges) {
                formattedValue = <>{(Math.round(kpiRawValue * 10) / 10).toString() + " "}<FormattedMessage id={"constants.changeEventsFilter.kpi.changes"} /></>;
            } else if (selectedKpi === KpiType.AverageProductChanges) {
                formattedValue = <>{(Math.round(kpiRawValue * 10) / 10).toString() + " /"}<FormattedMessage id={"constants.changeEventsFilter.kpi.day"} /></>;
            } else {
                formattedValue = (
                    <span>
                        {Math.round(kpiRawValue)}{" "}%
                    </span>);
            }
        }
        return formattedValue;
    };

    getFormattedLegendValue = (kpiRawValue: number | null, selectedKpi: KpiType) => {
        let formattedValue: any;
        if (kpiRawValue === null) {
            formattedValue = "--";
        } else {
            if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
                formattedValue = convertTimeToHtml(
                    convertMinutesToHours(kpiRawValue),
                    true
                );
            } else if (selectedKpi === KpiType.AllProductChanges) {
                formattedValue = <>{(Math.round(kpiRawValue * 10) / 10)}{" "}<FormattedMessage id={"constants.changeEventsFilter.kpi.changes"} /></>;
            } else if (selectedKpi === KpiType.AverageProductChanges) {
                formattedValue = <>{(Math.round(kpiRawValue * 10) / 10).toString() + " /"}<FormattedMessage id={"constants.changeEventsFilter.kpi.day"} /></>;
            } else {
                formattedValue = <>{Math.round(kpiRawValue)}{"% "}<FormattedMessage id="statistics.legend.prompted" /></>;
            }
        }
        return formattedValue;
    };

    getFormattedDifference = (selectedKpi: KpiType, difference: number) => {
        let formattedValue = <></>;
        if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            formattedValue = convertTimeToHtml(
                convertMinutesToHours(difference),
                true
            );
        } else if (selectedKpi === KpiType.PromptedProductChanges) {
            formattedValue = <>{Math.round(difference) + " %"}</>;
        } else if (selectedKpi === KpiType.AllProductChanges) {
            formattedValue = <>{Math.round(difference * 10) / 10}{" "}<FormattedMessage id={"statistics.tooltip.changes"} /></>;
        } else if (selectedKpi === KpiType.AverageProductChanges) {
            formattedValue = <>{Math.round(difference * 10) / 10}{" /"}<FormattedMessage id={"constants.changeEventsFilter.kpi.day"} /></>;
        }
        return formattedValue;
    };

    render(): React.ReactElement {
        const { startDate, endDate } = DateTimeUtils.getDateRange(this.state.dateFilterSelected, this.state.selectedStartDate, this.state.selectedEndDate, this.state.selectedWard ? this.state.selectedWardDaysOld - 1 : this.state.oldestWardCreationDateDays - 1, true);
        const dateFilterRanges = [
            DateFilter.Yesterday,
            DateFilter.Week,
            DateFilter.HalfMonth,
            DateFilter.Month,
            DateFilter.ThreeMonths,
            DateFilter.SixMonths,
            DateFilter.TwelveMonths,
            DateFilter.SinceStart,
            DateFilter.CustomRange,
        ];


        const triggerComponent = (
            <StyledButtonsContainer>
                <Button
                    variant="outline-secondary"
                    disabled={[Roles.GlobalAdmin, Roles.CountryAdmin].includes(this.props.role)}
                >
                    {localizer("statistics.excelExport.exportData")}
                    <StyledExportIcon disabled={[Roles.GlobalAdmin, Roles.CountryAdmin].includes(this.props.role)}/>
                </Button>
            </StyledButtonsContainer>);

        return (
            <StyledStatisticsContainer id="statistics-view">
                {this.props.nursingHome.id && <div>
                    <ChangeInformationBlocks
                        allWards={this.getAllWardsOptions()}
                        selectedDateFilter={Number(this.state.dateFilterSelected)}
                        ward={this.state.selectedWard ? this.state.selectedWard : this.allWardOption}
                        dateFilterOptions={DateTimeUtils.getDateFilterOptions(dateFilterRanges, true)}
                        timeFilterOptions={DateTimeUtils.getTimeFilterOptions([TimeFilter.AllDay, TimeFilter.DayShift, TimeFilter.NightShift])}
                        dateFilterChanged={this.dateFilterChanged}
                        startDateChanged={this.startDateChanged}
                        endDateChanged={this.endDateChanged}
                        startTimeChanged={this.startTimeChanged}
                        endTimeChanged={this.endTimeChanged}
                        timeChanged={this.timeFilterChanged}
                        selectWard={this.handleWardSelection}
                        selectedTimeFilter={this.state.timeFilterSelected}
                        selectedStartDate={this.state.selectedStartDate}
                        selectedEndDate={this.state.selectedEndDate}
                        selectedStartTime={this.state.selectedStartTime}
                        selectedEndTime={this.state.selectedEndTime}
                        kpiChanged={this.kpiChanged}
                        selectedKpi={this.state.selectedKpi}
                        productChanges={this.state.productChanges}
                        responseTime={this.state.responseTime}
                        avgProductChanges={this.state.avgProductChanges}
                        promptedProductChanges={this.state.promptedProductChanges}
                        nightTimeMinutesBetweenChanges={this.state.nightTimeMinutesBetweenChanges}
                        oldestWardCreationDateDays={this.state.oldestWardCreationDateDays}
                        selectedWardDaysOld={this.state.selectedWardDaysOld}
                    />
                    <div className="table-wrapper">
                        {this.state.selectedKpi === KpiType.ResponseTime || this.state.selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges ? (
                            <StatisticsGraph
                                averageChangeEventsLogs={this.state.averageChangeEventsLogs}
                                compareChangeEventsLogs={this.state.compareChangeEventsLogs}
                                selectedWard={this.state.selectedWard}
                                selectedKpi={this.state.selectedKpi}
                                kpiValue={this.getSelectedKpiValueStatistics(this.state.selectedKpi, false)}
                                compareKpiValue={this.getSelectedKpiValueStatistics(this.state.selectedKpi, true)}
                                startDate={startDate}
                                endDate={endDate}
                                startCompareDate={this.state.selectedCompareStartDate}
                                nursingHome={this.props.nursingHome}
                                oldestWardMoment={this.state.oldestWardMoment}
                                selectedWardCreation={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : undefined}
                                setSelectedCompareStartDate={this.setCompareStartDate}
                                setSelectedCompareEndDate={this.setCompareEndDate}
                                getFormattedTooltipValue={this.getFormattedTooltipValue}
                                getFormattedLegendValue={this.getFormattedLegendValue}
                                getFormattedDifference={this.getFormattedDifference}
                            />
                        ) : (
                            <ProductChangesGraph
                                averageChangeEventsLogs={this.state.averageChangeEventsLogs}
                                compareChangeEventsLogs={this.state.compareChangeEventsLogs}
                                selectedWard={this.state.selectedWard}
                                selectedKpi={this.state.selectedKpi}
                                kpiValue={this.getSelectedKpiValueProduct(this.state.selectedKpi, false)}
                                compareKpiValue={this.getSelectedKpiValueProduct(this.state.selectedKpi, true)}
                                startDate={startDate}
                                endDate={endDate}
                                startCompareDate={this.state.selectedCompareStartDate}
                                oldestWardMoment={this.state.oldestWardMoment}
                                selectedWardCreation={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : undefined}
                                setSelectedCompareStartDate={this.setCompareStartDate}
                                setSelectedCompareEndDate={this.setCompareEndDate}
                                getFormattedTooltipValue={this.getFormattedTooltipValue}
                                getFormattedLegendValue={this.getFormattedLegendValue}
                                getFormattedDifference={this.getFormattedDifference}
                            />
                        )}


                        <ModalWrapper
                            disabled={[Roles.GlobalAdmin, Roles.CountryAdmin].includes(this.props.role)}
                            triggerComponent={triggerComponent}
                            modalContent={
                                <ExcelExportForm
                                    startDate={this.state.selectedWard ? moment(this.state.selectedWard.createdDate) : this.state.oldestWardMoment}
                                    wardIds={this.state.selectedWard ? [this.state.selectedWard.id] : this.props.wards.map((ward: Ward) => ward.id)}
                                    nursingHomeId={this.props.nursingHome.id}
                                />
                            }
                            modalTitle={localizer("statistics.excelExport.exportData")}
                            size={EModalSize.XS}
                        />

                    </div>
                </div>
                }
            </StyledStatisticsContainer>
        );
    }
}

interface IReduxProps {
    nursingHome: CareFacility;
    wards: Ward[];
    userName: string;
    role: Roles;
}

const mapStateToProps = (state: RootState): IReduxProps => {
    return {
        nursingHome: state.nursingHomeSlice.nursingHome || new CareFacility(),
        wards: state.wardSlice.wards,
        userName: state.contextSlice.userName,
        role: state.contextSlice.role
    };
};

export default connect(
    mapStateToProps
)(withAITracking(reactPlugin, Statistics));
