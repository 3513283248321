
import styled, { css } from "styled-components";
import { Container, Dropdown, Row } from "react-bootstrap";
import Colors from "../../../../../assets/Colors";
import { StyledPickerCss, StyledPickerPanelCss }from "../CommonDatePickerStyle";
import { ReactComponent as Calendar} from "../../../../../assets/images/calendar.svg";

interface IDropdownContainerStyledProps {
    isActive: boolean;
}
export const StyledDropdownContainer = styled(({ isActive, ...props }) => ( <Container {...props} />)) <IDropdownContainerStyledProps>`
    padding: 0.25rem 16px;
    max-width: 300px;
    cursor: pointer;

    ${(props) =>
        props.isActive &&
        css`
            background-color: ${Colors.blue.brandingDark} !important;
            color: ${Colors.white.clearWhite} !important;

            svg {
                color: ${Colors.white.clearWhite};
            }
        `};
`;

export const StyledDropdownDivider = styled(Dropdown.Divider)`
    margin: 0;
`;

export const StyledDateInput = styled.div`
    width: 80%;
    input {
        text-align: left;
        text-indent: 30px;
        border: 1px solid ${Colors.grey.medium};
        border-radius: 2px;
    }
`;

export const StyledSmallerTextDiv = styled.div`
    font-size: 14px;
    font-weight: normal;
    width: 55px;
    margin-right: 12px;
`;

export const StyledTextDiv = styled(StyledSmallerTextDiv)`
    font-size: 16px;
`;
export const StyledDateTimeRow = styled(Row)`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px 6px 0px;
`;

export const StyledPicker = styled.div<any>`
    ${StyledPickerCss}    
    width: 100%;
    .rc-picker {
        width: 90%;
        height: 32px;        
        border-radius: 2px !important;
        background-color: white !important; 
        .rc-picker-input {
            background-color: white !important; 
            border-radius: 2px !important;
            input {
                padding: 10px;
            }
            &:hover { 
                cursor: pointer;
                box-shadow: 0 0px 0px #ccc !important;
            } 
        }
    }

    .rc-picker-focused {
        .rc-picker-input {
            input {
                border-radius: 2px !important;
                background-color: #CCDAE9 !important;
            }
            svg {
                top: 2px;
            }
        }
        border: 3px solid white !important;
        .rc-picker-input-placeholder > input{
            ${props => props.selectedValue ? "" : "color: #333 !important;"}
        }
    }
`;

export const StyledPickerPanel = styled.div<any>`
    ${StyledPickerPanelCss}
    .rc-picker-panel {
        border-top-right-radius: 4px;
        .rc-picker-date-panel {
            .rc-picker-header {
                border-top-right-radius: 4px;
            }
            .rc-picker-body {
                .rc-picker-content {
                    tbody {
                        .rc-picker-cell {
                            .rc-picker-cell-inner:hover {
                                background-color: ${Colors.blue.lighter};
                            }
                        }
                        .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner{
                            background-color: ${Colors.blue.brandingDark};
                            background: ${Colors.blue.brandingDark};
                            color: white;
                        }

                    }
                }
            }
        }
        .rc-picker-month-panel {
            .rc-picker-header {
                border-top-right-radius: 4px;
            }
        }    
        .rc-picker-year-panel {
            .rc-picker-header {
                border-top-right-radius: 4px;
            }
            .rc-picker-body {
                padding: 10px;
                .rc-picker-content {
                    .rc-picker-cell {
                        .rc-picker-cell-inner:hover {
                            background-color: ${Colors.blue.lighter};
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        background-color: ${Colors.blue.brandingDark};
                        background: ${Colors.blue.brandingDark};
                        color: white;

                    }
                }
            }
        }
        .rc-picker-decade-panel {
            .rc-picker-header {
                border-top-right-radius: 4px;
            }
            .rc-picker-body {
                .rc-picker-content {
                    .rc-picker-cell {
                        .rc-picker-cell-inner:hover {
                            background-color: ${Colors.blue.lighter};
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        color: white;
                        background-color: ${Colors.blue.brandingDark};
                        background: ${Colors.blue.brandingDark};
                    }
                }
            }
        }
    }
`;

export const StyledDay = styled.div<any>`
    line-height: 27px;
    height: 27px;
    width: 27px;
`;

export const StyledMonth = styled.div<any>`
    text-transform: uppercase;
    &:hover {  
        background-color: ${Colors.blue.lighter};
        border-radius: 4px;
    }
`;

export const StyledCalendar = styled(Calendar)`
    color: ${Colors.blue.brandingDark};
    position: absolute;
    font-size: 22px;
    width: inherit !important;
    right: 75px;
    top: 4px;
    font-size: 20px;
    cursor: pointer;
`;

export const StyledInput = styled.input`
    width: 100%;
`;

export const StyledInfoMessageContainer = styled.div`
    width: 100%;
    font-size: 14px;
    font-style: italic;
    background-color: ${Colors.grey.tenaLight};
    color: ${Colors.grey.medium};
    min-height: 31px;
    line-height: 22px
    align-items: center;
    padding: 9px 16px;
`;
