import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NurseRepository } from "../data/NurseRepository";
import { useAppSelector } from "../hooks/useAppSelector";
import { Roles } from "../models/Role";
import ActivateAccountView from "../pages/ActivateAccountView";
import AddCareFacilityView from "../pages/AddCareFacilityView";
import AddExternalUserView from "../pages/AddExternalUserView";
import AddInternalUserView from "../pages/AddInternalUserView";
import AuditView, { AuditContext } from "../pages/AuditView";
import CareFacilitiesView from "../pages/CareFacilitiesView";
import CareFacilityView from "../pages/CareFacilityView";
import CareGroupsView from "../pages/CareGroupsView";
import ContextSelectionView from "../pages/ContextSelectionView";
import CountriesView from "../pages/CountriesView";
import EditCareFacilityView from "../pages/EditCareFacilityView";
import EditExternalUserView from "../pages/EditExternalUserView";
import EditInternalUserView from "../pages/EditInternalUserView";
import HelpView from "../pages/HelpView";
import HomeView from "../pages/HomeView";
import LoginView from "../pages/LoginView";
import ManagePasswordWithActiveSessionView from "../pages/ManagePasswordWithActiveSessionView";
import MonitoringView from "../pages/MonitoringView";
import ResetPasswordView from "../pages/ResetPasswordView";
import ResidentView from "../pages/ResidentView";
import ResidentsView from "../pages/ResidentsView";
import SetupView from "../pages/SettingsView";
import SpecificADLoginView from "../pages/SpecificADLoginView";
import Statistics from "../pages/StatisticsView";
import UserView from "../pages/UserView";
import WardsView from "../pages/WardsView";
import { IContextStateType, setRedirectUrl } from "../reducers/contextSlice";
import { routePaths } from "../utils/PathHelpers";
import Navbar, { LinkData } from "../views/Navbar";
import NavbarNurse from "../views/NavbarNurse";
import RoutesWrapper from "./RoutesWrapper";
import { useAppDispatch } from "../hooks/useAppDispatch";

const RoutesGenerator = () => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const dispatch = useAppDispatch();
    const [route, setRoute] = useState<React.ReactElement | null>(null);

    useEffect(() => {
        const locationUrl = new URL(window.location.href);
        if([routePaths.overview, routePaths.loginPath, routePaths.activateAccount, routePaths.resetPassword].includes(locationUrl.pathname)) {
            return
        }

        dispatch(setRedirectUrl(`${locationUrl.pathname}${locationUrl.search}`));
    }, [dispatch]);

    useEffect(() => {
        const prepareComponent = () => {
            let component;
            if (context.isAuthenticated) {
                switch (context.role) {
                    case Roles.GlobalAdmin:
                        let contextBasedLinks: LinkData[] = [];
                        if (context.id && context.id !== "") {
                            contextBasedLinks = [
                                {
                                    id: "mainNav.careFacility",
                                    qaId: "careFacilty",
                                    to: routePaths.careFacility,
                                },
                                {
                                    id: "mainNav.statistics",
                                    qaId: "statistics",
                                    to: routePaths.statistics
                                }
                            ];
                        }
                        const linksData: LinkData[] = [
                            ...contextBasedLinks,
                            {
                                id: "mainNav.instructions",
                                qaId: "help",
                                to: routePaths.help,
                            },
                            {
                                id: "mainNav.settings",
                                qaId: "setup",
                                to: routePaths.settings,
                                subLinks: [
                                    {
                                        id: "mainNav.users",
                                        qaId: "users",
                                        to: routePaths.users,
                                    },
                                    {
                                        id: "mainNav.careFacilities",
                                        qaId: "careFacilities",
                                        to: routePaths.careFacilities,
                                    },
                                    {
                                        id: "mainNav.careGroups",
                                        qaId: "careGroups",
                                        to: routePaths.careGroups,
                                    },
                                    {
                                        id: "mainNav.countries",
                                        qaId: "countries",
                                        to: routePaths.countries,
                                    },
                                    {
                                        id: "mainNav.audit",
                                        qaId: "audit",
                                        to: routePaths.audit,
                                    }
                                ]
                            },
                        ];

                        component = (
                            <RoutesWrapper>
                                <Navbar linksData={linksData} />
                                <Routes>
                                    <Route path={routePaths.overview} element={<ContextSelectionView />} />
                                    {context.id && context.id !== "" && (
                                        <>
                                            <Route path={routePaths.careFacilitySettings} element={<SetupView />} />
                                            <Route path={routePaths.statistics} element={<Statistics />} />
                                        </>
                                    )}
                                    <Route path={routePaths.users} element={<UserView />} />
                                    <Route path={routePaths.addUser} element={<AddInternalUserView />} />
                                    <Route path={routePaths.editUser} element={<EditInternalUserView />} />
                                    <Route path={routePaths.careFacility} element={<CareFacilityView />} />
                                    <Route path={routePaths.addCareFacility} element={<AddCareFacilityView />} />
                                    <Route path={routePaths.careGroups} element={<CareGroupsView />} />
                                    <Route path={routePaths.careFacilities} element={<CareFacilitiesView />} />
                                    <Route path={routePaths.editCareFacility} element={<EditCareFacilityView />} />
                                    <Route path={routePaths.countries} element={<CountriesView />} />
                                    <Route path={routePaths.help} element={<HelpView />} />
                                    <Route path={routePaths.audit} element={<AuditView context={AuditContext.Global} />} />
                                    <Route path={routePaths.activateAccount} element={<ManagePasswordWithActiveSessionView />} />
                                    <Route path={routePaths.resetPassword} element={<ManagePasswordWithActiveSessionView />} />
                                    <Route path="*" element={<Navigate to={routePaths.overview} />} />
                                </Routes>
                            </RoutesWrapper>
                        );
                        break;
                    case Roles.CountryAdmin: {
                        let contextBasedLinks: LinkData[] = [];
                        if (context.id && context.id !== "") {
                            contextBasedLinks = [
                                {
                                    id: "mainNav.careFacility",
                                    qaId: "careFacilty",
                                    to: routePaths.careFacility,
                                },
                                {
                                    id: "mainNav.statistics",
                                    qaId: "statistics",
                                    to: routePaths.statistics
                                }
                            ];
                        }
                        const linksData: LinkData[] = [
                            ...contextBasedLinks,
                            {
                                id: "mainNav.instructions",
                                qaId: "help",
                                to: routePaths.help,
                            },
                            {
                                id: "mainNav.settings",
                                qaId: "setup",
                                to: routePaths.settings,
                                subLinks: [
                                    {
                                        id: "mainNav.users",
                                        qaId: "users",
                                        to: routePaths.users,
                                    },
                                    {
                                        id: "mainNav.careFacilities",
                                        qaId: "careFacilities",
                                        to: routePaths.careFacilities,
                                    },
                                    {
                                        id: "mainNav.careGroups",
                                        qaId: "careGroups",
                                        to: routePaths.careGroups,
                                    }
                                ]
                            },
                        ];

                        component = (
                            <RoutesWrapper>
                                <Navbar linksData={linksData} />
                                <Routes>
                                    <Route path={routePaths.overview} element={<ContextSelectionView />} />
                                    {context.id && context.id !== "" && (
                                        <>
                                            <Route path={routePaths.careFacilitySettings} element={<SetupView />} />
                                            <Route path={routePaths.statistics} element={<Statistics />} />
                                        </>
                                    )}
                                    <Route path={routePaths.users} element={<UserView />} />
                                    <Route path={routePaths.addUser} element={<AddExternalUserView />} />
                                    <Route path={routePaths.editUser} element={<EditExternalUserView />} />
                                    <Route path={routePaths.careFacility} element={<CareFacilityView />} />
                                    <Route path={routePaths.careFacilities} element={<CareFacilitiesView />} />
                                    <Route path={routePaths.editCareFacility} element={<EditCareFacilityView />} />
                                    <Route path={routePaths.addCareFacility} element={<AddCareFacilityView />} />
                                    <Route path={routePaths.careGroups} element={<CareGroupsView />} />
                                    <Route path={routePaths.help} element={<HelpView />} />
                                    <Route path={routePaths.activateAccount} element={<ManagePasswordWithActiveSessionView />} />
                                    <Route path={routePaths.resetPassword} element={<ManagePasswordWithActiveSessionView />} />
                                    <Route path="*" element={<Navigate to={routePaths.overview} />} />
                                </Routes>
                            </RoutesWrapper>
                        );
                        break;
                    }
                    case Roles.Support:
                    case Roles.NHManager: {
                        let contextBasedLinks: LinkData[] = [];
                        if (context.id && context.id !== "") {
                            contextBasedLinks = [
                                {
                                    id: "mainNav.home",
                                    qaId: "home",
                                    to: routePaths.home,
                                },
                                {
                                    id: "mainNav.monitoring",
                                    qaId: "monitoring",
                                    to: routePaths.monitoring,
                                },
                                {
                                    id: "mainNav.careFacility",
                                    qaId: "careFacilty",
                                    to: routePaths.careFacility,
                                },
                                {
                                    id: "mainNav.statistics",
                                    qaId: "statistics",
                                    to: routePaths.statistics,
                                },
                            ];
                        }
                        const linksData: LinkData[] = [
                            ...contextBasedLinks,
                            {
                                id: "mainNav.instructions",
                                qaId: "instructions",
                                to: routePaths.help,
                            },
                            {
                                id: "mainNav.settings",
                                qaId: "setup",
                                to: routePaths.settings,
                                subLinks: [
                                    {
                                        id: "mainNav.users",
                                        qaId: "users",
                                        to: routePaths.users,
                                    }
                                ]
                            },
                        ];
                        component = (
                            <RoutesWrapper>
                                <NavbarNurse linksData={linksData} />
                                <NurseRepository>
                                    <Routes>
                                        <Route path={routePaths.overview} element={<ContextSelectionView />} />
                                        <Route path={routePaths.home} element={<HomeView />} />
                                        <Route path={routePaths.careFacility} element={<CareFacilityView />} />
                                        <Route path={routePaths.monitoring} element={<MonitoringView />} />
                                        <Route path={routePaths.careRecipients} element={<ResidentsView />} />
                                        <Route path={routePaths.careRecipient} element={<ResidentView />} />
                                        <Route path={routePaths.careRecipientWithTab} element={<ResidentView />} />
                                        <Route path={routePaths.statistics} element={<Statistics />} />
                                        <Route path={routePaths.careFacilitySettings} element={<SetupView />} />
                                        <Route path={routePaths.wards} element={<WardsView />} />
                                        <Route path={routePaths.users} element={<UserView />} />
                                        <Route path={routePaths.editUser} element={<EditExternalUserView />} />
                                        <Route path={routePaths.addUser} element={<AddExternalUserView />} />
                                        <Route path={routePaths.help} element={<HelpView />} />
                                        <Route path={routePaths.careFaciltyAudit} element={<AuditView context={AuditContext.NursingHome} />} />
                                        <Route path={routePaths.activateAccount} element={<ManagePasswordWithActiveSessionView />} />
                                        <Route path={routePaths.resetPassword} element={<ManagePasswordWithActiveSessionView />} />
                                        <Route path="*" element={<Navigate to={routePaths.overview} />} />
                                    </Routes>
                                </NurseRepository>
                            </RoutesWrapper>
                        );
                        break;
                    }
                    case Roles.Nurse: {
                        let contextBasedLinks: LinkData[] = [];
                        if (context.id && context.id !== "") {
                            contextBasedLinks = [
                                {
                                    id: "mainNav.home",
                                    qaId: "home",
                                    to: routePaths.home,
                                },
                                {
                                    id: "mainNav.monitoring",
                                    qaId: "monitoring",
                                    to: routePaths.monitoring,
                                },
                                {
                                    id: "mainNav.careFacility",
                                    qaId: "careFacilty",
                                    to: routePaths.careFacility,
                                },
                                {
                                    id: "mainNav.statistics",
                                    qaId: "statistics",
                                    to: routePaths.statistics
                                }
                            ];
                        }
                        const linksData: LinkData[] = [
                            ...contextBasedLinks,
                            {
                                id: "mainNav.instructions",
                                qaId: "help",
                                to: routePaths.help,
                            },
                            {
                                id: "mainNav.settings",
                                qaId: "setup",
                                to: routePaths.settings,
                                subLinks: [
                                    {
                                        id: "mainNav.users",
                                        qaId: "users",
                                        to: routePaths.users,
                                    }
                                ]
                            },
                        ];
                        component = (
                            <RoutesWrapper>
                                <NavbarNurse linksData={linksData} />
                                <NurseRepository>
                                    <Routes>
                                        <Route path={routePaths.overview} element={<ContextSelectionView />} />
                                        <Route path={routePaths.home} element={<HomeView />} />
                                        <Route path={routePaths.monitoring} element={<MonitoringView />} />
                                        <Route path={routePaths.careFacility} element={<CareFacilityView />} />
                                        <Route path={routePaths.careRecipients} element={<ResidentsView />} />
                                        <Route path={routePaths.careRecipient} element={<ResidentView />} />
                                        <Route path={routePaths.careRecipientWithTab} element={<ResidentView />} />
                                        <Route path={routePaths.statistics} element={<Statistics />} />
                                        <Route path={routePaths.wards} element={<WardsView />} />
                                        <Route path={routePaths.careFacilitySettings} element={<SetupView readonly={true} />} />
                                        <Route path={routePaths.users} element={<UserView />} />
                                        <Route path={routePaths.editUser} element={<EditExternalUserView />} />
                                        <Route path={routePaths.addUser} element={<AddExternalUserView />} />
                                        <Route path={routePaths.help} element={<HelpView />} />
                                        <Route path={routePaths.activateAccount} element={<ManagePasswordWithActiveSessionView />} />
                                        <Route path={routePaths.resetPassword} element={<ManagePasswordWithActiveSessionView />} />
                                        <Route path="*" element={<Navigate to={routePaths.overview} />} />
                                    </Routes>
                                </NurseRepository>
                            </RoutesWrapper>
                        );
                        break;
                    }
                    default:
                        component = (
                            <RoutesWrapper>
                                <Routes>
                                    <Route path={routePaths.overview} element={<ContextSelectionView />} />
                                    <Route path="*" element={<Navigate to={routePaths.overview} />} />
                                </Routes>
                            </RoutesWrapper>
                        );
                        break;
                }
            } else {
                component = (
                    <Routes>
                        <Route path={routePaths.activateAccount} element={<ActivateAccountView />} />
                        <Route path={routePaths.resetPassword} element={<ResetPasswordView />} />
                        <Route path={routePaths.loginPath} element={<LoginView />} />
                        <Route path={routePaths.loginPath + "/:client"} element={<SpecificADLoginView />} />
                        <Route path={"/:client"} element={<SpecificADLoginView />} />
                        <Route path="*" element={<Navigate to={routePaths.loginPath} />} />
                    </Routes>
                );
            }
            setRoute(component);
        };
        prepareComponent();
    }, [context.id, context.role, context.isAuthenticated]);

    return <>{route}</>;
};

export default RoutesGenerator;
