import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ReactComponent as AuditIcon } from "../assets/images/auditSubmenu.svg";
import { ReactComponent as CareFacilitySettingsIcon } from "../assets/images/careFacilitySettingsSubmenu.svg";
import { ReactComponent as CareRecipientIcon } from "../assets/images/careRecipientsSubmenu.svg";
import { ReactComponent as WardsIcon } from "../assets/images/wardsSubmenu.svg";
import { StyledViewContainer } from "../components/common/View";
import { useAppSelector } from "../hooks/useAppSelector";
import { Roles } from "../models/Role";
import { IContextStateType } from "../reducers/contextSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { SubMenuContainer, SubMenuIconContainer, SubmenuTitle, Submenus } from "./CareFacilityViewStyle";

const CareFacilityView = () => {
    const context = useAppSelector<IContextStateType>(state => state.contextSlice);
    const navigate = useNavigate();

    return (
        <StyledViewContainer>
            <Submenus>
                {![Roles.GlobalAdmin, Roles.CountryAdmin].includes(context.role) && <>
                    <SubMenuContainer onClick={() => navigate(routePaths.wards)}>
                        <SubMenuIconContainer>
                            <WardsIcon />
                        </SubMenuIconContainer>
                        <SubmenuTitle>{localizer("home.wards")}</SubmenuTitle>
                    </SubMenuContainer>
                    <SubMenuContainer onClick={() => navigate(routePaths.careRecipients)}>
                        <SubMenuIconContainer>
                            <CareRecipientIcon />
                        </SubMenuIconContainer>
                        <SubmenuTitle>{localizer("mainNav.careRecipients")}</SubmenuTitle>
                    </SubMenuContainer>
                </>}
                <SubMenuContainer onClick={() => navigate(routePaths.careFacilitySettings)}>
                    <SubMenuIconContainer>
                        <CareFacilitySettingsIcon />
                    </SubMenuIconContainer>
                    <SubmenuTitle>{localizer("mainNav.careFacilitySettings")}</SubmenuTitle>
                </SubMenuContainer>
                {![Roles.Nurse, Roles.CountryAdmin, Roles.GlobalAdmin].includes(context.role) &&
                    <SubMenuContainer onClick={() => navigate(routePaths.careFaciltyAudit)}>
                        <SubMenuIconContainer>
                            <AuditIcon />
                        </SubMenuIconContainer>
                        <SubmenuTitle>{localizer("mainNav.audit")}</SubmenuTitle>
                    </SubMenuContainer>}
            </Submenus>
        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, CareFacilityView);
