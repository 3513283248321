import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { reactPlugin } from "../AppInsights";
import Colors from "../assets/Colors";
import { ReactComponent as InstructionIcon } from "../assets/images/instruction.svg";
import { ReactComponent as MapIcon } from "../assets/images/map.svg";
import { ReactComponent as ModelBoxIcon } from "../assets/images/modelBox.svg";
import { ReactComponent as PlayIcon } from "../assets/images/playIcon.svg";
import HelpBlock from "../components/common/informationBlock/HelpBlock";
import { EBlockSize, StyledTitle } from "../components/common/informationBlock/styled";
import { StyledInformationBlocks, StyledViewContainer } from "../components/common/View";
import HelpTable from "../components/themed/help/HelpTable";
import HelpVideosContainer from "../components/themed/help/HelpVideosContainer";
import { HelpSection } from "../models/HelpSection";
import { BRAND } from "../settings/Settings";
import { localizer } from "../utils/Localizer";
import { useSelector } from "react-redux";
import { SensorStripType } from "../models/EStripSizeSetting";

const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const ContactSupportContainer = styled.span`
    display: flex;
    flex-direction: row-reverse;
    color: ${Colors.black}
`;

const getTitle = (title: string, icon: JSX.Element, isSelected: boolean) => {
    return (
        <StyledTitleContainer>
            <StyledTitle isSelected={isSelected}>{title}</StyledTitle>
            <span>{icon}</span>
        </StyledTitleContainer>)
}

const getIcon = (helpSection: HelpSection) => {
    switch (helpSection) {
        case HelpSection.QuickGuide:
            return <MapIcon />;
        case HelpSection.InstructionVideos:
            return <PlayIcon />;
        case HelpSection.Explore3D:
            return <ModelBoxIcon />;
        case HelpSection.InstructionForUse:
            return <InstructionIcon />;
        default:
            return <></>;
    }
}

const getContent = (helpSection: HelpSection, stripSetting: SensorStripType) => {
    switch (helpSection) {
        case HelpSection.QuickGuide:
            return (
                <HelpTable
                    data={[{
                        name: localizer("help.quickGuide.linkText"),
                        link: stripSetting === SensorStripType.MultiSize ? localizer("help.quickGuide.link") : localizer("help.quickGuide.singleSize.link")
                    }]}
                    headerId={"help.quickGuide.title"}
                    qaId={"quickGuide_table"}

                />);
        case HelpSection.InstructionVideos:
            return <HelpVideosContainer sensorStripType={stripSetting} />;
        case HelpSection.Explore3D:
            return (
                <HelpTable
                    data={[{
                        name: localizer("help.explore3D.linkText"),
                        link: localizer("help.explore3D.link")
                    }]}
                    headerId={"help.explore3D.title"}
                    qaId={"explore3D_table"}

                />);
        case HelpSection.InstructionForUse:
            return (
                <HelpTable
                    data={[{
                        name: localizer("help.userInstructions.linkText"),
                        link: stripSetting === SensorStripType.MultiSize ? localizer("help.ifu.link") : localizer("help.ifu.singleSize.link")
                    }]}
                    headerId={"help.userInstructions.title"}
                    qaId={"userInstructions_table"}

                />);
        default:
            return <></>;
    }
}

const HelpView = () => {

    const [selectedHelpBlock, setSelectedHelpBlock] = useState<HelpSection>(HelpSection.QuickGuide);
    const selectedCareFacility = useSelector((state: any) => state.nursingHomeSlice.nursingHome);
    const stripSetting: SensorStripType = selectedCareFacility?.sensorStripType;
    const handleBlockSelection = (helpSection: HelpSection) => {
        setSelectedHelpBlock(helpSection);
    }

    return (
        <StyledViewContainer>
            <StyledInformationBlocks>
                <HelpBlock
                    helpSection={HelpSection.QuickGuide}
                    titleComponent={getTitle(localizer("help.quickGuide.title"), getIcon(HelpSection.QuickGuide), selectedHelpBlock === HelpSection.QuickGuide)}
                    infoMessage={localizer("help.quickGuide.description")}
                    size={EBlockSize.L}
                    isSelected={selectedHelpBlock === HelpSection.QuickGuide}
                    handleBlockSelection={handleBlockSelection}
                />
                <HelpBlock
                    helpSection={HelpSection.InstructionVideos}
                    titleComponent={getTitle(localizer("help.instructionVideo.title"), getIcon(HelpSection.InstructionVideos), selectedHelpBlock === HelpSection.InstructionVideos)}
                    infoMessage={localizer("help.instructionVideo.description")}
                    size={EBlockSize.L}
                    isSelected={selectedHelpBlock === HelpSection.InstructionVideos}
                    handleBlockSelection={handleBlockSelection}
                />
                <HelpBlock
                    helpSection={HelpSection.Explore3D}
                    titleComponent={getTitle(localizer("help.explore3D.title"), getIcon(HelpSection.Explore3D), selectedHelpBlock === HelpSection.Explore3D)}
                    infoMessage={localizer("help.explore3D.description")}
                    size={EBlockSize.L}
                    isSelected={selectedHelpBlock === HelpSection.Explore3D}
                    handleBlockSelection={handleBlockSelection}
                />
                <HelpBlock
                    helpSection={HelpSection.InstructionForUse}
                    titleComponent={getTitle(localizer("help.userInstructions.title"), getIcon(HelpSection.InstructionForUse), selectedHelpBlock === HelpSection.InstructionForUse)}
                    infoMessage={localizer("help.userInstructions.description")}
                    size={EBlockSize.L}
                    isSelected={selectedHelpBlock === HelpSection.InstructionForUse}
                    handleBlockSelection={handleBlockSelection}
                />
            </StyledInformationBlocks>
            {getContent(selectedHelpBlock, stripSetting)}
            <ContactSupportContainer>
                <FormattedMessage
                    id="help.description.two"
                    values={{ brand: BRAND }}
                />
            </ContactSupportContainer>
        </StyledViewContainer>
    );
};

export default withAITracking(reactPlugin, HelpView);