import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';
import { ReactComponent as CopyClipboard } from "../../../../assets/images/copyClipboard.svg";
import styled from "styled-components";
import { tooltipId } from "../../../common/tooltip/Tooltip";
import { localizer } from "../../../../utils/Localizer";

const StyledIcon = styled(CopyClipboard)`
    margin-left: 4px;
    width: 18px;
    height: 18px;
    outline: none;
    transition: transform 150ms ease;
    :hover {
        cursor: pointer;
    }
    :active {
        transform: scale(0.8);
    }
`;

const StyledIconContainer = styled.div`
    flex-grow: 1;
    display: flex;
`;

const ExternalIdTextOverflowWrapper = styled(TextOverflowWrapper)`
    max-width: 315px;
    flex-grow: 1;
`

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
    };

    return (
        <StyledLeft>
            <ExternalIdTextOverflowWrapper
                tooltip={value}
                hideTooltipOnProd={hideTooltipOnProd}
                uppercase
            >
                {value ? value : "--"}
            </ExternalIdTextOverflowWrapper>
            {value && (
                <StyledIconContainer>
                    <StyledIcon
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={localizer("constants.copyClipboard")}
                        onClick={copyToClipboard}
                    />
                </StyledIconContainer>
            )}
        </StyledLeft>
    );
};

const ExternalIdFormatter = (data: any) => <CellFormatter data={data} />;
export default ExternalIdFormatter;
