import ResidentHistory from "./ResidentHistory";
import {ResidentEventType} from "./ResidentEventType";
import Pager from "./Pager";
import { Moment } from "moment";

export default class ResidentHistoryPager extends Pager<ResidentHistory> {
    eventType: string;
    residentId: string;
    startDate: Moment; 
    endDate: Moment;
    startTime: Moment | null = null;
    endTime: Moment | null = null;
    averageResponseTime: number | null = null;
    changesPerResident: number | null = null;

    constructor(eventType: string, residentId: string, timeFrameDays: number, startDate: Moment, endDate: Moment, startTime: Moment | null, endTime: Moment | null) {
        super(timeFrameDays);
        this.startDate = startDate;
        this.endDate = endDate;
        this.eventType = eventType;
        this.residentId = residentId;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    isSameHistory(residentId: string, eventType: ResidentEventType, timeFrameDays: number, startDate: Moment, endDate: Moment, startTime: Moment | null, endTime: Moment | null): boolean {
        return residentId === this.residentId && eventType === this.eventType && timeFrameDays === this.timeFrameDays 
        && this.startDate === startDate && this.endDate === endDate && this.startTime === startTime && this.endTime === endTime;
    }
}