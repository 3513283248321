import { ResidentEventType } from "../models/ResidentEventType";
import { Roles } from "../models/Role";

export const TwoFAValidationCodeLength = 6;

export const noBatteryIconList = [ResidentEventType.Inactive, ResidentEventType.NotBeingMonitored, ResidentEventType.Connecting];

export const rolesWithResidentAddDeleteRights = [Roles.Support, Roles.NHManager, Roles.Nurse];

export const BRAND = "SmartCare™";

export const exclusiveMultiStripVideos = [5]
export const multStripVideosList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const exclusiveSingleStripVideos = [3]
export const localesWithoutExclusiveVideos = ["en-US", "fr-CA"]
export const singleStripVideosList = [1 ,14, 11, 2, 6, 4, 5, 3, 13, 12, 8, 9, 10, 7];

export const countriesWithTermsOfUse = ["US", "CA"];

export const mobileThresholdWidth = 768;

export const customerAdWhiteList = [
    "home",
    "monitoring",
    "statistics",
    "settings",
    "help",
    "careFacility",
];