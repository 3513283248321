import { createSlice } from '@reduxjs/toolkit';
import { RestClient } from '../data/RestClient';
import { Resident } from '../models/Resident';
import { updateState } from './residentHelper';

export interface IResidentStateType {
    residents: Resident[];
    isFetching: boolean;
}

const initialState: IResidentStateType = {
    residents: [],
    isFetching: false
};

export const residentSlice = createSlice({
    name: 'resident',
    initialState,
    reducers: {

        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },

        setResidents: (state, action) => {
            state.residents = action.payload;
        },

        updateResidentState: (state, action) => {
            const updatePayload = action.payload;

            const index = state.residents.findIndex(resident => resident.id === updatePayload.id?.toString());
            if (index > -1) {
                const updatedResident = updateState(state.residents[index], updatePayload);
                state.residents[index] = updatedResident;
            }
        },

        addUpdateResidentInStore: (state, action) => {
            const updatedResident = action.payload;

            const index = state.residents.findIndex(resident => resident.id === updatedResident.id?.toString());

            if (index > -1) {
                state.residents[index] = new Resident(updatedResident);
            } else {
                state.residents.push(new Resident(updatedResident));
            }
        },

        clearResidentSlice: () => initialState,
    },
});

export const {
    setIsFetching, setResidents, addUpdateResidentInStore, updateResidentState, clearResidentSlice
} = residentSlice.actions;

export const deleteResidentInStore = (id: string) => (dispatch: any, getState: any): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            const state = getState().residentSlice as IResidentStateType;

            const updatedResidents = state.residents.filter(resident => resident.id !== id);
            dispatch(setResidents(updatedResidents));
            resolve();
        } catch (exception) {
            reject();
        }
    });
};

export const fetchResidents = () => async (dispatch: any) => {
    dispatch(setIsFetching(true))
    const response = await RestClient.getResidentsForNursingHome();
    const residentsArr: Resident[] = [];
    response.forEach((resident: any) => {
        residentsArr.push(new Resident(resident));
    });

    dispatch(setResidents(residentsArr));
    dispatch(setIsFetching(false))
};


export default residentSlice.reducer;

