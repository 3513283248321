import React, { useCallback, useEffect, useState } from "react";
import Select, { components } from "react-select";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { localizer } from "../../../utils/Localizer";
import { StyledTextOverflowWrapper } from "../../wrappers/TextOverflowWrapper";
import CheckBox from "./CheckBox";
import { StyledDropdownItem } from "./DropdownButtonNew";

const StyledSelect = styled(Select)`
    .react-select__menu {
        max-height: ${(props) => props.maxMenuHeight}px;
        overflow: auto;
    }
`;

const StyledMultiDropdownItem = styled(StyledDropdownItem)`
    cursor: pointer !important;

    &.active {
        background: ${Colors.blue.lighter} !important;
        color: ${Colors.black}
    }

    label {
        display: block;
    }
    .form-check {
        margin-bottom: 0px;
    }
`;

const StyledNoOptionsLabel = styled.label`
    color: ${Colors.grey.medium};
    padding: 0.25rem 16px;
    margin-bottom: 0;
`;

const StyledPlaceholder = styled.span`
    color: ${Colors.grey.medium} !important;
`;

const StyledMenuContainer = styled.div<any>`
    height: 100%;
    max-height: ${(props) => props.maxMenuHeight}px;
`;

const StyledSelectionContainer = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export interface IOption {
    value: any;
    label: string;
    disabled?: boolean;
    extraMessage?: string;
    isActive?: boolean;
    showActiveMessage?: boolean;
}

export interface IOptionExtended extends IOption {
    isChecked: boolean;
}

interface ITranslations {
    allOptionsSelected: string;
    multipleOptionsSelected: string;
    noOptions?: string;
}

interface IMultiValueContainerProps {
    name: string;
    options: IOption[];
    selectedValue: IOption[] | undefined;
    handleChange: (name: string, value: any) => void;
    translations: ITranslations;
    maxHeight: number;
    portalingRequired?: boolean;
    qaId?: string;
    showActiveMessage?: boolean;
}

const ValueContainer = ({ children, getValue, ...props }: any): JSX.Element => {
    const valueLength = getValue().length;
    const optionsLength = props.selectProps.options.length;
    const { allOptionsSelected, multipleOptionsSelected, placeholder } = props.selectProps;
    let value;
    if (valueLength === 0) {
        value = <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
    } else {
        if (valueLength === optionsLength) {
            value = allOptionsSelected;
        } else if (valueLength === 1) {
            value = <StyledSelectionContainer>{getValue()[0].label}</StyledSelectionContainer>;
        } else {
            value = multipleOptionsSelected;
        }
    }
    return (
        <components.ValueContainer {...props}>
            {value}
            {children.length > 1 && React.cloneElement(children[1])}
        </components.ValueContainer>
    );
};

const MenuList = (props: any) => {
    const [options, setOptions] = useState(props.options);

    const selected = props.getValue();
    const allAreChecked = selected.length === options.filter((option: IOption) => !option.disabled).length;

    const isChecked = useCallback((option: IOption) => {
        return selected.some((selectedOpt: IOption) => selectedOpt.value === option.value);
    }, [selected]);

    useEffect(() => {
        const newOptions = options.map((option: IOption) =>
            ({ ...option, isChecked: isChecked(option) }));

        setOptions(newOptions);
    }, [props.children]);

    useEffect(() => {
        const extendedOptions = props.options.map((option: IOption) =>
            ({ ...option, isChecked: isChecked(option) }));

        const [unchecked, checked] = extendedOptions.reduce((acc: any, element: any) => {
            acc[Number(element.isChecked)].push(element);
            return acc;
        }, [[], []]);

        const sorting = (a: IOptionExtended, b: IOptionExtended) => {
            if (a.label.toLocaleLowerCase().trim() > b.label.toLocaleLowerCase().trim()) {
                return 1;
            }
            if (a.label.toLocaleLowerCase().trim() < b.label.toLocaleLowerCase().trim()) {
                return -1;
            }
            return 0;
        };

        setOptions([...checked.sort(sorting), ...unchecked.sort(sorting)]);
    }, []);

    return (
        <StyledMenuContainer maxMenuHeight={props.maxHeight} >
            {options.length === 0 && (<StyledNoOptionsLabel>{props.selectProps.noOptions}</StyledNoOptionsLabel>)}

            {options.length === 1 && (
                options.map((option: IOptionExtended) =>
                    <Option
                        key={option.value}
                        {...props}
                        onCheck={(opt: IOption) => props.setValue([...selected, opt], "set-value")}
                        onUncheck={(opt: IOption) => props.setValue(selected?.filter((selectd: IOption) => opt.value !== selectd.value), "set-value")}
                        isChecked={option.isChecked}
                        option={option}
                        disabled={option.disabled}
                        extraMessage={option.extraMessage}
                        isActive={option.isActive}
                        showActiveMessage={option.showActiveMessage}
                    />
                )
            )}

            {options.length > 1 && (
                <>
                    <Option
                        key={0}
                        {...props}
                        onCheck={() => props.setValue([...props.options.filter((option: IOption) => !option.disabled)], "set-value")}
                        onUncheck={() => props.setValue([], "set-value")}
                        isChecked={allAreChecked}
                        option={{ label: localizer('statistics.chart.allWards'), value: null }}
                    />
                    {options.map((option: IOptionExtended) =>
                        <Option
                            key={option.value}
                            {...props}
                            onCheck={(opt: IOption) => props.setValue([...selected, opt], "set-value")}
                            onUncheck={(opt: IOption) => props.setValue(selected?.filter((selectd: IOption) => opt.value !== selectd.value), "set-value")}
                            isChecked={option.isChecked}
                            option={option}
                            disabled={option.disabled}
                            extraMessage={option.extraMessage}
                            isActive={option.isActive}
                            showActiveMessage={option.showActiveMessage}
                        />
                    )}
                </>
            )}
        </StyledMenuContainer>
    );
};

const Option = (props: any) => {
    return (
        <StyledMultiDropdownItem
            disabled={props.disabled}
            active={props.isChecked}
            onClick={() => props.isChecked ? props.onUncheck(props.option) : props.onCheck(props.option)}
        >
            <StyledTextOverflowWrapper>
                <CheckBox
                    key={props.option.value + "-" + props.isChecked}
                    label={props.option.label}
                    value={props.isChecked}
                    fieldName={""}
                    onChange={() => null}
                    size='M'
                    disabled={props.disabled}
                    extraMessage={props.extraMessage}
                    showActiveMessage={props.showActiveMessage}
                    isActive={props.isActive}
                />
            </StyledTextOverflowWrapper>
        </StyledMultiDropdownItem>
    );
};

const MultiSelectionFieldNew = (props: IMultiValueContainerProps) => {

    const handleChange = (currentOptions: any) => {
        Array.isArray(currentOptions)
            ? props.handleChange(props.name, currentOptions.map(x => x.value as string))
            : props.handleChange(props.name, []);
    };

    const portaling = props.portalingRequired ? {
        menuPortalTarget: document.body,
    } : {};

    return (
        <StyledSelect
            closeMenuOnSelect={false}
            onChange={handleChange}
            components={{
                ValueContainer,
                MenuList,
                IndicatorSeparator: null
            }}
            isMulti
            value={props.selectedValue}
            name={props.name}
            options={props.options}
            className="basic-multi-select dropdown"
            classNamePrefix="react-select"
            placeholder={localizer("dropDownFieldFormGroup.placeholder")}
            noOptionsMessage={() => localizer("dropDown.noOptions")}
            isClearable={false}
            isSearchable={false}
            menuShouldScrollIntoView={false}
            maxMenuHeight={props.maxHeight}
            styles={{
                menuPortal: (base: any) => ({
                    ...base,
                    zIndex: 1300,
                }),
                valueContainer: (base: any) => ({
                    ...base,
                    display: "contents",
                }),
                control: (base: any) => ({
                    ...base,
                    boxShadow: "none"
                })
            }}
            allOptionsSelected={props.translations.allOptionsSelected}
            multipleOptionsSelected={props.translations.multipleOptionsSelected}
            noOptions={props.translations.noOptions}
            {...portaling}
        />
    );
};

export default MultiSelectionFieldNew;
