import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { appInsights, reactPlugin } from "../AppInsights";
import { PageHeader, StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import MultiSelectionFieldNew from "../components/common/controls/MultiSelectionFieldNew";
import ResidentEditForm from "../components/themed/resident/ResidentEditForm";
import ResidentsTable from "../components/themed/resident/ResidentsTable";
import { LocalStorageKeys } from "../data/Persistence";
import { useAppSelector } from "../hooks/useAppSelector";
import { Ward } from "../models/Ward";
import { selectActiveWards } from "../selectors/selectors";
import { rolesWithResidentAddDeleteRights } from "../settings/Settings";
import "../styles/main.scss";
import { ETrackEvent } from "../utils/AppInsightsHelpers";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import InformationBlock from "../views/InformationBlock/InformationBlock";
import { StyledDropdownsContainer } from "./ResidentsViewStyle";
import {ExternalIntegrationInformationBlock} from '../components/common/informationBlock/ExternalIntegrationInformationBlock'
import {CareFacility, IntegrationType} from '../models/CareFacility'

const ResidentsView = () => {

    const maxDropdownMenuHeight = 500;
    const wardInfoBlockWidth = 300;
    const wards = useAppSelector<Ward[]>(selectActiveWards);
    const careFacility = useAppSelector<CareFacility|null>(state => state.nursingHomeSlice.nursingHome);
    const [selectedWards, setSelectedWards] = useState<Ward[]>([]);
    const [addMode, setAddMode] = useState<boolean>(false);


    const {
        externalIntegrationType = null
    } = careFacility || {}
    const readOnly = externalIntegrationType !== null && externalIntegrationType !== IntegrationType.Amba

    const limitedAccess = useAppSelector<boolean>(state => !rolesWithResidentAddDeleteRights.includes(state.contextSlice.role));

    const handleWardSelection = (_: string, selectedWardsId: any) => {
        const selectedWards = selectedWardsId.map((id: string) => wards.find(ward => ward.id === id));
        reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify(selectedWardsId));
        setSelectedWards(selectedWards);
        appInsights.trackEvent({ name: ETrackEvent.ResidentsWardFiltering });
    };

    const getDefaultWard = useCallback((): Ward[] | [] => {
        if (wards.length > 0) {
            const cachedSelectedResidentsWardId = reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId) ? JSON.parse(reactLocalStorage.get(LocalStorageKeys.SelectedResidentsWardId)) : [];
            if (typeof cachedSelectedResidentsWardId === "object") {
                if (cachedSelectedResidentsWardId.length === 0) {
                    return wards;
                } else {
                    return cachedSelectedResidentsWardId.reduce((selectedResidentWards: Ward[], id: string) => {
                        const ward = wards.find(ward => ward.id === id);
                        if (ward) {
                            selectedResidentWards.push(ward);
                        }
                        return selectedResidentWards;
                    }, []);
                }
            } else {
                reactLocalStorage.set(LocalStorageKeys.SelectedResidentsWardId, JSON.stringify([wards[0]?.id || ""]));
                return [wards[0]] || [];
            }
        }
        return [];
    }, [wards]);

    useEffect(() => {
        setSelectedWards(getDefaultWard());
    }, [getDefaultWard, wards]);

    const headerItem = (
        <StyledDropdownsContainer>
            <InformationBlock
                title="statistics.changeInformationBlock.ward"
                width={wardInfoBlockWidth}
            >
                <MultiSelectionFieldNew
                    name={""}
                    handleChange={handleWardSelection}
                    options={wards.map(ward => ({
                        label: ward.name,
                        value: ward.id
                    }))}
                    selectedValue={selectedWards.map(ward => ({
                        label: ward.name,
                        value: ward.id
                    }))}
                    translations={{
                        allOptionsSelected: localizer("statistics.chart.allWards"),
                        multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                        noOptions: localizer("statistics.chart.noWards")
                    }}
                    maxHeight={maxDropdownMenuHeight}
                />
            </InformationBlock>
        </StyledDropdownsContainer>
    );

    return (
        <StyledViewContainer>
            {addMode ?
                <ResidentEditForm onDiscard={() => setAddMode(false)} selectedWard={selectedWards.length === 1 ? selectedWards[0] : undefined} />
                :
                <>
                    {readOnly && (
                        <ExternalIntegrationInformationBlock sectionTranslationKey='externalIntegration.careRecipient.add.informationMessage' />
                    )}

                    <PageHeader>{localizer("mainNav.careRecipients")}</PageHeader>
                    <ResidentsTable
                        dropdownHeaderItem={headerItem}
                        selectedWards={selectedWards}
                        limitedAccess={limitedAccess}
                        setAddMode={setAddMode}
                    />
                    <BackHardButton backRoute={routePaths.careFacility} />
                </>
            }


        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, ResidentsView);
