import { PopupRequest } from "@azure/msal-browser";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { adMsalInstance } from "../data/Auth";
import { LocalStorageKeys } from "../data/Persistence";
import { RestClient } from "../data/RestClient";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { LoginContexts } from "../models/LoginContext";
import { spinnerKeys, startSpinner, stopSpinner } from "../reducers/spinnerSlice";
import { withRouter } from "../routes/routesHelper";
import { customerAdWhiteList } from "../settings/Settings";
import { routePaths } from "../utils/PathHelpers";
import { REACT_APP_CUSTOMERAD_CLIENT_ID } from "../utils/ProcessEnvHelpers";

const SpecificADLoginView = (props: any) => {
    const { client } = props.params;
    const [domainHint, setDomainHint] = useState<string>("");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await RestClient.getCustomerAd(client);
                if (response && response.domainHint) {
                    setDomainHint(response.domainHint);
                }
                dispatch(startSpinner(spinnerKeys.global))
            } catch {
                navigate(routePaths.loginPath);
            }
        }

        if (!customerAdWhiteList.includes(client)) {
            fetchData();
        } else { 
            navigate(routePaths.loginPath);
        }
    }, [client, dispatch, navigate]);

    useEffect(() => {
        if (!domainHint) {
            return;
        }
        const popRequest: PopupRequest = {
            domainHint: domainHint,
            scopes: ["openid", "offline_access", REACT_APP_CUSTOMERAD_CLIENT_ID!],
            prompt: "login",
        }
        const login = async () => {
            const response = await adMsalInstance.loginPopup(popRequest);
            const { accessToken } = response;
            sessionStorage.setItem(LocalStorageKeys.AccessToken, accessToken);
            sessionStorage.setItem(LocalStorageKeys.LoginContext, LoginContexts.CustomerAd);
            sessionStorage.setItem(LocalStorageKeys.DomainHint, domainHint);
            dispatch(stopSpinner());
            navigate(routePaths.loginPath);
        }
        login();
    }, [client, dispatch, navigate, domainHint]);

    return (
        <></>
    )
}

export default withAITracking(reactPlugin, withRouter(SpecificADLoginView));