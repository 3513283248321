import styled from "styled-components";
import BasicModal from "../../../common/modal/BasicModal"
import { EModalSize } from "../../../common/modal/ModalWrapper"
import { localizer } from "../../../../utils/Localizer";
import { StyledButton } from "../../../common/View";

const SettingTitle = styled.div`
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

interface IStripSizeSettingsInformationModalProps {
    showInfoPopup: boolean;
    setShowInfoPopup: (show: boolean) => void;
}

const StripSizeSettingsInformationModal = (props: IStripSizeSettingsInformationModalProps) => {
    const { showInfoPopup, setShowInfoPopup } = props
    const content = (
        <>
            <br />
            <SettingTitle>{localizer("stripSettings.infoPopup.one.title")}:</SettingTitle>
            <div>{localizer("stripSettings.infoPopup.one.description")}</div>
            <br />
            <SettingTitle>{localizer("stripSettings.infoPopup.multi.title")}:</SettingTitle>
            <div>{localizer("stripSettings.infoPopup.multi.description")}</div>
            <br />
        </>
    )

    return <BasicModal
        width={EModalSize.S}
        handleOnHide={() => setShowInfoPopup(false)}
        show={showInfoPopup}
        title={localizer("stripSettings.infoPopup.title")}>
        {content}
        <ButtonContainer>
            <StyledButton
                variant={"primary"}
                type={"submit"}
                onClick={() => setShowInfoPopup(false)}>
                OK
            </StyledButton>
        </ButtonContainer>
    </BasicModal>
}

export default StripSizeSettingsInformationModal;
