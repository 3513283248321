import { IUserResourcesHierarchy } from "../reducers/contextSlice";
import { ResourceTypes } from "../reducers/userSlice";

export const flatten = (children: IUserResourcesHierarchy[]): IUserResourcesHierarchy[] => {
    return children.flatMap((ur: IUserResourcesHierarchy) => {
        if (ur.type === ResourceTypes.NursingHome) {
            return ur;
        } else {
            return flatten(ur.children);
        }
    });
};