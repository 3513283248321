import React from "react";
import batteryFull from "../../../../assets/images/battery-full.svg";
import batteryEmpty from "../../../../assets/images/battery-empty.svg";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";

class BatteryIcon extends React.Component<any> {
    render(): React.ReactElement {
        const { isBatteryFlat } = this.props;
        const image = isBatteryFlat ? batteryEmpty : batteryFull;

        return (
            <StyledLeft>{image && <img src={image} alt={image} />}</StyledLeft>
        );
    }
}

export default BatteryIcon;
