import styled from "styled-components";
import { mobileThresholdWidth } from "../../../settings/Settings";
import Colors from "../../../assets/Colors";
import { Button } from "react-bootstrap";

export const TitleContainer = styled.div`
    margin-top: 100px;
    @media (max-width: ${mobileThresholdWidth}px) {
        margin-top: 10px;
        margin-bottom: 18px;
    }
    font-family: Asap;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: center;
    color: ${Colors.blue.brandingDark}
`;

export const InfoContainer = styled.div`
    font-family: Asap;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: justified;
    color: ${Colors.black};
    gap: 30px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    max-width: 354px;
`;

export const StyledSubmitButton = styled(Button)`
    width: 100%;
    margin-top: 40px;
`;

export const ResponsiveViewContainer = styled.form`
    align-items: center;
    max-width: 354px; 
    margin: 0 auto;
    @media (max-width: ${mobileThresholdWidth}px) {
        max-width: 335px;
    }
`;

export const SuccessfulPasswordContainer = styled.div`
    align-items: center;
    max-width: 400px;
    margin: 0 auto;
    @media (max-width: ${mobileThresholdWidth}px) {
       display: none;
    }
`;

export const SuccessfulPasswordMobileContainer = styled.div`
    @media (min-width: ${mobileThresholdWidth}px) {
        display: none;
    }
    align-items: center;
`;

export const ResponsiveContainer = styled.div`
    @media (max-width: ${mobileThresholdWidth}px) {
        flex-direction: column-reverse;
        display: flex;
    }
`;

export const RedirectButton = styled(Button)`
    width: 186px;
    height: 50px;
`;

export const RedirectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 36px;
`;

export const MobileDescriptionContainer = styled.div`
    margin-top: 40px;
`;