import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { reactPlugin } from "../AppInsights";
import Colors from "../assets/Colors";
import TextField from "../components/common/controls/TextFieldNew";
import PasswordSetupForm from "../components/themed/login/PasswordSetupForm";
import { InfoContainer, ResponsiveContainer, ResponsiveViewContainer, TitleContainer } from "../components/themed/login/PasswordSetupFormStyle";
import ValidatedFormGroupWrapper from "../components/wrappers/ValidatedFormGroupWrapper";
import { RestClient } from "../data/RestClient";
import { EApp } from "../models/EApp";
import ErrorCodes from "../models/ErrorCodes";
import { withRouter } from "../routes/routesHelper";
import { mobileThresholdWidth } from "../settings/Settings";
import { localizer } from "../utils/Localizer";
import { emailRegex, passwordRegex } from "../utils/Regex";
import { toastError } from "../utils/Toaster";
import ExpiredLinkBlock, {ExpiredLinkContext} from "../components/themed/login/ExpiredLinkBlock";

enum Fields {
    Email = "Email"
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
`;

const StyledButton = styled(Button)`
    width: 160px;
    @media (max-width: ${mobileThresholdWidth}px) {
       width: 100%;
        &:nth-child(odd) {
            display: none;
        }
    }
`;

const ToLoginPageButton = styled(Button)`
    width: 100%;
    @media (max-width: 768px) {
        display: none;
    }
`;

const InfoBlock = styled.div <any>`
    font-size: 12px;
    color: ${props => props.hasError ? Colors.red.maryRed : Colors.grey.medium};
`;

const ResetPasswordView = () => {

    const [email, setEmail] = useState<string>("");
    const [resetRequested, setResetRequested] = useState<boolean>(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const [hasError, setHasError] = useState<boolean>(false);
    const [products, setProducts] = useState<EApp[]>([]);
    const [passwordSet, setPasswordSet] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [resetPasswordLinkValid, setResetPasswordLinkValid] = useState<boolean>(false);
    const [resetPasswordLinkVerificationCompleted, setResetPasswordLinkVerificationCompleted] = useState<boolean>(false);

    const user = queryParams.get("user");
    const token = queryParams.get("token");
    const externalId = queryParams.get("externalId");
    const product = queryParams.get("product");

    useEffect(() => {
        const verifyLinkValidity = async () => {
            const response = await RestClient.verifyResetPasswordLink(user, token, externalId, product);
            setResetPasswordLinkVerificationCompleted(true);
            setResetPasswordLinkValid(response.isLinkValid);
        };

        (user && token && externalId) && verifyLinkValidity();
    }, [user, token, externalId, product]);

    useEffect(() => {
        if (email === "") {
            setHasError(false);
            setError("");
        }
    }, [email]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const trimmedEmail = email.trim();
        if (!emailRegex.test(trimmedEmail)) {
            setHasError(true);
            setError(ErrorCodes.EmailUnmeetCriteria)
        } else {
            try {
                await RestClient.resetPassword({ username: trimmedEmail })
                setEmail("");
                setResetRequested(true)
            } catch (error: any) {
                if (error.status === 404) {
                    setEmail("");
                    setResetRequested(true)
                } else {
                    toastError(localizer("constants.somethingWentWrong"))
                }
            }
        }
    };

    const handlePasswordReset = async (event: any, password: string, confirmPassword: string) => {
        event.preventDefault();
        if (!passwordRegex.test(password)) {
            setHasError(true);
            setError(ErrorCodes.PasswordUnmeetCriteria)
        } else if (password !== confirmPassword) {
            setHasError(true);
            setError(ErrorCodes.PasswordNotMatching)
        } else {
            try {
                const response = await RestClient.setPassword({
                    username: user,
                    password,
                    passwordResetCode: token,
                    externalId,
                    product
                });
                setPasswordSet(true);
                setProducts([...response.products]);
            } catch (error: any) {
                if (error.status === 400) {
                    setHasError(true);
                    setError(ErrorCodes.PasswordTokenExpired);
                } else {
                    toastError(error);
                }
            }
        }
    }

    let content;
    if (!!user && !!token && !!externalId) {
        if (!resetPasswordLinkVerificationCompleted) {
            content = <></>
        } else if (!resetPasswordLinkValid) {
            content = <ExpiredLinkBlock context={ExpiredLinkContext.RESET_PASSWORD} />
        } else {
            content = (
                <PasswordSetupForm
                    title={localizer("account.resetPassword.setPassword.title", { username: user ?? "" })}
                    mobilePasswordSetTitle={localizer("account.passwordSet")}
                    user={user}
                    token={token}
                    passwordSet={passwordSet}
                    products={products}
                    handleSubmit={handlePasswordReset}
                    error={error}
                    hasError={hasError}
                    setHasError={setHasError}
                    setError={setError}
                    submitButtonText={localizer("account.resetPassword.submit")}
                    dependentErrorCodes={[ErrorCodes.PasswordUnmeetCriteria, ErrorCodes.PasswordNotMatching, ErrorCodes.PasswordTokenExpired]}
                />)
        }
    } else if (resetRequested) {
        content = (
            <ResponsiveViewContainer>
                <TitleContainer>
                    {localizer("account.resetPassword.requested")}
                </TitleContainer>
                <InfoContainer>
                    {localizer("account.resetPassword.requested.description")}
                </InfoContainer>
                <ToLoginPageButton
                    variant="outline-secondary"
                    onClick={() => navigate(-1)}
                >
                    {localizer("account.toLogin")}
                </ToLoginPageButton>
            </ResponsiveViewContainer>
        )
    } else if (!user || !token) {
        content = (
            <ResponsiveViewContainer onSubmit={handleSubmit}>
                <TitleContainer>
                    {localizer("account.resetPassword.title")}
                </TitleContainer>
                <ResponsiveContainer >
                    <div>
                        <InfoContainer>
                            {localizer("account.resetPassword.description")}
                        </InfoContainer>
                        <ValidatedFormGroupWrapper
                            validationErrors={hasError ? [error] : []}
                            dependentErrorCodes={[ErrorCodes.EmailUnmeetCriteria]}
                            label={"loginView.email"}
                            hideMessage
                        >
                            <TextField
                                fieldName={Fields.Email}
                                value={email}
                                onChange={(_: any, value: any) => setEmail(value)}
                            />
                        </ValidatedFormGroupWrapper>
                        <InfoBlock hasError={hasError}>
                            {localizer("account.resetPassword.info")}
                        </InfoBlock>
                        <ButtonContainer>
                            <StyledButton
                                variant="outline-secondary"
                                onClick={() => navigate(-1)}
                            >
                                {localizer("addEditButton.cancel")}
                            </StyledButton>
                            <StyledButton
                                disabled={!email}
                                type="submit"
                            >
                                {localizer("constants.send")}
                            </StyledButton>
                        </ButtonContainer>
                    </div>
                </ResponsiveContainer>
            </ResponsiveViewContainer>
        );
    }
    return content;
}

export default withAITracking(reactPlugin, withRouter(ResetPasswordView));
