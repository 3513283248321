import styled from "styled-components";
import Colors from "../../../assets/Colors";
import {localizer} from "../../../utils/Localizer";

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    padding: 210px 0 110px;
`;

const InfoHeader = styled.div`
    color: ${Colors.blue.brandingDark};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
`;

const InfoContent = styled.div`
    color: ${Colors.black};
    font-size: 16px;
    line-height: 24px;
    margin-top: 18px;
    width: 355px;
    text-align: center;
`;

export enum ExpiredLinkContext {
    SET_PASSWORD,
    RESET_PASSWORD
}

export interface ExpiredLinkBlockProps {
    context: ExpiredLinkContext
}

const ExpiredLinkBlock = (props: ExpiredLinkBlockProps) => {
    return (
        <InfoContainer>
            <InfoHeader>
                {localizer("account.expiredLink.title")}
            </InfoHeader>
            <InfoContent>
                {props.context === ExpiredLinkContext.SET_PASSWORD && localizer("account.expiredLink.activateAccountInfo")}
                {props.context === ExpiredLinkContext.RESET_PASSWORD && localizer("account.expiredLink.resetPasswordInfo")}
            </InfoContent>
        </InfoContainer>
    );
};

export default ExpiredLinkBlock;
