import { localizer } from "../../../../../utils/Localizer";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers"
import TextOverflowWrapper from "../../../../wrappers/TextOverflowWrapper";

const CellFormatter = (props: any) => {
    const { value } = props.data;
    let status= "";
    switch(true) {
        // The check for 0 represents the handling of signalR when accesing a nursing home. Backend sends it as that, thus the special handling
        case ((value >= 200 && value < 300) || value === 0):
            status = localizer("audits.table.status.success");
            break;
        case value === 401:
            status = localizer("audits.table.status.failedLogin");
            break;
        case value === 403:
            status = localizer("audits.table.status.permissionDenied");
            break;
        case (value >= 400 && value < 600):
            status = localizer("audits.table.status.failure");
            break;
        default:
            break;
    }

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={status}>
                {status}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const LoginStatusCodeFormatter = (data: any) => <CellFormatter data={data} />;
export default LoginStatusCodeFormatter;