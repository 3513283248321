export default class Pager<T> {
    rowsPerPage = 30;
    isLoading = false;
    nextStatusChangedDateTime= "";
    logSize = -1;
    timeFrameDays = -1;
    pages: T[][] = [];

    constructor(timeFrameDays: number | null = null) {
        if (timeFrameDays) {
            this.timeFrameDays = timeFrameDays;
        }
    }

    getCurrentPageIndex(): number {
        return this.pages.length;
    }

    getLogs(): T[] {
        return this.pages.reduce((acc, val) => acc.concat(val), []);
    }

    hasMorePages(): boolean {
        if (this.logSize >= 0) {
            const totalPages = Math.ceil(this.logSize / this.rowsPerPage);
            return totalPages > this.getCurrentPageIndex();
        }
        return true;
    }
}