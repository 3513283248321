import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from "../components/common/View";
import CountriesTable from "../components/themed/country/CountriesTable";
import CountryInformationBlock from "../components/themed/country/CountryInformationBlock";
import { useAppSelector } from "../hooks/useAppSelector";
import { Country } from "../models/Country";

const CountriesView = () => {
    const countries = useAppSelector<Country[]>(state => state.countrySlice.countries);

    return (
        <StyledViewContainer>
            <StyledInformationBlocks
                alignLeft
            >
                <StyledInformationBlockWrapper data-qa="countries_card">
                    <CountryInformationBlock />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >

            <CountriesTable
                data={countries}
            />
        </StyledViewContainer>
    );
};

export default withAITracking(reactPlugin, CountriesView);
