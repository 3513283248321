import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { appInsights } from '../AppInsights';
import { RestClient } from '../data/RestClient';
import CareGroup from '../models/CareGroup';
import ToastMessages from "../models/ToastMessages";
import User from '../models/ExternalUser';
import { parseErrorToString } from '../utils/AppInsightsHelpers';
import { localizer } from "../utils/Localizer";
import { toastError, toastSuccess } from "../utils/Toaster";
import { setUserResourcesHierarchy } from './contextSlice';

export interface ICareGroupStateType {
    careGroups: CareGroup[];
}

const initialState: ICareGroupStateType = {
    careGroups: [],
};

export const careGroupSlice = createSlice({
    name: 'careGroup',
    initialState,
    reducers: {
        setCareGroups: (state, action) => {
            state.careGroups = action.payload;
        },

        clearCareGroupSlice: () => initialState,
    },
});

export const {
    setCareGroups, 
    clearCareGroupSlice
} = careGroupSlice.actions;

export const addUpdateCareGroupsInStore = (requestBodies: CareGroup[]) => (dispatch: any, getState: any) => {
    const requestBodyCareGroups = requestBodies.map(requestBody => new CareGroup(requestBody));

    const state = getState().careGroupSlice as ICareGroupStateType;

    let updatedCareGroups: CareGroup[] = state.careGroups;
    requestBodyCareGroups.forEach(requestBodyCareGroup => {
        if (state.careGroups && state.careGroups.find(careGroup => requestBodyCareGroup.id?.toString() === careGroup.id)) {
            updatedCareGroups = updatedCareGroups.map((careGroup) => {
                return requestBodyCareGroup.id === careGroup.id ? requestBodyCareGroup : careGroup;
            });
        } else {
            updatedCareGroups.push(requestBodyCareGroup);
        }
    });

    dispatch(setCareGroups(updatedCareGroups));
};

export const updateCareGroup = (requestBody: CareGroup) => async (dispatch: any, getState: any) => {
    try {
        const newCareGroup = await RestClient.updateCareGroup(requestBody);
        const updatedCareGroup = new CareGroup(newCareGroup);

        const state = getState().careGroupSlice as ICareGroupStateType;
        const updatedCareGroups = state.careGroups!.map((careGroup) => {
            return careGroup.id === updatedCareGroup.id ? updatedCareGroup : careGroup;
        }) || [];

        dispatch(setCareGroups(updatedCareGroups));
    } catch (error) {
        console.error(error);        
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const deleteCareGroup = (id: string) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteCareGroup(id);

            const state = getState().careGroupSlice as ICareGroupStateType;
            const updatedCareGroups = state.careGroups.filter(careGroup => careGroup.id !== id);
           
            toastSuccess(localizer(ToastMessages.DeleteCareGroupSuccessful));
            dispatch(setCareGroups(updatedCareGroups));
            resolve(true);
        } catch (error: any) {
            if (error.status === 409) {
                toastError(
                    localizer(
                        ToastMessages.DeleteCareGroupRestrictionError
                    )
                );
                resolve(false);
                return;
            }
            toastError(localizer(ToastMessages.DeleteCareGroupError));
            reject(error);
        } finally {
            const { userResources } = await RestClient.getResources(true);
            dispatch(setUserResourcesHierarchy(userResources));
        }
    });
};

export const createCareGroup = (requestBody: any) => (dispatch: any, getState: any): Promise<CareGroup> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await RestClient.createCareGroup(requestBody);
            const newCareGroup = new CareGroup(response);
            const state = getState().careGroupSlice as ICareGroupStateType;

            const updatedCareGroups = [...state.careGroups, newCareGroup];

            dispatch(setCareGroups(updatedCareGroups));
            resolve(newCareGroup);
        } catch (error: any) {
            reject(error);
        } finally {
            const { userResources } = await RestClient.getResources(true);
            dispatch(setUserResourcesHierarchy(userResources));
        }
    });
};

export const fetchCareGroups = () => async (dispatch: any) => {
    try {
        const careGroupsResponse = await RestClient.getAllCareGroups();
        const careGroups = careGroupsResponse.map((requestBody: any) => new CareGroup(requestBody));
        dispatch(setCareGroups(careGroups));
    } catch (error) {
        console.error(error);
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const removeUserFromCareGroup = (careGroupId: string, userId: string, getState: any) => (dispatch: any) => {
    const state = getState().careGroupSlice as ICareGroupStateType;
    if (state.careGroups) {
        const careGroups = [...state.careGroups];
        const careGroup: CareGroup | undefined = careGroups.find(
            (careGroup) => careGroup.id === careGroupId
        );
        if (careGroup) {
            const updatedUserList = careGroup.assignedUsersIds.filter(assignedUserId => assignedUserId !== parseInt(userId, 10));
            const updatedCareGroup = {
                ...careGroup,
                assignedUsersIds: updatedUserList,
                amountOfAssignedUsers: updatedUserList?.length,
            };
            const updatedNursingHomes = [...careGroups.filter(nhg => nhg.id !== careGroupId), updatedCareGroup];
            dispatch(setCareGroups(updatedNursingHomes));
        }
    }
};

export const addUserToCareGroup = (careGroupId: string, users: User[], getState: any) => (dispatch: any) => {
    const state = getState().careGroupSlice as ICareGroupStateType;
    if (state.careGroups) {
        const careGroups = [...state.careGroups];
        const careGroup: CareGroup | undefined = careGroups.find(
            (careGroup) => careGroup.id === careGroupId
        );
        if (careGroup) {
            const updatedAssignedUsersId = _.union(careGroup.assignedUsersIds, users.map(u => parseInt(u.id)));
            const updatedCareGroup = {
                ...careGroup,
                assignedUsersIds: updatedAssignedUsersId,
                amountOfAssignedUsers: updatedAssignedUsersId.length
            };
            if(updatedCareGroup.amountOfAssignedUsers !== careGroup.amountOfAssignedUsers && updatedCareGroup.assignedUsersIds !== careGroup.assignedUsersIds) {
                const updatedCareGroupss = [...careGroups.filter(nhg => nhg.id !== careGroupId), updatedCareGroup];
                dispatch(setCareGroups(updatedCareGroupss));
            }
        }
    }
};

export default careGroupSlice.reducer;

