import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logoutIcon from "../assets/images/logout-icon.svg";
import switchIcon from "../assets/images/switch.svg";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { AppContext, IAppState } from "../data/AppRepository";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { reactLocalStorage } from "reactjs-localstorage";
import { EApp } from "../models/EApp";
import { IContextStateType, setContext, setFormDirty, setSelectedResourceName } from "../reducers/contextSlice";
import { withRouter } from "../routes/routesHelper";
import { isDevEnv, isLocalEnv } from "../utils/EnvCheck";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { REACT_APP_IDENTIFI_URL } from "../utils/ProcessEnvHelpers";
import GenericConfirmationForm from "./GenericConfirmationForm";
import LanguageSelector from "./LanguageSelector";
import LogoAndTitle from "./LogoAndTitle";
import LowerBarButton from "./LowerBarButton";
import { ActionContainer, BlueDivider, LowerActionContainer, SelectedResourceContainer, StyledATag, StyledExternalLinkIcon, StyledLowerBar, StyledLowerBarBackground, StyledTitleBar, StyledUpperBarButtons, UpperActionContainer } from "./TitleBarStyle";
import { LocalStorageKeys } from "../data/Persistence";
import { setNursingHome } from "../reducers/nursingHomeSlice";
import { Roles } from "../models/Role";

enum EConfirmationModalContext {
    LOGOUT,
    SWITCH_HOME,
    GOTO_TI
}

const TitleBar = () => {
    const dispatch = useAppDispatch();
    const appContext = useContext(AppContext);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [confirmationModalContext, setConfirmationModalContext] = useState(EConfirmationModalContext.LOGOUT);
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { assignedProducts, dirtyFormContext, isAuthenticated, selectedResourceName, id, initialRole, allNHResources } = context;
    const navigate = useNavigate();
    const location = useLocation();

    const getUpperBarButtons = (appContext: IAppState) => (
        <StyledUpperBarButtons>
            {isAuthenticated && ((location.pathname !== routePaths.overview && (!id || id === "")) || id && (allNHResources.length > 1 || initialRole === Roles.CountryAdmin)) && (<LowerBarButton
                qaId="mainNav_switch_button"
                text="mainNav.switch"
                iconSource={switchIcon}
                onClick={() => {
                    if (dirtyFormContext.isDirty) {
                        setConfirmationModal(true);
                        setConfirmationModalContext(EConfirmationModalContext.SWITCH_HOME);
                    } else {
                        reactLocalStorage.clear();
                        reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
                            role: initialRole
                        });
                        dispatch(setContext({ id: undefined, role: initialRole }));
                        dispatch(setNursingHome(undefined));
                        dispatch(setSelectedResourceName(undefined));
                        navigate(routePaths.overview);
                    }
                }}
            />)}
            <LanguageSelector />

            {isAuthenticated && (<LowerBarButton
                qaId="mainNav_logout_button"
                text="mainNav.logout"
                iconSource={logoutIcon}
                onClick={() => logout(appContext)}
            />)}
        </StyledUpperBarButtons>
    );

    const logout = (appContext: IAppState) => {
        if (dirtyFormContext.isDirty) {
            setConfirmationModal(true);
            setConfirmationModalContext(EConfirmationModalContext.LOGOUT);
        } else {
            appContext.logout();
        }
    };

    return (
        <>
            <StyledTitleBar>
                <StyledLowerBarBackground>
                    <StyledLowerBar>
                        <LogoAndTitle />
                        <SelectedResourceContainer>
                            {selectedResourceName}
                        </SelectedResourceContainer>
                        <ActionContainer>
                            <UpperActionContainer>
                                {getUpperBarButtons(appContext)}
                            </UpperActionContainer>
                            <LowerActionContainer>
                                {isAuthenticated && assignedProducts && assignedProducts.some(p => p === EApp.Identifi) && (
                                    <StyledATag
                                        rel="noreferrer"
                                        target="_self"
                                        href={REACT_APP_IDENTIFI_URL}
                                        onClick={(event) => {
                                            if (dirtyFormContext.isDirty) {
                                                event.preventDefault();
                                                setConfirmationModal(true);
                                                setConfirmationModalContext(EConfirmationModalContext.GOTO_TI);
                                            }
                                        }}
                                    >
                                        {localizer("mainNav.goToIdentifi")}
                                        <StyledExternalLinkIcon />
                                    </StyledATag>)
                                }
                            </LowerActionContainer>
                        </ActionContainer>
                    </StyledLowerBar>
                </StyledLowerBarBackground>
                <BlueDivider>
                    {(isDevEnv() || isLocalEnv()) && <div id="portal-connected-indicator" />}
                </BlueDivider>
            </StyledTitleBar>
            {confirmationModal && (
                <ModalWrapper
                    closeCallback={() => {
                        setConfirmationModal(false);
                    }}
                    isOpen={confirmationModal}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={dirtyFormContext.popupMessageId}
                            confirmButton={dirtyFormContext.popupConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmationModal(false);
                            }}
                            onConfirm={() => {
                                if (confirmationModalContext === EConfirmationModalContext.LOGOUT) {
                                    appContext.logout();
                                } if (confirmationModalContext === EConfirmationModalContext.GOTO_TI) {
                                    window.location.href = REACT_APP_IDENTIFI_URL ?? "";
                                } else {
                                    reactLocalStorage.clear();
                                    reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
                                        role: initialRole
                                    });
                                    dispatch(setContext({ id: undefined, role: initialRole }));
                                    dispatch(setNursingHome(undefined));
                                    dispatch(setSelectedResourceName(undefined));
                                    navigate(routePaths.overview);
                                }
                                setConfirmationModal(false);
                                if (dirtyFormContext.isDirty) {
                                    dispatch(setFormDirty(false));
                                }
                            }}
                        />
                    }
                    modalTitle={localizer(dirtyFormContext.popupTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </>
    );
};

export default withRouter(TitleBar);
