import { Roles } from "./Role";

export default class InternalUser {
    id: string;
    email: string;
    role: Roles;
    assignedResourceIds: number[];
    mobileNumber: string;
    language?: string;

    constructor(json: any = {}) {
        this.id = json.externalId ? String(json.externalId) : "";
        this.role = json.role ? json.role : "";
        this.email = json.email ? json.email : "";
        this.mobileNumber = json.mobileNumber ? json.mobileNumber : "";
        this.language = json.languageCode ? String(json.languageCode).toLowerCase() : "";
        if (json.countries && Array.isArray(json.countries)) {
            const arr = json.countries as any[];
            this.assignedResourceIds = arr.map(t => Number(t));
        } else {
            this.assignedResourceIds = [];
        }
    }
}