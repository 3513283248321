import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { RestClient } from '../data/RestClient';
import { SocketStatus } from '../data/SocketClient';
import { CareFacility } from '../models/CareFacility';
import User from '../models/ExternalUser';
import ToastMessages from "../models/ToastMessages";
import { localizer } from "../utils/Localizer";
import { toastError, toastSuccess } from "../utils/Toaster";
import { fetchResources, setAllNhResources, setUserResourcesHierarchy } from './contextSlice';
import { sortNursingHomes } from './nursingHomeHelpers';
import { flatten } from "../utils/SliceHelpers";

export interface INursingHomeStateType {
    nursingHome: CareFacility | null;
    nursingHomes: CareFacility[];
    socketStatus: SocketStatus;
}

const initialState: INursingHomeStateType = {
    nursingHome: null,
    nursingHomes: [],
    socketStatus: SocketStatus.INACTIVE,
};

export const nursingHomeSlice = createSlice({
    name: 'nursingHome',
    initialState,
    reducers: {
        setNursingHomes: (state, action) => {
            state.nursingHomes = sortNursingHomes(action.payload);
        },

        setNursingHome: (state, action) => {
            state.nursingHome = action.payload;
        },

        setSocketStatus: (state, action) => {
            state.socketStatus = action.payload;
        },

        clearNursingHomeSlice: () => initialState,
    },
});

export const {
    setNursingHomes, setNursingHome, setSocketStatus, clearNursingHomeSlice
} = nursingHomeSlice.actions;

export const updateNursingHome = (requestBody: any, isAdmin: boolean) => (dispatch: any): Promise<void> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.updateNursingHome(requestBody);

            toastSuccess(localizer(ToastMessages.UpdateCareFacilitySuccessful));

            if (isAdmin) {
                dispatch(fetchNursingHomes()); // admin only
            } else {
                dispatch(fetchNursingHome());
                dispatch(fetchNursingHomes());
            }
            dispatch(fetchResources());
            resolve();
        } catch (error: any) {
            if (error?.status === 409) {
                throw error;
            } else {
                const includedErrorPart = "shifts must be in correct chronological order".toUpperCase();
                error.response?.text?.toUpperCase().includes(includedErrorPart)
                    ? toastError(localizer(ToastMessages.NursingHomeTimeShiftError))
                    : toastError(localizer(ToastMessages.UpdateCareFacilityError));
                reject(error);
            }
        }
    });
};

export const createCareFacility = (requestBody: any, successCallback?: () => void) => async (dispatch: any, getState: any): Promise<any> => {
    try {
        const response = await RestClient.createCareFacility(requestBody);
        const newNursingHome = new CareFacility(response);
        const state = getState().nursingHomeSlice;

        const updatedNursingHomes = [...state.nursingHomes, newNursingHome];

        toastSuccess(localizer(ToastMessages.CreateCareFacilitySuccessful));

        dispatch(setNursingHomes(updatedNursingHomes));

        if (successCallback) {
            successCallback();
        }
    } catch (error: any) {
        if (error?.status === 409) {
            throw error
        } else {
            const includedErrorPart = "shifts must be in correct chronological order".toUpperCase();
            error.response?.text?.toUpperCase().includes(includedErrorPart)
                ? toastError(localizer(ToastMessages.NursingHomeTimeShiftError))
                : toastError(localizer(ToastMessages.CreateCareFacilityError));
        }
    } finally {
        const { userResources } = await RestClient.getResources(true);
        dispatch(setUserResourcesHierarchy(userResources));
        const flattenedUserResources = flatten(userResources);
        dispatch(setAllNhResources(flattenedUserResources));
    }
};

export const deleteNursingHome = (id: string) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteNursingHome(id);
            const state = getState().nursingHomeSlice as INursingHomeStateType;
            const updatedNursingHomes = state.nursingHomes.filter(
                (nursingHome) => nursingHome.id !== id
            );

            toastSuccess(localizer(ToastMessages.DeleteCareFacilitySuccessful));

            dispatch(setNursingHomes(updatedNursingHomes));
            resolve(true);
        } catch (error: any) {
            error.status === 409
                ? toastError(
                    localizer(
                        ToastMessages.DeleteCareFacilityExistingWardsError
                    )
                )
                : toastError(localizer(ToastMessages.DeleteCareFacilityError));
            reject(error);
        } finally {
            const { userResources } = await RestClient.getResources(true);
            dispatch(setUserResourcesHierarchy(userResources));    
            const flattenedUserResources = flatten(userResources);
            dispatch(setAllNhResources(flattenedUserResources));
        }
    });
};

export const fetchNursingHomes = () => async (dispatch: any) => {
    const response = await RestClient.getNursingHomes();
    const nursingHomesArr: CareFacility[] = [];
    response.forEach((nursingHome: any) => {
        nursingHomesArr.push(new CareFacility(nursingHome));
    });

    dispatch(setNursingHomes(nursingHomesArr));
};

export const fetchNursingHome = (nursingHomeId?: string | undefined) => async (dispatch: any) => {
    const response = await RestClient.getSelectedNursingHome(nursingHomeId);
    dispatch(setNursingHome(new CareFacility(response)));
};

export const removeUserFromNursingHome = (nursingHomeId: string, userId: string, getState: any) => (dispatch: any) => {
    const state = getState().nursingHomeSlice as INursingHomeStateType;
    if (state.nursingHomes) {
        const nursingHomes = [...state.nursingHomes];
        const nursingHome: CareFacility | undefined = nursingHomes.find(
            (nursingHome) => nursingHome.id === nursingHomeId
        );
        if (nursingHome) {
            const updatedUserList = nursingHome.assignedUsersIds.filter(assignedUserId => assignedUserId !== parseInt(userId, 10));
            const updatedNursingHome = {
                ...nursingHome,
                assignedUsersIds: updatedUserList,
                amountOfAssignedUsers: updatedUserList?.length,
            };
            const updatedNursingHomes = [...nursingHomes.filter(nh => nh.id !== nursingHomeId), updatedNursingHome];
            dispatch(setNursingHomes(updatedNursingHomes));
        }
    }
};

export const addUserToNursingHome = (nursingHomeId: string, users: User[], getState: any) => (dispatch: any) => {
    const state = getState().nursingHomeSlice as INursingHomeStateType;
    if (state.nursingHomes) {
        const nursingHomes = [...state.nursingHomes];
        const nursingHome: CareFacility | undefined = nursingHomes.find(
            (nursingHome) => nursingHome.id === nursingHomeId
        );
        if (nursingHome) {
            const updatedAssignedUsersId = _.union(nursingHome.assignedUsersIds, users.map(u => parseInt(u.id)));
            const updatedNursingHome = {
                ...nursingHome,
                assignedUsersIds: updatedAssignedUsersId,
                amountOfAssignedUsers: updatedAssignedUsersId.length
            };
            if (updatedNursingHome.amountOfAssignedUsers !== nursingHome.amountOfAssignedUsers && updatedNursingHome.assignedUsersIds !== nursingHome.assignedUsersIds) {
                const updatedNursingHomes = [...nursingHomes.filter(nh => nh.id !== nursingHomeId), updatedNursingHome];
                dispatch(setNursingHomes(updatedNursingHomes));
            }
        }
    }
};

export default nursingHomeSlice.reducer;

