import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import TakeMeUp from "../components/common/controls/TakeMeUpButton";
import { useContextRedirect } from "../hooks/useContextRedirect";

interface IRoutesWrapperProps {
    children: React.ReactElement | React.ReactElement[] | null;
}

const RoutesWrapper = ({ children }: IRoutesWrapperProps) => {
    const location = useLocation();

    useContextRedirect();

    useEffect(() => {
        const previousPath = localStorage.getItem("lastPath");
        const currentPath = location.pathname;
        let basePath = location.pathname;
        const isTabPage = currentPath.includes("&");
        if (isTabPage) {
            const currentTab = currentPath.substring(currentPath.indexOf("&"), currentPath.length);
            basePath = location.pathname.replace(currentTab, "");
        }

        if (currentPath === basePath) {
            localStorage.setItem("lastPath", currentPath);
            localStorage.setItem("previousPath", previousPath ? previousPath : "");
        }
    }, [location]);

    return (
        <>
            {children}
            <TakeMeUp />
        </>
    );
};

export default RoutesWrapper;
