import { FormattedMessage } from 'react-intl';
import { CareFacility } from "../../../models/CareFacility";
import { localizer } from "../../../utils/Localizer";
import styled from 'styled-components';
import { ReactComponent as DayIcon } from "../../../assets/images/day.svg";
import { ReactComponent as NightIcon } from "../../../assets/images/night.svg";
import { Switcher } from "../resident/CellFormatters/Switcher";
import { Col } from "react-bootstrap";
import ErrorCodes from "../../../models/ErrorCodes";
import Colors from "../../../assets/Colors";
import { CareFacilityFields } from "../nursingHomes/CareFacilitiesHelper";

interface IStyledNotificationContainerProps {
    horizontal: boolean;
}

const StyledNotificationContainer = styled(({ horizontal, ...props }) => (<div {...props} />)) <IStyledNotificationContainerProps>`
    width: 100%;
    flex-direction: ${props => props.horizontal ? "row" : "column"};

    .react-select__control {
        height: 42px;
        padding-bottom: 32px;
    }
`;

const StyledTitleWithIcon = styled.div<any>`
    display: flex;
    padding-top: ${props => `${props.paddingTop}px` || 0};
    color: ${Colors.blue.brandingDark};
    font-size: 20px;
    font-weight: 600;

    svg {
        margin-right: 4px;
    }
`;

const StyledSwitcherBlock = styled.div`
    margin-bottom: 24px;
`;

const StyledLabel = styled.label`
    margin-bottom: .5rem;
`;

interface INotificationsProps {
    nursingHome: CareFacility;
    handleChange: (fieldName: string, result: string) => void;
    validationErrors: string[];
    readonly?: boolean;
    horizontal?: boolean;
}

const pluralOrSingular = (value: number, plural: string, singular: string) => value > 1 ? localizer(plural) : localizer(singular);

const getMinutesLabel = (value: number) => pluralOrSingular(value, 'constants.minutes', 'constants.minute');

const valueToLabelMapper = (value: number, formatter: (v: number) => string) => ({
    name: value !== 0 ? `${value} ${formatter(value)}` : localizer('monitoring.off'),
    value
});

export const getCaregiverResponseTargetTimes = () => CareFacility.caregiverResponseTargetTimes.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getSupervisorResponseTargetTimes = () => CareFacility.supervisorResponseTargetTimes.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getSupervisorNotificationIntervals = () => CareFacility.supervisorNotificationIntervals.map(val => valueToLabelMapper(val, getMinutesLabel));

export const getCaregiverNotificationIntervals = () => CareFacility.caregiverNotificationIntervals.map(val => valueToLabelMapper(val, getMinutesLabel));

const Notifications = (props: INotificationsProps) => {
    const getError = (error: ErrorCodes): string | undefined => {
        const validationError = props.validationErrors.find(validationError => validationError.startsWith(error));
        if (validationError !== undefined) {
            return validationError;
        }
        return;
    };

    return (
        <StyledNotificationContainer horizontal={props.horizontal}>
            <div>
                <StyledTitleWithIcon>
                    <NightIcon height={24} width={24} fill={Colors.blue.brandingDark}/>
                    <FormattedMessage id="setup.notification.nightNotificationsTitle" />
                </StyledTitleWithIcon>

                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverResponseTarget" /></StyledLabel>
                    <Switcher
                        id={"night-caregiverResponseTarget"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverResponseTargetTimes()}
                        radioValue={getCaregiverResponseTargetTimes().find(option => String(option.value) === String(props.nursingHome.caregiverResponseTargetMinutes))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(CareFacilityFields.CaregiverResponseTarget, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverResponseTargetMinutes)}
                    />
                </StyledSwitcherBlock>

                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverNotificationInterval" /></StyledLabel>
                    <Switcher
                        id={"night-caregiverNotificationInterval"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverNotificationIntervals()}
                        radioValue={getCaregiverNotificationIntervals().find(option => String(option.value) === String(props.nursingHome.caregiverNotificationIntervalMinutes))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(CareFacilityFields.CaregiverNotificationInterval, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverNotificationIntervalMinutes)}
                    />
                </StyledSwitcherBlock>
            </div>

            <div>
                <StyledTitleWithIcon paddingTop={props.horizontal ? 0 : 50}>
                    <DayIcon height={24} width={24} fill={Colors.blue.brandingDark}/>
                    <FormattedMessage id="setup.notification.dayNotificationsTitle" />
                </StyledTitleWithIcon>
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverResponseTarget" /></StyledLabel>
                    <Switcher
                        id={"day-caregiverResponseTarget"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverResponseTargetTimes()}
                        radioValue={getCaregiverResponseTargetTimes().find(option => String(option.value) === String(props.nursingHome.caregiverResponseTargetMinutesDay))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(CareFacilityFields.CaregiverTargetResponseTimeDay, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverResponseTargetMinutesDay)}
                    />
                </StyledSwitcherBlock>

                {/* Caregiver notification interval */}
                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.caregiverNotificationInterval" /></StyledLabel>
                    <Switcher
                        id={"day-caregiverNotificationInterval"}
                        disabled={props.readonly}
                        fluid
                        radios={getCaregiverNotificationIntervals()}
                        radioValue={getCaregiverNotificationIntervals().find(option => String(option.value) === String(props.nursingHome.caregiverNotificationIntervalMinutesDay))?.value}
                        setMonitoringStatus={(value: any) => props.handleChange(CareFacilityFields.CaregiverNotificationIntervalDay, value)}
                        errorMessage={getError(ErrorCodes.EmptyCaregiverNotificationIntervalMinutesDay)}
                    />
                </StyledSwitcherBlock>

                <StyledSwitcherBlock>
                    <StyledLabel><FormattedMessage id="setup.notification.eightHourNotification" /></StyledLabel>
                    <Col sm={6} md={6}>
                        <Switcher
                            id={"eightHourNotification"}
                            disabled={props.readonly}
                            fluid
                            radios={[
                                {
                                    name: localizer('monitoring.off'),
                                    value: 0
                                },
                                {
                                    name: localizer('monitoring.on'),
                                    value: 1
                                }]}
                            radioValue={Number(props.nursingHome.notifyOfSomeUrineStatusOverdue)}
                            setMonitoringStatus={(value: any) => props.handleChange(CareFacilityFields.EightHourNotification, value)}
                        />
                    </Col>
                </StyledSwitcherBlock>
            </div>
        </StyledNotificationContainer>
    );
};

export default Notifications;
