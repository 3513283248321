import styled from "styled-components";
import { convertMinutesToHours, convertTimeToHtml } from "../../../../utils/ConvertMinutesToHours";
import { getTrendIcon } from "../../../../utils/StatisticsHelpers";
import { StyledLeft } from '../../../common/table/formatters/PositionContainers';

const CustomStyledLeft = styled(StyledLeft)`
    gap: 5px;
`;

const TrendTimeFormatter = (props: any) => {
    const { value } = props;
    let sign = "";
    if (value > 0) {
        sign = "+";
    } else if (value < 0) {
        sign = "-";
    }
    return (
        <CustomStyledLeft>
            <span>
                {getTrendIcon(value === 0 ? null : value)}
            </span>
            <span>
                ({sign}
                {value && value !== 0 ?
                    convertTimeToHtml(
                        convertMinutesToHours(Math.abs(value)),
                        true,
                        false
                    ) : "--"
                }
                )
            </span>
        </CustomStyledLeft>
    );
};

export default TrendTimeFormatter;