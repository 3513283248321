import styled from "styled-components";
import { StyledViewContainer } from "../../common/View";
import Colors from "../../../assets/Colors";
import { Button } from "react-bootstrap";

export enum CareFacilityFields {
    Name = "Name",
    Country = "Country",
    CareGroup = "CareGroup",
    CustomerId = "CustomerId",
    MorningShiftStartTime = "MorningShiftStartTime",
    EveningShiftStartTime = "EveningShiftStartTime",
    ApplyByTime = "ApplyByTime",
    CaregiverNotificationIntervalDay = "CaregiverNotificationIntervalDay",
    CaregiverTargetResponseTimeDay = "CaregiverTargetResponseTimeDay",
    CaregiverNotificationInterval = "CaregiverNotificationInterval",
    CaregiverResponseTarget = "CaregiverResponseTarget",
    IsWeakAuthenticationAllowed = "IsWeakAuthenticationAllowed",
    Is2FAActive = "is2FAActive",
    IsActive = "IsActive",    
    Is2FAActiveMobile = "Is2FAActiveMobile",
    EnforceStrongPasswordForCaregiver = "EnforceStrongPasswordForCaregiver",
    EnforceEmailForCaregiver = "EnforceEmailForCaregiver",
    MorningShiftNotificationTime = "MorningShiftNotificationTime",
    NightShiftNotificationTime = "NightShiftNotificationTime",
    ShiftNotificationTimeDisabled = "ShiftNotificationTimeDisabled",
    EightHourNotification = "EightHourNotification",
    ExternalIntegration = "ExternalIntegration",
    StripSizeSetting = "StripSizeSetting",
}

export const StyledNursingHomeSettingsContainer = styled(StyledViewContainer)`
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-flow: row wrap;
    padding: 0 0 160px 0;
    max-width: 100%;
    gap: 40px;
`;

export const StyledNursingHomeSettingsColumn = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
`;

export const StyledHeader = styled.div`
    color: ${Colors.blue.brandingDark};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
`;

export const StyledButton = styled(Button)`
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    width: 160px;
    height: 50px;
    font-size: $tena-font-size-1;
    font-family: "Asap", sans-serif;
`;

export const StyledButtonsContainer = styled.div`
    margin-top: 2rem;
    float: right;
`;

export const StyledBlock = styled.div`
    padding-top: 50px;
    width: 100%;
`;

export const StyledButtonBlock = styled(StyledBlock)`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 40px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    justify-content: end;
`;