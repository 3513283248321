import { Configuration, PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import { LoginContexts } from "../models/LoginContext";
import { fetchConfiguration } from '../reducers/configurationSlice';
import { cleanupRedux } from '../reducers/dataHelper';
import store from '../store';
import {
    REACT_APP_AAD_AUTHORITY_URL,
    REACT_APP_AAD_CLIENT_ID,
    REACT_APP_AAD_CUSTOMERAD_REDIRECT_URL,
    REACT_APP_CUSTOMERAD_AUTHORITY,
    REACT_APP_CUSTOMERAD_AUTHORITY_URL,
    REACT_APP_CUSTOMERAD_CLIENT_ID
} from "../utils/ProcessEnvHelpers";
import { toastLogoutInfo } from '../utils/Toaster';
import { LocalStorageKeys, cleanupLocalStorage } from "./Persistence";
import { AuthRestClient } from './RestClient';


export const msalAuthParams = {
    scopes: ["openid", "profile", "User.Read"],
    prompt: 'login',
};

export const silentLoginRequest = {
    account: {},
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: false,
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
    forceRefresh: false,
};

export interface ITwoFactorAuthenticationResponse {
    is2FactorAuthenticationEnabled: boolean;
    mobileNumber: string;
}

export const msalConfig: Configuration = {
    auth: {
        authority: REACT_APP_AAD_AUTHORITY_URL!,
        clientId: REACT_APP_AAD_CLIENT_ID!
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
};

export const adMsalConfig: Configuration = {
    auth: {
        authority: REACT_APP_CUSTOMERAD_AUTHORITY_URL!,
        clientId: REACT_APP_CUSTOMERAD_CLIENT_ID!,
        knownAuthorities: [REACT_APP_CUSTOMERAD_AUTHORITY!],
        redirectUri: REACT_APP_AAD_CUSTOMERAD_REDIRECT_URL!,
    },
}

export const adMsalInstance = new PublicClientApplication(adMsalConfig);

export const getRefreshedAccessToken = async () => {
    const signedInUser = adMsalInstance.getAllAccounts()[0];
    const scopes = ["openid", "offline_access", REACT_APP_CUSTOMERAD_CLIENT_ID!];

    const silentTokenBody = {
        scopes: scopes,
        account: {
            homeAccountId: signedInUser!.homeAccountId ?? "",
            environment: signedInUser!.environment ?? "",
            tenantId: signedInUser!.tenantId ?? "",
            username: signedInUser!.username ?? "",
            localAccountId: signedInUser!.localAccountId ?? "",
            idTokenClaims: signedInUser!.idTokenClaims ?? {},
        },
    }

    let refreshedAccessToken;

    try {
        const response = await adMsalInstance.acquireTokenSilent(silentTokenBody);
        refreshedAccessToken = response.accessToken;
    } catch {
        const domainHint = sessionStorage.getItem(LocalStorageKeys.DomainHint);
        if (domainHint) {
            const popRequest: PopupRequest = {
                domainHint: domainHint,
                scopes: scopes,
                prompt: "login",
            }
            const login = async () => {
                const response = await adMsalInstance.loginPopup(popRequest);
                const { accessToken } = response;
                sessionStorage.setItem(LocalStorageKeys.AccessToken, accessToken);
                sessionStorage.setItem(LocalStorageKeys.LoginContext, LoginContexts.CustomerAd);
                refreshedAccessToken = accessToken;
            }
            login();
        }
    }
    return refreshedAccessToken;
}

export const handleLogout = async (message?: string | null, redirectUrl?: string) => {
    cleanupLocalStorage();
    cleanupRedux();
    store.dispatch(fetchConfiguration());
    message && toastLogoutInfo(message);
    if (adMsalInstance && adMsalInstance.getAllAccounts().length > 0) {
        adMsalInstance.logoutRedirect();
    }
    sessionStorage.clear();
    await AuthRestClient.logout();
    if (redirectUrl) {
        window.location.href = redirectUrl
    }
    window.scrollTo(0, 0);
};

export const browserLoginWithAdToken = async (idToken: string): Promise<number> => {
    if (idToken) {
        const response = await AuthRestClient.loginWithAdToken(idToken);
        const sessionTimeoutMinutes = response.sessionTimeoutMinutes!;

        return Promise.resolve(sessionTimeoutMinutes);
    }
    return Promise.reject();
};

export const browserLoginWithCustomerAdAccessToken = async (accessToken: string): Promise<number> => {
    if (accessToken) {
        const response = await AuthRestClient.loginWithCustomerAdAccessToken(accessToken);
        const sessionTimeoutMinutes = response.sessionTimeoutMinutes!;

        return Promise.resolve(sessionTimeoutMinutes);
    }
    return Promise.reject();
};


