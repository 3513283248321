import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { localizer } from "../../../utils/Localizer";

const StyledIcon = styled.div`
    font-size: 12px;
    color: ${Colors.blue.brandingDark};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    text-align: center;
    white-space: nowrap;
`;

interface IIconProps {
    icon: React.ReactElement;
    textId: string;
    onClick?: () => void;
}

export const IconWithText = (props: IIconProps) => {
    const text = localizer(props.textId);
    return (
        <StyledIcon onClick={props.onClick}>
            {props.icon}
            {text}
        </StyledIcon>
    );
};
