import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../../../../../assets/images/editIcon.svg";
import { ReactComponent as EyeStrokeIcon } from "../../../../../../assets/images/eyeStroke.svg";
import { IconWithText } from "../../../../../common/table/IconWithText";
import ActionsContainer from "../../../../../common/table/formatters/ActionsContainer";
import { routePaths } from "../../../../../../utils/PathHelpers";

const UserActions = (props: any) => {
    const navigate = useNavigate();

    const getIcon = () => {
        const eyeStrokeAction = <IconWithText icon={<EyeStrokeIcon onClick={() => navigate(`${routePaths.users}/${props.row.original.id}`)} />} textId={"constants.view"} />;

        if ( props.row.original.readOnly) {
            return eyeStrokeAction;
        } else {
            return <IconWithText
            icon={
                <EditIcon onClick={() => navigate(`${routePaths.users}/${props.row.original.id}`)} />
            }
            textId={"constants.edit"} />
        }
    }
    return (
        <ActionsContainer>
            {getIcon()}
        </ActionsContainer>
    );
};

export default UserActions;
