import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { IStyleProps } from "./ITableProps";

export const StyledSortingIndicator = styled(FontAwesomeIcon)`
    margin-left: 9px;
`;

export const Styles = styled.div<IStyleProps>`
    height: ${props => props.dynamicHeight ? "auto" : "100%"};
    overflow-x: auto;

    .table {
        display: block;
        overflow: hidden;
        height: calc(100% - 20px);
        margin-bottom: 36px;
        ${props => props.minTableHeight ? `min-height: ${props.minTableHeight}px;` : ``}
        .thead {
            .tr {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                .th{
                    align-items: center !important;
                    line-height: 20px;
                }
            }
        }
        .tbody {
            overflow: auto;
            border-top: 0;
            .tr {
                word-break: break-all;
                min-height: ${props => props.rowHeight ? props.rowHeight : 72}px;
                border-left: 1px solid ${Colors.grey.tenaLight};
                border-right: 1px solid ${Colors.grey.tenaLight};

                :nth-child(odd) {
                    background: ${props => props.inverseColors ? Colors.white.clearWhite : Colors.grey.tenaLight};
                }

                :nth-child(even) {
                    background: ${props => props.inverseColors ? Colors.grey.tenaLight : Colors.white.clearWhite};
                }

                :last-child:nth-child(even) {
                    border-bottom: 1px solid ${Colors.grey.tenaLight};
                }
            }
        }

        .tr {
            padding: 0 20px;
            background: ${props => props.inverseColors ? Colors.grey.tenaLight : Colors.blue.brandingDark};
        }

        .th {
            background: ${props => props.inverseColors ? Colors.grey.tenaLight : Colors.blue.brandingDark};
            height: ${props => props.headerHeight ? props.headerHeight : 56}px;
            color: ${props => props.inverseColors ? Colors.black : Colors.white.clearWhite};
            text-align: center;
            font-weight: 600;
        }

        .td {
            margin: 0;
            padding: 0.5rem;
            color: ${Colors.black};
            width: 1%;

            &.collapse {
                width: 0.0000000001%;
            }
        }
        .filter-cell {
            width: 100%;
        }
    }
`;
