import { ReactComponent as TrendDown } from "../assets/images/trendDown.svg";
import { ReactComponent as TrendStagnant } from "../assets/images/trendStagnant.svg";
import { ReactComponent as TrendUp } from "../assets/images/trendUp.svg";
import Colors from "../assets/Colors";

export const getTrendIcon = (value: number | null) => {
    if (value === null) {
        return <></>;
    } else {
        if (value > 0) {
            return <TrendUp stroke={Colors.black} />;
        } else if (value < 0) {
            return <TrendDown stroke={Colors.black} />;
        } else {
            return <TrendStagnant stroke={Colors.black} />;
        }
    }
};

export const getTrendValue = (trendValue: number | null) => {
    if (trendValue !== null) {
        const roundedValue = Math.round(trendValue);
        if (roundedValue > 0) {
            return "+" + roundedValue.toString();
        }
        return roundedValue;
    }
    return "--";
};