export const parseErrorToString = (error: any): string => {
    if(typeof error === "object"){
        return JSON.stringify(error, Object.getOwnPropertyNames(error));
    }
    return error as string; 
};

export enum ETrackEvent {
    // General
    JumpToResidentViewFromName = "Jump to resident view from name click",
    JumpToResidentViewFromGoTo = "Jump to resident view from go to click",
    // Home
    HomeTimePeriodFiltering = "Home page - Time period filtering",
    HomeWardFiltering = "Home page - Ward filtering",
    // Monitoring
    MonitoringWardFiltering = "Monitoring page - Ward filtering",
    MonitoringSetupChange = "Monitoring page - Monitoring change",
    // Statistics
    NHStatistics = "Statistics page - Nursing home statistics filtering",
    NHStatisticsCompare = "Statistics page - Nursing home statistics compare",
    NHStatisticsExcelExport = "Statistics page - Nursing home statistics excel export",
    // Residents
    ResidentsWardFiltering = "Residents page - Ward filtering",
    // Resident
    ResidentStatisticsFilter = "Resident view - Statistics filtering",
    ResidentStatisticsCompare = "Resident view - Statistics compare",
    JumpToHistory = "Resident view - Jump from resident statistics to resident history",
    ResidentUpdated = "Resident view - Resident updated",
    ResidentAddedAddAnother = "Resident view - Added and add another",
    ResidentAdded = "Resident view - Resident added",
    ResidentDeleted = "Resident view - Resident deleted",
    ResidentLeftWithoutSaving = "Resident view - Edit/Add left without saving",
    ResidentDeletedActive = "Resident view - Deleted active resident",
    ResidentHistory = "Resident view - History filtering",
    ResidentHistoryFilteringCheckbox = "Resident view - History filtering checkbox",
    // Users
    UsersPage = "Users page - Read users",
    UsersPageAdd = "Users page - Add user",
    UsersPageDelete = "Users page - Delete user",
    UsersPageCaregiverUpdate = "Users page - Caregiver update",
    UsersPageCaregiverPasswordUpdate =  "Users page - Caregiver password update",
    UsersPageSupportUserExtend = "Users page - Support user access extend",
    // Settings
    SettingsUpdate = "Settings page - Update nursing home",
    SettingsUpdateWard = "Settings page - Update ward",
    SettingsAddWard = "Settings page - Add ward",
    SettingsDeleteWard = "Settings page - Delete ward",
    // Nursing home
    NursingHomePageAdd = "Nursing home page - Add nursing home",
    NursingHomePageDelete = "Nursing home page - Delete nursing home",
    NursingHomePageUpdate = "Nursing home page - Update nursing home",
    // Nursing home group
    NursingHomeGroupPageAdd = "Nursing home group page - Add nursing home group",
    NursingHomeGroupPageDelete = "Nursing home group page - Delete nursing home group",
    NursingHomeGroupPageUpdate = "Nursing home group page - Update nursing home group",
    // Country
    CountryPageAdd = "Country page - Add country",
    CountryPageDelete = "Country page - Delete country",
    CountryPageUpdate = "Country page - Update country",
    // Help
    HelpPageResourceAccess = "Help page - Resource access",
    HelpPageVideoAccess = "Help page - Video access",
    // Audit
    AuditPage = "Audit page",
}

export enum EFilter { 
    KpiFilter = "KPI filter",
    DateFilter = "Date filter",
    CustomStartDateFilter = "Custom start date filter",
    CustomEndDateFilter = "Custom end date filter",
    TimeFilter = "Time filter",
    CustomStartTimeFilter = "Custom start time filter",
    CustomEndTimeFilter = "Custom end time filter",
    WardFilter = "Ward filter",
    EventTypeFilter = "Event type filter",
}