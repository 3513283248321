import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Country } from "../../../models/Country";
import { StyledAmount, StyledDescription, StyledTitle } from "../../common/informationBlock/styled";
import { StyledInlineFlexStart } from "../../styled";
import CountryAddButton from "./CountryAddButton";
const CountryInformationBlock = () => {
    const countries = useAppSelector<Country[]>(state => state.countrySlice.countries);
    return (
        <>
            <StyledInlineFlexStart>
                <StyledTitle><FormattedMessage id={"countries.informationBlocks.title"} /></StyledTitle>
                <CountryAddButton />
            </StyledInlineFlexStart>
            <StyledDescription>
                <FormattedMessage id={"countries.informationBlocks.totalCountriesProvisioned"} />
            </StyledDescription>
            <StyledAmount data-qa="countries_amount">
                {countries.length}
            </StyledAmount>
        </>
    );
};

export default CountryInformationBlock;
