import styled from "styled-components";
import { getTrendIcon } from "../../../../utils/StatisticsHelpers";
import { StyledLeft } from '../../../common/table/formatters/PositionContainers';

const CustomStyledLeft = styled(StyledLeft)`
    gap: 5px;
`;

const TrendFormatter = (props: any) => {
    const { value } = props;
    let formattedValue;
    if (value > 0) {
        formattedValue = "+" + value;
    } else if (value < 0) {
        formattedValue = value;
    } else {
        formattedValue = "--";
    }

    return (
        <CustomStyledLeft>
            {getTrendIcon(value === 0 ? null : value)}({formattedValue})
        </CustomStyledLeft>
    );
};

export default TrendFormatter;