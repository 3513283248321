import styled from "styled-components";
import Colors from "../../../../assets/Colors";

export const SectionContainer = styled.form`
    margin-top: 40px;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${Colors.grey.mediumLight};
    margin-top: 5px;
    margin-bottom: 20px;
`;

export const StyledImg = styled.img`
    margin-left: 6px;
    margin-bottom: 4px;
    :hover{
        cursor: pointer;
    }
`;

export const ScrollableContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 25px;
    max-height: 226px;
    overflow-y: auto;
    border: 1px solid ${Colors.grey.mediumLight};
    padding: 12px;
    margin-bottom: 20px;
`;

export const InfoContainer = styled.span`
    font-size: 12px;
    font-style: italic;
    float:  right;
`;

export const InputContainer = styled.div<any>`
    display: flex;
    flex-direction: row;
    gap: 20px;
    ${props => props.halfRow ? `width: calc(50% - 10px);` : ''}
`;

export const SelectAllContainer = styled.div`
    margin: 10px 13px;
`;

export const TooltipContentContainer = styled.div`
    width: 420px;
`;

export const SectionInfo = styled.div`
    margin-bottom: 15px;
`;

export const ExpirationTimesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-flow: wrap;
`;