import { createSlice } from '@reduxjs/toolkit';

export const spinnerKeys = {
    global: 'global',
    audit: 'audit',
    country: 'country',
};

export interface INursingHomeStateType {
    activeSpinners: string[];
}

const initialState: INursingHomeStateType = {
    activeSpinners: []
};

export const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers: {
        startSpinner: (state, action) => {
            state.activeSpinners.push(action.payload);
        },

        stopSpinner: (state) => {
            // TODO: rewrite when there will be more then 1 spinner
            state.activeSpinners = state.activeSpinners.slice(0, -1);
        },

        clearSpinnerSlice: () => initialState,
    },
});

export const {
    startSpinner,
    stopSpinner,
    clearSpinnerSlice
} = spinnerSlice.actions;

export default spinnerSlice.reducer;

