import { Button, Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {StyledFormContainer, StyledModalFormButtons} from "../components/common/styled";

interface IGenericConfirmationFormProps {
    messageId: string;
    messageValues?: any,
    confirmButton: string;
    onConfirmCancel: (event: any) => void;
    onConfirm: (event: any) => void;
}

const GenericConfirmationForm = (props: IGenericConfirmationFormProps) => {

    return (
        <Form>
            <StyledFormContainer>
                <FormattedMessage id={props.messageId} values={props.messageValues} />
            </StyledFormContainer>
            <StyledModalFormButtons>
                <Button
                    data-qa="confirm_button"
                    variant={"primary"}
                    type="button"
                    onClick={props.onConfirm}
                >
                    <FormattedMessage id={props.confirmButton} />
                </Button>
                <Button
                    data-qa="cancel_button"
                    variant={"outline-secondary"}
                    type="button"
                    onClick={props.onConfirmCancel}
                >
                    <FormattedMessage id="addEditButton.cancel" />
                </Button>
            </StyledModalFormButtons>
        </Form>
    );
}

export default GenericConfirmationForm;
