import { StyledLeft } from '../../../common/table/formatters/PositionContainers';

const SimpleValueFormatter = (props: any) => {
    const { value } = props;
    return (
        <StyledLeft>
            {value && value !== 0 ? value : "--"}
        </StyledLeft>
    );
};

export default SimpleValueFormatter;