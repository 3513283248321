import { useEffect, useState } from 'react';
import { calculateDiffInMinutes } from "../../../../utils/GetTimeDifferenceInMinutes";
import { EMPTY_VALUE } from '../../../../utils/Localizer';
import { convertMinutesToHours, convertTimeToString } from "../../../../utils/ConvertMinutesToHours";
import { StyledLeft } from '../../../common/table/formatters/PositionContainers';

const DateDifferenceInHours = (props: any) => {
  const [timeDifference, settimeDifference] = useState(EMPTY_VALUE);

  useEffect(() => {
    countTimeDiffrence();
    const myInterval = setInterval(
      countTimeDiffrence,
      secondsToMiliseconds(3)
    );

    return () => clearInterval(myInterval);
  }, [props.value]);

  const secondsToMiliseconds = (amount: number) => amount * 1000;

  const countTimeDiffrence = () => {
    const { value } = props;
    const timeDifference = value ? calculateDiffInMinutes(value) : null;
    const timeDifferenceInHours = timeDifference ? convertTimeToString(convertMinutesToHours(timeDifference, EMPTY_VALUE, false)) : EMPTY_VALUE;

    settimeDifference(timeDifferenceInHours);
  };

  return (
    <StyledLeft>
      {timeDifference}
    </StyledLeft>
  );
};

export default DateDifferenceInHours;
