import React from "react";
import { FormattedMessage } from "react-intl";
import { utcDateToLocal, utcTimeSpanToLocal } from "../../../../utils/TimeZoneConverter";
import Table, { headerJustifyOptions } from "../../../common/table/Table";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { ITableProps } from "../../ITableProps";

const AuditDetailsTable = (props: ITableProps) => {

    const timeColumns = [
        "MorningShiftStartTime",
        "NightShiftStartTime",
        "MorningShiftNotificationTime",
        "NightShiftNotificationTime"
    ];

    const dateColumns = [
        "ExpirationDateTime",
        "TermsOfUseAcceptedDateTime"
    ];

    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="audits.details.table.entity" />,
                accessor: "entityType",
                Cell: (props: any) => {
                    return (
                        <StyledLeft>
                            <TextOverflowWrapper tooltip={props.value} >
                                {props.value}
                            </TextOverflowWrapper>
                        </StyledLeft>)
                },
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                alignItems: "left",
                cellAlignItems: "self-start"
            },
            {
                Header: <FormattedMessage id="audits.details.table.propertyName" />,
                accessor: "propertyName",
                Cell: (props: any) => {
                    return (
                        <StyledLeft>
                            <TextOverflowWrapper tooltip={props.value} >
                                {props.value}
                            </TextOverflowWrapper>
                        </StyledLeft>)
                },
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                alignItems: "left",
            },
            {
                Header: <FormattedMessage id="audits.details.table.oldValue" />,
                accessor: "oldValue",
                Cell: (props: any) => {
                    let value = "--"
                    if (props.value !== null) {
                        if (timeColumns.includes(props.row.original.propertyName)) {
                            value = utcTimeSpanToLocal(props.value.toString());
                        } else if (dateColumns.includes(props.row.original.propertyName)) {
                            value = utcDateToLocal(props.value.toString());
                        } else {
                            value = props.value.toString();
                        }
                    }

                    return (
                        <StyledLeft>
                            <TextOverflowWrapper tooltip={value} >
                                {value}
                            </TextOverflowWrapper>
                        </StyledLeft>)
                },
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                alignItems: "left",
            },
            {
                Header: <FormattedMessage id="audits.details.table.newValue" />,
                accessor: "newValue",
                Cell: (props: any) => {
                    let value = "--"
                    if (props.value !== null) {
                        if (timeColumns.includes(props.row.original.propertyName)) {
                            value = utcTimeSpanToLocal(props.value.toString());
                        } else if (dateColumns.includes(props.row.original.propertyName)) {
                            value = utcDateToLocal(props.value.toString());
                        } else {
                            value = props.value.toString();
                        }
                    }

                    return (
                        <StyledLeft>
                            <TextOverflowWrapper tooltip={value} >
                                {value}
                            </TextOverflowWrapper>
                        </StyledLeft>)
                },
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                alignItems: "left",
            },
        ],
        []
    );

    const data = React.useMemo(() => props.data, [props.data]);
    return (
        <Table
            qaId={"audit_detail_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "created",
                        desc: true
                    }
                ]
            }}
        />
    );
};

export default React.memo(AuditDetailsTable);
