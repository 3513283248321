import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { localizer } from "../../../utils/Localizer";

const StyledFontAwesome = styled(FontAwesomeIcon)`
    font-size: 20px;
    margin-bottom: 4px;
`;

const StyledIcon = styled.div<{disabled?: boolean}>`
    font-size: 12px;
    color: ${Colors.blue.brandingDark};
    opacity: ${props => props.disabled ? "0.2" : "1"};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    text-align: center;
    white-space: nowrap;
`;

interface IIconProps {
    icon: IconDefinition;
    text: string;
    disabled?: boolean;
    qaId?: string;
    onClick?: () => void;
}

export const Icon = (props: IIconProps) => {
    const text = localizer(props.text);
    return (
        <StyledIcon disabled={props.disabled === true} onClick={props.onClick}>
            <StyledFontAwesome icon={props.icon} data-qa={props.qaId}/>
            {text}
        </StyledIcon>
    );
};
