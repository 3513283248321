export default class ChangeEvent {
    responseTimeMinutes: number | null;
    productChanges: number;
    timeCreated: Date | undefined;
    nightTimeMinutesBetweenChanges: number | null;
    productChangesPercentage: number | null;
    avgProductChanges: number;

    constructor(json: any) {
        this.productChanges = json.productChangesCount  ? json.productChangesCount : 0;
        this.responseTimeMinutes = json.responseTimeMinutes;
        this.timeCreated = json.day;
        this.nightTimeMinutesBetweenChanges = json.nightTimeMinutesBetweenChanges;
        this.productChangesPercentage =  json.promptedProductChangesPercentage;
        this.avgProductChanges =  json.avgProductChanges;
    }
}