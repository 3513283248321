import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledBackButton = styled(Button)`
    width: 160px;
`;

interface IBackHardButtonProps {
    backRoute: string;
    onClick?: () => void;
}

const BackHardButton = (props: IBackHardButtonProps) => {
    const navigate = useNavigate();
    return (
        <StyledBackButton
            data-qa="back_button"
            variant={"outline-secondary"}
            type="button"
            className="discard-button"
            onClick={props.onClick ? props.onClick : () => navigate(props.backRoute)}
        >
            <FormattedMessage id="constants.back" />
        </StyledBackButton>)

};

export default BackHardButton;
