import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useState } from "react";
import { Form, FormLabel, FormText, Row } from "react-bootstrap";
import styled from "styled-components";
import { appInsights } from "../../../AppInsights";
import Colors from "../../../assets/Colors";
import { RestClient } from "../../../data/RestClient";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Country } from "../../../models/Country";
import ErrorCodes from "../../../models/ErrorCodes";
import { countryToReqBody } from "../../../models/helpers/RequestHelpers";
import Market from "../../../models/Market";
import ToastMessages from "../../../models/ToastMessages";
import { fetchCountries } from "../../../reducers/countrySlice";
import { ETrackEvent, parseErrorToString } from "../../../utils/AppInsightsHelpers";
import { getLocalizedCountries, getLocalizedCountry, localizer } from "../../../utils/Localizer";
import { toastError, toastSuccess } from "../../../utils/Toaster";
import DropdownButtonNew from "../../common/controls/DropdownButtonNew";
import ConfirmDeleteForm from "../../common/forms/ConfirmDeleteForm";
import { StyledFormGroup } from "../../common/View";
import ValidatedFormGroupWrapper from "../../wrappers/ValidatedFormGroupWrapper";
import AddEditModalButtonGroup from "../../../views/Buttons/AddEditModalButtonGroup";
import { fetchResources } from "../../../reducers/contextSlice";

const StyledFormContainer = styled.div`
    width: 100%;
`;

const StyledFormText = styled(FormText)`
    color: ${Colors.black};
`;

enum Fields {
    Country = "Country",
    Market = "Market",
    // Active = "Active",
}

const CountryEditForm = (props: any) => {
    const onDiscard = (event: any) => {
        event.preventDefault();
        props?.hideModal();
    };

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [countryEntity, setCountryEntity] = useState<Country>(props.entity || new Country());
    const [validationErrors, setErrors] = useState<string[]>([]);

    const isEdit = !!props.entity;
    const countries = useAppSelector<Country[]>(state => state.countrySlice.countries);

    const dispatch = useAppDispatch();

    const clearFormData = () => {
        setCountryEntity(new Country());
        setErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!countryEntity.marketCode) {
            errors.push(ErrorCodes.CountryEmptyMarketError);
        }

        if (!countryEntity.code) {
            errors.push(ErrorCodes.CountryEmptyCodeError);
        }

        const duplicateNameFound = countries.some(country => country.code?.toLowerCase() === countryEntity.code?.toLowerCase() && country.id !== countryEntity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.CountryDuplicateCountryError);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendCountry = async (addMore: boolean) => {
        if (isEdit) {
            try {
                const requestBody = countryToReqBody(countryEntity);
                await RestClient.updateCountry(requestBody);

                dispatch(fetchCountries());
                dispatch(fetchResources());
                toastSuccess(localizer(ToastMessages.UpdateCountrySuccessful));
                props?.hideModal();
                appInsights.trackEvent({ name: ETrackEvent.CountryPageUpdate });
            }
            catch (error: any) {
                toastError(localizer(ToastMessages.UpdateCountryError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }

        } else {
            try {
                const requestBody = countryToReqBody(countryEntity);
                await RestClient.postCountry(requestBody);
                toastSuccess(localizer(ToastMessages.CreateCountrySuccessful));
                if (addMore) {
                    clearFormData();
                } else {
                    props?.hideModal();
                }
                dispatch(fetchCountries());
                dispatch(fetchResources());
                appInsights.trackEvent({ name: ETrackEvent.CountryPageAdd });
            }
            catch (error: any) {
                toastError(localizer(ToastMessages.CreateCountryError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }
        }
    };

    const deleteCountry = async () => {
        try {
            await RestClient.deleteCountry(props.entity.id);
            props?.hideModal();
            dispatch(fetchCountries());
            dispatch(fetchResources());
            toastSuccess(localizer(ToastMessages.DeleteCountrySuccessful));
            appInsights.trackEvent({ name: ETrackEvent.CountryPageDelete });
        } catch (error: any) {
            toastError(localizer(ToastMessages.DeleteCountryErrorHasConnectedHomes));

            console.error(error);
            appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendCountry(false);
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setShowDeleteConfirmation(false);
    };

    const onDeleteConfirm = (event: any) => {
        event.preventDefault();
        deleteCountry();
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendCountry(true);
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (props.entity.id) {
            setShowDeleteConfirmation(true);
        }
    };

    const handleChange = (name: string, value: any) => {
        const updatedCountry = { ...countryEntity };

        switch (name) {
            case Fields.Country:
                updatedCountry.code = value;
                break;
            case Fields.Market:
                updatedCountry.marketCode = value;
                break;
        }

        setCountryEntity(updatedCountry);
    };

    const localizedCountries = getLocalizedCountries();
    const markets = useAppSelector<Market[]>(state => state.marketSlice.markets);
    const dropdownOptions = markets.map(market => ({
        label: localizer(`markets.${market.code}`),
        value: market.code
    })) || [];

    const deleteConfirmation = (
        <ConfirmDeleteForm
            title={props.entity?.name}
            onConfirmCancel={onConfirmCancel}
            onDeleteConfirm={onDeleteConfirm}
        />
    );

    const form = (
        <StyledFormContainer>
            <Form>
                {isEdit
                    ? <Row className="row g-0">
                        <StyledFormGroup>
                            <FormLabel>{localizer("country.editForm.country")}</FormLabel>
                            <StyledFormText>{getLocalizedCountry(countryEntity?.code)}</StyledFormText>
                        </StyledFormGroup>
                    </Row>
                    : <Row className="row g-0">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.CountryEmptyCodeError, ErrorCodes.CountryDuplicateCountryError]}
                            label={"country.editForm.country"}
                        >
                            <DropdownButtonNew
                                qaId="country_dropdown"
                                id={Fields.Country}
                                name={Fields.Country}
                                handleChange={(name, value) => handleChange(name, value)}
                                options={localizedCountries.map(localizedCountry => ({
                                    label: localizedCountry.label,
                                    value: localizedCountry.code.toUpperCase()
                                })) || []}
                                selectedValue={countryEntity.code}
                                isFilterable
                                portaling
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                }
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.CountryEmptyMarketError]}
                        label={"country.editForm.assortment"}
                    >
                        <DropdownButtonNew
                            qaId="assesment_dropdown"
                            id={Fields.Market}
                            name={Fields.Market}
                            handleChange={(name, value) => handleChange(name, value)}
                            options={dropdownOptions}
                            selectedValue={countryEntity.marketCode}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="g-0 mt-3">
                    <AddEditModalButtonGroup
                        isEdit={isEdit}
                        onSave={onSave}
                        onSaveAndAddAnother={onSaveAndAddAnother}
                        onDiscard={onDiscard}
                        extraActions={isEdit ? [{
                            label: "constants.delete",
                            action: onDelete
                        }] : []} />
                </Row>
            </Form>
        </StyledFormContainer >
    );

    return showDeleteConfirmation ? deleteConfirmation : form;
};

export default CountryEditForm;
