import styled from "styled-components";
import Colors from "../../../assets/Colors";

const CutLabel = styled.div<any>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${props => props.width ? `width: ${props.width - 30}px;` : 'width: 210px;'}
`;

const RadioLabel = styled.label<any>`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    ${props => props.width ? `width: ${props.width}px;` : ''}
`;

const RadioInput = styled.input.attrs({ type: 'radio' })<any>`
    display: none;
    ${props => props.disabled && "pointer-events: none; " }
`;

const CustomRadio = styled.span<any>`
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid ${Colors.blue.brandingDark};
    border-radius: 50%;
    margin-right: 10px;
    background-color: ${props => props.checked ? Colors.blue.brandingDark : 'transparent'};
    position: relative;
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border: 2px solid white; /* Set transparent border */
        border-radius: inherit;
        position: absolute;
    }
    ${props => props.disabled ? 
        `
        background-color: ${props.checked ? Colors.blue.darkDisabled : 'transparent'};
        border: 3px solid ${Colors.blue.darkDisabled};
        pointer-events: none;
        ` : ''}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 25px;
    margin-bottom: 20px;
`;

export interface IRadioEntry {
    value: string | number;
    label: string;
    disabled?: boolean;
}

interface IRadioButtonsProps {
    entries: IRadioEntry[];
    selectedRadio: string | number;
    setSelectedRadio: (value: any) => void;
    readOnly?: boolean;
    width?: number;
}
const RadioButtons = (props: IRadioButtonsProps) => {
    const { entries, selectedRadio, setSelectedRadio, width, readOnly } = props;
    const handleRadioChange = (event: any) => {
        setSelectedRadio(event.target.value);
    };

    return (
        <Container>
            {entries.map((entry: IRadioEntry) => {
                return (<RadioLabel key={entry.value} width={width}>
                    <RadioInput disabled={entry.disabled || readOnly} value={entry.value} checked={selectedRadio?.toString() === entry.value.toString()} onChange={handleRadioChange} />
                    <CustomRadio disabled={entry.disabled || readOnly} checked={selectedRadio?.toString() === entry.value.toString()} />
                    <CutLabel width={width} disabled={entry.disabled || readOnly}>
                        {entry.label}
                    </CutLabel>
                </RadioLabel>)
            })}
        </Container>
    )
}

export default RadioButtons;
