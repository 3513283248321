import { useContext } from "react";
import { useIdleTimer } from 'react-idle-timer';
import { useEffect } from "react";
import { AppContext } from "./AppRepository";
import { AuthRestClient } from "./RestClient";

interface IProps {
    inactivityLimitMinutes: number;
}

let cookieTime = Date.now();

const SessionHandler = (props: IProps) => {
    const minutesBeforeRefreshCookie = 5;
    const contextValue = useContext(AppContext);

    useEffect(() => {
        cookieTime = Date.now();
    }, [])

    const onIdle = () => {
        contextValue.logout('logout.inactivity');
    };

    const onAction = () => {
        const milisecondsDiff = Date.now() - cookieTime;
        if (milisecondsDiff > minutesBeforeRefreshCookie * 60 * 1000) {
            cookieTime = Date.now();
            AuthRestClient.keepSessionAlive()
        }
    };

    useIdleTimer({
        onIdle: onIdle,
        element: document,
        debounce: 250,
        timeout: props.inactivityLimitMinutes * 60 * 1000,
    })

    useIdleTimer({
        element: document,
        onAction: onAction,
        throttle: 60 * 1000
    })

    return (
        <></>
    );
}

export default SessionHandler;