import styled from "styled-components";
import Colors from "../../../../assets/Colors";
import TimePickerDropdown from "../../../common/controls/dateTime/TimePickerDropdown";
import ErrorCodes from "../../../../models/ErrorCodes";
import ValidatedFormGroupWrapper from "../../../wrappers/ValidatedFormGroupWrapper";
import DatePicker from "react-datepicker";
import moment, { Moment } from "moment";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { StyledCalendar } from "../../../common/controls/dateTime/helpers/DropdownDateTimeRangeButtonStyle";
import { Fields } from "./UserExternalEditForm";

interface ISupportExpirationDateCardProps {
    nhName: string;
    nhId: number;
    validationErrors: string[];
    onChange: (name: string, value: any, resourceId?: number) => void;
    date: Moment;
    readOnly: boolean;
}

const CardContainer = styled.div`
    width: 452px;
    height: 132px;
    background-color: ${Colors.grey.tenaLight};
    padding: 20px;
`;

const NHTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${Colors.black};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledDatePicker = styled(DatePicker)`
    height: 42px !important;
    :focus{
        border: 1px solid #00488F !important;
        padding-bottom: 6px !important;
    }
    transform: translate(0%,-55%);
    padding: 6px 30px 6px 45px;
`;

const DateInputContainer = styled.span`
    display: inline-block;
`;

const StyledCalendarIcon = styled(StyledCalendar)`
  position: sticky;
  transform: translate(30%, 45%);
  pointer-events: none;
  z-index: 2;
`;

const PickerContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const SupportExpirationDateCard = (props: ISupportExpirationDateCardProps) => {
    const { nhName, nhId, validationErrors, onChange, date } = props;
    const [expirationDate, setExpirationDate] = useState<Moment>(date);
    const isToday = (date: Moment) => {
        const today = moment();
        return date.format("MM-DD-YYYY") === today.format("MM-DD-YYYY");
    };

    const handleExpirationDateChange = (date: Moment) => {
        const now = moment();
        if (
            isToday(date) &&
            date.hours() <= now.hours() &&
            date.minutes() <= now.minutes()
        ) {
            date.hours(now.hours());
            date.minutes(now.minutes());

            setExpirationDate(date);
            onChange(Fields.ExpirationDates, moment(date), nhId)
        } else {
            setExpirationDate(date);
            onChange(Fields.ExpirationDates, moment(date), nhId)
        }
    };

    const calculateDisabledHours = (): number[] => {
        const disabledHours = [];
        if (isToday(expirationDate)) {
            const hours = moment().hours();
            for (let i = 0; i < Number(hours); i++) {
                disabledHours.push(i);
            }
        }
        return disabledHours;
    };

    const calculateDisabledMinutes = (): number[] => {
        const disabledMinutes = [];
        if (isToday(expirationDate)) {
            const currHour = moment().hours();
            const hour = expirationDate.hours();
            if (currHour === hour) {
                const minMinutes = moment().minutes();
                for (let i = 0; i < Number(minMinutes); i++) {
                    disabledMinutes.push(i);
                }
            }
        }
        return disabledMinutes;
    };

    const onTimeChange = (date: Moment | null) => {
        if (date) {
            date.set("year", expirationDate.year());
            date.set("month", expirationDate.month());
            date.set("date", expirationDate.date());
            handleExpirationDateChange(date);
        }
    };

    const rootElement = document.getElementById("root");
    const popupContainer = rootElement && rootElement.parentElement ? { defaultPopupContainer: rootElement.parentElement } : {};

    return (
        <CardContainer key={nhId}>
            <NHTitle>
                {nhName}
            </NHTitle>
            <PickerContainer>
                <ValidatedFormGroupWrapper
                    validationErrors={validationErrors}
                    dependentErrorCodes={[
                        ErrorCodes.ExpirationDatePastError,
                    ]}
                    label={"users.editForm.date"}
                    hideMessage={true}
                >
                    <DateInputContainer>
                        <StyledCalendarIcon />
                        <StyledDatePicker
                            maxDate={new Date("2099-01-01")}
                            isClearable={false}
                            selected={new Date(expirationDate.toDate())}
                            onChange={(value: any) =>
                                value
                                    ? handleExpirationDateChange(
                                        moment(value)
                                    )
                                    : null
                            }
                            minDate={new Date()}
                            dateFormat={"yyyy-MM-dd"}
                            className="form-control"
                            popperPlacement="bottom-start"
                            showPopperArrow={false}
                            disabledKeyboardNavigation={true}
                            disabled={props.readOnly}
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                        offset: [0, -24],
                                    },
                                }
                            ]}

                        />
                    </DateInputContainer>
                </ValidatedFormGroupWrapper>
                <ValidatedFormGroupWrapper
                    validationErrors={validationErrors}
                    dependentErrorCodes={[
                        ErrorCodes.ExpirationDatePastError,
                    ]}
                    label={"users.editForm.time"}
                    hideMessage={true}
                >
                    <TimePickerDropdown
                        id={"support-user-time-picker"}
                        value={expirationDate}
                        onChange={onTimeChange}
                        minuteStep={1}
                        popupWidth={196}
                        calculateDisabledHours={calculateDisabledHours}
                        calculateDisabledMinutes={calculateDisabledMinutes}
                        showClockIcon
                        readOnly={props.readOnly}
                        disabled={props.readOnly}
                        {...popupContainer}
                    />
                </ValidatedFormGroupWrapper>
            </PickerContainer>
        </CardContainer>
    )
}

export default SupportExpirationDateCard;