import styled from "styled-components";

export const StyledFormButtons = styled.div`
    width: 100%;

    button {
        float: right;
        margin-left: 10px;
    }
`;

export const StyledFormContainer = styled.div`
    width: 100%;
    padding: 0 0 60px 0;
`

export const StyledModalFormButtons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
`;

export const StyledActionButtons = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;

    button {
        margin-left: 10px;
    }
`;

export const StyledUppercase = styled.span`
    text-transform: uppercase;
`;

export const StyledLowercase = styled.span`
    text-transform: lowercase;
`;
