import { FormattedMessage } from "react-intl";
import { auditUserKey } from "../AuditKeys";
import { IAuditInformationBlockProps } from "../IAuditInformationBlockProps";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import { StyledTitle } from "../../../common/informationBlock/styled";

const AuditUsersInformationBlock = (props: IAuditInformationBlockProps) => (
    <>
        <StyledTitle><FormattedMessage id={auditUserKey} /></StyledTitle>
        <DropdownButtonNew
            qaId="user_dropdown"
            id={auditUserKey}
            name={auditUserKey}
            handleChange={(_name, value) => props.handleChange(value, auditUserKey)}
            options={props.data || []}
            selectedValue={props.filters[auditUserKey]}
            placeholder={'dropDown.all'}
            maxHeight={500}
        />
    </>
);

export default AuditUsersInformationBlock;
