import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import infoIcon from "../../../../assets/images/info.svg";
import { ResidentEventType } from "../../../../models/ResidentEventType";
import { TransmitterError } from "../../../../models/TransmitterError";
import { localizer } from "../../../../utils/Localizer";
import ChangeStatusIcon from "../../../common/ChangeStatusIcon";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import { tooltipId } from "../../../common/tooltip/Tooltip";
import DateDifferenceInHours from "./DateDifferenceInHours";

const CommonStyle = `
    margin-right: 8px;
`;

const StyledSpan = styled.span`
    margin-left: 11px;
`;

const StyledChangeStatusIcon = styled(ChangeStatusIcon)`
    ${CommonStyle}
`;

const StyledLeftWordBreak = styled(StyledLeft)`
    word-break: break-word;
`;

const StyledImg = styled.img`
    margin-left: 4px;
    margin-top: 2px;
`;

const StyledContainer = styled(StyledLeft)`
    display: grid;
`;

const StyledTimeContainer = styled.div`
    margin-left: 35px;
`;

const ResidentStatus = (props: any) => {

    const getLocalizedStatusText = (bedStatus: ResidentEventType, transmitterError: TransmitterError): any => {
        if(bedStatus === ResidentEventType.Error) {
            if(transmitterError === TransmitterError.SignalLost) {
                return <FormattedMessage id={"brief.statusType." + transmitterError} />;
            } else if([TransmitterError.CalculationError, TransmitterError.StripDetachedFromTransmitter].includes(transmitterError)) {
                return <FormattedMessage id={"brief.statusType.StripDetachedFromTransmitter"} />;
            }
        }
        return <FormattedMessage id={"brief.statusType." + bedStatus} />;
    };

    const { bedStatus, transmitterError, responseTargetOverdue, statusUpdatedTime } = props.row.original;

    return (
        <StyledContainer>
            <StyledLeftWordBreak>
                <span>
                    <StyledChangeStatusIcon status={bedStatus} />
                </span>
                <StyledSpan>{getLocalizedStatusText(bedStatus, transmitterError)}</StyledSpan>
                {bedStatus === ResidentEventType.SomeUrine &&
                    responseTargetOverdue && (
                        <>
                            <StyledImg
                                alt="info"
                                data-tooltip-id={tooltipId}
                                data-tooltip-content={localizer("residents.info.someUrineOverdue")}
                                data-tip={localizer("residents.info.someUrineOverdue")}
                                src={infoIcon}
                            />
                        </>)

                }
            </StyledLeftWordBreak>
            <StyledTimeContainer><DateDifferenceInHours value={statusUpdatedTime} /></StyledTimeContainer>
        </StyledContainer>
    );
}

export default ResidentStatus;
