import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { ReactComponent as DayIcon } from "../../../../../assets/images/day.svg";
import { ReactComponent as NightIcon } from "../../../../../assets/images/night.svg";
import Colors from "../../../../../assets/Colors";
import { CRC8 } from "../../../../../utils/CRC8";
import { localizer } from "../../../../../utils/Localizer";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../../wrappers/TextOverflowWrapper";

const StyledContainer = styled(StyledLeft) <any>`
    align-items: ${(props) => props.alignItems ? props.alignItems : ''};
`;

const StyledIconContainer = styled.span`
    width: 25px;
    margin-right: 10px;
`;

const StyledOff = styled.span`
    text-transform: capitalize;
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { transmitterProductCode } = props.data.row.original;
    const { hideTooltipOnProd } = props.data.column;

    const transmitterProductCodeWithChecksum = transmitterProductCode ? transmitterProductCode + CRC8.computeChecksum(transmitterProductCode) : "";
    const tooltip = transmitterProductCodeWithChecksum ? `${localizer("resident.history.tableRow.transmitterMonitoringTime.tooltipPrefix")}: ${transmitterProductCodeWithChecksum}` : "";
    return (
        <StyledContainer alignItems={props.data.column.cellAlignItems}>
            <TextOverflowWrapper ignoreOverflowing tooltip={tooltip} hideTooltipOnProd={hideTooltipOnProd}>
                {value === 0 ? (
                    <StyledOff>
                        <FormattedMessage id={"monitoring.off"} />
                    </StyledOff>
                ) : (
                    <>
                        <StyledIconContainer>
                            {value === "night" ?
                                <NightIcon height={24} width={24} fill={Colors.blue.brandingDark} /> :
                                <DayIcon height={24} width={24} fill={Colors.blue.brandingDark} />
                            }
                        </StyledIconContainer><FormattedMessage id={"resident.history.tableRow.transmitterMonitoringTime." + value} />
                    </>
                )}
            </TextOverflowWrapper>
        </StyledContainer>
    );
};

const TransmitterMonitoringTimeFormatter = (data: any) => <CellFormatter data={data} />;
export default TransmitterMonitoringTimeFormatter;
