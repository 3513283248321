// import {Col} from 'react-bootstrap'
import {StyledInformationContent, StyledInformationRow, StyledInformationTitle} from './styled'
import {localizer} from '../../../utils/Localizer'

interface IProps {
    sectionTranslationKey: 'externalIntegration.ward.informationMessage'
    | 'externalIntegration.careRecipient.add.informationMessage'
    | 'externalIntegration.careRecipient.delete.informationMessage'
}

export function ExternalIntegrationInformationBlock({sectionTranslationKey}: IProps) {
    return (
        <StyledInformationRow>
            <StyledInformationTitle>{localizer('externalIntegration.informationTitle')}</StyledInformationTitle>
            <StyledInformationContent>{localizer(sectionTranslationKey)}</StyledInformationContent>
        </StyledInformationRow>
    )
}
