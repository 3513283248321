import { isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { appInsights } from "../../../AppInsights";
import { LocalStorageKeys } from "../../../data/Persistence";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CareFacility, IntegrationType } from "../../../models/CareFacility";
import CareGroup from "../../../models/CareGroup";
import { SensorStripType } from "../../../models/EStripSizeSetting";
import ErrorCodes from "../../../models/ErrorCodes";
import { Roles } from "../../../models/Role";
import { nursingHomeToReqBody } from "../../../models/helpers/RequestHelpers";
import { IContextStateType, setContext, setFormDirty, setSelectedResourceName } from "../../../reducers/contextSlice";
import { validateNursingHome } from "../../../reducers/nursingHomeHelpers";
import {
    createCareFacility, deleteNursingHome, updateNursingHome
} from "../../../reducers/nursingHomeSlice";
import "../../../styles/main.scss";
import { ETrackEvent } from "../../../utils/AppInsightsHelpers";
import { adminRoleCountries } from "../../../utils/CountryHelpers";
import { getLocalizedCountry, localizer } from "../../../utils/Localizer";
import { routePaths } from "../../../utils/PathHelpers";
import GenericConfirmationForm from "../../../views/GenericConfirmationForm";
import { IDropDownOption } from "../../common/IDropDownOption";
import { ActionButtonsContainer, ButtonContainer, StyledButton } from "../../common/View";
import BackHardButton from "../../common/controls/BackHardButton";
import CheckBox from "../../common/controls/CheckBox";
import { SectionTitle } from "../../common/forms/SectionStyle";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import DayNightTimes from "../setup/DayNightTimes";
import GeneralSettings from "../setup/GeneralSettings";
import Notifications from "../setup/Notifications";
import SecuritySettings from "../setup/SecuritySettings";
import { CareFacilityFields, CheckboxContainer, Content, StyledBlock, StyledNursingHomeSettingsColumn } from "./CareFacilitiesHelper";
import "./CareFacilityAddEditForm.scss";
interface ICareFacilityAddEditFormProps {
    entity: CareFacility | undefined;
}

const CareFacilityAddEditForm = (props: ICareFacilityAddEditFormProps) => {
    const context = useAppSelector<IContextStateType>(state => state.contextSlice);
    const careGroups = useAppSelector<CareGroup[]>(state => state.careGroupSlice.careGroups);
    const nursingHomes = useAppSelector<CareFacility[]>(state => state.nursingHomeSlice.nursingHomes);
    const [careGroupsOptions, setCareGroupsOptions] = useState<IDropDownOption[]>(careGroups.map(careGroup => ({
        label: careGroup.name,
        value: careGroup.id.toString()
    })) || []);

    const filteredCountries = adminRoleCountries(context.userResourcesHierarchy);

    const countryOptions = filteredCountries.map((country) => (
        {
            label: getLocalizedCountry(country.name) || country.name,
            value: country.id.toString()
        }
    )) || [];

    const dispatch = useAppDispatch();

    const [entity, setEntity] = useState<CareFacility>(cloneDeep(props.entity) || new CareFacility());
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const isEdit = !!props.entity;
    const initialState = cloneDeep(props.entity) || new CareFacility();

    const { id, initialRole } = context;

    useEffect(() => {
        if (entity.countryId) {
            const filteredCareGroups = careGroups.filter(cg => cg.countryId.toString() === entity.countryId).map(careGroup => ({
                label: careGroup.name,
                value: careGroup.id.toString()
            }))
            setCareGroupsOptions(filteredCareGroups);
        }
    }, [careGroups, entity.countryId]);

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            window.scrollTo(0, 0);
            return;
        }
        try {
            await sendNursingHome();
            dispatch(setFormDirty(false));
            if (isEdit && id === entity.id && entity.isActive === false) {
                reactLocalStorage.clear();
                reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
                    role: initialRole
                });
                dispatch(setContext({ id: undefined, role: initialRole }));
                dispatch(setSelectedResourceName(undefined));
                navigate(routePaths.overview);
            } else {
                navigate(routePaths.careFacilities);
            }
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error?.status === 409) {
                setValidationErrors([ErrorCodes.DuplicateNameError])
            }
        }
    };

    const onDeleteConfirm = async (event: any) => {
        event.preventDefault();
        dispatch(deleteNursingHome(String(entity.id)));
        setConfirmDelete(false);
        appInsights.trackEvent({ name: ETrackEvent.NursingHomePageDelete });
    };

    const validate = async (): Promise<boolean> => {
        const errors: ErrorCodes[] = validateNursingHome(entity);
        const nursingHomeAlreadyExists = nursingHomes?.find(nursingHome =>
            nursingHome.id !== entity.id
            && nursingHome.name.toLowerCase() === entity.name.toLowerCase());
        if (nursingHomeAlreadyExists) {
            errors.push(ErrorCodes.DuplicateNameError);
        }
        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendNursingHome = () => {
        const requestBody = nursingHomeToReqBody(entity);
        if (isEdit) {
            appInsights.trackEvent({ name: ETrackEvent.NursingHomePageUpdate });
            return dispatch(updateNursingHome(requestBody, true));
        } else {
            appInsights.trackEvent({ name: ETrackEvent.NursingHomePageAdd });
            return dispatch(createCareFacility(requestBody));
        }
    };

    const shouldClearNotify = (timeA: string, timeB: string) => {
        const [shiftHours, shiftMinutes] = timeA.split(":");
        const [notifyHours, notifyMinutes] = timeB.split(":");

        const shiftToMinutes = Number(shiftHours) * 60 + Number(shiftMinutes);
        const notifyToMinutes = Number(notifyHours) * 60 + Number(notifyMinutes);

        return shiftToMinutes > notifyToMinutes;
    };

    const handleChange = (name: string, value: string) => {
        const nursingHome = cloneDeep(entity);

        switch (name) {
            case CareFacilityFields.Name:
                nursingHome.name = value;
                break;
            case CareFacilityFields.MorningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.morningShiftNotificationTime)) {
                    nursingHome.morningShiftNotificationTime = "";
                }
                nursingHome.morningShiftStartTime = value;
                break;
            case CareFacilityFields.EveningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.nightShiftNotificationTime)) {
                    nursingHome.nightShiftNotificationTime = "";
                }

                nursingHome.nightShiftStartTime = value;
                break;
            case CareFacilityFields.CaregiverNotificationInterval:
                nursingHome.caregiverNotificationIntervalMinutes = Number(value);
                break;
            case CareFacilityFields.CaregiverNotificationIntervalDay:
                nursingHome.caregiverNotificationIntervalMinutesDay = Number(value);
                break;
            case CareFacilityFields.CaregiverResponseTarget:
                nursingHome.caregiverResponseTargetMinutes = Number(value);
                break;
            case CareFacilityFields.CaregiverTargetResponseTimeDay:
                nursingHome.caregiverResponseTargetMinutesDay = Number(value);
                break;
            case CareFacilityFields.IsWeakAuthenticationAllowed:
                nursingHome.isWeakAuthenticationAllowed = Boolean(value);
                break;
            case CareFacilityFields.Country:
                nursingHome.countryId = value;
                nursingHome.nursingHomeGroupId = "";
                break;
            case CareFacilityFields.CareGroup:
                nursingHome.nursingHomeGroupId = value;
                if (!nursingHome.countryId) {
                    nursingHome.countryId = careGroups.find(cg => cg.id.toString() === value)?.countryId.toString() || "";
                }
                break;
            case CareFacilityFields.CustomerId:
                nursingHome.customerId = value;
                break;
            case CareFacilityFields.IsActive:
                nursingHome.isActive = Boolean(value);
                break;
            case CareFacilityFields.MorningShiftNotificationTime:
                nursingHome.morningShiftNotificationTime = value;
                break;
            case CareFacilityFields.NightShiftNotificationTime:
                nursingHome.nightShiftNotificationTime = value;
                break;
            case CareFacilityFields.ShiftNotificationTimeDisabled:
                nursingHome.shiftNotificationTimeDisabled = Boolean(value);
                break;
            case CareFacilityFields.Is2FAActive:
                nursingHome.is2FactorAuthenticationEnabled = Boolean(value);
                break;
            case CareFacilityFields.EightHourNotification:
                nursingHome.notifyOfSomeUrineStatusOverdue = !!Number(value);
                break;
            case CareFacilityFields.ExternalIntegration:
                if (value === "0") {
                    value = "";
                }
                nursingHome.externalIntegrationId = value || null;
                break;
            case CareFacilityFields.StripSizeSetting:
                nursingHome.sensorStripType = value as SensorStripType;
                break;
        }
        if (!isFormDirty && !isEqual(initialState, nursingHome)) {
            dispatch(setFormDirty(true, "general.leaveTitle", "general.leaveMessage", "constants.leave"));
        } else if (isFormDirty && isEqual(initialState, nursingHome)) {
            dispatch(setFormDirty(false));
        }

        setEntity(nursingHome);
    };

    const isReadOnly = () => {
        const integrationType = entity.externalIntegrationType;
        return isEdit && (integrationType !== null && integrationType !== IntegrationType.Amba);
    }

    const addEditForm =
        <div>
            <CheckboxContainer>
                <CheckBox
                    label={localizer("careFacility.active")}
                    fieldName={CareFacilityFields.IsActive}
                    value={entity.isActive}
                    onChange={handleChange}
                />
            </CheckboxContainer>
            <Content>
                <StyledNursingHomeSettingsColumn>
                    <GeneralSettings
                        readonly={isReadOnly()}
                        nursingHome={entity}
                        countries={countryOptions}
                        careGroups={careGroupsOptions}
                        handleChanges={handleChange}
                        validationErrors={validationErrors}
                    />
                    <SecuritySettings readonly={![Roles.NHManager, Roles.Support].includes(context.role)}
                        nursingHome={entity}
                        handlePreferenceChanges={handleChange}
                        validationErrors={validationErrors} />
                </StyledNursingHomeSettingsColumn>
                <StyledNursingHomeSettingsColumn>
                    <Notifications
                        readonly={context.role === Roles.Nurse}
                        nursingHome={entity}
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                    />
                    <StyledBlock>
                        <SectionTitle>
                            <FormattedMessage id="setup.shiftStartTime" />
                        </SectionTitle>
                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            dayValue={entity.morningShiftStartTime}
                            nightValue={entity.nightShiftStartTime}
                            dayTimeFieldName={CareFacilityFields.MorningShiftStartTime}
                            nightTimeFiledName={CareFacilityFields.EveningShiftStartTime}
                            dayError={ErrorCodes.EmptyMorningShiftStartTime}
                            nightError={ErrorCodes.EmptyNightShiftStartTime}
                            minuteStep={10}
                        />
                        <br />
                        <p><FormattedMessage id="setup.notifyCaregiver.title" /></p>
                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            dayValue={entity.morningShiftNotificationTime}
                            nightValue={entity.nightShiftNotificationTime}
                            dayTimeFieldName={CareFacilityFields.MorningShiftNotificationTime}
                            nightTimeFiledName={CareFacilityFields.NightShiftNotificationTime}
                            dayError={ErrorCodes.EmptyMorningShiftStartNotificationTime}
                            nightError={ErrorCodes.EmptyNightShiftStartNotificationTime}
                            minuteStep={10}
                            dayMinTime={entity.morningShiftStartTime}
                            nightMinTime={entity.nightShiftStartTime}
                            disabled={entity.shiftNotificationTimeDisabled}
                        />
                        <CheckBox
                            qaId="nh_notifyCaregiver_checkbox"
                            label={localizer("setup.notifyCaregiver.disable")}
                            value={entity.shiftNotificationTimeDisabled}
                            fieldName={CareFacilityFields.ShiftNotificationTimeDisabled}
                            onChange={handleChange}
                        />
                    </StyledBlock>
                </StyledNursingHomeSettingsColumn>
            </Content>
        </div>;

    return (
        <React.Fragment>
            {addEditForm}
            <ButtonContainer>
                <BackHardButton
                    onClick={() => isFormDirty ? setConfirmLeave(true) : navigate(routePaths.careFacilities)}
                    backRoute={routePaths.careFacilities} />
                <ActionButtonsContainer>
                    {isEdit && (
                        <StyledButton
                            variant={"outline-secondary"}
                            type={"button"}
                            onClick={() => setConfirmDelete(true)}
                        >
                            {localizer("careFacility.delete")}
                        </StyledButton>
                    )}
                    <StyledButton
                        variant={"primary"}
                        onClick={onSave}
                        disabled={isEqual(initialState, entity)}
                    >
                        {localizer("constants.save")}
                    </StyledButton>
                </ActionButtonsContainer>
            </ButtonContainer>

            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"general.leaveMessage"}
                            confirmButton={"constants.leave"}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                dispatch(setFormDirty(false));
                                navigate(routePaths.careFacilities);
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                    modalTitle={localizer("general.leaveTitle")}
                    size={EModalSize.XS}
                />
            )}

            {confirmDelete && (
                <ModalWrapper
                    closeCallback={() => setConfirmDelete(false)}
                    isOpen={confirmDelete}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"careFacility.delete.confirmation"}
                            confirmButton={"constants.delete"}
                            onConfirmCancel={() => {
                                setConfirmDelete(false);
                            }}
                            onConfirm={(event: any) => {
                                dispatch(setFormDirty(false));
                                onDeleteConfirm(event);
                                if (isEdit && id === entity.id) {
                                    reactLocalStorage.clear();
                                    reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
                                        role: initialRole
                                    });
                                    dispatch(setContext({ id: undefined, role: initialRole }));
                                    dispatch(setSelectedResourceName(undefined));
                                    navigate(routePaths.overview);
                                } else {
                                    navigate(routePaths.careFacilities);
                                }
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                    modalTitle={localizer("careFacility.delete")}
                    size={EModalSize.XS}
                />
            )}
        </React.Fragment>
    )
};

export default CareFacilityAddEditForm;
