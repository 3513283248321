// Adjust X axis points to contents.
// For 1-16 days, show all dates (date and full month)
// For 17-32 days, show every other day (date and full month)
// For 33-48 days, we show every third date (date and full month)
// For 49-64 days, we show every fourth date (date and full month)
// 65-80 days, we show every fifth date (date and full month)
// 81-96 days, we show every sixth date (date and full month)
// 97-112 days, we show every seventh date (date and full month)
// 113-128 days, we show every eighth date (date and full month)
// 129-144 days, we show every ninth date (date and full month)
// 145-160 days, we show every tenth date (date and full month)
// 161-176 days, we show every eleventh date (date and full month)
// 177-192 days, we show every twelfth date (date and full month)

// 193 days - 16 months, we show every month and year
// 17 months - 32 months, we show every second month and year
// 33 months - 48 months, we show every third month and year
// 49 months - 64 months, we show every fourth month and year

// 65 months - upwards, we show every year.

// 1 month = 30.42 days used for months to days conversion. Use normal rounding rules (>= 05 up, <0.5 down)
export const getInterval = (interval: number) => {
    switch (true) {
        case (interval <= 16): // 1-16 days
            return 0;
        case (interval > 16 && interval <= 32): // 17-32 days
            return 1;
        case (interval > 32 && interval <= 48): // 33-48 days
            return 2;
        case (interval > 48 && interval <= 64): // 49-64 days
            return 3;
        case (interval > 64 && interval <= 80): // 65-80 days
            return 4;
        case (interval > 80 && interval <= 96): // 81-96 days
            return 5;
        case (interval > 96 && interval <= 112): // 97-112 days
            return 6;
        case (interval > 112 && interval <= 128): // 113-128 days
            return 7;
        case (interval > 128 && interval <= 144): // 129-144 days
            return 8;
        case (interval > 144 && interval <= 160): // 145-160 days
            return 9;
        case (interval > 160 && interval <= 176): // 161-176 days
            return 10;
        case (interval > 176 && interval <= 192): // 177-192 days
            return 11;
        case (interval > 192 && interval <= 487): // 193 days - 16 months. 16 months = 16 * 30.42 = 486.72 = 487 days
            return 30;
        case (interval > 487 && interval <= 973): // 17 months - 32 months. 32 months = 32 * 30.42 = 973.44 = 973 days
            return 60;
        case (interval > 973 && interval <= 1460): // 33 months - 48 months. 48 months = 48 * 30.42 = 1460.16 = 1460 days
            return 90;
        case (interval > 1460 && interval <= 1947): // 49 months - 64 months. 64 months = 64 * 30.42 = 1946.88 = 1947 days
            return 120;
        case (interval > 1947): // 65 months - upwards, we show every year.
            return 365;
        default:
            return 0;
    }
};

// based on the tick interval we show different date formats
// For 1-192 days, date and full month
// 193 days - 64 months, month and year
// 65 months - upwards, year.
export const getXAxisFormat = (interval: number) => {
    switch (true) {
        case (interval <= 11):
            return "DD MMMM";
        case (interval > 11 && interval <= 120):
            return "MMMM YYYY";
        case (interval > 120):
            return "YYYY";
        default:
            return "DD MMMM";
    }
};

// time period when we swtich between Bar Charts or Line Chart
export const selectedTimePeriodChartSwitcher = 15;