import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { StatusChangeTriggers } from '../../../../../models/ResidentHistory';
import { StyledLeft } from '../../../../common/table/formatters/PositionContainers';


const StyledWrapper = styled(StyledLeft) <any>`
    flex-direction: column;
    align-items: ${(props) => props.alignItems ? props.alignItems : 'start'};
`;


const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { isProductChange } = props.data.row.original;

    let firstLineId = "resident.history.tableRow.statusChangeTrigger.";
    let secondLineId;

    switch (value) {
        case StatusChangeTriggers.ResidentUpdated:
            firstLineId = firstLineId + StatusChangeTriggers.Web;
            secondLineId = "resident.history.tableRow.statusChangeTrigger." + StatusChangeTriggers.ResidentUpdated;
            break;
        case StatusChangeTriggers.Web:
            firstLineId = firstLineId + StatusChangeTriggers.Web;
            secondLineId = "resident.history.tableRow.statusChangeTrigger.webSwitch";
            break;
        case StatusChangeTriggers.System:
        case StatusChangeTriggers.Button:
        case StatusChangeTriggers.Mobile:
        default:
            firstLineId = firstLineId + value;
            secondLineId = isProductChange ? "resident.history.tableRow.statusChangeTrigger.productChange" : "";
            break;
    }
    
    return (
        <StyledWrapper alignItems={props.data.column.cellAlignItems}>
            <FormattedMessage id={firstLineId} />
            {secondLineId.length > 0 && <small>(<FormattedMessage id={secondLineId} />)</small>}
        </StyledWrapper>
    );
};

const StatusChangeTrigger = (data: any) => <CellFormatter data={data} />;
export default StatusChangeTrigger;
