import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Colors from "../../../../../assets/Colors";
import ModalWrapper, {
    EModalSize
} from "../../../../common/modal/ModalWrapper";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers";
import AuditDetailsTable from "../AuditDetailsTable";

const StyledButton = styled.div`
    font-size: 16px;
    color: ${Colors.blue.brandingDark};
    cursor: pointer;
`;

const StyledMessageContainer = styled.div`
    text-align: center;
`;

const AuditDetailsFormatter = (props: any) => {

    const triggerComponent: React.ReactNode = (
        <StyledButton>
            <FormattedMessage id="audits.tableHeader.details" />
        </StyledButton>
    );
    const modalContent = props.data && props.data.length > 0 ?
        (
            <AuditDetailsTable data={props.data} />
        ) :
        (
            <StyledMessageContainer>
                <FormattedMessage id="audits.details.noPropertiesUpdated" />
            </StyledMessageContainer>
        );

    return (
        <StyledLeft>
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={
                    modalContent
                }
                modalTitle={
                    <FormattedMessage id="audits.tableHeader.details"></FormattedMessage>
                }
                size={EModalSize.M}
            />
        </StyledLeft>
    );
};

export default AuditDetailsFormatter;
