import React from "react";
import { FormattedMessage } from "react-intl";
import GoTo from "../../common/table/formatters/GoTo";
import StringFormatter from "../../common/table/formatters/StringFormatter";
import Table, { headerJustifyOptions } from "../../common/table/Table";
import { ITableProps } from "../ITableProps";
import HomeResidentFormatter from "./formatters/HomeResidentFormatter";
import TrendFormatter from "./formatters/TrendFormatter";
import WardFormatter from "./formatters/WardFormatter";
import SimpleValueFormatter from "./formatters/SimpleValueFormatter";
import PercentageFormatter from "./formatters/PercentageFormatter";

const ProductChangesTable = (props: ITableProps & {timeSpan: number}) => {
    const { timeSpan } = props;

    // 7 changes per day according to requirements
    const KpiMaxValuePerDay = 7;

    const columns = React.useMemo(
        () => [
            {
                Header:  <FormattedMessage id={"home.resident"} />,
                accessor: "name",
                Cell: ((props: any) => {
                    const percentage = Math.ceil((100*props.row.original.statistics.productChanges.value)/(timeSpan*KpiMaxValuePerDay))
                    return <HomeResidentFormatter {...props} barPercentage={percentage > 100 ? 100 : percentage}/>
                }),
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 7,
                hideTooltipOnProd: false,
                minWidth: 400,
            },
            {
                Header: <FormattedMessage id={"home.productChanges.totalChanges"} />,
                accessor: "statistics.productChanges.value",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                flexGrow: 3,
                minWidth: 100,
            },
            {
                Header: <FormattedMessage id={"home.trend"} />,
                accessor: "statistics.productChanges.trend",
                Cell: TrendFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexShrink: 20,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id={"home.productChanges.prompted"} />,
                accessor: "statistics.productChanges.prompted",
                Cell: PercentageFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexShrink: 15,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id={"home.night"} />,
                accessor: "statistics.productChanges.valueNight",
                Cell: SimpleValueFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                flexShrink: 20,
                
            },
            {
                Header: <FormattedMessage id={"home.day"} />,
                accessor: "statistics.productChanges.valueDay",
                Cell: SimpleValueFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                flexShrink: 20,
            },
            {
                Header: <FormattedMessage id={"home.ward"} />,
                accessor: "wardId",
                Cell: WardFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                minWidth: 230,
            },
            {
                Header: <FormattedMessage id={"general.tableHeader.goTo"} />,
                accessor: "id",
                Cell: GoTo,
                headerAlignment: headerJustifyOptions.right,
                flexShrink: 22,
                hideTooltipOnProd: true,
                minWidth: 90,
                disableSortBy: true
            }
        ],
        [timeSpan]
    );

    const data = React.useMemo(() => props.data, [props.data]);
    const sortBy =  React.useMemo(() => (
        [
            {
                id: "statistics.productChanges.value",
                desc: true
            },
            {
                id: "statistics.productChanges.trend",
                desc: true
            }
        ]
    ), []);

    return (
        <Table
            qaId={"product_changes_table"}
            columns={columns}
            data={data}
            styleProps={{
                rowHeight: 50,
                headerHeight: 55,
                inverseColors: true,
                dynamicHeight: props.data.length <= 3,
            }}
            initialState={{
                sortBy: sortBy
            }}
        />
    );
};

export default React.memo(ProductChangesTable);
