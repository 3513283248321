import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { appInsights } from "../../../../AppInsights";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
    fetchInternalUsers,
    IUserStateType
} from "../../../../reducers/userSlice";
import { ETrackEvent } from "../../../../utils/AppInsightsHelpers";
import { localizer } from "../../../../utils/Localizer";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import { headerJustifyOptions } from "../../../common/table/PaginatedTable";
import {
    StyledViewContainer
} from "../../../common/View";
import UserActions from "../common/tables/formatters/UserActions";
import UserNameFormatter from "../common/tables/formatters/UserNameFormatter";
import UsersTable from "../users/tables/UsersTable";

const GlobalAdminUserView = () => {
    const dispatch = useAppDispatch();
    const { users } = useAppSelector<IUserStateType>(
        (state) => state.userSlice
    );

    useEffect(() => {
        dispatch(fetchInternalUsers());
        appInsights.trackEvent({ name: ETrackEvent.UsersPage });
    }, [dispatch]);

    const translatedRolesUsers = React.useMemo(() => {
        return users.map((user) => {
        const translatedRole = localizer("user.role." + user.role);
        return { ...user, translatedRole: translatedRole };
    })}, [users]);

    const columns =
        [
            {
                Header: <FormattedMessage id="constants.email" />,
                accessor: "name",
                Cell: UserNameFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1
            },
            {
                Header: <FormattedMessage id="users.tableHeader.role" />,
                accessor: "translatedRole",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
            },
            {
                Header: <FormattedMessage id="users.tableHeader.lastAccess" />,
                accessor: "lastLogin",
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
                disableGlobalFilter: true
            },
            {
                Header: <></>,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: UserActions,
                disableSortBy: true,
                disableGlobalFilter: true
            }
        ];

    return (
        <StyledViewContainer>
            <UsersTable
                paginated={true}
                data={translatedRolesUsers}
                columns={columns}
            />
        </StyledViewContainer>
    );
};

export default GlobalAdminUserView;
