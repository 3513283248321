import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { localizer } from "../../../utils/Localizer";

interface ICheckBoxNewProps {
    fieldName: string;
    value: boolean;
    onChange: (name: string, value: any) => void;
    disabled?: boolean;
    errorMessage?: string | undefined;
    label: string;
    size?: 'M' | 'L';
    qaId?: string;
    extraMessage?: string;
    width?: number;
    cutText?: boolean;
    fontSize?: number;
    showActiveMessage?: boolean;
    isActive?: boolean;
}

const StyledCheckBox = styled(Form.Check) <any>`

    :hover {
        cursor: ${props => !!props.disabled ? "default" : "pointer"}
    }
    .form-check-input {
        height: ${(props: any) => props.size === 'M' ? '1em' : '1.5em'};
        width: ${(props: any) => props.size === 'M' ? '1em' : '1.5em'};
        border-color: ${Colors.blue.brandingDark};
        float: none;
        background-color: ${(props: any) =>
        props.disabled ? Colors.grey.light : Colors.white.clearWhite};
        :disabled {
            opacity: 1;
            border-color: ${Colors.grey.mediumLight};
            :checked {
                background-color: ${Colors.blue.darkDisabled};
                border-color: ${Colors.blue.darkDisabled};
            }
        }
        :checked {
            background-color: ${Colors.blue.brandingDark};

        }
        :focus {
            box-shadow: none;
        }
        :hover {
            cursor: ${props => !!props.disabled ? "default" : "pointer"}
        }
    }
    .form-check-label{
        opacity: 1 !important;
        white-space: pre-line;
        display: inline-flex;
        flex-direction: column;
        margin-left: 10px;
        vertical-align: bottom;
        :hover {
            cursor: ${props => !!props.disabled ? "default" : "pointer"}
        }
    }
    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label{ // this identifier look like this due to a bug in react-bootstrap which doesn't disable the label
        color: ${Colors.grey.medium} !important;
    }
`;
const StyledWrapper = styled.div<any>`
    display: block;
    position: relative;
    label {
        cursor: pointer;
    }
    ${props => props.width && `width: ${props.width}px;`}
`;

const ExtraMessageLabel = styled.span`
    font-style: italic;
    font-size: 14px;
    word-break: break-word;
`;

const StyledSpan = styled.div<any>`
    ${props => props.cutText && `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 210px;
    `}
`;

const StyledActiveMessage = styled.span`
    color: #828282;
`;

const CheckBox = (props: ICheckBoxNewProps) => {
    const onClick = () => {
        if (!props.disabled) {
            props.onChange(props.fieldName, !props.value);
        }
    };

    return (
        <StyledWrapper width={props.width}>
            <StyledCheckBox
                data-qa={props.qaId}
                name={props.fieldName}
                type="checkbox"
                id={`check-api-${props.fieldName}`}
                disabled={!!props.disabled}
                checked={!!props.value}
                label={
                    <>
                        <StyledSpan fontSize={props.fontSize} cutText={props.cutText}>{props.label}{props.showActiveMessage && !props.isActive && (<StyledActiveMessage>{" ("}{localizer("constants.inactive")}{")"}</StyledActiveMessage>)}</StyledSpan>
                        {props.extraMessage && (
                            <ExtraMessageLabel>{props.extraMessage}</ExtraMessageLabel>
                        )}
                    </>
                }
                onChange={onClick}
                size={props.size}
            >
                {props.errorMessage && (
                    <span className="error-message">
                        <FormattedMessage id={props.errorMessage} />
                    </span>
                )}
            </StyledCheckBox>
        </StyledWrapper>
    );
};

export default CheckBox;
