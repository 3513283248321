import {
    useParams
} from "react-router-dom";

export const withRouter = (Component: any) => {
    const ComponentWithRouterProp = (props: any) => {
        const params = useParams();
        return (
            <Component
                {...props}
                params={params}
            />
        );
    }
    return ComponentWithRouterProp;
}