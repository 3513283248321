import styled from "styled-components";
import { appInsights } from "../../../AppInsights";
import Colors from "../../../assets/Colors";
import { ReactComponent as PlayIconFull } from "../../../assets/images/playIconFull.svg";
import { default as th0, default as th1 } from "../../../assets/images/videoThumbnails/multiStrip/1.png";
import th10 from "../../../assets/images/videoThumbnails/multiStrip/10.png";
import th11 from "../../../assets/images/videoThumbnails/multiStrip/11.png";
import th2 from "../../../assets/images/videoThumbnails/multiStrip/2.png";
import th3 from "../../../assets/images/videoThumbnails/multiStrip/3.png";
import th4 from "../../../assets/images/videoThumbnails/multiStrip/4.png";
import th5 from "../../../assets/images/videoThumbnails/multiStrip/5.png";
import th6 from "../../../assets/images/videoThumbnails/multiStrip/6.png";
import th7 from "../../../assets/images/videoThumbnails/multiStrip/7.png";
import th8 from "../../../assets/images/videoThumbnails/multiStrip/8.png";
import th9 from "../../../assets/images/videoThumbnails/multiStrip/9.png";
import { default as oneStripTh1, default as oneStripTh14 } from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh1.png";
import oneStripTh10 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh10.png";
import oneStripTh11 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh11.png";
import oneStripTh12 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh12.png";
import oneStripTh13 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh13.png";
import oneStripTh2 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh2.png";
import oneStripTh3 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh3.png";
import oneStripTh4 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh4.png";
import oneStripTh5 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh5.png";
import oneStripTh6 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh6.png";
import oneStripTh7 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh7.png";
import oneStripTh8 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh8.png";
import oneStripTh9 from "../../../assets/images/videoThumbnails/oneStrip/oneStripTh9.png";
import { SensorStripType } from "../../../models/EStripSizeSetting";
import { localesWithoutExclusiveVideos, exclusiveMultiStripVideos, exclusiveSingleStripVideos, multStripVideosList, singleStripVideosList } from "../../../settings/Settings";
import { ETrackEvent } from "../../../utils/AppInsightsHelpers";
import { getLocale, localizer } from "../../../utils/Localizer";
import { REACT_APP_HELP_BASE_URL } from "../../../utils/ProcessEnvHelpers";
import { StyledInformationBlocks } from "../../common/View";
import { ClickableStyledInformationBlockWrapper } from "../../common/informationBlock/styled";

const multiStripThumbnails = [
    th0,
    th1,
    th2,
    th3,
    th4,
    th5,
    th6,
    th7,
    th8,
    th9,
    th10,
    th11
];

const oneStripThumbnails = [
    oneStripTh1,
    oneStripTh2,
    oneStripTh3,
    oneStripTh4,
    oneStripTh5,
    oneStripTh6,
    oneStripTh7,
    oneStripTh8,
    oneStripTh9,
    oneStripTh10,
    oneStripTh11,
    oneStripTh12,
    oneStripTh13,
    oneStripTh14
];

const VideoContainer = styled(ClickableStyledInformationBlockWrapper)`
    width: 339px;
    height: 220px;
    position: relative;
    border: 0px;
    padding: 0;
`;

const Thumbnail = styled.img`
    width: 339px;
    height: 220px;
    border-radius: 4px;
`

const Title = styled.div`
    position: absolute;
    bottom: 0px;
    background-color: ${Colors.white.clearWhite};
    padding: 20px;
    color: ${Colors.black};
    font-weight: bold;
    width: 339px;
    height: 80px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;

const PlayIcon = styled(PlayIconFull)`
    position: absolute;
    left: 135px;
    top: 50px;
`;

const getVideoUrl = (videoNumber: number) => {
    let url: string;
    switch (videoNumber) {
        case 0:
            url = "help.instructionVideo.file";
            break
        default:
            url = `help.instructionVideo.file.${videoNumber}`;
            break;
    }
    return url;
}

const getVideoTitle = (videoNumber: number) => {
    let name: string;
    switch (videoNumber) {
        case 0:
            name = "help.instructionVideo.linkText";
            break
        default:
            name = `help.instructionVideo.name.${videoNumber}`;
            break;
    }
    return name;
}

interface IHelpVideosContainer {
    sensorStripType: SensorStripType;
}

const HelpVideosContainer = (props: IHelpVideosContainer) => {
    const { sensorStripType } = props;
    const locale = getLocale();
    let videos;
    if (sensorStripType === SensorStripType.MultiSize) {
        videos = multStripVideosList.map(videoNumber => {
            if (!(exclusiveMultiStripVideos.includes(videoNumber) && localesWithoutExclusiveVideos.includes(locale))) {
                const videoUrl = getVideoUrl(videoNumber);
                const videoTitle = getVideoTitle(videoNumber);
                return (
                    <VideoContainer
                        onClick={() => {
                            window.open(`${REACT_APP_HELP_BASE_URL}/${localizer(videoUrl)}`, "_blank", "noopener,noreferrer");
                            appInsights.trackEvent({ name: ETrackEvent.HelpPageVideoAccess, properties: { videoNumber: videoNumber } })
                        }}
                        key={videoNumber}
                    >
                        <Thumbnail src={multiStripThumbnails[videoNumber]} alt={`${videoNumber}`} />
                        <PlayIcon />
                        <Title>{localizer(videoTitle)}</Title>
                    </VideoContainer>)
            }
            return;
        })
    } else {
        videos = singleStripVideosList.map((videoNumber: number, index: number) => {
            if (!(localesWithoutExclusiveVideos.includes(locale) && exclusiveSingleStripVideos.includes(videoNumber))) {
                return (
                    <VideoContainer
                        onClick={() => {
                            window.open(`${REACT_APP_HELP_BASE_URL}/${localizer("help.instructionVideo.singleSize.file." + (index + 1).toString())}`, "_blank", "noopener,noreferrer");
                            appInsights.trackEvent({ name: ETrackEvent.HelpPageVideoAccess, properties: { videoNumber: videoNumber } })
                        }}
                        key={videoNumber}
                    >
                        <Thumbnail src={oneStripThumbnails[videoNumber - 1]} alt={`${videoNumber}`} />
                        <PlayIcon />
                        <Title>{localizer(`help.instructionVideo.singleSize.name.${videoNumber}`)}</Title>
                    </VideoContainer>
                )
            }
            return
        })
    }

    return (
        <StyledInformationBlocks>
            {videos}
        </StyledInformationBlocks>
    )
}

export default HelpVideosContainer;