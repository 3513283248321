import React from "react";
import { ITableProps } from "../../ITableProps";
import { FormattedMessage } from "react-intl";
import Table, { headerJustifyOptions } from "../../../common/table/Table";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import RequestFormatter from "./formatters/RequestFormatter";
import AuditDetailFormatter from "./formatters/AuditDetailFormatter";
import StatusFormatter from "./formatters/StatusFormatter";

const AuditsResidentsTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="audits.tableHeader.time" />,
                accessor: "time",
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.resident" />,
                accessor: "resident",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 4,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.activity" />,
                accessor: "activity",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.modifiedBy" />,
                accessor: "performedBy",
                Cell: StringFormatter,
                flexGrow: 3,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: false,
            },

            {
                Header: <FormattedMessage id="audits.tableHeader.status" />,
                accessor: "statusCode",
                Cell: StatusFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexShrink: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <></>,
                accessor: "requestBody",
                Cell: (props: any) => {
                    const type = props.cell.row.original.type;
                    if (type) {
                        if (type === "POST" || type === "PUT") {
                            return <AuditDetailFormatter data={props.cell.row.original.updatedProperties}/>;
                        }
                        return <></>;
                    }
                    return <RequestFormatter  {...props} />;
                },
                headerAlignment: headerJustifyOptions.left,
                disableSortBy: true,
                flexShrink: 4,
            },
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);
    return (
        <Table
            qaId={"audit_residents_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "time",
                        desc: true
                    }
                ]
            }}
        />
    );
};

export default React.memo(AuditsResidentsTable);
