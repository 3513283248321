import { KpiType } from "../../../models/KpiType";
import { IStatisticValues } from "../../../models/StatisticValues";
import { convertMinutesToHours, convertTimeToHtml } from "../../../utils/ConvertMinutesToHours";
import { localizer } from "../../../utils/Localizer";
import { getTrendIcon, getTrendValue } from "../../../utils/StatisticsHelpers";
import { EBlockSize, ClickableStyledInformationBlockWrapper, IconContainer, StyledTrendContainer, StyledValueContainer } from "../../common/informationBlock/styled";
import NumberInformationBlock from "../informationBlock/NumberInformationBlock";

interface INightTimeBlock {
    selectedKpi: KpiType;
    nightTimeMinutesBetweenChanges: IStatisticValues;
    handleDropdownSelection: (name: string, value: any) => void;
    size: EBlockSize;
}

const NightTimeBlock = (props: INightTimeBlock) => {

    const {
        selectedKpi,
        handleDropdownSelection,
        nightTimeMinutesBetweenChanges,
        size
    } = props;

    return (
        <ClickableStyledInformationBlockWrapper
            size={size}
            alignItems="start"
            isSelected={selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges}
            onClick={() =>
                handleDropdownSelection(
                    "kpiSelected",
                    KpiType.AvgNightTimeMinutesBetweenChanges
                )
            }
        >
            <NumberInformationBlock
                header={localizer("constants.changeEventsFilter.kpi.nightTime")}
                description={localizer("constants.changeEventsFilter.kpi.nightTime.description")}
                isSelected={selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges}
                value={
                    <>
                        <StyledValueContainer>
                            {convertTimeToHtml(
                                convertMinutesToHours(
                                    nightTimeMinutesBetweenChanges.value
                                ),
                                true,
                                true
                            )}
                        </StyledValueContainer>
                        {nightTimeMinutesBetweenChanges.value !== null && (
                            <StyledTrendContainer>
                                <IconContainer>{getTrendIcon(nightTimeMinutesBetweenChanges.trend)}</IconContainer>
                                <div>{getTrendValue(nightTimeMinutesBetweenChanges.trend)}{nightTimeMinutesBetweenChanges.trend !== null && "%"}</div>
                            </StyledTrendContainer>)}
                    </>}
            />
        </ClickableStyledInformationBlockWrapper>
    )
}

export default NightTimeBlock;