import { localizer } from "../../../../../utils/Localizer";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers"
import TextOverflowWrapper from "../../../../wrappers/TextOverflowWrapper";

const CellFormatter = (props: any) => {
    const { value } = props.data;
    let status;
    if (value >= 200 && value < 300) {
        status = localizer("audits.table.status.success")
    } else {
        status = localizer("audits.table.status.failure")
    }

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={status}>
                {status}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const StatusFormatter = (data: any) => <CellFormatter data={data} />;
export default StatusFormatter;
