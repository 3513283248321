import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import {StyledActionButtons, StyledModalFormButtons} from "../../components/common/styled";

interface IAddEditButtonGroupProps {
    isEdit: boolean;
    onSave: (event: any) => void;
    onSaveAndAddAnother?: (event: any) => void;
    onDiscard?: (event: any) => void;
    removeCloseButton?: boolean;
    extraActions?: Array<{ label: string; action: (event: any) => void, disabled?: boolean }>;
    customConfirmButtonMessageId?: string;
    disabledConfirm?: boolean;
    disabledAddAnother?: boolean;
}

const AddEditModalButtonGroup = (props: IAddEditButtonGroupProps) => {
    return (
        <StyledModalFormButtons>
            <StyledActionButtons>
                <Button
                    data-qa="save_button"
                    disabled={props.disabledConfirm}
                    variant={"primary"}
                    type="submit"
                    className="confirm-button"
                    onClick={props.onSave}
                >
                    <FormattedMessage
                        id={
                            props.customConfirmButtonMessageId
                                ? props.customConfirmButtonMessageId
                                : "addEditButton.saveChanges"
                        }
                    />
                </Button>

                {!props.isEdit && props.onSaveAndAddAnother && (
                    <Button
                        data-qa="save_add_new_button"
                        variant={"outline-secondary"}
                        type="submit"
                        className="confirm-and-add-another-button"
                        onClick={props.onSaveAndAddAnother}
                        disabled={props.disabledAddAnother}
                    >
                        <FormattedMessage id="addEditButton.confirmAndAddAnother"/>
                    </Button>
                )}

                {props.extraActions &&
                    props.extraActions.map((extraAction) => (
                        <Button
                            data-qa="delete_button"
                            variant={"outline-secondary"}
                            type="button"
                            className="button"
                            onClick={extraAction.action}
                            key={extraAction.label}
                            disabled={extraAction.disabled}
                        >
                            <FormattedMessage id={extraAction.label}/>
                        </Button>
                    ))}
            </StyledActionButtons>
            {!props.removeCloseButton && (
                <Button
                    data-qa="cancel_button"
                    variant={"outline-secondary"}
                    type="button"
                    className="discard-button"
                    onClick={props.onDiscard}
                >
                    <FormattedMessage id="addEditButton.cancel"/>
                </Button>
            )}
        </StyledModalFormButtons>
    );
}

export default AddEditModalButtonGroup;
