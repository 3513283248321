import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Colors from "../assets/Colors";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { IDirtyFormContext, setFormDirty } from "../reducers/contextSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import GenericConfirmationForm from "./GenericConfirmationForm";
import { ReactComponent as OverviewIcon } from "../assets/images/overview.svg";
import { ReactComponent as MonitoringIcon } from "../assets/images/monitoring.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings.svg";
import { ReactComponent as InstructionsIcon } from "../assets/images/instructions.svg";
import { ReactComponent as StatisticsIcon } from "../assets/images/statistics.svg";
import { ReactComponent as CareFacilityMenuIcon } from "../assets/images/careFacilityMenuIcon.svg";
import DropdownNavigation from "../components/common/controls/DropdownNavigation";
import { CareFacility } from "../models/CareFacility";

const backgroundColor = Colors.white.clearWhite;

const StyledNavbar = styled.div`
    background-color: ${backgroundColor};
    height: 53px;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 15px;
    border-bottom: 1px inset #E0E0E0;
`;

export const StyledNavbarItem = `
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const StyledLink = styled.div<any>`
    ${StyledNavbarItem}
    ${(props: any) => props.id === "mainNav.instructions" ?
        css`
            margin-left: auto;
            margin-right: 0;
        `:
        "margin-left: 30px;"
    }
    border-bottom: ${(props: any) => props.active ? `4px solid ${Colors.blue.brandingDark}` : "4px solid " + backgroundColor};
    color: ${Colors.blue.brandingDark};
    &:hover {
        text-decoration: none;
        border-bottom: 4px solid ${Colors.blue.brandingDark};
    }
    ${(props: any) => props.hide && css`
        display: none;
    `}
`;

const DropdownContainer = styled.div<any>`
    ${(props: any) => !props.selectedNH &&
        css`
            margin-left: auto;
        `
    }    
`;

export type LinkData = {
    id: string,
    qaId: string,
    to: string,
    subLinks?: LinkData[],
};

interface IProps {
    linksData: LinkData[];
    hideButtons?: boolean;
}

const Navbar = (props: IProps) => {
    const [windowLocationPathname, setWindowLocationPathname] = useState(window.location.pathname);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [navigationPath, setPath] = useState<string>("");
    const dirtyFormContext = useAppSelector<IDirtyFormContext>(
        (state) => state.contextSlice.dirtyFormContext
    );
    const selectedCareFacility = useAppSelector<CareFacility | null>((state) => state.nursingHomeSlice.nursingHome);
    const confirmationModalWhiteList: [string] = [routePaths.careRecipient];

    React.useEffect(() => {
        setWindowLocationPathname(location.pathname);
    }, [location]);

    const handleNavigate = (path: string) => {
        setWindowLocationPathname(path);
        navigate(path);
        setConfirmationModal(false);
        if (dirtyFormContext.isDirty) {
            dispatch(setFormDirty(false));
        }
        if (dirtyFormContext.callback) {
            dirtyFormContext.callback();
        }
    };

    const onNavigationButtonClick = (path: string) => {
        const navigatesToSamePage = path === window.location.pathname && !confirmationModalWhiteList.includes(path);
        if (!navigatesToSamePage) {
            if (dirtyFormContext.isDirty) {
                setConfirmationModal(true)
                setPath(path);
            } else {
                handleNavigate(path);
            }
        }
    };

    const getIcon = (id: string) => {
        switch (id) {
            case "mainNav.home":
                return <OverviewIcon style={{ marginRight: "10px" }} />;
            case "mainNav.monitoring":
                return <MonitoringIcon style={{ marginRight: "10px" }} />;
            case "mainNav.statistics":
                return <StatisticsIcon style={{ marginRight: "10px" }} />;
            case "mainNav.instructions":
                return <InstructionsIcon style={{ marginRight: "10px" }} />;
            case "mainNav.careFacility":
                return <CareFacilityMenuIcon style={{ marginRight: "10px" }} />;
            default:
                return null;
        }
    }

    return (
        <>
            <StyledNavbar>
                {
                    !props.hideButtons && props.linksData.map(linkData => {
                        const active = linkData.to.length > 1 ? windowLocationPathname.startsWith(linkData.to) : windowLocationPathname === linkData.to;
                        if (linkData.id === "mainNav.settings" && linkData.subLinks) {
                            return <DropdownContainer key={linkData.id} selectedNH={selectedCareFacility}>
                                <DropdownNavigation
                                    label={localizer("mainNav.settings")}
                                    icon={<SettingsIcon style={{ marginRight: "10px" }} />}
                                    windowLocationPathname={windowLocationPathname}
                                    active={windowLocationPathname.startsWith(routePaths.settings)}
                                    key={linkData.id}
                                    subMenus={linkData.subLinks}
                                    onNavigationButtonClick={onNavigationButtonClick}
                                />
                            </DropdownContainer>
                        }
                        return (
                            <StyledLink
                                id={linkData.id}
                                data-qa={"navbar-" + linkData.qaId}
                                key={linkData.id}
                                onClick={() => onNavigationButtonClick(linkData.to)}
                                active={active}
                                hide={!selectedCareFacility && linkData.id === "mainNav.instructions"}
                            >
                                {getIcon(linkData.id)}
                                <FormattedMessage id={linkData.id} />
                            </StyledLink>
                        );
                    })
                }
            </StyledNavbar>
            {confirmationModal && (
                <ModalWrapper
                    closeCallback={() => {
                        setConfirmationModal(false);
                        setPath("");
                    }}
                    isOpen={confirmationModal}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={dirtyFormContext.popupMessageId}
                            confirmButton={dirtyFormContext.popupConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmationModal(false);
                                setPath("");
                            }}
                            onConfirm={() => handleNavigate(navigationPath)}
                        />
                    }
                    modalTitle={localizer(dirtyFormContext.popupTitleId)}
                    size={EModalSize.XS}
                />)}
        </>
    );

};

export default Navbar;