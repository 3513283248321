import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useState } from "react";
import { Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { appInsights } from "../../../AppInsights";
import { RestClient } from "../../../data/RestClient";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import CareGroup from "../../../models/CareGroup";
import ErrorCodes from "../../../models/ErrorCodes";
import ToastMessages from "../../../models/ToastMessages";
import { careGroupToReqBody } from "../../../models/helpers/RequestHelpers";
import { deleteCareGroup, fetchCareGroups } from "../../../reducers/careGroupSlice";
import { IContextStateType } from "../../../reducers/contextSlice";
import { ETrackEvent, parseErrorToString } from "../../../utils/AppInsightsHelpers";
import { adminRoleCountries } from "../../../utils/CountryHelpers";
import { getLocalizedCountry, localizer } from "../../../utils/Localizer";
import { toastError, toastSuccess } from "../../../utils/Toaster";
import AddEditModalButtonGroup from "../../../views/Buttons/AddEditModalButtonGroup";
import DropdownButtonNew from "../../common/controls/DropdownButtonNew";
import TextFieldNew from "../../common/controls/TextFieldNew";
import ConfirmDeleteForm from "../../common/forms/ConfirmDeleteForm";
import ValidatedFormGroupWrapper from "../../wrappers/ValidatedFormGroupWrapper";

const StyledFormContainer = styled.div`
    width: 100%;
`;

enum Fields {
    Name = "Name",
    CustomerId = "CustomerId",
    Country = "Country"
}

const CareGroupEditForm = (props: any) => {
    const onDiscard = (event: any) => {
        event.preventDefault();
        props?.hideModal();
    };
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const careGroups = useAppSelector<CareGroup[]>(state => state.careGroupSlice.careGroups);


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [careGroupEntity, setCareGroupEntity] = useState<CareGroup>(props.entity || new CareGroup());
    const [validationErrors, setErrors] = useState<string[]>([]);

    const isEdit = !!props.entity;
    const dispatch = useAppDispatch();

    const validate = (): Promise<boolean> => {
        const errors = [];

        if (!careGroupEntity.name) {
            errors.push(ErrorCodes.EmptyNameError);
        }

        if (!careGroupEntity.customerId) {
            errors.push(ErrorCodes.EmptyNursingHomeGroupCustomerId);
        }

        if (!careGroupEntity.countryId) {
            errors.push(ErrorCodes.CountryEmptyCodeError);
        }

        const duplicateNameFound = careGroups.some(careGroup => careGroup.name?.toLowerCase() === careGroupEntity.name?.toLowerCase() && careGroup.countryId === careGroupEntity.countryId && careGroup.id !== careGroupEntity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.NursingHomeGroupDuplicateNameError);
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const sendCareGroup = async () => {
        if (isEdit) {
            try {
                const requestBody = careGroupToReqBody(careGroupEntity);
                await RestClient.updateCareGroup(requestBody);
                dispatch(fetchCareGroups());
                toastSuccess(localizer(ToastMessages.UpdateCareGroupSuccessful));
                props?.hideModal();
                appInsights.trackEvent({ name: ETrackEvent.NursingHomeGroupPageUpdate });
            } catch (error: any) {
                toastError(localizer(ToastMessages.UpdateCareGroupError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }

        } else {
            try {
                const requestBody = careGroupToReqBody(careGroupEntity);
                await RestClient.createCareGroup(requestBody);
                toastSuccess(localizer(ToastMessages.CreateCareGroupSuccessful));
                props?.hideModal();
                dispatch(fetchCareGroups());
                appInsights.trackEvent({ name: ETrackEvent.NursingHomeGroupPageAdd });
            } catch (error: any) {
                toastError(localizer(ToastMessages.CreateCareGroupError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }
        }
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendCareGroup();
    };

    const onConfirmCancel = (event: any) => {
        event.preventDefault();
        setShowDeleteConfirmation(false);
    };

    const onDeleteConfirm = async (event: any) => {
        event.preventDefault();
        dispatch(deleteCareGroup(props.entity.id));
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    const onDelete = (event: any) => {
        event.preventDefault();
        if (props.entity.id) {
            setShowDeleteConfirmation(true);
        }
        appInsights.trackEvent({ name: ETrackEvent.NursingHomeGroupPageDelete });
    };

    const handleChange = (name: string, value: any) => {
        const updatedCareGroup = { ...careGroupEntity };

        switch (name) {
            case Fields.Name:
                updatedCareGroup.name = value;
                break;
            case Fields.CustomerId:
                updatedCareGroup.customerId = value;
                break;
            case Fields.Country:
                updatedCareGroup.countryId = Number(value);
                break;
        }

        setCareGroupEntity(updatedCareGroup);
    };

    const filteredCountries = adminRoleCountries(context.userResourcesHierarchy);

    const countryOptions = filteredCountries.map((country) => (
        {
            label: getLocalizedCountry(country.name) || country.name,
            value: country.id.toString()
        }
    )) || [];
    const deleteConfirmation = (
        <ConfirmDeleteForm
            title={props.entity?.name}
            onConfirmCancel={onConfirmCancel}
            onDeleteConfirm={onDeleteConfirm}
        />
    );
    const form = (
        <StyledFormContainer>
            <Form>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNameError, ErrorCodes.NursingHomeGroupDuplicateNameError]}
                        label={"form.nursingHomeGroup.name"}
                    >
                        <TextFieldNew
                            fieldName={Fields.Name}
                            value={careGroupEntity.name}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyNursingHomeGroupCustomerId]}
                        label={"form.nursingHomeGroup.customerId"}
                    >
                        <TextFieldNew
                            fieldName={Fields.CustomerId}
                            value={careGroupEntity.customerId}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="row g-0">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.CountryEmptyCodeError]}
                        label={"constants.country"}
                    >
                        <DropdownButtonNew
                            id={Fields.Country}
                            name={Fields.Country}
                            disabled={isEdit}
                            showSelectedAsDisabledMessage={isEdit}
                            handleChange={(name, value) => handleChange(name, value)}
                            options={countryOptions}
                            selectedValue={careGroupEntity.countryId?.toString()}
                            portaling
                            maxWidth={658}
                        />
                    </ValidatedFormGroupWrapper>
                </Row>
                <Row className="g-0 mt-3">
                    <AddEditModalButtonGroup
                        isEdit={isEdit}
                        onSave={onSave}
                        onDiscard={onDiscard}
                        extraActions={isEdit ? [{
                            label: "constants.delete",
                            action: onDelete
                        }] : []} />
                </Row>
            </Form>
        </StyledFormContainer >
    );

    return showDeleteConfirmation ? deleteConfirmation : form;
};

export default CareGroupEditForm;
