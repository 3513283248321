import styled from "styled-components";
import {Button} from 'react-bootstrap';
import Colors from '../assets/Colors';

interface IStyledButtonProps {
    sidesize?: number;
}

export const StyledAddButton = styled(Button)<IStyledButtonProps>`
    position: relative;
    color: ${Colors.white.clearWhite};
    background-color: ${Colors.blue.brandingDark};
    padding: 10px;
    min-width: unset;
    ${(props) => props.sidesize && `
        width: ${props.sidesize}px;
        height: ${props.sidesize}px;
    `};
    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 50%;
        height: 4px;
        background-color: #fff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(90deg);
    }

    &:disabled {
        opacity: 0.8;
    }
`;

export const StyledInlineSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const StyledInlineFlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;
