import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { appInsights, reactPlugin } from "../AppInsights";
import DropdownDateTimeRangeButton from "../components/common/controls/dateTime/helpers/DropdownDateTimeRangeButton";
import { PickerType } from "../components/common/controls/dateTime/helpers/PickerType";
import Expander from "../components/common/controls/Expander";
import MultiSelectionFieldNew, { IOption } from "../components/common/controls/MultiSelectionFieldNew";
import { StyledViewContainer } from "../components/common/View";
import NightTimeTable from "../components/themed/home/NightTimeTable";
import ProductChangesTable from "../components/themed/home/ProductChangesTable";
import ResponseTimeTable from "../components/themed/home/ResponseTimeTable";
import { RestClient } from "../data/RestClient";
import { useAppSelector } from "../hooks/useAppSelector";
import { DateFilter } from "../models/DateFilter";
import { DateTimeUtils } from "../models/DateTimeUtils";
import OverviewStatisticsResponse from "../models/OverviewStatisticsResponse";
import { Ward } from "../models/Ward";
import { selectActiveWards } from "../selectors/selectors";
import { ETrackEvent, parseErrorToString } from "../utils/AppInsightsHelpers";
import { localizer } from "../utils/Localizer";
import InformationBlock from "../views/InformationBlock/InformationBlock";
import {
    CollapsibleContainer,
    Description, DropdownContainer,
    ExpanderContainer,
    NoDataInfoContainer,
    TabButton, TabButtonContainer, TabContainer, TableContainer, Title, TitleContainer
} from "./HomeViewStyle";

const HomeView = () => {
    const rowHeight = 50;
    const expanderHeight = 55;
    const tabMenuHeight = 55;
    const headerHeight = 15;
    const wards = useAppSelector<Ward[]>(selectActiveWards);

    const [overviewStatisticsResponse, setOverviewStatisticsResponse] = useState<OverviewStatisticsResponse>(new OverviewStatisticsResponse({}));
    const [productChangesExpandStatus, setProductChangesExpandStatus] = useState<boolean>(false);
    const [responseTimeExpandStatus, setResponseTimeExpandStatus] = useState<boolean>(false);
    const [nightTimeExpandStatus, setNightTimeExpandStatus] = useState<boolean>(false);
    const [selectedWards, setSelectedWards] = useState<IOption[]>([]);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<DateFilter>(DateFilter.Days3);

    const productChangesRef = useRef<null | HTMLDivElement>(null);
    const responseTimeRef = useRef<null | HTMLDivElement>(null);
    const nightTimeRef = useRef<null | HTMLDivElement>(null);
    const { ref: pcRef, inView: isProductChangesInView } = useInView({ threshold: 1 });
    const { ref: rtRef, inView: isResponseTimeInView } = useInView({ threshold: 1 });
    const { ref: ntRef, inView: isNightTimeInView } = useInView({ threshold: 1 });

    useEffect(() => {
        const fetchData = async () => {
            const { startDate, endDate } = DateTimeUtils.getDateRange(selectedTimeFrame, moment(), moment());

            try {
                if (wards.length !== 0) {
                    const response: any = await RestClient.getOverviewStatistics(
                        startDate,
                        endDate)
                    setOverviewStatisticsResponse(new OverviewStatisticsResponse(response));
                    const { wardsWithData } = response;
                    const selectedWards = wards.filter(w => wardsWithData.find((wardWithData: any) => wardWithData.id.toString() === w.id)).map(ward => ({
                        label: ward.name,
                        value: ward.id,
                    }))
                    setSelectedWards(selectedWards);
                }
            } catch (error: any) {
                console.error(error)
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }
        }
        fetchData();
    }, [wards, selectedTimeFrame]);

    const setPcRefs = useCallback(
        (node: any) => {
            productChangesRef.current = node;
            pcRef(node);
        },
        [pcRef],
    );

    const setRtRefs = useCallback(
        (node: any) => {
            responseTimeRef.current = node;
            rtRef(node);
        },
        [rtRef],
    );

    const setNtRefs = useCallback(
        (node: any) => {
            nightTimeRef.current = node;
            ntRef(node);
        },
        [ntRef],
    );

    const { wardsWithData, residents } = overviewStatisticsResponse;

    //calculating maximum height so we can have the fluid animation when showing all/less, need exact height because height:auto doesn't work
    const maxHeight = (residents.length * rowHeight) + expanderHeight + headerHeight;

    const handleWardSelection = (_: string, selectedWardsId: any) => {
        const selectedWards = selectedWardsId.map((id: string) => wards.find(ward => ward.id === id)).map((w: any) => ({
            label: w.name,
            value: w.id,
        }));
        setSelectedWards(selectedWards);
        appInsights.trackEvent({ name: ETrackEvent.HomeWardFiltering })
    }

    const filteredResidents = residents.filter((resident: any) => selectedWards.map(w => w.value).includes(resident.wardId.toString()));
    const options = wards.map(ward => {
        const disabled = !wardsWithData.find((wardWithData: any) => wardWithData.id.toString() === ward.id);
        return {
            label: ward.name,
            value: ward.id,
            disabled,
            extraMessage: disabled ? localizer("home.ward.noData") : undefined
        }
    });

    const timeFrameOptions = [DateFilter.Today, DateFilter.Days3, DateFilter.Week];

    return (
        <StyledViewContainer>
            <DropdownContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.ward"
                    width={300}
                >
                    <MultiSelectionFieldNew
                        name={""}
                        handleChange={handleWardSelection}
                        options={options}
                        selectedValue={selectedWards}
                        translations={{
                            allOptionsSelected: localizer("statistics.chart.allWards"),
                            multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                            noOptions: localizer("statistics.chart.noWards")
                        }}
                        maxHeight={500}
                        portalingRequired={true}
                    />
                </InformationBlock>
                <InformationBlock
                    title="home.timePeriod"
                    width={300}>
                    <DropdownDateTimeRangeButton
                        offsetValue={0}
                        qaId="time_period_dropdown"
                        id={"home_page"}
                        name={"home_page"}
                        handleChange={(_name, value: any) => {
                            appInsights.trackEvent({ name: ETrackEvent.HomeTimePeriodFiltering, properties: { selectedDateFilter: Number(value) } })
                            setSelectedTimeFrame(Number(value))
                        }}
                        selectedValue={selectedTimeFrame}
                        options={DateTimeUtils.getDateFilterOptions(timeFrameOptions)}
                        amountOfDays={7}
                        pickerType={PickerType.Date}
                        disableCustomRange={true}
                    />
                </InformationBlock>
            </DropdownContainer>
            <TabButtonContainer>
                <TabButton active={isProductChangesInView} onClick={() => window.scrollTo({ top: (productChangesRef?.current?.offsetTop ?? 0) - tabMenuHeight, behavior: "smooth" })}>
                    {localizer("home.productChanges.title")}
                </TabButton>
                <TabButton active={isResponseTimeInView && !isProductChangesInView} onClick={() => window.scrollTo({ top: (responseTimeRef?.current?.offsetTop ?? 0) - tabMenuHeight, behavior: "smooth" })}>
                    {localizer("home.responseTime.title")}
                </TabButton>
                <TabButton active={isNightTimeInView && !isResponseTimeInView && !isProductChangesInView} onClick={() => window.scrollTo({ top: (nightTimeRef?.current?.offsetTop ?? 0) - tabMenuHeight, behavior: "smooth" })}>
                    {localizer("home.nightTime.title")}
                </TabButton>
            </TabButtonContainer>
            <TabContainer>
                <CollapsibleContainer ref={setPcRefs} expandStatus={productChangesExpandStatus}>
                    <TitleContainer>
                        <Title>{localizer("home.productChanges.title")}</Title>
                        <Description>{localizer("home.productChanges.subTitle")}</Description>
                    </TitleContainer>
                    <TableContainer dataLength={filteredResidents.length} maxHeight={maxHeight} expandStatus={productChangesExpandStatus}>
                        <ProductChangesTable data={filteredResidents} timeSpan={selectedTimeFrame} />
                        {filteredResidents.length === 0 && (<NoDataInfoContainer>{localizer("home.tableView.noData")}</NoDataInfoContainer>)}
                    </TableContainer>
                    <ExpanderContainer>
                        {filteredResidents.length > 3 && (
                            <Expander
                                expandStatus={productChangesExpandStatus}
                                openMessage={localizer("home.tableView.showAll")}
                                closeMessage={localizer("home.tableView.showLess")}
                                onClick={() => { setProductChangesExpandStatus(!productChangesExpandStatus) }} />)}
                    </ExpanderContainer>
                </CollapsibleContainer>
                <CollapsibleContainer ref={setRtRefs} expandStatus={responseTimeExpandStatus}>
                    <TitleContainer>
                        <Title>{localizer("home.responseTime.title")}</Title>
                        <Description>{localizer("home.responseTime.subTitle")}</Description>
                    </TitleContainer>
                    <TableContainer dataLength={filteredResidents.length} maxHeight={maxHeight} expandStatus={responseTimeExpandStatus}>
                        <ResponseTimeTable data={filteredResidents} />
                        {filteredResidents.length === 0 && (<NoDataInfoContainer>{localizer("home.tableView.noData")}</NoDataInfoContainer>)}
                    </TableContainer>
                    <ExpanderContainer>
                        {filteredResidents.length > 3 && (
                            <Expander
                                expandStatus={responseTimeExpandStatus}
                                openMessage={localizer("home.tableView.showAll")}
                                closeMessage={localizer("home.tableView.showLess")}
                                onClick={() => { setResponseTimeExpandStatus(!responseTimeExpandStatus) }} />
                        )
                        }
                    </ExpanderContainer>
                </CollapsibleContainer>
                <CollapsibleContainer ref={setNtRefs} expandStatus={nightTimeExpandStatus}>
                    <TitleContainer>
                        <Title>{localizer("home.nightTime.title")} </Title>
                        <Description>{localizer("home.nightTime.subTitle")}</Description>
                    </TitleContainer>
                    <TableContainer dataLength={filteredResidents.length} maxHeight={maxHeight} expandStatus={nightTimeExpandStatus}>
                        <NightTimeTable data={filteredResidents} />
                        {filteredResidents.length === 0 && (<NoDataInfoContainer>{localizer("home.tableView.noData")}</NoDataInfoContainer>)}
                    </TableContainer>
                    <ExpanderContainer>
                        {filteredResidents.length > 3 && (
                            <Expander
                                expandStatus={nightTimeExpandStatus}
                                openMessage={localizer("home.tableView.showAll")}
                                closeMessage={localizer("home.tableView.showLess")}
                                onClick={() => { setNightTimeExpandStatus(!nightTimeExpandStatus) }} />
                        )
                        }
                    </ExpanderContainer>
                </CollapsibleContainer>
            </TabContainer>
        </StyledViewContainer>
    )
}

export default withAITracking(reactPlugin, HomeView);