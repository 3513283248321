import { StyledLeft } from './PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;
    const formattedArray = value?.join(', ');
    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={formattedArray} hideTooltipOnProd={hideTooltipOnProd}>
                {formattedArray}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const ArrayFormatter = (data: any) => <CellFormatter data={data} />;
export default ArrayFormatter;
