import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import infoIcon from "../../../../assets/images/info.svg";
import { CustomerAdUserManagementFlow } from "../../../../models/ECustomerAdUserManagementFlow";
import { ExternalUserType } from "../../../../models/EExternalUserType";
import ErrorCodes from "../../../../models/ErrorCodes";
import User from "../../../../models/ExternalUser";
import { Roles } from "../../../../models/Role";
import { IUserResourcesHierarchy } from "../../../../reducers/contextSlice";
import { availableLanguages, localizer } from "../../../../utils/Localizer";
import { SectionHeader } from "../../../common/View";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import MultiSelectionFieldNew from "../../../common/controls/MultiSelectionFieldNew";
import PasswordField from "../../../common/controls/PasswordField";
import RadioButtons, { IRadioEntry } from "../../../common/controls/RadioButtons";
import TextField from "../../../common/controls/TextFieldNew";
import { tooltipId } from "../../../common/tooltip/Tooltip";
import ValidatedFormGroupWrapper from "../../../wrappers/ValidatedFormGroupWrapper";
import CareFacilitiesScrollableContainer from "./CareFacilitiesScrollableContainer";
import LoginMethodInformationModal from "./LoginMethodInformationModal";
import SupportExpirationDateCard from "./SupportExpirationDateCard";
import { Divider, ExpirationTimesContainer, InfoContainer, InputContainer, SectionContainer, SectionInfo, StyledImg, TooltipContentContainer } from "./UserEditFormStyle";

export enum Fields {
    Email = "Email",
    Password = "Password",
    Mobile = "Mobile",
    Language = "Language",
    Username = "Username",
    Role = "Role",
    LoginMethod = "LoginMethod",
    AssignedResources = "AssignedResources",
    ExpirationDates = "ExpirationDates",
    AssignedWards = "AssignedWards"
}

export enum Context {
    Add,
    Edit,
    View
}

const getTooltipIconMessage = (isWeakAuthenticationAllowed: boolean, formContext: Context) => {
    return (<TooltipContentContainer>
        {formContext === Context.Edit &&
            (<>
                <b>
                    {localizer("users.editUser.passwordInfo")}
                </b>
                <br />
                <br />
            </>)
        }
        {isWeakAuthenticationAllowed ? localizer("account.passwordRequirementsWeak") : (
            <>
                <div>
                    {localizer("account.passwordRequirements")}
                </div>
                <br />
                <ul>
                    <li>
                        {localizer("account.lowerCase")}
                    </li>
                    <li>
                        {localizer("account.upperCase")}
                    </li>
                    <li>
                        {localizer("account.number")}
                    </li>
                    <li>
                        {localizer("account.specialCharacter")}
                    </li>
                </ul>
                <br />
                {localizer("account.cannotReuseCurrentPassword")}
            </>
        )}
    </TooltipContentContainer >)
};

interface IProps {
    children: ReactNode;
    onSubmit: (event: any) => Promise<void>;
    radioRoleValues: IRadioEntry[];
    setShowInfoPopup: (value: boolean) => void;
    validationErrors: string[];
    allNHResources: IUserResourcesHierarchy[];
    user: User;
    initialUserState?: User;
    handleChange: (name: string, value: any) => void;
    formContext: Context;
    formDisabled?: boolean;
}

const UserExternalEditForm = (props: IProps) => {
    const [showLoginMethodPopup, setShowLoginMethodPopup] = useState<boolean>(false);
    const {
        children,
        onSubmit,
        allNHResources,
        radioRoleValues,
        validationErrors,
        setShowInfoPopup,
        user, handleChange,
        formContext,
        formDisabled,
        initialUserState
    } = props;
    const defaultNh = allNHResources && allNHResources.length > 0 ? allNHResources[0] : undefined;
    const [isWeakAuthenticationAllowed, setIsWeakAuthenticationAllowed] = useState<boolean>((defaultNh && defaultNh.isWeakAuthenticationAllowed) ?? false);
    const [enforceEmailForCaregiver, setEnforceEmailForCaregiver] = useState<boolean>((defaultNh && defaultNh.enforceEmailForCaregiver) ?? false);
    const [radioLoginMethodValues, setRadioLoginMethodValues] = useState<IRadioEntry[]>([]);
    const { assignedResourceIds, role: selectedRole } = user;

    useEffect(() => {
        if (selectedRole === Roles.Caregiver) {
            if (assignedResourceIds.length > 0) {
                setIsWeakAuthenticationAllowed(allNHResources.some(nh => nh.id === assignedResourceIds[0] && nh.isWeakAuthenticationAllowed));
                setEnforceEmailForCaregiver(allNHResources.some(nh => nh.id === assignedResourceIds[0] && nh.enforceEmailForCaregiver));
            } else {
                handleChange(Fields.AssignedResources, [defaultNh?.id]);
            }
        }
    }, [allNHResources, assignedResourceIds, defaultNh?.id, handleChange, selectedRole]);

    useEffect(() => {
        let loginMethods = [];
        const assignedNhs = allNHResources.filter(nh => assignedResourceIds.includes(nh.id));
        const enableCustomerAdLogin = assignedNhs.length > 0 && assignedNhs.every(nh => nh && nh.customerAdUserManagementFlow === CustomerAdUserManagementFlow.CIUserManagement);
        const customerAdNHSelected = assignedNhs.length > 0 && assignedNhs.some(nh => nh && nh.customerAdUserManagementFlow === CustomerAdUserManagementFlow.CIUserManagement);
        if (formContext === Context.Add) {
            if (selectedRole === Roles.Caregiver) {
                if (enableCustomerAdLogin) {
                    loginMethods.push({ value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated) });
                }
                loginMethods.push({ value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email) })
                if (!enforceEmailForCaregiver) {
                    loginMethods.push({ value: ExternalUserType.Username, label: localizer("user.loginMethod." + ExternalUserType.Username) });
                }
                setRadioLoginMethodValues(loginMethods);
            } else if (selectedRole !== Roles.Support) {
                if (enableCustomerAdLogin) {
                    loginMethods = [
                        { value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated) },
                        { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email) },
                    ]
                    setRadioLoginMethodValues(loginMethods);
                } else {
                    setRadioLoginMethodValues([]);
                }
            } else {
                setRadioLoginMethodValues([]);
            }
        } else if (formContext === Context.Edit) {
            if (selectedRole === Roles.Caregiver) {
                if (enableCustomerAdLogin) {
                    loginMethods = [
                        { value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated) },
                        { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email) },
                        { value: ExternalUserType.Username, label: localizer("user.loginMethod." + ExternalUserType.Username), disabled: user.externalUserType !== ExternalUserType.Username },
                    ]
                    setRadioLoginMethodValues(loginMethods);
                } else if (!enforceEmailForCaregiver) {
                    loginMethods = [
                        { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email), disabled: user.externalUserType !== ExternalUserType.Username && initialUserState?.externalUserType !== ExternalUserType.Username },
                        { value: ExternalUserType.Username, label: localizer("user.loginMethod." + ExternalUserType.Username), disabled: user.externalUserType !== ExternalUserType.Username && initialUserState?.externalUserType !== ExternalUserType.Username}
                    ]
                    setRadioLoginMethodValues(loginMethods);
                } else {
                    if(enforceEmailForCaregiver && initialUserState?.externalUserType === ExternalUserType.Username) {
                        loginMethods = [
                            { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email)},
                            { value: ExternalUserType.Username, label: localizer("user.loginMethod." + ExternalUserType.Username), disabled: true}
                        ]
                        setRadioLoginMethodValues(loginMethods);
                    } else {
                        if(customerAdNHSelected){
                            loginMethods = [
                                { value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated), disabled: true},
                                { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email), disabled: true}
                            ]
                            setRadioLoginMethodValues(loginMethods);
                        } else {
                            setRadioLoginMethodValues([]);
                        }
                    }
                }
            } else if (selectedRole !== Roles.Support) {
                if (enableCustomerAdLogin) {
                    loginMethods = [
                        { value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated) },
                        { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email) }
                    ]
                    setRadioLoginMethodValues(loginMethods);
                } else {
                    if(customerAdNHSelected){
                        loginMethods = [
                            { value: ExternalUserType.Federated, label: localizer("user.loginMethod." + ExternalUserType.Federated), disabled: true},
                            { value: ExternalUserType.Email, label: localizer("user.loginMethod." + ExternalUserType.Email), disabled: true}
                        ]
                        setRadioLoginMethodValues(loginMethods);
                    } else {
                        setRadioLoginMethodValues([]);
                    }
                }
            } else {
                setRadioLoginMethodValues([]);
            }

        }

    }, [allNHResources, assignedResourceIds, formContext, selectedRole, initialUserState?.role, user.externalUserType, enforceEmailForCaregiver, initialUserState?.externalUserType]);

    const usernameNotAllowedAnymore =
        user.externalUserType === ExternalUserType.Username &&
        initialUserState &&
        formContext === Context.Edit &&
        initialUserState.role === Roles.Caregiver &&
        allNHResources.some(nh => nh.id === user.assignedResourceIds[0] && nh.enforceEmailForCaregiver);

    const getWardOptions = (allNHResources: IUserResourcesHierarchy[], assignedResourceIds: number[]) => {
        const selectedNH = allNHResources.find(nh => nh.id === assignedResourceIds[0]);
        if (selectedNH) {
            return selectedNH.children.map(ward => ({ label: ward.name, value: ward.id, isActive: ward.isActive, showActiveMessage: true }));
        }
        return [];
    }

    return (
        <>
            <SectionContainer onSubmit={onSubmit}>
                <SectionHeader>
                    {localizer("users.addUser.selectRole")}
                    {!(initialUserState?.role === Roles.Support && formContext === Context.Edit) && (<StyledImg
                        alt="info"
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={localizer("users.addUser.selectRole.tooltipInfo")}
                        data-tip={localizer("users.addUser.selectRole.tooltipInfo")}
                        src={infoIcon}
                        onClick={() => setShowInfoPopup(true)}
                    />)}
                </SectionHeader>
                <Divider />
                <RadioButtons
                    readOnly={user.readOnly || !user.canEditUserDetails}
                    entries={radioRoleValues}
                    selectedRadio={user.role}
                    setSelectedRadio={(value: any) => handleChange(Fields.Role, value)}
                />
                <SectionHeader>
                    {localizer("users.addUser.careFacilityAccess")}
                </SectionHeader>
                <Divider />
                {user.role === Roles.Caregiver && (<SectionInfo>
                    {localizer("users.addUser.careFacilityAccess.caregiverInfo")}
                </SectionInfo>)
                }
                <CareFacilitiesScrollableContainer
                    allNhResources={allNHResources}
                    selectedIds={assignedResourceIds}
                    selectedRole={selectedRole}
                    onCareFacilitySelect={(assignedResourceIds) => handleChange(Fields.AssignedResources, assignedResourceIds)}
                    disabled={user.readOnly}
                />
                {user.role === Roles.Caregiver && (
                    <InputContainer halfRow>
                        <ValidatedFormGroupWrapper
                            validationErrors={[]}
                            label={"home.wards"}
                        >
                            <MultiSelectionFieldNew
                                name={Fields.AssignedWards}
                                handleChange={(name, wardIds) => handleChange(name, wardIds)}
                                options={getWardOptions(allNHResources, assignedResourceIds)}
                                selectedValue={getWardOptions(allNHResources, assignedResourceIds).filter(opt => user.assignedWardsIds?.includes(opt.value))}
                                translations={{
                                    allOptionsSelected: localizer("statistics.chart.allWards"),
                                    multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                                    noOptions: localizer("statistics.chart.noWards")
                                }}
                                maxHeight={500}
                                showActiveMessage={true}
                            />
                        </ValidatedFormGroupWrapper>

                    </InputContainer>
                )}
                <SectionHeader>
                    {localizer("users.addUser.generalSettings")}
                </SectionHeader>
                <Divider >
                    <InfoContainer>
                        * {" " + localizer("users.addUser.generalSettings.requiredField")}
                    </InfoContainer>
                </Divider>
                {(user.role === Roles.Caregiver && (formContext === Context.Edit || !enforceEmailForCaregiver)) && (
                    <>
                        {
                            usernameNotAllowedAnymore && user.role === Roles.Caregiver && (
                                <SectionInfo>
                                    {localizer("users.editUser.usernameNoLongerAllower")}
                                </SectionInfo>)
                        }

                    </>
                )}
                {
                    radioLoginMethodValues.length > 1 && (
                        <>
                            <SectionInfo>
                                {localizer("users.addUser.generalSettings.selectLoginMethod")}
                                <StyledImg
                                    alt="info"
                                    data-tooltip-id={tooltipId}
                                    data-tooltip-content={localizer("users.selectLoginMethod.tooltipInfo")}
                                    data-tip={localizer("users.selectLoginMethod.tooltipInfo")}
                                    src={infoIcon}
                                    onClick={() => setShowLoginMethodPopup(true)}
                                />
                            </SectionInfo>
                            <RadioButtons
                                readOnly={user.readOnly}
                                entries={radioLoginMethodValues}
                                selectedRadio={user.externalUserType}
                                setSelectedRadio={(value: any) => handleChange(Fields.LoginMethod, value)}
                            />
                        </>
                    )
                }
                {user.role === Roles.Support ? (<>
                    <InputContainer halfRow>
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmail, ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists, ErrorCodes.AccountDoesNotExistInAD]}
                            label={"loginView.email"}
                            required
                        >
                            <TextField
                                disabled={user.readOnly || (initialUserState?.role === Roles.Support && formContext === Context.Edit) || !user.canEditUserDetails}
                                placeholder="name@email.com"
                                fieldName={Fields.Email}
                                value={user.email}
                                onChange={(name, value: any) => handleChange(name, value)}
                            />
                        </ValidatedFormGroupWrapper>
                    </InputContainer>
                    <SectionInfo>
                        {localizer("users.addUser.generalSettings.supportInfo")}:
                    </SectionInfo>
                    <ExpirationTimesContainer>
                        {
                            user.assignedResourceIds.map((id) => {
                                const nhName = allNHResources.find(nh => nh.id === id)?.name;
                                return <SupportExpirationDateCard
                                    date={user.supportUserNursingHomes ? moment(user.supportUserNursingHomes.find(nh => nh.nursingHomeId === id)?.expirationDateTime ?? moment().add(1, "days")) : moment().add(1, "days")}
                                    key={id}
                                    nhId={id}
                                    nhName={nhName ?? ""}
                                    validationErrors={validationErrors}
                                    onChange={handleChange}
                                    readOnly={user.readOnly}
                                />
                            })
                        }
                    </ExpirationTimesContainer>
                </>) : (
                    <>
                        <InputContainer>
                            {user.role === Roles.Caregiver && user.externalUserType === ExternalUserType.Username ? (
                                <>
                                    <ValidatedFormGroupWrapper
                                        validationErrors={validationErrors}
                                        dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists]}
                                        label={"account.username"}
                                        required
                                    >
                                        <TextField
                                            disabled={usernameNotAllowedAnymore || !user.canEditUserDetails}
                                            placeholder={localizer("account.username")}
                                            fieldName={Fields.Username}
                                            value={user.username}
                                            onChange={(name, value: any) => handleChange(name, value)}
                                        />
                                    </ValidatedFormGroupWrapper>
                                    <ValidatedFormGroupWrapper
                                        validationErrors={validationErrors}
                                        dependentErrorCodes={[ErrorCodes.PasswordUnmeetCriteria]}
                                        label={formContext === Context.Edit ? "users.editUser.password" : "account.password"}
                                        required={formContext === Context.Add}
                                        tooltipIconMessage={getTooltipIconMessage(isWeakAuthenticationAllowed, formContext)}
                                    >
                                        <PasswordField
                                            fieldName={Fields.Password}
                                            disabled={usernameNotAllowedAnymore || !user.canEditUserDetails}
                                            value={user.password}
                                            onChange={(name, value: any) => handleChange(name, value)}
                                        />
                                    </ValidatedFormGroupWrapper>

                                </>
                            ) : (
                                <>
                                    <ValidatedFormGroupWrapper
                                        validationErrors={validationErrors}
                                        dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmail, ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists]}
                                        label={"loginView.email"}
                                        required
                                    >
                                        <TextField
                                            disabled={formDisabled || !user.canEditUserDetails}
                                            placeholder="name@email.com"
                                            fieldName={Fields.Email}
                                            value={user.email}
                                            onChange={(name, value: any) => handleChange(name, value)}
                                        />
                                    </ValidatedFormGroupWrapper>

                                    <ValidatedFormGroupWrapper
                                        validationErrors={validationErrors}
                                        dependentErrorCodes={[ErrorCodes.FaultyMobileNumber]}
                                        label={"account.mobile"}
                                        fullWidthMessage
                                    >
                                        <TextField
                                            disabled={formDisabled || !user.canEditUserDetails}
                                            placeholder="+46 76 123 45 67"
                                            fieldName={Fields.Mobile}
                                            value={user.mobileNumber}
                                            onChange={(name, value: any) => handleChange(name, value)}
                                        />
                                    </ValidatedFormGroupWrapper>
                                </>
                            )}
                        </InputContainer>
                        <InputContainer halfRow>
                            <ValidatedFormGroupWrapper
                                validationErrors={validationErrors}
                                dependentErrorCodes={[]}
                                label={"account.language"}
                                required
                            >
                                <DropdownButtonNew
                                    id="language"
                                    disabled={formDisabled || (usernameNotAllowedAnymore && user.externalUserType === ExternalUserType.Username) || !user.canEditUserDetails}
                                    name={Fields.Language}
                                    selectedValue={user.language}
                                    showSelectedAsDisabledMessage={true}
                                    handleChange={(name, value) => handleChange(name, value)}
                                    options={availableLanguages.map((language) => { return { label: localizer("language." + language.toLowerCase()), value: language.toLowerCase() } })}
                                />
                            </ValidatedFormGroupWrapper>
                        </InputContainer>
                    </>
                )}
                {children}
            </SectionContainer>
            <LoginMethodInformationModal
                showPopup={showLoginMethodPopup}
                setShowPopup={setShowLoginMethodPopup}
                loginMethods={radioLoginMethodValues.map((entry) => entry.value as ExternalUserType)}
            />
        </>
    );
}

export default UserExternalEditForm;
