import { useRef, useState } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import { localizer } from "../../../utils/Localizer";
import { LinkData } from "../../../views/Navbar";
import { StyledContainer, StyledDropdown, StyledDropdownItem, StyledLink } from "./DropdownNavigationStyle";

interface IDropdownNavigationProps {
    icon: JSX.Element;
    label: string;
    subMenus: LinkData[];
    active: boolean;
    windowLocationPathname: string;
    onNavigationButtonClick: (path: string) => void;
}

const DropdownNavigation = (props: IDropdownNavigationProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const settingsMenuRef = useRef(null);
    useClickOutside(settingsMenuRef, () => setIsOpen(false));

    return (
        <StyledContainer>
            <StyledLink
                active={isOpen || props.active}
                onClick={() => setIsOpen(!isOpen)}>
                {props.icon}
                {props.label}
            </StyledLink>
            {isOpen && (
                <StyledDropdown ref={settingsMenuRef}>
                    {props.subMenus.map((subMenu: LinkData) => {
                        const active = props.windowLocationPathname === subMenu.to;
                        return (
                            <StyledDropdownItem
                                active={active}
                                onClick={() => {
                                    setIsOpen(false);
                                    props.onNavigationButtonClick(subMenu.to);

                                }}
                                key={subMenu.id}
                            >
                                {localizer(subMenu.id)}
                            </StyledDropdownItem>
                        )
                    })}
                </StyledDropdown>
            )}
        </StyledContainer>
    );
}

export default DropdownNavigation;