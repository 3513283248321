import { Roles } from "../models/Role";

export const adminRoleCountries = (countryResources: any[]) => {
    return countryResources.filter((country) => {
        // Function to check if roles array contains "GlobalAdmin" or "CountryAdmin"
        const hasAdminRole = (roles: any[]) => roles.includes(Roles.GlobalAdmin) || roles.includes(Roles.CountryAdmin);

        // Recursive function to check children
        const checkChildren = (children: any) => {
            if (!children || children.length === 0)  return true;
            for (const child of children) {
                if (child.type === "NursingHome") {
                    if (hasAdminRole(child.roles)) return true;
                } else if (child.type === "NursingHomeGroup") {
                    if (checkChildren(child.children)) return true;
                }
            }
            return false;
        };

        // Check if the country or its children have admin roles
        return checkChildren(country.children);
    })
} 