import { useEffect, useRef } from "react";
import { matchPath, useNavigate, useSearchParams } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { useAppSelector } from "./useAppSelector";
import { IContextStateType, contextChange, setRedirectUrl, setSelectedResourceName } from "../reducers/contextSlice";
import { useAppDispatch } from "./useAppDispatch";
import { roleRoutePaths, routePaths } from "../utils/PathHelpers";
import { Roles } from "../models/Role";
import { spinnerKeys } from "../reducers/spinnerSlice";
import { LocalStorageKeys } from "../data/Persistence";

export const useContextRedirect = () => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const isSpinnerVisible = useAppSelector<boolean>((state) =>
        state.spinnerSlice.activeSpinners.some((activeSpinner) => activeSpinner === spinnerKeys.global)
    );

    const { id: careFacilityId, allNHResources, role, redirectUrl } = context;

    // Set active care facility in context if user is assigned to only one
    useEffect(() => {
        if (!careFacilityId && allNHResources.length === 1) {
            const careFacility = allNHResources[0];
            dispatch(contextChange(careFacility.id.toString(), role));
            dispatch(setSelectedResourceName(careFacility.name));
        }
    }, [allNHResources, careFacilityId, role, dispatch]);

    // Set active care facility in context if care facility id is supplied in url
    useEffect(() => {
        const urlCareFacilityId = params.get("cfid");
        if (urlCareFacilityId === null || careFacilityId || allNHResources.length === 1) {
            return;
        }
        const careFacility = allNHResources.find(({ id, externalId }) => {
            return externalId === urlCareFacilityId.toLowerCase() || id.toString() === urlCareFacilityId;
        });

        if (careFacility) {
            reactLocalStorage.clear();
            reactLocalStorage.setObject(LocalStorageKeys.ResourceContext, {
                id: careFacility.id.toString(),
                role
            });
            dispatch(contextChange(careFacility.id.toString(), role));
            dispatch(setSelectedResourceName(careFacility.name));
        }
    }, [allNHResources, careFacilityId, dispatch, params, role]);

    // Forget redirectUrl after initial redirects have finished
    const firstLoadDone = useRef(false);
    useEffect(() => {
        if (firstLoadDone.current === false && isSpinnerVisible === false) {
            firstLoadDone.current = true;
            dispatch(setRedirectUrl(null));
        }
    }, [dispatch, isSpinnerVisible]);

    // Initial redirect handling after login or care facility context switch
    useEffect(() => {
        const routeExistsForRole = Boolean(
            roleRoutePaths[role]?.some((path) => {
                return redirectUrl !== null && matchPath(path, redirectUrl as string) !== null;
            })
        );

        if (redirectUrl !== null && routeExistsForRole) {
            navigate(redirectUrl);
        } else if (careFacilityId) {
            if ([Roles.GlobalAdmin, Roles.CountryAdmin].includes(role)) {
                navigate(routePaths.careFacility);
            } else {
                navigate(routePaths.home);
            }
        }
    }, [careFacilityId]);
};
