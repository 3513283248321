import styled from "styled-components";
import Colors from "../assets/Colors";

export const SubMenuContainer = styled.div`
    height: 270px;
    max-width: 200px;
    font-size: 24px;
    line-height: 24px;
    color: ${Colors.blue.brandingDark};
    gap: 24px;
    display: flex;
    flex-direction: column;
    &:hover {
        cursor: pointer;
        color: ${Colors.blue.tenaBlueHover};
        div {
            box-shadow: 0px 4px 20px 0px #00000026;
            background-color: ${Colors.blue.tenaBlueHover};
        }
    }
`;

export const SubMenuIconContainer = styled.div`
    height: 200px;
    width: 200px;
    background-color: ${Colors.blue.brandingDark};
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Submenus = styled.div`
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: center;
`;

export const SubmenuTitle = styled.span`
    text-align: center;
`;
