import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { HelpSection } from "../../../models/HelpSection";
import { ClickableStyledInformationBlockWrapper, EBlockSize } from "./styled";

interface IHelpBlock {
    helpSection: HelpSection;
    titleComponent: JSX.Element;
    infoMessage: string;
    size: EBlockSize;
    isSelected: boolean;
    handleBlockSelection: (helpSection: HelpSection) => void;
}

const StyledDescription = styled.div`
    margin: 1px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    letter-spacing: 0.5px;
    height: 80px;
    color: ${Colors.black};
`;

const HelpBlock = (props: IHelpBlock) => {

    const {
        helpSection,
        size,
        isSelected,
        handleBlockSelection,
        titleComponent,
        infoMessage
    } = props;

    return (
        <ClickableStyledInformationBlockWrapper
            size={size}
            alignItems="start"
            isSelected={isSelected}
            onClick={() => handleBlockSelection(helpSection)}
        >
            <>{titleComponent}</>
            <StyledDescription>
                {infoMessage}
            </StyledDescription>
        </ClickableStyledInformationBlockWrapper>
    )
}

export default HelpBlock;