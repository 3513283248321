import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { ReactComponent as DownArrow } from "../../../assets/images/downArrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/images/upArrow.svg";

const ExpanderButton = styled.div`
    :hover {
        cursor: pointer;
    }
    color: ${Colors.blue.brandingDark};
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
`;

interface IExpanderProps {
    onClick: () => void;
    expandStatus: boolean;
    openMessage: string;
    closeMessage: string;
}

const Expander = (props: IExpanderProps) => (
    <ExpanderButton onClick={() => { props.onClick() }}>
        {props.expandStatus ?
            <>
                {props.closeMessage + " "}
                <UpArrow width={12} stroke={Colors.blue.brandingDark} fill={Colors.blue.brandingDark} />
            </> :
            <>
                {props.openMessage + " "}
                <DownArrow />
            </>}
    </ExpanderButton>
);

export default Expander;