import React, { ReactNode } from "react";
import store from "../store";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { DateFilter } from "../models/DateFilter";
import { DateTimeUtils } from "../models/DateTimeUtils";
import { CareFacility } from "../models/CareFacility";
import { ResidentEventType } from "../models/ResidentEventType";
import ResidentHistory from "../models/ResidentHistory";
import ResidentHistoryPager from "../models/ResidentHistoryPager";
import { TimeFilter } from "../models/TimeFilter";
import {
    setSocketStatus
} from "../reducers/nursingHomeSlice";
import {
    addUpdateResidentInStore,
    deleteResidentInStore,
    updateResidentState
} from "../reducers/residentSlice";
import { addUpdateWardInStore } from "../reducers/wardSlice";
import { RestClient } from "./RestClient";
import SocketClient, { DataType, Method } from "./SocketClient";
import moment, { Moment } from "moment";

interface INurseRepositoryProps {
    children: ReactNode;
}

export interface INursingHomeState {
    getResidentHistory: (
        residentId: string,
        eventType: ResidentEventType,
        dateFilter: DateFilter,
        startDate: Moment,
        endDate: Moment,
        timeFilter: TimeFilter,
        startTime: Moment | null,
        endTime: Moment | null,
        isScrolling: boolean
    ) => Promise<ResidentHistoryPager>;
}

export const NursingHomeContext = React.createContext<INursingHomeState>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getResidentHistory: () => new Promise(() => {}),
});

export const NurseRepository = (props: INurseRepositoryProps) => {
    let residentHistoryPager: ResidentHistoryPager | undefined;

    const dispatch = useAppDispatch();

    const onSocketPayload = (
        method: Method,
        dataType: DataType,
        payload: any
    ) => {
        const nursingHome =
            store.getState().nursingHomeSlice.nursingHome || new CareFacility();

        if (nursingHome) {
            switch (dataType) {
                case DataType.SocketStatus:
                    dispatch(setSocketStatus(payload));
                    break;
                case DataType.WardState:
                    dispatch(addUpdateWardInStore(payload.wardState));
                    break;
                case DataType.ResidentCurrentState:
                    if (method === Method.Update) {
                        dispatch(updateResidentState(payload));
                    }
                    break;
                case DataType.Resident:
                    if (method === Method.New || method === Method.Update) {
                        dispatch(addUpdateResidentInStore(payload));
                    } else if (method === Method.Delete) {
                        dispatch(deleteResidentInStore(payload.id?.toString()));
                    }
                    break;
            }
        }
    };

    const getResidentHistory = (
        residentId: string,
        eventType: ResidentEventType,
        dateFilter: DateFilter,
        selectedStartDate: Moment,
        selectedEndDate: Moment,
        timeFilter: TimeFilter,
        selectedStartTime: Moment | null,
        selectedEndTime: Moment | null,
        isScrolling: boolean
    ): Promise<ResidentHistoryPager> => {
        return new Promise((resolve, reject) => {
            if (
                !residentHistoryPager ||
                !residentHistoryPager.isSameHistory(
                    residentId,
                    eventType,
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate,
                    selectedStartTime,
                    selectedEndTime
                ) ||
                !isScrolling
            ) {
                residentHistoryPager = new ResidentHistoryPager(
                    eventType,
                    residentId,
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate,
                    selectedStartTime,
                    selectedEndTime
                );
            }
            const pager = residentHistoryPager!;
            if (!pager.hasMorePages() || pager.isLoading) {
                resolve(pager);
                return;
            }
            try {
                const nursingHome =
                    store.getState().nursingHomeSlice.nursingHome ||
                    new CareFacility();
                const { startDate, endDate } = DateTimeUtils.getDateRange(
                    dateFilter,
                    selectedStartDate,
                    selectedEndDate
                );

                // we need to adjust the start and end time to the selected start and end date because 
                // in some cases because of night time savings we will have 1 hour offset
                const startTimeToApply = selectedStartTime?.format("HH:mm");
                const adjustedStartTime = moment(selectedStartDate).set({
                    hour: moment(startTimeToApply, 'HH:mm:ss').hour(),
                    minute: moment(startTimeToApply, 'HH:mm:ss').minute(),
                    second: moment(startTimeToApply, 'HH:mm:ss').second()
                })

                const selectedEndTimeToApply = selectedEndTime?.format("HH:mm");
                const adjustedSelectedEndTime = moment(selectedEndDate).set({
                    hour: moment(selectedEndTimeToApply, 'HH:mm:ss').hour(),
                    minute: moment(selectedEndTimeToApply, 'HH:mm:ss').minute(),
                    second: moment(selectedEndTimeToApply, 'HH:mm:ss').second()
                })
                
                const { startTime, endTime } = DateTimeUtils.getTimeRange(
                    nursingHome,
                    timeFilter,
                    adjustedStartTime,
                    adjustedSelectedEndTime
                );
                pager.isLoading = true;
                RestClient.getHistoryLogs(
                    residentId,
                    startDate,
                    endDate,
                    undefined, // we are setting them as undefined because we want to retrieve all entries
                    undefined, // we are setting them as undefined because we want to retrieve all entries
                    eventType,
                    startTime,
                    endTime
                )
                    .then((response: any) => {
                        pager.logSize = response.count;
                        pager.averageResponseTime =
                            response.avgResponseTimeMinutes;
                        pager.changesPerResident = response.productChangesCount;
                        const rawHistoryLogs: any[] =
                            response.residentHistories;
                        const histories = rawHistoryLogs.map(
                            (value) => new ResidentHistory(value)
                        );
                        pager.pages.push(histories);
                        pager.isLoading = false;
                        pager.nextStatusChangedDateTime = response.nextStatusChangedDateTime;
                        resolve(pager);
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            } catch (error: any) {
                pager.logSize = 0;
                resolve(pager);
            }
        });
    };

    return (
        <NursingHomeContext.Provider
            value={{
                getResidentHistory,
            }}
        >
            {<SocketClient onSocketPayload={onSocketPayload} />}
            {props.children}
        </NursingHomeContext.Provider>
    );
};
