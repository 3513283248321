import Prod from "../Prod";
import ProductFamily from "../ProductFamily";
import ProductSize from "../ProductSize";
import { Resident } from "../Resident";

export const getProduct = (productFamily: string, productName: string, productFamilies: ProductFamily[], products: Prod[]) => {
    const productFamilyName = productFamilies.find(x => x.name === productFamily)?.name;
    return products.find((product: Prod) =>
        product.name === productName
        && product.productFamilyName === productFamilyName
    );
};

export const residentHasValidSelectedProduct = (resident: Resident, nightTransmitter: boolean, productFamilies: ProductFamily[]): boolean => {
    if (nightTransmitter) {
        return productFamilies
            .some((productFamily: ProductFamily) => productFamily.name === resident.nightTransmitter.productFamily);
    }
    else {
        return productFamilies
            .some((productFamily: ProductFamily) => productFamily.name === resident.dayTransmitter.productFamily);
    }
};

export const residentHasValidSelectedBrief = (resident: Resident, nightTransmitter: boolean, productFamilies: ProductFamily[], products: Prod[]): boolean => {
    if (!residentHasValidSelectedProduct(resident, nightTransmitter, productFamilies)) {
        return false;
    }

    if (nightTransmitter) {
        return !!getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName, productFamilies, products);

    } else {
        return !!getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName, productFamilies, products);
    }
};

export const residentHasValidSelectedSize = (resident: Resident, nightTransmitter: boolean, productFamilies: ProductFamily[], products: Prod[]): boolean => {
    if (!residentHasValidSelectedProduct(resident, nightTransmitter, productFamilies)) {
        return false;
    }
    if (!residentHasValidSelectedBrief(resident, nightTransmitter, productFamilies, products)) {
        return false;
    }

    if (nightTransmitter) {
        if (resident.nightTransmitter.productName) {
            return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName, productFamilies, products)?.ProductSizes
                .some((productSize: ProductSize) => productSize.name === resident.nightTransmitter.productSize) || false;
        }
        return false;
    }
    else {
        if (resident.dayTransmitter.productName) {
            return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName, productFamilies, products)?.ProductSizes
                .some((productSize: ProductSize) => productSize.name === resident.dayTransmitter.productSize) || false;
        }
        return false;
    }
};

export const getSelectedProductSizeForResident = (resident: Resident, nightTransmitter: boolean, productFamilies: ProductFamily[], products: Prod[]): ProductSize | undefined => {
    if (nightTransmitter) {
        return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName, productFamilies, products)?.ProductSizes
            .find((productSize: ProductSize) => productSize.name === resident.nightTransmitter.productSize);
    }
    else {
        return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName, productFamilies, products)?.ProductSizes
            .find((productSize: ProductSize) => productSize.name === resident.dayTransmitter.productSize);
    }
};

export const areProductsTheSame = (resident: Resident, productFamilies: ProductFamily[], products: Prod[]) => {
    return residentHasValidSelectedSize(resident, true, productFamilies, products) &&
        residentHasValidSelectedSize(resident, false, productFamilies, products) &&
        resident.dayTransmitter.productId === resident.nightTransmitter.productId;
};

export const areStripsLengthTheSame = (resident: Resident, productFamilies: ProductFamily[], products: Prod[]) => {
    return residentHasValidSelectedSize(resident, true, productFamilies, products) &&
        residentHasValidSelectedSize(resident, false, productFamilies, products) &&
        getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip.toUpperCase() ===
        getSelectedProductSizeForResident(resident, false, productFamilies, products)?.strip.toUpperCase();
};
