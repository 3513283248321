import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { appInsights } from "../../../../AppInsights";
import Colors from "../../../../assets/Colors";
import { NursingHomeContext } from "../../../../data/NurseRepository";
import { LocalStorageKeys } from "../../../../data/Persistence";
import { DateFilter } from "../../../../models/DateFilter";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { Resident } from "../../../../models/Resident";
import { ResidentEventType } from "../../../../models/ResidentEventType";
import ResidentHistory from "../../../../models/ResidentHistory";
import { TimeFilter } from "../../../../models/TimeFilter";
import ToastMessages from "../../../../models/ToastMessages";
import { EFilter, ETrackEvent, parseErrorToString } from "../../../../utils/AppInsightsHelpers";
import { localizer } from "../../../../utils/Localizer";
import { toastError } from "../../../../utils/Toaster";
import CheckBox from "../../../common/controls/CheckBox";
import ResidentHistoryGeneralInfo from "../ResidentHistoryGeneralInfo";
import ResidentHistoryTable from "./ResidentHistoryTable";
import { countTimeDifference } from "./formatters/helpers/TimeDifferenceBetweenTwoDates";

interface IResidentHistoryViewProps {
    resident: Resident;
}

const NoDataContainer = styled.div`
    height: 76px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.grey.tenaLight};
    color: ${Colors.black};
    margin-top: -36px;
`;

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: ${Colors.black};
`;

const ResidentHistoryView = (props: IResidentHistoryViewProps) => {
    const context = useContext(NursingHomeContext);
    const { search } = useLocation();
    const [residentHistory, setResidentHistory] = useState<ResidentHistory[]>(
        []
    );
    const [filteredResidentHistory, setFilteredResidentHistory] = useState<ResidentHistory[]>([]);
    const [nextCreatedDate, setNextCreatedDate] = useState<string | null>(null);
    const [averageResponseTime, setAverageResponseTime] = useState<
        number | null
    >(null);
    const [changesPerResident, setChangesPerResident] = useState<number | null>(
        null
    );
    const [selectedEventType, setSelectedEventType] =
        useState<ResidentEventType>(ResidentEventType.All);
    const [selectedDateFilter, setSelectedDateFilter] = useState<DateFilter>(search ? DateFilter.CustomRange : (localStorage.getItem(LocalStorageKeys.ResidentHistoryDateFilter) ? Number(localStorage.getItem(LocalStorageKeys.ResidentHistoryDateFilter)) : DateFilter.Week));
    const [selectedStartDate, setSelectedStartDate] = useState<Moment>(search ? moment(search.replaceAll("?", "")) : moment().add(-6, "days"));
    const [selectedEndDate, setSelectedEndDate] = useState<Moment>(search ? moment(search.replaceAll("?", "")) : moment());
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilter>(
        search ?
            DateTimeUtils.getTimeFilterOptions()[0].value :
            DateTimeUtils.getTimeFilter(localStorage.getItem(LocalStorageKeys.ResidentHistoryTimeFilter) ?? "")
    );
    const [selectedStartTime, setSelectedStartTime] = useState<Moment>(moment("12:00", "HH:mm"));
    const [selectedEndTime, setSelectedEndTime] = useState<Moment>(moment("13:00", "HH:mm"));
    const [showErrorMessages, setShowErrorMessages] = useState<boolean>(true);

    const getCurrentFilterSetup = () => {
        return {
            eventType: selectedEventType,
            dateFilter: selectedDateFilter,
            startDate: selectedStartDate.format("YYYY-MM-DD"),
            endDate: selectedEndDate.format("YYYY-MM-DD"),
            timeFilter: selectedTimeFilter,
            startTime: selectedStartTime.format("HH:mm"),
            endTime: selectedEndTime.format("HH:mm")
        }
    }


    useEffect(() => {
        if (!showErrorMessages) {
            setFilteredResidentHistory(residentHistory.filter((history) => history.bedStatus !== ResidentEventType.Error));
        } else {
            setFilteredResidentHistory(residentHistory);
        }
    }, [residentHistory, showErrorMessages]);

    const calculateTimeInState = (currentObject: any, nextObject: any, nextCreatedDate: any) => {
        const currentCreatedDate = currentObject.created;
        if (currentObject.nextStatusChangedDateTime) {
            return countTimeDifference(currentObject.nextStatusChangedDateTime, currentCreatedDate);
        } else if (nextObject) {
            return countTimeDifference(nextObject.created, currentCreatedDate);
        } else if (nextCreatedDate) {
            return countTimeDifference(moment(nextCreatedDate).toDate(), currentCreatedDate);
        }
        return undefined;
    }

    useEffect(() => {
        const getHistory = async () => {
            try {
                const historyResponse = await context.getResidentHistory(
                    props.resident.id,
                    selectedEventType,
                    selectedDateFilter,
                    selectedStartDate,
                    selectedEndDate,
                    selectedTimeFilter,
                    selectedStartTime,
                    selectedEndTime,
                    false
                )
                if (historyResponse) {
                    const history = historyResponse.getLogs();

                    const historyWithTimeInState = history.map((historyObject, index) => {
                        let nextObject;
                        if (index !== 0) {
                            nextObject = history[index - 1];
                        }
                        return {
                            ...historyObject,
                            timeInStatus: calculateTimeInState(historyObject, nextObject, historyResponse.nextStatusChangedDateTime)
                        };
                    })
                    setResidentHistory(historyWithTimeInState);
                    setFilteredResidentHistory(historyWithTimeInState);
                    setNextCreatedDate(historyResponse.nextStatusChangedDateTime);
                    setChangesPerResident(
                        historyResponse.changesPerResident
                    );
                    setAverageResponseTime(
                        historyResponse.averageResponseTime
                    );
                }
            } catch (error) {
                toastError(localizer(ToastMessages.DataLoadingError));
                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });

            }
        }
        getHistory();
    }, [props.resident.id, selectedEventType, selectedDateFilter, selectedTimeFilter, selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, context]);

    const handleOptionsSelection = (name: string, value: any) => {
        if (name === "dateSelected") {
            setSelectedDateFilter(Number(value));
            localStorage.setItem(LocalStorageKeys.ResidentHistoryDateFilter, value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentHistory,
                properties: {
                    ...getCurrentFilterSetup(),
                    dateFilter: Number(value),
                    filterUsed: EFilter.DateFilter
                }
            });
        }
        if (name === "eventType") {
            setSelectedEventType(value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentHistory,
                properties: {
                    ...getCurrentFilterSetup(),
                    eventType: value,
                    filterUsed: EFilter.EventTypeFilter
                }
            });
        }
        if (name === "timeSelected" &&
            value !== selectedTimeFilter) {
            setSelectedTimeFilter(value);
            localStorage.setItem(LocalStorageKeys.ResidentHistoryTimeFilter, value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentHistory,
                properties: {
                    ...getCurrentFilterSetup(),
                    timeFilter: value,
                    filterUsed: EFilter.TimeFilter
                }
            });
        }
    };

    const startDateChanged = (value: any) => {
        if (selectedDateFilter !== DateFilter.CustomRange) {
            setSelectedStartDate(value);
            setSelectedDateFilter(DateFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentHistoryDateFilter, DateFilter.CustomRange.toString());
        } else {
            setSelectedStartDate(value);
        }
        appInsights.trackEvent({
            name: ETrackEvent.ResidentHistory,
            properties: {
                ...getCurrentFilterSetup(),
                eventType: selectedEventType,
                dateFilter: DateFilter.CustomRange,
                startDate: value.format("YYYY-MM-DD"),
                filterUsed: EFilter.CustomStartDateFilter
            }
        });
    };

    const endDateChanged = (value: any) => {
        if (selectedDateFilter !== DateFilter.CustomRange) {
            setSelectedEndDate(value);
            setSelectedDateFilter(DateFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentHistoryDateFilter, DateFilter.CustomRange.toString());

        } else {
            setSelectedEndDate(value);
        }
        appInsights.trackEvent({
            name: ETrackEvent.ResidentHistory,
            properties: {
                ...getCurrentFilterSetup(),
                dateFilter: DateFilter.CustomRange,
                endDate: value.format("YYYY-MM-DD"),
                filterUsed: EFilter.CustomEndDateFilter
            }
        });
    };

    const startTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedStartTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentHistoryTimeFilter, TimeFilter.CustomRange);
        } else {
            setSelectedStartTime(value);
        }
        appInsights.trackEvent({
            name: ETrackEvent.ResidentHistory,
            properties: {
                ...getCurrentFilterSetup(),
                timeFilter: TimeFilter.CustomRange,
                startTime: value.format("HH:mm"),
                filterUsed: EFilter.CustomStartTimeFilter
            }
        });
    };

    const endTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedEndTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentHistoryTimeFilter, TimeFilter.CustomRange);

        } else {
            setSelectedEndTime(value);
        }
        appInsights.trackEvent({
            name: ETrackEvent.ResidentHistory,
            properties: {
                ...getCurrentFilterSetup(),
                timeFilter: TimeFilter.CustomRange,
                endTime: value.format("HH:mm"),
                filterUsed: EFilter.CustomEndTimeFilter
            }
        });
    };

    return (
        <>
            <ResidentHistoryGeneralInfo
                resident={props.resident}
                averageResponseTime={averageResponseTime}
                changesPerResident={changesPerResident}
                handleOptionSelection={handleOptionsSelection}
                selectedEventType={selectedEventType}
                selectedDateFilter={Number(selectedDateFilter)}
                selectedTimeFilter={selectedTimeFilter}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                selectedStartTime={selectedStartTime}
                selectedEndTime={selectedEndTime}
                startDateChanged={startDateChanged}
                endDateChanged={endDateChanged}
                startTimeChanged={startTimeChanged}
                endTimeChanged={endTimeChanged}
            />
            <RowContainer>
                <CheckBox
                    label={localizer("residentHistory.showErrorMessages")}
                    fieldName="showErrorMessages"
                    value={showErrorMessages}
                    onChange={() => {
                        setShowErrorMessages(!showErrorMessages);
                        appInsights.trackEvent({
                            name: ETrackEvent.ResidentHistoryFilteringCheckbox
                        })
                    }}
                />
                <div>{localizer("constants.table.numberOfRows", { amount: filteredResidentHistory.length })} </div>
            </RowContainer>
            <ResidentHistoryTable nextStatusChangedDateTime={nextCreatedDate} data={filteredResidentHistory} />
            {filteredResidentHistory && filteredResidentHistory.length === 0 && (
                <NoDataContainer>
                    {localizer("resident.history.noData")}
                </NoDataContainer>
            )}
        </>
    );
};

export default ResidentHistoryView;
