import moment from "moment";
import { ExternalUserType } from "./EExternalUserType";
import { Roles } from "./Role";

export const UserRegexp = {
    // Regexp validates that the password that is at least 8 to 64 characters. It requires 3 out of 4 of lowercase, uppercase, numbers, or symbols..
    PasswordRegexp: new RegExp("^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,64}$"),
    // Regexp validates email address format
    EmailRegexp: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
};

export default class ExternalUser {
    id: string;
    username: string;
    email: string;
    role: Roles;
    assignedResourceIds: number[];
    mobileNumber: string;
    password: string;
    language?: string;
    supportUserNursingHomes?: { nursingHomeId: number, expirationDateTime: string }[];
    isActivated?: boolean;
    readOnly: boolean;
    canEditUserDetails: boolean;
    assignedWardsIds?: number[];
    externalUserType: ExternalUserType;

    constructor(json: any = {}) {
        this.id = json.externalId ? String(json.externalId) : "";
        this.role = json.role ? json.role : "";
        this.username = ExternalUserType.Username === json.externalUserType ? json.username : "";
        this.email = ExternalUserType.Username !== json.externalUserType ? json.username : "";
        this.mobileNumber = json.mobileNumber ? json.mobileNumber : "";
        this.password = json.password ? json.password : "";
        this.language = json.languageCode ? String(json.languageCode).toLowerCase() : "";
        this.isActivated = json.isActivated;
        this.readOnly = json.readOnly ?? false;
        this.canEditUserDetails = json.canEditUserDetails ?? true;
        this.externalUserType = json.externalUserType ?? ExternalUserType.Email;
        if (json.nursingHomes && Array.isArray(json.nursingHomes)) {
            const arr = json.nursingHomes as any[];
            this.assignedResourceIds = arr.map(t => Number(t.nursingHomeId));
        } else {
            this.assignedResourceIds = [];
        }
        if (json.role === Roles.Support) {
            if (json.supportUserNursingHomes) {
                this.supportUserNursingHomes = json.supportUserNursingHomes.map((nursingHome: any) => {
                    return {
                        nursingHomeId: nursingHome.nursingHomeId,
                        expirationDateTime: moment.utc(nursingHome.expirationDateTime).local().toISOString()
                    };
                });
            } else {
                this.supportUserNursingHomes = json.nursingHomes.map((nursingHome: any) => {
                    return {
                        nursingHomeId: nursingHome.nursingHomeId,
                        expirationDateTime: moment.utc(nursingHome.expirationDateTime).local().toISOString()
                    };
                });
            }
        }

        if(json.role === Roles.Caregiver) {
            if(json.nursingHomes && Array.isArray(json.nursingHomes)) {
                this.assignedWardsIds = json.nursingHomes.flatMap(
                    (nh:any) => nh.wards
                );
            } else {
                this.assignedWardsIds = [];
            }
        }
    }
}
