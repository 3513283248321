import styled, { css } from "styled-components";
import Colors from "../../../assets/Colors";

export const StyledLink = styled.div<any>`
    height: 52px;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    ${(props: any) => props.id === "mainNav.instructions" ?
        css`
            margin-left: auto;
            margin-right: 0;
        `:
        "margin-left: 30px;"
    }
    border-bottom: ${(props: any) => props.active ? `4px solid ${Colors.blue.brandingDark}` : "4px solid " + Colors.white.clearWhite};
    color: ${Colors.blue.brandingDark};
    &:hover {
        text-decoration: none;
        border-bottom: 4px solid ${Colors.blue.brandingDark};
    }
`;

export const StyledDropdown = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: 224px;
    background-color: ${Colors.white.clearWhite};
    border: 1px solid ${Colors.grey.light};
    transform: translate(-100px, -2px) !important;
    position: absolute !important;
    inset: inherit !important;
    box-shadow: 0px 4px 20px 0px #00000026;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 53px !important;
    width: 130px;
`;

export const StyledDropdownItem = styled.div<any>`
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    color: ${Colors.blue.brandingDark};
    &:hover {
        background-color: ${Colors.grey.light};
        cursor: pointer;
    }
    ${(props: any) => props.active ? css`
        background-color: ${Colors.grey.tenaLight};
        font-weight: bold;
    `: ""}
`;