import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { cloneDeep, isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ActionButtonsContainer, ButtonContainer, PageHeader, StyledButton, StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import UserInternalEditForm, { Context, Fields } from "../components/themed/users/common/UserInternalEditForm";
import { RestClient } from "../data/RestClient";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import ErrorCodes from "../models/ErrorCodes";
import { ICountryListItem } from "../models/ICountryListItem";
import InternalUser from "../models/InternalUser";
import { Roles } from "../models/Role";
import { IContextStateType, IUserResourcesHierarchy, setFormDirty } from "../reducers/contextSlice";
import {
    IUserStateType,
    ResourceTypes,
    deleteInternalUser,
    fetchInternalUsers,
    updateInternalUser
} from "../reducers/userSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { emailRegex, phoneRegex } from "../utils/Regex";
import { toastError } from "../utils/Toaster";
import GenericConfirmationForm from "../views/GenericConfirmationForm";

const disableSaveButton = (initialState: InternalUser | undefined, userEntity: InternalUser | undefined): boolean => {
    if (!initialState || !userEntity) {
        return true;
    }

    if (initialState.email !== userEntity.email) {
        return false;
    }

    if (initialState.mobileNumber !== userEntity.mobileNumber) {
        return false;
    }

    if (initialState.language !== userEntity.language) {
        return false;

    }
    if (initialState.role !== userEntity.role) {
        return false;
    }

    if (userEntity.role === Roles.CountryAdmin && !isEqual(initialState.assignedResourceIds, userEntity.assignedResourceIds) && userEntity.assignedResourceIds.length > 0) {
        return false;
    }
    return true;
}

const EditUserView = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { id } = useParams();
    const [userEntity, setUserEntity] = useState<InternalUser | undefined>(undefined);
    const { users } = useAppSelector<IUserStateType>((state) => state.userSlice);
    const [filterValue, setFilterValue] = useState<string>("");
    const [validationErrors, setErrors] = useState<any[]>([]);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [countries, setCountries] = useState<ICountryListItem[]>([]);
    const [initialState, setInitialState] = useState<InternalUser | undefined>(undefined);
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const leaveFormTitleId="general.leaveTitle";
    const leaveFormMessageId = "general.leaveMessage";
    const leaveFormConfirmTextId = "constants.leave";

    useEffect(() => {
        const fetchUser = async () => {
            if (context.role !== Roles.Invalid) {
                try {
                    const user = await RestClient.getUserInternal(id);
                    const userEntity = new InternalUser(user);
                    setUserEntity(userEntity);
                    const initialState = cloneDeep(userEntity);
                    setInitialState(initialState);
                } catch (error: any) {
                    toastError(localizer(error));
                }
            }
        };
        fetchUser();
    }, [context.role, id]);

    useEffect(() => {
        if (!users.length) {
            dispatch(fetchInternalUsers());
        }
    }, [dispatch, users]);


    const [deleteCofirmation, setDeleteConfirmation] = useState<boolean>(false);

    const handleChange = useCallback((name: string, value: any) => {
        const user = cloneDeep(userEntity) ?? new InternalUser();
        switch (name) {
            case Fields.Email:
                user.email = value;
                break;
            case Fields.Mobile:
                user.mobileNumber = value;
                break;
            case Fields.Language:
                user.language = value;
                break;
            case Fields.Role:
                user.role = value;
                break;
            case Fields.AssignedResources:
                user.assignedResourceIds = value;
                break;
            default:
                break;
        }
        if (!isFormDirty && !isEqual(initialState, user)) {
            dispatch(setFormDirty(true, leaveFormTitleId, leaveFormMessageId, leaveFormConfirmTextId));
        } else if (isFormDirty && isEqual(initialState, user)) {
            dispatch(setFormDirty(false));
        }
        setUserEntity(user);
    }, [userEntity]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }

        try {
            await submitInternalRole();
            dispatch(setFormDirty(false));
            navigate(routePaths.users);
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error?.status === 404) {
                setErrors([ErrorCodes.AccountDoesNotExistInAD]);
            }
        }
    };

    const submitInternalRole = () => {
        if (!userEntity) {
            return;
        }
        const { id, email, role, language, assignedResourceIds, mobileNumber } = userEntity;
        const countriesId = role === Roles.CountryAdmin ? assignedResourceIds : mapCountries(context.userResourcesHierarchy).map(({ id }) => id);
        const resourceType = role === Roles.CountryAdmin ? ResourceTypes.Country : ResourceTypes.Global


        return dispatch(updateInternalUser(id, email.trim(),
            role, resourceType, countriesId, language, mobileNumber
        ));
    }

    useEffect(() => {
        setCountries(mapCountries(context.userResourcesHierarchy));
    }, [context.role, context.userResourcesHierarchy]);

    const mapCountries = (countries: IUserResourcesHierarchy[]) => {
        return countries.map(({ name, id }) => ({ code: name, id }));
    }

    const validate = (): Promise<boolean> => {
        const errors: any[] = [];
        if (userEntity) {
            const { email, mobileNumber } = userEntity;

            if (!email) {
                errors.push(ErrorCodes.FaultyNameErrorEmpty);
            }

            if ((email && email.length > 0) && !emailRegex.test(email.trim().toLowerCase())) {
                errors.push(ErrorCodes.FaultyNameErrorEmail);
            }

            if (users.some(user => user.name.toLocaleLowerCase() === email.toLocaleLowerCase()) && email !== initialState?.email) {
                errors.push(ErrorCodes.UserAlreadyExists);
            }

            if (mobileNumber && mobileNumber.length > 0 && !phoneRegex.test(mobileNumber.trim().toLowerCase())) {
                errors.push({
                    dependentErrorCode: ErrorCodes.FaultyMobileNumber,
                    values: { mobileNumber },
                });
            }
            setErrors(errors);
        }
        return Promise.resolve(errors.length < 1);
    };

    const radioRoleValues = [
        { value: Roles.GlobalAdmin, label: localizer("user.role." + Roles.GlobalAdmin) },
        { value: Roles.CountryAdmin, label: localizer("user.role." + Roles.CountryAdmin) }
    ]

    return (
        <StyledViewContainer>
            <PageHeader>{localizer("users.editUser")}</PageHeader>
            {userEntity !== undefined && (
                <UserInternalEditForm
                    formContext={Context.Edit}
                    user={userEntity}
                    onSubmit={onSubmit}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                    validationErrors={validationErrors}
                    radioRoleValues={radioRoleValues}
                    handleChange={handleChange}
                    initialUserState={initialState}
                    countries={countries}
                >
                    <ButtonContainer>
                        <BackHardButton
                            backRoute={routePaths.users}
                            onClick={() => !isEqual(initialState, userEntity) ? setConfirmLeave(true) : navigate(routePaths.users)}
                        />
                        <ActionButtonsContainer>
                            <StyledButton
                                variant={"outline-secondary"}
                                type={"button"}
                                onClick={() => setDeleteConfirmation(true)}
                            >
                                {localizer("users.deleteUser")}
                            </StyledButton>
                            <StyledButton
                                variant={"primary"}
                                type={"submit"}
                                disabled={disableSaveButton(initialState, userEntity)}
                            >
                                {localizer("constants.save")}
                            </StyledButton>

                        </ActionButtonsContainer>
                    </ButtonContainer>
                </UserInternalEditForm>
            )}
            {deleteCofirmation && (
                <ModalWrapper
                    closeCallback={() => setDeleteConfirmation(false)}
                    isOpen={deleteCofirmation}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"users.deleteUser.confirmation"}
                            confirmButton={"constants.delete"}
                            onConfirmCancel={() => {
                                setDeleteConfirmation(false);
                            }}
                            onConfirm={() => {
                                dispatch(deleteInternalUser(id ?? ""))
                                navigate(routePaths.users);
                                if(isFormDirty){
                                    dispatch(setFormDirty(false));
                                }
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                    modalTitle={localizer("users.deleteUser")}
                    size={EModalSize.XS}
                />
            )}
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={leaveFormMessageId}
                            confirmButton={leaveFormConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                navigate(routePaths.users);
                            }}
                        />
                    }
                    modalTitle={localizer(leaveFormTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </StyledViewContainer>
    )
}

export default withAITracking(reactPlugin, EditUserView);
