import styled from 'styled-components';
import { appInsights } from '../../../../AppInsights';
import { ReactComponent as ExternalLinkIcon } from '../../../../assets/images/externalLink.svg';
import { ETrackEvent } from '../../../../utils/AppInsightsHelpers';
import { StyledRight } from '../../../common/table/formatters/PositionContainers';

const MarginedStyledRight = styled(StyledRight)`
    > :not(:last-child) {
        margin-right: 12px;
    }
    word-break: normal;
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
    :hover {
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
`;

const ExternalGoTo = (props: any) => {
    const { link } = props.row.original;
    return (
        <MarginedStyledRight>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={link}
                onClick={() => appInsights.trackEvent({ name: ETrackEvent.HelpPageResourceAccess, properties: { accesedResource: link } })}
            >
                <StyledExternalLinkIcon />
            </a>
        </MarginedStyledRight>
    );
}

export default ExternalGoTo;
