import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";
import { KpiType } from "../../../../models/KpiType";
import ResidentStatisticsGraphResponse from "../../../../models/ResidentStatisticsGraphResponse";
import { convertMinutesToHours, convertTimeToHtml } from "../../../../utils/ConvertMinutesToHours";
import { getLocale } from "../../../../utils/Localizer";
import { Chart } from "../../../common/chart/Chart";
import GraphHeader from "../../../common/chart/GraphHeader";
import { IResidentChartData } from "./ResidentStatisticsView";

interface IResidentStatisticsGraphProps {
    selectedKpi: KpiType;
    kpiValue: number | null | undefined | { total: number | null; avg: number | null; };
    compareKpiValue: number | null | undefined | { total: number | null; avg: number | null; };
    startDate: Moment;
    endDate: Moment;
    startCompareDate: Moment | null;
    setSelectedCompareStartDate: (value: Moment | null) => void;
    setSelectedCompareEndDate: (value: Moment | null) => void;
    residentStatistics: ResidentStatisticsGraphResponse[];
    residentCompareStatistics: ResidentStatisticsGraphResponse[];
    startRangeDate: Moment;
    navigationLink: string;
    targetKpiValue: number;
}

const getKpiValue = (entry: ResidentStatisticsGraphResponse, selectedKpi: KpiType) => {
    let selectedKpiValue;
    if (selectedKpi === KpiType.ResponseTime) {
        selectedKpiValue = entry.responseTimeMinutes !== null ? entry.responseTimeMinutes : 0;
    } else if (selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
        selectedKpiValue = entry.nightTimeMinutesBetweenChanges !== null ? entry.nightTimeMinutesBetweenChanges / 60 : 0;
    } else if (selectedKpi === KpiType.AllProductChanges) {
        selectedKpiValue = entry.productChangesTotal;
    } else {
        selectedKpiValue = entry.promptedProductChangesPercentage !== null ? entry.promptedProductChangesPercentage : 0;
    }
    return selectedKpiValue;
}

const generateChartData = (
    residentStatistics: ResidentStatisticsGraphResponse[],
    selectedKpi: KpiType,
    compareResidentStatistics: ResidentStatisticsGraphResponse[],
    fromDate: Moment,
    toDate: Moment,
    fromDateCompare: Moment | null,
    daysInterval: number
): IResidentChartData[] => {
    const chartData: IResidentChartData[] = []
    const currentDate = moment(toDate);
    const currentCompareDate = fromDateCompare ? moment(fromDateCompare.clone().add(daysInterval - 1, "days")) : undefined;
    const dateEntryToChartDataMap: Map<string, { selectedKpiValue: number | null, selectedCompareKpiValue?: number | null, compareDate?: string }> = new Map();
    const dateEntryToChartDataCompareMap: Map<string, number | null> = new Map();
    while (currentDate >= fromDate) {
        dateEntryToChartDataMap.set(moment(currentDate).format("YYYY-MM-DD"), {
            selectedKpiValue: 0,
            compareDate: currentCompareDate ? moment(currentCompareDate).format("YYYY-MM-DD") : undefined
        });

        currentDate.subtract(1, "d");
        if (currentCompareDate) {
            dateEntryToChartDataCompareMap.set(moment(currentCompareDate).format("YYYY-MM-DD"), 0);
            currentCompareDate.subtract(1, "days");
        }
    }

    for (const entry of residentStatistics) {
        const date = moment(entry.day).format("YYYY-MM-DD");
        if (dateEntryToChartDataMap.has(date)) {
            const info = dateEntryToChartDataMap.get(date)
            dateEntryToChartDataMap.set(date, {
                ...info,
                selectedKpiValue: getKpiValue(entry, selectedKpi),
            }
            );
        }
    }

    for (const entry of compareResidentStatistics) {
        const date = moment(entry.day).format("YYYY-MM-DD")
        if (dateEntryToChartDataCompareMap.has(date)) {
            dateEntryToChartDataCompareMap.set(date, getKpiValue(entry, selectedKpi));
        }
    }

    for (const [key, value] of dateEntryToChartDataMap.entries()) {
        const data: IResidentChartData = {
            date: key,
            selectedKpiValue: value.selectedKpiValue,
            selectedCompareKpiValue: dateEntryToChartDataCompareMap.get(value.compareDate ?? ""),
            compareDate: value.compareDate
        }
        chartData.push(data);
    }

    return chartData
};

const getMaxYAxisValue = (residentStatistics: ResidentStatisticsGraphResponse[], selectedKpi: KpiType): number => {
    const allPresentResponseTimes: number[] = residentStatistics.map((residentStat: ResidentStatisticsGraphResponse) => {
        let value;
        switch (selectedKpi) {
            case KpiType.ResponseTime:
                value = residentStat.responseTimeMinutes;
                break;
            case KpiType.AllProductChanges:
                value = residentStat.productChangesTotal;
                break;
            case KpiType.PromptedProductChanges:
                value = residentStat.promptedProductChangesPercentage;
                break;
            default:
                value = residentStat.nightTimeMinutesBetweenChanges;
                break;
        }
        return value as number
    });

    const maxAggregatedResponseTime = Math.max(...allPresentResponseTimes);
    return Math.max(Math.ceil((selectedKpi === KpiType.ResponseTime ? maxAggregatedResponseTime : maxAggregatedResponseTime / 60) * 1.2), 5);
};

const getFormattedTooltipValue = (kpiRawValue: number | null, selectedKpi: KpiType) => {
    let formattedValue: any;
    if (kpiRawValue === null || kpiRawValue === 0) {
        formattedValue = "--";
    } else {
        if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            formattedValue = convertTimeToHtml(
                convertMinutesToHours(Math.round(selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges ? kpiRawValue * 60 : kpiRawValue)),
                true
            );
        } else if (selectedKpi === KpiType.AllProductChanges) {
            formattedValue = <>{kpiRawValue.toString() + " "}<FormattedMessage id={"constants.changeEventsFilter.kpi.changes"} /></>;
        } else {
            formattedValue = (
                <span>
                    {Math.round(kpiRawValue)}%
                </span>
            );
        }
    }
    return formattedValue;
};

const getFormattedLegendValue = (kpiRawValue: any | number | null, selectedKpi: KpiType) => {
    let formattedValue: any;
    if (kpiRawValue === null) {
        formattedValue = "--";
    } else {
        if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
            formattedValue = convertTimeToHtml(
                convertMinutesToHours(kpiRawValue),
                true
            );
        } else if (selectedKpi === KpiType.AllProductChanges) {
            formattedValue = <><FormattedMessage id={"constants.changeEventsFilter.kpi.productChanges.total"} />: {kpiRawValue.total} <FormattedMessage id={"constants.changeEventsFilter.kpi.changes"} />, {Math.round(kpiRawValue.avg* 10) / 10} <FormattedMessage id={"constants.statistics.kpi.productChanges.perDay"} /></>;
        } else {
            formattedValue = Math.round(kpiRawValue) + "%";
        }
    }
    return formattedValue;
};

const getFormattedDifference = (selectedKpi: KpiType, difference: number, percentageDifference?: number) => {
    let formattedValue;
    if (selectedKpi === KpiType.ResponseTime || selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges) {
        formattedValue = convertTimeToHtml(
            convertMinutesToHours(difference),
            true
        );
    } else if (selectedKpi === KpiType.PromptedProductChanges) {
        formattedValue = <>{Math.round(difference * 10) / 10 + " %"}</>;
    } else {
        formattedValue = <>{Math.round((percentageDifference ?? 0) * 10) / 10}</>;
    }
    return formattedValue;
};

const getPercentageValue = (selectedKpi: KpiType, kpiValue: any) => {
    if (kpiValue !== undefined) {
        if (selectedKpi === KpiType.AllProductChanges) {
            if (kpiValue && typeof kpiValue !== "number") {
                return kpiValue.avg ? kpiValue.avg : null;
            }
        }
    }
    return null;
};

const getYAxisLabel = (selectedKpi: KpiType) => {
    switch (selectedKpi) {
        case KpiType.ResponseTime:
            return "statistics.chart.minutes";
        case KpiType.AvgNightTimeMinutesBetweenChanges:
            return "statistics.chart.hours";
        case KpiType.PromptedProductChanges:
            return "statistics.chart.percent";
        default:
            return "statistics.chart.changes";
    }
}

const getHeaderId = (kpi: KpiType) => {
    let id: string = "";
    switch (kpi) {
        case KpiType.ResponseTime:
            id = "statistics.resident.title.responseTime";
            break;
        case KpiType.AllProductChanges:
            id = "statistics.resident.title.productChanges";
            break;
        case KpiType.PromptedProductChanges:
            id = "statistics.resident.title.promptedProductChanges";
            break;
        case KpiType.AvgNightTimeMinutesBetweenChanges:
            id = "statistics.resident.title.nightTime";
            break;
    }
    return id;
};

const getLegendText = (currentSelectedKpi: KpiType) => {
    if (currentSelectedKpi === KpiType.PromptedProductChanges) {
        return <><FormattedMessage id="statistics.legend.prompted" />:</>;
    } else if (currentSelectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges || currentSelectedKpi === KpiType.ResponseTime) {
        return <><FormattedMessage id="statistics.legend.average" />:</>;
    }
    return "";
};

const ResidentStatisticsGraph = (props: IResidentStatisticsGraphProps) => {

    const interval = props.endDate.diff(props.startDate, "d")
    const chartData = generateChartData(
        props.residentStatistics,
        props.selectedKpi,
        props.residentCompareStatistics,
        props.startDate,
        moment(props.endDate).subtract(1, "d"),
        props.startCompareDate,
        interval
    );

    return (
        <div>
            <GraphHeader
                disabledCompare={false}
                selectedKpi={props.selectedKpi}
                isDataPresent={props.residentStatistics.length > 0}
                daysInterval={interval}
                startDate={props.startDate}
                endDate={props.endDate}
                startRangeDate={props.startRangeDate}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate).locale(getLocale()).format("DD MMM YYYY") : null}
                getHeaderId={getHeaderId}
                includeToday={true}
            />
            {props.selectedKpi === KpiType.ResponseTime || props.selectedKpi === KpiType.AvgNightTimeMinutesBetweenChanges}
            <Chart
                dataKey={"selectedKpiValue"}
                compareDataKey={"selectedCompareKpiValue"}
                startDate={props.startDate}
                endDate={moment(props.endDate).subtract(1, "days")}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate) : null}
                compareEndDate={props.startCompareDate ? moment(props.startCompareDate).add(interval - 1, "days") : null}
                chartData={chartData}
                maxYAxisValue={getMaxYAxisValue(props.residentStatistics, props.selectedKpi)}
                selectedKpiValue={props.kpiValue !== undefined ? props.kpiValue : null}
                compareKpiValue={props.compareKpiValue !== undefined ? props.compareKpiValue : null}
                selectedKpi={props.selectedKpi}
                interval={interval}
                percentageKpiValue={getPercentageValue(props.selectedKpi, props.kpiValue)}
                comparePercentageKpiValue={getPercentageValue(props.selectedKpi, props.compareKpiValue)}
                getFormattedTooltipValue={getFormattedTooltipValue}
                getFormattedLegendValue={getFormattedLegendValue}
                getFormattedDifference={getFormattedDifference}
                enableCustomHovering={true}
                navigationLink={props.navigationLink}
                targetKpiValue={props.targetKpiValue}
                getYLabel={getYAxisLabel}
                getLegendText={getLegendText}
            />
        </div>
    );
};

export default ResidentStatisticsGraph;
