import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as signalR from "@microsoft/signalr";
import React, { useEffect, useRef } from "react";
import { appInsights } from "../AppInsights";
import { useAppSelector } from "../hooks/useAppSelector";
import { Role, Roles } from "../models/Role";
import { IContextStateType } from "../reducers/contextSlice";
import { parseErrorToString } from "../utils/AppInsightsHelpers";
import { REACT_APP_API_URL } from "../utils/ProcessEnvHelpers";

const socketUrl = `${REACT_APP_API_URL}/nursingHomeHub`;

export enum SocketStatus {
    INACTIVE = "-",
    CONNECTING = "Connecting",
    OK = "OK",
    ERROR = "ERROR"
}

export enum Method {
    New,
    Update,
    Delete
}

export enum DataType {
    SocketStatus,
    NursingHome,
    Ward,
    WardState,
    Resident,
    ResidentCurrentState,
    CareGiver,
    User
}

enum SocketActionTypes {
    UPDATE_ALL_WARDS = "update_all_wards",
    NEW_RESIDENT = "new_resident",
    UPDATE_RESIDENT = "update_resident",
    DELETED_RESIDENT = "delete_resident",
    NEW_NURSING_HOME = "new_nursing_home",
    UPDATE_WARD_RESPONSE_TIMES = "update_ward_response_times",
    UPDATE_RESIDENT_CURRENT_STATE = "update_resident_current_state",
}

interface ISocketClientProps {
    onSocketPayload: (method: Method, dataType: DataType, payload: any) => void;
}

const SocketClient = (props: ISocketClientProps) => {
    const context = useAppSelector<IContextStateType>(state => state.contextSlice);

    const rolesUsingSocket: Role[] = [
        Roles.NHManager,
        Roles.Nurse,
        Roles.Support
    ];

    const socket: React.MutableRefObject<signalR.HubConnection | undefined> = useRef();
    const buildConnection = () => {
        return new signalR.HubConnectionBuilder()
            .withUrl(socketUrl + "?NursingHomeId=" + context.id)
            .withAutomaticReconnect()
            .build();
    };

    const socketStop = async (socket: signalR.HubConnection) => {
        await socket.stop();
    };

    const registerHandlers = (socket: signalR.HubConnection, props: ISocketClientProps) => {
        props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.CONNECTING);

        socket.onreconnecting(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.CONNECTING);
        });

        socket.onreconnected(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.OK);
        });

        socket.onclose(() => {
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
        });

        socket.start()
            .then(() => {
                props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.OK);
            })
            .catch(() => {
                props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
            });

        socket.on("error", error => {
            appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
            props.onSocketPayload(Method.Update, DataType.SocketStatus, SocketStatus.ERROR);
        });

        socket.on(SocketActionTypes.NEW_RESIDENT, payload => {
            if (payload) {
                props.onSocketPayload(Method.New, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.DELETED_RESIDENT, payload => {
            if (payload) {
                props.onSocketPayload(Method.Delete, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_RESIDENT, payload => {
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.Resident, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_WARD_RESPONSE_TIMES, payload => {
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.WardState, payload);
            }
        });

        socket.on(SocketActionTypes.UPDATE_RESIDENT_CURRENT_STATE, payload => {
            if (payload) {
                props.onSocketPayload(Method.Update, DataType.ResidentCurrentState, payload);
            }
        });

    };

    useEffect(() => {
        if (socket.current) {
            socketStop(socket.current);
        }
        if (context.isAuthenticated && rolesUsingSocket.includes(context.role) && context.id) {
            socket.current = buildConnection();

            registerHandlers(socket.current, props);
        }
    }, [
        context.id,
        context.isAuthenticated,
        context.role,
        context.inactiveTimeInMinutesBeforeLogout,
        context.userResourcesHierarchy,
        context.isPending,
        context.userName,
    ]);

    useEffect(() => {
        return () => {
            if (socket.current) {
                socketStop(socket.current);
            }
        };
    }, []);

    return (
        <div />
    );
};

export default SocketClient;
