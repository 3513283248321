import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import styled from 'styled-components';
import Colors from '../../../assets/Colors';

export const tooltipId = "general-tooltip";

export const StyledTooltip = styled(ReactTooltip)<any>`
    opacity: 1 !important;
    border-radius: 0 !important;
    min-width: 125px;
    ${props => !props.dynamicWidth && "max-width: 270px;"}
    padding: 15px !important;
    font-size: 14px !important;
    text-align: left;
    letter-spacing: 0.4px;
    word-break: break-word;
    font-weight: 400;
    line-height: 16px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px !important;
    background-color: ${Colors.white.clearWhite};
    color: ${Colors.black};
`;

const Tooltip = () => (
    <StyledTooltip
        id={tooltipId}
        delayShow={500}
    />
);

export default Tooltip;