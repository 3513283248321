import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { reactPlugin } from "../AppInsights";
import Colors from "../assets/Colors";
import { AppContext } from "../data/AppRepository";
import ErrorCodes from "../models/ErrorCodes";
import { LocalStorageKeys } from "../data/Persistence";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { toastError, toastSuccess } from "../utils/Toaster";
import { withRouter } from "../routes/routesHelper";
import TwoFactorAuthForm from "../components/themed/login/TwoFactorAuthForm";
import LoginForm from "../components/themed/login/LoginForm";
import { LoginContexts } from "../models/LoginContext";

const StyledLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    padding: 110px 0;
    background-color: ${Colors.white};
`;

const Login = () => {
    const appContext = useContext(AppContext);
    const [twoFactorFormVisible, setTwoFactorFormVisible] =
        useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();

    useEffect(() => {
        const loginContext = sessionStorage.getItem(LocalStorageKeys.LoginContext);
        if (loginContext && loginContext === LoginContexts.CustomerAd) {
            appContext
                .loginWithCustomerAdAccessToken(sessionStorage.getItem(LocalStorageKeys.AccessToken) || "")
                .then(() => navigate(routePaths.loginPath))
                .catch((error: any) =>
                    toastError(getErrorMessage(error.message || error))
                );
        } else {
            if (location.hash.includes("id_token")) {
                appContext
                    .loginWithIdToken(location.hash.replace("#id_token=", ""))
                    .then(() => navigate(routePaths.loginPath))
                    .catch((error: any) =>
                        toastError(getErrorMessage(error.message || error))
                    );
                toastSuccess(localizer("loginView.successfulPasswordChange"));
            }
        }
        return;
    }, []);

    const getErrorMessage = (error: string) => {
        if (
            Object.values(ErrorCodes).find((code) => code.toString() === error)
        ) {
            return localizer(error);
        }
        return error;
    };

    const handleSuccessfulLogin = (userData: any) => {
        if (userData.twoFAResponse?.is2FactorAuthenticationEnabled) {
            setTwoFactorFormVisible(true);
            setUserData({ email: userData.email, password: userData.password, mobile: userData.twoFAResponse.mobileNumber })
        }
    }

    const handleBackToLoginPage = () => {
        setTwoFactorFormVisible(false);
    }

    return (
        <StyledLoginContainer>
            {!twoFactorFormVisible && (
                <LoginForm onSubmit={handleSuccessfulLogin}>
                </LoginForm>
            )}
            {twoFactorFormVisible && (
                <TwoFactorAuthForm
                    onBack={handleBackToLoginPage}
                    mobile={userData.mobile}
                    email={userData.email}
                    password={userData.password}>
                </TwoFactorAuthForm>
            )}
        </StyledLoginContainer>
    );
};

export default withAITracking(reactPlugin, withRouter(Login));
