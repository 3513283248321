import { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { ReactComponent as UpArrow } from "../../../assets/images/upArrow.svg";
import { useWindowScrollPosition } from "../../../hooks/useWindowScrollPosition";

const TakeMeUpContainer = styled.div`
    display: flex;
    justify-content: end;
    max-width: 1440px;
    position: relative;
`;

const TakeMeUpButton = styled.div<any>`
    opacity: ${props => props.show ? "1" : "0"};
    transition: opacity 0.5s ease-in-out;
    width: 56px;
    height: ${props => props.show ? "56px" : "0px"};
    border-radius: 54px;
    background-color: ${Colors.blue.brandingDark};
    :hover {
        cursor: ${props => props.show ? "pointer" : "default"};
    }
    position: fixed;
    bottom: 10px;
    z-index: 10;
    text-align: center;
    box-shadow: 0px 0px 7px ${Colors.white.clearWhite};
    line-height: 50px;
`;

const TakeMeUp = () => {
    const [showButton, setShowButton] = useState(false);
    const { scrollY } = useWindowScrollPosition();

    useEffect(() => {
        if(window.scrollY === 0 && showButton) {
            setShowButton(false);
        } else if(window.scrollY !== 0 && !showButton) {
            setShowButton(true);
        }
    }, [scrollY, showButton]);

    return (
        <TakeMeUpContainer>
            <TakeMeUpButton show={showButton} onClick={() => { setTimeout(() => window.scrollTo(0, 0), 10) }}>
                <UpArrow fill={Colors.white.clearWhite} stroke={Colors.white.clearWhite} />
            </TakeMeUpButton>
        </TakeMeUpContainer>
    )

}

export default TakeMeUp;
