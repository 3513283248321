import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { isLocalEnv } from "./utils/EnvCheck";
import { REACT_APP_APPINSIGHTS_CONNECTION_STRING } from "./utils/ProcessEnvHelpers";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    // Fallback for local development
    connectionString: REACT_APP_APPINSIGHTS_CONNECTION_STRING ? REACT_APP_APPINSIGHTS_CONNECTION_STRING : "InstrumentationKey=xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true
  }
});

if (!isLocalEnv()) {
  ai.loadAppInsights();
}

export { reactPlugin };
export const appInsights = ai.appInsights