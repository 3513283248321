import styled from "styled-components";
import { appInsights } from "../../../../AppInsights";
import Colors from "../../../../assets/Colors";
import { ETrackEvent } from "../../../../utils/AppInsightsHelpers";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";

const StyledATag = styled.a`
    color: ${Colors.blue.brandingDark};
    text-decoration: none;
    :hover {
        cursor: pointer;
        font-weight: bold;
    }   
`

const LinkedText = (props: any) => {
    const { value } = props;
    const { hideTooltipOnProd } = props.column;
    const { link } = props.row.original;

    return (
        <StyledLeft data-qa={value + "-table_item"}>
            <TextOverflowWrapper
                tooltip={value}
                hideTooltipOnProd={hideTooltipOnProd}
            >
                <StyledATag
                    rel="noopener noreferrer"
                    target="_blank"
                    href={link}
                    onClick={() => appInsights.trackEvent({ name: ETrackEvent.HelpPageResourceAccess, properties: { accesedResource: link } })}
                >
                    {value}
                </StyledATag>
            </TextOverflowWrapper>
        </StyledLeft>
    );
}

export default LinkedText;
