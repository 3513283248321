import moment from "moment";
import { utcTimeSpanToLocal } from "../utils/TimeZoneConverter";
import { SensorStripType } from "./EStripSizeSetting";

export const getSystemDate = (timeSpan: string, minutesToAdd: number = 0) => {
    const time = moment(timeSpan, 'HH:mm:ss');
    time.add(minutesToAdd, "m");
    return time.format('HH:mm');
};

export enum IntegrationType {
    Amba = "Amba",
    Webhook = "Webhook"
}

export class CareFacility {
    static caregiverResponseTargetTimes = [10, 20, 30, 40];
    static supervisorResponseTargetTimes = [0, 10, 20, 30, 40];
    static caregiverNotificationIntervals = [0, 3, 6, 9, 12, 15];
    static supervisorNotificationIntervals = [0 ,5, 10, 15, 20, 25];
    static reapplyReminderTimes = [1, 2, 3, 4, 5, 0];
    id: string;
    externalId: string;
    name: string;
    systemStartTime: string;
    systemEndTime: string;
    nightShiftStartTime: string;
    morningShiftStartTime: string;
    caregiverResponseTargetMinutes: number;
    caregiverResponseTargetMinutesDay: number;
    caregiverNotificationIntervalMinutes: number;
    caregiverNotificationIntervalMinutesDay: number;
    isWeakAuthenticationAllowed: boolean;
    is2FactorAuthenticationEnabled: boolean;
    is2FactorAuthenticationEnabledMobile: boolean;
    enforceEmailForCaregiver: boolean;
    nursingHomeGroupId: string;
    customerId: string;
    isActive: boolean;
    assignedUsersIds: number[];
    amountOfAssignedUsers: number;
    countryId: string;
    careGroupId: string;
    marketCode: string;
    morningShiftNotificationTime: string;
    nightShiftNotificationTime: string;
    shiftNotificationTimeDisabled: boolean;
    notifyOfSomeUrineStatusOverdue: boolean;
    externalIntegrationId: string | null;
    externalIntegrationName: string | null;
    externalIntegrationType: IntegrationType | null;
    sensorStripType: SensorStripType;

    constructor(json: any = {}) {
        this.id = json.id ? String(json.id) : '';
        this.externalId = json.externalId ? String(json.externalId) : '';
        this.name = json.name || '';
        this.systemStartTime = json.morningShiftStartTime ? utcTimeSpanToLocal(getSystemDate(json.morningShiftStartTime)) : "00:00";
        this.systemEndTime = json.morningShiftStartTime ? utcTimeSpanToLocal(getSystemDate(json.morningShiftStartTime, -1)) : "23:59";
        this.nightShiftStartTime = utcTimeSpanToLocal(json.nightShiftStartTime);
        this.morningShiftStartTime = utcTimeSpanToLocal(json.morningShiftStartTime);
        this.caregiverResponseTargetMinutes = json.caregiverResponseTargetMinutesNight;
        this.caregiverResponseTargetMinutesDay = json.caregiverResponseTargetMinutesDay;
        this.caregiverNotificationIntervalMinutes = json.caregiverNotificationIntervalMinutesNight;
        this.caregiverNotificationIntervalMinutesDay = json.caregiverNotificationIntervalMinutesDay;
        this.isWeakAuthenticationAllowed = json.isWeakAuthenticationAllowed;
        this.is2FactorAuthenticationEnabled = json.is2FactorAuthenticationEnabled;
        this.is2FactorAuthenticationEnabledMobile = json.is2FactorAuthenticationEnabledMobile;
        this.enforceEmailForCaregiver = json.enforceEmailForCaregiver ?? true;
        this.nursingHomeGroupId = json.nursingHomeGroupId ? String(json.nursingHomeGroupId) : '';
        this.countryId = json.countryId ? String(json.countryId) : '';
        this.careGroupId = json.nursingHomeGroupId ? String(json.nursingHomeGroupId) : '';
        this.customerId = json.customerId ? json.customerId : '';
        this.isActive = json.isActive ?? true;
        this.assignedUsersIds = json.assignedUsers || [];
        this.amountOfAssignedUsers = this.assignedUsersIds.length;
        this.marketCode = json.marketCode;
        this.morningShiftNotificationTime = utcTimeSpanToLocal(json.morningShiftNotificationTime);
        this.nightShiftNotificationTime = utcTimeSpanToLocal(json.nightShiftNotificationTime);
        this.shiftNotificationTimeDisabled = json.shiftNotificationTimeDisabled;
        this.notifyOfSomeUrineStatusOverdue = json.notifyOfSomeUrineStatusOverdue ?? true;
        this.externalIntegrationId = json.externalIntegrationId;
        this.externalIntegrationName = json.externalIntegrationName;
        this.externalIntegrationType = json.externalIntegrationType;
        this.sensorStripType = json.sensorStripType ?? SensorStripType.MultiSize;
    }
}
