export class Axis {
    isVert: boolean;
    cx: number;
    cy: number;
    rot: any;
    lineHeight: number;

    constructor(axisType: any, x: number, y: number, width: number, height: number) {
        this.isVert = axisType === 'yAxis';
        this.cx = this.isVert ? x : x + (width / 2);
        this.cy = this.isVert ? (height / 2) + y : y + height + 20;
        this.rot = this.isVert ? `270 ${this.cx} ${this.cy}` : 0;
        this.lineHeight = 20;
    }
}