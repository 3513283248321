import React from "react";
import styled from "styled-components";
import Colors from "../../assets/Colors";
import { useIsOverflow } from "../../hooks/useIsOverflow";
import { isProdEnv } from "../../utils/EnvCheck";
import { tooltipId } from "../common/tooltip/Tooltip";

export const StyledTextOverflowWrapper = styled.div<any>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${(props) => (props.clickable && (`
        color: ${Colors.blue.brandingDark};
        :hover {
            cursor: pointer;
            font-weight: bold;
        }
        `
    ))}
    ${(props) => (props.uppercase && (`
        text-transform: uppercase;
        `
    ))}
`;

interface IProps {
    tooltip: string | number;
    children: React.ReactChild;
    hideTooltipOnProd?: boolean;
    ignoreOverflowing?: boolean;
    onClick?: () => void;
    className?: string;
    uppercase?: boolean;
}

const TextOverflowWrapper = (props: IProps) => {
    const ref = React.useRef(null);
    const isOverflow = useIsOverflow(ref);

    const tooltipValue = isProdEnv() && props?.hideTooltipOnProd
        ? ""
        : (props.ignoreOverflowing || isOverflow) ? props?.tooltip : "";

    const clickable: boolean = props.onClick !== undefined;
    return (
        <StyledTextOverflowWrapper
            className={props.className}
            clickable={clickable}
            onClick={props.onClick}
            uppercase={props.uppercase}
            data-tooltip-id={tooltipId}
            data-tooltip-content={tooltipValue}
            ref={ref}>
            {props.children}
        </StyledTextOverflowWrapper>
    );
};

export default TextOverflowWrapper;
