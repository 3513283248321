import { StyledCenter } from './PositionContainers';

const CellFormatter = (props: any) => {
    const { value } = props.data;
    return (
        <StyledCenter>
            {value}
        </StyledCenter>
    );
};

const NumberFormatter = (data: any) => <CellFormatter data={data} />;
export default NumberFormatter;