import styled from "styled-components";
import Colors from "../assets/Colors";
import { ReactComponent as CareFacilityIcon } from "../assets/images/careFacility.svg";

export const FacilitiesViewContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: 50px;
    margin: 0 125px;
`;

export const CareFacilityView = styled.div`
    width: 150px;
    height: 202px;
    :hover {
        cursor: pointer;
    }
`;

export const CareFacilityIconContainer = styled.div`
    width: 150px;
    height: 150px;
    background-color: ${Colors.grey.tenaLight};
`;

export const StyledCareFacilityIcon = styled(CareFacilityIcon)`
    margin: auto;
    display: block;
    height: 100%
`;

export const CareFacilityName = styled.div`
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: break-word;
`;

export const HeaderContainer = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${Colors.blue.brandingDark};
    margin: 10px 125px 40px;
`;

export const NursingHomeGroupNameContainer = styled.div`
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    margin: 15px 125px;
`;

export const CountryNameContainer = styled.div`
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    margin: 25px 125px;
    margin-top: 50px;
`;

export const FilterContainer = styled.div`
    margin: 25px 125px;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 50px;
`;
