import styled from "styled-components";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Resident } from "../../../models/Resident";
import { ResidentEventType } from "../../../models/ResidentEventType";
import { Ward } from "../../../models/Ward";
import { getActiveResidentCount } from "../../../reducers/residentHelper";
import "../../../styles/main.scss";
import { localizer } from "../../../utils/Localizer";
import InformationBlock from "../../../views/InformationBlock/InformationBlock";
import MultiSelectionFieldNew from "../../common/controls/MultiSelectionFieldNew";
import { StyledInformationBlocks, StyledInformationBlockWrapper, StyledViewContainer } from "../../common/View";
import ResidentsInformationBlock from "./ResidentsInformationBlock";
import StatusSummaryInformationBlock from "./StatusSummaryInformationBlock";

const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
    width: 240px;
`;

const CustomStyledViewContainer = styled(StyledViewContainer)`
    padding: 0px;
`;

export interface IResidentsInformationBlocksProps {
    handleWardSelection: (name: string, value: any) => void;
    selectedWards: Ward[];
    wards: Ward[];
}

const ResidentsInformationBlocks = (props: IResidentsInformationBlocksProps): React.ReactElement => {
    const residents = useAppSelector<Resident[]>(state => state.residentSlice.residents.filter(resident => props.selectedWards.map(ward => ward.id).includes(resident.wardId)));

    const getAmountOfResidentsByBedStatus = (residentEventType: ResidentEventType[]): number => {
        return residents.filter(resident => residentEventType.some(type => type === resident.bedStatus)).length;
    };

    return (
        <CustomStyledViewContainer>
            <StyledDropdownsContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.ward"
                    width={300}
                >
                    <MultiSelectionFieldNew
                        name={""}
                        handleChange={props.handleWardSelection}
                        options={props.wards.map(ward => ({
                            label: ward.name,
                            value: ward.id
                        }))}
                        selectedValue={props.selectedWards.map(ward => ({
                            label: ward.name,
                            value: ward.id
                        }))}
                        translations={{
                            allOptionsSelected: localizer("statistics.chart.allWards"),
                            multipleOptionsSelected: localizer("statistics.chart.multipleWardsSelected"),
                            noOptions: localizer("statistics.chart.noWards")
                        }}
                        maxHeight={500}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>
            <StyledInformationBlocks>
                <StyledInformationBlockWrapper minWidth={300}>
                    <ResidentsInformationBlock
                        selectedWards={props.selectedWards}
                        amountOfActiveResidents={getActiveResidentCount(residents)}
                        amountOfAllResidents={residents.length}
                    />
                </StyledInformationBlockWrapper>
                <StyledInformationBlockWrapper minWidth={300}>
                    <StatusSummaryInformationBlock
                        amountOfSaturated={getAmountOfResidentsByBedStatus([ResidentEventType.Saturated])}
                        amountOfErrors={getAmountOfResidentsByBedStatus([ResidentEventType.Error])}
                        amountOfWarnings={getAmountOfResidentsByBedStatus([ResidentEventType.SomeUrine])}
                        amountOfSuccesses={getAmountOfResidentsByBedStatus([ResidentEventType.OK])}
                    />
                </StyledInformationBlockWrapper>
            </StyledInformationBlocks >
        </CustomStyledViewContainer>

    );
}

export default ResidentsInformationBlocks;
