
import styled, { css } from "styled-components";
import { ReactComponent as PrevIcon } from "../../../../assets/images/datepickerLeftArrow.svg";
import { ReactComponent as NextIcon } from "../../../../assets/images/datepickerRightArrow.svg";
import Colors from "../../../../assets/Colors";

export const StyledNextIcon = styled(NextIcon)`
    fill: ${Colors.grey.medium};
    :hover{
        cursor: pointer;
        fill: ${Colors.black};
    }
`;

export const StyledPrevIcon = styled(PrevIcon)`
    fill: ${Colors.grey.medium};
    :hover{
        cursor: pointer;
        fill: ${Colors.black};
    }
`;

export const StyledPickerCss = css`
    margin-right:90px;
    .rc-picker {
        .rc-picker-input {
            &:hover { 
                cursor: pointer;
                box-shadow: 0 1px 6px #ccc !important;
            } 
            input {
                border-radius: 3px;
            }
            input:hover {
                cursor: pointer;
            } 
            .rc-picker-clear {
                right: 13px;
                top: 6px
            }
        }
    }

    .rc-picker-focused {
        background-color: #F8F9FA !important;
        box-shadow: none !important;
        .rc-picker-input {
            box-shadow: none !important;
            input {
                background-color: #F8F9FA !important;
            }
            &:hover { 
                box-shadow: none !important;
            } 
        }
    }
`;

export const StyledPickerPanelCss = css`
    .rc-picker-panel {
        display: flex !important;
        width: 242px;
        color: ${Colors.black}; //almost black

        .rc-picker-date-panel {
            width: 100%;
            table {
                width: 100%;
            }
            .rc-picker-header {
                height: 40px;
                background-color: ${Colors.white.hoverWhite};
                .rc-picker-header-view {
                    .rc-picker-month-btn {
                        text-transform: capitalize;
                    }
                    button {
                        margin-top: 10px;
                        padding-left: 6px;
                        padding-right: 6px;
                        background-color: ${Colors.white.hoverWhite};
                        font-family: Asap;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 22px;
                    }
                    button:hover{
                        background-color: #EDEFF0;
                        border-radius: 5px;
                    }
                }
                .rc-picker-header-prev-btn {
                    background-color: transparent;
                    border: none;
                }
                .rc-picker-header-next-btn {
                    background-color: transparent;
                    border: none;
                }
                .rc-picker-header-super-prev-btn {
                    display: none;
                }
                .rc-picker-header-super-next-btn {
                    display: none;
                }
            }
            .rc-picker-body {
                .rc-picker-content {
                    thead {
                        background-color: ${Colors.white.hoverWhite};
                        height:27px;
                        font-size: 13px;
                        line-height: 22px;
                        border-bottom: 1px solid ${Colors.grey.almostWhite};
                    }
                    tbody {
                        border: 8px solid transparent;
                        td{
                            pointer-events: none;
                        }
                        .rc-picker-cell {
                            .rc-picker-cell-inner:hover {
                                border-radius: 5px;
                            }
                            .rc-picker-cell-inner {
                                pointer-events: all;
                                height: 27px;
                                width: 27px;
                            }
                        }
                        .rc-picker-cell-disabled {
                            background-color: transparent;
                            cursor: default;
                            .rc-picker-cell-inner:hover {
                                background-color: transparent;
                                cursor: default;
                            } 
                        }

                        .rc-picker-cell-today > .rc-picker-cell-inner {
                            border: 0px;
                        }

                        .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner{
                            border-radius: 5px;

                        }
                        
                        td.rc-picker-cell.rc-picker-cell-disabled.rc-picker-cell-in-view.rc-picker-cell-today {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
        .rc-picker-month-panel {
            width: 100%;
            table {
                width: 100%;
            }
            .rc-picker-header {
                height: 40px;
                background-color: ${Colors.white.hoverWhite};
                border-bottom: 1px solid ${Colors.grey.almostWhite};

                .rc-picker-header-view {
                    button {
                        margin-top: 5px;
                        padding-left: 6px;
                        padding-right: 6px;
                        background-color: ${Colors.white.hoverWhite};
                        font-family: Asap;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 22px;
                        height: 30px;
                    }
                    button:hover{
                        background-color: #EDEFF0;
                        border-radius: 5px;
                    }
                }
                .rc-picker-header-super-prev-btn {
                    background-color: transparent;
                    border: none;
                }
                .rc-picker-header-super-next-btn {
                    background-color: transparent;
                    border: none;
                }
            }

            .rc-picker-body {
                padding: 10px;
                .rc-picker-content {
                    .rc-picker-cell {
                        width: 70px;
                        .rc-picker-cell-inner {
                            width: inherit;
                            line-height: 18px;
                            height: 18px;
                        }
                    }
                    .rc-picker-cell-disabled: hover {
                        background-color: transparent;
                        .rc-picker-cell-inner:hover {
                            background-color: transparent;
                            cursor: default;
                        }
                    }
                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        background-color: transparent;
                        background:transparent;
                        border-radius: 5px;
                    }
                }
            }
            .rc-picker-body {
                .rc-picker-content {
                    .rc-picker-cell {
                        width: 70px;
                        .rc-picker-cell-inner {
                            width: inherit;
                        }
                        .rc-picker-cell-inner:hover {
                            background-color: transparent;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
        .rc-picker-year-panel {
            width: 100%;
            table {
                width: 100%;
            }
            .rc-picker-header {
                height: 40px;
                background-color: ${Colors.white.hoverWhite};
                border-bottom: 1px solid ${Colors.grey.almostWhite};
                .rc-picker-header-view {
                    button {
                        margin-top: 8px;
                        padding-left: 6px;
                        padding-right: 6px;
                        background-color: ${Colors.white.hoverWhite};
                        font-family: Asap;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 15px;
                        line-height: 22px;
                    }
                    button:hover{
                        background-color: #EDEFF0;
                        border-radius: 5px;
                    }
                }
                .rc-picker-header-super-prev-btn {
                    
                    background-color: transparent;
                    border: none;
                }
                .rc-picker-header-super-next-btn {
                    
                    background-color: transparent;
                    border: none;
                }
            }
            .rc-picker-body {
                padding: 10px;
                .rc-picker-content {
                    .rc-picker-cell {
                        width: 70px;
                        .rc-picker-cell-inner {
                            width: inherit;
                        }
                        .rc-picker-cell-inner:hover {
                            border-radius: 5px;
                        }
                    }

                    .rc-picker-cell-disabled:hover {
                        background-color: transparent;
                        .rc-picker-cell-inner:hover {
                            background-color: transparent;
                            cursor: default;
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        border-radius: 5px;
                    }
                }
            }
        }
        .rc-picker-decade-panel {
            width: 100%;
            table {
                width: 100%;
            }
            .rc-picker-header {
                height: 40px;
                background-color: ${Colors.white.hoverWhite};
                border-bottom: 1px solid ${Colors.grey.almostWhite};
                .rc-picker-header-view {
                    height: 40px;
                    padding-left: 6px;
                    padding-right: 6px;
                    padding-top: 4px;
                    background-color: ${Colors.white.hoverWhite};
                    font-family: Asap;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 22px;
                }
                .rc-picker-header-super-prev-btn {
                    
                    background-color: transparent;
                    border: none;
                }
                .rc-picker-header-super-next-btn {
                    
                    background-color: transparent;
                    border: none;
                }
            }
            .rc-picker-body {
                padding: 10px;
                .rc-picker-content {
                    .rc-picker-cell {
                        width: 70px;
                        .rc-picker-cell-inner {
                            width: inherit;
                        }
                        .rc-picker-cell-inner:hover {
                            border-radius: 5px;
                        }
                    }
                    
                    .rc-picker-cell-disabled:hover {
                        background-color: transparent;
                        .rc-picker-cell-inner:hover {
                            background-color: transparent;
                            cursor: default;
                        }
                    }

                    .rc-picker-cell-range-start > .rc-picker-cell-inner, .rc-picker-cell-range-end > .rc-picker-cell-inner, .rc-picker-cell-selected > .rc-picker-cell-inner {
                        border-radius: 5px;
                    }
                }
            }
        }
    }
`;