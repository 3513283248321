import Select, { components } from 'react-select';
import Colors from '../assets/Colors';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
    font-size: 17px;
    margin-left: 12px;
`;

const StyledPlaceholder = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    max-width: 130px;
    margin-top: 3px;
`;

const StyledOption = styled.div<any>`
    word-break: break-word;
    display: flex;
    align-items: flex-start;
    padding-left: ${(props: any) => props.depth * 24}px;
    width: auto;
`;

const StyledSpan = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
`;

const renderHierarchyOption = (
    children: React.ReactChild,
    depth: number,
) => (
        <StyledOption
            depth={depth}
            data-qa={children + "-main_dropdown_item"}
        >
            {children}
        </StyledOption>
    );

const renderWithIcon = (children: React.ReactChild, menuIsOpen: boolean) => (
    <StyledPlaceholder>
        <StyledSpan>{children}</StyledSpan>{" "}
        <StyledIcon icon={menuIsOpen ? faCaretUp : faCaretDown} />
    </StyledPlaceholder>
);

const Placeholder = (props: any) => (
    <components.Placeholder
        {...props}
        children={renderWithIcon(props.children, props.selectProps.menuIsOpen)}
    >
    </components.Placeholder>
);

const Option = (props: any) => (
    <components.Option
        {...props}
        children={renderHierarchyOption(props.children, props.data.depth || 0)}
    >
    </components.Option>
);

const SingleValue = (props: any) => (
    <components.SingleValue
        {...props}
        children={renderWithIcon(props.children, props.selectProps.menuIsOpen)}
    >
    </components.SingleValue>
);

interface ICustomDropdownPropsProps {
    qaId: string;
    handleChange: (value: any) => void;
    options: any[];
    placeholder: string;
    maxHeight: number;
    width?: number;
    fontSize?: number;
    selectedOption: any;
}

export default function CustomDropdown(props: ICustomDropdownPropsProps): React.ReactElement {
    const width = props.width || 300;
    const fontSize = props.fontSize || 16;
    const maxHeight = props.maxHeight;

    const customStyles = {
        container: (provided: any) => {
            return {
                ...provided,
                width: 'auto',
                maxWidth: width,
                zIndex: 4
            };
        },
        option: (provided: any, state: any) => {
            return {
                ...provided,
                color: state.isSelected
                    ? Colors.white.clearWhite
                    : Colors.black,
                cursor: state.isDisabled ? 'normal' : 'pointer',
                width,
                backgroundColor: state.isDisabled
                    ? Colors.grey.light
                    : state.isSelected
                        ? Colors.blue.dark
                        : state.isFocused
                            ? Colors.blue.lighter
                            : Colors.white.clearWhite,
                ":active": {
                    backgroundColor: state.isSelected ? Colors.blue.dark : Colors.blue.light,
                }
            };
        },
        control: () => {
            return {
                fontSize: `${fontSize}px`,
                color: Colors.blue.brandingDark,
                width: 'auto',
                backgroundColor: "none",
                borderColor: "none",
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap" as "wrap",
                justifyContent: "flex-start" as "flex-start",
                label: "control",
                transition: "all 100ms",
                height: "auto",
                maxHeight: 28,
            };
        },
        menu: (provided: any) => ({
            ...provided,
            width: 'auto',
        }),
        menuList: (provided: any) => ({
            ...provided,
            overflowX: 'hidden',
            width,
            maxHeight,
            paddingTop: '8px',
            paddingBottom: '8px',
            borderRadius: '4px',
        }),
        singleValue: () => ({
            color: Colors.blue.brandingDark,
            cursor: 'pointer',
            maxWidth: 'none',
            position: 'relative' as 'relative',
            whiteSpace: 'nowrap' as 'nowrap',
        }),
        indicatorsContainer: () => ({
            display: 'none',
        }),
        valueContainer: () => ({
        }),
        placeholder: () => ({
            width: '90px',
            cursor: 'pointer',
            fontWeight: 400,
            position: 'relative' as 'relative',
        }),
    };

    return (
        <div data-qa={props.qaId}>
            <Select
                styles={customStyles}
                components={{
                    Placeholder,
                    SingleValue,
                    Option
                }}
                value={props.selectedOption}
                onChange={props.handleChange}
                options={props.options}
                placeholder={props.placeholder}
                isSearchable={false} />
        </div>
    );
}
