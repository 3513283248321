import moment, { Moment } from "moment";
import Picker from "rc-picker";
import generateConfig from "rc-picker/lib/generate/moment";
import locale from "rc-picker/lib/locale/en_US";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { DateFilter } from "../../../../../models/DateFilter";
import Colors from "../../../../../assets/Colors";
import { getLocale, localizer } from "../../../../../utils/Localizer";
import {
    IDropDownOption, StyledDropdownItem, StyledReactBootstrapDropdownButton
} from "../../DropdownButtonNew";
import { StyledNextIcon, StyledPrevIcon } from "../CommonDatePickerStyle";
import { StyledDateInput, StyledDateTimeRow, StyledDay, StyledDropdownContainer, StyledMonth, StyledPicker, StyledPickerPanel, StyledSmallerTextDiv, StyledTextDiv, StyledCalendar, StyledInput, StyledInfoMessageContainer } from "./DropdownDateTimeRangeButtonStyle";
import { PickerType } from "./PickerType";

export interface IDropdownDateTimeRangeButtonProps {
    qaId?: string;
    id: string;
    name: string;
    handleChange: (name: string, value: string) => void;
    handleStartDateTimeChange?: (value: Moment | null) => void;
    handleEndDateTimeChange?: (value: Moment | null) => void;
    selectedValue: any;
    options: IDropDownOption[];
    start?: Moment | null;
    end?: Moment | null;
    pickerType: PickerType;
    amountOfDays?: number;
    offsetValue: number;
    infoMessage?: string;
    disableCustomRange?: boolean;
}

type Props = IDropdownDateTimeRangeButtonProps & {
    startPickerPropertiesInitialMinDate?: Moment;
    endPickerPropertiesInitialMaxDate?: Moment;
};

const DropdownDateTimeRangeButton = (props: Props) => {
    const key: string = "dateRangeSeparator";
    const customDateTimeValue: string | number = 99;
    const [isStartOpen, setIsStartOpen] = useState<boolean>(false);
    const [isEndOpen, setIsEndOpen] = useState<boolean>(false);
    const startPickerRef = useRef<Picker<Moment>>(null);
    const startPopupRef = useRef(null);
    useClickOutside(startPopupRef, () => setIsStartOpen(false));

    const endPickerRef = useRef<Picker<Moment>>(null);
    const endPopuptRef = useRef(null);
    useClickOutside(endPopuptRef, () => setIsEndOpen(false));

    const getSelectedOptionLabel = () => {
        if (props.selectedValue === customDateTimeValue) {
            return `${moment(props.start).format("YYYY-MM-DD")} - ${moment(props.end).format("YYYY-MM-DD")}`;
        }

        const selectedOption = props.options.find(
            (option) => option.value === props.selectedValue
        );
        return selectedOption
            ? selectedOption.label
            : localizer("dropDownFieldFormGroup.placeholder");
    };

    const generateInfoMessage = (infoMessage?: string) => {
        if(infoMessage){
            return <StyledInfoMessageContainer>{infoMessage}</StyledInfoMessageContainer>;
        }
        return;
    }

    const generateDropDownItems = () => {
        return props.options.map((option) => {
            return (
                <StyledDropdownItem
                    key={option.value}
                    active={option.value === props.selectedValue}
                    eventKey={option.value}
                    hidden={option.label === localizer("constants.customRange")}
                >
                    {option.label}
                </StyledDropdownItem>
            );
        });
    };

    const generateRangeSelector = () => {
        const heightOffsetValue = 28;
        const axisValueSelector = 1; // 0 for y, 1 for x
        const cellBorderRadius = "5px";
        const heavyFontWeight = 700;
        const maxEndDate = props.endPickerPropertiesInitialMaxDate ? props.endPickerPropertiesInitialMaxDate.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        return (
            <>
                <StyledDropdownContainer
                    onClick={selectCustomRange}
                    key={key}
                    isActive={customDateTimeValue === props.selectedValue}
                >
                    <StyledTextDiv onClick={selectCustomRange}>
                        <FormattedMessage id="constants.changeEventsFilter.date.custom" />:
                    </StyledTextDiv>
                    <StyledDateTimeRow
                        className="g-0"
                        onClick={selectCustomRangeForExactTarget}
                    >
                        <StyledSmallerTextDiv onClick={selectCustomRange}>
                            <FormattedMessage id="constants.changeEventsFilter.date.from" />
                        </StyledSmallerTextDiv>
                        <StyledDateInput
                            onClick={selectCustomRangeForExactTarget}
                        >
                            <StyledPicker onClick={selectCustomRange}>
                                <Picker<Moment>
                                    ref={startPickerRef}
                                    generateConfig={generateConfig}
                                    locale={{ ...locale, locale: getLocale(), monthFormat: "MMMM" }}
                                    picker={"date"}
                                    open={isStartOpen}
                                    panelRender={(originPanel: React.ReactNode) => {
                                        return <StyledPickerPanel ref={startPopupRef} onClick={(event: any) => event.stopPropagation()}>{originPanel}</StyledPickerPanel>;
                                    }}
                                    dropdownAlign={{
                                        overflow: { adjustX: true, adjustY: true },
                                        targetOffset: [-props.offsetValue, heightOffsetValue],
                                        offset: [axisValueSelector]
                                    }
                                    }
                                    disabledDate={(value: Moment) => value.format("YYYY-MM-DD") > maxEndDate || (props.startPickerPropertiesInitialMinDate !== undefined && value.format("YYYY-MM-DD") < moment(props.startPickerPropertiesInitialMinDate).format("YYYY-MM-DD"))}
                                    value={moment(props.start)}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        selectCustomRange();
                                        setIsStartOpen(!isStartOpen);
                                    }}
                                    onOpenChange={(isOpen) => {
                                        // when panel is closing de-focus input
                                        if (!isOpen && startPickerRef && startPickerRef.current) {
                                            startPickerRef.current.blur();
                                        }
                                    }}
                                    format={(_value: Moment) => moment(props.start).format("YYYY-MM-DD")}
                                    onChange={handleStartDateTimeChange}
                                    nextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                                    prevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                                    superNextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                                    superPrevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                                    dateRender={(current: Moment) => {
                                        const style: React.CSSProperties = {};
                                        style.borderRadius = cellBorderRadius;
                                        const formattedCurrent = current.format("YYYY-MM-DD");
                                        const formattedStart = moment(props.start).format("YYYY-MM-DD");
                                        const formattedEnd = moment(props.end).format("YYYY-MM-DD");
                                        if (formattedCurrent === formattedEnd) {
                                            style.color = Colors.white.clearWhite;
                                            style.backgroundColor = Colors.blue.brandingDark;
                                        } else if (formattedCurrent > formattedStart && formattedCurrent < formattedEnd) {
                                            style.backgroundColor = Colors.blue.tailBlue;
                                        } else if (formattedCurrent === moment().format("YYYY-MM-DD") && formattedCurrent !== formattedStart) {
                                            style.backgroundColor = Colors.grey.almostWhite;
                                            style.fontWeight = heavyFontWeight;
                                        }

                                        return (
                                            // empty title to disable built in tooltip on hover
                                            <div className="rc-picker-cell-inner" style={style} title="">
                                                <StyledDay >{current.date()}</StyledDay>
                                            </div>
                                        );
                                    }}
                                    monthCellRender={(current: Moment) => {
                                        const style: React.CSSProperties = {};
                                        style.borderRadius = cellBorderRadius;
                                        if (current.format("YYYY-MM-DD") === moment(props.start).format("YYYY-MM-DD")) {
                                            style.color = Colors.white.clearWhite;
                                            style.backgroundColor = Colors.blue.brandingDark;
                                        }

                                        current.locale(getLocale());
                                        return (
                                            // empty title to disable built in tooltip on hover
                                            <div className="rc-picker-cell-inner" style={style} title="">
                                                <StyledMonth selected={true} >{current.format("MMM")}</StyledMonth>
                                            </div>);
                                    }}
                                    inputReadOnly={true}
                                    inputRender={(props: any) => (
                                        <>
                                            <StyledInput {...props} />
                                            <StyledCalendar />
                                        </>
                                    )}
                                />
                            </StyledPicker>
                        </StyledDateInput>
                    </StyledDateTimeRow>
                    <StyledDateTimeRow
                        className="g-0"
                        onClick={selectCustomRangeForExactTarget}
                    >
                        <StyledSmallerTextDiv onClick={selectCustomRange}>
                            <FormattedMessage id="constants.changeEventsFilter.date.to" />
                        </StyledSmallerTextDiv>
                        <StyledDateInput
                            onClick={selectCustomRangeForExactTarget}
                        >
                            <StyledPicker onClick={selectCustomRange}>
                                <Picker<Moment>
                                    ref={endPickerRef}
                                    generateConfig={generateConfig}
                                    locale={{ ...locale, locale: getLocale(), monthFormat: "MMMM" }}
                                    picker={"date"}
                                    panelRender={(originPanel: React.ReactNode) => {
                                        return <StyledPickerPanel ref={endPopuptRef} onClick={(event: any) => event.stopPropagation()}>{originPanel}</StyledPickerPanel>;
                                    }}
                                    dropdownAlign={{
                                        overflow: { adjustX: true, adjustY: true },
                                        targetOffset: [-props.offsetValue, heightOffsetValue],
                                        offset: [axisValueSelector]
                                    }
                                    }
                                    disabledDate={(value: Moment) => value.format("YYYY-MM-DD") < moment(props.start).format("YYYY-MM-DD") || value.format("YYYY-MM-DD") > maxEndDate || (props.startPickerPropertiesInitialMinDate !== undefined && value.format("YYYY-MM-DD") < moment(props.startPickerPropertiesInitialMinDate).format("YYYY-MM-DD"))}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        selectCustomRange();
                                        setIsEndOpen(!isEndOpen);
                                    }}
                                    onOpenChange={(isOpen) => {
                                        // when panel is closing de-focus input
                                        if (!isOpen && endPickerRef && endPickerRef.current) {
                                            endPickerRef.current.blur();
                                        }
                                    }}
                                    open={isEndOpen}
                                    format={(_value: Moment) => moment(props.end).format("YYYY-MM-DD")}
                                    onChange={handleEndDateTimeChange}
                                    nextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                                    prevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                                    superNextIcon={<StyledNextIcon fill={Colors.grey.medium} />}
                                    superPrevIcon={<StyledPrevIcon fill={Colors.grey.medium} />}
                                    value={props.end ? moment(props.end) : props.endPickerPropertiesInitialMaxDate}
                                    dateRender={(current: Moment) => {
                                        const style: React.CSSProperties = {};
                                        style.borderRadius = cellBorderRadius;
                                        const formattedCurrent = current.format("YYYY-MM-DD");
                                        const formattedStart = moment(props.start).format("YYYY-MM-DD");
                                        const formattedEnd = moment(props.end).format("YYYY-MM-DD");
                                        if (formattedCurrent === formattedStart) {
                                            style.color = Colors.white.clearWhite;
                                            style.backgroundColor = Colors.blue.brandingDark;
                                        } else if (formattedCurrent > formattedStart && formattedCurrent < formattedEnd) {
                                            style.backgroundColor = Colors.blue.tailBlue;
                                        } else if (formattedCurrent === moment().format("YYYY-MM-DD") && formattedCurrent !== formattedEnd) {
                                            style.backgroundColor = Colors.grey.almostWhite;
                                            style.fontWeight = heavyFontWeight;
                                        }

                                        return (
                                            // empty title to disable built in tooltip on hover
                                            <div className="rc-picker-cell-inner" style={style} title="">
                                                <StyledDay >{current.date()}</StyledDay>
                                            </div>
                                        );
                                    }}
                                    monthCellRender={(current: Moment) => {
                                        const style: React.CSSProperties = {};
                                        style.borderRadius = cellBorderRadius;
                                        if (current.format("YYYY-MM-DD") === moment(props.end).format("YYYY-MM-DD")) {
                                            style.color = Colors.white.clearWhite;
                                            style.backgroundColor = Colors.blue.brandingDark;
                                        }

                                        current.locale(getLocale());
                                        return (
                                            // empty title to disable built in tooltip on hover
                                            <div className="rc-picker-cell-inner" style={style} title="">
                                                <StyledMonth selected={true} >{current.format("MMM")}</StyledMonth>
                                            </div>);
                                    }}
                                    inputReadOnly={true}
                                    inputRender={(props: any) => (
                                        <>
                                            <StyledInput {...props} />
                                            <StyledCalendar />
                                        </>
                                    )}
                                />
                            </StyledPicker>
                        </StyledDateInput>
                    </StyledDateTimeRow>
                </StyledDropdownContainer>
            </>
        );
    }

    const selectCustomRange = () => {
        if (props.selectedValue !== DateFilter.CustomRange) {
            handleOptionSelect(DateFilter.CustomRange);
        }
    };

    const selectCustomRangeForExactTarget = (event: React.SyntheticEvent<any>) => {
        if (event.currentTarget === event.target) {
            selectCustomRange();
        }
    };

    const handleOptionSelect = (value: any) => {
        props.handleChange(props.name, value);
    };

    const handleStartDateTimeChange = (value: Moment | null) => {
        if (value && props.handleEndDateTimeChange && props.handleStartDateTimeChange) {
            props.handleStartDateTimeChange(value);
            if (props.endPickerPropertiesInitialMaxDate && (!props.end || value.format("YYYY-MM-DD") > moment(props.end).format("YYYY-MM-DD"))) {
                props.handleEndDateTimeChange(props.endPickerPropertiesInitialMaxDate);
            } else if (props.amountOfDays) {
                const endDate = moment(value).add(props.amountOfDays, "d");
                const today = moment();
                if(endDate > today) {
                    props.handleEndDateTimeChange(today);
                } else {
                    props.handleEndDateTimeChange(endDate);
                }
            }
            setIsStartOpen(false);
        }
    };

    const handleEndDateTimeChange = (value: Moment | null) => {
        if (value && props.handleEndDateTimeChange && props.handleStartDateTimeChange) {
            props.handleEndDateTimeChange(value);
            if (!props.start) {
                props.handleStartDateTimeChange(
                    moment(value).subtract(1, "d")
                );
            } else if (props.amountOfDays) {
                const dayDiff = value.diff(props.start, "d");
                if (dayDiff > props.amountOfDays) {
                    props.handleStartDateTimeChange(
                        moment(value).subtract(props.amountOfDays, "d")
                    );
                }
            }
            setIsEndOpen(false);
        }
    };


    return (
        <StyledReactBootstrapDropdownButton
            data-qa={props.qaId}
            id={props.id}
            variant={"light"}
            title={getSelectedOptionLabel()}
            onSelect={handleOptionSelect}
            noOverflow={true}
        >
            {generateInfoMessage(props.infoMessage)}
            {generateDropDownItems()}
            {!props.disableCustomRange && (generateRangeSelector())}
        </StyledReactBootstrapDropdownButton>
    );

};

export default DropdownDateTimeRangeButton;