import { Role, Roles } from "../models/Role";
import store from "../store";
import { fetchConfiguration } from "./configurationSlice";
import { clearContextSlice, fetchGlobalConfig } from "./contextSlice";
import { clearCountrySlice, fetchCountries } from "./countrySlice";
import { clearMarketSlice, fetchMarkets, fetchProducts } from "./marketSlice";
import {
    clearCareGroupSlice,
    fetchCareGroups
} from "./careGroupSlice";
import {
    clearNursingHomeSlice,
    fetchNursingHome,
    fetchNursingHomes
} from "./nursingHomeSlice";
import { clearResidentSlice, fetchResidents } from "./residentSlice";
import {
    clearSpinnerSlice
} from "./spinnerSlice";
import { clearUserSlice } from "./userSlice";
import { clearWardSlice, fetchWards } from "./wardSlice";

export const cleanupRedux = () => {
    store.dispatch(clearContextSlice());
    store.dispatch(clearNursingHomeSlice());
    store.dispatch(clearWardSlice());
    store.dispatch(clearUserSlice());
    store.dispatch(clearResidentSlice());
    store.dispatch(clearCareGroupSlice());
    store.dispatch(clearMarketSlice());
    store.dispatch(clearSpinnerSlice());
    store.dispatch(clearCountrySlice());
    store.dispatch(clearSpinnerSlice());
};

//commenting out fetches because this will be removed for Assay 0 but added for Assay 1 and we kept getting errors 
export const populateRedux = async (role: Role, id: string) => {
    store.dispatch(fetchConfiguration());
    switch (role) {
        case Roles.GlobalAdmin:
            store.dispatch(clearUserSlice());
            store.dispatch(clearNursingHomeSlice());
            store.dispatch(clearCareGroupSlice());
            store.dispatch(fetchCountries());
            store.dispatch(fetchMarkets());
            store.dispatch(fetchNursingHomes());
            store.dispatch(fetchCareGroups());
            store.dispatch(fetchGlobalConfig());
            if (id) {
                store.dispatch(fetchNursingHome());
                // store.dispatch(fetchWards());
                // store.dispatch(fetchResidents());
                // store.dispatch(fetchProducts());
            }
            break;
        case Roles.CountryAdmin:
            store.dispatch(clearUserSlice());
            store.dispatch(clearNursingHomeSlice());
            store.dispatch(clearCareGroupSlice());
            store.dispatch(fetchNursingHomes());
            store.dispatch(fetchCareGroups());
            store.dispatch(fetchGlobalConfig());
            if (id) {
                store.dispatch(fetchNursingHome());
                store.dispatch(fetchWards());
                // store.dispatch(fetchResidents());
                // store.dispatch(fetchProducts());
            }
            break;
        case Roles.NHManager:
        case Roles.Support:
        case Roles.Nurse:
            store.dispatch(fetchMarkets());
            if (id) {
                store.dispatch(fetchNursingHome());
                store.dispatch(fetchWards());
                store.dispatch(fetchResidents());
                store.dispatch(fetchProducts());
            }
            break;
        default:
            break;
    }
};
