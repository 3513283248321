import { localizer } from "../../../../../../utils/Localizer";
import { StyledLeft } from "../../../../../common/table/formatters/PositionContainers";

const StatusFormatter = (props: any) => {
    const { value } = props;
    return (
        <StyledLeft>
            {value ? localizer("constants.active") : localizer("constants.pending") }
        </StyledLeft>
    );
}

export default StatusFormatter;
