import moment, { Moment } from "moment";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    Area,
    Bar, BarChart, CartesianGrid, Cell, ComposedChart, LabelProps,
    Legend,
    LegendProps, Line, ReferenceLine, ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { appInsights } from "../../../AppInsights";
import Colors from "../../../assets/Colors";
import { ReactComponent as SquareChartIcon } from "../../../assets/images/blockChartIcon.svg";
import { ReactComponent as LineChartIcon } from "../../../assets/images/lineChartIcon.svg";
import { ReactComponent as LineRefChartIcon } from "../../../assets/images/referenceLineChartIcon.svg";
import { KpiType } from "../../../models/KpiType";
import { ETrackEvent } from "../../../utils/AppInsightsHelpers";
import { getLocale, localizer } from "../../../utils/Localizer";
import BasicModal from "../../common/modal/BasicModal";
import { EModalSize } from "../../common/modal/ModalWrapper";
import { StyledFormButtons } from "../../common/styled";
import AxisLabel from "./AxisLabel";
import "./Chart.scss";
import { getInterval, getXAxisFormat, selectedTimePeriodChartSwitcher } from "./chartHelper";
import {
    ButtonContainer,
    DateButton, DateButtonLabelContainer, DateComparisonOrText, DifferenceText,
    IconContainer, InfoMessageContainer,
    LegendPeriodContainer,
    StyledCircle, StyledContainer, StyledLegendContainer, StyledLegendDiv, StyledLegendListItem, StyledLegendText, StyledText,
    TooltipContainer, TooltipDateContainer, TooltipInfo, TooltipValueContainer
} from "./ChartStyle";

// constants for the chart
const chartHeight = 350;
interface IYAxisLabelProps {
    labelTranslationKey: string;
}

const YAxisLabel = (props: IYAxisLabelProps) => {
    return (
        <AxisLabel
            axisType="yAxis"
            x={10}
            y={chartHeight / 2 - 45}
            width={0}
            height={0}
            fill={Colors.black}
        >
            <FormattedMessage id={props.labelTranslationKey} />
        </AxisLabel>
    );
};

const CustomLegend = (legendProps: LegendProps & { legendPayload: any[], compareActive: boolean, selectedKpi: KpiType, targetKpi: number | undefined, legendText: string | JSX.Element }) => {
    const { legendPayload, selectedKpi, targetKpi, iconType, legendText } = legendProps;
    const baselineLegend = legendPayload ? legendPayload[0] : undefined;
    const compareLegend = legendPayload ? legendPayload[1] : undefined;
    return (
        <StyledLegendContainer>
            {selectedKpi === KpiType.ResponseTime && (
                <StyledLegendListItem key={"legend-item-reference-line"}>
                    <IconContainer><LineRefChartIcon /></IconContainer>
                    <StyledLegendText color={Colors.black} ><FormattedMessage id={"statistics.referenceLineLegendGroup.targetResponseTime"} values={{ kpiValue: targetKpi }} /></StyledLegendText>

                </StyledLegendListItem>

            )}
            {baselineLegend &&
                (<StyledLegendListItem baseline={true} key={"legend-item-0"}>
                    <IconContainer>
                        {iconType === "line" ? <LineChartIcon stroke={baselineLegend.color} /> : <SquareChartIcon stroke={baselineLegend.color} fill={baselineLegend.color} />}
                    </IconContainer>
                    <StyledLegendText color={baselineLegend.color}>
                        <LegendPeriodContainer>{baselineLegend.value.start} - {baselineLegend.value.end}</LegendPeriodContainer>
                        <StyledText>{legendText} {baselineLegend.value.formattedValue}</StyledText>
                    </StyledLegendText>
                </StyledLegendListItem>)
            }
            {compareLegend && (
                <StyledLegendListItem key={"legend-item-1"}>
                    <IconContainer>
                        {iconType === "line" ? <LineChartIcon stroke={compareLegend.color} /> : <SquareChartIcon stroke={compareLegend.color} fill={compareLegend.color} />}
                    </IconContainer>
                    <StyledLegendText color={compareLegend.color}>
                        <LegendPeriodContainer>{compareLegend.value.start} - {compareLegend.value.end}</LegendPeriodContainer>
                        <StyledText>{legendText} {compareLegend.value.formattedValue}</StyledText>
                        {compareLegend.value.formattedDifference && (
                            <DifferenceText><FormattedMessage id="statistics.legend.difference" />: {compareLegend.value.formattedDifference}</DifferenceText>
                        )}
                    </StyledLegendText>
                </StyledLegendListItem>

            )}
        </StyledLegendContainer>);
};

const CustomTooltip = (props: any) => {
    const { active, payload, selectedKpi, getFormattedTooltipValue, getInfoMessage } = props;
    if (active && payload && payload.length) {
        const baseline = payload[0];
        const compare = payload[1];
        const locale = getLocale();
        const baselineFormattedDate = moment(baseline.payload.date).locale(locale).format("DD MMMM YYYY");
        const compareFormattedDate = compare ? moment(compare.payload.compareDate).locale(locale).format("DD MMMM YYYY") : "";
        return (
            <TooltipContainer>
                <TooltipInfo bold color={Colors.blue.brandingDark}>
                    <TooltipDateContainer>{baselineFormattedDate}</TooltipDateContainer>
                    <TooltipValueContainer>{getFormattedTooltipValue(baseline.value, selectedKpi, baseline.payload.selectedWardPercentage)}</TooltipValueContainer>
                </TooltipInfo>
                {compare && (
                    <TooltipInfo color={Colors.teal.selected}>
                        <TooltipDateContainer>{compareFormattedDate}</TooltipDateContainer>
                        <TooltipValueContainer>{getFormattedTooltipValue(compare.value, selectedKpi, compare.payload.selectedCompareWardPercentage)}</TooltipValueContainer>
                    </TooltipInfo>
                )}
                {getInfoMessage(baselineFormattedDate, compareFormattedDate)}
            </TooltipContainer>
        );
    }

    return null;
};


const XAxisLabel = (props: { x: number, y: number, dateStrings: string[] }) => (
    <g transform={`translate(${props.x},${props.y})`}>
        <text
            x={0}
            y={0}
            dy={16}
            textAnchor="middle"
            fill={Colors.grey.medium}
            fontSize={14}
            style={{ textTransform: "capitalize" }}
        >
            {props.dateStrings[0]}
        </text>
        <text
            x={0}
            y={15}
            dy={16}
            textAnchor="middle"
            fill={Colors.grey.medium}
            fontSize={14}
            style={{ textTransform: "capitalize" }}
        >
            {props.dateStrings[1]}
        </text>

    </g>
);

interface IChartProps {
    chartData: any[];
    dataKey: string;
    compareDataKey: string;
    maxYAxisValue: number;
    targetKpiValue?: number;
    selectedKpi: KpiType;
    selectedKpiValue: number | null | any;
    compareKpiValue: number | null | any;
    interval: number;
    startDate: Moment;
    compareStartDate: Moment | null;
    endDate: Moment;
    compareEndDate: Moment | null;
    percentageKpiValue?: number | null;
    comparePercentageKpiValue?: number | null;
    getFormattedTooltipValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number) => any;
    getFormattedLegendValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number | null) => any;
    getFormattedDifference: (selectedKpi: KpiType, difference: number, percentageDifference?: number) => any;
    enableCustomHovering?: boolean;
    navigationLink?: string;
    getYLabel?: (selectedKpi: KpiType) => string;
    getLegendText: (selectedKpi: KpiType) => string | JSX.Element;
}

export const Chart = (props: IChartProps) => {
    const navigate = useNavigate();
    const [activeBar, setActiveBar] = useState(-1);
    const [mouseLeave, setMouseLeave] = useState(true);
    const [activeCompareBar, setCompareActiveBar] = useState(-1);
    const [mouseCompareLeave, setCompareMouseLeave] = useState(true);
    const [popup, setPopup] = useState(false);
    const [hoveredComparisonDate, setHoveredComparisonDate] = useState<string>("");
    const [hoveredBaselineDate, setHoveredBaselineDate] = useState<string>("")
    const legendPayload: any[] = [];
    const currentLocale = getLocale();

    const SpecialCircle = (circleProps: any) => {
        const baseline = circleProps.payload[props.dataKey];
        const compare = circleProps.payload[props.compareDataKey]
        return <StyledCircle
            onMouseOver={() => {
                setHoveredComparisonDate(circleProps.payload.compareDate)
            }}
            onMouseLeave={() => {
                setHoveredComparisonDate("")
            }}
            onClick={() => {
                if (baseline === compare) {
                    setPopup(true)
                    setHoveredBaselineDate(circleProps.payload.date)
                    setHoveredComparisonDate(circleProps.payload.compareDate)
                } else {
                    appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
                    navigate({ pathname: props.navigationLink, search: circleProps.payload.compareDate });
                }
            }}
            cx={circleProps.cx}
            cy={circleProps.cy}
            r={4}
            stroke={Colors.teal.selected}
            strokeWidth={2}
            fill="white" />
    }

    const orderedData = props.chartData.sort((a, b) =>
        moment(a.date).isAfter(moment(b.date)) ? 1 : -1
    );

    const getYLabel = (selectedKpi: KpiType) => {
        switch (selectedKpi) {
            case KpiType.ResponseTime:
                return "statistics.chart.minutes";
            case KpiType.AvgNightTimeMinutesBetweenChanges:
                return "statistics.chart.hours";
            case KpiType.PromptedProductChanges:
                return "statistics.chart.percent";
            default:
                return "statistics.chart.changes";
        }
    }

    const getBarChartInfoMessage = (baselineDate: string, compareDate: string) => {
        if (props.enableCustomHovering) {
            if (hoveredBaselineDate.length > 0) {
                return (
                    <InfoMessageContainer>
                        <FormattedMessage
                            id="resident.statistics.tooltip.barInfoDate.general"
                            values={{
                                date: <b>{moment(hoveredBaselineDate).locale(currentLocale).format("DD MMMM YYYY")}</b>
                            }} />
                    </InfoMessageContainer>
                )
            } else if (hoveredComparisonDate.length > 0) {
                return (
                    <InfoMessageContainer><FormattedMessage
                        id="resident.statistics.tooltip.barInfoDate.general"
                        values={{
                            date: <b>{moment(hoveredComparisonDate).locale(currentLocale).format("DD MMMM YYYY")}</b>
                        }} />
                    </InfoMessageContainer>)
            } else {
                if (compareDate.length > 0) {
                    return (
                        <InfoMessageContainer><FormattedMessage
                            id="resident.statistics.tooltip.barInfo" />
                        </InfoMessageContainer>
                    )
                }
                return (
                    <InfoMessageContainer>
                        <FormattedMessage
                            id="resident.statistics.tooltip.barInfoDate"
                            values={{
                                date: <b>{baselineDate}</b>
                            }} />
                    </InfoMessageContainer>
                )
            }
        }
        return <></>;
    }

    const getLineChartInfoMessage = (baselineDate: string, compareDate: string) => {
        if (props.enableCustomHovering) {
            if (hoveredBaselineDate.length > 0 && hoveredComparisonDate.length > 0) {
                return (
                    <InfoMessageContainer>
                        <FormattedMessage id="resident.statistics.tooltip.dotInfo" />
                    </InfoMessageContainer>
                )
            } else if (hoveredBaselineDate.length > 0) {
                return (
                    <InfoMessageContainer>
                        <FormattedMessage
                            id="resident.statistics.tooltip.dotInfoDate"
                            values={{
                                date: <b>{moment(hoveredBaselineDate).locale(currentLocale).format("DD MMMM YYYY")}</b>
                            }} />
                    </InfoMessageContainer>
                )
            } else if (hoveredComparisonDate.length > 0) {
                return (
                    <InfoMessageContainer>
                        <FormattedMessage
                            id="resident.statistics.tooltip.dotInfoDate"
                            values={{
                                date: <b>{moment(hoveredComparisonDate).locale(currentLocale).format("DD MMMM YYYY")}</b>
                            }} />
                    </InfoMessageContainer>
                )
            } else {
                if (compareDate.length > 0) {
                    return (
                        <InfoMessageContainer>
                            <FormattedMessage id="resident.statistics.tooltip.dotInfo" />
                        </InfoMessageContainer>
                    )
                }
                return (
                    <InfoMessageContainer>
                        <FormattedMessage
                            id="resident.statistics.tooltip.dotInfoDate"
                            values={{
                                date: <b>{baselineDate}</b>
                            }} />
                    </InfoMessageContainer>)
            }
        }
        return <></>;
    }

    const yAxisLabel: LabelProps = {
        content: (
            <YAxisLabel
                labelTranslationKey={props.getYLabel ? props.getYLabel(props.selectedKpi) : getYLabel(props.selectedKpi)}
            />
        )
    };

    const xAxisInterval = getInterval(props.interval);

    const start = props.startDate.locale(currentLocale).format("DD MMMM YYYY");
    const end = props.endDate.locale(currentLocale).format("DD MMMM YYYY");
    const selectedKpi = props.selectedKpi;
    const targetKpi = props.targetKpiValue;
    const formattedValue = props.getFormattedLegendValue(props.selectedKpiValue, props.selectedKpi, props.percentageKpiValue);
    const compareFormattedValue = props.getFormattedLegendValue(props.compareKpiValue, props.selectedKpi, props.comparePercentageKpiValue);
    const percentageDifference = props.percentageKpiValue && props.comparePercentageKpiValue ? Math.abs(props.percentageKpiValue - props.comparePercentageKpiValue) : undefined;
    const formattedDifference = props.selectedKpiValue && props.compareKpiValue ? props.getFormattedDifference(props.selectedKpi, Math.abs(props.selectedKpiValue - props.compareKpiValue), percentageDifference) : undefined;

    legendPayload.push({
        id: 1,
        value: {
            formattedValue,
            start,
            end,
            percentage: props.percentageKpiValue
        },
        type: "square",
        color: Colors.blue.brandingDark
    });
    if (props.compareStartDate && props.compareEndDate) {
        legendPayload.push({
            id: 2,
            value: {
                formattedValue: compareFormattedValue,
                start: props.compareStartDate.locale(currentLocale).format("DD MMMM YYYY"),
                end: props.compareEndDate.locale(currentLocale).format("DD MMMM YYYY"),
                formattedDifference,
                percentage: props.comparePercentageKpiValue
            },
            type: "square",
            color: Colors.teal.selected
        });
    }

    const baselineBarProps = props.enableCustomHovering ? {
        onClick: (data: any) => {
            appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
            navigate({ pathname: props.navigationLink, search: data.date });
        },
        onMouseLeave: () => {
            setHoveredBaselineDate("");
            setMouseLeave(true);
            setCompareMouseLeave(true);
            setActiveBar(-1);
        },
        onMouseOver: (data: any, index: number) => {
            if (index >= 0) {
                setHoveredBaselineDate(data.date);
                setActiveBar(index);
                setMouseLeave(false);
                setCompareMouseLeave(false);
            } else {
                setHoveredBaselineDate("");
                setActiveBar(-1);
                setMouseLeave(true);
                setCompareMouseLeave(true);
            }

        }
    } : {};

    const compareBarProps = props.enableCustomHovering ? {
        onClick: (data: any) => {
            appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
            navigate({ pathname: props.navigationLink, search: data.compareDate });
        },
        onMouseLeave: () => {
            setCompareMouseLeave(true);
            setMouseLeave(true);
            setCompareActiveBar(-1);
            setHoveredComparisonDate("")
        },
        onMouseOver: (data: any, index: any) => {
            if (index >= 0) {
                setHoveredComparisonDate(data.compareDate)
                setCompareActiveBar(index);
                setMouseLeave(false);
                setCompareMouseLeave(false);
            } else {
                setHoveredComparisonDate("")
                setCompareActiveBar(-1);
                setCompareMouseLeave(true);
                setMouseLeave(true);
            }

        }
    } : {};

    const chart: React.ReactNode = (
        <>
            <ResponsiveContainer width="90%" height={chartHeight}>
                {props.interval > selectedTimePeriodChartSwitcher ?
                    (
                        <ComposedChart
                            margin={{ top: 5, right: 20, bottom: 5, left: 10 }}
                            data={orderedData}
                        >
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="35%" stopColor={Colors.blue.brandingDark} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={Colors.blue.brandingDark} stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke={Colors.grey.lightGrey} vertical={false} />
                            <Tooltip content={
                                <CustomTooltip
                                    selectedKpi={props.selectedKpi}
                                    getFormattedTooltipValue={props.getFormattedTooltipValue}
                                    getInfoMessage={getLineChartInfoMessage}
                                />} />
                            <Area
                                dataKey={props.dataKey}
                                type={"monotone"}
                                strokeWidth={3}
                                stroke={Colors.blue.brandingDark}
                                fill={"url(#colorUv)"}
                                strokeLinejoin="miter"
                                activeDot={props.enableCustomHovering ?
                                    (activeDotProps) => <StyledCircle
                                        onMouseOver={() => {
                                            setHoveredBaselineDate(activeDotProps.payload.date)
                                        }}
                                        onMouseLeave={() => {
                                            setHoveredBaselineDate("")
                                        }}
                                        onClick={() => {
                                            navigate({ pathname: props.navigationLink, search: activeDotProps.payload.date });
                                            appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
                                        }}
                                        cx={activeDotProps.cx}
                                        cy={activeDotProps.cy}
                                        r={4}
                                        stroke={Colors.blue.tenaBlue}
                                        strokeWidth={3}
                                        fill="white"
                                    />
                                    :
                                    (activeDotProps) => <circle cx={activeDotProps.cx} cy={activeDotProps.cy} r={4} stroke={Colors.blue.tenaBlue} strokeWidth={3} fill="white" />}
                            />
                            {props.compareStartDate && props.compareEndDate && (
                                <Line
                                    type="monotone"
                                    strokeWidth={3}
                                    dot={false}
                                    dataKey={props.compareDataKey}
                                    stroke={Colors.teal.selected}
                                    activeDot={props.enableCustomHovering ?
                                        (activeDotProps) => <SpecialCircle {...activeDotProps} />
                                        :
                                        (activeDotProps) => <circle cx={activeDotProps.cx} cy={activeDotProps.cy} r={4} stroke={Colors.teal.selected} strokeWidth={3} fill="white" />
                                    }
                                />
                            )}
                            <XAxis
                                height={70}
                                dataKey="date"
                                interval={xAxisInterval}
                                tick={(props) => {
                                    const tickMoment = moment(props.payload.value).locale(currentLocale);
                                    const formattedValue = tickMoment.format(getXAxisFormat(xAxisInterval)).toString();
                                    const dateStrings = formattedValue.split(" ");
                                    return <XAxisLabel x={props.x} y={props.y} dateStrings={dateStrings} />;
                                }}
                            />
                            <YAxis
                                domain={[0, props.maxYAxisValue]}
                                type="number"
                                interval={0}
                                orientation="left"
                                tickCount={8}
                                allowDecimals={false}
                                label={yAxisLabel}
                                tick={{ fill: Colors.grey.medium, fontSize: 14 }}
                            />
                            {(props.selectedKpi === KpiType.ResponseTime) && (
                                <ReferenceLine
                                    y={props.targetKpiValue}
                                    stroke={Colors.grey.medium}
                                    strokeDasharray="4 4"
                                />
                            )}
                        </ComposedChart>
                    )

                    : (
                        <BarChart
                            margin={{ top: 30, right: 20, bottom: 0, left: 0 }}
                            data={orderedData}
                            barGap={0}
                        >
                            <defs>
                                <linearGradient id="baselineColor" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="45%" stopColor={Colors.blue.brandingDark} stopOpacity={1} />
                                    <stop offset="95%" stopColor={Colors.blue.brandingDark} stopOpacity={0.9} />
                                </linearGradient>
                                <linearGradient id="compareColor" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="45%" stopColor={Colors.teal.selected} stopOpacity={1} />
                                    <stop offset="95%" stopColor={Colors.teal.selected} stopOpacity={0.8} />
                                </linearGradient>
                            </defs>
                            <CartesianGrid stroke={Colors.grey.lightGrey} vertical={false} />
                            <Tooltip
                                cursor={{ fill: "transparent" }}
                                content={
                                    <CustomTooltip
                                        selectedKpi={props.selectedKpi}
                                        getFormattedTooltipValue={props.getFormattedTooltipValue}
                                        getInfoMessage={getBarChartInfoMessage} />} />
                            <XAxis
                                height={70}
                                dataKey="date"
                                interval={xAxisInterval}
                                tick={(props) => {
                                    const tickMoment = moment(props.payload.value).locale(currentLocale);
                                    const formattedValue = tickMoment.format(getXAxisFormat(xAxisInterval)).toString();
                                    const dateStrings = formattedValue.split(" ");
                                    return <XAxisLabel x={props.x} y={props.y} dateStrings={dateStrings} />;
                                }}
                            />
                            <YAxis
                                domain={[0, props.maxYAxisValue]}
                                type="number"
                                interval={0}
                                orientation="left"
                                tickCount={8}
                                allowDecimals={false}
                                label={yAxisLabel}
                                tick={{ fill: Colors.grey.medium, fontSize: 14 }}
                            />
                            <Bar
                                dataKey={props.dataKey}
                                barSize={20}
                                fill={"url(#baselineColor)"}
                                {...baselineBarProps}
                            >
                                {orderedData.map((_entry, index) => {
                                    return <Cell style={{ transition: "fill 1s" }} cursor={props.enableCustomHovering ? "pointer" : "default"} fill={(activeBar === index || mouseLeave) ? "#1D388B" : "rgba(29, 56, 139, 0.7)"} key={`cell-${index}`} />
                                })}
                            </Bar>
                            {props.compareStartDate && (
                                <Bar
                                    dataKey={props.compareDataKey}
                                    barSize={20}
                                    fill={"url(#compareColor)"}
                                    {...compareBarProps}
                                >
                                    {orderedData.map((_entry, index) => {
                                        return <Cell style={{ transition: "fill 1s" }} cursor={props.enableCustomHovering ? "pointer" : "default"} fill={activeCompareBar === index || mouseCompareLeave ? "rgba(3, 154, 178)" : "rgba(3, 154, 178, 0.7)"} key={`cell-${index}`} />
                                    })}
                                </Bar>
                            )}
                            {(props.selectedKpi === KpiType.ResponseTime) && (
                                <ReferenceLine
                                    y={props.targetKpiValue}
                                    stroke={Colors.grey.medium}
                                    strokeDasharray="4 4"
                                    strokeWidth={2}
                                />
                            )}
                        </BarChart>
                    )}
            </ResponsiveContainer>
            <BasicModal
                width={EModalSize.XS}
                show={popup}
                handleOnHide={() => {
                    setPopup(false)
                    setHoveredBaselineDate("")
                    setHoveredComparisonDate("")
                }}
                title={localizer("resident.statistics.historyPopup.title")}

            >
                <>
                    {localizer("resident.statistics.historyPopup.info")}:
                    <ButtonContainer>
                        <div>
                            <DateButtonLabelContainer>{localizer("resident.statistics.historyPopup.timePeriod")}:</DateButtonLabelContainer>
                            <DateButton
                                color={Colors.blue.brandingDark}
                                onClick={() => {
                                    appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
                                    navigate({ pathname: props.navigationLink, search: hoveredBaselineDate });
                                }}
                            >
                                {moment(hoveredBaselineDate).locale(currentLocale).format("DD MMMM YYYY")}
                            </DateButton>

                        </div>
                        <DateComparisonOrText>
                            {localizer("resident.statistics.historyPopup.or")}
                        </DateComparisonOrText>
                        <div>
                            <DateButtonLabelContainer>{localizer("resident.statistics.historyPopup.comparisonPeriod")}:</DateButtonLabelContainer>
                            <DateButton
                                color={Colors.teal.selected}
                                onClick={() => {
                                    appInsights.trackEvent({ name: ETrackEvent.JumpToHistory });
                                    navigate({ pathname: props.navigationLink, search: hoveredComparisonDate });
                                }}
                            >
                                {moment(hoveredComparisonDate).locale(currentLocale).format("DD MMMM YYYY")}
                            </DateButton>

                        </div>
                    </ButtonContainer>
                    <StyledFormButtons>

                        <Button
                            data-qa="cancel_button"
                            variant={"outline-secondary"}
                            type="button"
                            className="discard-button"
                            onClick={() => {
                                setPopup(false)
                                setHoveredBaselineDate("")
                                setHoveredComparisonDate("")
                            }}
                        >
                            <FormattedMessage id="addEditButton.cancel" />
                        </Button>
                    </StyledFormButtons>
                </>
            </BasicModal>
        </>
    );

    return (
        <div style={{ position: "relative" }}>
            <StyledContainer>
                {chart}
                <StyledLegendDiv>
                    <Legend
                        wrapperStyle={{ marginLeft: "120px" }}
                        align="left"
                        verticalAlign={"bottom"}
                        content={
                            <CustomLegend
                                legendPayload={legendPayload}
                                compareActive={true}
                                targetKpi={targetKpi}
                                selectedKpi={selectedKpi}
                                iconType={props.interval > 15 ? "line" : "square"}
                                legendText={props.getLegendText(selectedKpi)}
                            />}
                    />
                </StyledLegendDiv>
            </StyledContainer>
        </div>
    );
};
