import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { ReactComponent as CloseIcon } from "../../../assets/images/dropdownClose.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";

const StyledCloseIcon = styled(CloseIcon)`
    height: 16px;
    position: absolute;
    right: 6px;
    top: 8px;
    cursor: pointer;
    fill: ${Colors.blue.darkDisabled};
    :hover{
        fill: ${Colors.blue.brandingDark};
    }
`;

const StyledSearchIcon = styled(SearchIcon)`
    height: 16px;
    position: absolute;
    left: 6px;
    top: 8px;
    cursor: text;
`;


const Container = styled.div`
    position: relative;
    max-width: 300px;
    width: 100%;
    margin-bottom: 15px;
`;

const CustomInput = styled.input`
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 1px solid ${Colors.blue.darkDisabled};
    width: 100%;
    height: 30px;
    padding-left: 30px;
    padding-right: 40px;
    &:hover {
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
    }
    &:focus {
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 1px solid ${Colors.blue.brandingDark};
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
        outline: none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${Colors.blue.darkDisabled};
    }
    ::-ms-input-placeholder {
        color: ${Colors.black};
    }
`;
interface IFilterInputProps {
    value: string;
    onChange: (value: any) => void;
    placeholder?: string;
}
const FilterInput = (props: IFilterInputProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }

    return (
        <Container>
            <StyledSearchIcon stroke={Colors.blue.brandingDark} />
            <CustomInput
                {...props}
                type={"text"}
                value={props.value as string}
                onChange={handleChange}
            />
            {props.value !== "" && props.value !== undefined && <StyledCloseIcon onClick={() => props.onChange("")} />}
        </Container>
    );
};

export default FilterInput;
