
import styled from "styled-components";
import Colors from "../assets/Colors";
import { StyledATag } from "../components/common/View";
import { mobileThresholdWidth } from "../settings/Settings";

export const FooterContainer = styled.div`
    @media (max-width: ${mobileThresholdWidth}px) {
        display: none;
    }
    width: 100%;
    background-color: ${Colors.grey.tenaLight};
`;

export const FooterInfoContainer = styled.div`
    gap: 12px;
    display: flex;
    color: ${Colors.black};
    height: 140px;
    font-size: 14px;
    background-color: ${Colors.grey.tenaLight};
    padding: 25px 20px;
    justify-content: space-between;
    max-width: 1440px;
    margin: auto;
`;

export const StyledButtonWrapper = styled.div`
    button {
        background-color: transparent !important;
        border: 0px !important;
        color: ${Colors.black} !important;
        font-size: 14px !important;
        padding: 0px !important;
        text-decoration: underline;
        &:hover {
            color: ${Colors.blue.tenaBlueHover};
        }
    }
`;

export const StyledButton = styled.button`
    background-color: transparent !important;
    border: 0px !important;
    color: ${Colors.black} !important;
    text-decoration: underline;
    font-size: 14px !important;
    padding: 0px !important;   
    display: inline-flex;
    align-items: flex-start;
    &:hover {
        color: ${Colors.blue.tenaBlueHover};
    }
`;

export const CustomATag = styled(StyledATag)`
    color: ${Colors.black};
    text-decoration: underline;
    &:hover { 
        color: ${Colors.black};
    }
    margin-block: auto;
`;

export const InfoContainer = styled.div`
    display: flex;
    gap: 10px;
`;

export const Address = styled.p`
    float: left;
    font-size: 14px;
    line-height: 19px;
    white-space: pre-line;
`;

export const ButtonsContainer = styled.div`
display: flex;
    gap: 10px;
`;

export const InfoIconContainer = styled.div`
    display: flex;
`;


export const CopyrightContainer = styled.div`
    gap: 10px;
    display: flex;
`;

export const FirstRow = styled.div`
    height: 56px;
    display: flex;
    max-width: 1440px;
    margin: auto;
    font-size: 14px;
    gap: 40px;
    justify-content: space-between;
    div {
        margin-block: auto;
    }

    svg {
        margin-block: auto;
    }
    padding: 0 20px;
`;

export const Divider = styled.div`
    height: 1px;
    background-color: #CBCBCB;
`;

export const AdressContainer = styled.div<any>`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.width ? props.width : 205}px;
`;

export const REFContainer = styled.div`
    display: flex;
    gap: 5px;
`;
