import styled from "styled-components";
import Colors from "../../../assets/Colors";

export const StyledCircle = styled.circle`
    :hover {
        cursor: pointer;
        stroke-width: 8;
        transition: stroke-width 1s;
        transition: fill 0.2s;
    }
`
export const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
`;

export const StyledLegendDiv = styled.div`
    margin-top: 40px;
`;

export const StyledLegendListItem = styled.li<any>`
    ${(props) => props.baseline ? "font-weight: 700;" : ""}
    list-style-type: none;
    margin-right: 70px;
`;

export const StyledLegendText = styled.span`
    margin-left: 5px;
    font-size: 14px;
    color: ${props => props.color}
`;

export const StyledLegendContainer = styled.div`
    font-size: 14px;
    text-align: left;
`;

export const StyledText = styled.span`
    margin-left: 15px;
`;

export const DifferenceText = styled.span`
    color: ${Colors.black};
    margin-left: 60px;
`;

export const IconContainer = styled.span`
    width: 35px;
`;

export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    background-color: ${Colors.white.clearWhite};
    border: 1px solid ${Colors.grey.lightGrey};
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    `;

export const TooltipInfo = styled.div<any>`
    display: flex;
    flex-direction: row;
    font-family: Asap;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    justify-content: space-between;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
    color: ${props => props.color};
    ${props => props.bold ? "font-weight: bold;" : ""};
    `;

export const TooltipDateContainer = styled.div`
    width: 140px;
    `;

export const TooltipValueContainer = styled.div`
    min-width: 160px;
    align: right;
    `;

export const LegendPeriodContainer = styled.span`
    width: 280px;
    display: inline-block;
    text-transform: capitalize;
    `;

export const DateButton = styled.button<any>`
    height: 48px;
    width: 250px;
    background-color: ${props => props.color};
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border-radius: 4px;
    border-color: transparent;
    font-weight: bold;
    `;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;
    span {
        align-self: center;
    }
    `;

export const DateButtonLabelContainer = styled.div`
    margin-bottom: 5px;
`;

export const DateComparisonOrText = styled.div`
    line-height: 100px;
`;

export const InfoMessageContainer = styled.div`
    font-size: 12px;
    margin: 8px 8px 0px 8px;
`;
