import { createSlice } from "@reduxjs/toolkit";
import { RestClient } from "../data/RestClient";
import { EMaintenanceStatus } from "../models/EMaintenanceStatus";

export interface IConfigurationStateType {
    cookieScriptExecution: boolean;
    releaseNotification: EMaintenanceStatus;
}

const initialState: IConfigurationStateType = {
    cookieScriptExecution: false,
    releaseNotification: EMaintenanceStatus.None
};

export const configurationSlice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setCookieScripExecution: (state, action) => {
            state.cookieScriptExecution = action.payload;
        },
        setReleaseNotification: (state, action) => {
            state.releaseNotification = action.payload;
        },
    },
});

export const {
    setCookieScripExecution,
    setReleaseNotification
} = configurationSlice.actions;

export const fetchConfiguration = () => async (dispatch: any) => {
    const { enableCookieScriptExecution, releaseNotification } = await RestClient.getConfiguration();
    dispatch(setCookieScripExecution(enableCookieScriptExecution));
    dispatch(setReleaseNotification(releaseNotification));
};

export default configurationSlice.reducer;
