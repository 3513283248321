import React from "react";
import { FormattedMessage } from "react-intl";
import Table, { headerJustifyOptions } from "../../common/table/Table";
import GoTo from "./CellFormatters/ExternalGoTo";
import LinkedText from "./CellFormatters/LinkedText";

interface IHelpTableProps {
    data: { name: string, link: string }[];
    headerId: string;
    qaId: string;
}

const HelpTable = (props: IHelpTableProps) => {

    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <FormattedMessage id={props.headerId} />
                ),
                accessor: "name",
                Cell: LinkedText,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="general.tableHeader.goTo" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: "link",
                Cell: GoTo,
                disableSortBy: true,
                maxWidth: 160
            },
        ], [props.headerId]
    );
    const data = React.useMemo(() =>
        props.data || []
        , [props.data]);

    return (
        <Table
            qaId={props.qaId}
            columns={columns}
            data={data}
        />
    );
};

export default React.memo(HelpTable);
