import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Colors from "../../../assets/Colors";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Ward } from "../../../models/Ward";
import AddWardButton from "./AddWardButton";
import EditWardButton from "./EditWardButton";
import {CareFacility, IntegrationType} from '../../../models/CareFacility'
import {ExternalIntegrationInformationBlock} from '../../common/informationBlock/ExternalIntegrationInformationBlock'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: ${Colors.white.clearWhite};
    height: 100%;
    padding-top: 30px;
    max-width: 650px;
`;

const StyledHeader = styled.div`
    color: ${Colors.black};
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: space-between;
`;

const StyledScrollableContainer = styled.div`
    height: 600px;
    overflow-x: auto;
`;

const StyledRow = styled.div`
    color: ${Colors.black};
    display: flex;
    padding: 11px 18px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    min-height: 55px;
    font-size: 16px;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #E0E0E0;
    flex-wrap: nowrap;
`;

const StyledSvg = styled.svg<any>`
    circle {
        fill: ${props => props.isActive ? "#85ba35" : "#e1e8e7"};
    }
    margin-right: 11px;
`;

const StyledName = styled.div`
    word-break: break-all;
`;

interface IWardListItemProps {
    ward: Ward;
    readonly: boolean;
}

const WardListItem = (props: IWardListItemProps) => {
    return (
        <StyledRow>
            <StyledName>
                <StyledSvg height="13px" width="13px" isActive={props.ward.isActive}>
                    <circle
                        cx="50%"
                        cy="50%"
                        r="50%"
                    />
                </StyledSvg>
                {props.ward.name}
            </StyledName>
            <EditWardButton disabled={props.readonly} ward={props.ward} />
        </StyledRow>
    );
};

const WardList = () => {
    const wards = useAppSelector<Ward[]>(state => state.wardSlice.wards);
    const activeNursingHome = useAppSelector<CareFacility | null>(state => state.nursingHomeSlice.nursingHome);
    const {
        externalIntegrationType = null
    } = activeNursingHome || {}
    const readOnly = externalIntegrationType !== null && externalIntegrationType !== IntegrationType.Amba

    return (
        <>
            {readOnly && (
                <ExternalIntegrationInformationBlock sectionTranslationKey='externalIntegration.ward.informationMessage' />
            )}
            <StyledContainer>
                <StyledHeader>
                    <FormattedMessage id="home.wards" />
                    <AddWardButton disabled={readOnly} />
                </StyledHeader>
                <StyledScrollableContainer>
                    {wards.map(ward => (
                        <WardListItem
                            key={ward.name}
                            ward={ward}
                            readonly={readOnly}
                        />
                    ))}
                </StyledScrollableContainer>
            </StyledContainer>
        </>
    );
};

export default WardList;
