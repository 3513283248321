import styled from "styled-components";
import Colors from "../../../../assets/Colors";
import { KpiType } from "../../../../models/KpiType";
import { IStatisticValues } from "../../../../models/StatisticValues";
import { localizer } from "../../../../utils/Localizer";
import { getTrendIcon, getTrendValue } from "../../../../utils/StatisticsHelpers";
import NightTimeBlock from "../../../common/informationBlock/NightTimeBlock";
import NumberInformationBlock from "../../../common/informationBlock/NumberInformationBlock";
import PromptedChangesBlock from "../../../common/informationBlock/PromptedChangesBlock";
import ResponseTimeBlock from "../../../common/informationBlock/ResponseTimeBlock";
import { ClickableStyledInformationBlockWrapper, EBlockSize, IconContainer, StyledTrendContainer, StyledValueContainer } from "../../../common/informationBlock/styled";
import { StyledBoldSpan, StyledInformationBlocks } from "../../../common/View";

const ProductContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ProductSectionContainer = styled.div`
    span {
        font-size: 14px;
        color:${Colors.grey.medium};
        text-transform: uppercase;
        margin-right: 30px;
    }
    display: grid;
    height: 74px;
    line-height: 28px;
`;

const StyledAverageC = styled.div`
    align-self: self-end;
`;

interface IResidentStatisticsKPIView {
    selectedKpi: KpiType;
    handleDropdownSelection: (name: string, value: any) => void;
    responseTime: IStatisticValues;
    productChangesAverage: IStatisticValues;
    productChangesTotal: IStatisticValues;
    promptedProductChanges: IStatisticValues;
    nightTimeMinutesBetweenChanges: IStatisticValues;
}

const ResidentStatisticsKPIView = (props: IResidentStatisticsKPIView) => {

    const {
        handleDropdownSelection,
        nightTimeMinutesBetweenChanges,
        productChangesAverage,
        productChangesTotal,
        promptedProductChanges,
        responseTime,
        selectedKpi
    } = props

    return (
        <StyledInformationBlocks>
            <ResponseTimeBlock
                size={EBlockSize.L}
                selectedKpi={selectedKpi}
                responseTime={responseTime}
                handleDropdownSelection={handleDropdownSelection}
            />
            <ClickableStyledInformationBlockWrapper
                size={EBlockSize.L}
                alignItems="start"
                isSelected={
                    selectedKpi === KpiType.AllProductChanges
                }
                onClick={() =>
                    handleDropdownSelection(
                        "kpiSelected",
                        KpiType.AllProductChanges
                    )
                }
            >
                <NumberInformationBlock
                    header={localizer("constants.changeEventsFilter.kpi.productChanges")}
                    isSelected={selectedKpi === KpiType.AllProductChanges}
                    value={
                        <ProductContainer>
                            <ProductSectionContainer>
                                <span>{localizer("constants.changeEventsFilter.kpi.productChanges.total")}</span>
                                <StyledValueContainer>
                                    <StyledBoldSpan>{(productChangesTotal && productChangesTotal.value) ? productChangesTotal.value : "--"}</StyledBoldSpan>
                                </StyledValueContainer>
                            </ProductSectionContainer>
                            <ProductSectionContainer>
                                <span>{localizer("constants.changeEventsFilter.kpi.productChanges.average")}</span>
                                <StyledAverageC>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{(productChangesAverage && productChangesAverage.value) ? Math.round(productChangesAverage.value * 10) / 10 : "--"}</StyledBoldSpan>
                                    </StyledValueContainer>
                                    {productChangesAverage && productChangesAverage.value ? (
                                        <StyledTrendContainer>
                                            <IconContainer>{getTrendIcon(productChangesAverage.trend)}</IconContainer>
                                            <div>{getTrendValue(productChangesAverage.trend)}{productChangesAverage.trend !== null && "%"}</div>
                                        </StyledTrendContainer>
                                    ) : <></>}
                                </StyledAverageC>
                            </ProductSectionContainer>
                        </ProductContainer>
                    }
                />
            </ClickableStyledInformationBlockWrapper>
            <PromptedChangesBlock
                size={EBlockSize.L}
                selectedKpi={selectedKpi}
                promptedProductChanges={promptedProductChanges}
                handleDropdownSelection={handleDropdownSelection}
            />
            <NightTimeBlock
                size={EBlockSize.L}
                selectedKpi={selectedKpi}
                nightTimeMinutesBetweenChanges={nightTimeMinutesBetweenChanges}
                handleDropdownSelection={handleDropdownSelection}
            />
        </StyledInformationBlocks>
    )
}

export default ResidentStatisticsKPIView;