import React from "react";
import { FormattedMessage } from "react-intl";
import Colors from "../../../assets/Colors";
import DayIcon from "../../../assets/images/dayIcon";
import NightIcon from "../../../assets/images/nightIcon";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Resident } from "../../../models/Resident";
import { Ward } from "../../../models/Ward";
import { selectActiveWards } from "../../../selectors/selectors";
import PaginatedTable from "../../common/table/PaginatedTable";
import {
    headerJustifyOptions
} from "../../common/table/Table";
import GoTo from "../../common/table/formatters/GoTo";
import StringFormatter from "../../common/table/formatters/StringFormatter";
import ResidentIcon from "./CellFormatters/ResidentIcon";
import TransmitterFormatter from "./CellFormatters/TrasmitterFormatter";
import { CareFacility, IntegrationType } from "../../../models/CareFacility";
import ExternalIdFormatter from "./CellFormatters/ExternalIdFormatter";

interface IProps {
    selectedWards: Ward[];
    limitedAccess: boolean;
    setAddMode: (value: boolean) => void;
    dropdownHeaderItem: JSX.Element;
}

const getWardName = (wardId: string, wards: Ward[]) => {
    const ward = wards.find(w => w.id === wardId);
    return ward ? ward.name : "";
}

const ResidentsTable = (props: IProps) => {

    const residents = useAppSelector<Resident[]>(
        (state) => state.residentSlice.residents
    )

    const careFacility = useAppSelector<CareFacility | null>((state) => state.nursingHomeSlice.nursingHome);
    const wards = useAppSelector<Ward[]>(selectActiveWards);
    const regex = /.{1,3}/g;
    const residentWithWardName = residents.map((resident) => {
        const name = getWardName(resident.wardId, wards);
        return {
            ...resident,
            wardName: name,
            dayFilterTransmitter: resident.dayTransmitter.transmitterProductCode.replace(regex, '$& '),
            nightFilterTransmitter: resident.nightTransmitter.transmitterProductCode.replace(regex, '$& ')
        };
    });

    const filteredResidents = residentWithWardName.filter((resident) =>
        props.selectedWards.map((ward) => ward.id).includes(resident.wardId)
    );

    const { externalIntegrationId, externalIntegrationType = null } = careFacility || {};
    const readOnly = externalIntegrationType !== null && externalIntegrationType !== IntegrationType.Amba

    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <FormattedMessage id="residents.tableHeader.residents" />
                ),
                accessor: "name",
                Cell: ResidentIcon,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 2,
                hideTooltipOnProd: false,
            },
            {
                Header: (
                    <FormattedMessage id="residents.informationBlocks.title.ward" />
                ),
                accessor: "wardName",
                disableSortBy: true,
                Cell: StringFormatter,
                flexGrow: 3,
                headerAlignment: headerJustifyOptions.left,
            },
            {
                accessor: "nightFilterTransmitter", // Hidden column for filtering
                show: false,
                Cell: () => <div style={{ display: 'none' }} />,
                style: { display: "none" },
                disableSortBy: true,
                maxWidth: 0,
            },
            {
                Header: (
                    <>
                        <NightIcon fill={Colors.white.clearWhite} />
                        <FormattedMessage id="careRecipients.transmitterId" />
                    </>
                ),
                accessor: "nightTransmitter.transmitterProductCode",
                disableSortBy: true,
                Cell: TransmitterFormatter,
                flexGrow: 3,
                headerAlignment: headerJustifyOptions.left,
            },
            {
                accessor: "dayFilterTransmitter", // Hidden column for filtering
                show: false,
                Cell: () => <div style={{ display: 'none' }} />,
                style: { display: "none" },
                disableSortBy: true,
                maxWidth: 0,
            },
            {
                Header: (
                    <>
                        <DayIcon fill={Colors.white.clearWhite} />
                        <FormattedMessage id="careRecipients.transmitterId" />
                    </>
                ),
                accessor: "dayTransmitter.transmitterProductCode",
                disableSortBy: true,
                Cell: TransmitterFormatter,
                flexGrow: 3,
                headerAlignment: headerJustifyOptions.left,
            },
            ...(externalIntegrationId ? [
                {
                    Header: (<FormattedMessage id="careRecipients.externalId" />),
                    accessor: "externalId",
                    disableSortBy: true,
                    Cell: ExternalIdFormatter,
                    flexGrow: 3,
                    headerAlignment: headerJustifyOptions.left,
                }] : []),
            {
                Header: <FormattedMessage id="general.tableHeader.goTo" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: GoTo,
                disableSortBy: true,
                flexShrink: 3,
                maxWidth: 160
            },
        ],
        [externalIntegrationId]
    );
    const data = React.useMemo(() =>
        filteredResidents || []
        , [filteredResidents]);

    const styleProps = { rowHeight: 56 };

    return (
        <PaginatedTable
            qaId={"residents_table"}
            summaryId="careRecipients.table.summary"
            headerItem={props.dropdownHeaderItem}
            columns={columns}
            data={data}
            disableAddClick={readOnly}
            addClick={() => props.setAddMode(true)}
            styleProps={styleProps}
        />
    );
};

export default React.memo(ResidentsTable);
