import React from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { IContextStateType } from "../../../../reducers/contextSlice";
import { getLocalizedCountry, localizer } from "../../../../utils/Localizer";
import PaginatedTable from "../../../common/table/PaginatedTable";
import { headerJustifyOptions } from "../../../common/table/Table";
import { ITableProps } from "../../ITableProps";
import CareGroupActions from "../CareGroupActions";
import StringFormatter from "../../../common/table/formatters/StringFormatter";

const CareGroupsTable = (props: ITableProps) => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);

    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="careGroup.name"/>,
                accessor: "name",
                sortType: "basic",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: false,
                
            },
            {
                Header: <FormattedMessage id="constants.country"/>,
                accessor: "countryName",
                sortType: "basic",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: true,
            },
            {
                Header: <></>,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: CareGroupActions,
                disableSortBy: true,
                flexShrink: 3,
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    const localizedCountriesCareGroups = data.map((cg) => {
        const countryCode = context.userResourcesHierarchy.find((country) => country.id === cg.countryId)?.name ?? (cg.countryId?.toString() ?? "");
        return { ...cg, countryName: getLocalizedCountry(countryCode) || countryCode };
    });

    const styleProps = { rowHeight: 56 };
    const tableHeaderText = localizer("mainNav.careGroups");
    return (
        <PaginatedTable
            qaId={"nh_table"}
            columns={columns}
            summaryId="careGroup.table.summary"
            data={localizedCountriesCareGroups}
            tableHeaderText={tableHeaderText}
            styleProps={styleProps}
            initialState={{
                sortBy: [
                    {
                        id: "name",
                        desc: false
                    }
                ]
            }}
            addClick={() => props.addClick && props.addClick()}
        />
    );
};

export default React.memo(CareGroupsTable);
