export const {
    REACT_APP_AAD_AUTHORITY_URL,
    REACT_APP_AAD_CLIENT_ID,
    REACT_APP_API_URL,
    REACT_APP_CUSTOMERAD_AUTHORITY_URL,
    REACT_APP_CUSTOMERAD_CLIENT_ID,
    REACT_APP_CUSTOMERAD_AUTHORITY,
    REACT_APP_AAD_CUSTOMERAD_REDIRECT_URL,
    REACT_APP_APPINSIGHTS_CONNECTION_STRING,
    REACT_APP_HELP_BASE_URL,
    REACT_APP_IDENTIFI_URL,
    REACT_APP_IDENTIFI_RESET_SESSION_URL,
    REACT_APP_VERSION,
    NODE_ENV,
    PUBLIC_URL
} = process.env;
