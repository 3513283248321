import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { IContextStateType } from "../../../../../reducers/contextSlice";
import { localizer } from "../../../../../utils/Localizer";
import { routePaths } from "../../../../../utils/PathHelpers";
import PaginatedTable from "../../../../common/table/PaginatedTable";
import Table from "../../../../common/table/Table";
import { ITableProps } from "../../../ITableProps";

const UsersTable = (props: ITableProps) => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const navigate = useNavigate();

    const data = React.useMemo(() => props.data, [props.data]);
    const styleProps = { rowHeight: 56 }
    const tableHeaderText = context.id && context.id !== "" ? localizer("users.userAccounts") : localizer("users.userAccounts") + " - " + localizer("users.allUsers");
    return props.paginated ? (
        <PaginatedTable
            qaId={"users_table"}
            columns={props.columns ?? []}
            tableHeaderText={tableHeaderText}
            summaryId="users.table.summary"
            addClick={() => navigate(routePaths.addUser)}
            data={data}
            styleProps={styleProps}
            initialState={{
                sortBy: [
                    {
                        id: "name",
                        desc: false
                    }
                ]
            }}
        />
    ) : (
        <Table
            qaId={"users_table"}
            columns={props.columns ?? []}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "name",
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(UsersTable);
