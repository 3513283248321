export default class ProductFamily {
    name: string;
    marketCode: string;
    sortOrder: number;
    constructor(json: any, marketCode: string) {
        this.name = json.name;
        this.marketCode = marketCode;
        this.sortOrder = json.sortOrder;
    }

    static sort(products: ProductFamily[]): ProductFamily[] {
        products.sort(((a, b) => {
            if (a.sortOrder === b.sortOrder) return 0;
            return a.sortOrder > b.sortOrder ? 1 : -1;
        }));
        return products;
    }
}