import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useContext, useEffect } from "react";
import { reactPlugin } from "../AppInsights";
import { AppContext } from "../data/AppRepository";
import { withRouter } from "../routes/routesHelper";

const ManagePasswordwithActiveSessionView = () => {

    const appContext = useContext(AppContext);
    useEffect(() => {
        const logout = async () => {
            const url = window.location.href;
            await appContext.logout(null, url);
        };
        logout();
    }, []);

    return <></>;
}

export default withAITracking(reactPlugin, withRouter(ManagePasswordwithActiveSessionView));