import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { isEqual } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { appInsights, reactPlugin } from "../AppInsights";
import BackHardButton from "../components/common/controls/BackHardButton";
import CheckBox from "../components/common/controls/CheckBox";
import { SectionTitle } from "../components/common/forms/SectionStyle";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { CareFacilityFields, Content, StyledBlock, StyledButton, StyledButtonBlock, StyledButtonsContainer, StyledHeader, StyledNursingHomeSettingsColumn, StyledNursingHomeSettingsContainer } from "../components/themed/nursingHomes/CareFacilitiesHelper";
import DayNightTimes from "../components/themed/setup/DayNightTimes";
import GeneralSettings from "../components/themed/setup/GeneralSettings";
import Notifications from "../components/themed/setup/Notifications";
import SecuritySettings from "../components/themed/setup/SecuritySettings";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { CareFacility } from "../models/CareFacility";
import CareGroup from "../models/CareGroup";
import ErrorCodes from "../models/ErrorCodes";
import { Roles } from "../models/Role";
import { nursingHomeToReqBody } from "../models/helpers/RequestHelpers";
import { IContextStateType, setFormDirty } from "../reducers/contextSlice";
import { validateNursingHome } from "../reducers/nursingHomeHelpers";
import { updateNursingHome } from "../reducers/nursingHomeSlice";
import { ETrackEvent } from "../utils/AppInsightsHelpers";
import { getLocalizedCountry, localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import GenericConfirmationForm from "../views/GenericConfirmationForm";
import { SensorStripType } from "../models/EStripSizeSetting";

const SetupView = (props: { readonly?: boolean }) => {
    const dispatch = useAppDispatch();
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { role } = context;
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const nursingHome = useAppSelector<CareFacility>(
        (state) => state.nursingHomeSlice.nursingHome ? state.nursingHomeSlice.nursingHome : new CareFacility()
    );
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [entity, setEntity] = useState<CareFacility>(
        cloneDeep(nursingHome) || new CareFacility()
    );
    const initialState = cloneDeep(nursingHome) || new CareFacility();
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const careGroups = useAppSelector<CareGroup[]>(state => state.careGroupSlice.careGroups);

    const countryCode = context.userResourcesHierarchy.find(res => res.id.toString() === nursingHome.countryId)?.name;
    const careGroupsOptions = careGroups.filter(cg => cg.countryId.toString() === nursingHome.countryId).map(careGroups => ({
        label: careGroups.name,
        value: careGroups.id.toString()
    })) || [];

    useEffect(() => {
        if (nursingHome.id.length > 0) {
            setEntity(cloneDeep(nursingHome));
            setValidationErrors([]);
        }
    }, [nursingHome]);

    const shouldClearNotify = (timeA: string, timeB: string) => {
        const [shiftHours, shiftMinutes] = timeA.split(":");
        const [notifyHours, notifyMinutes] = timeB.split(":");

        const shiftToMinutes = Number(shiftHours) * 60 + Number(shiftMinutes);
        const notifyToMinutes = Number(notifyHours) * 60 + Number(notifyMinutes);

        return shiftToMinutes > notifyToMinutes;
    };

    const handleChange = (name: string, value: string) => {
        const nursingHome = cloneDeep(entity);

        switch (name) {
            case CareFacilityFields.Name:
                nursingHome.name = value;
                break;
            case CareFacilityFields.MorningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.morningShiftNotificationTime)) {
                    nursingHome.morningShiftNotificationTime = "";
                }
                nursingHome.morningShiftStartTime = value;
                break;
            case CareFacilityFields.EveningShiftStartTime:
                if (shouldClearNotify(value, nursingHome.nightShiftNotificationTime)) {
                    nursingHome.nightShiftNotificationTime = "";
                }
                nursingHome.nightShiftStartTime = value;
                break;
            case CareFacilityFields.CaregiverNotificationInterval:
                nursingHome.caregiverNotificationIntervalMinutes =
                    Number(value);
                break;
            case CareFacilityFields.CaregiverNotificationIntervalDay:
                nursingHome.caregiverNotificationIntervalMinutesDay =
                    Number(value);
                break;
            case CareFacilityFields.CaregiverResponseTarget:
                nursingHome.caregiverResponseTargetMinutes = Number(value);
                break;
            case CareFacilityFields.CaregiverTargetResponseTimeDay:
                nursingHome.caregiverResponseTargetMinutesDay = Number(value);
                break;
            case CareFacilityFields.MorningShiftNotificationTime:
                nursingHome.morningShiftNotificationTime = value;
                break;
            case CareFacilityFields.NightShiftNotificationTime:
                nursingHome.nightShiftNotificationTime = value;
                break;
            case CareFacilityFields.ShiftNotificationTimeDisabled:
                nursingHome.shiftNotificationTimeDisabled = Boolean(value);
                break;
            case CareFacilityFields.Is2FAActive:
                nursingHome.is2FactorAuthenticationEnabled = Boolean(value);
                break;
            case CareFacilityFields.Is2FAActiveMobile:
                nursingHome.is2FactorAuthenticationEnabledMobile = Boolean(value);
                break;
            case CareFacilityFields.EnforceEmailForCaregiver:
                nursingHome.enforceEmailForCaregiver = Boolean(value);
                break;
            case CareFacilityFields.EnforceStrongPasswordForCaregiver:
                nursingHome.isWeakAuthenticationAllowed = Boolean(!value);
                break;
            case CareFacilityFields.EightHourNotification:
                nursingHome.notifyOfSomeUrineStatusOverdue = !!Number(value);
                break;
            case CareFacilityFields.CustomerId:
                nursingHome.customerId = value;
                break;
            case CareFacilityFields.CareGroup:
                nursingHome.nursingHomeGroupId = value;
                break;
            case CareFacilityFields.ExternalIntegration:
                if(value === "0") {
                    value = "";
                }
                nursingHome.externalIntegrationId = value || null;
                break;
            case CareFacilityFields.StripSizeSetting:
                nursingHome.sensorStripType = value as SensorStripType;
                break;
        }

        if (!isFormDirty && !isEqual(initialState, nursingHome)) {
            dispatch(setFormDirty(true, "general.leaveTitle", "general.leaveMessage", "constants.leave"));
        } else if (isFormDirty && isEqual(initialState, nursingHome)) {
            dispatch(setFormDirty(false));
        }

        setEntity(nursingHome);
    };

    const validate = async (): Promise<boolean> => {
        const errors: ErrorCodes[] = validateNursingHome(entity);
        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendNursingHome();
        dispatch(setFormDirty(false));
    };

    const sendNursingHome = async () => {
        const requestBody = nursingHomeToReqBody(entity);
        dispatch(updateNursingHome(requestBody, false));
        appInsights.trackEvent({ name: ETrackEvent.SettingsUpdate });
        window.scrollTo(0, 0);
    };

    return (
        <StyledNursingHomeSettingsContainer>
            <StyledHeader>
                {localizer("setup.editCareFacility")}
            </StyledHeader>
            <Content>
                <StyledNursingHomeSettingsColumn>
                    <GeneralSettings readonly={props.readonly || role === Roles.Nurse}
                        nursingHome={entity}
                        countries={[{ label: getLocalizedCountry(countryCode || ""), value: entity.countryId }]}
                        careGroups={careGroupsOptions}
                        handleChanges={handleChange}
                        validationErrors={validationErrors} />
                    <SecuritySettings readonly={props.readonly || ![Roles.NHManager, Roles.Support].includes(role)}
                        nursingHome={entity}
                        handlePreferenceChanges={handleChange}
                        validationErrors={validationErrors} />
                </StyledNursingHomeSettingsColumn>
                <StyledNursingHomeSettingsColumn>
                    <Notifications
                        readonly={props.readonly || role === Roles.Nurse}
                        nursingHome={entity}
                        handleChange={handleChange}
                        validationErrors={validationErrors}
                    />
                    {validationErrors.length > 0 && (
                        <div>
                            <span className="error-message">
                                <FormattedMessage
                                    id={ErrorCodes.ValidationErrorsExist}
                                />
                            </span>
                        </div>
                    )}

                    <StyledBlock>
                        <SectionTitle>
                            <FormattedMessage id="setup.shiftStartTime" />
                        </SectionTitle>
                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            readOnly={props.readonly}
                            dayValue={entity.morningShiftStartTime}
                            nightValue={entity.nightShiftStartTime}
                            dayTimeFieldName={CareFacilityFields.MorningShiftStartTime}
                            nightTimeFiledName={CareFacilityFields.EveningShiftStartTime}
                            dayError={ErrorCodes.EmptyMorningShiftStartTime}
                            nightError={ErrorCodes.EmptyNightShiftStartTime}
                            minuteStep={10}
                            disabled={props.readonly}
                        />
                        <br />
                        <p>
                            <FormattedMessage id="setup.notifyCaregiver.title" />
                        </p>

                        <DayNightTimes
                            handleChange={handleChange}
                            validationErrors={validationErrors}
                            dayValue={entity.morningShiftNotificationTime}
                            nightValue={entity.nightShiftNotificationTime}
                            dayTimeFieldName={
                                CareFacilityFields.MorningShiftNotificationTime
                            }
                            nightTimeFiledName={
                                CareFacilityFields.NightShiftNotificationTime
                            }
                            dayError={ErrorCodes.EmptyMorningShiftStartNotificationTime}
                            nightError={ErrorCodes.EmptyNightShiftStartNotificationTime}
                            minuteStep={10}
                            dayMinTime={entity.morningShiftStartTime}
                            nightMinTime={entity.nightShiftStartTime}
                            readOnly={props.readonly}
                            disabled={props.readonly || entity.shiftNotificationTimeDisabled}
                        />
                        <CheckBox
                            label={localizer("setup.notifyCaregiver.disable")}
                            value={entity.shiftNotificationTimeDisabled}
                            fieldName={CareFacilityFields.ShiftNotificationTimeDisabled}
                            onChange={handleChange}
                            disabled={props.readonly}
                        />
                    </StyledBlock>
                </StyledNursingHomeSettingsColumn>
            </Content>
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"general.leaveMessage"}
                            confirmButton={"constants.leave"}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                dispatch(setFormDirty(false));
                                navigate(routePaths.careFacility);
                            }}
                        />
                    }
                    modalTitle={localizer("general.leaveTitle")}
                    size={EModalSize.XS}
                />
            )}
            <StyledButtonBlock>
                <StyledButtonsContainer>
                    <StyledButton
                        variant={"primary"}
                        onClick={onSubmit}
                        disabled={isEqual(initialState, entity)}
                    >
                        <FormattedMessage id="setup.setupView.saveChanges" />
                    </StyledButton>
                </StyledButtonsContainer>

                <StyledButtonsContainer>
                    <BackHardButton
                        onClick={() => isFormDirty ? setConfirmLeave(true) : navigate(routePaths.careFacility)}
                        backRoute={routePaths.careFacility} />
                </StyledButtonsContainer>
            </StyledButtonBlock>
        </StyledNursingHomeSettingsContainer>
    );
};

export default withAITracking(reactPlugin, SetupView);
