import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import CareFacilityAddEditForm from "../components/themed/nursingHomes/CareFacilityAddEditForm";
import { PageHeader, StyledViewContainer } from "../components/common/View";
import { CareFacility } from "../models/CareFacility";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RestClient } from "../data/RestClient";
import { toastError } from "../utils/Toaster";
import { localizer } from "../utils/Localizer";
import { guidRegex } from '../utils/Regex'
import { useAppDispatch } from '../hooks/useAppDispatch'
import { routePaths } from '../utils/PathHelpers'
import ToastMessages from '../models/ToastMessages'

const EditCareFacilityView = () => {
    const { id } = useParams();
    const [cfEntity, setcfEntity] = useState<CareFacility | undefined>(undefined);

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const fetchCareFacility = async () => {
            if (id) {
                try {
                    let cf
                    if (guidRegex.test(id)) {
                        cf = await RestClient.getCareFacilityExternal(id);
                    }
                    else {
                        cf = await RestClient.getCareFacility(id);
                    }

                    const cfEntity = new CareFacility(cf);
                    setcfEntity(cfEntity);
                } catch (error: any) {
                    if(error?.status === 404) {
                        toastError(localizer(ToastMessages.LoadCareFacilityNotFoundError));
                        navigate(routePaths.careFacilities);
                    }
                    else {
                        toastError(localizer(error));
                    }
                }
            }
        }
        fetchCareFacility();
    }, [dispatch, id, navigate]);

    return (
        <StyledViewContainer>
            <PageHeader>{localizer("careFacility.edit")}</PageHeader>
            {cfEntity && (<CareFacilityAddEditForm
                entity={cfEntity}
            />)}
        </StyledViewContainer>
    );
}

export default withAITracking(reactPlugin, EditCareFacilityView);
