import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import React, {Component} from "react";
import { FormattedMessage } from 'react-intl';
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import { Icon } from '../../common/table/Icon';
import { Ward } from '../../../models/Ward';
import AddEditWardForm from "./AddEditWardForm";

interface IEditWardButtonState {
    isModalDisplayed: boolean;
    modalTitle: string;
}

interface IProps {
    ward: Ward;
    disabled?: boolean;
}

export default class EditWardButton extends Component<IProps, IEditWardButtonState> {
    setDeleteMode = () => {
        this.setState(() => ({
            modalTitle: "setup.deleteWardPopup.modalTitle"
        }));
    }

    setEditMode = () => {
        this.setState(() => ({
            modalTitle: "setup.editWardPopup.modalTitle"
        }));
    }

    render(): React.ReactElement {

        if (this.props.disabled === true) {
            return <Icon
                disabled={this.props.disabled === true}
                icon={faPencilAlt}
                text={"setup.addEditButton"}
            />
        }

        const triggerComponent: React.ReactNode = (
            <Icon
                icon={faPencilAlt}
                text={"setup.addEditButton"}
            />
        );

        return (
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={<AddEditWardForm
                    entity={this.props.ward}
                    setDeleteMode={() => this.setDeleteMode()}
                    setEditMode={() => this.setEditMode()}
                />}
                modalTitle={<FormattedMessage id={this.state?.modalTitle || "setup.editWardPopup.modalTitle"}></FormattedMessage>}
                size={EModalSize.S}
            />
        );
    }
}
