import moment from "moment";

export const utcTimeSpanToLocal = (utcTimeSpan: string | undefined): string => {
    if (!utcTimeSpan) {
        return "";
    }
    return moment.utc(utcTimeSpan, "HH:mm").local().format("HH:mm");
};

export const localTimeSpanToUtc = (utcTimeSpan: string | undefined): string => {
    if (!utcTimeSpan) {
        return "";
    }
    return moment(utcTimeSpan, "HH:mm").utc().format("HH:mm");
};

export const utcDateToLocal = (utcDateString: string | undefined): string => {
    if (!utcDateString) {
        return "";
    }
    return moment.utc(utcDateString).local().format("YYYY-MM-DD HH:mm:ss");
}