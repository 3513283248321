import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import WardList from "../components/themed/setup/WardList";
import { routePaths } from "../utils/PathHelpers";

const WardsView = () => {
    return (
        <StyledViewContainer>
            <WardList />
            <BackHardButton backRoute={routePaths.careFacility} />
        </StyledViewContainer>
    );
};

export default withAITracking(reactPlugin, WardsView);
