import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';
import { PatternFormat} from 'react-number-format';

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd } = props.data.column;

    return (
        <StyledLeft>
            <TextOverflowWrapper tooltip={value} hideTooltipOnProd={hideTooltipOnProd}>
                {value ? <PatternFormat displayType={"text"} type={"text"} value={value} format="### ### ### ### ###"/> : "--"}
            </TextOverflowWrapper>
        </StyledLeft>
    );
};

const TransmitterFormatter = (data: any) => <CellFormatter data={data} />;
export default TransmitterFormatter;
