import CountryEditForm from "./CountryEditForm";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import { localizer } from "../../../utils/Localizer";
import { StyledAddButton } from "../../styled";

const CountryAddButton = () => {
    const triggerComponent: React.ReactNode = (
        <StyledAddButton variant="primary" data-qa="add_country_button"/>
    );

    return (
        <ModalWrapper
            triggerComponent={triggerComponent}
            modalContent={<CountryEditForm entity={undefined} />}
            modalTitle={localizer("countries.addButton.title")}
            size={EModalSize.S}
        />
    );
};

export default CountryAddButton;
