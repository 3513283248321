import React from "react";

// check if the contents is overflown
export const useIsOverflow = (ref: any, callback?: any) => {
    const [isOverflow, setIsOverflow] = React.useState(false);

    React.useEffect(() => {
        const { current } = ref;

        const trigger = () => {
            const hasOverflow = current.scrollWidth > current.clientWidth || current.scrollHeight > current.clientHeight;
            setIsOverflow(hasOverflow);

            if (callback) {
                callback(hasOverflow);
            }
        };

        if (current) {
            if ('ResizeObserver' in window) {
                new ResizeObserver(trigger).observe(current);
            }
            trigger();
        }
    }, [callback, ref]);

    return isOverflow;
};