import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { appInsights } from "../../../../AppInsights";
import { LocalStorageKeys } from "../../../../data/Persistence";
import { RestClient } from "../../../../data/RestClient";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { DateFilter } from "../../../../models/DateFilter";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { KpiType } from "../../../../models/KpiType";
import { CareFacility } from "../../../../models/CareFacility";
import { Resident } from "../../../../models/Resident";
import ResidentStatisticsGraphResponse from "../../../../models/ResidentStatisticsGraphResponse";
import { IStatisticValues } from "../../../../models/StatisticValues";
import { TimeFilter } from "../../../../models/TimeFilter";
import { getCaregiverResponseTargetMinutes } from "../../../../reducers/nursingHomeHelpers";
import { EFilter, ETrackEvent, parseErrorToString } from "../../../../utils/AppInsightsHelpers";
import { routePaths } from "../../../../utils/PathHelpers";
import InformationBlock from "../../../../views/InformationBlock/InformationBlock";
import DropdownDateTimeDaysInThePastFromNowRangeButton from "../../../common/controls/dateTime/DropdownDateTimeDaysInThePastFromNowRangeButton";
import { PickerType } from "../../../common/controls/dateTime/helpers/PickerType";
import TimeRangePickerDropdown from "../../../common/controls/dateTime/TimeRangePickerDropdown";
import { StyledDropdownsContainer } from "../../../common/View";
import ResidentStatisticsGraph from "./ResidentStatisticsGraph";
import ResidentStatisticsKPIView from "./ResidentStatisticsKPIView";

export interface IResidentChartData {
    date: string;
    selectedKpiValue: number | null;
    selectedCompareKpiValue?: number | null;
    compareDate?: string;
}
interface IResidentStatisticsProps {
    resident: Resident;
}

const ResidentStatisticsView = (props: IResidentStatisticsProps) => {

    const [dateFilterSelected, setDateFilterSelected] = useState<DateFilter>(localStorage.getItem(LocalStorageKeys.ResidentStatisticsDateFilter) ? Number(localStorage.getItem(LocalStorageKeys.ResidentStatisticsDateFilter)) : DateFilter.Week);
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilter>(DateTimeUtils.getTimeFilter(localStorage.getItem(LocalStorageKeys.ResidentStatisticsTimeFilter) ?? ""));
    const [selectedKpi, setSelectedKpi] = useState<KpiType>(KpiType.ResponseTime);

    const [selectedStartDate, setSelectedStartDate] = useState<Moment>(localStorage.getItem(LocalStorageKeys.ResidentStatisticsStartDate) ? moment(localStorage.getItem(LocalStorageKeys.ResidentStatisticsStartDate)) : moment().subtract(6, "d"));
    const [selectedEndDate, setSelectedEndDate] = useState<Moment>(localStorage.getItem(LocalStorageKeys.ResidentStatisticsEndDate) ? moment(localStorage.getItem(LocalStorageKeys.ResidentStatisticsEndDate)) : moment());
    const [selectedCompareStartDate, setSelectedCompareStartDate] = useState<Moment | null>(localStorage.getItem(LocalStorageKeys.ResidentStatisticsCompareStartDate) ? moment(localStorage.getItem(LocalStorageKeys.ResidentStatisticsCompareStartDate)) : null);
    const [selectedCompareEndDate, setSelectedCompareEndDate] = useState<Moment | null>(localStorage.getItem(LocalStorageKeys.ResidentStatisticsCompareEndDate) ? moment(localStorage.getItem(LocalStorageKeys.ResidentStatisticsCompareEndDate)) : null);

    const [selectedStartTime, setSelectedStartTime] = useState<Moment | null>(moment("12:00", "HH:mm"));
    const [selectedEndTime, setSelectedEndTime] = useState<Moment | null>(moment("13:00", "HH:mm"));

    const [residentStatistics, setResidentStatistics] = useState<ResidentStatisticsGraphResponse[]>([]);
    const [productChangesAverage, setProductChangesAverage] = useState<IStatisticValues>({ value: null, trend: null });
    const [productChangesTotal, setProductChangesTotal] = useState<IStatisticValues>({ value: null, trend: null });
    const [responseTime, setResponseTime] = useState<IStatisticValues>({ value: null, trend: null });
    const [promptedProductChanges, setPromptedProductChanges] = useState<IStatisticValues>({ value: null, trend: null });
    const [nightTimeMinutesBetweenChanges, setNightTimeMinutesBetweenChanges] = useState<IStatisticValues>({ value: null, trend: null });

    const [residentCompareStatistics, setResidentCompareStatistics] = useState<ResidentStatisticsGraphResponse[]>([]);
    const [compareProductChangesAverage, setCompareProductChangesAverage] = useState<IStatisticValues>({ value: null, trend: null });
    const [compareProductChangesTotal, setCompareProductChangesTotal] = useState<IStatisticValues>({ value: null, trend: null });
    const [compareResponseTime, setCompareResponseTime] = useState<IStatisticValues>({ value: null, trend: null });
    const [comparePromptedProductChanges, setComparePromptedProductChanges] = useState<IStatisticValues>({ value: null, trend: null });
    const [compareNightTimeMinutesBetweenChanges, setCompareNightTimeMinutesBetweenChanges] = useState<IStatisticValues>({ value: null, trend: null });

    const selectedDateFilter = Number(dateFilterSelected);
    const specificXAxisOffsetValue = 214;
    const { resident } = props;

    const activeNursingHome = useAppSelector<CareFacility | null>(state => state.nursingHomeSlice.nursingHome);

    useEffect(() => {
        const fetchData = async () => {
            const { startDate, endDate } = DateTimeUtils.getDateRange(dateFilterSelected, selectedStartDate, selectedEndDate, moment().diff(resident.createdDate, "days") - 1, false, true);
            const { startTime, endTime } = DateTimeUtils.getTimeRange(activeNursingHome ?? new CareFacility(), selectedTimeFilter, selectedStartTime, selectedEndTime);
            const ignoreNightTime = selectedTimeFilter === TimeFilter.DayShift || selectedTimeFilter === TimeFilter.CustomRange;
            try {
                const response: any = await RestClient.getResidentStatistics(
                    resident.id,
                    activeNursingHome?.id,
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                    ignoreNightTime
                )

                if (selectedCompareStartDate !== null && selectedCompareEndDate !== null) {
                    appInsights.trackEvent({
                        name: ETrackEvent.ResidentStatisticsCompare, properties: {
                            compareStartDate: selectedCompareStartDate,
                            compareEndDate: selectedCompareEndDate,
                            selectedKpi: selectedKpi,
                        }
                    })
                    const compareDates = DateTimeUtils.getDateRange(DateFilter.CustomRange, selectedCompareStartDate, selectedCompareEndDate, moment().diff(resident.createdDate, "days") - 1, false, true);

                    const compareResponse: any = await RestClient.getResidentStatistics(
                        resident.id,
                        activeNursingHome?.id,
                        compareDates.startDate,
                        compareDates.endDate,
                        startTime,
                        endTime,
                        ignoreNightTime
                    )
                    setResidentCompareStatistics(compareResponse.groupedNurseActions)
                    setCompareResponseTime(compareResponse.responseTime);
                    setComparePromptedProductChanges(compareResponse.promptedProductChanges);
                    setCompareNightTimeMinutesBetweenChanges(compareResponse.nightTimeMinutesBetweenChanges);
                    setCompareProductChangesAverage(compareResponse.productChangesAverage);
                    setCompareProductChangesTotal(compareResponse.productChangesTotal);
                }
                setResidentStatistics(response.groupedNurseActions);
                setResponseTime(response.responseTime);
                setPromptedProductChanges(response.promptedProductChanges);
                setNightTimeMinutesBetweenChanges(response.nightTimeMinutesBetweenChanges);
                setProductChangesAverage(response.productChangesAverage);
                setProductChangesTotal(response.productChangesTotal);
            } catch (error: any) {
                console.error(error)
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error })
            }
        }
        fetchData();
    }, [
        activeNursingHome,
        resident.id,
        resident.createdDate,
        selectedKpi,
        dateFilterSelected,
        selectedStartDate,
        selectedEndDate,
        selectedTimeFilter,
        selectedStartTime,
        selectedEndTime,
        selectedCompareStartDate,
        selectedCompareEndDate,
    ]);

    const dateFilterRanges = [
        DateFilter.Today,
        DateFilter.Yesterday,
        DateFilter.Week,
        DateFilter.HalfMonth,
        DateFilter.Month,
        DateFilter.SinceStart,
        DateFilter.CustomRange,
    ];

    const { startDate, endDate } = DateTimeUtils.getDateRange(dateFilterSelected, selectedStartDate, selectedEndDate, moment().diff(resident.createdDate, "days") - 1, false, true);
    const getCurrentFilterSetup = () => {
        return {
            dateFilter: dateFilterSelected,
            startDate: selectedStartDate.format("YYYY-MM-DD"),
            endDate: selectedEndDate.format("YYYY-MM-DD"),
            timeFilter: selectedTimeFilter,
            startTime: selectedStartTime?.format("HH:mm"),
            endTime: selectedEndTime?.format("HH:mm"),
            kpi: selectedKpi,
        }
    }
    const dateFilterChanged = (value: any) => {
        localStorage.setItem(LocalStorageKeys.ResidentStatisticsDateFilter, value);
        setDateFilterSelected(Number(value));
        setSelectedCompareStartDate(null);
        setSelectedCompareEndDate(null);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareStartDate);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareEndDate);
        appInsights.trackEvent({
            name: ETrackEvent.ResidentStatisticsFilter,
            properties: {
                ...getCurrentFilterSetup(),
                dateFilter: Number(value),
                filterUsed: EFilter.DateFilter
            }
        })
    };

    const setCompareStartDate = (value: any) => {
        setSelectedCompareStartDate(value);
        localStorage.setItem(LocalStorageKeys.ResidentStatisticsCompareStartDate, value);
    }

    const setCompareEndDate = (value: any) => {
        setSelectedCompareEndDate(value);
        localStorage.setItem(LocalStorageKeys.ResidentStatisticsCompareEndDate, value);
    }

    const timeFilterChanged = (value: any) => {
        localStorage.setItem(LocalStorageKeys.ResidentStatisticsTimeFilter, value);
        setSelectedTimeFilter(value);
        appInsights.trackEvent({
            name: ETrackEvent.ResidentStatisticsFilter,
            properties: {
                ...getCurrentFilterSetup(),
                timeFilter: value,
                filterUsed: EFilter.TimeFilter
            }
        })
    };

    const handleDropdownSelection = (name: string, value: any) => {
        if (name === "dateSelected" && value !== String(selectedDateFilter)) {
            dateFilterChanged(value);
        }
        if (name === "timeSelected" && value !== selectedTimeFilter) {
            timeFilterChanged(value);
        }
        if (name === "kpiSelected" && value !== selectedKpi) {
            setSelectedKpi(value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    kpi: value,
                    filterUsed: EFilter.KpiFilter
                }
            })
        }
    };

    const startDateChanged = (value: any) => {
        if (dateFilterSelected !== DateFilter.CustomRange) {
            setSelectedStartDate(value);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsStartDate, value);
            setDateFilterSelected(DateFilter.CustomRange)
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsDateFilter, DateFilter.CustomRange.toString());
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    dateFilter: DateFilter.CustomRange.toString(),
                    startDate: value.format("YYYY-MM-DD"),
                    filterUsed: EFilter.CustomStartDateFilter
                }
            })
        } else {
            setSelectedStartDate(value);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsStartDate, value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    startDate: value.format("YYYY-MM-DD"),
                    filterUsed: EFilter.CustomStartDateFilter
                }
            })
        }
        setSelectedCompareStartDate(null);
        setSelectedCompareEndDate(null);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareStartDate);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareEndDate);
        setResidentCompareStatistics([])
    }

    const endDateChanged = (value: any) => {
        if (dateFilterSelected !== DateFilter.CustomRange) {
            setSelectedEndDate(value);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsEndDate, value);
            setDateFilterSelected(DateFilter.CustomRange)
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsDateFilter, DateFilter.CustomRange.toString());
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    dateFilter: DateFilter.CustomRange.toString(),
                    endDate: value.format("YYYY-MM-DD"),
                    filterUsed: EFilter.CustomEndDateFilter
                }
            })
        } else {
            setSelectedEndDate(value);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsEndDate, value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    endDate: value.format("YYYY-MM-DD"),
                    filterUsed: EFilter.CustomEndDateFilter
                }
            })
        }
        setSelectedCompareStartDate(null);
        setSelectedCompareEndDate(null);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareStartDate);
        localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareEndDate);
    }

    const startTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedStartTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsTimeFilter, TimeFilter.CustomRange);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    timeFilter: TimeFilter.CustomRange,
                    startTime: value.format("HH:mm"),
                    filterUsed: EFilter.CustomStartTimeFilter
                }
            })
        } else {
            setSelectedStartTime(value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    startTime: value.format("HH:mm"),
                    filterUsed: EFilter.CustomStartTimeFilter
                }
            })
        }
    };

    const endTimeChanged = (value: any) => {
        if (selectedTimeFilter !== TimeFilter.CustomRange) {
            setSelectedEndTime(value);
            setSelectedTimeFilter(TimeFilter.CustomRange);
            localStorage.setItem(LocalStorageKeys.ResidentStatisticsTimeFilter, TimeFilter.CustomRange);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    timeFilter: TimeFilter.CustomRange,
                    endTime: value.format("HH:mm"),
                    filterUsed: EFilter.CustomEndTimeFilter
                }
            })
        } else {
            setSelectedEndTime(value);
            appInsights.trackEvent({
                name: ETrackEvent.ResidentStatisticsFilter,
                properties: {
                    ...getCurrentFilterSetup(),
                    endTime: value.format("HH:mm"),
                    filterUsed: EFilter.CustomEndTimeFilter
                }
            })
        }
    };

    const getSelectedKpiValueProduct = (isComparisonMode: boolean) => {
        let value;
        switch (selectedKpi) {
            case KpiType.AllProductChanges:
                value = isComparisonMode ? {
                    total: compareProductChangesTotal.value,
                    avg: compareProductChangesAverage.value
                } : {
                    total: productChangesTotal.value,
                    avg: productChangesAverage.value
                };
                break;
            case KpiType.PromptedProductChanges:
                value = isComparisonMode ? comparePromptedProductChanges.value : promptedProductChanges.value;
                break;
            case KpiType.ResponseTime:
                value = isComparisonMode ? compareResponseTime.value : responseTime.value;
                break;
            case KpiType.AvgNightTimeMinutesBetweenChanges:
                value = isComparisonMode ? compareNightTimeMinutesBetweenChanges.value : nightTimeMinutesBetweenChanges.value;
                break;
            default:
                break;
        }
        return value;
    }

    return (
        <>
            <StyledDropdownsContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.date"
                    width={300}
                >
                    <DropdownDateTimeDaysInThePastFromNowRangeButton
                        amountOfDays={moment().diff(resident.createdDate, "days")}
                        id="dateDropdown"
                        options={DateTimeUtils.getDateFilterOptions(dateFilterRanges)}
                        handleChange={handleDropdownSelection}
                        selectedValue={selectedDateFilter}
                        name="dateSelected"
                        start={selectedStartDate}
                        end={selectedEndDate}
                        handleStartDateTimeChange={startDateChanged}
                        handleEndDateTimeChange={endDateChanged}
                        pickerType={PickerType.Date}
                        offsetValue={specificXAxisOffsetValue}
                        endPickerPropertiesInitialMaxDate={moment()}
                    />
                </InformationBlock>
                <InformationBlock
                    title="statistics.changeInformationBlock.time"
                    width={300}
                >
                    <TimeRangePickerDropdown
                        name="timeSelected"
                        value={selectedTimeFilter}
                        onChange={handleDropdownSelection}
                        options={DateTimeUtils.getTimeFilterOptions()}
                        handleStartDateTimeChange={startTimeChanged}
                        handleEndDateTimeChange={endTimeChanged}
                        start={selectedStartTime}
                        end={selectedEndTime}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>
            <ResidentStatisticsKPIView
                handleDropdownSelection={handleDropdownSelection}
                selectedKpi={selectedKpi}
                responseTime={responseTime}
                productChangesAverage={productChangesAverage}
                productChangesTotal={productChangesTotal}
                promptedProductChanges={promptedProductChanges}
                nightTimeMinutesBetweenChanges={nightTimeMinutesBetweenChanges}

            />

            <div className="table-wrapper">
                <ResidentStatisticsGraph
                    selectedKpi={selectedKpi}
                    residentStatistics={residentStatistics}
                    residentCompareStatistics={residentCompareStatistics}
                    startDate={moment(startDate)}
                    endDate={moment(endDate)}
                    startRangeDate={resident.createdDate ? resident.createdDate : moment()}
                    setSelectedCompareStartDate={setCompareStartDate}
                    setSelectedCompareEndDate={setCompareEndDate}
                    startCompareDate={selectedCompareStartDate}
                    kpiValue={getSelectedKpiValueProduct(false)}
                    compareKpiValue={getSelectedKpiValueProduct(true)}
                    navigationLink={routePaths.careRecipients + `/${resident.id}/history`}
                    targetKpiValue={getCaregiverResponseTargetMinutes(activeNursingHome)}
                />
            </div>

        </>
    )
}

export default ResidentStatisticsView;