import { Button, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../../assets/Colors";

export const StyledViewContainer = styled.div`
    background-color: ${Colors.white.clearWhite};
    padding: 24px 16px 160px 16px;
`;

export const StyledInformationBlocks = styled.div<any>`
    min-height: 160px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    margin-bottom: 48px;
    margin-left: 8px;
    margin-right: 8px;
    gap: 8px;
`;

export const StyledInformationBlockWrapper = styled.div<any>`
    padding: 23px 23px 23px 23px;
    border:  ${props => props.isSelected ? `1.5px solid ${Colors.blue.brandingDark}` : `1px solid ${Colors.grey.almostWhite}`};
    box-sizing: border-box;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(217, 218, 218, 0.22);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${props => props.alignItems || 'stretch'};
    height: 200px;
    min-height: 200px
    max-height: 200px;
    width: 339px;
    min-width: 339px
    max-width: 339px;
`;

export const StyledSpan = styled.span`
    margin-left: 5px;
`;

export const StyledBoldSpan = styled.span`
    font-weight: bold;
`;

export const StyledFormGroup = styled(FormGroup)`
    margin-bottom: 1rem;
`;

export const StyledATag = styled.a`
    text-decoration: initial;
    :hover{
        text-decoration: underline;
    }
`;

export const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
`;

export const PageHeader = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${Colors.blue.brandingDark};
`;

export const StyledButton = styled(Button)`
    min-width: 160px;
`;

export const SectionHeader = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.blue.brandingDark}
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${Colors.grey.mediumLight};
    margin-top: 5px;
    margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
    margin-top: 200px;
    display: flex;
    justify-content: space-between;
`;

export const ActionButtonsContainer = styled.div`
    gap: 20px;
    display: flex;
`;
