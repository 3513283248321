import isNil from 'lodash/isNil';
import { EMPTY_VALUE } from "./Localizer";
import {
    StyledBoldSpan,
    StyledSpan
} from "../components/common/View";
interface IConvertTime {
    days?: number;
    hours?: number;
    minutes?: number;
}

export const convertMinutesToHours = (minutes: number | undefined | null, emptyValue = EMPTY_VALUE, useRoundInsteadOfFloorForMinutes = true) => {
    if (isNil(minutes) || Number.isNaN(minutes) || minutes < 0) {
        return emptyValue;
    }

    const minutesInDay = 1440;
    const minutesInHour = 60;

    const result: IConvertTime = {};

    result.days = Math.floor(minutes / minutesInDay);
    result.hours = Math.floor(minutes % minutesInDay / minutesInHour);
    result.minutes = roundOrFloorMinutes(useRoundInsteadOfFloorForMinutes, (minutes % minutesInHour));

    return result;
};

export const convertTimeToString = (time: IConvertTime | string, isLong?: boolean) => {
    if (typeof time === "string") {
        return time;
    }

    let result = "";

    if (time.days) {
        result += `${time.days} d `;
    }
    if (time.hours) {
        result += `${time.hours} h `;
    }
    if (time.minutes && (!time.days || !time.hours)) {
        result += isLong ? `${time.minutes} min` : `${time.minutes} m`;
    }
    if (result.length === 0) {
        result = isLong ? "0 min" : "0 m";
    }

    return result;
};

export const convertTimeToHtml = (time: IConvertTime | string, isLong?: boolean, boldedNumbers?: boolean) => {
    if (typeof time === "string") {
        return <StyledBoldSpan>{time}</StyledBoldSpan>;
    }
    let minutes: JSX.Element | null = <>{boldedNumbers ? <StyledBoldSpan>{time.minutes}</StyledBoldSpan> : time.minutes}<StyledSpan>{`${isLong}` ? "min" : "m"}</StyledSpan></>;
    if (time.days && time.hours) {
        minutes = null;
    } else if ((time.days || time.hours) && time.minutes !== undefined && time.minutes < 1) {
        minutes = null;
    }

    return (
        <>
            {time.days ? <>{boldedNumbers ? <StyledBoldSpan>{time.days}</StyledBoldSpan> : time.days} <StyledSpan>d</StyledSpan> </> : null}
            {time.hours ? <>{boldedNumbers ? <StyledBoldSpan>{time.hours}</StyledBoldSpan> : time.hours}<StyledSpan>h</StyledSpan> </> : null}
            {minutes}
        </>
    );
};

const roundOrFloorMinutes = (useRoundInsteadOfFloor: boolean, minutes: number) =>
    useRoundInsteadOfFloor ? Math.round(minutes) : Math.floor(minutes);

