import React from "react";
import { ITableProps } from "../ITableProps";
import { FormattedMessage } from "react-intl";
import { headerJustifyOptions } from "../../common/table/Table";
import NursingHomeActions from "./CareFacilityActions";
import StringFormatter from "../../common/table/formatters/StringFormatter";
import PaginatedTable from "../../common/table/PaginatedTable";
import { getLocalizedCountry, localizer } from "../../../utils/Localizer";
import ActiveStatusFormatter from "../../common/table/formatters/ActiveStatusFormatter";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { IContextStateType } from "../../../reducers/contextSlice";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../../utils/PathHelpers";
import ExternalIdFormatter from '../resident/CellFormatters/ExternalIdFormatter'
import {CareFacility} from '../../../models/CareFacility'

const CareFacilitiesTable = (props: ITableProps) => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const navigate = useNavigate();
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="careFacility.name" />,
                accessor: "name",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="constants.country" />,
                accessor: "countryName",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="nursingHomes.tableHeader.externalId" />,
                accessor: "externalId",
                Cell: ExternalIdFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 4
            },
            {
                Header: <FormattedMessage id="constants.status" />,
                accessor: "isActive",
                Cell: ActiveStatusFormatter,
                sortType: "basic",
                headerAlignment: headerJustifyOptions.left,
                flexShrink: 1,
            },
            {
                Header: <></>,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: NursingHomeActions,
                disableSortBy: true,
                flexShrink: 1,
                disableGlobalFilter: true
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    const localizedCountriesCareFacilities = data.map((cf: CareFacility) => {
        const countryCode = context.userResourcesHierarchy.find((country) => country.id === Number(cf.countryId))?.name ?? cf.countryId.toString();
        return {
            ...cf,
            externalId: cf.externalIntegrationId !== null ? cf.externalId : null,
            countryName: getLocalizedCountry(countryCode) || countryCode
        };
    });
    const styleProps = { rowHeight: 56 };
    const tableHeaderText = localizer("mainNav.careFacilities");

    return (
        <PaginatedTable
            qaId={"nh_table"}
            columns={columns}
            data={localizedCountriesCareFacilities}
            tableHeaderText={tableHeaderText}
            summaryId="careFacility.table.summary"
            styleProps={styleProps}
            addClick={() => navigate(routePaths.addCareFacility)}
            initialState={{
                sortBy: [
                    {
                        id: "name",
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(CareFacilitiesTable);
