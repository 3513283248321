import React, { SVGProps } from "react";

function OffIcon(props: SVGProps<any>): React.ReactElement {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg" {...props} >
            <circle cx="12.5" cy="12" r="8"/>
            <path d="M9.31832 8.81798C9.4361 8.70021 9.58649 8.59745 9.72519 8.51775L16.0019 14.7945C15.9415 14.8906 15.8426 15.0217 15.6823 15.1819C15.522 15.3422 15.3909 15.4411 15.2948 15.5016L9.01809 9.22486C9.09779 9.08616 9.20054 8.93576 9.31832 8.81798ZM8.8762 9.50949L8.87652 9.50868L8.87646 9.50883L8.87636 9.50909L8.8762 9.50949Z" />
            <path d="M9.31847 15.1819C9.2007 15.0641 9.09794 14.9138 9.01824 14.7751L15.295 8.49834C15.391 8.55878 15.5222 8.65768 15.6824 8.81796C15.8427 8.97824 15.9416 9.10935 16.0021 9.20544L9.72535 15.4822C9.58664 15.4025 9.43625 15.2997 9.31847 15.1819ZM10.01 15.624L10.0092 15.6237L10.0093 15.6238L10.0096 15.6239L10.01 15.624Z"/>
        </svg>


    );
}


export default OffIcon;
