import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { appInsights } from '../../../../AppInsights';
import Colors from '../../../../assets/Colors';
import { ETrackEvent } from '../../../../utils/AppInsightsHelpers';
import { routePaths } from "../../../../utils/PathHelpers";
import { StyledLeft } from '../../../common/table/formatters/PositionContainers';
import TextOverflowWrapper from '../../../wrappers/TextOverflowWrapper';

const CustomStyledLeft = styled(StyledLeft)`
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-start;
    font-size: 14px;
`;

const ProgressBarContainer = styled.div`
    width: 380px;
`;

const ProgressBar = styled.div<any>`
    height: 10px;
    width: ${props => props.percentage}%;
    border-radius: 0px 8px 8px 0px;
    background-color: ${Colors.blue.brandingDark};
`;

const HomeResidentFormatter = (props: any) => {
    const navigate = useNavigate();
    const { id } = props.row.original;
    return (
        <CustomStyledLeft>
            <TextOverflowWrapper
                onClick={() => {
                    appInsights.trackEvent({ name: ETrackEvent.JumpToResidentViewFromName });
                    navigate(`${routePaths.careRecipients}/${id}`);
                }}
                tooltip={props.value} >
                {props.value}
            </TextOverflowWrapper>
            <ProgressBarContainer>
                <ProgressBar percentage={props.barPercentage} />
            </ProgressBarContainer>
        </CustomStyledLeft>
    );
};

export default HomeResidentFormatter;
