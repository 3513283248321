import { FormattedMessage } from "react-intl";
import { ReactComponent as CeIcon } from "../assets/images/ce.svg";
import { ReactComponent as CHREPIcon } from "../assets/images/chrep.svg";
import { ReactComponent as CopyrightIcon } from "../assets/images/copyright.svg";
import { ReactComponent as FactoryIcon } from "../assets/images/factory.svg";
import { ReactComponent as MdIcon } from "../assets/images/md.svg";
import { ReactComponent as REFIcon } from "../assets/images/ref.svg";
import { ReactComponent as UKCAIcon } from "../assets/images/ukca.svg";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import TermsOfUseForm from "../components/themed/termsOfUse/TermsOfUseForm";
import { RestClient } from "../data/RestClient";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { IConfigurationStateType } from "../reducers/configurationSlice";
import { fetchResources, IContextStateType } from "../reducers/contextSlice";
import { countriesWithTermsOfUse } from "../settings/Settings";
import { localizer } from "../utils/Localizer";
import { REACT_APP_VERSION } from "../utils/ProcessEnvHelpers";
import {
    Address,
    AdressContainer,
    ButtonsContainer,
    CopyrightContainer, CustomATag,
    Divider,
    FirstRow,
    FooterContainer, FooterInfoContainer,
    InfoContainer, InfoIconContainer,
    REFContainer,
    StyledButton, StyledButtonWrapper
} from "./FooterStyle";

const divider = "|";

const Footer = () => {
    const dispatch = useAppDispatch();
    const { cookieScriptExecution } = useAppSelector<IConfigurationStateType>((state) => state.configurationSlice);

    const {
        countries,
        isTermsOfUseAcceptanceNeeded,
        loggedInUserId
    } = useAppSelector<IContextStateType>((state) => state.contextSlice);

    const triggerComponent: React.ReactNode = (
        <StyledButton>
            {localizer("cookies.termsAndConditions")}
        </StyledButton>
    );

    const triggerComponentTermsOfUse: React.ReactNode = (
        <StyledButton>
            {localizer("termsOfUse.title")}
        </StyledButton>
    );

    const onHideModal = async (shouldSetTermsOfUse: boolean) => {
        if (shouldSetTermsOfUse) {
            await RestClient.setTermsOfUse();
            dispatch(fetchResources());
        }
    }

    return (
        <FooterContainer>
            <FirstRow>
                <div>
                    <CeIcon height={18} />
                    <MdIcon height={18} />
                </div>
                <CopyrightContainer>
                    <div>
                        <CopyrightIcon />
                    </div>
                    <span>
                        Version {REACT_APP_VERSION || "debug"}
                    </span>
                    {" "}
                    {divider}
                    {" "}
                    {localizer("help.about.copyright")}
                </CopyrightContainer>
                <div>
                    UDI DI 7322542030462
                </div>
                <ButtonsContainer>
                    {cookieScriptExecution && (
                        <>
                            <StyledButtonWrapper>
                                {/* OneTrust Cookies Settings button */}
                                <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                                    {localizer("cookies.cookiePreferences")}
                                </button>
                            </StyledButtonWrapper>
                            <ModalWrapper
                                triggerComponent={triggerComponent}
                                modalContent={<>
                                    <p><FormattedMessage id={"cookies.subTitle"} /> </p>
                                    <h2><FormattedMessage id={"cookies.firstParagraphTitle"} /> </h2>
                                    <p><FormattedMessage id={"cookies.firstParagraph"} /> </p>
                                    <h2><FormattedMessage id={"cookies.secondParagraphTitle"} /> </h2>
                                    <p><FormattedMessage id={"cookies.secondParagraph"} /> </p>
                                    <p><FormattedMessage id={"cookies.thirdParagraph"} /> </p>
                                    {/* OneTrust Cookies List */}
                                    <div id="ot-sdk-cookie-policy"></div>
                                </>}
                                modalTitle={<FormattedMessage id={"cookies.title"} />}
                                size={EModalSize.M}
                            />
                        </>
                    )}
                    {loggedInUserId > 0 && (countries && (countries.length === 0 || countries.some(country => countriesWithTermsOfUse.includes(country)))) && (
                        <>
                            <ModalWrapper
                                closeCallback={() => onHideModal(isTermsOfUseAcceptanceNeeded)}
                                triggerComponent={triggerComponentTermsOfUse}
                                isOpen={isTermsOfUseAcceptanceNeeded}
                                modalContent={
                                    <TermsOfUseForm
                                        loggedInUserId={loggedInUserId}
                                        isTermsOfUseAcceptanceNeeded={isTermsOfUseAcceptanceNeeded}
                                        onHideModal={onHideModal}
                                    />
                                }
                                modalTitle={<FormattedMessage id={"termsOfUse.title"} />}
                                size={EModalSize.M}
                                fixedFooter={true}
                            />
                            <CustomATag
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`${localizer("privacyPolicy.link")}`}
                            >
                                {localizer("privacyPolicy.linkText")}
                            </CustomATag>
                        </>
                    )}
                    <REFContainer>
                        <REFIcon />
                        {localizer("help.about.refNumber")}
                    </REFContainer>
                </ButtonsContainer>
            </FirstRow>
            <Divider />
            <FooterInfoContainer>
                <InfoContainer>
                    <InfoIconContainer>
                        <FactoryIcon height={20} />
                    </InfoIconContainer>
                    <AdressContainer>
                        <Address>
                            {localizer("help.about.address")}
                        </Address>
                        <Address>
                            {localizer("help.about.visitorAddress")}
                        </Address>
                    </AdressContainer>
                </InfoContainer>
                <InfoContainer>
                    <InfoIconContainer>
                        <UKCAIcon height={32} />
                    </InfoIconContainer>
                    <AdressContainer width={105}>
                        <Address>
                            {localizer("footer.ukAddress")}
                        </Address>
                    </AdressContainer>
                </InfoContainer>
                <InfoContainer>
                    <InfoIconContainer>
                        <CHREPIcon height={20} />
                    </InfoIconContainer>
                    <AdressContainer width={270}>
                        <Address>
                            {localizer("footer.chAddress")}
                        </Address>
                    </AdressContainer>
                </InfoContainer>
            </FooterInfoContainer>
        </FooterContainer>
    );
}

export default Footer;