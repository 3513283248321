import styled from "styled-components";
import { useCookies } from "react-cookie";
import { languageCookieName, getLocale, localizations, convertLangCode, convertLocale, availableLanguages } from "../utils/Localizer";
import CustomDropdown from "./CustomDropdown";
import { IDropDownOption } from "../components/common/IDropDownOption";
import { useCallback, useEffect } from "react";
import { mobileThresholdWidth } from "../settings/Settings";
import { useAppSelector } from "../hooks/useAppSelector";
import { IContextStateType } from "../reducers/contextSlice";

const StyledLanguageSelector = styled.div`
    cursor: pointer;
`;

function LanguageSelector(): React.ReactElement {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);

    const { language }= context;
    const [cookies, setCookie] = useCookies([languageCookieName]);
    const queryParams = new URLSearchParams(document.location.search);
    const lang = queryParams.get("lang");
    const languages: IDropDownOption[] = [
        { value: "cs", label: "Čeština" },
        { value: "da", label: "Dansk" },
        { value: "de", label: "Deutsch" },
        { value: "en-AU", label: "English (AUS)" },
        { value: "en", label: "English (UK)" },
        { value: "en-US", label: "English (US)" },
        { value: "es", label: "Español" },
        { value: "fr", label: "Français" },
        { value: "fr-BE", label: "Français (BE)" },
        { value: "fr-CA", label: "Français (CA)" },
        { value: "it", label: "Italiano" },
        { value: "nl-BE", label: "Nederlands (BE)" },
        { value: "nl", label: "Nederlands (NL)" },
        { value: "nb", label: "Norsk" },
        { value: "pt", label: "Português" },
        { value: "ro", label: "Română" },
        { value: "sk", label: "Slovák" },
        { value: "fi", label: "Suomeksi" },
        { value: "sv", label: "Svenska" },
    ];

    const existingLocalizations: string[] = Object.keys(localizations);

    const options = languages.filter(language => existingLocalizations.some((localization: string) => {
        return localization === convertLangCode(language.value);
    }));


    const languageChange = useCallback(({ value }: { value: string }) => {
        document.documentElement.lang = convertLocale(value);
        setCookie(languageCookieName, value, { path: "/" });
        window.location.reload();
    }, [setCookie]);

    const selectedOption = options.find(option => option.value === getLocale(cookies[languageCookieName]));

    useEffect(() => {
        if(cookies[languageCookieName]) {
            if (lang && getLocale(cookies[languageCookieName]) !== lang && availableLanguages.includes(lang)) {
                const url = new URL(window.location.href);
                url.searchParams.delete("lang");
                window.history.replaceState({}, "", url.toString());
                languageChange({ value: lang });
            }
        } else {
            if(language && language !== selectedOption?.value) {
                languageChange({ value: language });
            }
        }
    }, [language, cookies, lang, languageChange, selectedOption?.value]);

    return (
        <StyledLanguageSelector >
            <CustomDropdown
                qaId="language_dropdown"
                selectedOption={selectedOption}
                handleChange={languageChange}
                options={options}
                placeholder={""}
                width={130}
                fontSize={14}
                maxHeight={window.innerWidth > mobileThresholdWidth ? 500 : 350}
            />
        </StyledLanguageSelector>
    );
}

export default LanguageSelector;
