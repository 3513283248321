import {ReactNode} from "react";
import {useAppSelector} from "../../../../hooks/useAppSelector";
import ErrorCodes from "../../../../models/ErrorCodes";
import {ICountryListItem} from "../../../../models/ICountryListItem";
import {Roles} from "../../../../models/Role";
import {IContextStateType} from "../../../../reducers/contextSlice";
import {availableLanguages, localizer} from "../../../../utils/Localizer";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import RadioButtons, {IRadioEntry} from "../../../common/controls/RadioButtons";
import TextField from "../../../common/controls/TextFieldNew";
import ValidatedFormGroupWrapper from "../../../wrappers/ValidatedFormGroupWrapper";
import CountriesScrollableContainer from "./CountriesScrollableContainer";
import {Divider, InfoContainer, InputContainer, SectionContainer} from "./UserEditFormStyle";
import InternalUser from "../../../../models/InternalUser";
import { SectionHeader } from "../../../common/View";

export enum Fields {
    Email = "Email",
    Mobile = "Mobile",
    Language = "Language",
    Role = "Role",
    AssignedResources = "AssignedResources"
}

export enum Context {
    Add,
    Edit,
    View
}

interface IProps {
    children: ReactNode;
    onSubmit: (event: any) => Promise<void>;
    radioRoleValues: IRadioEntry[];
    validationErrors: string[];
    filterValue: string;
    setFilterValue: (value: string) => void;
    countries: ICountryListItem[];
    user: InternalUser;
    initialUserState?: InternalUser;
    handleChange: (name: string, value: any) => void;
    formContext: Context;
}

const UserInternalEditForm = (props: IProps) => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const {
        children,
        onSubmit,
        radioRoleValues,
        validationErrors,
        countries,
        user, handleChange,
    } = props;

    const { role: selectedRole } = user;

    return (
        <>
            <SectionContainer onSubmit={onSubmit}>
                <SectionHeader>
                    {localizer("users.addUser.selectRole")}
                </SectionHeader>
                <Divider />
                <RadioButtons
                    entries={radioRoleValues}
                    selectedRadio={user.role}
                    setSelectedRadio={(value: any) => handleChange(Fields.Role, value)}
                />
                {selectedRole === Roles.CountryAdmin && context.role === Roles.GlobalAdmin && (
                    <>
                        <SectionHeader>
                            {localizer("users.addUser.countryAccess")}
                        </SectionHeader>
                        <Divider />
                        <CountriesScrollableContainer
                            allCountries={countries}
                            selectedIds={user.assignedResourceIds}
                            onCountrySelect={(countries: ICountryListItem[]) => handleChange(Fields.AssignedResources, countries.map((country) => country.id))}
                        />
                    </>
                )}
                <SectionHeader>
                    {localizer("users.addUser.generalSettings")}
                </SectionHeader>
                <Divider >
                    <InfoContainer>
                        * {" " + localizer("users.addUser.generalSettings.requiredField")}
                    </InfoContainer>
                </Divider>
                <InputContainer>
                        <>
                            <ValidatedFormGroupWrapper
                                validationErrors={validationErrors}
                                dependentErrorCodes={[ErrorCodes.FaultyNameErrorEmail, ErrorCodes.FaultyNameErrorEmpty, ErrorCodes.UserAlreadyExists, ErrorCodes.AccountDoesNotExistInAD]}
                                label={"loginView.email"}
                                required
                            >
                                <TextField
                                    placeholder="name@email.com"
                                    fieldName={Fields.Email}
                                    value={user.email}
                                    onChange={(name, value: any) => handleChange(name, value)}
                                />
                            </ValidatedFormGroupWrapper>

                            <ValidatedFormGroupWrapper
                                validationErrors={validationErrors}
                                dependentErrorCodes={[ErrorCodes.FaultyMobileNumber]}
                                label={"account.mobile"}
                                fullWidthMessage
                            >
                                <TextField
                                    placeholder="+46 76 123 45 67"
                                    fieldName={Fields.Mobile}
                                    value={user.mobileNumber}
                                    onChange={(name, value: any) => handleChange(name, value)}
                                />
                            </ValidatedFormGroupWrapper>
                        </>
                </InputContainer>
                <InputContainer halfRow>
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[]}
                        label={"account.language"}
                        required
                    >
                        <DropdownButtonNew
                            id="language"
                            name={Fields.Language}
                            selectedValue={user.language}
                            handleChange={(name, value) => handleChange(name, value)}
                            options={availableLanguages.map((language) => { return { label: localizer("language." + language.toLowerCase()), value: language.toLowerCase() } })}
                        />
                    </ValidatedFormGroupWrapper>
                </InputContainer>
                {children}
            </SectionContainer>
        </>
    );
}

export default UserInternalEditForm;

