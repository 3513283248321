import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import ExternalUserView from "../components/themed/users/views/ExternalUserView";
import GlobalAdminUserView from "../components/themed/users/views/GlobalAdminUserView";
import { useAppSelector } from "../hooks/useAppSelector";
import { Roles } from "../models/Role";
import { IContextStateType } from "../reducers/contextSlice";

const UsersView = () => {
    const { role, id } = useAppSelector<IContextStateType>(state => state.contextSlice);

    if (id && id !== "") {
        switch (role) {
            case Roles.GlobalAdmin:
                return <GlobalAdminUserView />
            case Roles.CountryAdmin:
            case Roles.NHManager:
            case Roles.Support:
            case Roles.Nurse:
                return <ExternalUserView />
            default:
                return null
        }
    } else {
        switch (role) {
            case Roles.GlobalAdmin:
                return <GlobalAdminUserView />
            case Roles.CountryAdmin:
            case Roles.NHManager:
            case Roles.Support:
            case Roles.Nurse:
                return <ExternalUserView />
            default:
                return null
        }
    }
}

export default withAITracking(reactPlugin, UsersView);
