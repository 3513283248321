import { Country } from '../../../models/Country';
import ActionsContainer from '../../common/table/formatters/ActionsContainer';
import CountryEditIcon from './CountryEditIcon';

const CountryActions = (props: any) => {
    return (
        <ActionsContainer>
            <CountryEditIcon
                country={new Country(props.row.original)}
            />
        </ActionsContainer>
    );
};

export default CountryActions;