import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import ExpiredLinkBlock, { ExpiredLinkContext } from "../components/themed/login/ExpiredLinkBlock";
import PasswordSetupForm from "../components/themed/login/PasswordSetupForm";
import { RestClient } from "../data/RestClient";
import { EApp } from "../models/EApp";
import ErrorCodes from "../models/ErrorCodes";
import { withRouter } from "../routes/routesHelper";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { passwordRegex } from "../utils/Regex";
import { toastError } from "../utils/Toaster";

const ActivateAccountView = () => {
    const [hasError, setHasError] = useState<boolean>(false);
    const [passwordSet, setPasswordSet] = useState<boolean>(false);
    const [isActivatedUserCaregiver, setIsActivatedUserCaregiver] = useState<boolean>(false);
    const [products, setProducts] = useState<EApp[]>([]);
    const [activationLinkValid, setActivationLinkValid] = useState<boolean>(false);
    const [activationLinkVerificationCompleted, setActivationLinkVerificationCompleted] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const user = queryParams.get("user");
    const token = queryParams.get("token");
    const externalId = queryParams.get("externalId");
    const product = queryParams.get("product");

    if (!token || !user) {
        navigate(routePaths.loginPath);
    }

    useEffect(() => {
        const verifyLinkValidity = async () => {
            const response = await RestClient.verifyResetPasswordLink(user, token, externalId, product);
            setActivationLinkVerificationCompleted(true);
            setActivationLinkValid(response.isLinkValid);
        };

        verifyLinkValidity();
    }, [user, token, externalId, product]);

    const handleSubmit = async (event: any, password: string, confirmPassword: string) => {
        event.preventDefault();
        if (!passwordRegex.test(password)) {
            setHasError(true);
            setError(ErrorCodes.PasswordUnmeetCriteria)
        } else if (password !== confirmPassword) {
            setHasError(true);
            setError(ErrorCodes.PasswordNotMatching)
        } else {
            try {
                const response = await RestClient.setPassword({
                    username: user,
                    password,
                    passwordResetCode: token,
                    externalId,
                    product
                });
                setPasswordSet(true);
                setProducts([...response.products]);
                setIsActivatedUserCaregiver(response.isCaregiver);
            } catch (error: any) {
                if (error.status === 400) {
                    setHasError(true);
                    setError(ErrorCodes.PasswordTokenExpired);
                } else {
                    toastError(error);
                }
            }
        }
    };

    let content;
    if (!token || !user || !activationLinkVerificationCompleted) {
        content = <></>;
    } else if (!activationLinkValid) {
        content = <ExpiredLinkBlock context={ExpiredLinkContext.SET_PASSWORD}></ExpiredLinkBlock>;
    } else {
        content = (<PasswordSetupForm
            title={localizer("account.setPassword.title", { username: user ?? "" })}
            mobilePasswordSetTitle={localizer("account.mobile.passwordSet")}
            user={user}
            token={token}
            passwordSet={passwordSet}
            products={products}
            isActivatedUserCaregiver={isActivatedUserCaregiver}
            handleSubmit={handleSubmit}
            error={error}
            hasError={hasError}
            setHasError={setHasError}
            setError={setError}
            submitButtonText={localizer("account.setPassword")}
            dependentErrorCodes={[ErrorCodes.PasswordNotMatching, ErrorCodes.PasswordUnmeetCriteria, ErrorCodes.PasswordTokenExpired]}
        />);
    }

    return content;
}

export default withAITracking(reactPlugin, withRouter(ActivateAccountView));
