import styled from "styled-components";

const Ring = styled.div<any>`
    width: 100%;
    max-width: ${props => props.maxWidth ? props.maxWidth : 168}px;
    font-size: 32px;
    color: #333333;
`;

const Speed = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background: #AAC3D9;
    position: relative;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    overflow: hidden;
`;

const Number = styled.div<any>`
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: #1D388B;
    transform-origin: center top;
    transform: rotate(${props => (props.value / 100) / 2}turn);
    transition: transform 0.2s ease-out;
`;

const Units = styled.div`
    width: 80%;
    height: 180%;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    /* Text */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 25%;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 700;
`;

interface ISpeedometerProps {
    number: number | string;
    maxWidth?: number;
}
const Speedometer = (props: ISpeedometerProps) => {
    const units = typeof props.number === "string" ? props.number : props.number + "%";
    return (
        <Ring maxWidth={props.maxWidth}>
            <Speed>
                <Number value={props.number}></Number>
                <Units>{units}</Units>
            </Speed>
        </Ring>
    )
}

export default Speedometer;