import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ActionButtonsContainer, ButtonContainer, PageHeader, StyledButton, StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import { IRadioEntry } from "../components/common/controls/RadioButtons";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import RoleInformationModal from "../components/themed/users/common/RoleInformationModal";
import UserEditForm, { Context, Fields } from "../components/themed/users/common/UserExternalEditForm";
import { RestClient } from "../data/RestClient";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { CustomerAdUserManagementFlow } from "../models/ECustomerAdUserManagementFlow";
import { ExternalUserType } from "../models/EExternalUserType";
import ErrorCodes from "../models/ErrorCodes";
import User from "../models/ExternalUser";
import { Role, Roles } from "../models/Role";
import { IContextStateType, IUserResourcesHierarchy, setFormDirty } from "../reducers/contextSlice";
import { IUserStateType, deleteExternalUser, expireSupportUser, resendActivationEmail, updateExternalUser } from "../reducers/userSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { emailRegex, passwordRegex, phoneRegex } from "../utils/Regex";
import { toastError } from "../utils/Toaster";
import GenericConfirmationForm from "../views/GenericConfirmationForm";
import { InfoContainer, InfoHeader, StyledInfoIcon } from "./EditExternalUserViewStyle";

const setAvailableRoles = (role: Roles, user: User, viewMode: boolean = false): IRadioEntry[] => {
    const isCaregiverWithUsername = user.role === Roles.Caregiver && user.externalUserType === ExternalUserType.Username;

    switch (role) {
        case Roles.Support:
        case Roles.NHManager:
            if (user.role === Roles.Support) {
                return [
                    { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager), disabled: true },
                    { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: true },
                    { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: true },
                    { value: Roles.Support, label: localizer("user.role." + Roles.Support), disabled: true }
                ]
            }
            return [
                { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager), disabled: isCaregiverWithUsername },
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: isCaregiverWithUsername },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: isCaregiverWithUsername },
            ];
        case Roles.Nurse:
            if ([Roles.Support, Roles.NHManager].includes(user.role)) {
                return [
                    { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager), disabled: viewMode || isCaregiverWithUsername },
                    { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: viewMode || isCaregiverWithUsername },
                    { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: viewMode || isCaregiverWithUsername },
                ];
            }
            return [
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: isCaregiverWithUsername },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: isCaregiverWithUsername },
            ];
        case Roles.CountryAdmin:
            if (user.role === Roles.Support) {

                return [
                    { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager), disabled: true },
                    { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: true },
                    { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: true },
                    { value: Roles.Support, label: localizer("user.role." + Roles.Support), disabled: true }
                ]
            }
            return [
                { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager), disabled: isCaregiverWithUsername },
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse), disabled: isCaregiverWithUsername },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver), disabled: isCaregiverWithUsername },
            ];
    }
    return [];
};

const disableSaveButton = (initialState: User | undefined, userEntity: User | undefined, allNHResources: IUserResourcesHierarchy[]): boolean => {
    if (!initialState || !userEntity) {
        return true;
    }
    if (userEntity.role === Roles.Caregiver && allNHResources.some(nh => nh.id === userEntity.assignedResourceIds[0] && nh.enforceEmailForCaregiver) && userEntity.externalUserType === ExternalUserType.Username) {
        return true;
    }
    if (initialState.email !== userEntity.email) {
        return false;
    }
    if (initialState.mobileNumber !== userEntity.mobileNumber) {
        return false;
    }
    if (initialState.username !== userEntity.username) {
        return false;
    }
    if (initialState.language !== userEntity.language) {
        return false;
    }
    if (initialState.role !== userEntity.role) {
        return false;
    }
    if (initialState.password !== userEntity.password) {
        return false;
    }
    if (!isEqual(initialState.assignedResourceIds, userEntity.assignedResourceIds) && userEntity.assignedResourceIds.length > 0) {
        return false;
    }
    if (initialState.role === Roles.Support && !isEqual(initialState.supportUserNursingHomes, userEntity.supportUserNursingHomes)) {
        return false;
    }

    if (initialState.role === Roles.Caregiver && !isEqual(initialState.assignedWardsIds, userEntity.assignedWardsIds)) {
        return false;
    }

    if (initialState.externalUserType !== userEntity.externalUserType) {
        return false;
    }

    return true;
}

const isViewMode = (user: User, role: Roles): boolean => {
    if (role === Roles.Nurse) {
        return [Roles.NHManager, Roles.Support].includes(user.role);
    }
    if (role === Roles.CountryAdmin) {
        return user.role === Roles.Support;
    }
    return false;
}

const EditUserView = () => {
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { id } = useParams();
    const [userEntity, setUserEntity] = useState<User | undefined>(undefined);
    const [validationErrors, setErrors] = useState<any[]>([]);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [confirmExpire, setConfirmExpire] = useState<boolean>(false);
    const { allNHResources } = context;
    const [initialState, setInitialState] = useState<User | undefined>(undefined);
    const [radioRoleValues, setRadioValues] = useState<IRadioEntry[]>([]);
    const [viewMode, setViewMode] = useState<boolean>(false);
    const [rolesToShowInRoleInfoPopup, setRolesToShowInRoleInfoPopup] = useState<Role[]>([]);
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const leaveFormTitleId="general.leaveTitle";
    const leaveFormMessageId = "general.leaveMessage";
    const leaveFormConfirmTextId = "constants.leave";

    useEffect(() => {
        const fetchUser = async () => {
            if (context.role !== Roles.Invalid) {
                try {
                    let user;
                    if (context.role === Roles.GlobalAdmin) {
                        user = await RestClient.getUserInternal(id);
                    } else {
                        user = await RestClient.getUserExternal(id, context.id);
                    }
                    const userEntity = new User(user);
                    const formDisabled = isViewMode(userEntity, context.role);
                    setUserEntity(userEntity);
                    setViewMode(formDisabled);
                    const initialState = cloneDeep(userEntity);
                    setInitialState(initialState);
                    setRadioValues(setAvailableRoles(context.role, userEntity, formDisabled));
                } catch (error: any) {
                    toastError(localizer(error));
                }
            }
        };
        fetchUser();
    }, [context.id, context.role, id]);

    useEffect(() => {
        setRadioValues(setAvailableRoles(context.role, userEntity ?? new User(), viewMode));
    }, [context, userEntity, viewMode]);

    const { users } = useAppSelector<IUserStateType>((state) => state.userSlice);

    const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

    const handleChange = useCallback((name: string, value: any, resourceId?: number) => {
        const user = cloneDeep(userEntity) ?? new User();
        switch (name) {
            case Fields.Email:
                user.email = value;
                break;
            case Fields.Mobile:
                user.mobileNumber = value;
                break;
            case Fields.Username:
                user.username = value;
                break;
            case Fields.Password:
                user.password = value;
                break;
            case Fields.Language:
                user.language = value;
                break;
            case Fields.LoginMethod:
                user.externalUserType = value;
                break;
            case Fields.Role:
                user.role = value;
                if (user.role === Roles.Caregiver) {
                    if (user.assignedResourceIds?.length > 1) {
                        const selectedCareFacilities = (allApplicableNhResource).filter((careFacility: IUserResourcesHierarchy) => user.assignedResourceIds.includes(careFacility.id));
                        if (selectedCareFacilities?.length > 0) {
                            user.assignedResourceIds = [selectedCareFacilities[0].id];
                        }
                    }
                    if(!user.assignedWardsIds || user.assignedWardsIds?.length === 0) {
                        user.assignedWardsIds = allNHResources.find(nh => nh.id === user.assignedResourceIds[0])?.children.map(ward => ward.id) || [];
                    }
                }

                break;
            case Fields.AssignedResources:
                user.assignedResourceIds = value;
                if (user.role === Roles.Caregiver) {
                    user.assignedWardsIds = allNHResources.find(nh => nh.id === user.assignedResourceIds[0])?.children.map(ward => ward.id) || [];
                }
                if (user.role === Roles.Support) {
                    if (user.supportUserNursingHomes && user.supportUserNursingHomes.length > 0) {
                        const updatedList: { nursingHomeId: number; expirationDateTime: string; }[] | undefined = []
                        user.supportUserNursingHomes.forEach(nh => {
                            if (value.includes(nh.nursingHomeId)) {
                                updatedList.push({
                                    nursingHomeId: nh.nursingHomeId,
                                    expirationDateTime: nh.expirationDateTime
                                })
                            }
                        })
                        value.forEach((id: number) => {
                            if (user.supportUserNursingHomes && !user.supportUserNursingHomes.some(obj => obj.nursingHomeId === id)) {
                                updatedList.push({
                                    nursingHomeId: id,
                                    expirationDateTime: moment().add(1, "days").toISOString()
                                })
                            }
                        })
                        user.supportUserNursingHomes = updatedList;
                    } else {
                        user.supportUserNursingHomes = value.map((id: number) => ({
                            nursingHomeId: id,
                            expirationDateTime: moment().add(1, "days").toISOString()
                        }));
                    }
                }
                const assignedNhs = allNHResources.filter(nh => user.assignedResourceIds.includes(nh.id));
                const enableCustomerAdLogin = assignedNhs.length > 0 && assignedNhs.every(nh => nh && nh.customerAdUserManagementFlow === CustomerAdUserManagementFlow.CIUserManagement);
                if (user.externalUserType === ExternalUserType.Federated && !enableCustomerAdLogin) {
                    user.externalUserType = ExternalUserType.Email;
                }
                break;
            case Fields.ExpirationDates:
                if (user.supportUserNursingHomes && user.supportUserNursingHomes?.length > 0) {
                    const updatedList = user.supportUserNursingHomes.map(nh => {
                        if (nh.nursingHomeId === resourceId) {
                            nh.expirationDateTime = moment(value).toISOString();
                        }
                        return nh;
                    })
                    user.supportUserNursingHomes = updatedList;
                } else {
                    const updatedList = user.assignedResourceIds.filter(id => id === resourceId).map(id => ({
                        nursingHomeId: id,
                        expirationDateTime: moment(value).toISOString()
                    }));
                    user.supportUserNursingHomes = updatedList;
                }
                break;
            case Fields.AssignedWards:
                user.assignedWardsIds = value;
                break;
            default:
                break;
        }
        if (!isFormDirty && !isEqual(initialState, user)) {
            dispatch(setFormDirty(true, leaveFormTitleId, leaveFormMessageId, leaveFormConfirmTextId));
        } else if (isFormDirty && isEqual(initialState, user)) {
            dispatch(setFormDirty(false));
        }
        setUserEntity(user);
    }, [userEntity]);

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        if (!userEntity) {
            return;
        }
        const { id,
            username,
            password,
            email,
            role,
            language,
            assignedResourceIds,
            mobileNumber,
            externalUserType,
            supportUserNursingHomes,
            assignedWardsIds
        } = userEntity;
        const user = externalUserType !== ExternalUserType.Username ? email : username;
        const pass = externalUserType === ExternalUserType.Username ? password : undefined;
        const careFacilities = assignedResourceIds;
        await dispatch(updateExternalUser(
            id,
            user,
            role,
            careFacilities,
            pass,
            language,
            externalUserType !== ExternalUserType.Username ? mobileNumber : undefined,
            externalUserType,
            supportUserNursingHomes,
            assignedWardsIds
        ));
        navigate(routePaths.users);
        dispatch(setFormDirty(false));
        window.scrollTo(0, 0);
    };

    const onResendActivationEmail = async (event: any) => {
        event.preventDefault();
        if (!userEntity) {
            return;
        }
        const { id } = userEntity;
        dispatch(resendActivationEmail(id));
    }

    const validate = (): Promise<boolean> => {
        const errors: any[] = [];
        if (userEntity) {
            const { username, password, email, role, assignedResourceIds, mobileNumber, externalUserType } = userEntity;
            if (role === Roles.Caregiver && externalUserType === ExternalUserType.Username) {
                if (!username) {
                    errors.push(ErrorCodes.FaultyNameErrorEmpty);
                }
                if (!(initialState!.role === Roles.Caregiver && password.length === 0)) {
                    if (((role === Roles.Caregiver && allNHResources.some(nh => nh.id === assignedResourceIds[0] && nh.isWeakAuthenticationAllowed)) ? password.length < 4 : !passwordRegex.test(password))) {
                        errors.push(ErrorCodes.PasswordUnmeetCriteria);
                    }
                }
            } else {
                if (!email) {
                    errors.push(ErrorCodes.FaultyNameErrorEmpty);
                }

                if ((email && email.length > 0) && !emailRegex.test(email.trim().toLowerCase())) {
                    errors.push(ErrorCodes.FaultyNameErrorEmail);
                }
            }

            if (users.some(user => user.name.toLocaleLowerCase() === email.toLocaleLowerCase()) && email !== initialState?.email) {
                errors.push(ErrorCodes.UserAlreadyExists);
            }

            if (mobileNumber && mobileNumber.length > 0 && !phoneRegex.test(mobileNumber.trim().toLowerCase())) {
                errors.push({
                    dependentErrorCode: ErrorCodes.FaultyMobileNumber,
                    values: { mobileNumber },
                });
            }

            setErrors(errors);

        }
        return Promise.resolve(errors.length < 1);
    };

    useEffect(() => {
        switch (context.role) {
            case Roles.Nurse:
                if (userEntity?.role === Roles.NHManager) {
                    setRolesToShowInRoleInfoPopup([Roles.NHManager, Roles.Caregiver, Roles.Nurse]);
                } else {
                    setRolesToShowInRoleInfoPopup([Roles.Caregiver, Roles.Nurse]);
                }
                break;
            case Roles.NHManager:
            case Roles.CountryAdmin:
                setRolesToShowInRoleInfoPopup([Roles.NHManager, Roles.Caregiver, Roles.Nurse]);
                break;
        }
    }, [context.role, userEntity]);

    const allApplicableNhResource = context.role === Roles.Support ? allNHResources.filter(nh => nh.isSupportUser) : allNHResources;

    return (
        <StyledViewContainer>
            <PageHeader>{localizer("users.editUser")}</PageHeader>
            {userEntity !== undefined && (
                <>
                    {!userEntity.canEditUserDetails && (
                        <InfoContainer>
                            <InfoHeader>
                                <StyledInfoIcon height={24} />
                                <div>{localizer("users.editUser.editRestriction.title")}</div>
                            </InfoHeader>
                            <div>
                                {localizer("users.editUser.editRestriction")}
                            </div>
                        </InfoContainer>)}
                    <UserEditForm
                        formContext={Context.Edit}
                        formDisabled={initialState?.readOnly}
                        user={userEntity}
                        onSubmit={onSubmit}
                        allNHResources={allApplicableNhResource}
                        validationErrors={validationErrors}
                        radioRoleValues={radioRoleValues}
                        setShowInfoPopup={setShowInfoPopup}
                        handleChange={handleChange}
                        initialUserState={initialState}
                    >
                        <ButtonContainer>
                            <BackHardButton
                                backRoute={routePaths.users}
                                onClick={() => !isEqual(initialState, userEntity) ? setConfirmLeave(true) : navigate(routePaths.users)}
                            />
                            <ActionButtonsContainer>
                                {!userEntity.isActivated && ![ExternalUserType.Username, ExternalUserType.Federated].includes(userEntity.externalUserType) && initialState?.role !== Roles.Support && initialState?.externalUserType === ExternalUserType.Email && (
                                    <StyledButton
                                        variant={"outline-secondary"}
                                        type={"button"}
                                        onClick={onResendActivationEmail}
                                        disabled={userEntity.readOnly}
                                    >
                                        {localizer("users.resendActivation")}
                                    </StyledButton>
                                )}
                                {userEntity.role === Roles.Support ?
                                    (
                                        <StyledButton
                                            variant={"outline-secondary"}
                                            disabled={userEntity.readOnly}
                                            type={"button"}
                                            onClick={() => setConfirmExpire(true)}
                                        >
                                            {localizer("users.expire")}
                                        </StyledButton>
                                    )
                                    :
                                    (
                                        <StyledButton
                                            variant={"outline-secondary"}
                                            type={"button"}
                                            disabled={userEntity.readOnly}
                                            onClick={() => setDeleteConfirmation(true)}
                                        >
                                            {localizer("users.deleteUser")}
                                        </StyledButton>
                                    )
                                }

                                <StyledButton
                                    variant={"primary"}
                                    type={"submit"}
                                    disabled={disableSaveButton(initialState, userEntity, allNHResources)}
                                >
                                    {localizer("constants.save")}
                                </StyledButton>

                            </ActionButtonsContainer>
                        </ButtonContainer>
                    </UserEditForm>

                </>
            )}
            <RoleInformationModal
                showInfoPopup={showInfoPopup}
                setShowInfoPopup={setShowInfoPopup}
                roles={rolesToShowInRoleInfoPopup}
            />
            {deleteConfirmation && (
                <ModalWrapper
                    closeCallback={() => setDeleteConfirmation(false)}
                    isOpen={deleteConfirmation}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"users.deleteUser.confirmation"}
                            confirmButton={"constants.delete"}
                            onConfirmCancel={() => {
                                setDeleteConfirmation(false);
                            }}
                            onConfirm={async () => {
                                await dispatch(deleteExternalUser(id ?? ""))
                                navigate(routePaths.users);
                                if (isFormDirty) {
                                    dispatch(setFormDirty(false));
                                }
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                    modalTitle={localizer("users.deleteUser")}
                    size={EModalSize.XS}
                />
            )}
            {confirmExpire && (
                <ModalWrapper
                    closeCallback={() => setConfirmExpire(false)}
                    isOpen={confirmExpire}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"users.expire.confirmation"}
                            confirmButton={"constants.expire"}
                            onConfirmCancel={() => {
                                setConfirmExpire(false);
                            }}
                            onConfirm={async () => {
                                await dispatch(expireSupportUser(id ?? ""))
                                navigate(routePaths.users);
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                    modalTitle={localizer("users.expire")}
                    size={EModalSize.XS}
                />
            )}
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={leaveFormMessageId}
                            confirmButton={leaveFormConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                navigate(routePaths.users);
                            }}
                        />
                    }
                    modalTitle={localizer(leaveFormTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </StyledViewContainer>
    )
}

export default withAITracking(reactPlugin, EditUserView);
