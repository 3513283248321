import React, { Component } from 'react';
import FormGroupTemplate from './FormGroupTemplate';
import './PolicyTimeSelectionFormGroup.scss';
import ErrorMessage from '../ErrorMessages/ErrorMessage';
import { FormattedMessage } from 'react-intl';
import CheckBox from "../../components/common/controls/CheckBox";
import { localizer } from "../../utils/Localizer";
import TimePickerDropdown from '../../components/common/controls/dateTime/TimePickerDropdown';

interface IPolicyTimeSelectionFormGroupProps {
    id: string;
    label: string;
    timeFieldName: string;
    timeFieldOnChange: (fieldName: string, value: any) => void;
    timeFieldValue: string;
    errorMessage: string | undefined;
    errorMessageStyles: string;
    optional?: boolean;
    minuteStep?: number;
    minTime?: string;
    disabled?: boolean;
    readOnly?: boolean;
    inputRef?: any;
}

interface IPolicyTimeSelectionFormGroupState {
    isDisabled: boolean;
    timeFieldValueBeforeDisabled: string | null;
    timeWidth: number;
}
export default class PolicyTimeSelectionFormGroup extends Component<IPolicyTimeSelectionFormGroupProps, IPolicyTimeSelectionFormGroupState> {
    public inputRef: any;

    constructor(props: IPolicyTimeSelectionFormGroupProps) {
        super(props);
        this.state = {
            isDisabled: !!(this.props.optional && this.props.timeFieldValue === ""),
            timeFieldValueBeforeDisabled: null,
            timeWidth: 0
        };
        this.inputRef = React.createRef();
    }

    handleChange = (_name: string, value: boolean) => {
        this.setState({ isDisabled: value });

        if (value) {
            this.setState({ timeFieldValueBeforeDisabled: this.props.timeFieldValue });
            this.props.timeFieldOnChange(this.props.timeFieldName, null);
        } else {
            this.props.timeFieldOnChange(this.props.timeFieldName, this.state.timeFieldValueBeforeDisabled);
        }
    };

    calculateDisabledHours = (): number[] => {
        const disabledHours = [];
        if (this.props.minTime) {
            const minHour = this.props.minTime.split(":")[0];

            for (let i = 0; i < Number(minHour); i++) {
                disabledHours.push(i);
            }
        }

        return disabledHours;
    }

    componentDidMount(): void {
        this.setState({ timeWidth: this.inputRef?.current?.offsetWidth });
    }

    componentDidUpdate(_prevProps: IPolicyTimeSelectionFormGroupProps, prevState: IPolicyTimeSelectionFormGroupState): void {
        if (prevState.timeWidth !== this.inputRef?.current?.offsetWidth) {
            this.setState({ timeWidth: this.inputRef?.current?.offsetWidth });
        }
    }

    calculateDisabledMinutes = (): number[] => {
        const disabledMinutes = [];

        if (this.props.minTime) {
            const minHour = this.props.minTime.split(":")[0];
            const hour = this.props.timeFieldValue?.split(":")[0];

            if (minHour === hour || !hour) {
                const minMinutes = this.props.minTime.split(":")[1];

                for (let i = 0; i < Number(minMinutes); i++) {
                    disabledMinutes.push(i);
                }
            }
        }

        return disabledMinutes;
    }

    render(): React.ReactElement {
        return (
            <FormGroupTemplate
                controlId={`${this.props.id}-form-group`}
                label={<FormattedMessage id={this.props.label} />}
                hasError={Boolean(this.props.errorMessage)}
            >
                <div ref={this.inputRef}>
                    {/* time input */}
                    <TimePickerDropdown
                        id={this.props.timeFieldName + "-tp"}
                        qaId={this.props.timeFieldName + "-time_picker_dropdown"}
                        readOnly={this.props.readOnly}
                        disabled={
                            this.state.isDisabled || !!this.props.disabled
                        }
                        value={this.props.timeFieldValue}
                        minTime={this.props.minTime}
                        onChange={(value: any) =>
                            value !== undefined && value !== null
                                ? this.props.timeFieldOnChange(
                                    this.props.timeFieldName,
                                    value.format("HH:mm")
                                )
                                : ""
                        }
                        minuteStep={10}
                        popupWidth={this.inputRef?.current?.offsetWidth}
                        calculateDisabledHours={this.calculateDisabledHours}
                        calculateDisabledMinutes={this.calculateDisabledMinutes}
                        showSuffixIcon
                    />
                    {this.props.optional && (
                        <CheckBox
                            label={localizer("policyTimeSelectionFormGroup.disableInput")}
                            value={this.state.isDisabled}
                            onChange={this.handleChange}
                            fieldName=""
                        />
                    )}
                </div>
                <ErrorMessage
                    errorMessageStyles={this.props.errorMessageStyles}
                    errorMessage={this.props.errorMessage}
                />
            </FormGroupTemplate>
        );
    }
}
