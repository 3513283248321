import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../../components/common/controls/PasswordField";
import ValidatedFormGroupWrapper from "../../../components/wrappers/ValidatedFormGroupWrapper";
import { EApp } from "../../../models/EApp";
import ErrorCodes from "../../../models/ErrorCodes";
import { localizer } from "../../../utils/Localizer";
import { routePaths } from "../../../utils/PathHelpers";
import {REACT_APP_IDENTIFI_RESET_SESSION_URL} from "../../../utils/ProcessEnvHelpers";
import { InfoContainer, MobileDescriptionContainer, RedirectButton, RedirectContainer, ResponsiveContainer, ResponsiveViewContainer, StyledSubmitButton, SuccessfulPasswordContainer, SuccessfulPasswordMobileContainer, TitleContainer } from "./PasswordSetupFormStyle";

const getRedirectButtons = (products: EApp[], navigate: any) => {
    if (products.length === 1) {
        if (products[0] === EApp.ChangeIndicator) {
            return <StyledSubmitButton onClick={() => navigate(routePaths.loginPath)}>{localizer("account.toLogin")}</StyledSubmitButton>
        } else {
            return (
                <StyledSubmitButton onClick={() => window.open(REACT_APP_IDENTIFI_RESET_SESSION_URL, "_self")}>
                    {localizer("account.toLogin")}
                </StyledSubmitButton>)
        }
    } else if (products.length === 2) {
        return (<RedirectContainer>
            <RedirectButton onClick={() => window.open(REACT_APP_IDENTIFI_RESET_SESSION_URL, "_self")}>
                {localizer("constants.identifi")}
            </RedirectButton>
            <RedirectButton onClick={() => navigate(routePaths.loginPath)}>
                {localizer("constants.changeIndicator")}
            </RedirectButton>
        </RedirectContainer>)
    }
    return <></>;
}
enum Fields {
    Password = "Password",
    ConfirmPassword = "ConfirmPassword"
}
interface IProps {
    setHasError: (hasError: boolean) => void;
    setError: (error: string) => void;
    handleSubmit: (event: any, password: string, confirmPassword: string) => void;
    passwordSet: boolean;
    products: EApp[];
    title: string;
    user: string | null;
    token: string | null;
    hasError: boolean;
    error: string;
    dependentErrorCodes: ErrorCodes[];
    submitButtonText: string;
    extraInfo?: string;
    mobilePasswordSetTitle: string;
    isActivatedUserCaregiver?: boolean;
}

const PasswordSetupForm = (props: IProps) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();
    const { setHasError, setError } = props;
    useEffect(() => {
        if (confirmPassword === "" && password === "") {
            setHasError(false);
            setError("");
        }
    }, [confirmPassword, password, setError, setHasError]);

    const mobileAndCaregiverSuccesfullPasswordSet = <>
        <TitleContainer>
            {props.mobilePasswordSetTitle}
        </TitleContainer>
        <MobileDescriptionContainer>
            <InfoContainer>
                {localizer("account.mobile.passwordSet.description")}
            </InfoContainer>
            <InfoContainer>
                <b>{localizer("account.mobile.passwordSet.header")}</b>
            </InfoContainer>
            <InfoContainer>
                {localizer("account.mobile.passwordSet.instructions")}
            </InfoContainer>
        </MobileDescriptionContainer>
    </>

    const content = props.passwordSet ? (
        <>
            <SuccessfulPasswordContainer>
                {props.isActivatedUserCaregiver ?
                    mobileAndCaregiverSuccesfullPasswordSet
                    : (
                        <>
                            <TitleContainer>
                                {localizer("account.passwordSet")}
                            </TitleContainer>
                            <InfoContainer>
                                {localizer("account.passwordSet.description")}
                            </InfoContainer>
                            {getRedirectButtons(props.products, navigate)}
                        </>
                    )}
            </SuccessfulPasswordContainer >
            <SuccessfulPasswordMobileContainer>
                {mobileAndCaregiverSuccesfullPasswordSet}
            </SuccessfulPasswordMobileContainer>

        </>) : (
        <ResponsiveViewContainer onSubmit={(event: any) => props.handleSubmit(event, password, confirmPassword)}>
            <TitleContainer>
                {props.title}
            </TitleContainer>
            <ResponsiveContainer>
                <InfoContainer>
                    <div>
                        {localizer("account.passwordRequirements")}
                    </div>
                    <ul>
                        <li>
                            {localizer("account.lowerCase")}
                        </li>
                        <li>
                            {localizer("account.upperCase")}
                        </li>
                        <li>
                            {localizer("account.number")}
                        </li>
                        <li>
                            {localizer("account.specialCharacter")}
                        </li>
                    </ul>
                    {props.extraInfo && (<div>
                        {props.extraInfo}
                    </div>)}
                </InfoContainer>
                <div>
                    <ValidatedFormGroupWrapper
                        validationErrors={props.hasError ? [props.error] : []}
                        dependentErrorCodes={props.dependentErrorCodes}
                        label={"account.password"}
                        hideMessage
                    >
                        <PasswordField
                            fieldName={Fields.Password}
                            value={password}
                            onChange={(_: any, value: any) => setPassword(value)}
                        />
                    </ValidatedFormGroupWrapper>
                    <ValidatedFormGroupWrapper
                        validationErrors={props.hasError ? [props.error] : []}
                        dependentErrorCodes={props.dependentErrorCodes}
                        label={"account.passwordConfirm"}
                    >
                        <PasswordField
                            fieldName={Fields.ConfirmPassword}
                            value={confirmPassword}
                            onChange={(_: any, value: any) => setConfirmPassword(value)}
                        />
                    </ValidatedFormGroupWrapper>
                </div>
            </ResponsiveContainer>
            <StyledSubmitButton
                disabled={password === "" || confirmPassword === ""}
                type="submit"
            >
                {props.submitButtonText}
            </StyledSubmitButton>
        </ResponsiveViewContainer>
    );
    return (!props.token || !props.user) ? <></> : content;
}

export default PasswordSetupForm;
