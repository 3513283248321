import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Colors from "../assets/Colors";
import { ReactComponent as ExclamationIcon } from "../assets/images/maintenanceExclamationIcon.svg";
import { ReactComponent as InfoIcon } from "../assets/images/maintenanceInfoIcon.svg";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { EMaintenanceStatus } from "../models/EMaintenanceStatus";
import { fetchConfiguration, IConfigurationStateType } from "../reducers/configurationSlice";

const BannerContainer = styled.div`
    background: linear-gradient(180deg, ${Colors.blue.brandingDark} 0%, rgba(29, 56, 139, 0.8) 99.81%);
    min-height: 54px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: ${Colors.teal.tail};
    display: flex;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 20px;
    gap: 10px;
    width: 100%;
`;

const TextContainer = styled.div`
    max-width: 1340px;
`;

const IconContainer = styled.span`
    margin-right: 10px;
    svg {
        margin-bottom: 3px;
    }
`;

const MaintenanceBanner = () => {
    const { releaseNotification } = useAppSelector<IConfigurationStateType>((state) => state.configurationSlice);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (releaseNotification !== EMaintenanceStatus.None) {
            // if ongoing call endpoint each 5 minutes else every 30 minutes
            const requestInterval = releaseNotification === EMaintenanceStatus.Ongoing ? 60000 * 5 : 60000 * 30;
            const interval = setInterval(() => {
                dispatch(fetchConfiguration());
            }, requestInterval);
            return () => clearInterval(interval);
        }
        return;
    }, [releaseNotification, dispatch]);

    return releaseNotification !== EMaintenanceStatus.None ? (
        <BannerContainer>
            <TextContainer>
                <IconContainer>{releaseNotification === EMaintenanceStatus.Ongoing ? <ExclamationIcon /> : <InfoIcon />}</IconContainer>
                <span><FormattedMessage id={"releaseUpdateMessage." + releaseNotification} /></span>
            </TextContainer>
        </BannerContainer>
    ) : <></>;
};

export default MaintenanceBanner;