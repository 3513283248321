import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import { ButtonContainer, PageHeader, StyledButton, StyledViewContainer } from "../components/common/View";
import BackHardButton from "../components/common/controls/BackHardButton";
import { IRadioEntry } from "../components/common/controls/RadioButtons";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import RoleInformationModal from "../components/themed/users/common/RoleInformationModal";
import UserExternalEditForm, { Context, Fields } from "../components/themed/users/common/UserExternalEditForm";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import { CustomerAdUserManagementFlow } from "../models/ECustomerAdUserManagementFlow";
import { ExternalUserType } from "../models/EExternalUserType";
import ErrorCodes from "../models/ErrorCodes";
import ExternalUser from "../models/ExternalUser";
import { Role, Roles } from "../models/Role";
import { IContextStateType, setFormDirty } from "../reducers/contextSlice";
import { IUserStateType, createExternalUser } from "../reducers/userSlice";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import { emailRegex, passwordRegex, phoneRegex } from "../utils/Regex";
import GenericConfirmationForm from "../views/GenericConfirmationForm";

const setAvailableRoles = (context: IContextStateType): IRadioEntry[] => {
    switch (context.role) {
        case Roles.NHManager:
            return [
                { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager) },
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse) },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver) },
                { value: Roles.Support, label: localizer("user.role." + Roles.Support) },
            ];
        case Roles.Nurse:
            return [
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse) },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver) },
            ];
        case Roles.CountryAdmin:
        case Roles.Support:
            return [
                { value: Roles.NHManager, label: localizer("user.role." + Roles.NHManager) },
                { value: Roles.Nurse, label: localizer("user.role." + Roles.Nurse) },
                { value: Roles.Caregiver, label: localizer("user.role." + Roles.Caregiver) },
            ];
    }
    return [];
};

const setRole = (context: IContextStateType): Role => {
    switch (context.role) {
        case Roles.Nurse:
        case Roles.NHManager:
            return Roles.Nurse;
        case Roles.CountryAdmin:
            return Roles.NHManager;
    }
    return Roles.Nurse;
};

const AddExternalUserView = () => {
    const context = useAppSelector<IContextStateType>((state) => state.contextSlice);
    const { users } = useAppSelector<IUserStateType>((state) => state.userSlice);
    const { allNHResources } = context;
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [userEntity, setUserEntity] = useState<ExternalUser>(new ExternalUser({ role: setRole(context) }));
    const [radioRoleValues] = useState<IRadioEntry[]>(setAvailableRoles(context));
    const [validationErrors, setErrors] = useState<any[]>([]);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [rolesToShowInRoleInfoPopup, setRolesToShowInRoleInfoPopup] = useState<Role[]>([])
    const [initialState] = useState<ExternalUser | undefined>(cloneDeep(userEntity));
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const leaveFormTitleId="general.leaveTitle";
    const leaveFormMessageId = "general.leaveMessage";
    const leaveFormConfirmTextId = "constants.leave";

    const navigate = useNavigate();
    const dispatch = useAppDispatch();


    const {
        email,
        username,
        mobileNumber,
        password,
        language,
        assignedResourceIds,
        externalUserType,
        role: selectedRole,
        supportUserNursingHomes
    } = userEntity

    const handleChange = useCallback((name: string, value: any, resourceId?: number) => {
        const user = cloneDeep(userEntity);
        switch (name) {
            case Fields.Email:
                user.email = value;
                break;
            case Fields.Mobile:
                user.mobileNumber = value;
                break;
            case Fields.Username:
                user.username = value;
                break;
            case Fields.Password:
                user.password = value;
                break;
            case Fields.Language:
                user.language = value;
                break;
            case Fields.LoginMethod:
                user.externalUserType = value;
                break;
            case Fields.Role:
                user.role = value;
                if (value === Roles.Caregiver) {
                    if (user.assignedResourceIds.length > 1) {
                        user.assignedResourceIds = [user.assignedResourceIds[0]];
                        user.assignedWardsIds = allNHResources.find(nh => nh.id === user.assignedResourceIds[0])?.children.map(ward => ward.id) || [];
                    }
                    if (allNHResources.some(nh => nh.id === user.assignedResourceIds[0] && nh.enforceEmailForCaregiver)) {
                        user.externalUserType = ExternalUserType.Email;
                    }
                    if (user.supportUserNursingHomes) {
                        user.supportUserNursingHomes = undefined;
                    }
                } else if (value === Roles.Support) {
                    user.supportUserNursingHomes = user.assignedResourceIds.map(id => ({
                        nursingHomeId: id,
                        expirationDateTime: moment().add(1, "days").toISOString()
                    }
                    ));
                    user.externalUserType = ExternalUserType.Email;
                } else {
                    user.supportUserNursingHomes = undefined;
                }
                break;
            case Fields.AssignedResources:
                user.assignedResourceIds = value;
                if (user.role === Roles.Caregiver) {
                    user.assignedWardsIds = allNHResources.find(nh => nh.id === user.assignedResourceIds[0])?.children.map(ward => ward.id) || [];
                }
                if (user.role === Roles.Caregiver && allNHResources.some(nh => nh.id === user.assignedResourceIds[0] && nh.enforceEmailForCaregiver)) {
                    user.externalUserType = ExternalUserType.Email;
                }
                if (user.role === Roles.Support) {
                    if (user.supportUserNursingHomes && user.supportUserNursingHomes.length > 0) {
                        const updatedList: { nursingHomeId: number; expirationDateTime: string; }[] | undefined = []
                        user.supportUserNursingHomes.forEach(nh => {
                            if (value.includes(nh.nursingHomeId)) {
                                updatedList.push({
                                    nursingHomeId: nh.nursingHomeId,
                                    expirationDateTime: nh.expirationDateTime
                                })
                            }
                        })
                        value.forEach((id: number) => {
                            if (user.supportUserNursingHomes && !user.supportUserNursingHomes.some(obj => obj.nursingHomeId === id)) {
                                updatedList.push({
                                    nursingHomeId: id,
                                    expirationDateTime: moment().add(1, "days").toISOString()
                                })
                            }
                        })
                        user.supportUserNursingHomes = updatedList;
                    } else {
                        user.supportUserNursingHomes = value.map((id: number) => ({
                            nursingHomeId: id,
                            expirationDateTime: moment().add(1, "days").toISOString()
                        }));
                    }
                }
                const assignedNhs = allNHResources.filter(nh => user.assignedResourceIds.includes(nh.id));
                const enableCustomerAdLogin = assignedNhs.length > 0 && assignedNhs.every(nh => nh && nh.customerAdUserManagementFlow === CustomerAdUserManagementFlow.CIUserManagement);
                if (user.externalUserType === ExternalUserType.Federated && !enableCustomerAdLogin) {
                    user.externalUserType = ExternalUserType.Email;
                }
                break;
            case Fields.ExpirationDates:
                if (user.supportUserNursingHomes && user.supportUserNursingHomes?.length > 0) {
                    const updatedList = user.supportUserNursingHomes.map(nh => {
                        if (nh.nursingHomeId === resourceId) {
                            nh.expirationDateTime = moment(value).toISOString();
                        }
                        return nh;
                    })
                    user.supportUserNursingHomes = updatedList;
                } else {
                    const updatedList = user.assignedResourceIds.filter(id => id === resourceId).map(id => ({
                        nursingHomeId: id,
                        expirationDateTime: moment(value).toISOString()
                    }));
                    user.supportUserNursingHomes = updatedList;
                }
                break;
            case Fields.AssignedWards:
                user.assignedWardsIds = value;
                break;
            default:
                break;
        }
        if (!isFormDirty && !isEqual(initialState, user)) {
            dispatch(setFormDirty(true, leaveFormTitleId, leaveFormMessageId, leaveFormConfirmTextId));
        } else if (isFormDirty && isEqual(initialState, user)) {
            dispatch(setFormDirty(false));
        }
        setUserEntity(user);
    }, [allNHResources, userEntity]);

    useEffect(() => {
        switch (context.role) {
            case Roles.Nurse:
                setRolesToShowInRoleInfoPopup([Roles.Caregiver, Roles.Nurse]);
                break;
            case Roles.CountryAdmin:
                setRolesToShowInRoleInfoPopup([Roles.NHManager, Roles.Caregiver, Roles.Nurse]);
                break;
            case Roles.NHManager:
                setRolesToShowInRoleInfoPopup([Roles.NHManager, Roles.Caregiver, Roles.Nurse, Roles.Support]);
                break;
        }
    }, [context.role, context.userResourcesHierarchy]);

    const validate = (): Promise<boolean> => {
        const errors: any[] = [];
        if (selectedRole === Roles.Caregiver && externalUserType === ExternalUserType.Username) {
            if (!username) {
                errors.push(ErrorCodes.FaultyNameErrorEmpty);
            }
            if (!password || ((selectedRole === Roles.Caregiver && allNHResources.some(nh => nh.id === assignedResourceIds[0] && nh.isWeakAuthenticationAllowed)) ? password.length < 4 : !passwordRegex.test(password))) {
                errors.push(ErrorCodes.PasswordUnmeetCriteria);
            }
            if (users.some(user => user.name.toLocaleLowerCase() === username.toLocaleLowerCase())) {
                errors.push(ErrorCodes.UserAlreadyExists);
            }
        } else {
            if (!email) {
                errors.push(ErrorCodes.FaultyNameErrorEmpty);
            }

            if ((email && email.length > 0) && !emailRegex.test(email.trim().toLowerCase())) {
                errors.push(ErrorCodes.FaultyNameErrorEmail);
            }

            if (users.some(user => user.name.toLocaleLowerCase() === email.toLocaleLowerCase())) {
                errors.push(ErrorCodes.UserAlreadyExists);
            }
        }

        if (mobileNumber && mobileNumber.length > 0 && !phoneRegex.test(mobileNumber.trim().toLowerCase())) {
            errors.push({
                dependentErrorCode: ErrorCodes.FaultyMobileNumber,
                values: { mobileNumber },
            });
        }

        setErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        try {
            await submitExternalRole();
            navigate(routePaths.users);
            dispatch(setFormDirty(false));
            window.scrollTo(0, 0);
        } catch (error: any) {
            if (error?.status === 404) {
                setErrors([ErrorCodes.AccountDoesNotExistInAD]);
            } else if (error?.status === 409) {
                setErrors([ErrorCodes.UserAlreadyExists])
            }
        }
    };

    const submitExternalRole = () => {
        const user = externalUserType === ExternalUserType.Username ? username : email;
        const pass = externalUserType === ExternalUserType.Username ? password : undefined;
        const phoneNumber = externalUserType === ExternalUserType.Username ? undefined : mobileNumber;
        const careFacilities = assignedResourceIds;
        const wards = userEntity.assignedWardsIds;

        return dispatch(createExternalUser(user,
            selectedRole,
            careFacilities,
            pass,
            selectedRole === Roles.Support ? undefined : language,
            phoneNumber,
            externalUserType,
            supportUserNursingHomes,
            wards
        ));
    }

    const disableAddButton = () => {
        if (selectedRole === Roles.Caregiver) {
            return (externalUserType === ExternalUserType.Username ? !username || !password : !email) || !language;
        }
        if (selectedRole === Roles.Support) {
            return !email || assignedResourceIds.length < 1;
        }
        return !email || assignedResourceIds.length < 1 || !language;
    }

    const allApplicableNhResource = context.role === Roles.Support ? allNHResources.filter(nh => nh.isSupportUser) : allNHResources;
    return (
        <StyledViewContainer>
            <PageHeader>{localizer("users.addUser")}</PageHeader>
            <UserExternalEditForm
                formContext={Context.Add}
                user={userEntity}
                onSubmit={onSubmit}
                allNHResources={allApplicableNhResource}
                validationErrors={validationErrors}
                radioRoleValues={radioRoleValues}
                setShowInfoPopup={setShowInfoPopup}
                handleChange={handleChange}
            >
                <ButtonContainer>
                    <BackHardButton
                        backRoute={routePaths.users}
                        onClick={() => !isEqual(initialState, userEntity) ? setConfirmLeave(true) : navigate(routePaths.users)}
                    />
                    <StyledButton
                        variant={"primary"}
                        type={"submit"}
                        disabled={disableAddButton()}
                    >
                        {localizer("users.addButton.title")}
                    </StyledButton>
                </ButtonContainer>
            </UserExternalEditForm>
            <RoleInformationModal
                showInfoPopup={showInfoPopup}
                setShowInfoPopup={setShowInfoPopup}
                roles={rolesToShowInRoleInfoPopup}
            />
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={leaveFormMessageId}
                            confirmButton={leaveFormConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                            }}
                            onConfirm={() => {
                                navigate(routePaths.users);
                            }}
                        />
                    }
                    modalTitle={localizer(leaveFormTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </StyledViewContainer>
    );
};

export default withAITracking(reactPlugin, AddExternalUserView);
