import styled from "styled-components";
import Colors from "../assets/Colors";
import { ReactComponent as InfoIcon } from "../assets/images/info.svg";

export const InfoContainer = styled.div`
    background-color: ${Colors.grey.tenaLight};
    color: ${Colors.blue.brandingDark};
    font-weight: 500;
    padding: 16px;
    margin-top: 24px;
    border-radius: 8px;
    line-height: 20px;
`;

export const InfoHeader = styled.div`
    line-height: 24px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
`;

export const StyledInfoIcon = styled(InfoIcon)`
    height: 24px;
    width: 24px;
`;