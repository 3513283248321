import React, { SVGProps } from "react";

interface ITimesCircleIconProps {
    color: string;
}

const TimesCircleIcon: React.FunctionComponent<
    SVGProps<any> & ITimesCircleIconProps
> = (props) => {
    const { color } = props;
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill={color} />
            <rect
                x="6"
                y="16"
                width="14.1421"
                height="2.82842"
                rx="0.5"
                transform="rotate(-45 6 16)"
                fill="white"
            />
            <rect
                x="16"
                y="18.0001"
                width="14.1421"
                height="2.82842"
                rx="0.5"
                transform="rotate(-135 16 18.0001)"
                fill="white"
            />
        </svg>
    );
};

export default TimesCircleIcon;
