import React from "react";
import { FormattedMessage } from "react-intl";
import { getLocalizedCountry } from "../../../utils/Localizer";
import CountryNameFormatter from "../../common/table/formatters/CountryNameFormatter";
import MarketCodeFormatter from "../../common/table/formatters/MarketCodeFormatter";
import NumberFormatter from "../../common/table/formatters/NumberFormatter";
import Table, { headerJustifyOptions } from "../../common/table/Table";
import { ITableProps } from "../ITableProps";
import CountryActions from "./CountryActions";

const CountriesTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="countries.tableHeader.code" />,
                accessor: "countryName",
                Cell: CountryNameFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="countries.tableHeader.marketCode" />,
                accessor: "marketCode",
                Cell: MarketCodeFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="countries.tableHeader.admins" />,
                accessor: "amountOfAssignedUsers",
                Cell: NumberFormatter,
                headerAlignment: headerJustifyOptions.center,
                flexShrink: 3,
            },
            {
                Header: <FormattedMessage id="general.tableHeader.actions" />,
                headerAlignment: headerJustifyOptions.right,
                accessor: "id",
                Cell: CountryActions,
                disableSortBy: true,
                flexShrink: 3,
            }
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);
    const dataWithLocalizedCountryName: any[] = [];
    data.forEach(country =>  dataWithLocalizedCountryName.push( {...country, countryName: getLocalizedCountry(country.code) || country.code }));
    return (
        <Table
            qaId={"countries_table"}
            columns={columns}
            data={dataWithLocalizedCountryName}
            initialState={{
                sortBy: [
                    {
                        id: "countryName",
                        desc: false
                    }
                ]
            }}
        />
    );
};

export default React.memo(CountriesTable);
