import moment, { Moment } from "moment";
import { FormattedMessage } from "react-intl";
import ChangeEvent from "../../../models/ChangeEvent";
import { DateTimeUtils } from "../../../models/DateTimeUtils";
import { KpiType } from "../../../models/KpiType";
import { CareFacility } from "../../../models/CareFacility";
import { Ward } from "../../../models/Ward";
import { IChartData } from "../../../pages/StatisticsView";
import { getCaregiverResponseTargetMinutes } from "../../../reducers/nursingHomeHelpers";
import { Chart } from "../../common/chart/Chart";
import GraphHeader from "../../common/chart/GraphHeader";

interface IStatisticsGraphProps {
    averageChangeEventsLogs: ChangeEvent[];
    compareChangeEventsLogs: ChangeEvent[];
    selectedWard: Ward | undefined;
    selectedKpi: KpiType;
    kpiValue: number | null | undefined;
    compareKpiValue: number | null | undefined;
    startDate: string;
    endDate: string;
    nursingHome: CareFacility;
    startCompareDate: Moment | null;
    oldestWardMoment: Moment;
    selectedWardCreation?: Moment;
    setSelectedCompareStartDate: (value: Moment | null) => void;
    setSelectedCompareEndDate: (value: Moment | null) => void;
    getFormattedTooltipValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number) => any;
    getFormattedLegendValue: (kpiRawValue: number | null, selectedKpi: KpiType, percentage?: number | null) => any;
    getFormattedDifference: (selectedKpi: KpiType, difference: number, percentageDifference?: number) => any;
}

const getHeaderId = (kpi: KpiType) => {
    let id: string = "";
    switch (kpi) {
        case KpiType.ResponseTime:
            id = "statistics.graphHeader.responseTime";
            break;
        case KpiType.AllProductChanges:
            id = "statistics.graphHeader.allProductChanges";
            break;
        case KpiType.PromptedProductChanges:
            id = "statistics.graphHeader.promptedChanges";
            break;
        case KpiType.AverageProductChanges:
            id = "statistics.graphHeader.unpromptedChanges";
            break;
        case KpiType.AvgNightTimeMinutesBetweenChanges:
            id = "statistics.graphHeader.nighTime";
            break;
    }
    return id;
};

const getLegendText = () => {
    return <><FormattedMessage id="statistics.legend.average" />:</>;
};

const StatisticsGraph = (props: IStatisticsGraphProps) => {

    const valueSelector = props.selectedKpi === KpiType.ResponseTime ? "responseTimeMinutes" : "nightTimeMinutesBetweenChanges";
    const start = new Date(props.startDate);
    const end = new Date(props.endDate);
    const interval = DateTimeUtils.getCompareInterval(start, end);

    const generateChartData = (): IChartData[] => {
        const dayAveragesMap: Map<string, {
            all: number[],
            ward: number,
            allCompare?: number[],
            wardCompare?: number,
            compareDate?: string
        }> = new Map();

        const dayProductChangesCompareMap: Map<string, {
            all: number[],
            ward: number
        }> = new Map();

        const chartData: IChartData[] = [];

        const fromDate = moment(props.startDate);
        const currentDate = moment(props.endDate).subtract(1, "d");
        const fromCompareDate = props.startCompareDate ? moment(props.startCompareDate) : undefined;
        const currentCompareDate = fromCompareDate ? moment(fromCompareDate.clone().add(interval - 1, 'days')) : undefined;
        while (currentDate >= fromDate) {
            const compareDate = currentCompareDate ? currentCompareDate.format('YYYY-MM-DD') : undefined;
            dayAveragesMap.set(currentDate.format('YYYY-MM-DD'), { all: [], ward: 0, compareDate });
            currentDate.subtract(1, 'days');
            if (compareDate && currentCompareDate) {
                dayProductChangesCompareMap.set(compareDate, { all: [], ward: 0 });
                currentCompareDate.subtract(1, 'days');
            }
        }

        for (const event of props.averageChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayAveragesMap.has(date)) {
                const value = event[valueSelector];
                if (value) {
                    dayAveragesMap.get(date)?.all.push(value);
                }
            }
        }

        for (const event of props.compareChangeEventsLogs) {
            const date = moment(event.timeCreated).format('YYYY-MM-DD');
            if (dayProductChangesCompareMap.has(date)) {
                const value = event[valueSelector];
                if (value) {
                    dayProductChangesCompareMap.get(date)?.all.push(value);
                }
            }
        }

        for (const [key, value] of dayAveragesMap.entries()) {
            const valuesAboveZero = value.all.filter(v => v > 0);
            const allAvg = valuesAboveZero.length > 0 ? valuesAboveZero.reduce((a, b) => a + b) / valuesAboveZero.length : 0;
            let allCompareChanges;
            if (value.compareDate && dayProductChangesCompareMap.has(value.compareDate)) {
                const entry = dayProductChangesCompareMap.get(value.compareDate);
                if (entry) {
                    const valuesAboveZeroCompare = entry.all.filter(v => v > 0);
                    allCompareChanges = valuesAboveZeroCompare.length > 0 && entry.all.length > 0 ? entry.all.reduce((sum, current) => sum + current) / valuesAboveZeroCompare.length : 0;
                }
            }
            chartData.push({
                date: key,
                selectedWard: props.selectedKpi === KpiType.ResponseTime ? allAvg : allAvg / 60,
                compareDate: value.compareDate,
                selectedCompareWard: allCompareChanges && (props.selectedKpi === KpiType.ResponseTime ? allCompareChanges : allCompareChanges / 60)
            });
        }
        return chartData;
    };

    const getMaxYAxisValue = (): number => {
        const allPresentResponseTimes: ChangeEvent[] = props.averageChangeEventsLogs.filter((changeEvent: ChangeEvent) =>
            props.selectedKpi === KpiType.ResponseTime ?
                !!changeEvent.responseTimeMinutes :
                !!changeEvent.nightTimeMinutesBetweenChanges);
        const allResponseTimes: number[] = allPresentResponseTimes.map((changeEvent: ChangeEvent) => (
            props.selectedKpi === KpiType.ResponseTime ?
                changeEvent.responseTimeMinutes :
                changeEvent.nightTimeMinutesBetweenChanges) as number);
        const allComparePresentResponseTimes: ChangeEvent[] = props.compareChangeEventsLogs.filter((changeEvent: ChangeEvent) =>
            props.selectedKpi === KpiType.ResponseTime ?
                !!changeEvent.responseTimeMinutes :
                !!changeEvent.nightTimeMinutesBetweenChanges);
        const allCompareResponseTimes: number[] = allComparePresentResponseTimes.map((changeEvent: ChangeEvent) => (
            props.selectedKpi === KpiType.ResponseTime ?
                changeEvent.responseTimeMinutes :
                changeEvent.nightTimeMinutesBetweenChanges) as number);
        const maxAggregatedResponseTime = Math.max(...allResponseTimes, ...allCompareResponseTimes);
        return Math.max(Math.ceil((props.selectedKpi === KpiType.ResponseTime ? maxAggregatedResponseTime : maxAggregatedResponseTime / 60) * 1.2), 5);
    };

    const caregiverResponseTargetMinutes = getCaregiverResponseTargetMinutes(props.nursingHome);
    const chartData = generateChartData();
    const isDataPresent = chartData.filter(data => data.selectedWard !== 0).length > 0;
    return (
        <div>
            <GraphHeader
                disabledCompare={false}
                selectedKpi={props.selectedKpi}
                isDataPresent={isDataPresent}
                daysInterval={interval}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate)}
                startRangeDate={props.selectedWard ? props.selectedWardCreation! : props.oldestWardMoment}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate).format("DD MMMM YYYY") : null}
                getHeaderId={getHeaderId}
                includeToday={false}
            />
            <Chart
                dataKey={"selectedWard"}
                compareDataKey={"selectedCompareWard"}
                startDate={moment(props.startDate)}
                endDate={moment(props.endDate).subtract(1)}
                compareStartDate={props.startCompareDate ? moment(props.startCompareDate) : null}
                compareEndDate={moment(props.startCompareDate).add(interval - 1, 'days')}
                chartData={chartData}
                maxYAxisValue={getMaxYAxisValue()}
                targetKpiValue={caregiverResponseTargetMinutes}
                selectedKpiValue={props.kpiValue !== undefined ? props.kpiValue : null}
                compareKpiValue={props.compareKpiValue !== undefined ? props.compareKpiValue : null}
                selectedKpi={props.selectedKpi}
                interval={interval}
                getFormattedTooltipValue={props.getFormattedTooltipValue}
                getFormattedLegendValue={props.getFormattedLegendValue}
                getFormattedDifference={props.getFormattedDifference}
                getLegendText={getLegendText}
            />
        </div>
    );
};

export default StatisticsGraph;
