import cloneDeep from "lodash/cloneDeep";
import { ChangeThreshold, ChangeThresholdShort } from "../models/ChangeThreshold";
import ErrorCodes from "../models/ErrorCodes";
import { Resident } from "../models/Resident";
import { ResidentEventType } from "../models/ResidentEventType";
import { CRC8 } from "../utils/CRC8";
import { localizer } from "../utils/Localizer";

export const getDeviceErrorsCount = (residents: Resident[]): number => (
    residents.filter(resident => resident.bedStatus === ResidentEventType.Error).length
);

export const updateState = (resident: Resident | any, json: any): Resident => {
    const updatedResident = cloneDeep(resident);
    updatedResident.wardId = json.wardId ? String(json.wardId) : '';
    updatedResident.isActive = json.isActive;
    updatedResident.description = json.description || '';
    updatedResident.bedStatus = json.bedStatus || 'Inactive';
    updatedResident.statusUpdatedTime = json.statusUpdatedTime;
    updatedResident.transmitterError = json.transmitterError;
    updatedResident.isBatteryFlat = json.isBatteryFlat;
    updatedResident.monitoringTransmitter = json.monitoringTransmitter;
    updatedResident.responseTargetOverdue = json.responseTargetOverdue;

    return updatedResident;
};

export const getChangeThresholds = (): any => {
    return Object.entries(ChangeThreshold).map(([label, value]) => {
        return { value, label: localizer(label) };
    });
};

export const getChangeThresholdsShort = (): any => {
    return Object.entries(ChangeThresholdShort).map(([label, value]) => {
        return { value, label: localizer(label) };
    });
};

export const getActiveResidentCount = (residents: Resident[]): number => (
    residents.filter(resident => resident.isActive && resident.monitoringTransmitter).length
);

const isTransmitterCodeValid = (transmitterCode: string): boolean => {
    const code = transmitterCode.split(' ').join('').substring(0, 12);
    return CRC8.computeChecksum(code) === transmitterCode.substring(transmitterCode.length - 3);
};

const isTransmitterControlDigitIsCorrect = (transmitterCode: string): boolean => {
    const controlDigit = Number(removeWhiteSpaces(transmitterCode)[4]);
    return controlDigit !== 2;
};

const validateTransmitterCode = (resident: Resident): ErrorCodes[] => {
    const transmitterErrors = [];
    if (resident.dayTimeSetup && resident.separateTransmitter) {
        if (resident.dayTransmitter.transmitterProductCode && resident.dayTransmitter.transmitterProductCode.trim().length > 0) {
            if (removeWhiteSpaces(resident.dayTransmitter.transmitterProductCode).length !== 15) {
                transmitterErrors.push(ErrorCodes.DayTransmitterCodeLength);
            }

            if (!isTransmitterCodeValid(resident.dayTransmitter.transmitterProductCode)) {
                transmitterErrors.push(ErrorCodes.InvalidDayTransmitterCode);
            } else if (!isTransmitterControlDigitIsCorrect(resident.dayTransmitter.transmitterProductCode)) {
                transmitterErrors.push(ErrorCodes.InvalidDayControlDigit);
            }
        }
    }

    if (resident.dayTimeSetup
        && resident.nightTimeSetup
        && resident.separateTransmitter
        && resident.nightTransmitter.transmitterProductCode.length > 0
        && removeWhiteSpaces(resident.nightTransmitter.transmitterProductCode) === removeWhiteSpaces(resident.dayTransmitter.transmitterProductCode)) {
        transmitterErrors.push(ErrorCodes.TransmitterIdAlreadyUsedInNightSetup);
    }

    if (resident.nightTimeSetup) {
        if (resident.nightTransmitter.transmitterProductCode && resident.nightTransmitter.transmitterProductCode.trim().length > 0) {
            if (removeWhiteSpaces(resident.nightTransmitter.transmitterProductCode).length !== 15) {
                transmitterErrors.push(ErrorCodes.NightTransmitterCodeLength);
            }

            // if - if else construction because we should show only one of that 2 errors in the same time
            if (!isTransmitterCodeValid(resident.nightTransmitter.transmitterProductCode)) {
                transmitterErrors.push(ErrorCodes.InvalidNightTransmitterCode);
            } else if (!isTransmitterControlDigitIsCorrect(resident.nightTransmitter.transmitterProductCode)) {
                transmitterErrors.push(ErrorCodes.InvalidNightControlDigit);
            }
        }
    }
    return transmitterErrors;
};

export const validateResident = (resident: Resident): ErrorCodes[] => {
    const errors = validateTransmitterCode(resident);

    if (!resident.name.trim() || resident.name.trim().length < 1) {
        errors.push(ErrorCodes.EmptyBedNumberError);
    }
    if (!resident.wardId || resident.wardId.length < 1) {
        errors.push(ErrorCodes.EmptyWardId);
    }

    if (resident.dayTimeSetup) {
        if (!resident.dayTransmitter.productFamily || resident.dayTransmitter.productFamily.length < 1) {
            errors.push(ErrorCodes.EmptyProductNameDay);
        }
        if (!resident.dayTransmitter.productName || resident.dayTransmitter.productName.length < 1) {
            errors.push(ErrorCodes.EmptyProductTypeDay);
        }
        if (!resident.dayTransmitter.productSize || resident.dayTransmitter.productSize.length < 1) {
            errors.push(ErrorCodes.EmptyProductSizeDay);
        }
        if (!resident.dayTransmitter.changeThreshold) {
            errors.push(ErrorCodes.EmptyChangeThresholdDay);
        }
    }
    if (resident.nightTimeSetup) {
        if (!resident.nightTransmitter.productFamily || resident.nightTransmitter.productFamily.length < 1) {
            errors.push(ErrorCodes.EmptyProductNameNight);
        }
        if (!resident.nightTransmitter.productName || resident.nightTransmitter.productName.length < 1) {
            errors.push(ErrorCodes.EmptyProductTypeNight);
        }
        if (!resident.nightTransmitter.productSize || resident.nightTransmitter.productSize.length < 1) {
            errors.push(ErrorCodes.EmptyProductSizeNight);
        }
        if (!resident.nightTransmitter.changeThreshold) {
            errors.push(ErrorCodes.EmptyChangeThresholdNight);
        }
    }

    return errors;
};

/**
 * Removes all white spaces including new lines.
 *
 * @export
 * @param value - string value that will have all whitespaces and newlines removed
 * @returns string with no whitespaces and new lines
 */
export const removeWhiteSpaces = (value: string) => (value.replace(/\s+/g, ''));
