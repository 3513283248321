import styled from "styled-components";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { Ward } from "../../../../models/Ward";
import { StyledLeft } from "../../../common/table/formatters/PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";

const StyledTextOverflowWrapper = styled(TextOverflowWrapper) <any>`
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
`;

const WardName = (props: any): React.ReactElement => {
    const wards = useAppSelector<Ward[]>(state => state.wardSlice.wards);
    const { value } = props;
    const { hideTooltipOnProd } = props.column;

    const wardName = wards.find(ward => ward.id === value)?.name || "";

    return (
        <StyledLeft>
            <StyledTextOverflowWrapper tooltip={wardName} hideTooltipOnProd={hideTooltipOnProd}>
                {wardName}
            </StyledTextOverflowWrapper>
        </StyledLeft>
    );
};

export default WardName;
