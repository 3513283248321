import { createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { RestClient } from '../data/RestClient';
import { Country } from '../models/Country';
import User from '../models/ExternalUser';

export interface ICountryStateType {
    countries: Country[];
}

const initialState: ICountryStateType = {
    countries: [],
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = [...action.payload];
        },

        clearCountrySlice: () => initialState,
    },
});

export const {
    setCountries, clearCountrySlice
} = countrySlice.actions;

export const addUpdateCountriesInStore = (requestBodies: Country[]) => (dispatch: any, getState: any) => {
    const requestBodyCountries = requestBodies.map(requestBody => new Country(requestBody));

    const countries = getState().countrySlice.countries as Country[];

    let updatedCountries: Country[] = [...countries];
    requestBodyCountries.forEach(requestBodyCountry => {
        if (countries.find(country => requestBodyCountry.id?.toString() === country.id)) {
            updatedCountries = updatedCountries.map((country) => {
                return requestBodyCountry.id === country.id ? requestBodyCountry : country;
            });
        } else {
            updatedCountries.push(requestBodyCountry);
        }
    });
    dispatch(setCountries(updatedCountries));
};

export const updateCountry = (requestBody: Country) => (dispatch: any, getState: any): Promise<Country> => {
    return new Promise(async (resolve, reject) => {
        try {
            const newCountry = await RestClient.updateCountry(requestBody);
            const updatedCountry = new Country(newCountry);

            const state = getState().countrySlice as ICountryStateType;
            const updatedCountries = state?.countries?.map((country) => {
                return country.id === updatedCountry.id ? updatedCountry : country;
            }) || [];

            dispatch(setCountries(updatedCountries));

            resolve(updatedCountry);
        } catch (exception) {
            reject(exception);
        }
    });
};

export const deleteCountryInStore = (id: string) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            const state = getState().countrySlice as ICountryStateType;

            const updatedCountries = state.countries.filter(country => country.id !== id);
            dispatch(setCountries(updatedCountries));
            resolve(true);
        } catch (exception) {
            reject();
        }
    });
};

export const deleteCountry = (id: string) => (dispatch: any, getState: any): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
        try {
            await RestClient.deleteCountry(id);

            const state = getState().countrySlice as ICountryStateType;
            const updatedCountries = state.countries.filter(country => country.id !== id);

            dispatch(setCountries(updatedCountries));

            resolve(true);
        } catch (exception) {
            reject(exception);
        }
    });
};

export const createCountry = (requestBody: any) => (): Promise<Country> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await RestClient.postCountry(requestBody);
            const newCountry = new Country(response);

            resolve(newCountry);
        }
        catch (error: any) {
            reject(error);
        }
    });
};

export const fetchCountries = () => async (dispatch: any) => {
    try {
        const countriesResponse = await RestClient.getCountries();
        const countries = countriesResponse.map((countryResponse: any) => new Country(countryResponse));
        dispatch(setCountries(countries));
    } catch (error) {        
        console.error(error);
    }
};

export const removeUserFromCountry = (nursingHomeId: string, userId: string, getState: any) => (dispatch: any) => {
    const state = getState().countrySlice as ICountryStateType;
    if (state.countries) {
        const countries = [...state.countries];
        const nursingHome: Country | undefined = countries.find(
            (nursingHome) => nursingHome.id === nursingHomeId
        );
        if (nursingHome) {
            const updatedUserList = nursingHome.assignedUsersIds.filter(assignedUserId => assignedUserId !== parseInt(userId, 10));
            const updatedCountry = {
                ...nursingHome,
                assignedUsersIds: updatedUserList,
                amountOfAssignedUsers: updatedUserList?.length,
            };
            const updatedCountrys = [...countries.filter(nh => nh.id !== nursingHomeId), updatedCountry];
            dispatch(setCountries(updatedCountrys));
        }
    }
};

export const addUserToCountry = (countryId: string, users: User[], getState: any) => (dispatch: any) => {
    const state = getState().countrySlice as ICountryStateType;
    if (state.countries) {
        const countries = [...state.countries];
        const country: Country | undefined = countries.find(
            (country) => country.id === countryId
        );
        if (country) {
            const updatedAssignedUsersId = _.union(country.assignedUsersIds, users.map(u => parseInt(u.id)));
            const updatedCountry = {
                ...country,
                assignedUsersIds: updatedAssignedUsersId,
                amountOfAssignedUsers: updatedAssignedUsersId.length
            };
            if (updatedCountry.amountOfAssignedUsers !== country.amountOfAssignedUsers && updatedCountry.assignedUsersIds !== country.assignedUsersIds) {
                const updatedCountrys = [...countries.filter(c => c.id !== countryId), updatedCountry];
                dispatch(setCountries(updatedCountrys));
            }
        }
    }
};

export default countrySlice.reducer;

