import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const StyledTextContainer = styled.div`
    white-space: pre-line;
`;

const TermsOfUseForm = (props: any) => {
    const onAcceptTermsOfUse = async (event: any) => {
        event.preventDefault();
        if (props?.hideModal) {
            props.hideModal();
        }
    };

    return (
        <>
            <Modal.Body>
                <StyledTextContainer>
                    <p>
                        <FormattedMessage id={"termsOfUse.link"} />
                    </p>
                </StyledTextContainer>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-qa="ok_button"
                    variant={"primary"}
                    type="submit"
                    className="confirm-button"
                    onClick={onAcceptTermsOfUse}
                >
                    <FormattedMessage id={"termsOfUse.acceptButton"} />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default TermsOfUseForm;