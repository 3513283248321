import React, {Component, ReactNode} from 'react';
import './FormGroupTemplate.scss';
import Form from "react-bootstrap/Form";
import cx from "classnames";


interface IFormGroupTemplateProps {
    classInputForm?: string;
    controlId: string;
    label: JSX.Element | Element |  string;
    children: ReactNode;
    hasError?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default class FormGroupTemplate extends Component<IFormGroupTemplateProps, {}> {
    render(): React.ReactElement {
        return (
            <Form.Group
                controlId={this.props.controlId}
                className={cx(this.props.classInputForm, {
                    "form-group-error": this.props.hasError
                })}
            >
                <Form.Label className="form-group-label"><>{this.props.label}</></Form.Label>
                {this.props.children}
            </Form.Group>
        );
    }
}
