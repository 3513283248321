import React from "react";
import { ITableProps } from "../../ITableProps";
import { FormattedMessage } from "react-intl";
import Table, { headerJustifyOptions } from "../../../common/table/Table";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import LoginStatusCodeFormatter from "./formatters/LoginStatusCodeFormatter"

const AuditsLoginsTable = (props: ITableProps) => {
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id="audits.tableHeader.time" />,
                accessor: "time",
                Cell: DateFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.user" />,
                accessor: "performedBy",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.category" />,
                accessor: "category",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: false,
                maxWidth: 140
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.activity" />,
                accessor: "activity",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: false,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.ipAddress" />,
                accessor: "hostAddress",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.deviceId" />,
                accessor: "caregiverDeviceId",
                Cell: StringFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
            },
            {
                Header: <FormattedMessage id="audits.tableHeader.status" />,
                accessor: "statusCode",
                Cell: LoginStatusCodeFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 1,
            },
        ],
        []
    );
    const data = React.useMemo(() => props.data, [props.data]);

    return (
        <Table
            qaId={"audit_logins_table"}
            columns={columns}
            data={data}
            initialState={{
                sortBy: [
                    {
                        id: "time",
                        desc: true
                    }
                ]
            }}
        />
    );
};

export default React.memo(AuditsLoginsTable);
