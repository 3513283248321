import styled from "styled-components";
import Colors from "../assets/Colors";

export const CollapsibleContainer = styled.div<any>`
    background: ${Colors.grey.tenaLight};
    border-radius: 12px;
    padding: 24px;
    height: 100%;
    max-width: 1440px;
`;

export const Description = styled.span`
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${Colors.grey.medium}
`;

export const DropdownContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const ExpanderContainer = styled.div`
    display: flex;
    margin: auto;
    justify-content: center;
`;

export const NoDataInfoContainer = styled.div`
    background-color: ${Colors.white.clearWhite};
    height: 76px;
    text-align: center;
    line-height: 76px;
`;

export const TabButton = styled.div<any>`
    :hover {
        cursor: pointer;
    }
    height: 24px;
    ${props => props.active ? `
        font-weight: bold;
        border-bottom: 2px solid ${Colors.blue.brandingDark};
        padding-bottom: 6px
    ` : ""}
`;

export const TabButtonContainer = styled.div`
    display: flex;
    gap: 24px;
    height: 55px;
    color: ${Colors.blue.brandingDark};
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: white;
    width: 100%;
    align-items: center;
`;

export const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const TableContainer = styled.div<any>`
    height: ${props => props.expandStatus ? props.maxHeight + "px" : props.dataLength === 0 ? "150px" : "230px"};
    transition: height 0.3s linear;  
    ${props => props.dataLength > 3 ?
        `mask-image:${!props.expandStatus ? ` -webkit-gradient(linear, left 60%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.1)));}` : "none"}` : ""};
`;

export const Title = styled.span`
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    color: ${Colors.black}
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 64px;
`;
