import { FormattedMessage } from "react-intl";
import { auditTypeKey } from "../AuditKeys";
import { IAuditInformationBlockProps } from "../IAuditInformationBlockProps";
import DropdownButtonNew from "../../../common/controls/DropdownButtonNew";
import { StyledTitle } from "../../../common/informationBlock/styled";

const AuditTypeInformationBlock = (props: IAuditInformationBlockProps) => (
    <>
        <StyledTitle><FormattedMessage id={auditTypeKey} /></StyledTitle>
        <DropdownButtonNew
            qaId="audit_type_dropdown"
            id={auditTypeKey}
            name={auditTypeKey}
            handleChange={(_name, value) => props.handleChange(value, auditTypeKey)}
            options={props.data || []}
            selectedValue={props.filters[auditTypeKey]}
            maxHeight={500}
        />
    </>
);

export default AuditTypeInformationBlock;
