import styled from "styled-components";
import BasicModal from "../../../common/modal/BasicModal"
import { EModalSize } from "../../../common/modal/ModalWrapper"
import { localizer } from "../../../../utils/Localizer";
import { StyledButton } from "../../../common/View";

const SettingTitle = styled.div`
    font-weight: bold;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const GeneralInfo = styled.div`
  font-style: italic;
`;

interface ISecuritySettingsInformationModalProps {
    showInfoPopup: boolean;
    setShowInfoPopup: (show: boolean) => void;
}

const SecuritySettingsInformationModal = (props: ISecuritySettingsInformationModalProps) => {
    const { showInfoPopup, setShowInfoPopup } = props
    const content = (
        <>
            <GeneralInfo>{localizer("setup.securitySettings.infoPopup.generalInfo")}</GeneralInfo>
            <br />
            <SettingTitle>{localizer("setup.securitySettings.infoPopup.enforceMFA.label")}</SettingTitle>
            <div>{localizer("setup.securitySettings.infoPopup.enforceMFA.value")}</div>
            <br />
            <SettingTitle>{localizer("setup.securitySettings.infoPopup.enforceStrongPassword.label")}</SettingTitle>
            <div>{localizer("setup.securitySettings.infoPopup.enforceStrongPassword.value")}</div>
            <br />
            <SettingTitle>{localizer("setup.securitySettings.infoPopup.enforceEmail.label")}</SettingTitle>
            <div>{localizer("setup.securitySettings.infoPopup.enforceEmail.value")}</div>
            <br />
        </>
    )

    return <BasicModal
        width={EModalSize.S}
        handleOnHide={() => setShowInfoPopup(false)}
        show={showInfoPopup}
        title={localizer("setup.securitySettings")}>
        {content}
        <ButtonContainer>
            <StyledButton
                variant={"primary"}
                type={"submit"}
                onClick={() => setShowInfoPopup(false)}>
                {localizer("setup.securitySettings.infoPopup.ok")}
            </StyledButton>
        </ButtonContainer>
    </BasicModal>
}

export default SecuritySettingsInformationModal;
