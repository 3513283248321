export const LocalStorageKeys = {
    ResourceContext: "context",

    SelectedUsersNursingHomeId: "selectedUsersNursingHomeId",
    SelectedUsersAdministratorsOnly: "selectedUsersAdministratorsOnly",

    SelectedResidentsWardId: "selectedResidentsWardId",
    ResidentListFilterValue: "residentListFilterValue",

    ResidentStatisticsDateFilter: "residentStatisticsDateFilter",
    ResidentStatisticsStartDate: "residentStatisticsStartDate",
    ResidentStatisticsEndDate: "residentStatisticsEndDate",
    ResidentStatisticsCompareStartDate: "residentStatisticsCompareStartDate",
    ResidentStatisticsCompareEndDate: "residentStatisticsCompareEndDate",
    ResidentStatisticsTimeFilter: "residentStatisticsTimeFilter",
    ResidentHistoryDateFilter: "residentHistoryDateFilter",
    ResidentHistoryTimeFilter: "residentHistoryTimeFilter",

    LoginContext: "loginContext",
    AccessToken: "accessToken",
    DomainHint: "domainHint",
}

export const cleanupLocalStorage = () => {
    localStorage.clear();
}