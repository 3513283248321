import { StyledLeft } from "./PositionContainers";
import TextOverflowWrapper from "../../../wrappers/TextOverflowWrapper";
import { DateTimeUtils } from "../../../../models/DateTimeUtils";
import { convertTimeToString } from "../../../../utils/ConvertMinutesToHours";
import styled from "styled-components";

const StyledContainer = styled(StyledLeft)<any>`
    align-items: ${(props) => props.alignItems ? props.alignItems : ''};
`;

const CellFormatter = (props: any) => {
    const { value } = props.data;
    const { hideTooltipOnProd, dateConverter } = props.data.column;

    const formattedTooltip = dateConverter
        ? value
        : DateTimeUtils.formatWithMiliseconds(value);

    const formattedDate = dateConverter
        ? convertTimeToString(dateConverter(value))
        : DateTimeUtils.formatUsingBrowserLocale(value);
    
    return (
        <StyledContainer alignItems={props.data.column.cellAlignItems}>
            <TextOverflowWrapper tooltip={formattedTooltip} hideTooltipOnProd={hideTooltipOnProd}>
                {formattedDate}
            </TextOverflowWrapper>
        </StyledContainer>
    );
};

const DateFormatter = (data: any) => <CellFormatter data={data} />;
export default DateFormatter;
