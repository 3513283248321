import moment, { Moment } from "moment";
import styled from "styled-components";
import { KpiType } from "../../../models/KpiType";
import { IStatisticValues } from "../../../models/StatisticValues";
import "../../../styles/main.scss";
import { localizer } from "../../../utils/Localizer";
import { getTrendIcon, getTrendValue } from "../../../utils/StatisticsHelpers";
import InformationBlock from "../../../views/InformationBlock/InformationBlock";
import DropdownDateTimeDaysInThePastFromNowRangeButton from "../../common/controls/dateTime/DropdownDateTimeDaysInThePastFromNowRangeButton";
import { PickerType } from "../../common/controls/dateTime/helpers/PickerType";
import TimeRangePickerDropdown from "../../common/controls/dateTime/TimeRangePickerDropdown";
import DropdownButtonNew from "../../common/controls/DropdownButtonNew";
import NightTimeBlock from "../../common/informationBlock/NightTimeBlock";
import NumberInformationBlock from "../../common/informationBlock/NumberInformationBlock";
import PromptedChangesBlock from "../../common/informationBlock/PromptedChangesBlock";
import ResponseTimeBlock from "../../common/informationBlock/ResponseTimeBlock";
import { ClickableStyledInformationBlockWrapper, EBlockSize, IconContainer, StyledTrendContainer, StyledValueContainer } from "../../common/informationBlock/styled";
import {
    StyledBoldSpan,
    StyledInformationBlocks,
    StyledSpan, StyledViewContainer
} from "../../common/View";
import "./ChangeInformationBlock.scss";

const StyledDropdownsContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 16px 8px;
    text-align: left;
`;


const parentClassName = "no-border-block";
const childClassName = "general-info-child";

interface IChangeInformationBlocksProps {
    selectedDateFilter: number;
    selectedStartDate: Moment;
    selectedEndDate: Moment;
    selectedStartTime: Moment | null;
    selectedEndTime: Moment | null;
    ward: any;
    allWards: any[];
    dateFilterOptions: any[];
    timeFilterOptions: any[];
    dateFilterChanged: (value: any) => void;
    startDateChanged: (value: Moment | null) => void;
    endDateChanged: (value: Moment | null) => void;
    startTimeChanged: (value: Moment | null) => void;
    endTimeChanged: (value: Moment | null) => void;
    timeChanged: (value: any) => void;
    selectWard: (wardId: string | null) => void;
    selectedTimeFilter: string;
    kpiChanged: (value: any) => void;
    selectedKpi: KpiType;
    productChanges: IStatisticValues;
    avgProductChanges: IStatisticValues;
    responseTime: IStatisticValues;
    promptedProductChanges: IStatisticValues;
    nightTimeMinutesBetweenChanges: IStatisticValues;
    oldestWardCreationDateDays: number;
    selectedWardDaysOld: number;
}

const ChangeInformationBlocks = (props: IChangeInformationBlocksProps) => {

    const handleDropdownSelection = (name: string, value: any) => {
        if (name === "wardSelected" && value !== props.ward.id) {
            props.selectWard(value);
        }
        if (name === "dateSelected" && value !== String(props.selectedDateFilter)) {
            props.dateFilterChanged(value);
        }
        if (name === "timeSelected" && value !== props.selectedTimeFilter) {
            props.timeChanged(value);
        }
        if (name === "kpiSelected" && value !== props.selectedKpi) {
            props.kpiChanged(value);
        }
    };

    const {
        allWards,
        dateFilterOptions,
        endDateChanged,
        endTimeChanged,
        nightTimeMinutesBetweenChanges,
        productChanges,
        avgProductChanges,
        promptedProductChanges,
        responseTime,
        selectedDateFilter,
        selectedEndDate,
        selectedEndTime,
        selectedKpi,
        selectedStartTime,
        selectedStartDate,
        selectedTimeFilter,
        startDateChanged,
        startTimeChanged,
        timeFilterOptions,
        ward
    } = props;

    const specificXAxisOffsetValue = 214;

    return (
        <StyledViewContainer className="statistics-view-info">
            <StyledDropdownsContainer>
                <InformationBlock
                    title="statistics.changeInformationBlock.ward"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                    width={300}
                >
                    <DropdownButtonNew
                        id="wardDropdown"
                        handleChange={handleDropdownSelection}
                        options={allWards.map((ward) => ({
                            label: ward.name,
                            value: ward.id,
                        }))}
                        selectedValue={ward.id}
                        name="wardSelected"
                        noOptionsMessageId="statistics.chart.noWards"
                        maxHeight={500}
                    />
                </InformationBlock>
                <InformationBlock
                    title="statistics.changeInformationBlock.date"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                    width={300}
                >
                    <DropdownDateTimeDaysInThePastFromNowRangeButton
                        amountOfDays={ward.createdDate ? props.selectedWardDaysOld : props.oldestWardCreationDateDays}
                        id="dateDropdown"
                        options={dateFilterOptions}
                        handleChange={handleDropdownSelection}
                        selectedValue={selectedDateFilter}
                        name="dateSelected"
                        start={selectedStartDate}
                        end={selectedEndDate}
                        handleStartDateTimeChange={
                            startDateChanged
                        }
                        handleEndDateTimeChange={endDateChanged}
                        pickerType={PickerType.Date}
                        offsetValue={specificXAxisOffsetValue}
                        endPickerPropertiesInitialMaxDate={moment().subtract(1, "d")}
                        infoMessage={localizer("dropDown.timePeriodClarification")}
                    />
                </InformationBlock>
                <InformationBlock
                    title="statistics.changeInformationBlock.time"
                    parentClassName={parentClassName}
                    childrenClassName={childClassName}
                    width={300}
                >
                    <TimeRangePickerDropdown
                        name="timeSelected"
                        value={selectedTimeFilter}
                        onChange={handleDropdownSelection}
                        options={timeFilterOptions}
                        handleStartDateTimeChange={
                            startTimeChanged
                        }
                        handleEndDateTimeChange={endTimeChanged}
                        start={selectedStartTime}
                        end={selectedEndTime}
                    />
                </InformationBlock>
            </StyledDropdownsContainer>

            <StyledInformationBlocks>
                <ResponseTimeBlock
                    size={EBlockSize.M}
                    responseTime={responseTime}
                    selectedKpi={selectedKpi}
                    handleDropdownSelection={handleDropdownSelection}
                />
                <ClickableStyledInformationBlockWrapper
                    size={EBlockSize.M}
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.AllProductChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.AllProductChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.allProductChanges"
                        )}
                        description={localizer("constants.changeEventsFilter.kpi.productChanges.total")}
                        isSelected={selectedKpi === KpiType.AllProductChanges}
                        value={productChanges.value !== null && productChanges.value > 0 && productChanges.value !== null ?
                            (
                                <>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{Math.round(productChanges.value * 10) / 10}</StyledBoldSpan>
                                        <StyledSpan>
                                            {localizer("constants.changeEventsFilter.kpi.changes")}
                                        </StyledSpan>
                                    </StyledValueContainer>
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(productChanges.trend)}</IconContainer>
                                        <div>{getTrendValue(productChanges.trend)}{productChanges.trend !== null && "%"}</div>
                                    </StyledTrendContainer>
                                </>
                            ) : (
                                <StyledValueContainer>
                                    <StyledSpan>--</StyledSpan>
                                </StyledValueContainer>
                            )
                        }
                    />
                </ClickableStyledInformationBlockWrapper>
                <ClickableStyledInformationBlockWrapper
                    size={EBlockSize.M}
                    alignItems="start"
                    isSelected={
                        selectedKpi === KpiType.AverageProductChanges
                    }
                    onClick={() =>
                        handleDropdownSelection(
                            "kpiSelected",
                            KpiType.AverageProductChanges
                        )
                    }
                >
                    <NumberInformationBlock
                        header={localizer(
                            "constants.changeEventsFilter.kpi.averageProductChanges"
                        )}
                        description={localizer("constants.changeEventsFilter.kpi.averageProductChanges.description")}
                        isSelected={selectedKpi === KpiType.AverageProductChanges}
                        value={avgProductChanges.value !== null && avgProductChanges.value > 0 && avgProductChanges.value !== null ?
                            (
                                <>
                                    <StyledValueContainer>
                                        <StyledBoldSpan>{Math.round(avgProductChanges.value * 10) / 10}</StyledBoldSpan>
                                        <StyledSpan>
                                            /{localizer("constants.changeEventsFilter.kpi.day")}
                                        </StyledSpan>
                                    </StyledValueContainer>
                                    <StyledTrendContainer>
                                        <IconContainer>{getTrendIcon(avgProductChanges.trend)}</IconContainer>
                                        <div>{getTrendValue(avgProductChanges.trend)}{avgProductChanges.trend !== null && "%"}</div>
                                    </StyledTrendContainer>
                                </>
                            ) : (
                                <StyledValueContainer>
                                    <StyledSpan>--</StyledSpan>
                                </StyledValueContainer>
                            )
                        }
                    />
                </ClickableStyledInformationBlockWrapper>
                <PromptedChangesBlock
                    size={EBlockSize.M}
                    promptedProductChanges={promptedProductChanges}
                    selectedKpi={selectedKpi}
                    handleDropdownSelection={handleDropdownSelection}
                    maxWidth={139}
                />
                <NightTimeBlock
                    size={EBlockSize.M}
                    selectedKpi={selectedKpi}
                    handleDropdownSelection={handleDropdownSelection}
                    nightTimeMinutesBetweenChanges={nightTimeMinutesBetweenChanges.value === 0 ? { ...nightTimeMinutesBetweenChanges, value: null } : nightTimeMinutesBetweenChanges}
                />
            </StyledInformationBlocks>
        </StyledViewContainer>
    );
};


export default ChangeInformationBlocks;