import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactPlugin } from "../AppInsights";
import Colors from "../assets/Colors";
import { ReactComponent as DayIcon } from "../assets/images/day.svg";
import { ReactComponent as DayAndNightIcon } from "../assets/images/dayAndNight.svg";
import { ReactComponent as HistoryIcon } from "../assets/images/historyIcon.svg";
import { ReactComponent as NightIcon } from "../assets/images/night.svg";
import { ReactComponent as StatisticsIcon } from "../assets/images/statisticsIcon.svg";
import { StyledViewContainer } from "../components/common/View";
import BackButton from "../components/common/controls/BackButton";
import { Icon } from "../components/common/table/Icon";
import ResidentEditForm from "../components/themed/resident/ResidentEditForm";
import ResidentHistoryView from "../components/themed/resident/history/ResidentHistoryView";
import ResidentStatisticsView from "../components/themed/resident/statistics/ResidentStatisticsView";
import TextOverflowWrapper from "../components/wrappers/TextOverflowWrapper";
import { LocalStorageKeys } from "../data/Persistence";
import { useAppSelector } from "../hooks/useAppSelector";
import { CareFacility } from "../models/CareFacility";
import { ChangeThresholdShort } from "../models/ChangeThreshold";
import { SensorStripType } from "../models/EStripSizeSetting";
import Prod from "../models/Prod";
import ProductFamily from "../models/ProductFamily";
import { Resident } from "../models/Resident";
import { Ward } from "../models/Ward";
import { areProductsTheSame, getSelectedProductSizeForResident, residentHasValidSelectedSize } from "../models/helpers/ResidentHelpers";
import { spinnerKeys } from "../reducers/spinnerSlice";
import { withRouter } from "../routes/routesHelper";
import { rolesWithResidentAddDeleteRights } from "../settings/Settings";
import { localizer } from "../utils/Localizer";
import { routePaths } from "../utils/PathHelpers";
import {
    ButtonContainer,
    GeneralResidentInfoContainer, HeaderContainer, InformationContainer, OneStripNotificationTimingContainer,
    ProductContainer, ProductName,
    SameProductContainer,
    SeparatorLine,
    SetupIdentifier,
    SingleInformationContainer,
    StripPlaceholder,
    StyledLink,
    StyledResidentInformationContainer,
    StyledTabButton,
    StyledTitle,
    SummaryStripIndicator,
    TabButtonContainer,
    ThresholdContainer
} from "./ResidentViewStyle";
import { guidRegex } from '../utils/Regex'
import { IResidentStateType } from '../reducers/residentSlice'

const iconHeight = 24;
const iconWidth = 24;

const getChangeThresholds = (thresholdValue: number) => {
    return localizer(Object.entries(ChangeThresholdShort).find(([_label, value]) => value === thresholdValue)![0]);
}

const getProductIcon = (resident: Resident, nightTimeBlock: boolean, sameProducts: boolean, stripSizeSetting?: SensorStripType) => {
    const noProducts = !resident.dayTimeSetup && !resident.nightTimeSetup;
    if (noProducts) {
        return nightTimeBlock ?
            <><NightIcon height={iconHeight} width={iconWidth} fill={Colors.grey.mediumLight} />{localizer("resident.summary.product.night")}</>
            :
            <> <DayIcon height={iconHeight} width={iconWidth} fill={Colors.grey.mediumLight} />{localizer("resident.summary.product.day")}</>;
    } else if (nightTimeBlock && resident.nightTimeSetup) {
        if (resident.dayTimeSetup && sameProducts && !resident.separateTransmitter && stripSizeSetting === SensorStripType.MultiSize) {
            return <><DayAndNightIcon height={iconHeight} width={iconWidth} fill={Colors.blue.brandingDark} />{localizer("resident.summary.product.nightAndDay")}</>
        }
        return <><NightIcon height={iconHeight} width={iconWidth} fill={Colors.blue.brandingDark} />{localizer("resident.summary.product.night")}</>
    } else if (resident.dayTimeSetup) {
        return <><DayIcon height={iconHeight} width={iconWidth} fill={Colors.blue.brandingDark} />{localizer("resident.summary.product.day")}</>
    }
    return <></>;
}

const getDayProductView = (sameProducts: boolean, sameStrips: boolean, resident: Resident, stripSizeSetting: SensorStripType, stripLength?: string) => {
    if (resident.dayTimeSetup && sameStrips && stripSizeSetting === SensorStripType.MultiSize) {
        if (sameProducts) {
            return (
                <SameProductContainer>
                    <StripPlaceholder>{localizer("resident.summary.strip.sameStripAndProduct")}</StripPlaceholder>
                </SameProductContainer>)
        } else {
            return (
                <>
                    <SetupIdentifier>{getProductIcon(resident, false, sameProducts, stripSizeSetting)}</SetupIdentifier>
                    <ProductName>{getProductName(resident, false)}</ProductName>
                    <StripPlaceholder>{localizer("resident.summary.strip.sameStrip")}</StripPlaceholder>
                </>
            )
        }

    }
    return (
        <>
            <SetupIdentifier>{getProductIcon(resident, false, sameProducts, stripSizeSetting)}</SetupIdentifier>
            <ProductName>{getProductName(resident, false)}</ProductName>
            {stripSizeSetting === SensorStripType.MultiSize && (
                <SummaryStripIndicator
                    icon={undefined}
                    stripLength={!resident.dayTimeSetup ? undefined : stripLength}
                />
            )}
        </>)
}

const getProductName = (resident: Resident, nightTimeBlock: boolean) => {
    if (!resident.nightTimeSetup) {
        return localizer("resident.summary.product.notSelected");
    } else if (nightTimeBlock) {
        return <>{resident.nightTransmitter.productName}, {resident.nightTransmitter.productSize}</>;
    }
    return <>{resident.dayTransmitter.productName}, {resident.dayTransmitter.productSize}</>;
}


const ResidentView = (props: any) => {

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsDateFilter);
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsTimeFilter);
            localStorage.removeItem(LocalStorageKeys.ResidentHistoryDateFilter);
            localStorage.removeItem(LocalStorageKeys.ResidentHistoryTimeFilter);
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsStartDate);
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsEndDate);
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareStartDate);
            localStorage.removeItem(LocalStorageKeys.ResidentStatisticsCompareEndDate);
        }
    }, []);

    const { residentId, tab } = props.params;

    const {residents, isFetching} = useAppSelector<IResidentStateType>(state => state.residentSlice);

    let resident: Resident | undefined
    if(guidRegex.test(residentId)) {
        resident = residents.find(r => r.externalId === residentId);
    }
    else {
        resident = residents.find(r => r.id === residentId);
    }

    const ward = useAppSelector<Ward | undefined>(state => state.wardSlice.wards.find(w => w.id === resident?.wardId));
    const productFamilies = useAppSelector<ProductFamily[]>(state => state.marketSlice.productFamilies);
    const products = useAppSelector<Prod[]>(state => state.marketSlice.products);
    const limitedAccess = useAppSelector<boolean>(state => !rolesWithResidentAddDeleteRights.includes(state.contextSlice.role));
    const [addResident, setAddResident] = useState<boolean>(false);
    const isSpinnerVisible = useAppSelector<boolean>(
        (state) => state.spinnerSlice.activeSpinners.some((activeSpinner) => activeSpinner === spinnerKeys.global)
    );
    const sameStrip = resident ? !resident.separateTransmitter : false;
    const sameProducts = resident ? areProductsTheSame(resident, productFamilies, products) : false;
    const navigate = useNavigate();
    const careFacility = useAppSelector<CareFacility | undefined>(state => state.nursingHomeSlice.nursingHome || undefined);

    // Treats the case when we are on a resident and switch NHs hence we don't have a resident on that page and we need to redirect to resident list.
    useEffect(() => {
        if (!isFetching && !isSpinnerVisible && !resident) {
            navigate(routePaths.careRecipients);
        }
    }, [isSpinnerVisible, resident, navigate, isFetching]);

    const backNavigationPath = () => {
        const previousPath = localStorage.getItem("previousPath");
        switch (previousPath) {
            case routePaths.monitoring:
                return routePaths.monitoring;
            case routePaths.home:
                return routePaths.home;
            default:
                return routePaths.careRecipients;
        }
    }

    return (
        <StyledViewContainer>
            {addResident ?
                <ResidentEditForm onDiscard={() => setAddResident(false)} entity={resident} blockDeletion={limitedAccess} />
                :
                <>  {resident && (
                    <>
                        <ButtonContainer>
                            <BackButton onClick={() => navigate(backNavigationPath())} />
                        </ButtonContainer>
                        <StyledResidentInformationContainer>
                            <HeaderContainer>
                                <StyledTitle>{localizer("resident.summary.title")}</StyledTitle>
                                <Icon
                                    icon={faPencilAlt}
                                    text={"residents.editButton"}
                                    qaId={resident?.name.toLowerCase() + ("-edit_button")}
                                    onClick={() => setAddResident(true)}
                                />
                            </HeaderContainer>
                            {ward && (<InformationContainer>
                                <GeneralResidentInfoContainer>
                                    <SingleInformationContainer>
                                        <span>{localizer("resident.summary.bedName")}</span>
                                        <TextOverflowWrapper tooltip={resident.name}>{resident.name}</TextOverflowWrapper>
                                    </SingleInformationContainer>
                                    <SingleInformationContainer>
                                        <span>{localizer("resident.summary.ward")}</span>
                                        <TextOverflowWrapper tooltip={ward.name}>{ward.name}</TextOverflowWrapper>
                                    </SingleInformationContainer>
                                </GeneralResidentInfoContainer>
                                <ProductContainer>
                                    <SetupIdentifier>{getProductIcon(resident, true, sameProducts, careFacility?.sensorStripType)}</SetupIdentifier>
                                    <ProductName>{getProductName(resident, true)}</ProductName>
                                    {careFacility && careFacility.sensorStripType === SensorStripType.MultiSize ? (
                                        <>
                                            <SummaryStripIndicator
                                                icon={undefined}
                                                stripLength={residentHasValidSelectedSize(resident, true, productFamilies, products) ? getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip : undefined}
                                            />
                                            <ThresholdContainer>{localizer("residents.editForm.changeThreshold.title")}: {getChangeThresholds(resident.nightTransmitter.changeThreshold)}</ThresholdContainer>
                                        </>
                                    ) : (
                                        <OneStripNotificationTimingContainer>
                                            <span>
                                                {localizer("residents.editForm.changeThreshold.title")}
                                            </span>
                                            <span>
                                                {getChangeThresholds(resident.nightTransmitter.changeThreshold)}
                                            </span>
                                        </OneStripNotificationTimingContainer>
                                    )}
                                </ProductContainer>
                                {(resident.dayTimeSetup || (!resident.dayTimeSetup && !resident.nightTimeSetup)) && (
                                    <ProductContainer>
                                        {careFacility && (
                                            <>
                                                {getDayProductView(sameProducts, sameStrip, resident, careFacility.sensorStripType, residentHasValidSelectedSize(resident, false, productFamilies, products) ? getSelectedProductSizeForResident(resident, false, productFamilies, products)?.strip : undefined)}
                                                {careFacility.sensorStripType === SensorStripType.MultiSize ? (
                                                    <ThresholdContainer>{localizer("residents.editForm.changeThreshold.title")}: {getChangeThresholds(resident.dayTransmitter.changeThreshold)}</ThresholdContainer>
                                                ) : (
                                                    <OneStripNotificationTimingContainer>
                                                        <span>
                                                            {localizer("residents.editForm.changeThreshold.title")}
                                                        </span>
                                                        <span>
                                                            {getChangeThresholds(resident.dayTransmitter.changeThreshold)}
                                                        </span>
                                                    </OneStripNotificationTimingContainer>
                                                )}
                                            </>
                                        )}


                                    </ProductContainer>)}
                            </InformationContainer>
                            )}
                        </StyledResidentInformationContainer>
                        <div>
                            <TabButtonContainer>
                                <StyledLink to={routePaths.careRecipients + `/${residentId}/statistics`}>
                                    <StyledTabButton active={(tab === "statistics" || tab === undefined)}>
                                        {localizer("resident.summary.statisticsTab")}
                                        <StatisticsIcon />
                                    </StyledTabButton>
                                </StyledLink>
                                <StyledLink to={routePaths.careRecipients + `/${residentId}/history`}>
                                    <StyledTabButton active={tab === "history"}>
                                        {localizer("resident.summary.historyTab")}
                                        <HistoryIcon />
                                    </StyledTabButton>
                                </StyledLink>
                            </TabButtonContainer>
                            <SeparatorLine />
                            <div>
                                {(tab === "statistics" || tab === undefined) &&
                                    <ResidentStatisticsView resident={resident} />
                                }
                                {tab === "history" &&
                                    <ResidentHistoryView resident={resident} />
                                }
                            </div>
                        </div>
                    </>)}
                </>}
        </StyledViewContainer>
    );
}


export default withAITracking(reactPlugin, withRouter(ResidentView));
