import React from "react";
import { ITableProps } from "../../ITableProps";
import { FormattedMessage } from "react-intl";
import Table, { headerJustifyOptions } from "../../../common/table/Table";
import StringFormatter from "../../../common/table/formatters/StringFormatter";
import DateFormatter from "../../../common/table/formatters/DateFormatter";
import RequestFormatter from "./formatters/RequestFormatter";
import StatusFormatter from "./formatters/StatusFormatter";
import AuditDetailsFormatter from "./formatters/AuditDetailFormatter";

const AuditsUsersTable = (props: ITableProps) => {

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="audits.tableHeader.time" />,
        accessor: "time",
        Cell: DateFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 1,
        hideTooltipOnProd: true,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.category" />,
        accessor: "category",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        hideTooltipOnProd: false,
        maxWidth: 140
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.target" />,
        accessor: "target",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 3,
        hideTooltipOnProd: false,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.nursingHome" />,
        accessor: "nursingHome",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 1,
        hideTooltipOnProd: false,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.activity" />,
        accessor: "activity",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 1,
        hideTooltipOnProd: false,
      },
      {
        Header:<FormattedMessage id="audits.tableHeader.modifiedBy" />,
        accessor: "user",
        Cell: StringFormatter,
        headerAlignment: headerJustifyOptions.left,
        flexGrow: 3,
        hideTooltipOnProd: false,
      },
      {
        Header: <FormattedMessage id="audits.tableHeader.status" />,
        accessor: "statusCode",
        Cell: StatusFormatter,
        headerAlignment: headerJustifyOptions.left,
        maxWidth: 100
      },
      {
        Header: <></>,
        accessor: "requestBody",
        Cell: (props: any) => {
          const type = props.cell.row.original.type;
          if (type) {
              if (type === "POST" || type === "PUT") {
                  return <AuditDetailsFormatter data={props.cell.row.original.updatedProperties}/>;
              }
              return <></>;
          }
          return <RequestFormatter  {...props} />;
      },
        headerAlignment: headerJustifyOptions.left,
        disableSortBy: true,
        flexShrink: 1,
        
        maxWidth: 100
      },
    ],
    []
  );
  const data = React.useMemo(() => props.data, [props.data]);

  return (
    <Table
      qaId={"audit_users_table"}
      columns={columns}
      data={data}
      initialState={{
        sortBy: [
          {
            id: "time",
            desc: true,
          },
        ],
      }}
    />
  );
};

export default React.memo(AuditsUsersTable);
