import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { StyledViewContainer } from "../components/common/View";
import CareGroupsTable from "../components/themed/nursingHomeGroups/tables/CareGroupsTable";
import { useAppSelector } from "../hooks/useAppSelector";
import CareGroup from "../models/CareGroup";
import { useState } from "react";
import ModalWrapper, { EModalSize } from "../components/common/modal/ModalWrapper";
import { localizer } from "../utils/Localizer";
import NursingHomeGroupEditForm from "../components/themed/nursingHomeGroups/CareGroupEditForm";

const CareGroupsView = () => {
    const careGroups = useAppSelector<CareGroup[]>(state => state.careGroupSlice.careGroups);
    const [addCareGroupModalOpen, setAddCareGroupModalOpen] = useState(false);
    return (
        <>
            <StyledViewContainer>
                <CareGroupsTable
                    data={careGroups}
                    addClick={() => { setAddCareGroupModalOpen(true) }}
                />
            </StyledViewContainer>
            {addCareGroupModalOpen && (
                <ModalWrapper
                    closeCallback={() => setAddCareGroupModalOpen(false)}
                    isOpen={addCareGroupModalOpen}
                    modalContent={
                        <NursingHomeGroupEditForm 
                        
                        />
                    }
                    modalTitle={localizer("careGroup.add")}
                    size={EModalSize.S}
                />
            )}
        </>
    );
}

export default withAITracking(reactPlugin, CareGroupsView);
