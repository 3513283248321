import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { appInsights } from '../../../../AppInsights';
import { ReactComponent as RightArrowIcon } from '../../../../assets/images/rightArrow.svg';
import { ETrackEvent } from '../../../../utils/AppInsightsHelpers';
import { StyledRight } from '../../../common/table/formatters/PositionContainers';
import { routePaths } from "../../../../utils/PathHelpers";

const MarginedStyledRight = styled(StyledRight)`
    > :not(:last-child) {
        margin-right: 12px;
    }

    word-break: normal;
`;

const StyledArrowIcon = styled(RightArrowIcon)`
    :hover {
        cursor: pointer;
        width: 33px;
        height: 33px;
    }
`;

const GoTo = (props: any) => {
    const { id } = props.row.original;
    const navigate = useNavigate();
    return (
        <MarginedStyledRight>
            <StyledArrowIcon onClick={() => {
                appInsights.trackEvent({ name: ETrackEvent.JumpToResidentViewFromGoTo });
                navigate(`${routePaths.careRecipients}/${id}`);
            }} />
        </MarginedStyledRight>
    );
}

export default GoTo;
