import { Roles } from "../models/Role";

const routePaths = {
    overview: "/",
    loginPath: "/login",
    expiredLink: "/expiredLink",
    home: "/home",
    monitoring: "/monitoring",
    careRecipients: "/careFacility/careRecipients",
    careRecipient: "/careFacility/careRecipients/:residentId",
    careRecipientWithTab: "/careFacility/careRecipients/:residentId/:tab",
    statistics: "/statistics",
    settings: "/settings",
    careFacilities: "/settings/careFacilities",
    editCareFacility: "/settings/careFacilities/:id",
    addCareFacility: "/settings/careFacilities/new",
    careGroups: "/settings/careGroups",
    users: "/settings/users",
    editUser: "/settings/users/:id",
    addUser: "/settings/users/new",
    audit: "/settings/audit",
    countries: "settings/countries",
    help: "/help",
    activateAccount: "/activateAccount",
    resetPassword: "/resetPassword",
    careFacility: "/careFacility",
    wards: "/careFacility/wards",
    careFacilitySettings: "/careFacility/settings",
    careFaciltyAudit: "/careFacility/audit"
};

const roleRoutePaths: Partial<Record<Roles, string[]>> = {
    [Roles.Nurse]: [
        routePaths.overview,
        routePaths.home,
        routePaths.monitoring,
        routePaths.careFacility,
        routePaths.careRecipients,
        routePaths.careRecipient,
        routePaths.careRecipientWithTab,
        routePaths.statistics,
        routePaths.wards,
        routePaths.careFacilitySettings,
        routePaths.users,
        routePaths.editUser,
        routePaths.addUser,
        routePaths.help,
        routePaths.activateAccount,
        routePaths.resetPassword,
        routePaths.settings
    ],
    [Roles.NHManager]: [
        routePaths.overview,
        routePaths.home,
        routePaths.careFacility,
        routePaths.monitoring,
        routePaths.careRecipients,
        routePaths.careRecipient,
        routePaths.careRecipientWithTab,
        routePaths.statistics,
        routePaths.careFacilitySettings,
        routePaths.wards,
        routePaths.users,
        routePaths.editUser,
        routePaths.addUser,
        routePaths.help,
        routePaths.careFaciltyAudit,
        routePaths.activateAccount,
        routePaths.resetPassword,
        routePaths.settings
    ],
    [Roles.CountryAdmin]: [
        routePaths.overview,
        routePaths.careFacilitySettings,
        routePaths.statistics,
        routePaths.users,
        routePaths.addUser,
        routePaths.editUser,
        routePaths.careFacility,
        routePaths.careFacilities,
        routePaths.editCareFacility,
        routePaths.addCareFacility,
        routePaths.careGroups,
        routePaths.help,
        routePaths.activateAccount,
        routePaths.resetPassword,
        routePaths.settings
    ],
    [Roles.GlobalAdmin]: [
        routePaths.overview,
        routePaths.careFacilitySettings,
        routePaths.statistics,
        routePaths.users,
        routePaths.addUser,
        routePaths.editUser,
        routePaths.careFacility,
        routePaths.addCareFacility,
        routePaths.careGroups,
        routePaths.careFacilities,
        routePaths.editCareFacility,
        routePaths.countries,
        routePaths.help,
        routePaths.audit,
        routePaths.activateAccount,
        routePaths.resetPassword,
        routePaths.settings
    ]
};
// Support have the same routes as NursingHomeManager
roleRoutePaths[Roles.Support] = roleRoutePaths[Roles.NHManager]

export { routePaths, roleRoutePaths };
