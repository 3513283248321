import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { createSlice } from "@reduxjs/toolkit";
import { appInsights } from "../AppInsights";
import { RestClient } from "../data/RestClient";
import { Roles } from "../models/Role";
import ToastMessages from "../models/ToastMessages";
import DisplayUser, { UserTypes } from "../models/DisplayUser";
import { parseErrorToString } from "../utils/AppInsightsHelpers";
import { localizer } from "../utils/Localizer";
import { toastError, toastSuccess } from "../utils/Toaster";
import { ExternalUserType } from "../models/EExternalUserType";

export enum ResourceTypes {
    Global = "Global",
    Country = "Country",
    NursingHomeGroup = "NursingHomeGroup",
    NursingHome = "NursingHome",
    Invalid = "Invalid",
}

export enum UserRole {
    GlobalAdmin = "GlobalAdmin",
    CountryAdmin = "CountryAdmin",
    NHGManager = "NHGManager",
    NHManager = "NHManager",
    Support = "Support",
    Nurse = "Nurse",
    Caregiver = "Caregiver",
}

export enum NursingHomeRole {
    Manager = "Manager",
    Support = "Support",
    Nurse = "Nurse",
    Caregiver = "Caregiver",
}

export interface IUserStateType {
    childResourceType: ResourceTypes;
    childResourceId: string;
    users: DisplayUser[];
}

const initialState: IUserStateType = {
    childResourceType: ResourceTypes.Invalid,
    childResourceId: "",
    users: [],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setChildResourceType: (state, action) => {
            state.childResourceType = action.payload;
        },
        setChildResourceId: (state, action) => {
            state.childResourceId = action.payload;
        },
        clearUserSlice: () => initialState,
    },
});

export const {
    setChildResourceType,
    setChildResourceId,
    clearUserSlice,
    setUsers
} = userSlice.actions;

export const fetchExternalUsers = (id?: number) => async (dispatch: any, _getState: any) => {
    try {
        const usersResponse = await RestClient.getUsersExternal(id);
        const users = usersResponse.map((user: any) => new DisplayUser(user));
        dispatch(setUsers(users));
    } catch (error) {
        console.error(error);
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const fetchInternalUsers = () => async (dispatch: any, _getState: any) => {
    try {
        const usersResponse = await RestClient.getUsersInternal();
        const users = usersResponse.map((user: any) => new DisplayUser({ ...user, type: UserTypes.INTERNAL }));
        dispatch(setUsers(users));
    } catch (error) {
        console.error(error);
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const createExternalUser = (
    userName: string,
    assignedRole: Roles,
    selectedCareFacilities: number[],
    password?: string,
    languageCode?: string,
    mobileNumber?: string,
    externalUserType?: ExternalUserType,
    supportUserNursingHomes?: {nursingHomeId: number, expirationDateTime: string}[],
    wards?: number[]
) => async () => {
    try {
        const requestBody: any = {
            userName: userName.trim(),
            externalUserType,
            languageCode,
            mobileNumber,
            assignedRole
        };

        if (password) {
            requestBody.caregiverPassword = password;
        }

        if (assignedRole === Roles.Support && supportUserNursingHomes) {
            requestBody.supportUserNursingHomes = supportUserNursingHomes;
        }

        if (assignedRole === Roles.Caregiver ) {
            requestBody.wardIds = wards ?? [];
        }

        await RestClient.createExternalUser(requestBody, selectedCareFacilities);
        toastSuccess(localizer(ToastMessages.CreateUserSuccessful));
    } catch (error: any) {
        if (error?.status === 404 || error?.status === 409) {
            throw error;
        }
        console.error(error);
        toastError(localizer(ToastMessages.CreateUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const createInternalUser = (
    userName: string,
    assignedRole: Roles,
    resourceType: ResourceTypes,
    countryIds: number[],
    languageCode?: string,
    mobileNumber?: string,
) => async (dispatch: any, _getState: any) => {
    try {
        const requestBody: any = {
            userName: userName.trim(),
            languageCode,
            mobileNumber,
            assignedRole
        };
        await RestClient.createInternalUser(requestBody, countryIds, resourceType);
        toastSuccess(localizer(ToastMessages.CreateUserSuccessful));
        dispatch(fetchInternalUsers());
    } catch (error: any) {
        if (error?.status === 404) {
            throw error;
        }
        console.error(error);
        toastError(localizer(ToastMessages.CreateUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const updateInternalUser = (
    id: string,
    userName: string,
    assignedRole: Roles,
    resourceType: ResourceTypes,
    countryIds: number[],
    languageCode?: string,
    mobileNumber?: string,
) => async (dispatch: any, _getState: any) => {
    try {
        const requestBody: any = {
            externalId: id,
            userName: userName.trim(),
            languageCode,
            mobileNumber,
            assignedRole
        };
        await RestClient.updateInternalUser(requestBody, countryIds, resourceType);
        toastSuccess(localizer(ToastMessages.UpdateUserSuccessful));
        dispatch(fetchInternalUsers());
    } catch (error: any) {
        if (error?.status === 404) {
            throw error;
        }
        console.error(error);
        toastError(localizer(ToastMessages.UpdateUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const updateExternalUser = (
    id: string,
    userName: string,
    assignedRole: Roles,
    selectedCareFacilities: number[],
    password?: string,
    languageCode?: string,
    mobileNumber?: string,
    externalUserType?: ExternalUserType,
    supportUserNursingHomes?: {nursingHomeId: number, expirationDateTime: string}[],
    wards?: number[]
) => async () => {
    try {
        const requestBody: any = {
            userName: userName.trim(),
            externalUserType,
            languageCode,
            mobileNumber,
            assignedRole,
            externalId: id
        };

        if (password) {
            requestBody.caregiverPassword = password;
        }

        if (assignedRole === Roles.Support && supportUserNursingHomes) {
            requestBody.supportUserNursingHomes = supportUserNursingHomes;
        }

        if (assignedRole === Roles.Caregiver ) {
            requestBody.wardIds = wards ?? [];
        }
        
        await RestClient.updateExternalUser(requestBody, selectedCareFacilities);

        toastSuccess(localizer(ToastMessages.UpdateUserSuccessful));
    } catch (error) {
        console.error(error);
        toastError(localizer(ToastMessages.UpdateUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const deleteExternalUser = (id: string) => async () => {
    try {
        await RestClient.deleteExternalUser(id);
        toastSuccess(localizer(ToastMessages.DeleteUserSuccessful));
    } catch (error) {
        console.error(error);
        toastError(localizer(ToastMessages.DeleteUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const deleteInternalUser = (id: string) => async (dispatch: any) => {
    try {
        await RestClient.deleteInternalUser(id);
        toastSuccess(localizer(ToastMessages.DeleteUserSuccessful));
        dispatch(fetchInternalUsers());
    } catch (error) {
        console.error(error);
        toastError(localizer(ToastMessages.DeleteUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
};

export const expireSupportUser = (id: string) => async () => {
    try {
        await RestClient.expireSupportUserAccess({externalId: id});
        toastSuccess(localizer(ToastMessages.DeleteUserSuccessful));
    } catch (error) {
        console.error(error);
        toastError(localizer(ToastMessages.DeleteUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
}

export const resendActivationEmail = (id: string) => async () => {
    try {
        await RestClient.resendActivationEmail({externalId: id});
        toastSuccess(localizer(ToastMessages.UpdateUserSuccessful));
    } catch (error) {
        console.error(error);
        toastError(localizer(ToastMessages.UpdateUserError));
        appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
    }
}

export default userSlice.reducer;
