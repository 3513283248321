import styled from "styled-components";
import Colors from "../../../assets/Colors";

export const StyledPageSelectorContainer = styled.div`
    min-width: 60px;
`;

export const StyledPageNumber = styled.div<any>`
    font-size: 16px;
    padding: 0 8px;
    color: ${Colors.blue.brandingDark};
    ${props => props.active && `text-decoration: underline;`}
    &:hover {
        cursor: pointer;
    }
`;

export const SortingIndicatorContainer = styled.span`
    margin-left: 7px;
    margin-bottom: 2px;
`;

export const PageSelectorContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
`;

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
`;

export const TableHeader = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${Colors.blue.brandingDark};
`;

export const EntriesContainer = styled.span`
    display: flex;
    align-items: center;
`;

export const ActionDescriptionContainer = styled.span`
    display: flex;
    align-items: center;
    margin: auto 0;
    color: ${Colors.blue.brandingDark};
    font-size: 12px;
    width: 100%;
    justify-content: center;
`;

export const InfoContainer = styled.div`
    height: 50px;
    background-color: ${Colors.grey.tenaLight};
    justify-content: center;
    display: flex;
    align-items: center;
    color: ${Colors.black};
`;

export const RowsContainer = styled.div`
    min-height: 560px;
`;
