import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CareFacility } from "../../../models/CareFacility";
import GoTo from "../../common/table/formatters/GoTo";
import Table, { headerJustifyOptions } from "../../common/table/Table";
import { ITableProps } from "../ITableProps";
import HomeResidentFormatter from "./formatters/HomeResidentFormatter";
import TimeFormatter from "./formatters/TimeFormatter";
import TrendTimeFormatter from "./formatters/TrendTimeFormatter";
import WardFormatter from "./formatters/WardFormatter";

const generateTimeMomentFromString = (hoursMinutes: string) => {
    const [hours, minutes] = hoursMinutes.split(":");
    return moment()
        .set("hour", Number(hours))
        .set("minute", Number(minutes))
        .utc();
};

const getNightTimeMaxValuePerDay = (morningShiftStartTime?: string, nightShiftStartTime?: string) => {
    if (morningShiftStartTime && nightShiftStartTime) {
        const differenceInMin = Math.floor(Math.abs(generateTimeMomentFromString(morningShiftStartTime).diff(generateTimeMomentFromString(nightShiftStartTime))) / 60000);
        const dayInMinutes = 24 * 60;
        const nightShiftDurationInMinutes = dayInMinutes - differenceInMin;
        return nightShiftDurationInMinutes;
    }
    return 12 * 60;
};

const NightTimeTable = (props: ITableProps) => {
    const activeNursingHome = useAppSelector<CareFacility | null>(state => state.nursingHomeSlice.nursingHome);
    const KpiMaxValuePerDay = getNightTimeMaxValuePerDay(activeNursingHome?.morningShiftStartTime, activeNursingHome?.nightShiftStartTime);
    const columns = React.useMemo(
        () => [
            {
                Header: <FormattedMessage id={"home.resident"} />,
                accessor: "name",
                Cell: ((props: any) => {
                    const percentage = Math.ceil((100 * props.row.original.statistics.nightTimeMinutesBetweenChanges.value) / KpiMaxValuePerDay)
                    return <HomeResidentFormatter {...props} barPercentage={percentage > 100 ? 100 : percentage} />
                }),
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: false,
                
                minWidth: 400,
            },
            {
                Header: <FormattedMessage id={"home.average"} />,
                accessor: "statistics.nightTimeMinutesBetweenChanges.value",
                Cell: TimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                
            },
            {
                Header: <FormattedMessage id={"home.trend"} />,
                accessor: "statistics.nightTimeMinutesBetweenChanges.trend",
                Cell: TrendTimeFormatter,
                headerAlignment: headerJustifyOptions.left,
                hideTooltipOnProd: true,
                
            },
            {
                Header: <FormattedMessage id={"home.ward"} />,
                accessor: "wardId",
                Cell: WardFormatter,
                headerAlignment: headerJustifyOptions.left,
                flexGrow: 3,
                hideTooltipOnProd: true,
                
                minWidth: 270,
            },
            {
                Header: <FormattedMessage id={"general.tableHeader.goTo"} />,
                accessor: "id",
                Cell: GoTo,
                headerAlignment: headerJustifyOptions.right,
                flexShrink: 3,
                hideTooltipOnProd: true,
                
                minWidth: 90,
                disableSortBy: true
            }
        ],
        [KpiMaxValuePerDay]
    );

    const data = React.useMemo(() => props.data, [props.data]);
    const sortBy = React.useMemo(() => (
        [
            {
                id: "statistics.nightTimeMinutesBetweenChanges.value",
                desc: false
            },
            {
                id: "statistics.nightTimeMinutesBetweenChanges.trend",
                desc: false
            }
        ]
    ), []);

    return (
        <Table
            qaId={"nightTime_table"}
            columns={columns}
            data={data}
            styleProps={{
                rowHeight: 50,
                headerHeight: 55,
                inverseColors: true,
                dynamicHeight: props.data.length <= 3,
            }}
            initialState={{
                sortBy: sortBy
            }}
        />
    );
};

export default React.memo(NightTimeTable);
