import React, { useState } from "react";
import {
    Dropdown, DropdownButton as ReactBootstrapDropdownButton,
    FormControl
} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import Colors from "../../../assets/Colors";
import { localizer } from "../../../utils/Localizer";

interface IDropdownButtonStyledProps {
    maxHeight: number;
    noOverflow: boolean;
}

export const StyledDropdownItem = styled(DropdownItem)`
    &.dropdown-item {
        min-height: 40px;
        line-height: 22px;
        padding: 9px 16px;
        word-break: break-all;
        white-space: break-spaces;
        &.active {
            background-color: ${Colors.blue.brandingDark};
        }
    }
`;

const commonDropdownStyle = css`
    .btn {
        padding: 0.375rem 0.75rem;
    }
    button:not(.react-datepicker__navigation) {
        width: 100%;
        background-color: ${Colors.white.clearWhite};
        display: flex;
        align-items: center;
        border: 1px solid ${Colors.grey.mediumLight};
        justify-content: space-between;
        height: 42px;
    }
    .dropdown-toggle[aria-expanded="false"] {
        &:focus {
            box-shadow: none !important;
            border: 1px solid ${Colors.grey.mediumLight};
            background-color: ${Colors.white.clearWhite} !important;
        }
    }
    .dropdown-toggle[aria-expanded="true"] {
        border: 1px solid ${Colors.grey.mediumLight};
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        &:focus {
            box-shadow: none !important;
            border: 1px solid ${Colors.grey.mediumLight};
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            background-color: ${Colors.white.hoverWhite};
        }
    }

    .dropdown-menu.show {
        transform: translate(0px, 41px) !important;
        border: 1px solid ${Colors.grey.mediumLight};
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        min-width: 9rem;
    }
`;

export const StyledReactBootstrapDropdownButton = styled(({ maxHeight, noOverflow, maxWidth, ...props }) => (<ReactBootstrapDropdownButton {...props} />)) <IDropdownButtonStyledProps>`
    ${commonDropdownStyle}
    .dropdown-menu.show {
        ${props => props.portaling ? `
            transform: translate(0px, -1px) !important;
            position: fixed !important;
            inset: inherit !important;
            background: white;
            max-width: ${props.maxWidth}px;
        `
        : ""
    }
        max-height: ${props => props.maxHeight}px;
        overflow: ${props => props.noOverflow ? '' : 'auto'};
    }
    button:not(.react-datepicker__navigation) {
        ${props => props.height ? `height: ${props.height}px;` : ''}
    }
`;

const StyledFormControl = styled(FormControl)`
    height: auto;
    border-radius: unset;
`;

const StyledTextDropdownSelectedOption = styled.div<any>`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledNoOptionsLabel = styled.label`
    color: ${Colors.grey.medium};
    margin-bottom: 0;
`;

const StyledReactBootstrapDropdown = styled(({ maxHeight, ...props }) => (<Dropdown {...props} />)) <IDropdownButtonStyledProps>`
    ${commonDropdownStyle}
    .dropdown-menu.show {
         ${props => props.portaling ? `
            transform: translate(0px, -1px) !important;
            position: fixed !important;
            inset: inherit !important;
            max-width: 658px;
            background: white;` : ""}
        z-index: 1200;
        max-height: ${props => props.maxHeight}px;
        overflow: auto;
    }
    input {
        border-top: 1px solid ${Colors.grey.mediumLight};
        border-bottom: 1px solid ${Colors.grey.mediumLight};
        border-left: 0px;
        border-right: 0px;
        &:focus {
            border: 1px solid ${Colors.blue.selectionBlue};
            box-shadow: 0px 0px 2px 2px ${Colors.blue.glowingBlue};
        }
    }
`;

const StyledListForFilterableDropdown = styled.ul`
    margin-bottom: 0px;
`;

export interface IDropDownOption {
    label: string;
    value: any;
    disabled?: boolean;
}

interface IDropdownButtonNewProps {
    id: string;
    name: string;
    handleChange: (name: string, value: string) => void;
    selectedValue: any;
    options: IDropDownOption[];
    maxHeight?: number;
    placeholder?: string;
    isFilterable?: boolean;
    disabled?: boolean;
    disabledMessage?: string;
    showSelectedAsDisabledMessage?: boolean;
    noOptionsMessageId?: string;
    qaId?: string;
    portaling?: boolean;
    height?: number;
    maxWidth?: number;
}

const DropdownButtonNew = (props: IDropdownButtonNewProps) => {

    const getSelectedOptionLabel = (): JSX.Element => {
        const selectedOption = props.options.find(
            (option) => option.value === props.selectedValue
        );

        const disabledMessage = props.disabled && props.disabledMessage ? props.disabledMessage : localizer(props.placeholder || "dropDownFieldFormGroup.placeholder");
        return (
            <StyledTextDropdownSelectedOption data-testid={"dropdown-node"} hasSelectedOption={props.disabled && props.disabledMessage ? undefined : selectedOption}>
                {((!props.disabled || props.showSelectedAsDisabledMessage) && selectedOption)
                    ? selectedOption.label
                    : disabledMessage}
            </StyledTextDropdownSelectedOption>
        );
    };

    const generateDropDownItems = (): JSX.Element[] => {
        if (props.options.length > 0) {
            return props.options.map((option) => {
                return (
                    <StyledDropdownItem
                        data-testid={"dropdown-option-node"}
                        data-qa={((option.label ? option.label.replace(/\u00A0/g, ' ').toLowerCase() : undefined) + "-dropdown_item")}
                        key={props.id + option.value}
                        eventKey={option.value}
                        active={option.value === props.selectedValue}
                        disabled={option.disabled}
                    >
                        {option.label}
                    </StyledDropdownItem>
                );
            });
        }
        return [
            <DropdownItem key={-1} eventKey={-1} disabled={true}>
                <StyledNoOptionsLabel>
                    <FormattedMessage
                        id={
                            props.noOptionsMessageId
                                ? props.noOptionsMessageId
                                : "dropDown.noOptions"
                        }
                    />
                </StyledNoOptionsLabel>
            </DropdownItem>,
        ];
    };

    const handleOptionSelect = (value: string) => {
        props.handleChange(props.name, value);
    };

    const [dropDownOptionsFilterValue, setDropDownOptionsFilterValue] =
        useState("");

    const FilterableDropdown = (
        <StyledReactBootstrapDropdown
            maxHeight={props.maxHeight ? props.maxHeight : 350}
            onSelect={(value: string) => handleOptionSelect(value)}
            portaling={props.portaling ? props.portaling.toString() : undefined}
        >
            <Dropdown.Toggle
                bsPrefix="custom-toggle"
                as={React.forwardRef((props: any, ref: any) => (
                    <Dropdown.Toggle
                        {...props}
                        ref={ref}
                        onSelect={(e: any) => {
                            e.preventDefault();
                            props.onSelect(e);
                        }}
                    >
                        {getSelectedOptionLabel()}
                    </Dropdown.Toggle>
                ))}
                data-qa={props.qaId}
                id={props.id}
                variant={"light"}
                value={props.selectedValue}
            ></Dropdown.Toggle>
            <Dropdown.Menu
                as={React.forwardRef((props: any, ref: any) => {
                    return (
                        <div
                            data-qa={(props["aria-labelledby"]).toLowerCase() + "-dropdown_list"}
                            ref={ref}
                            style={props.style}
                            className={props.className}
                            aria-labelledby={props["aria-labelledby"]}
                        >
                            <StyledFormControl
                                autoFocus
                                type="search"
                                data-testid={"dropdown-filterable-node"}
                                placeholder={localizer(
                                    "dropDown.filterOptions"
                                )}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setDropDownOptionsFilterValue(
                                        e.target?.value
                                    );
                                }}
                                value={dropDownOptionsFilterValue}
                            />
                            <StyledListForFilterableDropdown className="list-unstyled">
                                {React.Children.toArray(props.children).filter(
                                    (child: any) =>
                                        !dropDownOptionsFilterValue ||
                                        child?.props?.children
                                            ?.toLowerCase()
                                            ?.includes(
                                                dropDownOptionsFilterValue?.toLowerCase()
                                            )
                                )}
                            </StyledListForFilterableDropdown>
                        </div>
                    );
                })}
            >
                {generateDropDownItems()}
            </Dropdown.Menu>
        </StyledReactBootstrapDropdown>
    );

    const SimpleDropdown = (
        <StyledReactBootstrapDropdownButton
            data-qa={props.qaId}
            maxHeight={props.maxHeight ? props.maxHeight : 350}
            maxWidth={props.maxWidth}
            id={props.id}
            variant={"light"}
            title={getSelectedOptionLabel()}
            disabled={props.disabled}
            value={props.selectedValue}
            onSelect={(value: string) => handleOptionSelect(value)}
            height={props.height}
            portaling={props.portaling ? props.portaling.toString() : undefined}
        >
            {generateDropDownItems()}
        </StyledReactBootstrapDropdownButton>
    );

    return props.isFilterable ? FilterableDropdown : SimpleDropdown;
};

export default DropdownButtonNew;
