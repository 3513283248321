import { localizer } from "../utils/Localizer";

export enum ResidentEventType {
    All = 'All',
    Saturated = 'Saturated',
    SomeUrine = 'SomeUrine',
    Error = 'Error',
    OK = 'Ok',
    Inactive = 'Inactive',
    NotBeingMonitored = "NotBeingMonitored",
    Connecting = "Connecting"
}

export const ResidentEventTypeOptions = () => Object.entries(ResidentEventType).map(tuple =>({
    value: tuple[0],
    label: localizer('resident.history.tableRow.status.' + tuple[1])
}));
