import { Moment } from "moment";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import CompareDatePicker from "../../common/controls/dateTime/CompareDatePicker";
import { KpiType } from '../../../models/KpiType';
import '../../../styles/main.scss';
import Colors from "../../../assets/Colors";

interface IGraphHeaderProps {
    selectedKpi: KpiType;
    isDataPresent: boolean;
    daysInterval: number;
    startDate: Moment;
    endDate: Moment;
    startRangeDate: Moment;
    setSelectedCompareStartDate: (value: Moment | null) => void;
    setSelectedCompareEndDate: (value: Moment | null) => void;
    disabledCompare: boolean;
    compareStartDate: string | null;
    getHeaderId: (kpi: KpiType) => string;
    includeToday: boolean;
}

const StyledGraphTitle = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    align-items: center;
    height: 80px;
    color: ${Colors.black};
    margin-left:300px;
`;

const StyledGraphHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    align-items: center;
    color: ${Colors.black}
`;


const GraphHeader = (props: IGraphHeaderProps) => {

    return (
        <StyledGraphHeader>
            <div />
            <StyledGraphTitle>
                <span>
                    <FormattedMessage id={props.getHeaderId(props.selectedKpi)} />
                </span>
                {!props.isDataPresent && (
                    <span>
                        <FormattedMessage id={"statistics.graphHeader.noData"} />
                    </span>
                )}

            </StyledGraphTitle>
            <CompareDatePicker
                daysInterval={props.daysInterval}
                startDate={props.startDate}
                endDate={props.endDate}
                startRangeDate={props.startRangeDate}
                setSelectedCompareStartDate={props.setSelectedCompareStartDate}
                setSelectedCompareEndDate={props.setSelectedCompareEndDate}
                disabledCompare={props.disabledCompare}
                compareStartDate={props.compareStartDate}
                includeToday={props.includeToday}
            />

        </StyledGraphHeader>
    );
};

export default GraphHeader;
