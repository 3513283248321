
export enum Direction {
    Up = 'Up',
    Down = 'Down'
}

export default interface ISorting {
    direction: Direction,
    column: string
}
