import { ResidentEventType } from "../../models/ResidentEventType";
import Colors from "../../assets/Colors";
import DiaperIcon from "../../assets/images/DiaperIcon";
import WarningIcon from "../../assets/images/WarningIcon";
import TimesCircleIcon from "../../assets/images/TimesCircleIcon";
import CheckIcon from "../../assets/images/CheckIcon";
import WirelessIcon from "../../assets/images/WirelessIcon";

interface IOwnProps {
    status: string;
}

const ChangeStatusIcon: React.FunctionComponent<IOwnProps> = (props) => {
    const { status } = props;
    let image: JSX.Element;
    switch (status) {
        case ResidentEventType.Connecting:
            image = <WirelessIcon color={Colors.alert.grey} />;
            break;
        case ResidentEventType.Saturated:
            image = <DiaperIcon color={Colors.alert.red} />;
            break;
        case ResidentEventType.Error:
            image = <WarningIcon color={Colors.alert.red} /> ;
            break;
        case ResidentEventType.OK:
            image = <CheckIcon color={Colors.alert.green} /> ;
            break;
        case ResidentEventType.SomeUrine:
            image = <CheckIcon color={Colors.alert.yellow} /> ;
            break;
        case ResidentEventType.NotBeingMonitored:
        case ResidentEventType.Inactive:
        default:
            image = <TimesCircleIcon color={Colors.alert.grey} /> ;
            break;
    }

    return image;
};

export default ChangeStatusIcon;
