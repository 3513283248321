import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import get from "lodash/get";
import React from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import { useFilters, useFlexLayout, useSortBy, useTable } from "react-table";
import Tooltip from "../tooltip/Tooltip";
import { ITableProps } from "./ITableProps";
import { StyledSortingIndicator, Styles } from "./TableStyle";

export const headerJustifyOptions = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
};

const getParameter = (data: any, name: string, defaultValue: any): any => {
    const bodyCellParameterOrDefault = get(
        data,
        ["cell", "column", name],
        defaultValue
    );
    const parameterOfDefault = get(
        data,
        ["column", name],
        bodyCellParameterOrDefault
    );

    return parameterOfDefault;
};

const getStyles = (props: any, data: any) => {
    const justifyContent = getParameter(data, "leftAlign", false)
        ? undefined
        : data.column?.headerAlignment || headerJustifyOptions.center;
    const displayStyle = getParameter(data, "withoutFlex", false)
        ? undefined
        : "flex";
    return [
        {
            ...props,
            title: undefined, // removing 'Toggle SortBy' tooltip
        },
        {
            style: {
                justifyContent,
                alignItems: getParameter(data, "alignItems", "stretch"),
                display: displayStyle,
                flexGrow: getParameter(data, "flexGrow", 0),
                flexShrink: getParameter(data, "flexShrink", 1),
                flexBasis: getParameter(data, "flexBasis", "auto"),
                minWidth: getParameter(data, "minWidth", "auto"),
                maxWidth: getParameter(data, "maxWidth", "auto")
            },
        },
    ];
};

const Table = (props: ITableProps) => {
    const { qaId, columns, data, initialState } = props;

    const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

    const { getTableProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            initialState,
            defaultColumn,
            autoResetSortBy: false,
            autoResetFilters: false,
        },
        useFilters,
        useSortBy,
        useFlexLayout
    );

    return (
        <>
            <Styles {...props.styleProps}>
                <div {...getTableProps()} className="table" data-qa={qaId}>
                    <div className="thead">
                        {headerGroups.map((headerGroup) => (
                            <div
                                {...headerGroup.getHeaderGroupProps()}
                                className="tr"
                                key={"headerGroup-" + headerGroup.id}
                            >
                                {headerGroup.headers.map((column: any) => {
                                    return (
                                        <div
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(
                                                    getStyles
                                                )
                                            )}
                                            className="th"
                                            key={"header-" + column.id}
                                        >
                                            {column.Header.props && column.render("Header")}
                                            <div>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <StyledSortingIndicator
                                                            icon={faChevronDown}
                                                        />
                                                    ) : (
                                                        <StyledSortingIndicator
                                                            icon={faChevronUp}
                                                        />
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            {column.enableFilter && (
                                                <span className="filter-cell">
                                                    {column.render("Filter")}
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                    <div className="tbody">
                        <Flipper flipKey={rows.map(row => row.id).join("")}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <Flipped key={"flipped-row-" + row.id} flipId={row.id}>
                                        <div
                                            {...row.getRowProps()}
                                            className={"tr"}
                                            key={"row-" + row.id}
                                        >
                                            {row.cells.map((cell) => {
                                                return (
                                                    <div
                                                        {...cell.getCellProps(
                                                            getStyles
                                                        )}
                                                        className="td"
                                                        key={row.id + "-" + cell.column.id}
                                                    >
                                                        {cell.render("Cell")}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Flipped>
                                );
                            })}
                        </Flipper>
                    </div>
                </div>
            </Styles>
            {data.length > 0 && <Tooltip />}
        </>
    );
};

export default Table;
