import Switch from "react-switch";
import styled from "styled-components";

const CustomToggle = styled(Switch)`
    .react-switch-bg {
        background:${(props) => props.checked ? '#1D388B' : '#CCCCCC'} !important;
        box-shadow: none !important;
    }
    .react-switch-handle {
        box-shadow: none !important;
    }

`;

export default CustomToggle;