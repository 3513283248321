import styled from 'styled-components';
import { StyledRight } from './PositionContainers';

const MarginedStyledRight = styled(StyledRight)`
    > :not(:last-child) {
        margin-right: 17px;
    }
    
    word-break: normal;
`;

const ActionsContainer = (props: any) => {
    return (
        <MarginedStyledRight>
            {props.children}
        </MarginedStyledRight>
    );
};

export default ActionsContainer;