import { Ward } from "../models/Ward";

export const sortWards = (wards: Ward[]): Ward[] => {
    wards.sort(((a, b) => {
        if (a.name.toLowerCase() === b.name.toLowerCase()) { return 0; }
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    }));
    return wards;
};

export const wardToReqBody = (ward: Ward) => {
    return {
        id: Number(ward.id),
        name: ward.name.trim(),
        isActive: ward.isActive,
    };
};
