import React, { SVGProps } from "react";

function DayIcon(props: SVGProps<any>): React.ReactElement {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" />
            <path fillRule="evenodd" d="M22 12C22 12.5523 21.5523 13 21 13L18.7142 13C18.1619 13 17.7142 12.5523 17.7142 12C17.7142 11.4477 18.1619 11 18.7142 11L21 11C21.5523 11 22 11.4477 22 12Z" />
            <path fillRule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3L13 5.2858C13 5.83809 12.5523 6.2858 12 6.2858C11.4477 6.2858 11 5.83809 11 5.2858L11 3C11 2.44772 11.4477 2 12 2Z" />
            <path fillRule="evenodd" d="M19.0712 4.92864C19.4617 5.31916 19.4617 5.95232 19.0712 6.34285L17.4549 7.95916C17.0644 8.34968 16.4312 8.34968 16.0407 7.95916C15.6502 7.56863 15.6502 6.93547 16.0407 6.54494L17.657 4.92864C18.0475 4.53811 18.6807 4.53811 19.0712 4.92864Z" />
            <path fillRule="evenodd" d="M19.0714 19.0702C18.6808 19.4608 18.0477 19.4608 17.6572 19.0702L16.0408 17.4539C15.6503 17.0634 15.6503 16.4302 16.0408 16.0397C16.4314 15.6492 17.0645 15.6492 17.4551 16.0397L19.0714 17.656C19.4619 18.0465 19.4619 18.6797 19.0714 19.0702Z" />
            <path fillRule="evenodd" d="M6.28613 12C6.28613 12.5523 5.83842 13 5.28613 13L3.00033 13C2.44804 13 2.00033 12.5523 2.00033 12C2.00033 11.4477 2.44804 11 3.00033 11L5.28613 11C5.83842 11 6.28613 11.4477 6.28613 12Z" />
            <path fillRule="evenodd" d="M12 17.7139C12.5523 17.7139 13 18.1616 13 18.7139L13 20.9997C13 21.552 12.5523 21.9997 12 21.9997C11.4477 21.9997 11 21.552 11 20.9997L11 18.7139C11 18.1616 11.4477 17.7139 12 17.7139Z" />
            <path fillRule="evenodd" d="M7.95989 16.0405C8.35041 16.431 8.35041 17.0641 7.95989 17.4547L6.34358 19.071C5.95305 19.4615 5.31989 19.4615 4.92936 19.071C4.53884 18.6804 4.53884 18.0473 4.92937 17.6568L6.54567 16.0405C6.9362 15.6499 7.56936 15.6499 7.95989 16.0405Z" />
            <path fillRule="evenodd" d="M7.96004 7.95891C7.56951 8.34943 6.93635 8.34943 6.54582 7.95891L4.92952 6.3426C4.53899 5.95208 4.53899 5.31891 4.92952 4.92839C5.32004 4.53786 5.95321 4.53786 6.34373 4.92839L7.96004 6.5447C8.35056 6.93522 8.35056 7.56838 7.96004 7.95891Z" />
        </svg>
    );
}

export default DayIcon;
