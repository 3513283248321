import { SeverityLevel } from "@microsoft/applicationinsights-web";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ResponseError } from "superagent";
import { appInsights } from "../../../AppInsights";
import Colors from "../../../assets/Colors";
import { ReactComponent as DayIcon } from "../../../assets/images/day.svg";
import { ReactComponent as DayOnlyIcon } from "../../../assets/images/dayOnly.svg";
import { ReactComponent as NightIcon } from "../../../assets/images/night.svg";
import { ReactComponent as NightOnlyIcon } from "../../../assets/images/nightOnly.svg";
import { ReactComponent as SameAsNightIcon } from "../../../assets/images/sameAsNight.svg";
import { ReactComponent as StripIcon } from "../../../assets/images/stripIcon.svg";
import { RestClient } from "../../../data/RestClient";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { CareFacility, IntegrationType } from "../../../models/CareFacility";
import { SensorStripType } from "../../../models/EStripSizeSetting";
import ErrorCodes from "../../../models/ErrorCodes";
import Market from "../../../models/Market";
import { MarketCode } from "../../../models/MarketCode";
import Prod from "../../../models/Prod";
import ProductFamily from "../../../models/ProductFamily";
import ProductSize from "../../../models/ProductSize";
import { Resident } from "../../../models/Resident";
import ToastMessages from "../../../models/ToastMessages";
import { Ward } from "../../../models/Ward";
import { residentToReqBody } from "../../../models/helpers/RequestHelpers";
import { areProductsTheSame, areStripsLengthTheSame, getProduct, getSelectedProductSizeForResident, residentHasValidSelectedBrief, residentHasValidSelectedProduct, residentHasValidSelectedSize } from "../../../models/helpers/ResidentHelpers";
import { setFormDirty } from "../../../reducers/contextSlice";
import { getChangeThresholds, removeWhiteSpaces, validateResident } from "../../../reducers/residentHelper";
import { ETrackEvent, parseErrorToString } from "../../../utils/AppInsightsHelpers";
import { localizer } from "../../../utils/Localizer";
import { routePaths } from "../../../utils/PathHelpers";
import { toastError, toastSuccess } from "../../../utils/Toaster";
import AddEditButtonGroup from "../../../views/Buttons/AddEditButtonGroup";
import "../../../views/Buttons/SnoozeButton.scss";
import GenericConfirmationForm from "../../../views/GenericConfirmationForm";
import BackButton from "../../common/controls/BackButton";
import CheckBox from "../../common/controls/CheckBox";
import DropdownButtonNew from "../../common/controls/DropdownButtonNew";
import TextFieldNew from "../../common/controls/TextFieldNew";
import ModalWrapper, { EModalSize } from "../../common/modal/ModalWrapper";
import ValidatedFormGroupWrapper from "../../wrappers/ValidatedFormGroupWrapper";
import { StyledEmptyCol, StyledFrom, StyledIconContainer, StyledLabel, StyledLeftCol, StyledNightIcon, StyledRecommendationRow, StyledRightCol, StyledSpaceSmall, StyledStripLeftCol, StyledStripRightCol, StyledTitle, StyledToggle } from "./ResidentEditFormStyle";
import SensorStripIndicator from "./SensorStripIndicator";
import {ExternalIntegrationInformationBlock} from '../../common/informationBlock/ExternalIntegrationInformationBlock'

enum Fields {
    Name = "Name",
    NightTransmitterId = "NightTransmitterId",
    DayTransmitterId = "DayTransmitterId",
    Ward = "Ward",
    NightProductFamily = "NightProductFamily",
    NightProductName = "NightProductNamesas",
    NightProductSize = "NightProductSize",
    NightChangeThreshold = "NightChangeThreshold",
    DayProductFamily = "DayProductFamily",
    DayProductName = "DayProductName",
    DayProductSize = "DayProductSize",
    DayChangeThreshold = "DayChangeThreshold",
    Active = "Active",
    DayTimeSetup = "DayTimeSetup",
    NightTimeSetup = "NightTimeSetup",
    SeparateTransmitter = "SeparateTransmitter",
    SameAsNightTimeSetup = "SameAsNightTimeSetup",
}

interface IResidentEditFormProps {
    entity?: Resident;
    onDiscard: () => void;
    blockDeletion?: boolean;
    onModalSizeChange?: (modalSize: EModalSize) => void;
    selectedWard?: Ward;
}

const ResidentEditForm = (props: IResidentEditFormProps) => {
    const wards = useAppSelector<Ward[]>(state => state.wardSlice.wards);
    const market = useAppSelector<Market>(state => state.marketSlice.markets[0] || new Market());
    const productFamilies = useAppSelector<ProductFamily[]>(state => state.marketSlice.productFamilies);
    const products = useAppSelector<Prod[]>(state => state.marketSlice.products);
    const residents = useAppSelector<Resident[]>(state => state.residentSlice.residents);
    const careFacility = useAppSelector<CareFacility | undefined>(state => state.nursingHomeSlice.nursingHome || undefined);
    const isFormDirty = useAppSelector<boolean>(state => state.contextSlice.dirtyFormContext?.isDirty ?? false);
    const dispatch = useAppDispatch();

    const [entity, setEntity] = useState<Resident>(cloneDeep(props.entity) || new Resident({ wardId: props.selectedWard ? props.selectedWard.id : undefined }));
    const initialState = useMemo(() => cloneDeep(props.entity) || new Resident({ wardId: props.selectedWard ? props.selectedWard.id : undefined }), [props.entity, props.selectedWard]);
    const [sameAsNight, setSameAsNight] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isFirstRenderDone, setIsFirstRenderDone] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [confirmDeleteActive, setConfirmDeleteActive] = useState<boolean>(false);
    const [confirmLeave, setConfirmLeave] = useState<boolean>(false);
    const [clearFormContext, setClearFormContext] = useState<boolean>(false);
    // Use this to store a copy of day transmitter id before setting it to a new value, since we need to be able to get the old value back
    const [dayTransmitterIdCopy, setDayTransmitterIdCopy] = useState<string>("");
    const navigate = useNavigate();

    const selectedWard = wards.find(ward => ward.id === entity.wardId);
    const isEdit = !!props.entity;

    const popupTitleId = isEdit ? "resident.leaveTitle" : "resident.discardTitle";
    const popupMessageId = isEdit ? "resident.leaveMessage" : "resident.discardMessage";
    const popupConfirmTextId = isEdit ? "constants.leave" : "constants.discard";
    const callbackFunction = isEdit ? undefined : props.onDiscard;

    const {
        externalIntegrationType = null
    } = careFacility || {}
    const readOnly = externalIntegrationType !== null && externalIntegrationType !== IntegrationType.Amba

    const clearFormData = (): void => {
        setEntity(new Resident({ wardId: props.selectedWard ? props.selectedWard.id : undefined }));
        setValidationErrors([]);
    };

    const validate = (): Promise<boolean> => {
        const errors = [];

        errors.push(...validateResident(entity));

        const duplicateNameFound = residents.some(resident =>
            resident.wardId === entity.wardId
            && resident.name.toLowerCase() === entity.name.toLowerCase()
            && resident.id !== entity.id);

        if (duplicateNameFound) {
            errors.push(ErrorCodes.DuplicateNameError);
        }

        setValidationErrors(errors);
        return Promise.resolve(errors.length < 1);
    };

    const onSave = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendResident(false);
    };

    const onCancel = () => {
        if (!isEqual(initialState, entity)) {
            setConfirmLeave(true)
        } else {
            props.onDiscard();
        }
    };

    const onSaveAndAddAnother = async (event: any) => {
        event.preventDefault();
        const isValid = await validate();
        if (!isValid) {
            return;
        }
        sendResident(true);
    };

    const onDelete = () => {
        setConfirmDelete(true);
    };

    const onRevert = () => {
        setEntity(initialState);
        setValidationErrors([]);
        dispatch(setFormDirty(false));
    };

    const onDiscard = () => {
        setConfirmLeave(true);
        setClearFormContext(true);
    };

    const getExtraActions = (isEditMode: boolean, shouldBlockDeletion: boolean) => {
        const extraActions: { label: string; action: (event: any) => void, disabled?: boolean }[] = [];
        if (isEditMode) {
            if (!shouldBlockDeletion) {
                extraActions.push(
                    {
                        label: "residents.editForm.deleteLabel",
                        action: onDelete,
                        disabled: readOnly
                    }
                );
            }
            extraActions.push(
                {
                    label: "constants.revert",
                    action: onRevert,
                    disabled: isEqual(initialState, entity)
                }
            );
        } else {
            extraActions.push(
                {
                    label: "constants.discard",
                    action: onDiscard,
                    disabled: isEqual(initialState, entity)
                }
            );
        }
        return extraActions;
    };

    const handleDelete = () => {
        if (entity.isActive) {
            setConfirmDelete(false);
            setConfirmDeleteActive(true);
        } else {
            onDeleteConfirm();
        }
    };

    const onDeleteConfirm = () => {
        deleteResident();
        if (isFormDirty) {
            dispatch(setFormDirty(false));
        }
    };

    const sendResident = (addMore: boolean) => {
        if (isEdit) {
            RestClient.updateResident(residentToReqBody(entity))
                .then(() => {
                    toastSuccess(localizer(ToastMessages.UpdateCareRecipientSuccessful));
                    props.onDiscard();
                    appInsights.trackEvent({ name: ETrackEvent.ResidentUpdated });
                })
                .catch((error: ResponseError) => {
                    handleBackendValidation(error);

                    console.error(error);
                    appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
                });

        } else {
            RestClient.postResident(residentToReqBody(entity))
                .then((response: any) => {
                    toastSuccess(localizer(ToastMessages.CreateCareRecipientSuccessful));
                    if (addMore) {
                        appInsights.trackEvent({ name: ETrackEvent.ResidentAddedAddAnother });
                        clearFormData();
                        // Chrome is so fast that scrollTo() action fires before Chrome's default scroll to html anchor event. Hence the timeout
                        setTimeout(() => window.scrollTo(0, 0), 10)
                    } else {
                        appInsights.trackEvent({ name: ETrackEvent.ResidentAdded });
                        const { id } = response;
                        navigate(routePaths.careRecipients + `/${id}`)
                    }
                })
                .catch((error: any) => {
                    handleBackendValidation(error);

                    console.error(error);
                    appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
                });
        }
        dispatch(setFormDirty(false));
        window.scrollTo(0, 0);
    };

    const handleBackendValidation = (error: any) => {
        if (error?.status === 409) {
            const errorObject = JSON.parse(error.response.text);
            const errors = [];
            if (entity.nightTransmitter.transmitterProductCode.length > 0
                && errorObject.transmitterCodes.includes(removeWhiteSpaces(entity.nightTransmitter.transmitterProductCode).substring(0, 12))) {
                errors.push(ErrorCodes.DuplicateTransmitterIdNight);

            }
            if (entity.dayTransmitter.transmitterProductCode.length > 0
                && errorObject.transmitterCodes.includes(removeWhiteSpaces(entity.dayTransmitter.transmitterProductCode).substring(0, 12))) {
                errors.push(ErrorCodes.DuplicateTransmitterIdDay);
            }
            setValidationErrors(errors);
        } else {
            toastError(localizer(ToastMessages.CreateCareRecipientError));
        }
    };

    const deleteResident = () => {
        RestClient.deleteResident(entity.id)
            .then(() => {
                toastSuccess(localizer(ToastMessages.DeleteCareRecipientSuccessful));
                navigate(routePaths.careRecipients);
                appInsights.trackEvent({ name: ETrackEvent.ResidentDeleted });
            })
            .catch((error: ResponseError) => {
                toastError(localizer(ToastMessages.DeleteCareRecipientError));

                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });

            });
    };

    const setDayTransmitterId = (resident: Resident) => {
        setDayTransmitterIdCopy(resident.dayTransmitter.transmitterProductCode);
        return areStripsLengthTheSame(resident, productFamilies, products)
            ? resident.nightTransmitter.transmitterProductCode
            : resident.dayTransmitter.transmitterProductCode;
    };

    const handleChange = useCallback((name: string, value: any) => {
        const resident = cloneDeep(entity);

        switch (name) {
            case Fields.Name:
                resident.name = value;
                break;
            case Fields.NightTransmitterId:
                resident.nightTransmitter.transmitterProductCode = value;
                if (resident.dayTimeSetup && !resident.separateTransmitter) {
                    resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                }
                break;
            case Fields.NightProductFamily:
                resident.nightTransmitter.productFamily = value;
                resident.nightTransmitter.productName = "";
                resident.nightTransmitter.productSize = "";
                resident.nightTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.NightProductName:
                resident.nightTransmitter.productName = value.name;
                resident.nightTransmitter.productPadType = value.padType;
                resident.nightTransmitter.productSize = "";
                resident.nightTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.NightProductSize:
                resident.nightTransmitter.productSize = value.name;
                resident.nightTransmitter.productId = value.id;
                resident.nightTransmitter.productThresholdCategory = value.thresholdCategory;

                if (resident.dayTransmitter.productId) {
                    resident.separateTransmitter = !areStripsLengthTheSame(resident, productFamilies, products);
                }

                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayTransmitterId:
                resident.dayTransmitter.transmitterProductCode = value;
                setDayTransmitterIdCopy(resident.dayTransmitter.transmitterProductCode);
                break;
            case Fields.DayProductFamily:
                resident.dayTransmitter.productFamily = value;
                resident.dayTransmitter.productName = "";
                resident.dayTransmitter.productSize = "";
                resident.dayTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayProductName:
                resident.dayTransmitter.productName = value.name;
                resident.dayTransmitter.productPadType = value.padType;
                resident.dayTransmitter.productSize = "";
                resident.dayTransmitter.productId = 0;
                resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                break;
            case Fields.DayProductSize:
                resident.dayTransmitter.productSize = value.name;
                resident.dayTransmitter.productId = value.id;
                resident.dayTransmitter.productThresholdCategory = value.thresholdCategory;
                if(careFacility?.sensorStripType === SensorStripType.MultiSize){
                    resident.separateTransmitter = !!resident.nightTransmitter.productId && !areStripsLengthTheSame(resident, productFamilies, products);
                    resident.dayTransmitter.transmitterProductCode = setDayTransmitterId(resident);
                }
                break;
            case Fields.Ward:
                resident.wardId = value;
                break;
            case Fields.Active:
                resident.isActive = value;
                break;
            case Fields.NightTimeSetup:
                resident.nightTimeSetup = value;
                if (value === false) {
                    resident.nightTransmitter.clear();
                    resident.dayTransmitter.clear();
                    resident.dayTimeSetup = false;
                    resident.separateTransmitter = false;
                }
                break;
            case Fields.DayTimeSetup:
                resident.dayTimeSetup = value;
                if (value === false) {
                    resident.dayTransmitter.clear();
                    resident.separateTransmitter = false;
                    resident.dayTransmitter.transmitterProductCode = dayTransmitterIdCopy;
                }
                break;
            case Fields.SameAsNightTimeSetup:
                if (value) {
                    resident.dayTransmitter.copyFrom(resident.nightTransmitter);
                    resident.separateTransmitter = false;
                } else {
                    resident.dayTransmitter.clear();
                }
                break;
            case Fields.NightChangeThreshold:
                resident.nightTransmitter.changeThreshold = value;
                break;
            case Fields.DayChangeThreshold:
                resident.dayTransmitter.changeThreshold = value;
                break;
            case Fields.SeparateTransmitter:
                resident.separateTransmitter = value;
                setDayTransmitterIdCopy(resident.dayTransmitter.transmitterProductCode);
                resident.dayTransmitter.transmitterProductCode = !value && resident.dayTransmitter.productId
                    ? resident.nightTransmitter.transmitterProductCode
                    : dayTransmitterIdCopy;

                break;
        }
        if (!isFormDirty && !isEqual(initialState, resident)) {
            dispatch(setFormDirty(true, popupTitleId, popupMessageId, popupConfirmTextId, callbackFunction));
        } else if (isFormDirty && isEqual(initialState, resident)) {
            dispatch(setFormDirty(false));
        }
        setEntity(resident);
    }, [callbackFunction, careFacility?.sensorStripType, dayTransmitterIdCopy, dispatch, entity, initialState, isFormDirty, popupConfirmTextId, popupMessageId, popupTitleId, productFamilies, products, setDayTransmitterId]);

    useEffect(() => {
        setSameAsNight(areProductsTheSame(entity, productFamilies, products));
        if (careFacility?.sensorStripType === SensorStripType.MultiSize) {
            const nightProductStripSize = getSelectedProductSizeForResident(entity, true, productFamilies, products)?.strip.toUpperCase();
            const dayProductStripSize = getSelectedProductSizeForResident(entity, false, productFamilies, products)?.strip.toUpperCase();
            if (
                entity.nightTimeSetup &&
                entity.dayTimeSetup &&
                residentHasValidSelectedSize(entity, true, productFamilies, products) &&
                residentHasValidSelectedSize(entity, false, productFamilies, products) &&
                nightProductStripSize !== dayProductStripSize &&
                !entity.separateTransmitter
            ) {
                handleChange(Fields.SeparateTransmitter, true);
            }

        }
    }, [entity]);

    useEffect(() => {
        if (!isFirstRenderDone) {
            if (entity &&
                productFamilies &&
                products &&
                !!entity.nightTransmitter.productId &&
                !!entity.dayTransmitter.productId &&
                !entity.dayTransmitter.transmitterProductCode &&
                !entity.nightTransmitter.transmitterProductCode
            ) {
                const isSeparateTransmitter = isEdit ? entity.separateTransmitter : false;
                handleChange(Fields.SeparateTransmitter, isSeparateTransmitter);
                setIsFirstRenderDone(true);
            }
        }
    }, [entity, productFamilies, products]);

    const getProducts = (productFamily: string) => {
        const productFamilyName = productFamilies.find(x => x.name === productFamily)?.name;
        return products.filter((product: Prod) =>
            product.productFamilyName === productFamilyName
        );
    };

    const getSelectedBriefForResident = (resident: Resident, nightTransmitter: boolean): Prod | undefined => {
        if (nightTransmitter) {
            return getProduct(resident.nightTransmitter.productFamily, resident.nightTransmitter.productName, productFamilies, products);
        }
        else {
            return getProduct(resident.dayTransmitter.productFamily, resident.dayTransmitter.productName, productFamilies, products);
        }
    };

    const getSelectedProductForResident = (nightTransmitter: boolean): ProductFamily => {
        if (nightTransmitter) {
            return productFamilies
                .filter((productFamily: ProductFamily) => productFamily.name === entity.nightTransmitter.productFamily)[0];
        } else {
            return productFamilies
                .filter((productFamily: ProductFamily) => productFamily.name === entity.dayTransmitter.productFamily)[0];
        }
    };

    const getSelectedChangeThresholdForResident = (nightTransmitter: boolean): any => {
        const thresholds = getChangeThresholds();
        const thresholdValue = nightTransmitter ? entity.nightTransmitter.changeThreshold : entity.dayTransmitter.changeThreshold;
        const selectedThreshold = thresholds.find((threshold: any) => threshold.value === thresholdValue);
        return selectedThreshold;
    };

    const getProductTypeLabel = (): string => {
        if (market.code) {
            if (market.code === MarketCode.NorthAmerica) {
                return "residents.editForm.productType";
            }
        }
        return "residents.editForm.absLevel";
    };

    const recommendationLabel = (resident: Resident) => {
        if (
            (resident.nightTimeSetup && residentHasValidSelectedSize(resident, true, productFamilies, products) && !resident.dayTimeSetup)
        ) {
            const stripSize = localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip.toLowerCase()}`).toUpperCase();
            return (
                <>
                    <Row >
                        <Col md={9}>
                            <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                            <Col className={"mt-2"}>
                                <FormattedMessage
                                    id="residents.editForm.recommendation.oneStrip"
                                    values={{
                                        stripSize: <b>{stripSize}</b>
                                    }} />
                            </Col>
                        </Col >
                        <Col >
                            <StyledIconContainer>
                                <Col md={7} />
                                <Col md={3}>
                                    <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={2}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                            </StyledIconContainer>
                        </Col>
                    </Row>
                </>);
        } else if (
            resident.nightTimeSetup &&
            resident.dayTimeSetup &&
            residentHasValidSelectedSize(resident, true, productFamilies, products) &&
            residentHasValidSelectedSize(resident, false, productFamilies, products)
        ) {
            if (
                getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip.toUpperCase() ===
                getSelectedProductSizeForResident(resident, false, productFamilies, products)?.strip.toUpperCase()
            ) {
                const stripSize = localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip.toLowerCase()}`).toUpperCase();
                return (
                    <>
                        <Row>
                            <Col md={9}>
                                <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                                <Col className="mt-2">
                                    <FormattedMessage
                                        id="residents.editForm.recommendation.sameStrip"
                                        values={{
                                            stripSize: <b>{stripSize}</b>
                                        }} />
                                </Col>
                            </Col >
                            <Col >
                                <StyledIconContainer>
                                    {!sameAsNight ?
                                        <>
                                            <Col md={4} />
                                            <Col md={3}>
                                                <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                            </Col>
                                            <Col md={3}>
                                                <DayOnlyIcon fill={Colors.blue.brandingDark} />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md={7} />
                                            <Col md={3}>
                                                <SameAsNightIcon fill={Colors.blue.brandingDark} />
                                            </Col></>
                                    }
                                    <Col md={2}>
                                        <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                    </Col>
                                </StyledIconContainer>
                            </Col>
                        </Row>
                    </>);
            }
            const nightTimeStripSize = localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, true, productFamilies, products)?.strip.toLowerCase()}`).toUpperCase();
            const dayTimeStripSize = localizer(`sensorStripIndicator.${getSelectedProductSizeForResident(resident, false, productFamilies, products)?.strip.toLowerCase()}`).toUpperCase();
            return (
                <>
                    <Row>
                        <Col>
                            <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                            <Col className="mt-2">
                                <FormattedMessage
                                    id="residents.editForm.recommendation.twoStrips"
                                    values={{
                                        stripSize1: <b>{nightTimeStripSize}</b>,
                                        stripSize2: <b>{dayTimeStripSize}</b>
                                    }} />
                            </Col>
                        </Col>
                        <Col md={3}>
                            <StyledIconContainer>
                                <Col md={3} />
                                <Col md={3}>
                                    <NightOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col >
                                <Col md={3}>
                                    <DayOnlyIcon fill={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={1}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                                <Col md={1}>
                                    <StripIcon fill={Colors.blue.brandingDark} stroke={Colors.blue.brandingDark} />
                                </Col>
                            </StyledIconContainer>
                        </Col>
                    </Row>
                </>);
        }

        return (
            <Row>
                <Col>
                    <StyledTitle className="mb-0 mt-3">{localizer("residents.editForm.recommendation.title")}</StyledTitle>
                    <Col className="mt-2">
                        {localizer("residents.editForm.recommendation.choose")}
                    </Col>
                </Col>
            </Row>);
    };

    const isDayTransmitterIdFieldDisabled = (resident: Resident) => {
        return !resident.dayTimeSetup || !resident.separateTransmitter || (!resident.separateTransmitter && areStripsLengthTheSame(resident, productFamilies, products));
    };

    const isSeparateTransmitterFieldDisabled = (resident: Resident) => {
        return careFacility?.sensorStripType === SensorStripType.MultiSize && (!resident.dayTimeSetup || (resident.dayTimeSetup && !!resident.nightTransmitter.productId && !!resident.dayTransmitter.productId && !areStripsLengthTheSame(resident, productFamilies, products)));
    };

    const isDayStripImageDisabled = (resident: Resident) => {
        return resident.dayTimeSetup &&
            !!resident.dayTransmitter.productId &&
            (!resident.nightTransmitter.productId || areStripsLengthTheSame(resident, productFamilies, products)) &&
            !resident.separateTransmitter;
    };

    const isNightStripImageDisabled = (resident: Resident) => {
        return resident.nightTimeSetup &&
            resident.dayTimeSetup &&
            !!resident.nightTransmitter.productId &&
            !resident.dayTransmitter.productId;
    };

    const isSameAsNightDisabled = (resident: Resident) => {
        return !resident.nightTimeSetup || !resident.dayTimeSetup || !resident.nightTransmitter.productId;
    };

    const nightProductSizes = useMemo(() => entity.nightTransmitter.productName && residentHasValidSelectedBrief(entity, true, productFamilies, products) ?
        getProduct(entity.nightTransmitter.productFamily, entity.nightTransmitter.productName, productFamilies, products)?.ProductSizes.map((productSize: ProductSize) => {
            return { value: JSON.stringify(productSize), label: productSize.name };
        }) || [] : [], [entity, productFamilies, products]);


    useEffect(() => {
        if (nightProductSizes.length === 1 && !entity.nightTransmitter.productSize) {
            handleChange(Fields.NightProductSize, JSON.parse(nightProductSizes[0].value));
        }
    }, [entity.nightTransmitter.productSize, handleChange, nightProductSizes])

    const dayProductSizes = useMemo(() => entity.dayTransmitter.productName && residentHasValidSelectedBrief(entity, true, productFamilies, products) ?
        getProduct(entity.dayTransmitter.productFamily, entity.dayTransmitter.productName, productFamilies, products)?.ProductSizes.map((productSize: ProductSize) => {
            return { value: JSON.stringify(productSize), label: productSize.name };
        }) || [] : [], [entity, productFamilies, products]);


    useEffect(() => {
        if (dayProductSizes.length === 1 && !entity.dayTransmitter.productSize) {
            handleChange(Fields.DayProductSize, JSON.parse(dayProductSizes[0].value));
        }
    }, [entity.dayTransmitter.productSize, handleChange, dayProductSizes])
    const addEditForm =
        <StyledFrom>

            {readOnly && (
                <ExternalIntegrationInformationBlock sectionTranslationKey='externalIntegration.careRecipient.delete.informationMessage' />
            )}

            <Row className="gx-3 gy-0">
                <Col md="4">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyBedNumberError, ErrorCodes.DuplicateNameError]}
                        label={"residents.editForm.name"}
                    >
                        <TextFieldNew
                            disabled={readOnly}
                            data-qa="bed_number_input_text"
                            fieldName={Fields.Name}
                            value={entity.name}
                            onChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Col>
                <Col md="4">
                    <ValidatedFormGroupWrapper
                        validationErrors={validationErrors}
                        dependentErrorCodes={[ErrorCodes.EmptyWard, ErrorCodes.EmptyWardId]}
                        label={"residents.editForm.ward"}
                    >
                        <DropdownButtonNew
                            disabled={readOnly}
                            disabledMessage={wards.find((ward) => ward.id === entity.wardId)?.name}
                            id="resident-edit-ward-name"
                            name={Fields.Ward}
                            options={
                                wards.filter(ward => ward.isActive).map((ward: Ward) => {
                                    return { value: ward.id, label: ward.name };
                                })
                            }
                            selectedValue={entity.wardId ? selectedWard?.id : props.selectedWard?.id}
                            handleChange={handleChange}
                        />
                    </ValidatedFormGroupWrapper>
                </Col>
                <Col md="4">
                    <div className="float-end">
                        <StyledLabel>
                            <FormattedMessage id={"residents.editForm.active"} />
                        </StyledLabel>
                        <StyledToggle
                            disabled={readOnly}
                            checked={entity.isActive}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={() => handleChange(Fields.Active, !entity.isActive)}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="g-3">
                <StyledLeftCol className="g-3">
                    <Row className="mt-2 mb-3 g-2">
                        <Col md="auto">
                            <Row className="mb-2">
                                <CheckBox
                                    label={localizer("residents.editForm.nightTimeSetup")}
                                    value={entity.nightTimeSetup}
                                    fieldName={Fields.NightTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Col>
                        <Col>
                            <StyledNightIcon>
                                <NightIcon className="float-end align-top" fill={!entity.nightTimeSetup ? Colors.grey.mediumLight : Colors.blue.brandingDark} />

                            </StyledNightIcon>
                        </Col>
                    </Row>
                    <Row className="mb-1 g-2">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductNameNight]}
                            label={"residents.editForm.productName"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-family-night"
                                name={Fields.NightProductFamily}
                                disabled={!entity.nightTimeSetup}
                                options={
                                    productFamilies.map((productFamily: ProductFamily) => {
                                        return { value: productFamily.name, label: productFamily.name };
                                    })
                                }
                                selectedValue={
                                    entity.nightTransmitter.productFamily && residentHasValidSelectedProduct(entity, true, productFamilies)
                                        ? getSelectedProductForResident(true).name
                                        : null
                                }
                                handleChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">

                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductTypeNight]}
                            label={getProductTypeLabel()}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-name-night"
                                name={Fields.NightProductName}
                                disabled={!entity.nightTimeSetup || !entity.nightTransmitter.productFamily}
                                options={
                                    residentHasValidSelectedProduct(entity, true, productFamilies) ? getProducts(entity.nightTransmitter.productFamily)
                                        .map((product: Prod) => {
                                            return { value: JSON.stringify(product), label: product.name };
                                        }) : []
                                }
                                selectedValue={
                                    entity.nightTransmitter.productName && residentHasValidSelectedBrief(entity, true, productFamilies, products)
                                        ? JSON.stringify(getSelectedBriefForResident(entity, true))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductSizeNight]}
                            label={"residents.editForm.size"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-size-night"
                                name={Fields.NightProductSize}
                                disabled={!entity.nightTimeSetup || !entity.nightTransmitter.productName || !residentHasValidSelectedBrief}
                                options={nightProductSizes}
                                selectedValue={
                                    entity.nightTransmitter.productSize && residentHasValidSelectedSize(entity, true, productFamilies, products)
                                        ? JSON.stringify(getSelectedProductSizeForResident(entity, true, productFamilies, products))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyChangeThresholdNight]}
                            label={"residents.editForm.changeThreshold.title"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-threshold-night"
                                name={Fields.NightChangeThreshold}
                                disabled={!entity.nightTimeSetup}
                                options={getChangeThresholds()}
                                selectedValue={
                                    entity.nightTransmitter.changeThreshold
                                        ? getSelectedChangeThresholdForResident(true)?.value
                                        : null
                                }
                                handleChange={(name, value) => handleChange(name, Number(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledLeftCol>
                <StyledRightCol className="g-3">
                    <Row className="mt-2 mb-3 g-2">
                        <Col md="auto">
                            <Row className="mb-2">
                                <CheckBox
                                    label={localizer("residents.editForm.dayTimeSetup")}
                                    value={entity.dayTimeSetup}
                                    disabled={!entity.nightTimeSetup}
                                    fieldName={Fields.DayTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                            <Row>
                                <CheckBox
                                    label={localizer("residents.editForm.sameAsNightSetup")}
                                    disabled={isSameAsNightDisabled(entity)}
                                    value={sameAsNight}
                                    fieldName={Fields.SameAsNightTimeSetup}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Col>
                        <Col>
                            <DayIcon className="float-end" fill={!entity.dayTimeSetup ? Colors.grey.mediumLight : Colors.blue.brandingDark} />
                        </Col>
                    </Row>
                    <Row className="mb-1 g-2">

                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductNameDay]}
                            label={"residents.editForm.productName"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-family-day"
                                name={Fields.DayProductFamily}
                                disabled={!entity.dayTimeSetup}
                                options={
                                    productFamilies.map((productFamily: ProductFamily) => {
                                        return { value: productFamily.name, label: productFamily.name };
                                    })
                                }
                                selectedValue={
                                    entity.dayTransmitter.productFamily && residentHasValidSelectedProduct(entity, false, productFamilies)
                                        ? getSelectedProductForResident(false).name
                                        : null
                                }
                                handleChange={handleChange}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductTypeDay]}
                            label={getProductTypeLabel()}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-name-day"
                                name={Fields.DayProductName}
                                disabled={!entity.dayTimeSetup || !entity.dayTransmitter.productFamily}
                                options={
                                    entity.dayTransmitter.productFamily && residentHasValidSelectedProduct(entity, false, productFamilies) ? getProducts(entity.dayTransmitter.productFamily)
                                        .map((product: Prod) => {
                                            return { value: JSON.stringify(product), label: product.name };
                                        }) : []
                                }
                                selectedValue={
                                    entity.dayTransmitter.productName && residentHasValidSelectedBrief(entity, false, productFamilies, products)
                                        ? JSON.stringify(getSelectedBriefForResident(entity, false))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyProductSizeDay]}
                            label={"residents.editForm.size"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-product-size-day"
                                name={Fields.DayProductSize}
                                disabled={!entity.dayTimeSetup || !entity.dayTransmitter.productName}
                                options={dayProductSizes}
                                selectedValue={
                                    entity.dayTransmitter.productSize && residentHasValidSelectedSize(entity, false, productFamilies, products)
                                        ? JSON.stringify(getSelectedProductSizeForResident(entity, false, productFamilies, products))
                                        : null
                                }
                                handleChange={(name: string, value: any) => handleChange(name, JSON.parse(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                    <Row className="mb-1">
                        <ValidatedFormGroupWrapper
                            validationErrors={validationErrors}
                            dependentErrorCodes={[ErrorCodes.EmptyChangeThresholdDay]}
                            label={"residents.editForm.changeThreshold.title"}
                        >
                            <DropdownButtonNew
                                id="resident-edit-threshold-day"
                                name={Fields.DayChangeThreshold}
                                disabled={!entity.dayTimeSetup}
                                options={getChangeThresholds()}
                                selectedValue={
                                    entity.dayTransmitter.changeThreshold
                                        ? getSelectedChangeThresholdForResident(false)?.value
                                        : null
                                }
                                handleChange={(name, value) => handleChange(name, Number(value))}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledRightCol>
            </Row>
            {careFacility && careFacility.sensorStripType === SensorStripType.MultiSize &&
                <StyledRecommendationRow className="g-3 mt-3">
                    {recommendationLabel(entity)}
                </StyledRecommendationRow>
            }
            <Row className="g-3 mt-0">
                <StyledStripLeftCol className="g-3">
                    {(careFacility && careFacility.sensorStripType === SensorStripType.MultiSize) ? (
                        <SensorStripIndicator
                            icon={isDayTransmitterIdFieldDisabled(entity) ? undefined : <NightIcon height={24} width={24} fill={Colors.blue.brandingDark} />}
                            stripLength={residentHasValidSelectedSize(entity, true, productFamilies, products) ? getSelectedProductSizeForResident(entity, true, productFamilies, products)?.strip : undefined}
                            disabled={isNightStripImageDisabled(entity)}
                        />) : (
                        <div style={{ height: "30px" }}>
                            {!isDayTransmitterIdFieldDisabled(entity) &&
                                <NightIcon height={30} width={30} fill={Colors.blue.brandingDark} />}
                        </div>
                    )}
                    <Row className="gy-3">
                        <ValidatedFormGroupWrapper
                            multiErrorStack={true}
                            validationErrors={validationErrors}
                            dependentErrorCodes={[
                                ErrorCodes.BaseTransmitterErrorNight,
                                ErrorCodes.DuplicateTransmitterIdNight,
                                ErrorCodes.NightTransmitterCodeLength,
                                ErrorCodes.InvalidNightTransmitterCode,
                                ErrorCodes.InvalidNightControlDigit,
                            ]}
                            label={"residents.editForm.transmitterId"}
                        >
                            <TextFieldNew
                                data-qa="night_transmitter_id_input_text"
                                disabled={!entity.nightTimeSetup}
                                fieldName={Fields.NightTransmitterId}
                                value={entity.nightTransmitter.transmitterProductCode}
                                onChange={handleChange}
                                format={"### ### ### ### ###"}
                            />
                        </ValidatedFormGroupWrapper>
                    </Row>
                </StyledStripLeftCol>
                {!isDayTransmitterIdFieldDisabled(entity) ?
                    <StyledStripRightCol className="g-3">

                        {(careFacility && careFacility.sensorStripType === SensorStripType.MultiSize) ?
                            (<SensorStripIndicator
                                icon={<DayIcon height={24} width={24} fill={Colors.blue.brandingDark} />}
                                stripLength={residentHasValidSelectedSize(entity, false, productFamilies, products) ? getSelectedProductSizeForResident(entity, false, productFamilies, products)?.strip : undefined}
                                disabled={isDayStripImageDisabled(entity)}
                            />) : (
                                <div style={{ height: "30px" }}>
                                    <DayIcon height={30} width={30} fill={Colors.blue.brandingDark} />
                                </div>
                            )
                        }

                        <Row className="g-3">
                            <ValidatedFormGroupWrapper
                                multiErrorStack={true}
                                validationErrors={validationErrors}
                                dependentErrorCodes={[
                                    ErrorCodes.BaseTransmitterErrorDay,
                                    ErrorCodes.DuplicateTransmitterIdDay,
                                    ErrorCodes.DayTransmitterCodeLength,
                                    ErrorCodes.InvalidDayTransmitterCode,
                                    ErrorCodes.TransmitterIdAlreadyUsedInNightSetup,
                                    ErrorCodes.InvalidDayControlDigit,
                                ]}
                                label={"residents.editForm.transmitterId"}
                            >
                                <TextFieldNew
                                    data-qa="day_transmitter_id_input_text"
                                    disabled={isDayTransmitterIdFieldDisabled(entity)}
                                    fieldName={Fields.DayTransmitterId}
                                    value={entity.dayTransmitter.transmitterProductCode}
                                    onChange={handleChange}
                                    format={"### ### ### ### ###"}
                                />
                            </ValidatedFormGroupWrapper>
                        </Row>
                    </StyledStripRightCol>
                    :
                    <StyledEmptyCol />}
            </Row>
            <Row className="g-2 mt-3">
                <Col md="12">
                    <StyledSpaceSmall>
                        <CheckBox
                            label={localizer((careFacility && careFacility.sensorStripType === SensorStripType.MultiSize) ? "residents.editForm.separateTransmitter" : "residents.editForm.separateTransmitter.oneStrip")}
                            value={entity.separateTransmitter}
                            fieldName={Fields.SeparateTransmitter}
                            onChange={handleChange}
                            disabled={isSeparateTransmitterFieldDisabled(entity)}
                        />
                    </StyledSpaceSmall>
                </Col>
            </Row>
        </StyledFrom>;

    return (
        <React.Fragment>
            <BackButton onClick={onCancel} />
            {addEditForm}
            <AddEditButtonGroup
                isEdit={isEdit}
                onSave={onSave}
                onSaveAndAddAnother={onSaveAndAddAnother}
                removeCloseButton
                disabledConfirm={isEqual(initialState, entity)}
                disabledAddAnother={isEqual(initialState, entity)}
                extraActions={getExtraActions(isEdit, props.blockDeletion ?? false)}
            />
            {confirmDelete && (
                <ModalWrapper
                    closeCallback={() => setConfirmDelete(false)}
                    isOpen={confirmDelete}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"resident.deleteMessage"}
                            confirmButton={"constants.delete"}
                            onConfirmCancel={() => setConfirmDelete(false)}
                            onConfirm={() => handleDelete()}
                        />
                    }
                    modalTitle={localizer("residents.editForm.deleteLabel")}
                    size={EModalSize.XS}
                />
            )}
            {confirmDeleteActive && (
                <ModalWrapper
                    closeCallback={() => setConfirmDeleteActive(false)}
                    isOpen={confirmDeleteActive}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={"resident.deleteActiveMessage"}
                            messageValues={{ resident: entity.name }}
                            confirmButton={"constants.delete"}
                            onConfirmCancel={() => setConfirmDeleteActive(false)}
                            onConfirm={() => {
                                onDeleteConfirm();
                                appInsights.trackEvent({ name: ETrackEvent.ResidentDeletedActive });
                            }}
                        />
                    }
                    modalTitle={localizer("residents.editForm.deleteActive.modalTitle")}
                    size={EModalSize.XS}
                />
            )}
            {confirmLeave && (
                <ModalWrapper
                    closeCallback={() => setConfirmLeave(false)}
                    isOpen={confirmLeave}
                    modalContent={
                        <GenericConfirmationForm
                            messageId={popupMessageId}
                            confirmButton={popupConfirmTextId}
                            onConfirmCancel={() => {
                                setConfirmLeave(false);
                                setClearFormContext(false);
                            }}
                            onConfirm={() => {
                                if (clearFormContext) {
                                    onRevert();
                                    setConfirmLeave(false);
                                    setClearFormContext(false);
                                } else {
                                    props.onDiscard();
                                    dispatch(setFormDirty(false));
                                    appInsights.trackEvent({ name: ETrackEvent.ResidentLeftWithoutSaving });
                                }
                            }}
                        />
                    }
                    modalTitle={localizer(popupTitleId)}
                    size={EModalSize.XS}
                />
            )}
        </React.Fragment >
    );
};

export default ResidentEditForm;
