import {useContext, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import styled from "styled-components";
import { AppContext } from "../../../data/AppRepository";
import ErrorCodes from "../../../models/ErrorCodes";
import { localizer } from "../../../utils/Localizer";
import {toastError, toastLogoutInfo, toastSuccess} from "../../../utils/Toaster";
import ValidatedFormGroupWrapper from "../../wrappers/ValidatedFormGroupWrapper";

import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { FormattedMessage } from "react-intl";
import { appInsights } from "../../../AppInsights";
import { parseErrorToString } from "../../../utils/AppInsightsHelpers";
import TextField from "../../common/controls/TextFieldNew";
import PasswordField from "../../common/controls/PasswordField";
import {routePaths} from "../../../utils/PathHelpers";
import Colors from "../../../assets/Colors";
import {StyledATag} from "../../common/View";
import {useLocation, useNavigate } from "react-router-dom";

const StyledLoginForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 360px;
`;

const StyledLoginButton = styled(Button)`
    margin-top: 34px;
    height: 50px;
`;

const StyledDiv = styled(StyledATag)`
    font-size: 12px;
    color: ${Colors.blue.brandingDark};
    :hover {
        cursor: pointer;
        color: ${Colors.blue.brandingDark};
    }
`;

const LinkContainer = styled.div`
    margin-top: 5px;
    width: 360px;
    display: flex;
    justify-content: space-between;
`;

enum Fields {
    Email = "Email",
    Password = "Password"
}

const LoginForm = (props: any) => {
    const appContext = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [hasError, setHasError] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash.includes("id_token")) {
            appContext
                .loginWithIdToken(location.hash.replace("#id_token=", ""))
                .then(() => navigate(routePaths.loginPath))
                .catch((error: any) =>
                    toastError(getErrorMessage(error.message || error))
                );

            toastSuccess(localizer("loginView.successfulPasswordChange"));
        }
        return;
    }, []);

    useEffect(() => {
        if (email === "" && password === "") {
            setHasError(false);
            setError("");
        }
    }, [email, password]);

    const signInMsAdUser = (event: any) => {
        blockFetchUserResourcesFlag();
        event.preventDefault();
        appContext.loginWithAd().catch((error) => {
            if (error.errorCode === "interaction_in_progress") {
                toastLogoutInfo(localizer("loginView.supportlogin.microsoftLoginInProgress"));
            } else {
                toastError(localizer("loginView.supportlogin.somethingWentWrong"));
            }
        });
    };

    const blockFetchUserResourcesFlag = () => {
        sessionStorage.setItem("blockFetchUserResources", "true");
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setHasError(false);

        const twoFAResponse = await appContext
            .loginB2C(email, password)
            .catch((error: string) => {
                setHasError(true);
                setError(error);
                console.error(error);
                appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
            });
        props.onSubmit({ twoFAResponse, email, password});
    };

    const validateForm = (): boolean => {
        return email.length > 0 && password.length > 0;
    };

    const passwordFieldProhibitedErrorCodes = [ErrorCodes.NoPermissions];
    const emailFieldNoTextErrorCodes = [ErrorCodes.Locked];

    const getErrorMessage = (error: string) => {
        if (
            Object.values(ErrorCodes).find((code) => code.toString() === error)
        ) {
            return localizer(error);
        }
        return error;
    };

    return (
        <>
            <StyledLoginForm onSubmit={handleSubmit} >
                <ValidatedFormGroupWrapper
                    validationErrors={hasError ? [error] : []}
                    dependentErrorCodes={[ErrorCodes.AccessDenied, ErrorCodes.LoginFailed, ErrorCodes.NoPermissions, ErrorCodes.Locked]}
                    label={"loginView.email"}
                    hideMessage={emailFieldNoTextErrorCodes.some((code) => code === error)}
                >
                    <TextField
                        data-qa="login_input_text"
                        type={"email"}
                        fieldName={Fields.Email}
                        value={email}
                        onChange={(_, value: any) => setEmail(value)}
                    />
                </ValidatedFormGroupWrapper>
                <ValidatedFormGroupWrapper
                    validationErrors={hasError ? [error] : []}
                    dependentErrorCodes={[ErrorCodes.AccessDenied, ErrorCodes.LoginFailed, ErrorCodes.NoPermissions, ErrorCodes.Locked]}
                    label={"loginView.password"}
                    hideMessage={passwordFieldProhibitedErrorCodes.some((code) => code === error)}
                >
                    <PasswordField
                        data-qa="password_input_text"
                        fieldName={Fields.Password}
                        value={password}
                        onChange={(_: any, value: any) => setPassword(value)}
                    />
                </ValidatedFormGroupWrapper>
                <StyledLoginButton
                    data-qa="sign_in_button"
                    disabled={!validateForm()}
                    type="submit"
                >
                    <FormattedMessage id="loginView.login" />
                </StyledLoginButton>
            </StyledLoginForm>
            <LinkContainer>
                <StyledDiv onClick={signInMsAdUser}>
                    {localizer("loginView.loginSupport")}
                </StyledDiv>
                <StyledDiv
                    onClick={() => {
                        blockFetchUserResourcesFlag();
                        navigate(routePaths.resetPassword);
                    }}
                >
                    {localizer("loginView.resetPassword")}
                </StyledDiv>
            </LinkContainer>
        </>
    );
};

export default LoginForm;
