
export default class OverviewStatisticsResponse {
    wardsWithData: [{id: number }];
    residents: [
        {
            id: number
            name: string;
            wardId: string;
            statistics: {
                nightTimeMinutesBetweenChanges: [
                    {
                        trend: number | null;
                        value: number | null;
                    }
                ],
                productChanges: [
                    {
                        prompted: number | null;
                        trend: number | null
                        value: number | null;
                        valueDay: number | null;
                        valueNight: number | null;
                    }
                ]
                responseTime: [ 
                    {
                        trend: number | null;
                        value: number | null;
                        valueDay: number | null;
                        valueNight: number | null;
                    }
                ]
            }
        }

    ]

    constructor(json: any) {
        this.wardsWithData = json.wardsWithData ?  json.wardsWithData : [];
        this.residents = json.residents ? json.residents : [];
    }
}