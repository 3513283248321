import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { PatternFormat } from "react-number-format";
import Colors from "../../../assets/Colors";

const StyledFormControl = styled(Form.Control)`
    border: 1px solid ${Colors.grey.mediumLight};
    height: 42px;
    padding-left: 8px;
    &:hover {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
    &:focus {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Colors.blue.dark};
    }
`;

const StyledMaskedInput = styled(PatternFormat)`
    height: 42px;
    border: 1px solid ${Colors.grey.mediumLight};
    &:hover {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
    &:focus {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid ${Colors.blue.dark};
    }
`;

interface ITextFieldProps {
    fieldName: string;
    value: string;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    onChange: (name: string, value: any) => void;
    format?: string;
}

export const InputTypes = {
    number: 'number',
    text: 'text'
};

const NumericPreventKeys = [
    'e',
    'E',
    '.',
    ','
];

const TextField = (props: ITextFieldProps) => {
    const type = props.type || InputTypes.text;

    const handleChange = (result: any) => {
        const name = result.target.name;
        const value = result.target.value;
        props.onChange(name, value);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (type === InputTypes.number && NumericPreventKeys.includes(event.key)) {
            event.preventDefault();
        }
    };

    const Component: any = props.format ? StyledMaskedInput : StyledFormControl;

    return (
        <Component
            {...props}
            type={type}
            as={props.format ? PatternFormat : "input"}
            {...(props.format && {
                format: props.format,
            })}
            placeholder={props.placeholder}
            className="form-control"
            disabled={props.disabled}
            name={props.fieldName}
            value={props.value || ''}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            autoComplete={"false"} 
        />
    );
};

export default TextField;
