import ChangeEvent from "./ChangeEvent";
import Pager from "./Pager";
import ISorting, { Direction } from "./Sorting";

export default class ChangeEventPager extends Pager<ChangeEvent> {
    /**
     * If we have the complete dataset there is no need to ask the API to sort data.
     * @param changeEventLogs
     * @param sorting
     */
    static sortChangeEvents(changeEventLogs: ChangeEvent[], sorting: ISorting): ChangeEvent[] {
        return changeEventLogs.sort((a, b) => {
            let colA: any;
            let colB: any;
            switch (sorting.column) {
                case 'timeCreated':
                    colA = a.timeCreated;
                    colB = b.timeCreated;
                    break;
                case 'responseTimeMinutes':
                    colA = a.responseTimeMinutes;
                    colB = b.responseTimeMinutes;
                    break;
            }
            if (!colA) { return sorting.direction === Direction.Up ? -1 : 1; }
            if (!colB) { return sorting.direction === Direction.Up ? 1 : -1; }
            if (colA < colB) { return sorting.direction === Direction.Up ? -1 : 1; }
            if (colA > colB) { return sorting.direction === Direction.Up ? 1 : -1; }
            return 0;
        });
    }
}