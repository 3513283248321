import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { appInsights } from "../../../../../AppInsights";
import Colors from "../../../../../assets/Colors";
import { parseErrorToString } from "../../../../../utils/AppInsightsHelpers";
import ModalWrapper, {
    EModalSize
} from "../../../../common/modal/ModalWrapper";
import { StyledLeft } from "../../../../common/table/formatters/PositionContainers";

const StyledButton = styled.div`
    font-size: 16px;
    color: ${Colors.blue.brandingDark};
    cursor: pointer;
`;

const StyledGuidingTextContainer = styled.div`
    margin-bottom: 15px;
`;

const CellFormatter = (props: any) => {
    const jsonValue = React.useMemo(() => {
        let parsedValue = null;
        if (props.data.value) {
            try {
                parsedValue = JSON.parse(
                    `{"requestValue": ${props.data.value}}`
                );
            } catch (error) {
                try {
                    parsedValue = JSON.parse(props.data.value);
                } catch (error) {
                    console.error(error);      
                    appInsights.trackException({ exception: new Error(parseErrorToString(error)), severityLevel: SeverityLevel.Error });
                }
            }
        }

        return parsedValue;
    }, [props?.data?.value]);

    const triggerComponent: React.ReactNode = (
        <StyledButton>
            <FormattedMessage id="audits.tableHeader.details" />
        </StyledButton>
    );

    return props.data.value ? (
        <StyledLeft>
            <ModalWrapper
                triggerComponent={triggerComponent}
                modalContent={
                    <>
                        <StyledGuidingTextContainer>
                            <FormattedMessage id={"audits.details.table.oldLogsInfo"} />
                        </StyledGuidingTextContainer>
                        <pre>
                            {JSON.stringify(jsonValue, null, 2)}
                        </pre>
                    </>
                }
                modalTitle={
                    <FormattedMessage id="audits.tableHeader.request"></FormattedMessage>
                }
                size={EModalSize.L}
            />
        </StyledLeft>
    ) : null;
};

const RequestFormatter = (data: any) => <CellFormatter data={data} />;
export default RequestFormatter;
