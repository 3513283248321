import { StyledLeft } from '../../../common/table/formatters/PositionContainers';

const PercentageFormatter = (props: any) => {
    const { value } = props;
    return (
        <StyledLeft>
            {value ? value + "%" : "0%"}
        </StyledLeft>
    );
};

export default PercentageFormatter;